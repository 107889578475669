<template>
  <div>
    <div
      v-if="alertType === 'Warning'"
      class="border-l-2 p-4 rounded flex gap-2"
      style="border-color: #fed500; background-color: rgba(254, 213, 0, 0.1)"
      role="alert"
    >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fed500"  >
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
      </svg> -->
      <svg class="w-6 h-6" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6.7655L30.9465 30.5H5.0535L18 6.7655ZM18 0.5L0 33.5H36L18 0.5ZM16.5 14H19.5V23H16.5V14ZM18 28.625C16.9665 28.625 16.125 27.785 16.125 26.75C16.125 25.715 16.9665 24.875 18 24.875C19.0335 24.875 19.875 25.715 19.875 26.75C19.875 27.785 19.0335 28.625 18 28.625Z" fill="#FED500"/>
      </svg>


      <p v-if="title" class="" style="color: #fed500">{{ title.toUpperCase() }}</p>
      <slot />
    </div>

    <div
      v-if="alertType === 'Danger'"
      class="border-l-4 p-4 rounded"
      style="border-color: #ff0a00; background-color: rgba(255, 10, 0, 0.1)"
      role="alert"
    >
      <p v-if="title" class="Inter-Bold" style="color: #ff0a00">{{ title }}</p>
      <slot />
    </div>

    <div
      v-if="alertType === 'Success'"
      class="border-l-4 p-4 rounded"
      style="border-color: #00c92e; background-color: rgba(0, 201, 46, 0.1)"
      role="alert"
    >
      <p v-if="title" class="Inter-Bold" style="color: #00c92e">{{ title }}</p>
      <slot />
    </div>

    <div
      v-if="alertType === 'Information'"
      class="border-l-4 p-4 rounded"
      style="border-color: #2b53ff; background-color: rgba(43, 83, 255, 0.1)"
      role="alert"
    >
      <p v-if="title" class="Inter-Bold" style="color: #2b53ff">{{ title }}</p>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    alertType: { type: String, default: "Information" },
    title: { type: String, default: "" },
  },
};
</script>

<style></style>
