<template>
  <div :class="`wrapper${id}`" class="wrapper">
    <svg class="gauge_chart">
      <circle
        v-for="(cir, index) in curves"
        :key="cir.percent"
        :id="`circle-${index + 1}${id}`"
        :class="`circle${id} circle range`"
        
        cx="50%"
        cy="50%"
        :style="{ 'stroke-width': strokeWidth }"
        :stroke="`url(#circle-${index + 1}${id}-gradient)`"
      ></circle>
      <defs v-for="(cir, index) in curves" :key="index">
        <linearGradient
          :id="`circle-${index + 1}${id}-gradient`"
          :x1="cir.x1 || 0"
          :y1="cir.y1 || 0"
          :x2="cir.x2 || 0"
          :y2="cir.y2 || 0"
        >
          <!-- <stop offset="0%" :stop-color="cir.color" />
          <stop offset="100%" v-if="cir.color2" :stop-color="cir.color2" /> -->
          <stop offset="0%" :stop-color="cir.gradienColor.light" />
          <stop offset="100%" :stop-color="cir.gradienColor.dark" />
        </linearGradient>
      </defs>

      <!-- Shadows -->
      <template v-if="type === 'large'">
        <circle
          :id="`shadow-1${id}`"
          :stroke="getShadowOne"
          class="shadow-circle"
          :class="'shadow-circle' + id"
          :style="{ 'stroke-width': shadowWidth }"
          cx="50%"
          cy="50%"
        ></circle>
        <circle
          :id="`shadow-2${id}`"
          :stroke="getShadowTwo"
          class="shadow-circle"
          :class="'shadow-circle' + id"
          :style="{ 'stroke-width': shadowWidth }"
          cx="50%"
          cy="50%"
        ></circle>
        <circle
          :id="`shadow-3${id}`"
          :stroke="getShadowThree"
          class="shadow-circle"
          :class="'shadow-circle' + id"
          :style="{ 'stroke-width': shadowWidth }"
          cx="50%"
          cy="50%"
        ></circle>
      </template>
      <circle
        v-else
        :id="`shadow-1${id}`"
        stroke="#1E2224"
        class="shadow-circle"
        :class="'shadow-circle' + id"
        :style="{ 'stroke-width': shadowWidth }"
        cx="50%"
        cy="50%"
      ></circle>
    </svg>
    <img
      :ref="`gauge_chart_needle${id}`"
      id="gauge_chart_needle"
      src="@/assets/needle.png"
      alt=""
      :style="{ height: radius + 'px' }"
    />
    <label id="label">
      <slot name="label">{{ currentValue }}%</slot>
    </label>
    <label id="label-bottom">
      <slot name="label-bottom"></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "GuageChart",
  props: [
    "id",
    "curves",
    "currentValue",
    "type",
    "radius",
    "shadowWidth",
    "strokeWidth",
    "shadowRadius",
  ],

  computed: {
    getShadowOne() {
      
      return this.currentValue <= 33 ? "#2F1F20" : "#1E2224";
    },
    getShadowTwo() {
      return this.currentValue > 33 && this.currentValue <= 66
        ? "#2D3420"
        : "#1E2224";
    },
    getShadowThree() {
      return this.currentValue > 66 ? "#1B3424" : "#1E2224";
    },
  },
  mounted() {
    this.render();
  },
  updated() {
    this.render();
  },
  
  methods: {
    render() {
      // console.log('gaugechart render called')
      let r = this.radius; // radius
      let shadow_r = this.shadowRadius; // shadow radius

      let circles = document.querySelectorAll(`.circle${this.id}`);
      let total_circles = circles.length;
      for (let i = 0; i < total_circles; i++) {
        circles[i].setAttribute("r", r);
      }

      let mask_circles = document.querySelectorAll(`.shadow-circle${this.id}`);
      let total_mask_circles = mask_circles.length;
      for (let i = 0; i < total_mask_circles; i++) {
        mask_circles[i].setAttribute("r", shadow_r);
      }

      /* set gauge_chart's wrapper dimension */
      let gauge_chart_dimension = r * 2 + 20;
      let wrapper = document.querySelector(`.wrapper${this.id}`);
      wrapper.style.width = gauge_chart_dimension + "px";
      wrapper.style.height = gauge_chart_dimension + "px";

      /* add strokes to circles  */
      let cf = 2 * Math.PI * r;
      const curves = this.curves;

      let mask_cf = 2 * Math.PI * shadow_r;

      let mask_semi_cf = mask_cf * 0.75;

      curves.forEach((cir, index) => {
        let  idForCircle =  `#circle-${index + 1}${this.id}`
        // console.log('idForCircle == ',idForCircle)
        document
          .querySelector(idForCircle)
          .setAttribute(
            "stroke-dasharray",
            cf * cir.percent -
              (cf - cf * curves[0].percent) / 2 +
              "," +
              (cf - cf * cir.percent)
          );
      });

      if (this.type === "large") {
        curves.forEach((cir, index) => {
          document
            .querySelector(`#shadow-${index + 1}${this.id}`)
            .setAttribute(
              "stroke-dasharray",
              mask_cf * cir.percent -
                (mask_cf - mask_cf * curves[0].percent) / 2 +
                "," +
                (mask_cf - mask_cf * cir.percent)
            );
        });
      }

      if (this.type !== "large") {
        document
          .querySelector(`#shadow-1${this.id}`)
          .setAttribute(
            "stroke-dasharray",
            mask_semi_cf -
              (mask_cf - mask_semi_cf) / 2 +
              "," +
              (mask_cf - mask_semi_cf)
          );
      }

      this.handleRangeChange();
    },
    handleRangeChange() {
      let percent = this.currentValue;
      this.$refs["gauge_chart_needle" + this.id].style.transform =
        "rotate(" + (224.5 + (percent * 270) / 100) + "deg)";
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
.gauge_chart {
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
}
.circle {
  fill: none;
}
.shadow-circle {
  fill: none;
  transition: 1s ease-in-out;
  stroke-width: 20;
}

.range {
  stroke-width: 10;
}

#label {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: #f8f9f9;
  top: 40%;
}

#label-bottom {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -5%;
}

#gauge_chart_needle {
  left: 0px;
  margin: auto;
  position: absolute;
  right: 0px;
  top: 10px;
  transform-origin: bottom center;
  transform: rotate(270deg);
  transition: 0.5s ease-in-out;
}

#slider {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0);
  -webkit-appearance: none;
  width: 80%;
  height: 6px;
  border-radius: 5px;
  background: #f8f9f9;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 20px;
  height: 35px;
  background: #00a40f;
  border-radius: 4px;
}

#slider::-moz-range-thumb {
  cursor: pointer;
  width: 20px;
  height: 35px;
  background: #00a40f;
  border-radius: 4px;
}
</style>
