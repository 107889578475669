import Charts from "../api/charts";
import moment from "moment";
import FivesApi from "@/api/fivesApi";
import store from "./store";

const tableModule = {
  state: {
    alertSelected_TableColumn:'Market Alert',
    clickedRow: {},
    activeCountry: 'us',
    stockAlertChartDate:null,
    showBadge:false,
    stockAlertselectedSymbol:"GOOGL",
    stockAlertselectedSymbolDetail:{companyName:"Alphabet Inc Class A",id:"Nasdaq",name:"GOOGL"},
    // activeYear: 2022,
    activeYear: new Date().getFullYear(),
    activeYearMH: new Date().getFullYear(),
    activeYearMA: new Date().getFullYear() ,
    tableLoader:false,
    activeDates: [],
    startDate: "",
    endDate: "",
    marketAlertData: [],
    clickedCalenderDate: [],
    commentHighlightDate:[],
    // tableVarient:'',
    indexData:{
      ca:['ASX.INDX'],
      oz:['ASX.INDX','AORD.INDX'],
      us:['DJA.INDX','NDX.INDX'],
    },
    indexDataValues:{
      ca:{},
      oz:{},
      us:{},
    },
    indexUpdated:false,
    tableRowClicked:false,
    yearFirstLastDate:{
      start_date:'2024-01-01',
      last_date:'2024-12-31'

    },
    fiveYearChartRange:{
      startYear:null,
      endYear:null
    },
    chartClicked:{
      chartClickedOldDate: new Date().getFullYear()

    },
  },
  mutations: {
    updateStateInStoreA(state) {
      this.$store.commit('storeA/updateStateMutation', newState)
    },
    ALERT_SELECTED_TABLE_COLUMN(state,payload){
      state.alertSelected_TableColumn = payload
    },
    TABLE_LOADER_UPDATE(state,payload){
      state.tableLoader = payload
    },
    STOCK_ALERT_SELECTED_TABLE_COLUMN(state,payload){
      console.log("stockAlertselectedSymbol===>",payload)
      state.stockAlertselectedSymbol = payload
    },
    STOCK_ALERT_SELECTED_SYMBOL_DETAIL(state,payload){
      state.stockAlertselectedSymbolDetail = payload
    },

    ACTIVE_DATES_FOR_CALENDAR(state, payload) {
      state.activeDates = payload; //Calendar Dates: Hassaan
    },
    SET_BADGE(state,payload){
      state.showBadge = payload
    },
    SET_STOCKALERT_CHART_DATE(state,payload){
      state.stockAlertChartDate = payload
    },
    // TABLE_VARIENT(state, payload) {
    //   state.tableVarient = payload; //table Varient
    // },
    CHANGE_YEAR(state, payload) {
      state.activeYear = payload; //Calendar Api: Hassaan
    },

    CHANGE_YEAR_MH(state, payload) {
      state.activeYearMH = payload; 
    },

    CHANGE_YEAR_MA(state, payload) {
      state.activeYearMA = payload; 
    },

    SET_CLICKED_ROW(state, payload) {
      state.clickedRow = payload; //Save Row data for Clicked row:Hassaan
    },
    SET_START_DATE(state, payload) {
      //calander year range:Hassaan
      state.startDate = payload;
    },
    SET_COMMENT_HIGHLIGHTER(state,payload){
      console.log("payload===>",payload)
      if (Array.isArray(payload)) {
        state.commentHighlightDate = payload;
      } else {
        state.commentHighlightDate = [...state.commentHighlightDate, payload];
      }
    },
    REMOVE_COMMENT(state,payload){
      if(state?.commentHighlightDate?.length > 0){
        let removeDate = state?.commentHighlightDate?.filter((item) => item !== payload)
        state.commentHighlightDate = removeDate
      }
    },
    SET_END_DATE(state, payload) {
      //calander year range:Hassaan
      state.endDate = payload;
    },
    SET_MARKET_ALERT_DATA(state, payload) {
      state.marketAlertData = payload;
    },
    SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE(state, payload) {
      console.log("payload",payload)

      state.activeCountry = payload;
    },
    CLICKED_CALENDER_DATE(state, payload) {
      // console.log(payload);
      state.clickedCalenderDate = payload;
    },
    ADD_INDEX_DATA(state, payload) {
      // console.log(payload);
      state.indexDataValues[payload.id] = payload.data;
      state.indexUpdated=true
    },
    INDEX_DATA_UPDATED(state, payload) {
      state.indexUpdated=false
    },
    TABLE_ROW_CLICKED(state, payload) {
      state.tableRowClicked=payload
    },
    YEAR_FIRST_LAST_DATE(state, payload) {
      state.yearFirstLastDate=payload
    },
    FIVE_YEAR_CHART_RANGE(state, payload){
      state.fiveYearChartRange=payload
    },
    CHART_CLICKED(state,payload){
      state.chartClicked=payload
    }
  },

  actions: {
    async loadIndexData(context){
      // http://139.180.181.26:9090/api/v1/symbols/ASX.INDX?timeFrame=D&isIntra=false&market=Indices
      let indexData = context.state.indexData[context.state.activeCountry]
      let dataValue = context.state.indexDataValues[context.state.activeCountry]
      for (let index of indexData) {
        let fetchList = await Charts.getIndexData(index,'D')
        let data = fetchList.data;
        let dataSlice = data?.slice(data.length - 180,data.length-1);
        // console.log(dataSlice)
        dataValue[index] = dataSlice
      }
      context.commit('ADD_INDEX_DATA',{id:context.state.activeCountry,data:dataValue})
      
      
      // indexData
      // getIndexData
    },
    
  },

  getters: {
    getTableLoader: state => state.tableLoader,
    alertSelected_TableColumn: state => state.alertSelected_TableColumn,
    stockAlert_selected_symbol: state => state.stockAlertselectedSymbol,
    stockAlert_selected_symbol_detail: state => state.stockAlertselectedSymbolDetail,
    clickedRowStore: (state) => state.clickedRow,
    selectedEndDate: (state) => state.endDate,
    selectedStartDate: (state) => state.startDate,
    selectedActiveDateStore: (state) => state.activeDates,
    selectedActiveYearStore: (state) => state.activeYear,
    selectedActiveYearStoreMH: (state) => state.activeYearMH,
    selectedActiveYearStoreMA: (state) => state.activeYearMA,
    getTableData: (state) => state.marketAlertData, //getter for all data in table
    getActiveCountry: (state) => state.activeCountry, //getter for active country for table
    getClickedCalenderDate: (state) => state.clickedCalenderDate, //getter for calender clikedDate for scroll
    indexDataMap: (state) => state.indexData && state.indexData[state.activeCountry] && state.indexData[state.activeCountry], 
    indexDataValuesDefault: (state) => state.indexDataValues && state.indexDataValues[state.activeCountry] && state.indexDataValues[state.activeCountry][0],
    indexDataValues: (state) => state.indexDataValues,
    indexUpdated: (state) => state.indexUpdated, 
    commentHighlightArr: (state) => state.commentHighlightDate,
    getBadge:(state) => state.showBadge,
    getStockAlertChartDate:(state) => state.stockAlertChartDate,
    // tableVarent:(state)=>state.tableVarient
    tableRowClicked:(state) => state.tableRowClicked,
    yearFirstLastDateChart:(state) => state.yearFirstLastDate,
    getFiveYearChartRange:(state) => state.fiveYearChartRange,
    chartClickedFlag:(state) => state.chartClicked,
  },
};

export default tableModule;
