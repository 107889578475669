import chartApi from "@/api/charts";
import chartConstant from "@/helper/chartConstant";
import {parseToChartCandleData} from "@/utils/candle";
import moment from 'moment'
// import Data from "../components/vue_chart/data/data.json";
import {ChartSettingsDefaults, IndicatorSettingsDefaults, MultipleIndicatorSettings} from "@/utils/chartSettings";
import userApi from "@/api/userApi";
import {Utils} from "trading-vue-js"
import {GetTimeRange} from "@/components/vue_chart/chartEvent";
import pointAndFigure from "@/overlays/chartTypes/calculators/pnfCalculator";
localStorage.removeItem("showLegendProps")
// const selectedIndicators =localStorage.getItem('selectedIndicators')
let selectedIndicatorsList = []
// if(selectedIndicators){
//     selectedIndicatorsList=JSON.parse(selectedIndicators)
// }
const ChartModule={
    state:{
        highestCandle: {},
        lowestCandle: {},
        chartSettings: {
            ...ChartSettingsDefaults ,
            ...IndicatorSettingsDefaults,
            ...MultipleIndicatorSettings
        },
        dc: null,
        tradingChartReference: null,
        chartLoading: true,
        showChartVolume: false,
        chartManualInteraction: false,
        currentSymbol: chartConstant.CURRENT_SYMBOL_DEFAULTS,
        chartType: 'Candles',
        marketId: '',
        selectedSymbol: 'BHP.au',
        timeFrame: 'D',
        chartVisibleRange: '1Y',
        defineRangeInDate: '',
        startTime: null,
        endTime: null,
        symbolName: "TradingVue.js",
        defaultDataLength: 50,
        barTrendChart: 34,
        chartStartingIndex: 0,
        chartEndingIndex: 10,
        chartStartingTime: 1662966000000,
        chartEndingTime: 1690268400000,
        selectedSymbolCompanyName: "",
        selectedDataType:"Index",
        selectedIndicators: selectedIndicatorsList,
        pnfColumnSet: [],
        ohlcv: [],
        ohlcvPnF: [],
        ohlcvTimeMap: [],
        ohlcvTimeMapPnF: []
    },
    mutations:{
        SET_CHART_MANUAL_INTERACTION(state,payload) {
            state.chartManualInteraction = payload
        },
        UPDATE_TCI_PARAMS(state,payload) {
            state.barTrendChart = payload
        },
        SET_DATA_TYPE(state,payload) {
            state.selectedDataType = payload
        },
        TOGGLE_CHART_VOLUME(state) {
            state.showChartVolume = !state.showChartVolume
        },
        DEFINE_RANGE_IN_DATE(state, payload) {
            let startTime = payload[0];
            let endTime = payload[1];
            let dataMapStr = `${
                moment.unix(startTime/1000).format("DD-MM-YYYY")
            } - ${
                moment.unix(endTime/1000).format("DD-MM-YYYY")
            }`
            state.startTime = startTime
            state.endTime = endTime
            state.defineRangeInDate = dataMapStr
            //localStorage.setItem("range_changed",JSON.stringify([startTime,endTime]))
        },
        SET_CHART_LOADING(state, payload) {
            state.chartLoading = payload
        },
        SET_ADD_INDICATORS(state, payload) {
            state.selectedIndicators.push(payload)
            localStorage.setItem("selectedIndicators",JSON.stringify(state.selectedIndicators))
        },
        SET_ALL_INDICATORS(state, payload) {
            state.selectedIndicators= payload
            localStorage.setItem("selectedIndicators",JSON.stringify(payload))
        },
        SET_TIME_FRAME(state, payload) {
            state.timeFrame = payload
        },
        SET_REMOVE_INDICATORS(state, payload) {
            console.log("store",payload)
            state.selectedIndicators = payload
            },
        SET_TRADING_CHART_REFERENCE(state, payload) {
            state.tradingChartReference = payload.tradingChartReference
        },
        SET_DATA_CUBE(state, payload) {
            state.dc = payload.dc
        },
        SET_CANDLE_DATA_PNF(state, payload) {
            state.ohlcvPnF = payload
        },
        SET_CANDLE_DATA(state, payload) {
            state.ohlcv = payload
        },
        SET_CANDLE_DATA_TIME_MAP_PNF(state, payload) {
            state.ohlcvTimeMapPnF = payload
        },
        SET_CANDLE_DATA_TIME_MAP(state, payload) {
            state.ohlcvTimeMap = payload
        },
        SET_PNF_COLUMN_MAP(state, payload) {
            state.pnfColumnSet = payload
        },
        SET_CURRENT_SYMBOL(state, symbol) {
            state.currentSymbol = symbol
        },
        SET_SELECTED_SYMBOL(state, symbol) {
            console.log("symbol==>",symbol)
            state.selectedSymbol = symbol
        },
        SET_SELECTED_SYMBOL_COMPANY_NAME(state,payload){
            state.selectedSymbolCompanyName = payload
        },
        SET_MARKET_ID(state, marketId) {
            // console.log("SET_MARKET_ID")
            state.marketId = marketId
        },
        CHART_VISIBLE_RANGE(state, range) {
            state.chartVisibleRange = range
        },
        setChartStartingTime(state, startingTime) {
            state.chartStartingTime = startingTime
        },
        setChartEndingTime(state, endingTime) {
            state.chartEndingTime = endingTime
        },
        SET_CHART_TYPE(state, payload) {
            // state.dc.data.chart.type = payload
            state.chartType = payload
        },
        SET_HIGH_LOW_CANDLE(state, payload) {
            console.log("payload",payload)
            state.highestCandle = payload.highestCandle
            state.lowestCandle = payload.lowestCandle
            // state.chartType = payload
        },
        // SET_LOWEST_CANDLE(state, payload) {
        //     state.lowestCandle = payload
        //     // state.chartType = payload
        // },
    },
    actions:{
        async removeIndicator(context, payload) {
            let indicators = context.state.selectedIndicators.filter((indicator) => indicator !== payload)
            context.commit("SET_REMOVE_INDICATORS", indicators)
            localStorage.setItem("selectedIndicators", JSON.stringify(indicators))
            // console.log("this.data.tool",this.data.tool)
            console.log("context", context.getters.tv.$ref)
            //  name == settings-win
            let controllers = context.getters.tv?.controllers;
            if (controllers?.length) {
                let settingWindows = controllers?.[2];
                let keys = Object.keys(settingWindows?.widgets)
                for (let k of keys) {
                    console.log("context ==", k, settingWindows?.widgets[k])
                    context.getters.tv.$delete(settingWindows?.widgets, k)
                }
            }
            // https://dimensionalsys.atlassian.net/browse/MAP-682
            setTimeout(() => {
                userApi.updateChartConfig({
                    chartConfig: {
                        ...context.state?.userDetails?.chartConfig,
                        overlays: indicators
                    }
                })
            })
        },
        async addIndicator(context,payload){
            context.commit("SET_ADD_INDICATORS",payload)
            setTimeout(() => {
                userApi.updateChartConfig({chartConfig:{...context.state?.userDetails?.chartConfig,overlays:context.state.selectedIndicators}})
            })
        },
        async updateChartConfig(context,payload){
            setTimeout(() => {
                userApi.updateChartConfig({chartConfig:{...context.state?.userDetails?.chartConfig,...payload}})
            })
        },
        async updateTimeStamps(context,payload ) {
            
            let computedStartTime;
            let computedEndTime;
            let timeMap = payload.t
            let ohlcvData = payload.d
            if(context.state.chartManualInteraction){
                computedStartTime= Utils.calculate_data_index_without_ti_map(timeMap,context.state.chartStartingTime,context.state.timeFrame);
                computedEndTime = Utils.calculate_data_index_without_ti_map(timeMap,context.state.chartEndingTime,context.state.timeFrame);
                console.log("searchResults chartManualInteraction",context?.state?.chartManualInteraction,computedStartTime,computedStartTime?.side)
                console.log("searchResults",computedEndTime,computedEndTime?.side)
            }else{
                let [startingTime,endingTime] = GetTimeRange(ohlcvData,context.state.chartVisibleRange,context.state.timeFrame)
                computedStartTime = Utils.calculate_data_index_without_ti_map(timeMap,startingTime,context.state.timeFrame);
                computedEndTime = Utils.calculate_data_index_without_ti_map(timeMap,endingTime,context.state.timeFrame);
            }

            if(isNaN(computedStartTime?.index)){
                context.state.chartStartingIndex = 0
            }else{
                context.state.chartStartingIndex = computedStartTime?.index
            }

            if(isNaN(computedEndTime?.index)){
                context.state.chartEndingIndex = this.getSymbolData()?.length
            }else{
                context.state.chartEndingIndex = computedEndTime?.index
            }
            console.log("chart data==>",computedStartTime?.index,computedEndTime?.index)

        },
        async setSymbolData(context, payload) {
            // console.log(context,payload)
            // context.getters.dc.del('Tool')
            // context.getters.dc.del('Fibonacci')
            // context.getters.dc.del('Trend')
            // context.getters.dc.tv.custom_event({ event: 'remove-all-tools-event', args: [] })
            let predefineDataSet = payload.dataMap?.length > 0;
            const symbolData = predefineDataSet  ? context.state.currentSymbol :  await chartApi.getSymbol(payload.symbolName,payload.timeFrame,payload.exchange)
            console.log("symbolData1==>",symbolData)
            context.commit('SET_SELECTED_SYMBOL', payload.symbolName)
            localStorage.setItem("SymbolSelected",symbolData.symbol)
            // console.log("searchResults  symbolData",symbolData)
            if("Error" === symbolData.symbol){
                //--- set loading status

                console.log("Symbol Error")
                return false
            }else{
                let symIdentifier = symbolData.symbol + "~" + symbolData.metaData.exchange;
                const [ohlcvData,timeMap] = predefineDataSet ? [payload.dataMap,payload.timeStampMap] : parseToChartCandleData(
                    symbolData.data
                )
                if(context.state.chartType === "PnF"){
                    const boxSizes = [
                        [0, 0.001],
                        [0.2, 0.002],
                        [0.5, 0.005],
                        [1, 0.01],
                        [2, 0.02],
                        [5, 0.05],
                        [10, 0.1],
                        [20, 0.2],
                        [50, 0.5],
                        [100, 1],
                        [200, 2],
                        [500, 5],
                        [1e3, 10]
                    ]
                    let dataSet = ohlcvData
                    const close = dataSet[dataSet.length -1][4]
                    const divVal = close / 50
                    const bxSize = close > 50
                                ? +divVal.toFixed(0)
                                : close > 25
                                    ? +divVal.toFixed(1)
                                    : close > 2.5
                                        ? +divVal.toFixed(2)
                                        : +divVal.toFixed(3)
                    let boxSize = bxSize || boxSizes[0],
                        reversal = 2
                    const legenedProps = [
                        {k:"Box size",v:boxSize},
                        {k:"Reversal",v:reversal},
                    ];
                    localStorage.setItem("showLegendProps_Init",JSON.stringify(legenedProps))
                    const pnfAlgo = pointAndFigure()
                    let columnData= pnfAlgo(
                        null,
                        symbolData.data,
                        boxSize,
                        reversal
                    )
                    const [ohlcvDataPnF,timeMapPnF] = [[],[]]
                    for (let col of columnData) {
                        let timeStamp = moment(col.date).valueOf();
                        ohlcvDataPnF.push([
                            timeStamp,
                            col.max,
                            col.max,
                            col.min,
                            col.min,
                            col.volume,
                            null,
                            col.value
                        ])

                        timeMapPnF.push([timeStamp])
                    }
                    console.log("pnfDataSet",[ohlcvDataPnF,timeMapPnF],columnData)
                    await context.dispatch("updateTimeStamps",{t:timeMapPnF,d:ohlcvDataPnF})

                    context.commit('SET_PNF_COLUMN_MAP', columnData)
                    context.commit('SET_CANDLE_DATA_PNF', ohlcvDataPnF)
                    context.commit('SET_CANDLE_DATA_TIME_MAP_PNF', timeMapPnF)
                    // alert("Pnf")
                }
                // eslint-disable-next-line no-unused-vars
                await context.dispatch("updateTimeStamps",{t:timeMap,d:ohlcvData})

                context.commit('SET_CANDLE_DATA', ohlcvData)
                context.commit('SET_CANDLE_DATA_TIME_MAP', timeMap)

                context.commit('SET_SELECTED_SYMBOL_COMPANY_NAME', symbolData.companyName)
                // console.log('symIdentifier == ',symIdentifier,context.state.chartType)
                context.commit('SET_MARKET_ID', symbolData.metaData.exchange)
                context.commit('SET_CURRENT_SYMBOL', symbolData)

                return true
                // context.state.dc.set('chart.data', ohlcvData)
                // context.dispatch('setChartRange', context.stante.chartVisibleRange)
            }
        },
        goToDateChart(context,timeStamp){
            console.log("store",timeStamp)
            context.state.tradingChartReference.goto(timeStamp)
        },
        updateChartStartingTime(context, startingTime) {
            context.commit('setChartStartingTime', startingTime)
        },
        updateChartEndingTime(context, endingTime) {
            context.commit('setChartEndingTime', endingTime)
        },
        async setNewChartType(context, payload) {
            // await context.dispatch("updateTimeStamps",{t:context.state.ohlcvTimeMap,d:context.state.ohlcv})
            context.commit('SET_CHART_TYPE', payload)
            return true
            // if (payload === 'PnF' || payload === 'Kagi') 
            // setTimeout(() => {
            //     context.getters.tv.goto(
            //         context.getters.tv.data.data.chart.data.length + 20
            //     )
            // }, 0)
        },
    },
    getters:{
        chartStartingIndex: state => state.chartStartingIndex,
        chartEndingIndex: state => state.chartEndingIndex,
        defaultDataLength: state => state.defaultDataLength,
        chartStartingTime: state => state.chartStartingTime,
        selectedDataTypeDesc: state => state.selectedDataType,
        selectedDataType: state => state.selectedDataType === "Index",
        defineRangeInDate: state => state.defineRangeInDate,
        getChartType: state => state.chartType,
        showChartVolume: state => state.showChartVolume,
        getSymbolData: state =>
            parseToChartCandleData(
                state.currentSymbol.data
            )[0],
        ohlcv: state => state.ohlcv,
        barTrendChart: state => state.barTrendChart,
        ohlcvTimeMap: state => state.ohlcvTimeMap,
        ohlcvPnF: state => state.ohlcvPnF,
        ohlcvTimeMapPnF: state => state.ohlcvPnF,
        pnfColumnSet: state => state.pnfColumnSet,
        getTimeFrame: state => state.timeFrame,
        getGeneralUserSettings: state => state.chartSettings,
        getSelectedSymbol: state => state.selectedSymbol,
        dc: state => state.tradingChartReference?.data,
        tv: state => state.tradingChartReference,
        chartVisibleRange: state => state.chartVisibleRange,
        symbolName: state => state.selectedSymbol,
        symbolCompanyName: state => state.selectedSymbolCompanyName,
        chartLoading: state => state.chartLoading,
        getSelectedIndicators: state => state.selectedIndicators,
        exchangeToCountry: state => {
            return {
                TSX:"ca",
                ASX:"oz",
                NYSE:"us",
                Nasdaq:"us",
            }
        }
    },
}
export default ChartModule