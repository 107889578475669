<template>
  <!-- <MarketWatch /> -->
  <!-- <div v-if="!loading"> -->
    <router-view></router-view>
  <!-- </div> -->
  <!-- <div v-else>
    Loading
  </div> -->
  <!-- <WatchList /> -->
</template>

<script>
//import MarketWatch from "./components/pages/MarketWatchPage.vue";
// import WatchList from './components/pages/WatchListPage.vue'

import userApi from "@/api/userApi";
import {mapActions} from "vuex";

export default {
  name: "App",
  components: {
    //   MarketWatch
    // WatchList
  },
  methods:{
    ...mapActions(["getUserData"])
  },
  async mounted() {
    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.embeddedChatbotConfig = {
        chatbotId: "smAfK-jxiaETUt54qatPc",
        domain: "www.chatbase.co"
      }
    `;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = "https://www.chatbase.co/embed.min.js";
    script2.defer = true;
    script2.setAttribute('chatbotId', 'smAfK-jxiaETUt54qatPc');
    script2.setAttribute('domain', 'www.chatbase.co');
    document.head.appendChild(script2);
    // await this.getUserData();
    this.loading = false
    // if (!userDetails) {
    //   this.$router.push("/signin")
    //   // console.log('userDetails',userDetails);
    //   // this.$store.commit("SET_USER_DETAILS", userDetails);
    // }else{
    //  
    // }
  },
  data(){
    return {
      loading:true
    }
  }
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
* {
  font-family: "Inter" !important;
} */

body {
  font-family: Inter-Light !important;
  background-color: #151515 !important;
}
#chatbase-bubble-button{
  bottom: 2.5rem !important;
}
.Inter-Bold{
  font-family: Inter-Bold !important;
}
.Inter-Medium{
  font-family: Inter-Medium !important;
}
.Inter-Light{
  font-family: Inter-Light !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}
</style>
