import axios from "../axios";
import {
  FIVES_FOLDER_LIST,
  GET_COMPANY_REPORTS, GET_FAV_SCANS,
  GET_COMPANY_REPORTS_STOCKALERT,
  GET_FOLDERS_STOCKLIST, GET_INDEX_LIST, GET_INDICIES,
  SAVE_FAV_SCANS,
  STOCK_LIST_PLUS,
  GET_SNAP_QUOTES,
  UPDATE_MARQUEE,
  STOCK_LIST_PLUS_BETA,
  FIVES_BETA
} from "./apiRoutes";
import config from "@/api/config";

export default {
  fetchFivesFolderList: async () => {
    try {
      return config.mockingNewFeature ? [] : await axios.get(FIVES_FOLDER_LIST);
    } catch (err) {
      alert(err);
    }
  },
  fetchStockListFolders: async () => {
    try{
      return await axios.get(GET_FOLDERS_STOCKLIST)
    }catch (e) {
      return {data:[]}
    }
  },
  fetchSnapQuotes: async (symbols) => {
    try{
      return await axios.post(GET_SNAP_QUOTES,{
        symbols
      })
    }catch (e) {
      return {data:[]}
    }
  },
  fetchIndicies: async () => {
    try{
      return await axios.get(GET_INDICIES)
    }catch (e) {
      return {data:[]}
    }
  },
  fetchIndexList: async (node,limit) => {
    console.log("node",node)
    try{
      return await axios.post(GET_INDEX_LIST,{
        limit:limit,
        assetID:node.symbol
      })
    }catch (e) {
      return {data:[]}
    }
  },
  saveFavScans: async (stockList,type) => {
    try {
      let axiosResponse = await axios.post(SAVE_FAV_SCANS, {
        stockList,
        type
      });
      
      return axiosResponse.data.success;
    } catch (err) {
      console.log(err)
      return false
    }
  },
  getFavScans: async (type) => {
    try {
      let axiosResponse = await axios.post(GET_FAV_SCANS,{
        type
      });

      return axiosResponse.data.success && axiosResponse.data.payload && axiosResponse.data.payload.stockList ? axiosResponse.data.payload.stockList : [];
    } catch (err) {
      console.log(err)
      return []
    }
  },
  fetchStockListByCategory: async (nodeMeta,length,start,sorted) => {
    try {
      console.log("nodeMeta in api",nodeMeta)
      let axiosResponse = await axios.post(STOCK_LIST_PLUS, {

        category: nodeMeta.category,
        country: nodeMeta.country,
        // exchange: nodeMeta.exchange,
        resourceType: nodeMeta.resourceType,
        criteria: nodeMeta.criteria,
        id:nodeMeta.id,
        start,
        length,
        sorted

      });
      return axiosResponse.data;
    } catch (err) {
      console.log(err)
      return {
        data:[],
        column:[],
      }
    }
  },

  // fetchStockListTableData: async (nodeMeta,length,start,sorted) => {
  fetchStockListTableData: async (nodeMeta) => {
    try {
      console.log("nodeMeta in api",nodeMeta)
      let axiosResponse = await axios.post(STOCK_LIST_PLUS_BETA,nodeMeta);
      return axiosResponse.data;
    } catch (err) {
      console.log(err)
      return {
        data:[],
      }
    }
  },

  fetchFivesTableData: async (nodeMeta) => {
    try {
      console.log("nodeMeta in api",nodeMeta)
      let axiosResponse = await axios.post(FIVES_BETA,nodeMeta);
      return axiosResponse.data;
    } catch (err) {
      console.log(err)
      return {
        data:[],
      }
    }
  },


  getCompanyReport: async (value,country) => {
    return await axios.post(`${GET_COMPANY_REPORTS}/${country}`, {
      ticker: value.symbol,
    });
  },
  getCompanyReportStockAlert: async (value) => {
    console.log("hahahah")
    return await axios.post(`${GET_COMPANY_REPORTS_STOCKALERT}`, {
      ticker: value,
    });
  },
  getMarqueeInfo: async (bannerSettings) => {
    return await axios.post(UPDATE_MARQUEE, {
      bannerSettings,
    });
  }
};
