<template>
  <div class="flex">
    <div
      style="width: 100%"
      class="allSelectors flex items-center w-screen"
      v-if="signedInUser.role == 'plus'"
    >
      <h1 class="text-white Inter-Bold flex items-center Market-Head">
        &nbsp;Market Heat
      </h1>

      <div class="flex ml-2 mr-6">
        <span class="ml-3 text-white text-xs market-heat-header-font">|</span>
        <p class="ml-0.5 text-white text-xs market-heat-header-font">
          {{
            signedInUser.country.countryShow > 1 ? "Select Country" : "Country"
          }}
        </p>
      </div>

      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex ml-1 mr-1 h-7 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.dataStreamCountry[0].country == 'us' ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            market-heat-header-font
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/Us_flag.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">
            United States
          </p>
        </div>

        <div class="leading-6 market-heat-header-font">
          <label for="toogleA" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleA"
                :checked="usaValue"
                :disabled="usaValue"
                @change="toggleUSAValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleA">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>
          </label>
        </div>
      </span>

      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex h-7 ml-1 mr-1 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.dataStreamCountry[0].country == 'ca' ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/CanadaFlag.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">Canada</p>
        </div>
        <div class="leading-6">
          <label for="toogleB" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleB"
                :checked="canadaValue"
                :disabled="canadaValue"
                @change="toggleCANValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleB">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>
            <!-- <div class="relative">
              <input id="toogleB" type="checkbox" :checked="canadaValue" :disabled="canadaValue"
                @change="toggleCANValue" class="sr-only" />

              <div class="w-8  p-[6px] -ml-2 h-4 bg-gray-700 rounded-full shadow-inner topToggle"></div>
              <div
                class="dot absolute w-3 h-3 bg-white rounded-full shadow -left-[5px] top-[2px] transition topToggleBtn">
              </div>
            </div> -->
          </label>
        </div>
      </span>
      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex h-7 ml-1 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.dataStreamCountry[0].country == 'oz' ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/australiaicon.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">
            Australia
          </p>
        </div>
        <div class="leading-6">
          <label for="toogleC" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleC"
                :checked="australiaValue"
                :disabled="australiaValue"
                @change="toggleAUSValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleC">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>

            <!-- old toggle -->

            <!-- <div class="relative">
              <input id="toogleC" type="checkbox" :checked="australiaValue" class="sr-only" :disabled="australiaValue"
                @change="toggleAUSValue" />

              <div class="w-8 p-[6px] -ml-2 h-4 bg-gray-700 rounded-full shadow-inner topToggle"></div>
              <div
                class="dot absolute w-3 h-3 bg-white rounded-full shadow -left-[5px] top-[2px] transition topToggleBtn">
              </div>
            </div> -->
          </label>
        </div>
      </span>

      <div>
        <button
          @click="showCalendar()"
          :disabled="!tableData.length > 0"
          class="flex text-[#898d96] justify-center items-center h-full w-full cursor-pointer ml-3"
        >
        Go to Date
        </button>
        <div
          v-if="showDatePicker"
          class="absolute mt-[1.5vh] z-[105] flex flex-col"
        >
          <button
            @click="handleFocusOut()"
            class="text-gray-200 flex justify-end text-right -mt-6"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <DatePicker v-model="selectedDate" color="green" :available-dates="disableDate()" is-dark>
          </DatePicker>
        </div>
      </div>
    </div>

    <div
      v-else
      style="width: 100%"
      class="allSelectors flex items-center w-screen"
    >
      <h1 class="text-white Inter-Bold flex items-center Market-Head">
        &nbsp;Market Heat
      </h1>

      <div class="flex ml-2 mr-6">
        <span class="ml-3 text-white text-xs market-heat-header-font">|</span>
        <p class="ml-0.5 text-white text-xs market-heat-header-font">
          {{
            signedInUser.country.countryShow > 1 ? "Select Country" : "Country"
          }}
        </p>
      </div>

      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex ml-1 mr-1 h-7 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.country.countryList.includes('us') ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            market-heat-header-font
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/Us_flag.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">
            United States
          </p>
        </div>

        <div class="leading-6 market-heat-header-font">
          <label for="toogleA" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleA"
                :checked="usaValue"
                :disabled="usaValue"
                @change="toggleUSAValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleA">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>
          </label>
        </div>
      </span>

      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex h-7 ml-1 mr-1 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.country.countryList.includes('ca') ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/CanadaFlag.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">Canada</p>
        </div>
        <div class="leading-6">
          <label for="toogleB" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleB"
                :checked="canadaValue"
                :disabled="canadaValue"
                @change="toggleCANValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleB">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>
            <!-- <div class="relative">
              <input id="toogleB" type="checkbox" :checked="canadaValue" :disabled="canadaValue"
                @change="toggleCANValue" class="sr-only" />

              <div class="w-8  p-[6px] -ml-2 h-4 bg-gray-700 rounded-full shadow-inner topToggle"></div>
              <div
                class="dot absolute w-3 h-3 bg-white rounded-full shadow -left-[5px] top-[2px] transition topToggleBtn">
              </div>
            </div> -->
          </label>
        </div>
      </span>
      <span
        style="width: 15%; border: 0.3px solid #7582783d"
        class="flex h-7 ml-1 rounded bg-[#1E2224] items-center justify-between px-1"
        :class="
          signedInUser.country.countryList.includes('oz') ? '' : 'disabled'
        "
      >
        <div class="flex items-center">
          <img
            class="h-3.5 market-heat-header-font"
            :src="require('../../assets/australiaicon.png')"
            alt="flag"
          />
          <p class="ml-1 text-white text-xs market-heat-header-font">
            Australia
          </p>
        </div>
        <div class="leading-6">
          <label for="toogleC" class="flex items-center cursor-pointer">
            <div class="switch flex items-center">
              <input
                type="checkbox"
                name="switch"
                class="sr-only switch-checkbox"
                id="toogleC"
                :checked="australiaValue"
                :disabled="australiaValue"
                @change="toggleAUSValue"
              />
              <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleC">
                <span class="switch-text"></span>
                <span class="switch-dot"></span>
              </label>
            </div>

            <!-- old toggle -->

            <!-- <div class="relative">
              <input id="toogleC" type="checkbox" :checked="australiaValue" class="sr-only" :disabled="australiaValue"
                @change="toggleAUSValue" />

              <div class="w-8 p-[6px] -ml-2 h-4 bg-gray-700 rounded-full shadow-inner topToggle"></div>
              <div
                class="dot absolute w-3 h-3 bg-white rounded-full shadow -left-[5px] top-[2px] transition topToggleBtn">
              </div>
            </div> -->
          </label>
        </div>
      </span>
      <!-- <div>
        <button
          @click="showCalendar()"
          :disabled="!tableData.length > 0"
          class="flex text-[#898d96] justify-center items-center h-full w-full cursor-pointer ml-3"
        >
        Go to Date
              </button>
        <div
          v-if="showDatePicker"
          class="absolute mt-[1.5vh] z-[105] flex flex-col"
        >
          <button
            @click="handleFocusOut()"
            class="text-gray-200 flex justify-end text-right -mt-6"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <DatePicker v-model="selectedDate" color="green"  :available-dates="disableDate()" is-dark>
          </DatePicker>
        </div>
      </div> -->
    </div>

    <div class="mr-2 flex items-center">
      <button
        @click="changeYeartoback()"
        :class="[selectedActiveYearStoreMH <= 2000 ? 'hidden' : '',moreData?'pointer-events-none':'']"
        :disabled="selectedActiveYearStoreMH <= 2000 || getTableLoader ? true : false"
      > 
        <font-awesome-icon
          style="color: #e5e9ec"
          :icon="['fas', 'chevron-left']"
          class="market-heat-header-font text-xs"
        />
      </button>

      <span
        style="color: white"
        class="market-heat-header-font text-xs text-white mt-1 px-2"
      >
        {{ selectedActiveYearStoreMH }}
      </span>

      <button
        @click="changeYeartofront()"
        :class="[selectedActiveYearStoreMH == currentYear ? 'hidden' : '',moreData?'pointer-events-none':'']"
        :disabled="selectedActiveYearStoreMH == currentYear || getTableLoader ? true : false"
      >
        <font-awesome-icon
          style="color: #e5e9ec"
          :icon="['fas', 'chevron-right']"
          class="market-heat-header-font text-xs"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "MarketHeatTopBar",
  props: {
    hideDatePicker: {
      type: Boolean,
      default: false,
    },
    moreData:{
      type:Boolean,
      default:false
    },
    tableData:{
      type:Array,
      default:[]
    }
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      usaValue: false,
      canadaValue: false,
      australiaValue: false,
      currentYear: null,
      showDatePicker: false,
      selectedDate: null,
      isChange:false,
    };
  },
  computed: {
    ...mapGetters([
      "getActiveCountry",
      "selectedActiveYearStore",
      "selectedActiveYearStoreMH",
      "signedInUser",
      "getTableLoader"
    ]),
  },
  methods: {
    showCalendar() {
      console.log("handleFocusOut");
      this.showDatePicker = true;
    },
    handleFocusOut() {
      this.showDatePicker = false;
    },

    disableDate(){
      const endDateData = this.tableData[0]
      const startDateData = this.tableData[this.tableData?.length - 1]
      const  lastDataDate = endDateData?.date
      const  startDataDate = startDateData?.date
      const endYear = lastDataDate?.split("-")[0]
      const startYear = startDataDate?.split("-")[0]
      const endMonth = lastDataDate?.split("-")[1]
      const startMonth = startDataDate?.split("-")[1]
      const endDay = lastDataDate?.split("-")[2]
      const startDay = startDataDate?.split("-")[2]
      return {start: new Date(startYear, startMonth[1]-1, startDay),end: new Date(endYear, endMonth[1]-1, endDay)}
    },

    disabledYears(date) {
      console.log("date===>",date)
      const year = date.getFullYear();
    return year < 2023;
    },

    changeYeartoback() {
      this.$store.commit("CHANGE_YEAR_MH", this.selectedActiveYearStoreMH - 1);
      this.isChange = true
    },
    changeYeartofront() {
      this.$store.commit("CHANGE_YEAR_MH", this.selectedActiveYearStoreMH + 1);
      this.isChange = true
    },
    toggleUSAValue() {
      this.usaValue = !this.usaValue;
      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "us");
      localStorage.setItem("activeCountry", "us");
    },
    toggleAUSValue() {
      this.australiaValue = !this.australiaValue;

      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "oz");
      localStorage.setItem("activeCountry", "oz");
    },
    toggleCANValue() {
      this.canadaValue = !this.canadaValue;
      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "ca");
      localStorage.setItem("activeCountry", "ca");
    },

    toggleDataStream() {
      console.log("toggle stream function");
      if (this.signedInUser.role == "plus") {
        console.log("plus user");
        if (this.signedInUser.dataStreamCountry[0].country == "us") {
          console.log("USA TRIGGERED");
          this.toggleUSAValue();
        }
        if (this.signedInUser.dataStreamCountry[0].country == "ca") {
          console.log("CAN TRIGGERED");
          this.toggleCANValue();
        }
        if (this.signedInUser.dataStreamCountry[0].country == "oz") {
          console.log("AUS TRIGGERED");
          this.toggleAUSValue();
        }
      } else {
        this.australiaValue = true;
      }
    },
  },
  watch: {
    hideDatePicker(n, v) {
      if (n) {
        console.log("toggle", n);
        this.showDatePicker = false;
      } else {
        console.log("hide");
      }
    },
    tableData(){
      console.log("data==>",this.tableData)
    },
    selectedDate(v) {
      console.log("v==>",v);
      this.$emit("changeDate", v);
    },
    usaValue() {
      if (this.usaValue) {
        this.canadaValue = false;
        this.australiaValue = false;
      }
    },
    canadaValue() {
      if (this.canadaValue) {
        this.usaValue = false;
        this.australiaValue = false;
      }
    },
    australiaValue() {
      if (this.australiaValue) {
        this.usaValue = false;
        this.canadaValue = false;
      }
    },
    getActiveCountry() {
      //getMarketTableData
      console.log("changeMarket");
      this.$emit("changeMarket");
    },
    selectedActiveYearStoreMH() {
      if(this.isChange){
        console.log("store call")
        this.isChange=false
        this.$emit("highlightYear");
      }
    },
  },
  mounted() {
    if (this.getActiveCountry === "us") {
      this.toggleUSAValue();
    }
    if (this.getActiveCountry === "oz") {
      this.toggleAUSValue();
    }
    if (this.getActiveCountry === "ca") {
      this.toggleCANValue();
    }

    let date = new Date();
    this.currentYear = date.getFullYear();
    console.log(this.currentYear);
    if (this.signedInUser.dataStreamCountry?.length > 0) {
      console.log(
        "mounted data stream ===================>",
        this.signedInUser.dataStreamCountry
      );
      this.toggleDataStream();
    }

    // if(localStorage.getItem('activeCountry') == 'us'){
    //   this.usaValue = true
    // }
    // if(localStorage.getItem('activeCountry') == 'oz'){
    //   this.australiaValue = true
    // }
    // if(localStorage.getItem('activeCountry') == 'ca'){
    //   this.canadaValue = true
    // }
  },
  created() {
    //this.$store.commit("CHANGE_YEAR_MH", new Date().getFullYear());
  },
};
</script>
<style scoped>
.toggle {
  border: 0.3px solid gray;
}

@media screen and (max-width: 2685px) {
  .Market-Head {
    font-size: 1.6vw;
  }

  .market-heat-header-font {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1440px) {
  .Market-Head {
    font-size: 1.3rem;
  }

  .market-heat-header-font {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 1250px) {
  .Market-Head {
    font-size: 0.9rem;
  }

  .market-heat-header-font {
    font-size: 0.5rem;
  }

  .topToggle {
    width: 20px;
    height: 5px;
  }

  .topToggleBtn {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 1060px) {
  .Market-Head {
    font-size: 0.8rem;
  }

  .market-heat-header-font {
    font-size: 0.3rem;
  }

  .topToggle {
    width: 19px;
    height: 1px;
  }

  .topToggleBtn {
    width: 7px;
    height: 7px;
  }
}
</style>
