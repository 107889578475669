<template>
  <div class="pl-2">
    <svg
      v-if="order === -1"
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 16.1429L0.602886 9.39285L8.39711 9.39285L4.5 16.1429Z"
        fill="#D9D9D9"
      />
      <path
        d="M4.5 -7.86805e-07L8.39711 5.78571L0.602887 5.78571L4.5 -7.86805e-07Z"
        fill="#D9D9D9"
        fill-opacity="0.3"
      />
    </svg>

    <svg
      v-if="order === 1"
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 0L8.39711 6.75H0.602886L4.5 0Z" fill="#D9D9D9" />
      <path
        d="M4.5 16.1429L0.602886 10.3571L8.39711 10.3571L4.5 16.1429Z"
        fill="#D9D9D9"
        fill-opacity="0.3"
      />
    </svg>

  </div>
</template>
<script>
export default {
  name: "TableSortIcon",
  props: {
    order: {
      type: Number,
      default: 0,
    },
  },
};
</script>
