<template>
  <div class="w-full h-full">
    
    <!-- add to watchlist  -->
    <tailwind-modal :show-modal="showWatchlistModal" v-if="showWatchlistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  gap: 3vh;
                  justify-content: space-between;
                  height: 20vh;
                  width: 25vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;
                    flex-grow: 8;
                    gap: 1vh;
                    height: 80%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Save to watchlist</h1>
                  </div>

                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2vh;
                      height: 100%;
                    ">
                    <select class="text-[black] w-8/12 h-[3vh] focus:outline-none" @change="selectWatcRowIndex">
                      <option value="">
                        <span>--Select Watchlist--</span>
                      </option>
                      <option v-for="(names, i) in watchlistData" :key="i" :value="i">
                        {{ names.name }}
                      </option>
                    </select>
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false"
                  :class="isResponseError?'text-[#FF0A00]':'text-[#02BD2D]'"
                    class="w-full flex items-center justify-center h-[100%]">
                    {{ showModalMessage }}
                  </div>
                </div>

                <div class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[20%]">
                  <button class="cancel-button w-[20%] h-full text-[.6vw]" @click="handleCloseSaveWatchlist">
                    Cancel
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="SaveWatchlist"
                  :disabled="selectwatchIndex == null">
                    Save
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="openCreateWatchlistModal">
                    Create
                  </button>
                </div>

              </div>
    </tailwind-modal>

    <!-- create watchlist  -->
    <tailwind-modal :show-modal="createWatclistModal" v-if="createWatclistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 20vh;
                  width: 25vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;

                    flex-grow: 8;
                    gap: 1vh;
                    height: 70%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Create watchlist</h1>
                  </div>
                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      gap: 2vh;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    ">
                    <input type="text" placeholder="create watchlist"
                      class="p-2 rounded text-[black] w-9/12 focus:outline-none" v-model="newWatchName"
                      v-on:keyup.enter="handleCreateWatchlist" />
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false"
                  :class="isResponseError?'text-[#FF0A00]':'text-[#02BD2D]'"
                    class="w-full pb-2 flex items-center justify-center h-[100%]">
                    {{ showModalMessage }}
                  </div>
                </div>

                <div v-if="!showModalMessage" class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[30%]">
                  <button class="cancel-button w-[20%] h-fit py-1 text-[.6vw]" @click="createWatclistModal = false">
                    Cancel
                  </button>
                  <button class="ok-button w-[20%] h-fit py-1 text-[.6vw]" @click="handleCreateWatchlist"
                    :disabled="newWatchName == ''">
                    Save
                  </button>
                </div>

              </div>
            </tailwind-modal>


    <div class="w-full h-[25%] whitespace-nowrap flex items-center space-x-4" ref="chartPageContainer">
        <div class="mr-2">
            <span class="text-white text-[1.6vw] Inter-Bold flex items-center Market-Head">
                Stock Alert
            </span>
        </div>
        <div class="w-[18vw] ml-2 relative">
            <!-- search stock -->
            <div class="w-full flex items-center relative">
                <input type="text" id="findStock" placeholder="Search Stock..."
                class="p-2 border-box bg-[#1E2224] block w-full h-full text-[.7vw] text-gray-200 border border-[#7582783d] shadow-inner rounded-[3px] leading-tight focus:outline-none focus:border-gray-500"
                @keydown.up="up"
                @keydown.down="down"
                @keydown.esc="closeList"
                @keydown.enter="selectItem"
                v-model="selectedSymbol"
                @input="debounce(searchStock($event.target.value), 400)" />
                
                <span v-if="symbolList.length" class="absolute bg-[#1E2224] right-2">
                    <svg @click.prevent="closeList"   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="gray" class="cursor-pointer w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
                <span class="absolute right-2 bg-[#1E2224]" v-else>
                    <svg stroke="gray" viewBox="0 0 10 11" fill="none" class="cursor-pointer text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.56739 4.30769C6.56739 6.02491 5.23719 7.36538 3.6587 7.36538C2.0802 7.36538 0.75 6.02491 0.75 4.30769C0.75 2.59048 2.0802 1.25 3.6587 1.25C5.23719 1.25 6.56739 2.59048 6.56739 4.30769Z" stroke="currentcolor" stroke-width="1.5"/>
                    <path d="M6.31967 7.07711L8.31532 9.15403" stroke="currentcolor" stroke-width="1.5" stroke-linecap="square"/>
                </svg>
            </span>

                
            </div>
            <div class="absolute top-10">
                <SymbolListComponent v-if="symbolList.length" :symbolInputName="selectedSymbol"  :handleTop="scrollToItem" ref="symbolListRef" :symbol-list="symbolList" v-on:symbolSelected="symbolSelected" :isAbsolute="true" :selectedIndex="selectedIndex" :isChart="true" />
            </div>


        </div>
        <div class="w-[10vw] dropdown flex">
            <!-- select watchlist... -->
            <button
              class="bg-[#1E2224] p-[.6vh] text-[.7vw] dropdown-toggle w-full h-full text-[#9ea8af] border transition duration-150 ease-in-out border-[#7582783d] shadow-inner rounded-[3px] leading-tight focus:outline-none focus:border-gray-500"
              type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="flex justify-between w-full h-full border-box">
                <span> {{ selectedWatchListName }} </span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[1vw] h-[2vh]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <ul
              class="dd-menu dropdown-menu w-[12vw] absolute hidden h-[12vh] overflow-y-auto z-50 float-left list-none text-left rounded-[3px] shadow-lg mt-1 m-0 bg-clip-padding border-none"
              aria-labelledby="dropdownMenuButton1">
              <li v-for="item in watchListApi_Data" :key="item.id" class="text-white px-2 hover:hover:bg-[#2E2E2F] bg-[#1E2224] text-sm p-1 cursor-pointer"
                @click="handleSelectedWatchList(item)">
                {{ item.name }} ({{ item.symbolList.length }})
              </li>
            </ul>

        </div>
        <div class="w-[10vw] dropdown flex">
            <!-- select stock... -->
            <button
            :class="`${Object.keys(selectedWatchList).length === 0 ? 'pointer-event-none cursor-default opacity-40' : 'focus:border-gray-500 cursor-pointer'}`"
              class="bg-[#1E2224] p-[.6vh] text-[.7vw] dropdown-toggle w-full h-full text-[#9ea8af] border transition duration-150 ease-in-out border-[#7582783d] shadow-inner rounded-[3px] leading-tight focus:outline-none "
              type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="flex justify-between w-full h-full border-box">
                <span> {{ selectedStockName }} </span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[1vw] h-[2vh]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <ul
              class="dd-menu dropdown-menu w-[12vw] absolute hidden max-h-[12vh] overflow-y-auto z-50 float-left list-none text-left rounded-[3px] shadow-lg mt-1 m-0 bg-clip-padding border-none"
              aria-labelledby="dropdownMenuButton1">
              <li v-for="(item, index) in selectedWatchList.symbolList" :key="index" class="text-white hover:hover:bg-[#2E2E2F] bg-[#1E2224] text-sm p-1 px-2 cursor-pointer"
                @click="handleSelectedStock(item)">
                {{ item.name }}
              </li>
            </ul>
        </div>
        <div class="w-[1vw] ml-2" title="refresh selected stock">
            <!-- refresh -->
            <span class="cursor-pointer" @click="handleRefreshSelections">
                <svg class="text-[#2E3238] hover:text-gray-700 w-5 h-5" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.75 7.5C13.75 11.25 11.0063 13.75 7.62125 13.75C4.87 13.75 2.65 12.2906 1.875 10M1.25 7.5C1.25 3.75 3.99375 1.25 7.37875 1.25C10.1306 1.25 12.35 2.70938 13.125 5" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.625 4.375L13.125 5L13.75 2.5M4.375 10.625L1.875 10L1.25 12.5L4.375 10.625Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


            </span>

        </div>
        <div class="w-[8.5vw] ml-2">
            <!-- Add to watchlist -->
            <div :class="selectedSymbol?'':'pointer-events-none opacity-60'" @click="handleAddToWatchList" class="greenBtn text-[13px] py-1 w-full text-[#02BD2D] border border-[#02BD2D] rounded-[3px] text-center cursor-pointer">
                <span>+ ADD TO WATCHLIST</span>
            </div>

        </div>
    </div>
    <div class="w-full h-[73%] relative" style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 3px;">
      <div class="w-full h-full apexChartdiv vld-parent" ref="areaChartHeightDiv">
        <!-- <AreaChartLoader :showChartLoader="chartLoader" />
        
        <AreaChart :chartHeight="chartHeight" @value-emitted="onValueEmitted" :isStockAlert="true" :is-index-chart="false" :data-chart="symbolChartData" :symbol-name="selectedChartSymbol" /> -->

        <AreaChartLoader :showChartLoader="chartLoader" />

        <AreaChart v-if="updateChart" @timeValue="handleTimeValue" :isStockAlert="true" :is-index-chart="false" :showTrend="true" class="-mt-2 py-1" :chartHeight="chartHeight" :stockAlertAreaChart="true"
        :data-chart="symbolChartData" :symbol-name="selectedChartSymbol" @value-emitted="onValueEmitted"/>

     </div>
     <div v-if="showDummyDiv" class="absolute w-7 h-4 right-1 bottom-2 bg-[#151515]"></div>
    </div>
    </div>
 </template>
 
 <script>
import debounce from "@/helper/debounce";
import chartApi from "@/api/charts";
import SymbolListComponent from "@/components/popups/SymbolList";
import { mapActions, mapGetters } from "vuex";
import GetSymbols from "@/api/charts";
import AreaChart from "../charts/AreaChart.vue";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import TailwindModal from "@/components/popups/TailwindModal";
import moment from 'moment'
import watchListApi from "@/api/watchListApi";
 
 export default {
    name: "StockHeader",
    components:{
      SymbolListComponent,
      AreaChart,
      AreaChartLoader,
      TailwindModal
    },
    data() {
        return {
          isResponseError:false,
            showDummyDiv:false,
            newWatchName:"",
            createWatclistModal:false,
            showModalMessage:null,
            watchlistData:[],
            selectedIndex: 0,
            selectedSymbol:"",
            showModalLoader:false,
            showWatchlistModal:false,
            symbolList: [],
            selectedWatchList: {},
            selectedSymbolDetail:{},
            selectwatchIndex: null,
            symbolChartData:[],
            selectedChartSymbol:"",
            exchange:"ASX",
            selectedWatchListName: "Select Watchlist...",
            selectedStockName: "Select Stock...",
            chartLoader:false,
            chartHeight:0
        }
    },    


    methods: {
    ...mapActions(["fetchAllWatchListData"]),

    handleTimeValue(t){
      console.log("t==>",t)
      if(t=="12" || t == "YTD"){
        this.showDummyDiv = true
      }else{
        this.showDummyDiv = false
      }
    },
    
    openCreateWatchlistModal(){
      this.showWatchlistModal = false
      this.createWatclistModal = true
    },

    async handleCreateWatchlist(){
      let date = moment().format("MMMM Do YYYY, h:mm a");
      console.log("create watchlist",this.newWatchName)
      try{
        this.showModalLoader = true;
        const payload = {
          name: this.newWatchName,
          symbolList: [this.selectedSymbolDetail],
          date: date,
        };
        const res = await watchListApi.createWatchList(payload);
        if (res.data.success) {
          this.showModalLoader = false;
          this.showModalMessage = "A new Watchlist has been created for your selected stocks.";
          setTimeout(() => {
            this.createWatclistModal = false;
            this.showModalMessage = null;
          }, 3000);
          await this.fetchAllWatchListData()
        }else{
          this.showModalLoader = false;
          this.isResponseError = true
          this.showModalMessage = res?.data?.message;
          setTimeout(() => {
            this.createWatclistModal = false;
            this.showModalMessage = null;
            this.isResponseError = false

          }, 3000);
        }

      }catch(err){
        this.showModalLoader = false;
        this.isResponseError = true
        this.showModalMessage = "Server Error";
        setTimeout(() => {
          this.isResponseError = false
          this.createWatclistModal = false;
        this.showModalMessage = null;
        }, 3000);
      }
    },
    up(){
      if(this.selectedIndex == 0){
        return;
      }
      this.selectedIndex -= 1
      this.scrollToItem()
      
    },
    down(){
      if(this.selectedIndex >= this.symbolList.length - 1){
        return;
      }
      this.selectedIndex += 1
      this.scrollToItem()
    },
    closeList(){
      this.symbolList = []
    },
    async handleAddToWatchList(){
      this.watchlistData = this.watchListApi_Data
      this.showModalMessage = null
      this.showWatchlistModal = true
      console.log("Add to watchlist",this.watchListApi_Data);
    },
    handleCloseSaveWatchlist(){
      this.showWatchlistModal = false
    },
    async SaveWatchlist() {
      this.showModalLoader = true;

      let date = moment().format("MMMM Do YYYY, h:mm a");
      try {
        const payload = {
          watchId: this.watchlistData[this.selectwatchIndex]._id,
          symbolList: [this.selectedSymbolDetail],
          updatedDate: date,
        };
        console.log("payload",payload)
        const res = await watchListApi.updateWatchListRecord(payload);
        if (res.data.success) {
          this.showModalLoader = false;
          this.showModalMessage = "Stock has been saved to your watchlist";
          setTimeout(() => {
            this.showWatchlistModal = false;
             this.showModalMessage = null;
          }, 3000);
        }else{
          this.showModalLoader = false;
          this.isResponseError = true
          this.showModalMessage = res?.data?.message;
          setTimeout(() => {
          this.isResponseError =false
            this.showWatchlistModal = false;
            this.showModalMessage = null;
          }, 3000);
        }
      } catch (err) {
        this.showModalLoader = false;
        this.isResponseError = true
        this.showModalMessage = "Server Error";
        setTimeout(() => {
          this.isResponseError = false
          this.showWatchlistModal = false;
        this.showModalMessage = null;
        }, 3000);
      }

    },
    selectWatcRowIndex(e) {
      console.log(e.target.value);
      this.selectwatchIndex = e.target.value;
    },
    selectItem(){
      let data = this.symbolList[this.selectedIndex]
      this.selectedSymbol = data.name
      this.symbolSelected(data)
      this.selectedIndex = 0
      this.symbolList = []
      this.exchange=data.id
    },
    onValueEmitted(item){

    },
    async symbolSelected(value,index) {
      console.log("selected==>",value,index)
      this.chartLoader = true      
      this.chartHeight = 0
      this.chartHeight = this.$refs.areaChartHeightDiv.clientHeight - 40;
      this.selectedSymbol = value.name
      this.selectedSymbolDetail = value
      this.selectedChartSymbol = value.name
      this.selectedIndex = index
      this.symbolChartData = []
      this.symbolList = [];
      document.getElementById("findStock").value = "";
      console.log("selected symbol",value);
      this.$store.commit('STOCK_ALERT_SELECTED_TABLE_COLUMN', value?.name)
      this.$store.commit('STOCK_ALERT_SELECTED_SYMBOL_DETAIL', value)
      let symbolDataList = await GetSymbols.getSymbol(
          value.name,
          "D",
          value.id,
          5400
          );
      symbolDataList = symbolDataList?.data ? symbolDataList?.data : [];
      this.symbolChartData = symbolDataList;
      this.chartLoader = false
      console.log("Height==>", this.chartHeight);

      console.log("symbole data list", this.symbolChartData);
      





      // await this.getChartData(value.name, this.getTimeFrame,value.id);
      // this.$store.dispatch("updateChartConfig",{
      //   selectedSymbol:this.selectedSymbol
      // })
    },
    debounce,
    searchStock(value) {
      value = value.trim();
      value = value.replace(/\s+/g, ' ').trim();
      this.stockName = value
      if (value && value.length) {
        chartApi.getListOfSymbolsStartWith(value).then((res) => {
          this.symbolNameInput = value;
          this.symbolList = res;
        });
      }
      if (value.length === 0) {
        this.symbolList = [];
      }
    },
    scrollToItem(){
      if(this.$refs.symbolListRef && this.$refs.symbolListRef.$refs.optionsList)
        this.$refs.symbolListRef.$refs.optionsList.scrollTop = this.selectedIndex * this.itemHeight
    },
    handleSelectedWatchList(value) {
      this.selectedWatchList = value;
      this.selectedWatchListName = value.name;
      this.selectedStockName = "Select Stock";
    },
    async handleSelectedStock(value) {
      this.selectedStockName = value?.name;
      await this.symbolSelected(value)
    },
    handleRefreshSelections() {
      this.selectedWatchListName = "Select Watchlist";
      this.selectedStockName = "Select Stock";
      this.selectedWatchList={}
    },


    },

    computed:{
    ...mapGetters(["watchListApi_Data","getStockAlertChartDate"]),
    updateChart() {
      let updateChartDecision = this.symbolChartData?.length > 0;
      console.log('updateChartDecision',updateChartDecision)
      return updateChartDecision;
    },

    },

    async mounted(){
      
      await this.fetchAllWatchListData()
      // const obj = {companyName:"BHP Group Ltd",id:"ASX",name:"BHP.au"}
      const obj = {companyName:"Alphabet Inc Class A",id:"Nasdaq",name:"GOOGL"}
      await this.symbolSelected(obj)
    },

    watch:{
    
     
    }


 }
 </script>
 
 <style scoped>
 .greenBtn:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}
.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
  /* width: 20px !important; */
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

@media only screen and (max-width: 1550px) {
  .greenBtn{
    font-size: 10px;
  }
}



 </style>