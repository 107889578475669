import { render, staticRenderFns } from "./AccountCreated.vue?vue&type=template&id=5828acee&scoped=true"
import script from "./AccountCreated.vue?vue&type=script&lang=js"
export * from "./AccountCreated.vue?vue&type=script&lang=js"
import style0 from "./AccountCreated.vue?vue&type=style&index=0&id=5828acee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5828acee",
  null
  
)

export default component.exports