<template>
    <tailwind-modal
      :show-modal="removeSymbol"
      v-on:change="toggleModal"
      :showHeader="false"
    >
      <div
        class="min-w-[38rem] px-10 py-4"
        style="
          border: 0.3px solid rgba(2, 189, 45, 0.3);
          box-sizing: border-box;
          scrollbar-color: red;
          border-radius: 3px;
          background-color: #151515;
        "
      >
        <!-- <div class="flex pb-4">Delete?</div> -->
  
        <!-- <div class="pb-10">
          <input
            type="text"
            class="p-4 flex bg-transparent block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
            placeholder="Please Enter Name."
            v-model="watchListName"
          />
          <span v-if="showError" class="text-[16px] text-[#FF0A00] text-center">{{error}}</span>
        </div> -->
        <span class="text-lg">Are you sure?</span>

  
        <div class="buttons flex justify-end">
         
          <button
      
        type="submit"
        @click="toggleModal"
        class="cancel-button px-4 rounded mt-6 mr-4 font-semibold py-1 text-sm"
      >
        Cancel
      </button>
  
          <!-- <button
            type="submit"
            @click="handleRemoveSymbol"
            class=".ok-button px-8 mt-6 py-2 text-sm"
          >
            Remove
          </button> -->
          <button
       
        type="submit"
        @click="handleRemoveSymbol"
        class="ok-button px-4 mt-6 font-semibold py-1 text-sm"
      >
        Remove
      </button>
        </div>
      </div>
    </tailwind-modal>
  </template>
  <script>
  import TailwindModal from "@/components/popups/TailwindModal";
  import watchListApi from "../../../api/watchListApi";
  export default {
    name: "RemoveWatchlistModal",
    components: { TailwindModal },
    data() {
      return {
        watchListName: "",
        error: "",
        showError: false,
      };
    },
    props: {
        removeSymbol: {
        type: Boolean,
      
      },
      userId: {
        type: String,
        default: "",
      },
      watchId: {
        type: String,
        default: "",
      },
      userName: {
        type: String,
        default: "",
      },
    },
    methods: {
      toggleModal() {
        this.$emit("close");
      },
      showErrorMsg(error ) {
        this.showError = true
        this.error = error
        setTimeout( () => {
          console.log("showErrorMsg timeout")
          this.showError = false
          this.error = ""
        },2000)
      },
    
      handleRemoveSymbol: async function () {
        this.$emit("removeSymbol")
        
    //   if (this.watchId && this.userId) {
    //     try {
    //       const payload = {
    //         userId: this.userId,
    //         watchId: this.watchId,
    //       };
    //       const res = await watchListApi.deleteWatchList(payload);
    //       console.log(res);
    //       if(!res.data.isError){
    //         this.$emit("close");
    //         let ind = this.watchListApi_Data.length == 1 ? 0 : this.watchListApi_Data.length - 2
    //         // this.$emit("fetchWatchList")
            
    //       }
        //   console.log("this.watchListApi_Data.length", this.watchListApi_Data.length);
          // if (!res.data.isError) {

        //   let ind = this.watchListApi_Data.length == 1 ? 0 : this.watchListApi_Data.length - 2
        //   this.fetchwatchListApi_Data(ind);
        //   this.removeWatchList=false
          // }
    //     } catch (err) {
    //       console.log("api call failed");
    //     }
    //   } else {
    //     console.log("no dates selected");
    //   }
    },

    },
    mounted(){
        console.log(this.removeWatchList)
    }
  };
  </script>
  <style scoped>
.ok-button {
  border: 0.6px solid #FF0A00;
  border-radius: 3px;
  color: #FF0A00;
  text-transform: uppercase;
}
.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(255, 57, 57, 0.24) 0%,
    rgba(255, 70, 64, 0) 50%,
    rgba(248, 55, 42, 0.24) 100%
  );
}
.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}
.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}


</style>
  