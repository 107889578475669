<template>
    <div class="w-full h-full apexChartdiv vld-parent" ref="areaChartHeightDiv">
      <AreaChart :chartHeight="chartHeight" :isStockAlert="true" :is-index-chart="false" :data-chart="symbolDataList" :symbol-name="selectedSymbol" />
    </div>
 </template>
 
 <script>
  import AreaChart from "../charts/AreaChart.vue";

 
 export default {
    name: "StockAreaChart",

    data() {
        return {
         chartHeight: this.$refs.areaChartHeightDiv?.clientHeight
        ? this.$refs.areaChartHeightDiv?.clientHeight - 60
        : 0,
        selectedSymbol:"bhp.au",
        symbolDataList:[]
        }
    },

    components:{
        AreaChart
    }
    
 }
 </script>
 
 <style>
 
 </style>