<template>
  <div class="">
    <div class="text-white text-sm ">

      <!-- stock list start -->

      <div v-if="fileType == SelectfileType[0]">
        <div class="pl-4 " v-for="(item,itemindex) in title.folder" :key="itemindex">
          <AccordionTree  :title="item.name" type="main-folder" :fileType="SelectfileType[0]" 
             :folderType = "item.name"  :mainObj="title" :MainListIndex = "itemindex">

            <AccordionTree v-for="(subFolder,subFolderindex) in item.subFolders" :key="subFolderindex"
              :title="subFolder.name" :mainObj="title" type="sub-folder" :folderType = "item.name"  :MainSubListIndex = "subFolderindex">

              <AccordionTree v-for="(subChild,subChildindex) in subFolder.child" :key="subChildindex"
              :title="subChild.name"  :metaData="subChild.metaData" type='child-folder'
              :textColor="textColor" v-on:subfile="handleClickeFile"

              

              :mainObj="title" :folderType = "item.name" :MainSubListIndex = "subFolderindex" :subChildListIndex="subChildindex" 

              v-on:click="selectFolder(subChild.name, subChildindex)" :image="image"
              @StockListSelectedStock=StockListSelectedStock
              
              :style="[(stockList.stockListIndex == subChildindex) && (stockList.stockListText == subChild.name) ? {border:` 0.3px solid #7582783d`}:{}]"
              >
              
                

                <p v-for="(subFile,subFileindex) in subChild.files.fileName" :key="subFileindex"
                  @click="handleSelectedFile(subFile)" :class="handleClickedClass()" type='files'>
                  <font-awesome-icon icon="fa-solid fa-bars" class="px-1" />
                  {{ subFile }}
                </p>
              </AccordionTree>
            </AccordionTree>
          </AccordionTree>
        </div>
      </div>



      <!-- stock list end -->



      <div v-if="fileType == SelectfileType[1]">
        <div class="pl-4 " v-for="(item,itemindex) in title.folder" :key="itemindex">
          <AccordionTree :title="item.name" type="main-folder" :fileType="SelectfileType[1]">
            <AccordionTree v-for="(subFolder,subFolderindex) in item.subFolders" :key="subFolderindex"
              :title="subFolder.name" type="sub-folder">
              <AccordionTree v-for="(subChild,subChildindex) in subFolder.child" :key="subChildindex"
                :title="subChild.name" type='child-folder' :textColor="textColor"
                v-on:click="selectFolder(subChild.name)">
                <p v-for="(subFile,subFileindex) in subChild.files" :key="subFileindex"
                  @click="handleSelectedFile(subFile)" :class="handleClickedClass()" type='files'>
                  <font-awesome-icon icon="fa-solid fa-bars" class="px-1" />
                  {{ subFile }}
                </p>
              </AccordionTree>

            </AccordionTree>
          </AccordionTree>
        </div>
      </div>


      <div v-if="fileType == SelectfileType[2]" style="display:flex; flex-direction: column; gap:1vh;">

        <div class=" flex justify-between text-[blue]"
          :style="[folderIndex === itemindex ? {border:` 0.3px solid #7582783d`, boxSizing :'border-box'}:{}]"
          v-for="(item,itemindex) in this.searchFunc" :key="itemindex">

          <!-- <div class="flex justify-between"> -->

          <AccordionTree :stockSearchItemIndex="itemindex" :title="item.name" type="main-folder"
            :selectFolder="selectFolder(item.name)" :fileType="SelectfileType[2]" :iconColor="iconColor"
            :dotsimage="dotsimage" :selectedfolderName="selectedfolderName" @Acc_FolderName="Acc_FolderName">


            <p v-for="(subFile,subFileindex) in item.files.fileName" :key="subFileindex"
              @click="handleSelectedFile(subFile) + selectedFileIndex(subFileindex)" type='files'
              class="text-white flex pt-[.3vh] text-[.7vw] justify-between w-[100%] cursor-pointer hover:text-[#00C92E]">
              <!-- `hover:bg-[${JSON.parse(JSON.stringify(item.filecolor[subFileindex]))}]` -->
              <span class="flex items-center gap-[.5vw] w-[90%] overflow-x-hidden">
                <!-- <img :src="colorrr" class="w-[.5vw] bg-[blue]" /> -->
                <svg xmlns="http://www.w3.org/2000/svg" stroke='#151515' :fill='item.filecolor[subFileindex]'
                  viewBox="0 0 512 512" class="w-[.5vw]">
                  <path
                    d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
                </svg>
                <!-- <font-awesome-icon icon="fa-solid fa-circle" class="w-2 h-2 p-1 self-center "  id="colorr" />  -->
                {{ subFile }}


                <!-- {{JSON.stringify( item.filecolor[subFileindex] )}} -->

                <span class="text-white opacity-40 flex ">{{shortdesc}}</span>
              </span>
              <span class="flex  flex-end ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-[1vw]">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>

                <!-- <img :src="dotsimage" class=" w-[.5vw] " style="color: aqua;" /> -->
              </span>
            </p>
          </AccordionTree>



          <div class="dropdown relative flex justify-end items-start order-last"
            @click="selectedFolder(item.name,itemindex)">
            <button
              class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap pt-[.45vh]"
              type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" :class="['w-[1.3vw]', folderIndex === itemindex ? 'text-[#00C92E]':'']">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
              </svg>
              <!-- <img :src="dotsimage" class="w-[.8vw] order-last text-gray-500 cursor-pointer" alt="" /> -->
            </button>

            <ul
              class="dropdown-menu min-w-max absolute hidden bg-black text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
              aria-labelledby="dropdownMenuButton1">
              <li
                class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700 cursor-pointer"
                @click.prevent="RenameFolder(item.name,itemindex)">
                Rename
              </li>
              <li
                class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700 cursor-pointer"
                @click="RemoveFolder(item.name,itemindex)">
                Remove
              </li>
            </ul>
          </div>

          <!-- </div> -->

          <tailwind-modal :show-modal="showconfirmationModel">
            <div class=" px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
              <div class="flex pb-4 font-extrabold  text-lg">ALERT</div>
              <div class="flex self-center text-white">
                Are you sure you want to delete this folder ?
              </div>


              <div class="flex justify-center">
                <button type="submit" @click="closeModal"
                  class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Cancel
                </button>
                <button type="submit" @click="saveChanges(itemindex)"
                  class="remove-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Yes
                </button>
              </div>

            </div>
          </tailwind-modal>

          <tailwind-modal :show-modal="showRenameField">
            <div class=" px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
              <div class="flex pb-4">RENAME FOLDER</div>
              <div class="flex flex-center">
                <input v-model="renamefolderName" placeholder="Rename Folder Name"
                  class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
              </div>


              <div class="flex justify-center">
                <button type="submit" @click="closeModal"
                  class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Cancel
                </button>
                <button type="submit" @click="saveRename(item.name,itemindex)"
                  class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Yes
                </button>
              </div>

            </div>
          </tailwind-modal>

          <tailwind-modal :show-modal="savesearchModel">
            <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
              <div class="flex pb-4">SAVE SEARCH</div>
              <div class="text-white px-4 py-2 flex ">
                <!-- <span class="p-2">Selected Folder</span> -->
                <!-- <span class="px-4 font-bold text-2xl self-center">{{selectedfolderName}}</span> -->
              </div>
              <div class="">
                <div class="grid justify-center">
                  <div class="form-group  mb-2 flex justify-center">
                    <input type="text" placeholder="Select Directory Name" disabled v-model="getfolderName"
                      class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-800 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
                  </div>

                  <div class="flex gap-2">
                    <div class="form-group  mb-2 flex justify-center">
                      <input type="text" placeholder="File Name" v-model="newFileName"
                        class="p-4 bg-[#151515] block w-44 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
                    </div>

                    <div class="dropdown relative order-last w-full flex justify-start items-center">
                      <button
                        class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap h-[40%] w-[12%] bg-transparent rounded-sm"
                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" stroke='#151515'
                          :fill='btn_color' viewBox="0 0 512 512">

                          <path
                            d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
                        </svg>
                      </button>
                      <ul
                        class="dropdown-menu min-w-max absolute hidden bg-gray-800 text-base z-50  py-2 list-none  rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                        aria-labelledby="dropdownMenuButton1">
                        <li v-for="(color,colorindex) in colors" :key='colorindex' :value='colorindex'
                          class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700 cursor-pointer"
                          @click="insertColor(color,colorindex)">
                          <img :src="color" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="h-20 overflow-y-auto">
                    <textarea placeholder="Description"
                      class=" p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500">
                </textarea>
                  </div>
                </div>

              </div>


              <div class="flex justify-end">
                <button type="submit" @click="closeModal"
                  class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Close
                </button>
                <button type="submit" @click="saveSearchChanges"
                  class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                  Save Changes
                </button>
              </div>

            </div>

          </tailwind-modal>

        </div>
      </div>




    </div>
  </div>
</template>

<script>
import TailwindModal from '../popups/TailwindModal.vue';
import AccordionTree from '../utilities/AccordionTree.vue';
export default {

  name: "TreeView",

  components: {
    AccordionTree,
    TailwindModal
  },

  data() {
    return {
      stockList:{
        stockListText:'',
        stockListIndex: -1,
      },

      folderIndex: -1,
      newFileName: '',
      getcolor: '',
      getfolderName: '',
      selectedfolderName: '',
      selectedfolderIndex: '',
      updatedfolderName: '',
      renamefolderName: '',
      showRenameField: false,
      edititem: '',
      deleteItem: '',
      showconfirmationModel: false,
      folderArray: this.title.folder,
      SelectfileType: ['StockList', 'TopFive', 'StockSearch'],
      isOpen: false,

      //   folder: [
      //     {
      //       name: "Stocks Fundamentals Lists",
      //       subFolders: [
      //         {
      //           name: "United States",
      //           child: [
      //             {
      //               name: "NYSE ALL",
      //               files: [],
      //             },
      //             {
      //               name: "NASDAQ ALL",
      //               files: [],
      //             },
      //             {
      //               name: "NASDAQ 100",
      //               files: [],
      //             },
      //             {
      //               name: "Russell 1000",
      //               files: [],
      //             },
      //             {
      //               name: "S & P",
      //               files: [
      //                 "EPS",
      //                 "5Yr EPS GROWTH",
      //                 "P/E RATIO",
      //                 "PEG RATIO",
      //                 "DIVIDEND",
      //                 "ROI",
      //                 "ROE",
      //                 "BOOK VALUE",
      //                 "REV/SHARE",
      //                 "REV/SHARE 5YR",
      //                 "FREE CASHFLOW",
      //               ],
      //             },
      //           ],
      //         },

      //         {
      //           name: "Australia",
      //           child: [],
      //         },

      //         {
      //           name: "Canada",
      //           child: [],
      //         },
      //       ],
      //     },
      //     {
      //       name: "Stocks Technical List",
      //       subFolders: [
      //         {
      //           name: "United States",
      //           child: [],
      //         },

      //         {
      //           name: "Australia",
      //           child: [],
      //         },

      //         {
      //           name: "Canada",
      //           child: [],
      //         },
      //       ],
      //     },
      //   ],
      // },
      clickedValue: "",
      colorrr: 'imageslist/black.svg',
      colors: ['imageslist/lightPurple.svg', 'imageslist/purple.svg', 'imageslist/black.svg', 'imageslist/seeGreen.svg', 'imageslist/lightGreen.svg', 'imageslist/green.svg', 'imageslist/lightBlue.svg', 'imageslist/blue.svg', 'imageslist/darkYellow.svg', 'imageslist/yellow.svg', 'imageslist/red.svg'],
      colorsCode: ['#BB6BD9', '#9B51E0', '#151515', '#6FCF97', '#27AE60', '#219653', '#56CCF2', '#2D9CDB', '#F2994A', '#F2C94C', '#EB5757'],
      selectedColor: '',
      btn_color: '151515'
    };
  },
  watch: {
    isOpen() {

    },
  },

  methods: {
    // new stock list start
    StockListSelectedStock(text, childIndex) {
      console.log('hello')
      this.stockList.stockListIndex = childIndex
      this.stockList.stockListText = text
    },


    // new stock list end
    Acc_FolderName(name, index) {
      


      this.$emit('Acc_FolderName', name)

    },
    selectFolder(value, index) {

      this.$emit('payload')
    },
    selectFolderList(val, index) {

    },
    ClickedClass(value) {
      if (value === "clicked") {
        return "cursor-pointer hover:bg-gray-700";
      } else {
        return "cursor-pointer hover:bg-gray-700";
      }
    },
    handleClickeFile(value) {

      this.$emit('clickedFileData', value)


    },

    handleClickedClass(value) {



      if (value === "clicked") {
        return "cursor-pointer hover:bg-gray-700 ";
      } else {
        if (this.fileType == "TopFive") {
          return "cursor-pointer hover:bg-green-900";
        }
        return "cursor-pointer hover:bg-gray-700";
      }
    },

    handleSelectedFile(value) {


      this.clickedValue = value;
      this.$emit('selectedFile', value, this.fileType)
    },
    selectedFileIndex(index) {


      this.$emit('SelectedFile', index)
    },
    selectedFolder(itemname, itemindex) {

      this.selectedfolderName = itemname
      this.selectedfolderIndex = itemindex
    },
    RenameFolder(itemname, itemindex) {

      this.edititem = itemindex
      this.renamefolderName = itemname

      this.showRenameField = true


    },
    RemoveFolder(itemname, itemindex) {


      this.deleteItem = itemindex


      this.showconfirmationModel = true
    },
    closeModal() {


      this.showconfirmationModel = false
      this.showRenameField = false
      this.$emit('close')

    },

    saveChanges() {

      this.showconfirmationModel = false
      let a = this.folderArray.splice(this.deleteItem, 1)

    },
    saveRename(itemname, itemindex) {

      if (this.edititem != null) {
        this.folderArray[this.edititem].name = this.renamefolderName;
      }
      this.showRenameField = false
    },
    saveSearchChanges() {

      this.$emit('save', this.newFileName, this.btn_color)

    },
    SelectColor($event) {
      c
      this.selectedColor = $event.target.options[$event.target.options.selectedIndex].text

      document.getElementById("colorr").style.color = this.selectedColor;

    },

    insertColor(color, index) {
      this.getcolor = color

      this.btn_color = this.colorsCode[index]

    }

  },


  props: {
    title: {
      type: Object,
      default: () => { }
    },
    fileType: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: 'white'
    },
    textColortopfive: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    dotsimage: {
      type: String,
      default: ''
    },
    shortdesc: {
      type: String,
      default: ''
    },
    folderName: {
      type: String,
      default: 'folderName'
    },
    savesearchModel: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    searchFunc: {
      type: Array,
      default: () => { }
    }


  },

};
</script>

<style scoped>
.remove-button {
  border: 0.6px solid red;
  border-radius: 3px;
  color: red;
  text-transform: uppercase;
}

.remove-button:hover {
  background: linear-gradient(270deg,
      rgba(241, 4, 4, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(154, 0, 0, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.ok-button {
  border: 0.6px solid green;
  border-radius: 3px;
  color: green;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(78, 233, 7, 0.153) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(78, 233, 7, 0.153) 100%);
}
</style>
