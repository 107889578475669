<template>
    <tailwind-modal :showModal="true" :showHeader="false">
      <div
      class="w-[600px]"
      style="
        border: 0.3px solid rgba(2, 189, 45, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #151515;
      "
    >
      <div>
        <div class="flex justify-end">
          <span
            class="text-white h-6 w-6 text-xl block outline-none focus:outline-none cursor-pointer"
            @click="closeModal"
          >
            ×
          </span>
        </div>
        <div class="h-full  px-3 pb-2">
          <div ref="termsofuse">
            <h1 style="color: " class="text-[#02BD2D] text-2xl">
            Important Notice
            </h1>
            <p>
              Market Alert Pro Pty Ltd is an authorized representative (No.
              001297846) of Equity Analyst Pty Ltd AFSL No. 534455. Market Alert
              Pro provides general advice only. Market Alert Pro, nor its
              representatives, give or intend to give personal financial advice.
              The content of this website and any email or telephone content
              provided by representatives of Market Alert Pro has been prepared
              without taking into account the objectives, financial situation or
              needs of any individual. You should consider the appropriateness
              of any general advice on this website or any information given by
              email or telephone in light of your objectives, financial
              situation or goals. If any general advice on this website or given
              by its representatives relates to the acquisition or possible
              acquisition of a particular financial product by you, you should
              obtain a copy of, and consider, the PDS for that product before
              making any decision.
            </p>
          </div>
          
        </div>
      </div>
    </div>

  </tailwind-modal>
</template>
<script>
// import { log } from "console";
import TailwindModal from "../popups/TailwindModal.vue";
// import AlertShort from "../utilities/AlertShort.vue";

export default {
  data() {
    return {
      loaderState: false,
      cardToken: null,
      messages: [],
      alertType: "Information",
      alertMessage: "Enter a Card to save.",
      InfoMessage: "Enter a Card to save.",
      success: null,
      displayAlert: true,
      cardElem: null,
      buttonDsiabled: false,
      receivedProps: this.privacyProp,
    };
  },
  props:
    // privacyProp:String,
    ["privacyProp"],
  components: {
    TailwindModal,
    // AlertShort,
  },
  methods: {
    closeModal() {
      console.log("hello from card modal");
      this.$emit("closeImportantNoticeModal");
    },
    test() {
      console.log(this.privacyProp);
    },
  },
 
};
</script>

<style scoped>
.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}
.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}
.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}
.InputContainer .InputElement {
  position: absolute;
  top: 0;
  color: white !important;
}
div.stripeCard {
  color: #f3f3f3 !important;
}
</style>
