<template>
  <div class="">
    <!-- verficationEmailSent -->
    <div v-if="verficationEmailSent">
      <div class="mainafter h-[100vh]">
        <div
          class="px-4 flex justify-center items-center h-full w-full relative"
        >
          <div>
            <div class="w-[100%]">
              <img src="../../assets/mapWhite.svg" class="w-full" alt="" />
            </div>
            <!-- emailSentAgain -->
            <div class="w-[100%] mt-1" v-if="emailSentAgain">
              <div
                class=""
                data-temp-component="true"
                v-if="messages.length > 0"
              >
                <AlertShort class="w-full" :alertType="alertType">
                  <p
                    class="text-gray-300"
                    v-for="message in messages"
                    :key="message.id"
                  >
                    {{ message }}
                  </p>
                </AlertShort>
              </div>
            </div>

            <div class="p-3 rounded h-[25vh] w-[50vh] mt-4" role="alert">
              <p class="font-bold text-lg" style="color: #00c92e">
                Your account is not active yet. Please verify your email.
              </p>
              <div class="flex justify-between items-center mt-5">
                <p class="text-sm h-full items-center" style="color: #fff">
                  If you don’t see your verification email, please check spam
                  and junk folders. If you don’t verify your email address
                  within 7 days, your account will be automatically deleted and
                  nobody wants that.
                </p>
              </div>

              <div class="pb-6">
                <slot />
              </div>
            </div>
            <div
              class="w-[100%] h-[4.5vh] bg-transparent flex items-center justify-center mt-5"
              style="gap: 0.3vw"
            >
              <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
              <p class="text-[#E5E9EC] text-[.9vw]">or</p>
              <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
            </div>
            <div class="text-white flex justify-center mt-2">
              Didn’t get the email yet?
            </div>
            <div>
              <button
                @click="ResentVerificationEmail"
                class="ok-button px-8 mt-6 font-semibold py-2 text-sm w-full"
              >
                RESEND ACTIVATION LINK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- validLink === false -->
    <div v-if="validLink === false">
      <div class="mainafter h-[100vh]">
        <div
          class="px-4 flex justify-center items-center h-full w-full relative"
        >
          <AlertLong alertType="Danger" closeAlert="false" title="Invalid Link">
            <p class="text-white flex items-center justify-center h-full mt-2">
              It seems that your link is not valid!
            </p>
          </AlertLong>
        </div>
      </div>
    </div>

    <!-- signUpForm -->
    <div class="main min-h-screen  w-full pt-16" v-if="signUpForm && validLink">
      <div
        class="w-full flex items-center sm:mt-1 h-[90vh] mt-12 md:justify-start sm:justify-center"
      >
        <form
          @submit.prevent="signUpRe"
          class="lg:w-[35%] md:w-[42%] md:justify-end sm:w-[50%] sm:px-0 px-6 w-full overflow-y-auto footer h-[90%] z-50 flex -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600 items-center"
          :class="loaderState ? 'pointer-events-none' : ''"
        >
          <div
            class="h-full lg:w-9/12 md:w-10/12 w-full flex justify-center -bg-yellow-100 z-50"
          >
            <div class="h-fit w-full">
              <div
                class="md:w-full sm:w-11/12"
                :class="validationObject.isPasswordNotValid ? 'mt-24' : ''"
              >
                <router-link to="/">
                  <img src="../../assets/mapWhite.svg" class="w-full" alt="" />
                </router-link>
              </div>

              <div class="flex justify-center">
                <div class="w-full">
                  <div>
                    <p class="text-[#E5E9EC] p-0 m-0">
                      Try Market Alert Pro for free
                    </p>
                    <h1
                      class="text-[24px] text-[#02BD2D] font-bold p-0 m-0 Inter-Bold"
                    >
                      Create an account
                    </h1>
                  </div>
                  <div class="w-[100%]" v-if="alertMessage">
                    <div
                      class=""
                      data-temp-component="true"
                      v-if="messages.length > 0"
                    >
                      <AlertShort class="w-full" :alertType="alertType">
                        <p
                          class="text-gray-300 w-fit"
                          v-for="message in messages"
                          :key="message.id"
                        >
                          {{ message }}
                        </p>
                      </AlertShort>
                    </div>
                  </div>
                  <div class="-bg-yellow-300 flex-col md:w-full justify-center">

                    <input placeholder="Username*" type="text" v-model="username" for="username"
                class="w-full  bg-transparent p-4 box-border -text-[.8vw] text-[#FFFFFF] rounded-md mt-2 focus:outline-none"
                  id="username" ref="username" :disabled="disabled"
                  style="border: 0.6px solid #7582787d" />

                <p v-if="validationObject.isUserNotValid" :class="
                  'p-0 m-0 mt-0 text-[14px] text-[' +
                  pageColors.MAP_RED +
                  '] text-[1vw]'
                ">
                  {{ pleaseEnterMessage("username") }}
                </p>
                <p v-else class="text-[#02BD2D] text-[12px] my-1">
                  No spaces or special characters in username. Choose carefully; the username cannot change after creating the account.
                </p>


                    <input
                      placeholder="Set an email address for your account*"
                      type="email"
                      v-model="email"
                      for="email"
                      class="w-full bg-transparent p-4 box-border text-[#FFFFFF] rounded-md mt-2 focus:outline-none"
                      id="email"
                      ref="email"
                      :disabled="disabled"
                      style="border: 0.6px solid #7582787d"
                    />

                    <p
                      v-if="validationObject.isEmailNotValid"
                      :class="
                        'p-0 m-0 text-[14px]  mt-0 text-[' +
                        pageColors.MAP_RED +
                        '] text-[1vw]'
                      "
                    >
                      {{ pleaseEnterMessage("email address") }}
                    </p>

                    <div
                      style="border: 0.6px solid #7582787d"
                      class="form-group rounded-md w-full -bg-green-300 justify-between flex mt-4"
                      ref="password"
                    >
                      <div class="w-10/12">
                        <input
                          :type="type"
                          id="password"
                          @focus="handlePasswordFocus"
                          v-model="password"
                          placeholder="Choose a password*"
                          :disabled="disabled"
                          class="bg-transparent w-[120%] p-4 rounded-md text-[#FFFFFF] focus:outline-none"
                        />
                      </div>

                      <div
                        class="flex justify-center w-2/12 items-center z-[50]"
                      >
                        <font-awesome-icon
                          @click="showPassword('password')"
                          class="text-white"
                          style="color: whitesmoke; height: px; cursor: pointer"
                          :icon="['fas', iconName]"
                          :disabled="disabled"
                        />
                      </div>
                    </div>
                    <div
                      class="-w-9/12 w-full h-[.1vh] bg-transparent flex justify-between mt-3"
                    >
                      <div
                        class="h-full w-[19%] rounded-xl"
                        v-for="(n, i) in 5"
                        :class="
                          passwordStrengthPoints > i
                            ? 'bg-[' + passwordMeterColors[i] + ']'
                            : 'bg-gray-400'
                        "
                        :key="i"
                      ></div>
                    </div>
                    <p
                      v-if="validationObject.isPasswordNotValid"
                      :class="
                        'p-0 m-0 mt-0 text-[' +
                        pageColors.MAP_RED +
                        '] text-[1vw]'
                      "
                    >
                      you entered a weak password
                    </p>
                    <div
                      v-if="passwordCondition"
                      class="px-2 w-fit py-1 mt-2 border-l-4 rounded"
                      style="
                        border-color: #00c92e;
                        background-color: rgba(0, 201, 46, 0.1);
                      "
                    >
                      <p class="text-white text-[0.8vw]">
                        Password must have 6 character includes special
                        character, uppercase and number
                      </p>
                    </div>
                    <div
                      style="border: 0.6px solid #7582787d"
                      class="form-group rounded-md w-full justify-between flex mt-4"
                      ref="repeatpassword"
                    >
                      <div class="w-10/12">
                        <input
                          :type="typeRepeatPassword"
                          v-model="repeatpassword"
                          @focus="handleRepeatPasswordFocus"
                          id="repeatpassword"
                          placeholder="Retype your password*"
                          :disabled="disabled"
                          class="w-[120%] bg-transparent p-4 box-border text-[#FFFFFF] rounded-md focus:outline-none"
                          @change="passwordMatching"
                        />
                      </div>

                      <div class="flex w-2/12 justify-center items-center z-50">
                        <font-awesome-icon
                          @click="showPassword('repeatPassword')"
                          class="text-white"
                          style="color: whitesmoke; height: px; cursor: pointer"
                          :icon="['fas', repeatPasswordIcon]"
                          :disabled="disabled"
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    v-if="validationObject.isPasswordNotMatch"
                    :class="
                      'p-0 m-0 mt-0 text-[14px] text-[' +
                      pageColors.MAP_RED +
                      '] text-[1vw]'
                    "
                  >
                    password didn't match
                  </p>

                  <div class="flex-col w-full">
                    <div class="flex w-full justify-center">
                      <div
                        class="w-full flex items-center justify-center mt-4"
                        :class="loaderState ? 'btnDefault' : ''"
                      >
                        <VueLoadingButton
                          aria-label="Post message"
                          :class="loaderState ? 'cursor-not-allowed' : ''"
                          class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                          @click.native="signUpRe"
                          :loading="loaderState"
                          :styled="isStyled"
                          :type="submit"
                          ><span class="text-[15px] font-bold"
                            >GET 7 DAYS FREE</span
                          ></VueLoadingButton
                        >
                      </div>
                    </div>
                    <div
                      class="w-full h-[4.5vh] bg-transparent flex items-center justify-center"
                      style="gap: 0.3vw"
                    >
                      <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                      <p class="text-[#E5E9EC]">or</p>
                      <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                    </div>

                    <div class="w-full bg-transparent">
                      <div class="flex items-center justify-center">
                        <div class="flex items-center">
                          <p class="text-white">Already have an account?</p>
                        </div>
                        <div class="flex items-center">
                          <button class="text-[#02BD2D] font-bold ml-2">
                            <router-link
                              to="/"
                              :class="[disabled ? pointer_events_none : '']"
                              ><span
                                ><a href="/" target="_blank">Login</a></span
                              >
                            </router-link>
                          </button>
                        </div>
                      </div>
                      <div class="w-full flex items-end justify-center">
                        <p class="text-[white] whitespace-nowrap">
                          Have an issue?
                          <router-link to="/contactus">
                            <span class="text-sm cursor-pointer"
                              ><a
                                href="/contactus"
                                target="_blank"
                                style="color: #9e9898"
                                >Contact Support</a
                              >
                            </span>
                          </router-link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="w-full sm:mt-20 mt-24 pb-6 flex justify-center">
       <PageFooter />
      </div>
    </div>

    <template v-if="termsOfUse">
      <TermsOfUse
        v-on:closeTermsOfUseModal="toggleModalTermsOfuse"
        :privacyProp="privacyProp"
      />
    </template>
    <template>
      <div class="w-full h-full bg-red-400">
        <ImportantNotice
          v-if="ImportantNoticeModal"
          v-on:closeImportantNoticeModal="toggleImportantNotice"
          :privacyProp="privacyProp"
        />
      </div>
    </template>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import userApi from "../../api/userApi";
import { products, price } from "../../utils/products";
import VueLoadingButton from "vue-loading-button";
import "../customcss/index.css";

// import { FormValidationException } from "../../helper/exceptions";
import {
  STRIPE_PRODUCT_LIST,
  signUpRe,
  sent_verification_email,
} from "@/api/apiRoutes";
import AlertShort from "../utilities/AlertShort.vue";
import { STRIPE_ADD_CARD } from "@/api/apiRoutes";
import PageFooter from "@/components/Footer/Footer.vue"

import TermsOfUse from "../popups/TermsOfUse.vue";
import ImportantNotice from "../popups/ImportantNotice.vue";
import { passwordStrength } from "check-password-strength";
import { Colors } from "../../helper/globalUtils";
import { pleaseEnterMessage } from "../../helper/globalUtils";
import AlertLong from "../utilities/AlertLong.vue";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";

export default {
  data() {
    return {
      verficationEmailSent: false,
      ImportantNoticeModal: false,
      pageColors: Colors,
      privacyProp: null,
      alertMessage: false,
      emailSentAgain: false,
      missingProd: false,
      formValidation: {
        email: {
          isValid: true,
          messag: "",
        },
      },
      passwordMeterColors: [
        "#FF0A00",
        "#FF0A00",
        "#02BD2D",
        "#02BD2D",
        "#02BD2D",
      ],
      validationObject: {
        isEmailNotValid: false,
        isUserNotValid: false,
        isPhoneNotValid: false,
        isPasswordNotValid: false,
        isFirstNameNotValid: false,
        isLastNameNotValid: false,
        isPasswordNotMatch: false,
        isProductNotSelected: false,
        isVarientNotSelected: false,
        istermsAndCondtionsAccepted: false,
      },
      CorrectPasswordPoints: ["Special character", "Uppercase", "Number"],
      passwordStrengthPoints: 0,
      passwordRequirements: ["It must be at least 6 characters long.", ""],
      checkPasswordMsg: "",
      results: " ",
      border: "20px solid black",
      firstName: "",
      lastName: "",
      email: "",
      username:"",
      validatedEmail: null,
      validatedLName: null,
      validatedFName: null,
      validatedPassword: null,
      validatedPhone: null,
      validatedUserName: null,
      phone: "",
      password: "",
      repeatpassword: "",
      type: "password",
      typeRepeatPassword: "password",
      termsAndCondiion: false,
      selectedProduct: "",
      stripeProducts: [],
      messages: [],
      success: null,
      alertType: "Success",
      iconName: "fa-eye",
      cardShow: false,
      cardToken: "",
      cardElem: null,
      loaderState: false,
      disabled: false,
      termsOfUse: false,
      privacyandCookies: false,
      pointer_events_none: "pointer-events-none",
      borderColor: "red",
      booleanValue: false,
      refArray: ["email", "password", "repeatpassword","username"],
      // refArray: ["email", "password", "repeatpassword"],
      passwordCondition: false,
      vareinets: [],
      productId: null,
      planId: null,
      productRetrived: false,
      missingVarient: false,
      validLink: false,
      signUpForm: true,

      userCreated: false,
      repeatPasswordIcon: "fa-eye",
    };
  },
  computed: {
    stripeProductsLocal() {
      return this.stripeProducts;
    },
  },
  components: {
    FooterCompanyLinks,
    ImportantNotice,
    AlertShort,
    TermsOfUse,
    AlertLong,
    VueLoadingButton,
    PageFooter
  },
  watch: {
    email(value) {
      this.$refs.email.style.border = `0.6px solid #7582787d`;
      this.validationObject.isEmailNotValid = false;
      if (value) {
        this.email = value;
        this.validateEmail(value);
        this.validationObject.isEmailNotValid = false;
        this.alertType = " "
        
      }
    },
    username(value) {
      this.$refs.username.style.border = `0.6px solid #7582787d`;
      this.validationObject.isUserNotValid = false;
      if (value) {
        this.username = value;
        this.validateUsername(value);
        this.validationObject.isUserNotValid = false;
        this.alertType = " "
      }
    },

    phone(value) {
      this.validatedPhone = true;
      this.validationObject.isPhoneNotValid = false;
      this.$refs.phone.style.border = `0.6px solid #7582787d`;
      if (value) {
        console.log("phone if");
        this.validationObject.isPhoneNotValid = false;
        this.phone = value;
        this.validatePhone(value);
      }
    },

    firstName(value) {
      this.$refs.fname.style.border = `0.6px solid #7582787d`;
      this.validationObject.isFirstNameNotValid = false;
      if (value) {
        this.firstName = value;
        this.validateFName(value);
      }
    },
    termsAndCondiion(value) {
      console.log(value);
      if (value == true) {
        this.validationObject.istermsAndCondtionsAccepted = false;
      }
    },
    lastName(value) {
      this.$refs.lname.style.border = `0.6px solid #7582787d`;
      this.validationObject.isLastNameNotValid = false;

      if (value) {
        this.lastName = value;
        this.validateLName(value);
      }
    },
    password(value) {
      this.$refs.password.style.border = `0.6px solid #7582787d`;
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
      this.validationObject.isPasswordNotValid = false;
      this.alertType = " "

      if (value) {
        this.password = value;
        this.passwordCondition = false;
        try {
          this.validatePassword(value);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.passwordStrengthPoints = 0;
      }
    },
    repeatpassword() {
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
      this.alertType = " "
    },
  
  },
  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    async signUpRe() {
      let validationErrors = [];
      let isEmptyForm = true;
      let isNotValid = false;
      for (const ref of this.refArray) {
        let fieldRef = this.$refs[ref];
        console.log(
          "fieldRef.value==>",
          ref,
          fieldRef.value,
          fieldRef.value === ""
        );
        if (fieldRef.value === "") {
          fieldRef.style.border = `0.1px solid 	${this.pageColors.MAP_RED}`;
          this.$refs.password.style.border = `0.6px solid ${this.pageColors.MAP_RED}`;
          this.$refs.repeatpassword.style.border = `0.6px solid ${this.pageColors.MAP_RED}`;
          isEmptyForm = false;
        }
      }
      if (!isEmptyForm) {
        validationErrors.push("Fields with (*) are required.");
        this.showErrors(validationErrors);
        return;
      }

      if (this.validatedEmail == false) {
        console.log("email ghalat hai")
        this.validationObject.isEmailNotValid = true;
        this.FieldsBorderColor(["email"], this.pageColors.MAP_RED);

        isNotValid = true;
      }
      if (this.validatedUserName == false) {
        this.validationObject.isUserNotValid = true;
        this.FieldsBorderColor(["username"], this.pageColors.MAP_RED);

        isNotValid = true;
      }
      if (this.passwordStrengthPoints < 3) {
        this.passwordCondition = true;
        isNotValid = true;
        this.$refs.password.style.border = `0.6px solid ${this.pageColors.MAP_RED}`;
        this.validationObject.isPasswordNotValid = true;
      }
      if (this.password != this.repeatpassword) {
        this.validationObject.isPasswordNotMatch = true;
        this.FieldsBorderColor(
          ["password", "repeatpassword"],
          this.pageColors.MAP_RED
        );
        isNotValid = true;
      }
      if (isNotValid) {
        return;
      }

      

      try {
        this.loaderState = true;
        let payload = {
          email: this.email?.toLowerCase(),
          password: this.password,
          productId: this.productId,
          planId: this.planId,
          username:this.username
        };
        console.log(payload)
   
        let res = await axios.post(signUpRe, payload);
        console.log("res====>", res);
        if (res) {
          this.success = res.data.success;
          if (this.success) {
            this.alertMessage = true;
            this.verficationEmailSent = true;
            localStorage.setItem("email", this.email);
            this.alertType = "Success";
            this.messages = [res.data.message];
            this.userCreated = true;
            this.signUpForm = false;
            this.validationErrors = [];
          } else {
            this.loaderState = false;
            this.alertMessage = true;
            this.alertType = "Danger";
            this.messages = [res.data.message];
            // setTimeout(() => {
            //   this.alertType = " ";
            // }, 2 * 2000);
          }
        } else {
          throw Error("Invalid Information");
        }
      } catch (e) {
        this.showErrors(validationErrors);
      }
    },
    handlePasswordFocus() {
      this.$refs.password.style.border = `0.6px solid #7582787d`;
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
      this.validationObject.isPasswordNotValid = false;
    },
    handleRepeatPasswordFocus() {
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
    },
    validateFName(value) {
      if (/^[A-Za-z]\w*$/.test(value)) {
        this.validatedFName = true;
      } else {
        console.log("name not true");
        this.validatedFName = false;
      }
    },
    async ResentVerificationEmail() {
      let email = localStorage.getItem("email");

      let result = await axios.post(sent_verification_email, { email: email });
      if (result.data.success) {
        this.emailSentAgain = true;
        this.messages = ["Email sent successfully kindly check your mail"];
        this.alertType = "Success";
      }
    },
    pleaseEnterMessage,
    selectProduct(value) {
      this.missingProd = false;
      this.selectedProduct = null;
      console.log(value);
      let localVarients = this.stripeProducts.find((o) => o.id === value);
      this.vareinets = localVarients.pricing;
      console.log(this.vareinets);
      this.productId = value;
    },
    selectVarient(value) {
      this.missingVarient = false;
      this.selectedProduct = value;
      console.log(this.selectedProduct);
    },
    passwordMatching() {
      if (this.password != this.repeatpassword) {
        console.log("password not mach");
        this.FieldsBorderColor(
          ["password", "repeatpassword"],
          this.pageColors.MAP_RED
        );
        this.validationObject.isPasswordNotMatch = true;
      } else {
        this.validationObject.isPasswordNotMatch = false;
        this.FieldsBorderColor(["password", "repeatpassword"], "#7582787d");
      }
    },
    redAll() {
      this.$refs.val.style.border = `0.1px solid 	rgba(255,0,0,0.5)`;
    },
    toggleModalTermsOfuse(value) {
      if (!this.disabled) {
        this.termsOfUse = !this.termsOfUse;
        this.privacyProp = value;
        console.log(this.privacyProp);
      }
    },
    toggleImportantNotice(value) {
      this.ImportantNoticeModal = !this.ImportantNoticeModal;
    },
    toggleModalCookiesPolicy() {
      this.privacyandCookies = !this.privacyandCookies;
    },

    validatePhone(phone) {
      var regex =
        /(^1300\d{6}$)|(^1800|1900|1902\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\d{4}$)|(^04\d{2,3}\d{6}$)/;
      if (regex.test(phone)) {
        this.validatedPhone = true;
        console.log("phone is true");
      } else {
        console.log("phone is false");
        this.validatedPhone = false;
      }
    },
    validateEmail(value) {
      var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailPattern.test(value)) {
        console.log("passed")
        this.validatedEmail = true;
      } else {
        // this.$refs.email.style.border = `0.1px solid 	rgba(255,0,0,0.5)`;

        // console.log("email not true");
        this.validatedEmail = false;
        // this.alertType = "Danger";
        // this.messages = ["Please enter a valid email addressss"];
        if (this.$refs.email.value == 0) {
          this.$refs.email.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
          this.validatedEmail = true;
          // this.messages.length = 0;
        }
      }
    },
    validatePassword(value) {
      let _passwordStrength = passwordStrength(value);
      this.passwordStrengthPoints =
        _passwordStrength.contains.length +
        (_passwordStrength.length < 6 ? 0 : 1);
      console.log(_passwordStrength);

      if (_passwordStrength.length < 6)
        throw "Password must be at least 6 characters long.";

      return this.passwordStrengthPoints >= 3;
    },
    validateFName(value) {
      if (/^[A-Za-z]\w*$/.test(value)) {
        this.validatedFName = true;
      } else {
        console.log("name not true");
        this.validatedFName = false;
      }
    },
    validateUsername(value) {
      var pattern = /^[a-zA-Z0-9_]+$/;
      if (pattern.test(value)) {
        this.validatedUserName = true;
      } else {
        this.validatedUserName = false;
      }
    },
    validateLName(value) {
      if (/^[A-Za-z]\w*$/.test(value)) {
        console.log("name is true");

        this.validatedLName = true;
      } else {
        this.validatedLName = false;

        if (this.$refs.lname.value == 0) {
          this.$refs.lname.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
          this.validatedLName = true;
        }
      }
    },

    showPassword(value) {
      if (value === "password") {
        if (this.type === "password") {
          console.log(this.$refs.password.type);
          this.type = "text";
          this.iconName = "fa-eye-slash";
        } else {
          this.type = "password";
          this.iconName = "fa-eye";
        }
      } else {
        if (this.typeRepeatPassword === "password") {
          // console.log(this.$refs.password.type);
          this.typeRepeatPassword = "text";
          this.repeatPasswordIcon = "fa-eye-slash";
        } else {
          this.typeRepeatPassword = "password";
          this.repeatPasswordIcon = "fa-eye";
        }
      }
    },
    FieldsBorderColor(refrence, color) {
      for (const refvalues of refrence) {
        this.$refs[refvalues].style.border = `0.6px solid ${color}`;
      }
    },
    //~
    async signUP() {
      let validationErrors = [];
      let isEmptyForm = true;
      let isNotValid = false;
      for (const ref of this.refArray) {
        let fieldRef = this.$refs[ref];
        console.log(
          "fieldRef.value==>",
          ref,
          fieldRef.value,
          fieldRef.value === ""
        );
        if (fieldRef.value === "") {
          fieldRef.style.border = `0.1px solid 	${this.pageColors.MAP_RED}`;
          isEmptyForm = false;
        }
        if (this.productId == null) {
          this.missingProd = true;
          isEmptyForm = false;
        }
        if (this.selectedProduct == null) {
          isEmptyForm = false;
          this.missingVarient = true;
        } else {
          this.missingVarient = false;
        }
      }
      if (!isEmptyForm) {
        validationErrors.push("Fields with (*) are required.");
        this.showErrors(validationErrors);
        return;
      }
      if (this.validatedFName == false) {
        this.validationObject.isFirstNameNotValid = true;
        this.FieldsBorderColor(["fname"], this.pageColors.MAP_RED);
        isNotValid = true;
      }
      if (this.validatedLName == false) {
        this.validationObject.isLastNameNotValid = true;
        this.FieldsBorderColor(["lname"], this.pageColors.MAP_RED);
        isNotValid = true;
      }
      if (this.validatedEmail == false) {
        this.validationObject.isEmailNotValid = true;
        this.FieldsBorderColor(["email"], this.pageColors.MAP_RED);

        isNotValid = true;
      }
      if (this.validatedUserName == false) {
        this.validationObject.isUserNotValid = true;
        this.FieldsBorderColor(["username"], this.pageColors.MAP_RED);

        isNotValid = true;
      }
      if (this.validatedPhone == false) {
        this.validationObject.isPhoneNotValid = true;
        this.FieldsBorderColor(["phone"], this.pageColors.MAP_RED);

        isNotValid = true;
      }
      if (this.passwordStrengthPoints < 3) {
        this.passwordCondition = true;
        isNotValid = true;
        this.$refs.password.style.border = `0.6px solid ${this.pageColors.MAP_RED}`;
        this.validationObject.isPasswordNotValid = true;
      }
      if (this.password != this.repeatpassword) {
        this.validationObject.isPasswordNotMatch = true;
        this.FieldsBorderColor(
          ["password", "repeatpassword"],
          this.pageColors.MAP_RED
        );
        isNotValid = true;
      }
      if (this.productId == null) {
        isNotValid = true;
        this.validationObject.isProductNotSelected = true;
        this.missingProd = true;
      }
      if (!this.termsAndCondiion) {
        this.validationObject.istermsAndCondtionsAccepted = true;
        isNotValid = true;
      }
      if (isNotValid) {
        return;
      }
      if (validationErrors.length > 0) {
        this.showErrors(validationErrors);
        return;
      }

      try {
        if (validationErrors.length > 0) throw Error("Failed to validate form");

        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          username:this.username,
          password: this.password,
          repeatPassword: this.repeatpassword,
          termsAndCondition: this.termsAndCondiion,
          subscription: this.selectedProduct,
          productId: this.productId,
        };
        console.log("payload=========>", payload);
        return  
        this.loaderState = true;
        this.disabled = true;
        const res = await userApi.userSignUp(payload);
        console.log("res:", res);
        this.loaderState = false;
        this.disabled = false;
        if (res.status === 200) {
          this.success = res.data.success;
          if (this.success) {
            this.alertType = "Success";
            this.messages = [res.data.message];
            this.userCreated = true;
            this.validationErrors = [];
          } else {
            alert("error");
            this.alertType = "Danger";
            this.messages = [res.data.message];
            // setTimeout(() => {
            //   this.alertType = " ";
            // }, 2 * 2000);
          }
        } else {
          throw Error("Invalid Information");
        }
      } catch (err) {
        this.showErrors(validationErrors);
      }
      await this.sleep(4000);
      this.alertMessage = false;
    },
    //  owais
    sleep(ms) {
      this.alertMessage = true;
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    showErrors(validationErrors) {
      this.alertMessage = true;
      console.log("show errors", validationErrors);
      this.loaderState = false;
      this.disabled = false;
      this.success = false;
      this.alertType = "Danger";
      // setTimeout(() => {
      //   this.alertType = "";
      // }, 4 * 2000);
      this.messages = validationErrors;
      console.log(this.messages.length);
    },

    checked() {
      this.termsAndCondiion = !this.termsAndCondiion;

      console.log(this.termsAndCondiion);
    },
    async getProducts() {
      /**
       * @Todo RN: If we unable to retreive list of products, then sign up page should be down and display an error message "Registration form is currently unavailable."
       */
      let data = await axios.get(STRIPE_PRODUCT_LIST);
      console.log("stripe product data", data);
      if (data) {
        this.stripeProducts = data.data.payload;
        console.log(this.stripeProducts);
        this.productRetrived = true;
      }
    },
    // addCard function
    async addCard(token) {
      let response = await axios.post(STRIPE_ADD_CARD, {
        cardToken: token,
      });
      console.log(response);
      return response;
    },
  },
  async mounted() {
    // await this.getProducts();
    let route = this.$route.query;
    this.productId = route.product;
    this.planId = route.price;
    console.log("route==>",route.product,route.price,products);
    if (route.product && route.price) {
      if (products.includes(route.product) && price.includes(route.price)) {
        this.validLink = true;
      } else {
        this.validLink = false;
      }
    } else {
      this.validLink = false;
    }

    // this.pleaseEnterMessage("hello");
  },
};
</script>

<style scoped>
a {
  color: #02bd2d;
}
a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}
input:focus {
  border: 1px solid #02bd27 !important;
}
.main {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-image: url("../../assets/map-main-background.jpg");

  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  background-attachment: fixed;

  height: auto;
}

.mainafter {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainafter::before {
  content: "";
  background-image: url("../../assets/map-main-background.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}

/* .mainafter {
  position: relative;
  height: 100vh;
  width: 100%;
  
 
}

.mainafter::before {
  content: "";
  
  background-image: url("../../assets/map-main-background.jpg");
  background-size: cover;
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
} */
.footer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.checkbox-round {
  width: 0.9em;
  height: 0.9em;
  background-color: black;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.checkbox-round:checked {
  background-color: #02bd2d;
}

.btnDefault {
  pointer-events: none;
}
.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}
</style>
