<template>
    <div>

        <loading :active.sync="showChartLoader"  
        :is-full-page="false"
        color="gray"
              loader="Dots"
              zIndex=999,
              background-color="#1E2224"
              ></loading>       
    </div>
</template>

<script>

import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name:"AreaChartLoader",
    props:["showChartLoader"],
    components:{
        Loading
    }

}
</script>

<style>

</style>