<template>
    <div class="w-full h-[95vh] text-white">

    <div class="w-full h-full">
        <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="report"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
    <section slot="pdf-content">
      <div class="px-6 py-4">
      
      <!-- top header div  -->
      <div class="flex w-full items-center justify-between">
        <img src="../../assets/logo.png" class="h-[30px] flex mt-3" alt="" />
        <div class="flex flex-col items-center">
          <span class="text-md">Company Report</span>
          <span class="text-sm">dd/mm/yyyy</span>
        </div>
      </div>

      <!-- info and chart area -->
      <div class="flex items-start space-x-6 mt-4">
        <div class="w-8/12">
          <div class="flex justify-between items-start">
            <div>
              <p class="font-black text-[26px]">TICKER</p>
              <p class="text-sm">Company Name</p>
            </div>
            <div class="flex flex-col items-end">
              <p class="text-[24px] text-green-700">CURRENCY + PRICE</p>
              <p class="text-md text-green-700 text-right">1 Year Change %</p>
              <p class="text-sm text-gray-500">First trade date: dd/mm/yyyy</p>
            </div>
          </div>
          
          <div class="bg-gray-600 bg-opacity-20 p-2 rounded-md mt-4">
            <p class="font-bold">Business Classification</p>
            <p class="text-sm pb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit.  ipsam ab temporibus a molestiae veniam error minus non placeat consectetur.</p>
          </div>
            
        </div>

        <div class="w-4/12">
          <img src="../../assets/chart.png" alt="" class="h-[20vh] w-full">
        </div>
      </div>

      <!-- Ticker data  -->
      <div class="flex rounded-md border-[0.3px] border-gray-300 mt-4">
        <div class="w-6/12 px-6 py-2 flex justify-between border-r-[0.3px] border-gray-300">
         <div class="w-5/12">
          <div class="w-full">
            <div class="flex justify-between py-1">
              <span>P/E</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>EPS</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Mkt Cap</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Div %</span>
              <span>XXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Net Profit</span>
              <span>XXXX</span>
            </div>
          </div>
         </div>

         <div class="w-5/12">

          <div class="w-full">
            <div class="flex justify-between py-1">
              <span>P/E</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>EPS</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Mkt Cap</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Div %</span>
              <span>XXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Net Profit</span>
              <span>XXXX</span>
            </div>
          
          </div>
        </div>

          
        </div>
        
        <div class="w-6/12 px-6 py-2 flex justify-between">
         <div class="w-5/12">
          <div class="w-full">
            <div class="flex justify-between py-1">
              <span>P/E</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>EPS</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Mkt Cap</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Div %</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Net Profit</span>
              <span>XXXX</span>
            </div>
          </div>
         </div>

         <div class="w-5/12">

          <div class="w-full">
            <div class="flex justify-between py-1">
              <span>P/E</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>EPS</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Mkt Cap</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Div %</span>
              <span>XXXX</span>
            </div>
            <div class="flex justify-between py-1">
              <span>Net Profit</span>
              <span>XXXX</span>
            </div>
          
          </div>
        </div>

          
        </div>
      
      </div>

      <!-- Business summary  -->
      <div class="mt-6">
        <p class="text-xl font-black">Business Summary</p>
        <p class="text-sm mt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima earum molestiae iure, dicta molestias nemo in hic quasi corporis reiciendis libero veritatis illum quis ratione assumenda iusto natus debitis! Eveniet facilis voluptate consectetur aliquam minima consequatur minus quibusdam ratione totam aperiam harum laboriosam saepe cum debitis deserunt, ut quisquam veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore dolor adipisci vel ad aspernatur consequuntur corporis ratione laborum beatae! Impedit facere magnam voluptatem consequatur minus nesciunt reiciendis a, neque ducimus provident nemo! Tenetur magnam esse laboriosam, incidunt nostrum tempora dolore quis eos debitis quidem, magni minus illum assumenda. Consequuntur porro ipsa fugit vero perferendis error itaque esse harum quod quos.</p>
      </div>

      <!-- Financial Report  -->
      <div class="mt-6">
        <p class="text-xl font-black">Financial Report</p>
        <p class="text-sm mt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima earum molestiae iure, dicta molestias nemo in hic quasi corporis reiciendis libero veritatis illum quis ratione assumenda iusto natus debitis! Eveniet facilis voluptate consectetur aliquam minima consequatur minus quibusdam ratione totam aperiam harum laboriosam saepe cum debitis deserunt, ut quisquam veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore dolor adipisci vel ad aspernatur consequuntur corporis ratione laborum beatae! Impedit facere magnam voluptatem consequatur minus nesciunt reiciendis a, neque ducimus provident nemo! Tenetur magnam esse laboriosam, incidunt nostrum tempora dolore quis eos debitis quidem, magni minus illum assumenda. Consequuntur porro ipsa fugit vero perferendis error itaque esse harum quod quos.</p>
      </div>

      <!-- important notice  -->
      <div class="bg-gray-600 bg-opacity-20 py-1 pl-2 rounded-md mt-12">
            <p class="font-bold">Important Notice:</p>
            <p class="text-sm pb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eos non facere accusamus esse quibusdam blanditiis, expedita consequatur rem odit. Repellat, veritatis, ipsam ab temporibus a molestiae veniam error minus non placeat consectetur.</p>
      </div>       
  
  </div>
      </section>
    </vue-html2pdf>
 
    <div class="w-full flex justify-end mt-4">
        <div @click="generatePDF()" class="border border-green-700 cursor-pointer p-2">Download Pdf</div>
      </div>
</div>
</div>

</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
name: 'Report',
methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  components: {
    VueHtml2pdf,
  },

}
</script>

<style scoped>
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 150px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}

</style>