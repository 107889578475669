<template>
  <div class="w-full h-full bg-[#000000] flex items-center justify-center mainafter">
    <div
      class="xl:w-3/12 lg:w-5/12 md:w-6/12 sm:w-7/12 pt-4 pb-6  flex flex-col items-center z-10"
    >
      <span class="text-[#02BD2D] font-semibold">Welcome To</span>
      <div class="mb-2 sm:w-96 w-80 flex justify-center -mt-6">
        <img src="../../assets/mapWhite.svg" />
      </div>
      <div class="w-9/12">
        <AlertShort
          class="w-full bg-[#001A08] bg-opacity-5 text-center"
          alertType="Success"
        >
          <p class="text-lg text-[#FFFFFF] py-2 font-semibold">
            Your Account is Ready!
          </p>
        </AlertShort>
      </div>
      <div class="w-11/12 text-sm mt-6 mb-10">
        <div class="text-center px-2">
          <span class="text-white"
            >Welcome to Market Alert Pro, the platform for traders and investors
            looking to stay ahead of the game.</span
          >
        </div>
        <div class="my-4 pl-8">
          <span class="text-white"
            >We're thrilled to offer you a
            <span class="text-[#02BD2D] font-semibold"
              >PREMIUM ACCOUNT TRIAL</span
            >
            so that you can experience the full power of Market Alert Pro.</span
          >
        </div>
        <div class="text-center">
          <span class="text-white"
            >Cancel at any time with no obligation and no charges.</span
          >
        </div>
      </div>
      <div class="w-9/12 cursor-pointer z-10">
        <router-link to="/">
          <button class="loginBtn w-full text-center py-4">
            LOGIN TO MARKET ALERT PRO
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AlertShort from "../utilities/AlertShort.vue";

export default {
  components: {
    AlertShort,
  },
};
</script>

<style scoped>
.mainafter {
  position: relative;
}
.mainafter::before {
  content: "";
  background-image: url("../../assets/map-main-background.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
  filter: brightness(0.9); 
  pointer-events: none;
}

.loginBtn {
  border: 0.6px solid #0d902b;
  border-radius: 6px;
  color: #02bd2d;
}
.loginBtn:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}
</style>
