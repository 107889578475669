<template>
  <div class="relative h-[90vh] w-full">
    <div class=" px-2  relative h-full w-full">
      <!-- /////////////////////------------------HEADER---------------------------------////////////////////////////// -->
      <div class="flex items-center w-full h-[5%]  gap-[.5vw] mb-2">
        <div class="text-white text-[1.6vw] Inter-Bold">
          &nbsp;Stock&nbsp;Lists
        </div>
        <div class="flex text-[.65vw]">
          <span class="text-[#F8F8F9] opacity-40 font-normal">&nbsp;My&nbsp;Favourites</span>
        </div>
        <div class="flex text-[.6vw]">
          <span class="text-[#F8F8F9] opacity-40 font-normal">|</span>
        </div>
        <div class="flex gap-[.5vw] items-center">
          <span class="flex gap-[.2vw] cursor-pointer items-center" v-for="(f, i) in favouriteList" :key="i"
            @click.prevent="navigateToTreeFromList(f)">
            <SvgListIcon class="w-[.5vw]" :fill-color="f.favColor" />
            <span class="text-[#FFFFFF] text-[.5vw]">{{ f.desc }}</span>
          </span>
        </div>
      </div>

      <!-- /////////////////////------------------COLUMNS---------------------------------////////////////////////////// -->
      <div class="flex h-[95%] w-full gap-[.5vw]" style="">
        <!-- ///////////////-----------------COLUMN 1------------------------/////////////////////////// -->
        <div class="relative w-[20%] h-full pt-[1vw] bg-[#1E2125] border-[0.3px] border-[#7582785c] rounded-md"
          style="display: flex; flex-direction: column; justify-content: space-between;">
          <div style="display:flex; flex-direction: column; gap:1vh;">
            <div class=" flex justify-between flex flex-wrap btnsize px-[.5vw] mx-[.5vw]">
              <div class="text-white font-semibold text-[.8vw] ">
                Browser Stocks Lists
              </div>

              <div v-if="userIsNotPremium"
                class="text-white border rounded-sm text-[.45vw] self-center cursor-pointer w-[4vw] h-[80%] flex justify-center items-center"
                @click="$router.push('/settings')">
                + UPGRADE
              </div>

            </div>

            <div class="">
              <form class="px-[.5vw] mx-[.5vw]">
                <!-- <label for="default-search" class=" text-[1vw] font-medium text-gray-900 sr-only">Search</label> -->
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-[.5vw] pointer-events-none">
                    <svg aria-hidden="true" class="w-[.8vw] text-white dark:text-white" fill="none"
                      stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                  <input type="search" id="default-search"
                    class="rounded block p-[.45vw] pl-[1.5vw] w-full text-[.7vw] text-white bg-black border border-black placeholder-white focus:outline-none"
                    placeholder="Search" required />
                </div>
              </form>
            </div>

            <div class="overflow-y-scroll h-[49vh] hideScrollbar">
              <TreeBrowser mainNode :id="n.id" :ref="n.id" @treeLoad="treeLoad" :node="n" v-for="(n, i) of newConfig"
                :key="i" @onClick="treeEvent" :map="[n.name]" :idMap="[n.id]" />
            </div>
          </div>

          <div
            class=" bg-[#151515] h-full box-border border-[0.3px] border-[#7582785c] overflow-y-auto p-[1vw] rounded-b-md"
            style="display: flex; flex-direction: column; gap:.6vh;">
            <div class="">
              <img src="images/info.svg" alt="info" class=" w-[.7vw]" />
            </div>
            <div class="Inter-Bold text-[1.1vw] text-base text-white">{{ info.name }}</div>
            <p class="text-[#E5E9EC] text-[.6vw] leading-[2vh]">
              {{ info.desc }}
            </p>
          </div>
        </div>

        <!-- ///////////////-----------------COLUMN 2------------------------/////////////////////////// -->
        <div :class="isLoading?'w-8/12' : 'w-[80%]'" class="h-full border-[0.3px] border-[#7582785c] rounded-b-md box-border">
          <!-- /////////////////////----------------HEADER---------------------------////////////////////////////////// -->
          <div class="flex flex-wrap justify-between h-[10%] items-center px-[.5vw]">
            <div class="flex items-center">
              <div class="  text-white flex flex-nowrap">
                <span class=" Inter-Bold text-[.9vw]" v-for="(stocks, i) of selectedStock" :key="i">{{ stocks }}
                  <span class="opacity-40 " v-if="i !== selectedStock.length - 1"> &nbsp;|&nbsp;</span>
                  <span class="opacity-40 " v-if="i === selectedStock.length - 1"> :&nbsp;</span>
                </span>
                <span></span>
                <!-- Fundamentals&nbsp;>&nbsp;SP500&nbsp;>&nbsp;US&nbsp;>&nbsp;P/E&nbsp;Ratio&nbsp; -->
              </div>
              <!-- Fundamentals&nbsp;>&nbsp;SP500&nbsp;>&nbsp;US&nbsp;>&nbsp;P/E&nbsp;Ratio&nbsp; -->
              <div class="flex flex-wrap p-[] text-[.6vw] font-normal text-[#E5E9EC]">
                <!--                <span class="opacity-40" v-if="lastUpdated">Updated: 7/29/2022 at 9:01 AM</span>-->
                <span class="opacity-40" v-if="lastUpdated">Updated: {{ lastUpdated }}</span>
              </div>
            </div>
            <!-- Fundamentals&nbsp;>&nbsp;SP500&nbsp;>&nbsp;US&nbsp;>&nbsp;P/E&nbsp;Ratio&nbsp; -->
            <div class="flex justify-end items-center gap-[.6vw] h-full">
              <!-- <button
                class="text-[#E5E9EC] opacity-50  border border-1 border-[#E5E9EC] rounded self-center  cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                REMOVE WATCHLIST
              </button> -->
              <!-- v-if="watch_List_Api_Data.length > 0" -->
              <button v-if="watch_List_Api_Data.length > 0" :disabled="getStockListData.length < 1"
                :title="getStockListData.length < 1 ? 'Select Stock' : 'Add To Watchlist'"
                @click="SaveWatchlistModal('add')"
                class="text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                + ADD TO WATCHLIST
              </button>
              <button v-if="!watch_List_Api_Data.length" :disabled="getStockListData.length < 1"
                :title="getStockListData.length < 1 ? 'Select Stock' : 'Add To Watchlist'"
                @click="SaveWatchlistModal('create')"
                class="text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                + ADD TO WATCHLIST
              </button>
              <template v-if="favScanLength > 0">
                <button v-if="!currentFavSaved && favouriteList.length < 4" title="ADD TO FAV" @click="AddToFav"
                  class="text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                  + ADD TO FAV
                </button>
                <button v-if="currentFavSaved" title="ADD TO FAV" @click="RemoveFromFav"
                  class="text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                  + REMOVE FROM FAV
                </button>
              </template>

              <div class=" flex justify-end items-center gap-[.5vw]"
                :class="stocksListTableData.length > 0 ? '' : 'hidden'">
                <!-- <button @click="handleBackArrow" :class="page <= 1 ? ['hidden'] : ''">
                  <font-awesome-icon style="color: #e5e9ec; " :icon="['fas', 'chevron-left']"
                    class="market-heat-header-font text-xs" />
                </button> -->
                <select name="data" v-model="length" class=" text-center bg-black text-white"
                  @change="handleChange(length)">
                  <option v-for="(num, i) of dataValues" :key="i" :value="num">
                    {{ num }}
                  </option>
                </select>
                <!-- <button @click="handleForwardArrow" :class="page >= 10 ? ['hidden'] : ''">
                  <font-awesome-icon style="color: #e5e9ec; " :icon="['fas', 'chevron-right']"
                    class="market-heat-header-font text-xs" />
                </button> -->
              </div>
              <tailwind-modal :show-modal="showWatchlistModal" v-if="showWatchlistModal">
                <div
                  style="display:flex; flex-direction:column; gap:3vh;justify-content: space-between; height:20vh; padding: 1vw; ">
                  <div style="display:flex; flex-direction: column; flex-grow: 8;gap:1vh; height: 80%;">

                    <div style="display:flex; flex-direction:column; gap:1vh;">
                      <h1 class=" text-[1vw] "> Save to watchlist</h1>

                    </div>
                    <div style="display:flex; flex-direction:column; gap:2vh; height:100%; ">
                      <select class="text-[black] h-[3vh] w-full" @change="selectWatcRowIndex">


                        <option value=""><span>--Select Watchlist--</span> </option>
                        <option v-for="(names, i) in watch_List_Api_Data" :key="i" :value="i">{{ names.name }}</option>

                      </select>
                    </div>
                  </div>

                  <div class="flex justify-between gap-[.5vw] items-center flex-grow-[1] h-[20%]">

                    <button class="cancel-button w-[45%] h-full text-[.8vw]" @click="showWatchlistModal = false">Cancel</button>
                    <button class="ok-button  w-[45%] h-full text-[.8vw]" @click="SaveWatchlist">Save</button>
                  </div>
                </div>
              </tailwind-modal>
              <tailwind-modal :show-modal="createWatclistModal" v-if="createWatclistModal">
                <div
                  style="display:flex; flex-direction:column;justify-content: space-between; height:20vh; padding: 1vw">
                  <div style="display:flex; flex-direction: column; flex-grow: 8;gap:1vh; height:70%;">

                    <div style="display:flex; flex-direction:column; gap:1vh;">
                      <h1 class=" text-[1vw] "> Create watchlist</h1>

                    </div>
                    <div style="display:flex; flex-direction:column; gap:2vh; height:100%; ">
                      <input type="text" placeholder="create watchlist" class="p-[.2vw] rounded text-[black]"
                        v-model="newWatchName">

                    </div>
                  </div>

                  <div class="flex justify-evenly gap-[1.5vw] items-center flex-grow-[1]  h-[30%]">

                    <button class="cancel-button h-[70%] w-[50%] text-[.7vw]"
                      @click="createWatclistModal = false">Cancel</button>
                    <button class="ok-button  h-[70%] w-[50%] text-[.7vw]" @click="SaveCreateWatchlist">Save</button>
                  </div>
                </div>
              </tailwind-modal>
            </div>

          </div>


          <div class="w-full h-[90%] p-2 min-h-fit overflow-x-hidden overflow-y-auto"  v-if="isLoading">
            <SkeletonTableLoader :rows="18" screen="large" />
          </div>

          <div v-if="showError" class="h-[90%] flex w-full">
                    <AlertShort
                      alertType="Warning"
                      title="No Data Found"
                      class="m-auto"
                    /> 
          </div>

          <div v-if="stocksListTableData.length>0 && isLoading == false" class=" h-[90%] min-h-fit overflow-scroll rounded-b-md p-[.5vw]">

            <table class="w-full border-[#7582785c] table" >
              <thead class="border-b-[#7582785c] sticky top-0 bg-[#151515] w-full mb-10">


                <tr class="">

                  <th class="w-[1%] " style="border-bottom-color: #3d3a3a; font-size: 0.9vw; padding-right:15px;"
                    v-if="columnConfig.length > 0">
                    <label class="container">
                      <input type="checkbox" class="" v-model="checkAll" />
                      <span class="checkmark"></span>
                    </label>
                  </th>

                  <th
                    class="text-[.6vw] bg-[#151515]  font-bold text-white select-none border-[#7582785c] w-[2%] pl-2">
                    Chart
    
                  </th>
                  <th :key="i" v-for="(x, i) of columnConfig" @click.prevent="updateSort(x.name, currentSort?.order, i)"
                    class="text-[.6vw] font-bold text-white border-[#7582785c] cursor-pointer select-none" :class="`w-[${x.width}%] pl-2`"
                    :style="[x.type == 'string' ? { 'text-align': 'left' } : { 'text-align': 'left' }]">

                    <div class="flex gap-[.2vw] items-center ">
                      {{ x.label }}
                      <table-sort-icon :order="currentSort?.order" v-if="currentSort?.name === x.name" />
                    </div>
                    <!--                    <img src="images/sorticon.svg" alt="sort" class="w-[.75vw] inline" />-->
                  </th>

                </tr>
              </thead>
              <tbody>

                <tr :key="i" v-for="(x, i) of stocksListTableData" class="border-b-[1px] border-[#7582785c] h-[5vh] pl-2">

                  <td style="border-bottom-color: #3d3a3a; font-size: 0.9vw" class=""
                   v-if="columnConfig.length > 0">

                   <!-- :checked="watchListIDs.includes(x.symbol) || checkAll" /> -->
                    <label class="container w-[1%]">
                      <input type="checkbox" class="" @change="CheckRowTrue($event.target.checked, i, x)"
                        :checked="checkAll || singleInput"  />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td  v-if="stocksListTableData.length > 0">
                    <div class="flex justify-center items-center pl-2">

                   
                    <a :href="`/chart?t=${x.symbol}`" class="">
                      <svg width="18" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_571_8639)">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.76867 13.7519L5.73489 10.6234L7.5432 12.1031C7.73859 12.2629 8.02254 12.2518 8.20483 12.0771L11.9751 8.4635L15.2449 12.0779C15.4298 12.2823 15.7458 12.2981 15.9501 12.1132C16.1544 11.9284 16.1703 11.6124 15.9854 11.408L12.3708 7.41252C12.2803 7.31251 12.1532 7.25334 12.0184 7.24855C11.8835 7.24371 11.7525 7.29371 11.6551 7.38701L7.83322 11.0502L6.00804 9.55676C5.80556 9.39108 5.50957 9.40983 5.32957 9.59963L2.04409 13.0649C1.85451 13.2648 1.86292 13.5811 2.06284 13.7707C2.26281 13.9603 2.57909 13.9518 2.76867 13.7519V13.7519Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.67902 6.75151L9.31929 6.38751L10.9887 6.159L10.7406 7.82565L10.3809 7.46165L7.98564 9.82908C7.80186 10.0107 7.50986 10.0221 7.3125 9.85539L5.49434 8.31964L2.50778 11.5715C2.32134 11.7744 2.00527 11.7878 1.80232 11.6014C1.59936 11.415 1.58594 11.099 1.77233 10.896L5.0829 7.29151C5.26396 7.09436 5.56831 7.07508 5.77281 7.24778L7.60822 8.79813L9.67902 6.75151V6.75151Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.9813 4.43245C17.9813 3.97809 17.6124 3.60919 17.158 3.60919H0.82326C0.368894 3.60919 0 3.97809 0 4.43245V15.1063C0 15.5607 0.368894 15.9295 0.82326 15.9295H17.158C17.6124 15.9295 17.9813 15.5607 17.9813 15.1063V4.43245V4.43245ZM0.998521 4.60771V14.931H16.9827V4.60771H0.998521Z"
                            fill="#02BD2D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_571_8639">
                            <rect width="18" height="18" fill="white" transform="translate(0 0.786682)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>

                  </td>

                  <!-- :title="x[column.name]" -->
                  <td v-for="(column, i) of columnConfig" :key="i" 
                  :style="[column.type == 'string' ? { 'text-align': 'left' } : { 'text-align': 'left' },
                 columnHighlight == i ? {'background-color':'#3a3a3a5c'}:{} ,
                     
 
                     ]"
                    class="text-[.65vw] font-medium text-white border-[#7582785c] pl-2">
                    <span v-if="x[column.name] == `null`" class="text-pink-500">Not available</span>
                    <span v-else v-html="x[column.name]"></span> 
                    <!-- <span v-html="x[column.name]==null? 'N/A' :x[column.name]"></span>  -->
                    <!-- {{  x[column.type]==='percent' ? x[column.name]+ '%' : x[column.type]==='int' ? x[column.name].toLocaleString('en-US'): x[column.name] }} -->


                  </td>

                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import watchListApi from "@/api/watchListApi";
import TailwindModal from "@/components/popups/TailwindModal";
import TreeView from "../tree/TreeView.vue";
import { mapGetters } from "vuex";
import TreeBrowser from "@/components/tree/TreeBrowser";
import FivesApi from "@/api/fivesApi";
import moment from "moment";
import { Tree_event } from "@/components/tree/tree_event";
import SvgListIcon from "@/components/tree/SvgListIcon";
import TableSortIcon from "@/components/tables/TableSortIcon";
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue"
import AlertShort from "@/components/utilities/AlertShort.vue"

export default {
  name: "StockListPage",
  components: {
    SkeletonTableLoader,
    TableSortIcon,
    SvgListIcon,
    TreeBrowser,
    TailwindModal,
    AlertShort,
    // VFolder
    TreeView,
  },
  data() {
    return {
      showError:false,
      isLoading:true,
      singleInput:null,
      newWatchName: '',
      createWatclistModal: false,
      colHighlight: false,
      columnHighlight: null,
      isBackArrowActive: false,
      isNextArrowActive: false,
      page: 1,
      nodeMeta: "",
      currentSort: {
        name: "",
        order: 0,
      },
      start: 0,
      length: 10,
      dataValues: [10, 20, 50],
      perPage: 10,
      checkAll: false,
      getStockListData: [],
      // watchListIDs: [],
      selectwatchIndex: null,
      watch_List_Api_Data: {},
      showWatchlistModal: false,
      selectedStock: [],
      newConfig: [],
      image: "images/premium.png",
      fileType: "StockList",
      textColor: "gray",
      stocksListTableData: [],
      currentFav: {
        idMapList: [],
        desc: "",
        favColor: "",
        saved: false
      },
      info: {
        name: "",
        desc: ``
      },
      currentFavSaved: false,
      favouriteList: [],
      favColorMap: ["light_blue", "orange", "purple", "yellow"],
      lastUpdated: null,
      columnConfig: [],
      uid: 0,
    };
  },
  //owais
  methods: {
    async SaveCreateWatchlist() {
      
      
      try {
        const payload = {
          name: this.newWatchName,
          symbolList: this.getStockListData,
        }
        console.log(payload)
        
        const res = await watchListApi.createWatchList(payload)
        // console.log("res",res)

        if (res.data.success) {
          
          const res1 = await watchListApi.fetchWatchListData();
          this.watch_List_Api_Data = res1.data.payload;
          console.log("this.watch_List_Api_Data",this.watch_List_Api_Data)
        }
        const payload2 = {
            symbolList: this.getStockListData,
           
            watchId: this.watch_List_Api_Data[0]._id,
          }
          console.log('payload2', payload2)


          const res2 = await watchListApi.updateWatchListRecord(payload2);
          if(res2.data.success){
            this.createWatclistModal = false
            this.getStockListData=[]
          }



      }
      catch (err) {
        console.log(err)
        this.createWatclistModal = false
      }
      
    },
    navigateToTreeFromList(data) {
      // console.log("navigateToTreeFromList",data.idMapList,data.idMapList[0])
      this.navigateToTree(data.idMapList, data.idMapList[0])
    },
    navigateToTree(nodePath, baseNode) {
      // console.log('navigateToTree',nodePath,baseNode)
      let refNode = this
      let nodeid = nodePath[0]
      let startsWith = nodeid.startsWith(baseNode);
      const clickRef = (refNode, lastNode) => {
        // console.log('find node params',lastNode,refNode[0].nodeRef,refNode[0].expanded , refNode[0].isFolderType)
        if (refNode && refNode[0] && refNode[0].nodeRef) {
          if (refNode[0].expanded && refNode[0].isFolderType) {
            return refNode[0]
          }
          if (refNode[0].isActiveColor && refNode[0].isFileType) {
            return refNode[0]
          }
          refNode[0].nodeRef.click()
          // lastNode ? refNode[0].nodeRef.click() : refNode[0].nodeClicked()
          return refNode[0]
        }
      }
      let len = nodePath.length - 1
      for (const id in nodePath) {
        nodeid = nodePath[id]
        if (startsWith && refNode) {
          refNode = refNode.$refs[nodeid]
          refNode = clickRef(refNode, len === Number(id));
        }
      }
    },
    treeLoad(baseNode) {
      let nodePath = ['stl', 'stl_au', 'stl_au_all_ords', 'FIVE_DAYS_CHANGE']
      this.navigateToTree(nodePath, baseNode);
    },
    treeEvent(node, map, criteria, id, idMap) {
      if(this.singleInput ==  false){
        this.singleInput =  null
      }
      else{
        this.singleInput =  false
      }
      this.checkAll = false
      console.log("node",node)

      this.selectedStock = map
      let isFile = node?.type === 'file';
      if (isFile) {
        Tree_event.$emit('disableTree', node.id)
      }
      if (isFile && node.children?.length === 0 && node.name) {
        this.info = {
          desc: node.desc,
          name: node.name,
        }
      }
      if (node?.metaData?.category && criteria && Object.keys(criteria).length > 0 && !node?.isMainNode) {
        node.metaData.criteria = criteria
        // console.log('idMap',idMap)
        let favDesc = map.join(">");
        let hasFav = this.favouriteList.find(d => d.desc === favDesc)
        this.currentFavSaved = !!hasFav
        this.currentFav = {
          idMapList: idMap,
          desc: favDesc,
          favColor: ""
        }
        this.currentSort = {
          name: "",
          order: 0,
        }
        this.getStockList(node.metaData, this.length);
        console.log("node.metaData",node.metaData);
      }
    },
    async AddToFav() {
      let map = this.favColorMap
      let favLen = this.favouriteList.length
      this.currentFavSaved = true;
      this.currentFav.favColor = map[favLen]
      let favouriteListApi = this.favouriteList
      favouriteListApi.push(this.currentFav)
      if (await FivesApi.saveFavScans(favouriteListApi, 'stock')) {
        this.favouriteList = favouriteListApi
      }

      console.log('idMapList', this.currentFav)

    },
    async RemoveFromFav() {
      let filterData = this.favouriteList.filter(d => d.desc !== this.currentFav.desc)
      if (filterData > 0) {
        let map = this.favColorMap
        let favouriteListApi = filterData.map((v, i) => {
          v.favColor = map[i]
        })
        if (await FivesApi.saveFavScans(favouriteListApi, 'stock')) {
          this.favouriteList = favouriteListApi
        }
      } else {
        if (await FivesApi.saveFavScans(filterData, 'stock')) {
          this.favouriteList = filterData
        }
      }
      this.currentFavSaved = false;
      console.log('idMapList', this.currentFav)
    },
    formatDate(d) {
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY")
    },
    handleChange(value) {
      if (this.nodeMeta)
        this.getStockList(this.nodeMeta, value)
    },
    updateSort(value, sort, index) {
      this.columnHighlight = index
      // if(this.columnHighlight.includes(index)){
      //   this.columnHighlight[index] = null
      //   console.log("match hua")
      // }
      // else{
      //   this.columnHighlight[index] = index
      // }





      if (this.nodeMeta) {
        let order = sort === 0 || sort === -1 ? 1 : sort === 1 ? -1 : 1;
        this.currentSort = {
          name: value,
          order: order
        }
        let newSort = {};
        newSort[value] = order

        this.getStockList(this.nodeMeta, this.length, newSort)
      }
    },
    handleBackArrow() {

      if (this.perPage > 10) {
        this.page -= 1
        this.perPage -= 10
        console.log(this.perPage)
      }
    },
    handleForwardArrow() {

      if (this.perPage >= 0) {
        this.page += 1
        this.perPage += 10
        console.log(this.perPage)
      }

    },
    selectWatcRowIndex(e) {
      console.log(e.target.value)
      this.selectwatchIndex = e.target.value
    },
    async SaveWatchlist() {

      this.showWatchlistModal = false

      try {
        const payload = {
          
          watchId: this.watch_List_Api_Data[this.selectwatchIndex]._id,
          symbolList: this.getStockListData
        }
        console.log(payload)
      
        const res = await watchListApi.updateWatchListRecord(payload);
        if (res.data.success) {
          this.getStockListData = []
       
        }
      }
      catch (err) {
        console.log('market heat modal update-symbol-api destroyed');
      }

      this.getStockListData = []
      // this.watchListIDs = []
    },

    async SaveWatchlistModal(type) {

      try {
       
        const res = await watchListApi.fetchWatchListData();
        this.watch_List_Api_Data = res.data.payload;
        console.log("...api in stoclist ", this.watch_List_Api_Data)

      } catch (err) {
        console.log("something went wrong", err);
      }
      if (type == 'add') {
        this.showWatchlistModal = true
      }
      else if (type == 'create') {
        this.createWatclistModal = true
      }

    },

    CheckRowTrue(checked, i, row) {

      console.log(".........", row)
      if (checked) {
        this.getStockListData.push({ companyName: row.company_name, id: row.exchange, name: this.stocksListTableData[i].symbol });
        console.log(".... owais ", this.getStockListData)
        // this.watchListIDs.push(this.stocksListTableData[i].symbol);
      } else if (!checked) {
        for (let v in this.getStockListData) {
          if (this.getStockListData[v].name === this.stocksListTableData[i].symbol) {
            this.getStockListData.splice(v, 1)
            // this.watchListIDs.splice(v, 1)
            break
          }
        }
        
      }
    },



    async getStockList(nodeMeta, length = 10, sorted = null) {
      this.nodeMeta = nodeMeta;
      this.showError = false
      this.isLoading = true
      let response = await FivesApi.fetchStockListByCategory(nodeMeta, length, this.start, sorted);
      console.log("response ", response)
      let res = response.data;
    
      let _data = []
      res.data.map((x, _i) => {
      
        response.columns.map((column, _i) => {
          if (column.width)
            response.columns[_i].width = 10

          if (column.type)
            response.columns[_i].type = "string"

          switch (column.type) {
            // case "string":
            // x[column.name] = x[column.name];
            // break;
            case "int":
              if (parseInt(x[column.name]) < 0)
                x[column.name] = `<span style="color:red">${x[column.name].toLocaleString("en-US")}</span>`
              else
                x[column.name] = x[column.name].toLocaleString("en-US");
              break;
            case "float":
              if (parseFloat(x[column.name]) < 0 && parseFloat(x[column.name])!==null){
                console.log("parseFloat(x[column.name]",column.name,x[column.name],parseFloat(x[column.name]))

                x[column.name] = `<span style="color:red">${x[column.name].toFixed(2).toLocaleString("en-US")}</span>`
              }

              else{

                x[column.name] = x[column.name]===null?"Not Available":x[column.name].toLocaleString("en-US")
              }

              break;
            case "percent":
              if (parseFloat(x[column.name]) < 0 && parseFloat(x[column.name])!==null){

                x[column.name] = `<span style="color:red">${x[column.name].toFixed(2).toLocaleString("en-US")}</span>`
              }
              else{

                x[column.name] = x[column.name].toLocaleString("en-US");
              }
              break;
            default:
              x[column.name] = x[column.name] + "";
          }

        })
        _data.push({ ...x })
        // console.log(_data)

      })
      this.columnConfig = response.columns;
      this.stocksListTableData = _data//res.data;
      if(this.stocksListTableData.length>0){
        this.showError = false
        this.isLoading=false
      }else{
        this.showError = true
      }
      this.isLoading=false
      // this.showError = true
      this.lastUpdated = this.formatDate(response.lastUpdated);
      this.getStockListData = []
      // this.watchListIDs = []
    },
    async getFolders() {
      let result = await FivesApi.fetchStockListFolders()
      // console.log("get folders ",result);
      this.newConfig = result.data

      // this.obj.folder = result.data
    },
    payload() { },
    onChange(result) { },
  },
  computed: {
    ...mapGetters(["get_Selected_Stoks", "userIsNotPremium"]),
    favScanLength() {
      return this.currentFav.idMapList.length
    }
  },
  watch: {
    checkAll(newVal) {

      if (newVal) {
        this.getStockListData = []
        // this.watchListIDs = []
        for (let i of this.stocksListTableData) {
          this.getStockListData.push({ id: i.exchange, name: i.symbol, companyName:i.company_name });
          console.log("this.nodeMeta.exchange ",this.getStockListData)
        }
      }
      else {
        this.getStockListData = []
        // this.watchListIDs = []
      }
    },
    length(val){
      
      if(val &&  this.singleInput ==  false){
        this.singleInput =  null
      }
      else{
        this.singleInput =  false
      }
      this.checkAll = false
      // this.singleInput =  null
    }
  },
  async mounted() {
    this.favouriteList = await FivesApi.getFavScans('stock')
    await this.getFolders();

    // fetch watchlist data 

    try {
     
      const res = await watchListApi.fetchWatchListData();
      this.watch_List_Api_Data = res.data.payload;

    } catch (err) {
      console.log("something went wrong", err);
    }
  },
};
</script>

<style scoped>
.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
  /* width: 20px !important; */
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}


.container {
  display: block;
  position: relative;
  /* right: 2px; */
  cursor: pointer;
  width: 0.1em;
  height: 0.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}


.container input:checked~.checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}


.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}


.container input:checked~.checkmark:after {
  display: block;
}


.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
}


/* @media screen and (max-width: 900px) {
  .btnsize {
    margin-left: 10px;
  }
} */
th {
  padding-right: 10px !important;
  padding-bottom: 10px;
}

td {
  padding-right: 10px !important;
}

.maindiv {}

/* [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    background-color: #00FF00;
    color: #111;
    font-size: 150%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
} */
[data-title]:hover {
  color: red;

}
</style>
