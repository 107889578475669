export default {
    data:{
        data:[
            {
                id: "628e4cbc247432f12b936777",
                name: "10/10/21",
                symbolList: [
                    {
                        id: "ASX",
                        name: "5GG.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Communication Services",
                            marketCap: 68.8608,
                            peRatio: -15.3,
                            eps: -0.021,
                            earningYield: -6.5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 215190011,
                            return1m: 20.8,
                            return3m: -11.1,
                            return6m: -35.4,
                            return12m: -48.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AAC.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Staples",
                            marketCap: 1109.091,
                            peRatio: 7.9,
                            eps: 0.229,
                            earningYield: 12.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 602766747,
                            return1m: -18.2,
                            return3m: 8,
                            return6m: 20.2,
                            return12m: 29.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ANX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 31.639,
                            peRatio: 6.4,
                            eps: 0.012,
                            earningYield: 15.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 405628160,
                            return1m: -10.6,
                            return3m: -10.6,
                            return6m: -2.6,
                            return12m: -7.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ATL.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 73.52961,
                            peRatio: -5.8,
                            eps: -0.067,
                            earningYield: -17.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 0,
                            issue: 186150908,
                            return1m: -25,
                            return3m: -32.2,
                            return6m: -33.9,
                            return12m: 4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AZS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 60.59347,
                            peRatio: -3,
                            eps: -0.066,
                            earningYield: -33,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 310735721,
                            return1m: -23.1,
                            return3m: -42.9,
                            return6m: -42.9,
                            return12m: -16.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BNR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 34.81093,
                            peRatio: 4.5,
                            eps: 0.023,
                            earningYield: 22.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 292591100,
                            return1m: -38.2,
                            return3m: -48.8,
                            return6m: 61.5,
                            return12m: 64.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CSR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 2053.169,
                            peRatio: 7.5,
                            eps: 0.555,
                            earningYield: 13.3,
                            tradingBasis: "",
                            dividendRate: 0.315,
                            dividendYield: 7.4,
                            frankedPct: 100,
                            issue: 485382776,
                            return1m: -10.9,
                            return3m: -30,
                            return6m: -26.7,
                            return12m: -25.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CST.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 32.95217,
                            peRatio: -25.5,
                            eps: -0.006,
                            earningYield: -3.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 199710121,
                            return1m: -17.1,
                            return3m: -9.4,
                            return6m: -23.7,
                            return12m: -35.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CXO.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1775.927,
                            peRatio: -250.6,
                            eps: -0.004,
                            earningYield: -0.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1732611716,
                            return1m: -23.8,
                            return3m: -18.1,
                            return6m: 84.3,
                            return12m: 332.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "DEG.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1225.694,
                            peRatio: -135.1,
                            eps: -0.006,
                            earningYield: -0.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1408843525,
                            return1m: -22.5,
                            return3m: -30.1,
                            return6m: -29.5,
                            return12m: -31.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "DNA.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 42.00324,
                            peRatio: -1.6,
                            eps: -0.021,
                            earningYield: -62.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 0,
                            issue: 1235389382,
                            return1m: 0,
                            return3m: -17.1,
                            return6m: -10.5,
                            return12m: -12.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FFX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 236.2486,
                            peRatio: -4.3,
                            eps: -0.046,
                            earningYield: -23,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1181243221,
                            return1m: -56.2,
                            return3m: -52.3,
                            return6m: -41.8,
                            return12m: -6.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FYI.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 59.45348,
                            peRatio: -14,
                            eps: -0.012,
                            earningYield: -7.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 365867589,
                            return1m: -19.5,
                            return3m: -31.2,
                            return6m: -48.4,
                            return12m: -68,
                        },
                    },
                    {
                        id: "ASX",
                        name: "GEV.au",
                        metaData: null,
                    },
                    {
                        id: "ASX",
                        name: "GLN.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 302.8774,
                            peRatio: -126.6,
                            eps: -0.008,
                            earningYield: -0.8,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 304399362,
                            return1m: -8.3,
                            return3m: -50.3,
                            return6m: -47.3,
                            return12m: -1,
                        },
                    },
                    {
                        id: "ASX",
                        name: "IGO.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 7837.722,
                            peRatio: 49.8,
                            eps: 0.202,
                            earningYield: 2,
                            tradingBasis: "",
                            dividendRate: 0.15,
                            dividendYield: 1.4,
                            frankedPct: 100,
                            issue: 757267813,
                            return1m: -17,
                            return3m: -27.4,
                            return6m: -11,
                            return12m: 34.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "NIC.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 2826.868,
                            peRatio: 13.7,
                            eps: 0.073,
                            earningYield: 7.3,
                            tradingBasis: "",
                            dividendRate: 0.04,
                            dividendYield: 3.7,
                            frankedPct: 0,
                            issue: 2731273497,
                            return1m: -18.5,
                            return3m: -21.7,
                            return6m: -29.4,
                            return12m: 4.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "OPY.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Financials",
                            marketCap: 23.7419,
                            peRatio: -0.2,
                            eps: -0.642,
                            earningYield: -442.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 158279339,
                            return1m: -38.3,
                            return3m: -58,
                            return6m: -80.7,
                            return12m: -90.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "S2R.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 51.67435,
                            peRatio: -5.4,
                            eps: -0.026,
                            earningYield: -18.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 356374855,
                            return1m: -20,
                            return3m: -18.8,
                            return6m: -24.3,
                            return12m: 16.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SYA.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1153.947,
                            peRatio: 12.1,
                            eps: 0.013,
                            earningYield: 8.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 8246752670,
                            return1m: -25,
                            return3m: -28.4,
                            return6m: 26,
                            return12m: 113.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "WC8.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 16.705,
                            peRatio: -10.6,
                            eps: -0.002,
                            earningYield: -9.5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 645272727,
                            return1m: -21.2,
                            return3m: -23.5,
                            return6m: -50.9,
                            return12m: 0,
                        },
                    },
                ],
                userId: "628e48a0104d478f233ab612",
            },
            {
                id: "628e4cbc247432f12b936778",
                name: "20220320",
                symbolList: [
                    {
                        id: "ASX",
                        name: "AGY.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 467.8376,
                            peRatio: 244.4,
                            eps: 0.001,
                            earningYield: 0.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1356050987,
                            return1m: -26.7,
                            return3m: -17.5,
                            return6m: 20,
                            return12m: 243.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ALK.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 402.0188,
                            peRatio: 5.2,
                            eps: 0.125,
                            earningYield: 19.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 595583420,
                            return1m: -38.3,
                            return3m: -40.3,
                            return6m: -29.9,
                            return12m: -43.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ARR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 91.01214,
                            peRatio: -157.4,
                            eps: -0.002,
                            earningYield: -0.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 395704936,
                            return1m: -25,
                            return3m: -40.7,
                            return6m: 54.5,
                            return12m: 226.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AVL.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 130.0482,
                            peRatio: -22.7,
                            eps: -0.001,
                            earningYield: -4.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 3940855932,
                            return1m: -27.3,
                            return3m: -48.4,
                            return6m: 14.3,
                            return12m: 68.4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AZL.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 200.9447,
                            peRatio: -14.8,
                            eps: -0.006,
                            earningYield: -6.8,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2232719212,
                            return1m: -44,
                            return3m: -44,
                            return6m: -6.7,
                            return12m: 333.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CDT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 25.98682,
                            peRatio: -10.8,
                            eps: -0.003,
                            earningYield: -9.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 999492984,
                            return1m: 0,
                            return3m: -50.9,
                            return6m: -30.8,
                            return12m: 80,
                        },
                    },
                    {
                        id: "ASX",
                        name: "COB.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 211.988,
                            peRatio: -48.3,
                            eps: -0.013,
                            earningYield: -2.1,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 321193916,
                            return1m: -25.5,
                            return3m: -20.6,
                            return6m: 48.2,
                            return12m: 123.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CRN.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 2900.265,
                            peRatio: 4.4,
                            eps: 0.399,
                            earningYield: 22.6,
                            tradingBasis: "",
                            dividendRate: 0.085,
                            dividendYield: 4.4,
                            frankedPct: 0,
                            issue: 966500000,
                            return1m: -16.1,
                            return3m: -13,
                            return6m: 57.2,
                            return12m: 133.5,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CXO.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1775.927,
                            peRatio: -250.6,
                            eps: -0.004,
                            earningYield: -0.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1732611716,
                            return1m: -23.8,
                            return3m: -18.1,
                            return6m: 84.3,
                            return12m: 332.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "DYL.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Energy",
                            marketCap: 245.8709,
                            peRatio: -35.8,
                            eps: -0.017,
                            earningYield: -2.8,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 387198206,
                            return1m: -14.2,
                            return3m: -37.6,
                            return6m: -36.3,
                            return12m: -14.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "EL8.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Energy",
                            marketCap: 107.4433,
                            peRatio: -18.9,
                            eps: -0.02,
                            earningYield: -5.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 275495717,
                            return1m: -20,
                            return3m: -30.9,
                            return6m: -7.3,
                            return12m: 22.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FFX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 236.2486,
                            peRatio: -4.3,
                            eps: -0.046,
                            earningYield: -23,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1181243221,
                            return1m: -56.2,
                            return3m: -52.3,
                            return6m: -41.8,
                            return12m: -6.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "GEM.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 957.5511,
                            peRatio: 20.5,
                            eps: 0.054,
                            earningYield: 4.9,
                            tradingBasis: "",
                            dividendRate: 0.03,
                            dividendYield: 2.7,
                            frankedPct: 100,
                            issue: 847390315,
                            return1m: 5.8,
                            return3m: 3.3,
                            return6m: -0.1,
                            return12m: 15.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "LPI.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 143.1494,
                            peRatio: -9,
                            eps: -0.044,
                            earningYield: -11.1,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 349144848,
                            return1m: -30.1,
                            return3m: -38.3,
                            return6m: -4.8,
                            return12m: 125.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "LRS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 131.2443,
                            peRatio: -10.7,
                            eps: -0.006,
                            earningYield: -9.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1930063738,
                            return1m: -42.6,
                            return3m: -13.2,
                            return6m: 135.7,
                            return12m: 37.5,
                        },
                    },
                    {
                        id: "ASX",
                        name: "MGX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 629.4954,
                            peRatio: -8.4,
                            eps: -0.064,
                            earningYield: -11.9,
                            tradingBasis: "",
                            dividendRate: 0.02,
                            dividendYield: 3.6,
                            frankedPct: 100,
                            issue: 1210568033,
                            return1m: -20,
                            return3m: -15,
                            return6m: 30.1,
                            return12m: -39.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "PRU.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 2318.292,
                            peRatio: 10.4,
                            eps: 0.155,
                            earningYield: 9.6,
                            tradingBasis: "",
                            dividendRate: 0.008,
                            dividendYield: 0.5,
                            frankedPct: 0,
                            issue: 1359701713,
                            return1m: -15.5,
                            return3m: -16.6,
                            return6m: -0.2,
                            return12m: 13.4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "PSC.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 435.5905,
                            peRatio: -78.3,
                            eps: -0.013,
                            earningYield: -1.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 0,
                            issue: 446759462,
                            return1m: 2.1,
                            return3m: 12,
                            return6m: 29.8,
                            return12m: 415.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "RED.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 671.5628,
                            peRatio: -17.8,
                            eps: -0.015,
                            earningYield: -5.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2356360652,
                            return1m: -29.9,
                            return3m: -28,
                            return6m: -3.6,
                            return12m: 45.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "RNU.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Energy",
                            marketCap: 344.6774,
                            peRatio: -200,
                            eps: -0.001,
                            earningYield: -0.5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2154233531,
                            return1m: -33.3,
                            return3m: -46.4,
                            return6m: 25,
                            return12m: 127.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "WAF.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1255.867,
                            peRatio: 5.9,
                            eps: 0.207,
                            earningYield: 17,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1021029743,
                            return1m: -2.8,
                            return3m: -5.8,
                            return6m: -6.9,
                            return12m: 22.7,
                        },
                    },
                ],
                userId: "628e48a0104d478f233ab612",
            },
            {
                id: "628e4cbc247432f12b936779",
                name: "Indices",
                symbolList: [],
                userId: "628e48a0104d478f233ab612",
            },
            {
                id: "628e4cbc247432f12b93677a",
                name: "WB 200810",
                symbolList: [
                    {
                        id: "ASX",
                        name: "3DP.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 169.4516,
                            peRatio: -58.4,
                            eps: -0.004,
                            earningYield: -1.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 677806204,
                            return1m: 38.9,
                            return3m: -5.7,
                            return6m: -30.6,
                            return12m: -46.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "92E.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Energy",
                            marketCap: 34.38905,
                            peRatio: -5.3,
                            eps: -0.076,
                            earningYield: -18.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 81878696,
                            return1m: -26.4,
                            return3m: -48.7,
                            return6m: -37.2,
                            return12m: 39.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "A2M.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Staples",
                            marketCap: 3288.832512,
                            peRatio: 168.3,
                            eps: 0.026,
                            earningYield: 0.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 743656528,
                            return1m: 3.3,
                            return3m: -7.7,
                            return6m: -20,
                            return12m: -38.4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ADH.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 388.8883,
                            peRatio: 10.8,
                            eps: 0.213,
                            earningYield: 9.2,
                            tradingBasis: "",
                            dividendRate: 0.18,
                            dividendYield: 7.8,
                            frankedPct: 100,
                            issue: 171316433,
                            return1m: 34.9,
                            return3m: -19.3,
                            return6m: -40.5,
                            return12m: -36.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AMI.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 327.8199,
                            peRatio: 10.8,
                            eps: 0.025,
                            earningYield: 9.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 100,
                            issue: 1237056457,
                            return1m: -20.9,
                            return3m: -44.8,
                            return6m: -39.8,
                            return12m: -29.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AR9.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 36.93245,
                            peRatio: -4.6,
                            eps: -0.032,
                            earningYield: -21.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 263803207,
                            return1m: -3.3,
                            return3m: -17.1,
                            return6m: -21.6,
                            return12m: -39.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ARR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 91.01214,
                            peRatio: -157.4,
                            eps: -0.002,
                            earningYield: -0.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 395704936,
                            return1m: -25,
                            return3m: -40.7,
                            return6m: 54.5,
                            return12m: 226.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "AZS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 60.59347,
                            peRatio: -3,
                            eps: -0.066,
                            earningYield: -33,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 310735721,
                            return1m: -23.1,
                            return3m: -42.9,
                            return6m: -42.9,
                            return12m: -16.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BET.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 343.3149,
                            peRatio: -6.9,
                            eps: -0.05,
                            earningYield: -14.4,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 904456199,
                            return1m: -28.9,
                            return3m: -43.9,
                            return6m: -58.4,
                            return12m: -70,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BGL.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 722.0023,
                            peRatio: -39.6,
                            eps: -0.016,
                            earningYield: -2.5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1031431826,
                            return1m: -25.9,
                            return3m: -34.2,
                            return6m: -23.7,
                            return12m: -34.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BRN.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 1585.785,
                            peRatio: -51.9,
                            eps: -0.016,
                            earningYield: -1.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1714362144,
                            return1m: -22.4,
                            return3m: -6.9,
                            return6m: 27.7,
                            return12m: 60.5,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BSX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 94.28951,
                            peRatio: -3.2,
                            eps: -0.057,
                            earningYield: -31.5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 471447565,
                            return1m: -39,
                            return3m: -52,
                            return6m: -67.3,
                            return12m: -47.1,
                        },
                    },
                    {
                        id: "ASX",
                        name: "BTH.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 303.7926,
                            peRatio: -12.2,
                            eps: -0.042,
                            earningYield: -8.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 552350154,
                            return1m: 1,
                            return3m: -37.8,
                            return6m: -48.5,
                            return12m: -53.1,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CAE.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 120.092,
                            peRatio: 17.7,
                            eps: 0.011,
                            earningYield: 5.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 533742074,
                            return1m: -22.4,
                            return3m: -38.7,
                            return6m: 11.8,
                            return12m: 222,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CAT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 176.2628,
                            peRatio: -3.8,
                            eps: -0.198,
                            earningYield: -26.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 231924764,
                            return1m: -23,
                            return3m: -41,
                            return6m: -51,
                            return12m: -62.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CCX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Consumer Discretionary",
                            marketCap: 454.5634,
                            peRatio: 20.1,
                            eps: 0.089,
                            earningYield: 5,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 100,
                            issue: 231920086,
                            return1m: -26.4,
                            return3m: -46.4,
                            return6m: -67.1,
                            return12m: -65.6,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CHR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 15.15018,
                            peRatio: -21.3,
                            eps: -0.022,
                            earningYield: -4.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 32581035,
                            return1m: 25.7,
                            return3m: -38.8,
                            return6m: -35.9,
                            return12m: 116.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CPV.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Industrials",
                            marketCap: 46.58288,
                            peRatio: -5,
                            eps: -0.041,
                            earningYield: -20,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 211740344,
                            return1m: -28.1,
                            return3m: -42.3,
                            return6m: -14.6,
                            return12m: -51.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "CTM.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 397.2088,
                            peRatio: -18,
                            eps: -0.051,
                            earningYield: -5.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 427106273,
                            return1m: -20.9,
                            return3m: -32,
                            return6m: -19.6,
                            return12m: 25.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "DEV.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Energy",
                            marketCap: 70.69139,
                            peRatio: -6.8,
                            eps: -0.032,
                            earningYield: -14.8,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 314183969,
                            return1m: -42.1,
                            return3m: -51.1,
                            return6m: -54.2,
                            return12m: -36.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FEX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 153.6642,
                            peRatio: 2.1,
                            eps: 0.149,
                            earningYield: 48.1,
                            tradingBasis: "",
                            dividendRate: 0.052,
                            dividendYield: 17.5,
                            frankedPct: 100,
                            issue: 516213920,
                            return1m: -1.6,
                            return3m: 19.2,
                            return6m: 17,
                            return12m: 10.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FFX.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 236.2486,
                            peRatio: -4.3,
                            eps: -0.046,
                            earningYield: -23,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1181243221,
                            return1m: -56.2,
                            return3m: -52.3,
                            return6m: -41.8,
                            return12m: -6.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "FMG.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 55051.89,
                            peRatio: 4.7,
                            eps: 3.879,
                            earningYield: 21.1,
                            tradingBasis: "",
                            dividendRate: 2.97,
                            dividendYield: 16,
                            frankedPct: 100,
                            issue: 3078964918,
                            return1m: -6.2,
                            return3m: -5.4,
                            return6m: 0.1,
                            return12m: -7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "HTG.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 51.82554,
                            peRatio: -3.9,
                            eps: -0.02,
                            earningYield: -25.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 588926543,
                            return1m: -24.8,
                            return3m: -64.9,
                            return6m: -63.3,
                            return12m: -73.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "JRV.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 813.0668,
                            peRatio: -26.2,
                            eps: -0.02,
                            earningYield: -3.8,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1519750961,
                            return1m: -36.6,
                            return3m: -42,
                            return6m: -11.3,
                            return12m: -0.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "KP2.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 75.718262718,
                            peRatio: -25.9,
                            eps: -0.001,
                            earningYield: -3.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 230879152,
                            return1m: -4.3,
                            return3m: -24.1,
                            return6m: 46.7,
                            return12m: 46.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "LTR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 2378.564,
                            peRatio: 51.5,
                            eps: 0.022,
                            earningYield: 1.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2192225198,
                            return1m: -15.9,
                            return3m: -39.3,
                            return6m: -27.9,
                            return12m: 43.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "MLD.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 240.9061,
                            peRatio: 7.6,
                            eps: 0.09,
                            earningYield: 13.2,
                            tradingBasis: "",
                            dividendRate: 0.05,
                            dividendYield: 7.2,
                            frankedPct: 100,
                            issue: 341710846,
                            return1m: -11.1,
                            return3m: -20.9,
                            return6m: -15,
                            return12m: -6.1,
                        },
                    },
                    {
                        id: "ASX",
                        name: "MNB.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 69.64967,
                            peRatio: -15,
                            eps: -0.009,
                            earningYield: -6.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 525657897,
                            return1m: -22.1,
                            return3m: -26.4,
                            return6m: 20.5,
                            return12m: 117.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "MNS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 309.2753,
                            peRatio: -10.9,
                            eps: -0.027,
                            earningYield: -9.1,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 966485329,
                            return1m: -26.8,
                            return3m: -37.5,
                            return6m: -34.1,
                            return12m: 13.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "NEA.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 586.4076,
                            peRatio: -24.6,
                            eps: -0.043,
                            earningYield: -4.1,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 499070294,
                            return1m: -10.9,
                            return3m: -18.1,
                            return6m: -30.5,
                            return12m: -41.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "NMT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 531.9251,
                            peRatio: 27.7,
                            eps: 0.032,
                            earningYield: 3.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 7,
                            issue: 548376396,
                            return1m: -31.4,
                            return3m: -44.8,
                            return6m: -18.3,
                            return12m: 106.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "OPY.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Financials",
                            marketCap: 23.7419,
                            peRatio: -0.2,
                            eps: -0.642,
                            earningYield: -442.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 158279339,
                            return1m: -38.3,
                            return3m: -58,
                            return6m: -80.7,
                            return12m: -90.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "PLS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 6906.288,
                            peRatio: 81,
                            eps: 0.029,
                            earningYield: 1.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2976848384,
                            return1m: -19.9,
                            return3m: -27.2,
                            return6m: -21.3,
                            return12m: 61.8,
                        },
                    },
                    {
                        id: "ASX",
                        name: "RED.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 671.5628,
                            peRatio: -17.8,
                            eps: -0.015,
                            earningYield: -5.6,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 2356360652,
                            return1m: -29.9,
                            return3m: -28,
                            return6m: -3.6,
                            return12m: 45.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "RMS.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 828.3528,
                            peRatio: 6.3,
                            eps: 0.143,
                            earningYield: 15.8,
                            tradingBasis: "",
                            dividendRate: 0.025,
                            dividendYield: 2.6,
                            frankedPct: 100,
                            issue: 867385109,
                            return1m: -30.2,
                            return3m: -41.5,
                            return6m: -39.7,
                            return12m: -47,
                        },
                    },
                    {
                        id: "ASX",
                        name: "RVR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 82.95432,
                            peRatio: -3750,
                            eps: 0,
                            earningYield: 0,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 518464488,
                            return1m: -16.7,
                            return3m: -31.8,
                            return6m: -18.9,
                            return12m: -25,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SDF.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Financials",
                            marketCap: 4966.177,
                            peRatio: 25.9,
                            eps: 0.196,
                            earningYield: 3.9,
                            tradingBasis: "",
                            dividendRate: 0.122,
                            dividendYield: 2.4,
                            frankedPct: 100,
                            issue: 977593945,
                            return1m: -0.2,
                            return3m: 6.3,
                            return6m: -0.5,
                            return12m: 19,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SLC.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Communication Services",
                            marketCap: 357.716,
                            peRatio: -9,
                            eps: -0.079,
                            earningYield: -11.1,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: 100,
                            issue: 483413824,
                            return1m: -12.3,
                            return3m: -23.2,
                            return6m: -39.8,
                            return12m: -22.4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SLR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1250.398,
                            peRatio: 14.4,
                            eps: 0.086,
                            earningYield: 6.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 933132746,
                            return1m: -17.8,
                            return3m: -42.9,
                            return6m: -27.8,
                            return12m: -28.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SOR.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Financials",
                            marketCap: 97.7,
                            peRatio: null,
                            eps: null,
                            earningYield: null,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 390879891,
                            return1m: -17.6,
                            return3m: -26.3,
                            return6m: -47.2,
                            return12m: -36.4,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SRK.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 31.05,
                            peRatio: 24,
                            eps: 0.005,
                            earningYield: 4.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 270000000,
                            return1m: -31.4,
                            return3m: -11.1,
                            return6m: 20,
                            return12m: -48.9,
                        },
                    },
                    {
                        id: "ASX",
                        name: "SRN.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 30.56373,
                            peRatio: -8.2,
                            eps: -0.003,
                            earningYield: -12.2,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1455415895,
                            return1m: -43.6,
                            return3m: 69.2,
                            return6m: 120,
                            return12m: 46.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "TNT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 144.6911,
                            peRatio: -78.8,
                            eps: -0.001,
                            earningYield: -1.3,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1258183426,
                            return1m: -14.8,
                            return3m: -25.8,
                            return6m: -32.4,
                            return12m: -53.1,
                        },
                    },
                    {
                        id: "ASX",
                        name: "TRT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 16.01416,
                            peRatio: -2.1,
                            eps: -0.012,
                            earningYield: -48,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 571934283,
                            return1m: -37.5,
                            return3m: -52.8,
                            return6m: -66.7,
                            return12m: -60.3,
                        },
                    },
                    {
                        id: "ASX",
                        name: "WAF.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Materials",
                            marketCap: 1255.867,
                            peRatio: 5.9,
                            eps: 0.207,
                            earningYield: 17,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 1021029743,
                            return1m: -2.8,
                            return3m: -5.8,
                            return6m: -6.9,
                            return12m: 22.7,
                        },
                    },
                    {
                        id: "ASX",
                        name: "WBT.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Information Technology",
                            marketCap: 391.1299,
                            peRatio: -12.6,
                            eps: -0.181,
                            earningYield: -7.9,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 172303933,
                            return1m: -9.9,
                            return3m: -14.6,
                            return6m: -19.1,
                            return12m: 32.2,
                        },
                    },
                    {
                        id: "ASX",
                        name: "ZIP.au",
                        metaData: {
                            exchange: "ASX",
                            category: "Equity",
                            sector: "Financials",
                            marketCap: 354.2871,
                            peRatio: -0.6,
                            eps: -0.746,
                            earningYield: -167.7,
                            tradingBasis: "",
                            dividendRate: 0,
                            dividendYield: 0,
                            frankedPct: null,
                            issue: 687936061,
                            return1m: -48,
                            return3m: -69.7,
                            return6m: -89.9,
                            return12m: -94.2,
                        },
                    },
                ],
                userId: "628e48a0104d478f233ab612",
            },
        ]
    }
}