<template>
    <tailwind-modal :show-modal="filtermodalshow">
        <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4">ADD FILTER</div>

            <div class="">
                <div class="flex justify-center ">
                    <div class="grow basis-1/12 flex justify-center ">
                        <div class="mb-4 rounded">
                            <select ref="selectedRef" name="selectIndicator"  v-model="selectedIndicator"
                                    class="p-2 w-96 rounded-md bg-[#151515] border border-gray-700 text-gray-200 focus:outline-none focus:border-gray-500 leading-4 tracking-wide text-lg" :disabled="isEdit">
                                <option  value="" disabled >Select Indicator</option>
                                <option  v-for="(i, index) in indicatorsDropdown" :key="index" :value="index">
                                    {{ i.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="form-group  mb-2 flex justify-center" v-for="(p, index) in this.activeIndicatorParameters"
                     :key="index">
                    <div class="" id="input">
                        <input :type="p.type" v-model.number="dataObject[p.id]"
                               class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
                               :placeholder="p.placeholder" />
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">Okay</button>
                <!-- <button type="submit" @click="closeModal()"
                        class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm flex justify-center">
                   <p> Close </p> 
                </button> -->
                <button type="submit" @click="saveChanges()"
                        class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm" :disabled="!this.selectedIndicator">
                    Save Changes
                </button>
            </div>

        </div>

    </tailwind-modal>
</template>
<script>
    import TailwindModal from "@/components/popups/TailwindModal";
    export default {
        name:'AddFilter',
        components: {TailwindModal},
        data(){
            return {
                activeIndicatorParameters: [],
                selectedIndicator:'',
                selectedIndicatorName:'',
                dataObject:{}
            }
        },
        props:{
            filtermodalshow:{
                type:Boolean,
                default:false
            },
            isEdit:{
                type:Boolean,
                default:false
            },
            indicatorsDropdown:{
                type:Object
            },
            selectedData:{
                type:Object
            }
        },
        methods:{
            closeModal(){
                this.$emit('closeModal',this.isEdit)
            },
            saveChanges(){
                console.log('this.dataObject',this.dataObject)
                this.$emit('saveChanges',this.dataObject,{selectedIndicator:this.selectedIndicator,selectedIndicatorName:this.selectedIndicatorName},this.isEdit)
            },
            selectIndicator(value) {
                // console.log("........ owais1", value)
                if (this.indicatorsDropdown[value] === undefined)
                    return

                let newObj = {}
                let params = this.indicatorsDropdown[value].parameters
                for (const p of params) {
                    // console.log(p)
                    newObj[p.id] = p.default;
                }
                this.activeIndicatorParameters = this.indicatorsDropdown[value].parameters
                this.dataObject = newObj
                this.selectedIndicator = value                
                this.selectedIndicatorName = this.indicatorsDropdown[value].label
            
            },
        },
        updated(){
            console.log('updated',this.selectedData && this.isEdit)
            if(this.selectedData && this.isEdit){
                this.dataObject = this.selectedData.data
                this.selectedIndicator = this.selectedData.indicator
                // this.activeIndicatorParameters = this.indicatorsDropdown[this.selectedData.indicator]
                // this.selectedIndicatorName = $event.target.options[$event.target.options.selectedIndex].text
                
            }
        },
        watch:{
            selectedIndicator(n){

                this.selectIndicator(n)
            }
        }
    }
</script>
<style scoped>
    .ok-button {
        border: 0.6px solid #02bd2d;
        border-radius: 3px;
        color: #02bd2d;
        text-transform: uppercase;
    }
    .ok-button:hover {
        background: linear-gradient(
                270deg,
                rgba(2, 189, 45, 0.24) 0%,
                rgba(2, 189, 45, 0) 50%,
                rgba(2, 189, 45, 0.24) 100%
        );
    }
    .cancel-button {
        border: 0.6px solid #f8f9f9;
        border-radius: 3px;
        color: #f8f9f9;
        text-transform: uppercase;
        width: 20px !important;
    }
    .cancel-button:hover {
        background: linear-gradient(
                270deg,
                rgba(248, 249, 249, 0.21) 0%,
                rgba(248, 249, 249, 0) 52.08%,
                rgba(248, 249, 249, 0.21) 100%
        );
    }
</style>