
const SYMBOL = {
  "index": 3,
  "key": "symbol",
  "title": "Symbol",
  "definition": "",
  "type": "string",
  "head_style": "",
  "body_style": "",
  "width": "32px"
};

const VOLUME = {
  "index": 11,
  "key": "volume",
  "title": "Volume",
  "definition": "",
  "type": "string",
  "head_style": "",
  "body_style": "",
  "width": "32px"
};

const COMPANY_NAME = {
  "index": 4,
  "key": "company_name",
  "title": "Company Name",
  "definition": "",
  "type": "string",
  "head_style": "",
  "body_style": "",
  "width": "128px"
};
const CLOSE ={
  "index": 18,
  "key": "close",
  "title": "Close",
  "definition": "",
  "type": "number",
  "head_style": "",
  "body_style": "",
  "width": "32px"
};

const us_ColumnConfig = [
  {
    "index": 1,
    "key": "ticker",
    "title": "Ticker",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 2,
    "key": "date_time",
    "title": "Date/Time",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 3,
    "key": "symbol",
    "title": "Symbol",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 4,
    "key": "company_name",
    "title": "Company Name",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "128px"
  },
  {
    "index": 5,
    "key": "sector",
    "title": "Sector",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 6,
    "key": "industry",
    "title": "Industry",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 7,
    "key": "last_close",
    "title": "Last Close",
    "definition": "",
    "type": "decimal",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 12,
    "key": "close_capp",
    "title": "Close (CAPP)",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 8,
    "key": "mkt_cap_m",
    "title": "Mkt Cap ($M)",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  // {
  //   "index": 9,
  //   "key": "highest_index",
  //   "title": "Highest Index",
  //   "definition": "",
  //   "type": "",
  //   "head_style": "",
  //   "body_style": "",
  //   "width": "32px"
  // },
  {
    "index": 9,
    "key": "index",
    "title": "Index",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 10,
    "key": "exchange",
    "title": "Exchange",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  // {
  //   index: 70,
  //   key: "spx_per_change",
  //   title: "SPX % Change",
  //   definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
  //   type: "string",
  //   head_style: "",
  //   body_style: "",
  //   width: "32px"
  // },
  // {
  //   index: 71,
  //   key: "stock_per_change",
  //   title: "Stock % Change",
  //   definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
  //   type: "number",
  //   head_style: "",
  //   body_style: "",
  //   width: "32px"
  // },
  {
    "index": 11,
    "key": "volume",
    "title": "Volume",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  
  {
    "index": 13,
    "key": "peak_value",
    "title": "Peak Value",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 14,
    "key": "peak_date",
    "title": "Peak Date",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 15,
    "key": "highest_index",
    "title": "Highest Index",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 16,
    "key": "one_day_chg",
    "title": "1 Day Chg%",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 17,
    "key": "twenty_day_chg",
    "title": "20 Day Chg%",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 18,
    "key": "close",
    "title": "Close",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 19,
    "key": "twenty_day_change",
    "title": "20 Day % Change",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 25,
    "key": "twenty_day_change_candle",
    "title": "20 Day % Change",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 20,
    "key": "bearish_candle",
    "title": "Bearish Candle",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 21,
    "key": "bull_bear_candle",
    "title": "Bull/Bear Candle",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 22,
    "key": "close_ten_days_ago",
    "title": "Close 10 Days Ago",
    "definition": "",
    "type": "float",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 23,
    "key": "close_ten_days_change",
    "title": "10 Day Change %",
    "definition": "",
    "type": "float",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 24,
    "key": "breakout_rating",
    "title": "Breakout Rating",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
]

const us_capp = [
  SYMBOL,
  COMPANY_NAME,
  {
    "index": 12,
    "key": "close_capp",
    "title": "Close (CAPP)",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  VOLUME,
  {
    "index": 13,
    "key": "peak_value",
    "title": "Peak Value",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 14,
    "key": "peak_date",
    "title": "Peak Date",
    "definition": "",
    "type": "date",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 9,
    "key": "highest_index",
    "title": "Highest Index",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  }
] 

const us_candle = [
  SYMBOL,
  COMPANY_NAME,
  VOLUME,
  CLOSE,
  {
    "index": 19,
    "key": "twenty_day_change_candle",
    "title": "20 Day % Change",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 20,
    "key": "bullish_candle",
    "title": "Bullish Candle",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 20,
    "key": "bearish_candle",
    "title": "Bearish Candle",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 21,
    "key": "bull_bear_candle",
    "title": "Bull/Bear Candle",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 9,
    "key": "highest_index",
    "title": "Highest Index",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  }
]

const us_etf_breakout = [
  SYMBOL,
  COMPANY_NAME,
  CLOSE,
  VOLUME,
  {
    "index": 16,
    "key": "one_day_chg",
    "title": "1 Day Chg%",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 17,
    "key": "twenty_day_chg",
    "title": "20 Day Chg%",
    "definition": "",
    "type": "number",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  }
]

const us_breakout = [
  SYMBOL,
  COMPANY_NAME,
  
  {
    "index": 22,
    "key": "close_ten_days_ago",
    "title": "Close 10 Days Ago",
    "definition": "",
    "type": "float",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 7,
    "key": "last_close",
    "title": "Last Close",
    "definition": "",
    "type": "decimal",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  VOLUME,
  {
    "index": 23,
    "key": "close_ten_days_change",
    "title": "10 Day Change %",
    "definition": "",
    "type": "float",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 40,
    "key": "twenty_day_change",
    "title": "20 Day Change %",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  },
  {
    "index": 24,
    "key": "breakout_rating",
    "title": "Breakout Rating",
    "definition": "",
    "type": "string",
    "head_style": "",
    "body_style": "",
    "width": "32px"
  }
]

export default us_ColumnConfig;
export {us_capp, us_candle,us_etf_breakout, us_breakout};