<template>
  <div class="">
    <!-- tokenExpired -->
    <div v-if="tokenExpired">
      <div class="mainafter h-[100vh]">
        <div
          class="px-4 flex justify-center items-center h-full w-full relative"
        >
          <AlertLong alertType="Danger" closeAlert="false" title="Link Expired">
            <p class="text-white flex items-center justify-center h-full mt-2">
              Oops! it seems your link is not valid
            </p>
          </AlertLong>
        </div>
      </div>
    </div>

    <div v-else class="main min-h-screen w-full pt-2">
      <div
        class="w-full flex items-center mt-20 md:justify-start sm:justify-center -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600"
      >
        <div
          class="xl:w-[35%] lg:w-[45%] md:w-[52%] sm:w-[60%] w-full overflow-y-auto footer h-[90%] z-50 flex items-center"
          :class="loaderState ? 'pointer-events-none' : ''"
        >
          <div
            class="h-fit w-[100%] z-50"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: end;
              gap: 2vh;
            "
          >
            <div
              class="h-fit lg:w-[80%] lg:px-6 px-6 md:w-[90%] w-full -bg-green-300 flex justify-center"
              style="display: flex; flex-direction: column; gap: 4vh"
            >
              <div class="sm:w-9/12 w-full">
                <router-link to="/">
                  <img src="../../assets/mapWhite.svg" class="w-full" alt="" />
                </router-link>
              </div>
              <div
                class="w-9/12 -mt-2"
                v-if="responseMessage"
                data-temp-component="true"
              >
                <AlertShort class="w-full" :alertType="messageType">
                  <p class="text-sm text-gray-300">
                    {{ responseMessage }}
                  </p>
                </AlertShort>
              </div>

              <div
                style="display: flex; flex-direction: column; gap: 2vh"
                class="w-full -mt-4"
              >
                <div>
                  <p class="text-[#E5E9EC] p-0 m-0">You are almost done!</p>
                  <h1 class="text-[#02BD2D] font-bold p-0 m-0 Inter-Bold">
                    Verify your identity
                  </h1>
                </div>

                <div
                  class="w-full"
                  style="display: flex; flex-direction: column"
                >
                  <div class="mt-2">
                    <VuePhoneNumberInput
                      @update="(item) => handleInput(item)"
                      :only-countries="countries"
                      :no-example="true"
                      class="mb-[8px]"
                      :class="
                        (verify || disableElement) && 'pointer-events-none'
                      "
                      :default-country-code="countryCode"
                      v-model="phone"
                      :dark="true"
                      maxlength="10"
                    />
                  </div>
                  <span
                    class="text-[12px] text-red-600 font-bold"
                    v-if="showPhoneError"
                    
                    >Phone number is not valid</span
                  >
                  <span
                    class="text-[12px] text-[#02BD2D] font-bold"
                    v-if="countryCode==='AU'"
                    
                    >No need to add 0 in start eg: 4xx xxx xxx</span
                  >

                  <input
                    :disabled="!verify"
                    @keyup.enter="verifyOtp"
                    placeholder="6 digit OTP code here"
                    type="text"
                    v-model="otpNumber"
                    maxlength="6"
                    @input="onInput"
                    :class="[
                      !verify && 'cursor-not-allowed bg-gray-800',
                      disableElement && 'pointer-events-none',
                    ]"
                    class="h-[5vh] bg-transparent p-4 box-border text-[#FFFFFF] rounded-md mt-4 focus:outline-none"
                    id="otp"
                    ref="otp"
                    style="border: 0.6px solid #7582787d"
                  />

                  <div
                    class="flex items-center pt-2"
                    :class="!showOtpError ? 'justify-end' : 'justify-between'"
                  >
                    <div
                      class="text-[12px] text-red-600 w-8/12 font-bold"
                      v-if="showOtpError"
                    >
                      Otp must be 6 digits
                    </div>
                    <div
                      class="text-[10px] text-[#02BD2D] w-4/12 font-bold rounded-sm text-center bg-black"
                      v-if="resendCode"
                    >
                      Resend code in {{ timer }} sec
                    </div>
                  </div>
                  <!-- resendCode -->
                </div>
                <div class="w-full flex justify-center">
                  <div
                    class="w-full flex items-center justify-center"
                    :class="loaderState ? 'btnDefault' : ''"
                  >
                    <VueLoadingButton
                      v-if="verify"
                      aria-label="Post message"
                      :class="[
                        loaderState && 'cursor-not-allowed',
                        disableElement && 'pointer-events-none',
                      ]"
                      class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                      @click.native="verifyOtp"
                      :loading="loaderState"
                      ><span class="text-[15px] font-bold">{{
                        loaderState ? "Verifying" : "VERIFY AND CONTINUE"
                      }}</span></VueLoadingButton
                    >
                    <VueLoadingButton
                      v-else
                      aria-label="Post message"
                      :class="!phoneTermsAndCondition ? (loaderState ? '' : 'bg-gray-400 cursor-not-allowed pointer-events-none') : ''"
                      class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                      @click.native="sendOtp"
                      :loading="loaderState"
                      ><span class="text-[15px] font-bold">{{
                        loaderState ? "Sending" : "SEND OTP CODE"
                      }}</span></VueLoadingButton
                    >
                  </div>
                </div>

                
                <!-- <div class="flex  w-full  space-x-3">
                  <div class="h-full flex pt-0.5 ">
                            <input type="checkbox" id="Terms_Con_Checkbox" @click="checkedPhoneTermsAndCondition()" :checked="termsAndCondiionSectorSuffer" :disabled="disabled" class="checkbox-round bg-[white]" />
                        </div>
                  <div class=" text-[12px] text-[white]">
                    <span>Please read our </span>

                    <a
                      href="https://docs.marketalertpro.com/legal/terms-and-conditions"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Terms and Conditions
                      </span> </a
                    >,

                    <a
                      href="https://docs.marketalertpro.com/legal/privacy-policy"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Privacy Policy
                      </span>
                    </a>

                    and

                    <a
                      href="https://docs.marketalertpro.com/legal/financial-services-guide-fsg"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Financial Services Guide (FSG)
                      </span>
                    </a>
                  </div>
                </div> -->

                <div class="flex justify-center w-full">
                  <div
                    class="w-10/12 bg-transparent flex items-center justify-center"
                    style="gap: 0.3vw"
                  >
                    <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                    <p class="text-[#E5E9EC]">or</p>
                    <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                  </div>
                </div>
                <div class="flex justify-center w-full">
                  <div class="w-9/12 bg-transparent">
                    <div class="h-[33%] flex items-center justify-center">
                      <div class="flex items-center">
                        <p class="text-white">Already have an account?</p>
                      </div>
                      <div class="flex items-center">
                        <button class="text-[#02BD2D] font-bold ml-2">
                          <router-link to="/"><span>Login</span> </router-link>
                        </button>
                      </div>
                    </div>
                    <div class="h-[66%] w-full flex items-end justify-center">
                      <p class="text-[white]">
                        Have an issue?
                        <router-link to="/contactus">
                          <span class="text-sm cursor-pointer"
                            ><a
                              href="/contactus"
                              target="_blank"
                              style="color: #9e9898"
                              >Contact Support</a
                            >
                          </span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full sm:mt-20 mt-32 pb-6 flex justify-center">
       <PageFooter />
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import userApi from "../../api/userApi";
import { verify_token } from "@/api/apiRoutes";
import VueLoadingButton from "vue-loading-button";
import VuePhoneNumberInput from "vue-phone-number-input";

import "../customcss/index.css";

// import { FormValidationException } from "../../helper/exceptions";

import AlertShort from "../utilities/AlertShort.vue";

import TermsOfUse from "../popups/TermsOfUse.vue";
import ImportantNotice from "../popups/ImportantNotice.vue";
import { pleaseEnterMessage } from "../../helper/globalUtils";
import AlertLong from "../utilities/AlertLong.vue";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"

export default {
  data() {
    return {
      resendCode: false,
      timer: 60,
      intervalId: null,
      disableElement: false,
      tokenExpired: false,
      verify: false,
      showPhoneError: false,
      showOtpError: false,
      loaderState: false,
      userVerificationToken: null,
      phoneTermsAndCondition:true,
      showMessage:false,
      messageType: "",
      otpNumber: "",
      countryCode: "AU",
      
      countries: ["US", "CA", "AU", "ZA", "FR", "GB", "NZ", "SG"],
      phone: "",
      regex: /^\d{9}$/,
      phoneCode: {
        US: "+1",
        AU: "+61",
        CA: "+1",
        GB: "+44",
        NZ: "+64",
        FR: "+33",
        SG: "+65",
        ZA: "+27",
      },
      responseMessage: "",
    };
  },
  computed: {},
  components: {
    FooterCompanyLinks,
    ImportantNotice,
    AlertShort,
    TermsOfUse,
    AlertLong,
    VueLoadingButton,
    VuePhoneNumberInput,
    PageFooter
  },
  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
//     checkedPhoneTermsAndCondition(){
//       this.phoneTermsAndCondition = !this.phoneTermsAndCondition;

// console.log("this.phoneTermsAndCondition",this.phoneTermsAndCondition);
//     },

   

    handleInput(value) {
      this.phone = value?.formattedNumber;
      this.countryCode = value?.countryCode;
    },
    async sendOtp() {
      console.log("phone", this.phone);
      let validNumber = this.validatePhone(this.phone);
      let phoneCode = this.phoneCode[this.countryCode];
      if (validNumber) {
        if (this.phone && phoneCode) {
          this.loaderState = true;
          // let concatNumber = phoneCode+this.phone
          // let formattedNumber = this.removePhoneNumberFormatting(concatNumber)
          let payload = {
            countryCode: phoneCode,
            phoneNumber: this.phone,
            phoneCountry: this.countryCode,
          };
          try {
            let res = await userApi.sendOtp(payload);
            console.log("res", res);
            if (res?.data?.success) {
              this.messageType = "Success";
              this.responseMessage = res?.data?.message;
              this.resendCode = true;
              this.startTimer();
              this.verify = true;
              this.loaderState = false;
            } else {
              this.messageType = "Danger";
              this.responseMessage = res?.data?.message;
              this.loaderState = false;
            }
          } catch (err) {
            this.messageType = "Danger";
            this.responseMessage = "An unknown error occur";
            this.loaderState = false;
          }
        }
       
      } else {
        this.showPhoneError = true;
      }
    },

   
    async verifyOtp() {
      let phoneCode = this.phoneCode[this.countryCode];
      if (this.otpNumber.length == 6) {
        this.loaderState = true;
        try {
          let payload = {
            countryCode: phoneCode,
            phoneNumber: this.phone,
            otp: this.otpNumber,
            phoneCountry: this.countryCode,
            userToken: this.userVerificationToken,
          };
          let res = await userApi.verifyOtp(payload);
          console.log("response", res);
          if (res?.data?.success) {
            this.messageType = "Success";
            this.responseMessage = res?.data?.message;
            this.resendCode = false;
            this.loaderState = false;
            this.disableElement = true;
            setTimeout(() => {
              router.push(
                `/userdetails?Token=${this.userVerificationToken?.Token}`
              );
            }, 2000);
          } else {
            this.messageType = "Danger";
            this.responseMessage = res?.data?.message;
            this.loaderState = false;
          }
        } catch (err) {
          this.messageType = "Danger";
          this.responseMessage = "An unknown error occur";
          this.loaderState = false;
        }
      } else {
        this.showOtpError = true;
      }
    },
    onInput(event) {
      const input = event.target;
      input.value = input.value.replace(/\D/g, "");
      this.otpNumber = input.value;
    },
    validatePhone(phone) {
      if (this.regex.test(phone)) {
        return true;
      } else {
        return false;
      }
    },

    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.timer = 60;
          this.resendCode = false;
          (this.verify = false), clearInterval(this.intervalId);
        }
      }, 1000);
    },

    async verifyToken() {
      let result = await axios.post(verify_token, {
        token: this.userVerificationToken,
      });
      if (!result.data.success) {
        console.log("expired");
        this.tokenExpired = true;
      }
    },

    removePhoneNumberFormatting(phoneNumber) {
      return phoneNumber.replace(/[()\s,-]/g, "");
    },
  },
  watch: {
    countryCode(val) {
      if (val == "US") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "CA") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "GB") {
        this.regex = /^\d{10}$/;
      } else if (val == "AU") {
        this.regex = /^\d{9}$/;
      } else if (val == "NZ") {
        this.regex = /^\d{10}$/;
      } else if (val == "FR") {
        this.regex = /^\d{9}$/;
      } else if (val == "SG") {
        this.regex = /^\d{8}$/;
      } else if (val == "ZA") {
        this.regex = /^\d{9}$/;
      }
    },
    phone() {
      this.showPhoneError = false;
    },
    otpNumber() {
      this.showOtpError = false;
    },
    responseMessage() {
      console.log("message");
      setTimeout(() => {
        this.responseMessage = "";
      }, 6000);
    },
  },
  async mounted() {
    let route = this.$route.query;
    console.log("route", route);
    let len = Object.keys(route).length;
    if (route?.Token) {
      this.userVerificationToken = route;
      await this.verifyToken();
    } else {
      this.tokenExpired = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
a {
  color: #02bd2d;
}
a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}
input:focus {
  border: 1px solid #02bd27 !important;
}
.main {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-image: url("../../assets/map-main-background.jpg");

  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  background-attachment: fixed;

  height: auto;
}

.mainafter {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainafter::before {
  content: "";
  background-image: url("../../assets/map-main-background.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}

.checkbox-round {
  width: 0.8em;
  height: 0.8em;
  /* background-color: black; */
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  outline: none;
}
/* .mainafter {
    position: relative;
    height: 100vh;
    width: 100%;
    
   
  }
  
  .mainafter::before {
    content: "";
    
    background-image: url("../../assets/map-main-background.jpg");
    background-size: cover;
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  } */
.footer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
/* .checkbox-round {
  width: 0.9em;
  height: 0.9em;
  background-color: black;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
} */

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.checkbox-round:checked {
  background-color: #02bd2d;
}

.btnDefault {
  pointer-events: none;
}
.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}
</style>
