<template>

    <div class="
    border-[0.3px] border-[#7582785c]
    rounded
    w-[55%]
    pt-[2vh]
    pb-[1vh]
    px-[.8vw]
    h-full
    12qqqqqqqqqqqqq2
    box-border
    " style="display:flex; flex-direction:column; gap:0.9vh;">

        <AddFileModal :filesaveModal="filesaveModal" @FileCloseModal="FileCloseModal"
            @SaveFileDataModal="SaveFileDataModal" />

        <NewAddFilter :newFiltermodalshow="newFiltermodalshow" @newCloseModal="newCloseModal"
            @newSaveModal="newSaveModal" :newParameterLength="newParameterLength" />


        <AddFilter :filtermodalshow="filtermodalshow" @closeModal="closeModal" @saveChanges="saveChanges"
            :indicatorsDropdown="indicatorsDropdown" :isEdit="isEdit" :selectedData="selectedData" />

        <div class="text-white text-[1vw] font-semibold">Build Stock Search</div>

        <div class=" h-[93.9%]" style="display: flex; flex-direction: column; justify-content: space-between;">
            <div class=" h-[86%]" style=" display: flex; flex-direction: column; gap: 0.7vh;">






                <div style="display: flex;flex-direction: column; justify-content: space-between; height:100%;">

                    <div class="indicators-div max-h-[30vh] overflow-y-scroll"
                        style="display: flex; flex-direction:column; gap:1vh;">

                        <!-- Added Rows -->

                        <div class="gap-[.2vw] tr" ref="mainTrDiv"
                            v-for="(mainArrayData, rowIndex_i) of this.NewSearch_JSONData_Array" :key="rowIndex_i">

                            <div class="th">
                                <select @change="TypeSelected" v-model="mainArrayData.key">
                                    <option value="">--Select Type--</option>
                                    <option v-for="(Types, i) of mainArrayData.selectedDataKeys" :key="i">
                                        {{Types.label}}
                                    </option>
                                </select>
                            </div>

                            <div class="th">

                                <button class="bg-[black] text-white  rounded-[4px] w-full font-extralight border-none"
                                    v-for="(Date, i) in Object.keys( NewSearchData[mainArrayData.key].Date)" :key="i"
                                    :title="showRowCalendar && selctedRowIndex == rowIndex_i? 'Hide Calendar':'Show Calendar'">
                                    <p @click="ShowCalendar(rowIndex_i)"
                                        class="flex justify-center items-center h-full w-full">Date</p>

                                    <div class="absolute mt-[1.5vh] z-[105]">

                                        <DatePicker :value="mainArrayData.selectedDataKeys.Fundamentals.Date.date" v-if=" showRowCalendar && selctedRowIndex == rowIndex_i && mainArrayData.key == 'Fundamentals'
                                        // mainArrayData.selectedDataKeys.Fundamentals.Date.showCalendar
                                        " color="green" is-dark />

                                        <DatePicker
                                            v-if="showRowCalendar && selctedRowIndex == rowIndex_i && mainArrayData.key == 'Technicals'"
                                            is-range :value="mainArrayData.selectedDataKeys.Technicals.Date.date"
                                            color="green" is-dark />

                                        <DatePicker
                                            v-if="showRowCalendar && selctedRowIndex == rowIndex_i && mainArrayData.key == 'Market'"
                                            :value="mainArrayData.selectedDataKeys.Market.Date.date"
                                            :max-date='NewSearchData.Market.Date.date'
                                            :min-date='NewSearchData.Market.Date.date' color="green" is-dark />
                                    </div>
                                </button>

                            </div>
                            <div class="th">
                                <select v-if="mainArrayData.key == 'Technicals'"
                                    v-model="mainArrayData.selectedDataKeys.Technicals.Criteria1.key">
                                    <option>--Select Criteria 1--</option>
                                    <option
                                        v-for="(Criteria1, i) in Object.keys( mainArrayData.selectedDataKeys.Technicals.Criteria1.criteria)"
                                        :key="i">{{ Criteria1 }}
                                        ({{mainArrayData.selectedDataKeys.Technicals.Criteria1.criteria[Criteria1].join(",")}})
                                    </option>
                                </select>
                                <select v-else-if="mainArrayData.key == 'Fundamentals'" @change="FundamentalsCriteria"
                                    v-model="mainArrayData.selectedDataKeys.Fundamentals.Criteria.key">
                                    <option>--Select Criteria--</option>
                                    <option
                                        v-for="(Criteria, i) in  mainArrayData.selectedDataKeys.Fundamentals.Criteria.criteria"
                                        :key="i">{{
                                        Criteria }}
                                    </option>
                                </select>
                                <!-- @change="CountrySelect" -->
                                <select v-else-if="mainArrayData.key == 'Market'"
                                    v-model="mainArrayData.selectedDataKeys.Market.Country.key">
                                    <option>--Select Country--</option>
                                    <option
                                        v-for="(Country, i) in Object.keys( mainArrayData.selectedDataKeys.Market.Country.MarketCountry )"
                                        :key="i">
                                        {{ Country }}
                                    </option>
                                </select>
                            </div>

                            <div class="th">
                                <select v-if="mainArrayData.key"
                                    v-model="mainArrayData.selectedDataKeys[mainArrayData.key].Operator.key">
                                    <option>--Select Operator--</option>
                                    <option
                                        v-for="(Operator, i) of mainArrayData.selectedDataKeys[mainArrayData.key].Operator.operator"
                                        :key="i">
                                        {{Operator}}
                                    </option>
                                </select>
                            </div>

                            <div class="th">
                                <select v-if="mainArrayData.key == 'Technicals'"
                                    v-model="mainArrayData.selectedDataKeys.Technicals.Criteria2.key">
                                    <option>--Select Criteria 2--</option>
                                    <option
                                        v-for="(Criteria2, i) of Object.keys( mainArrayData.selectedDataKeys.Technicals.Criteria2.criteria )"
                                        :key="i">
                                        {{Criteria2}}
                                        ({{mainArrayData.selectedDataKeys.Technicals.Criteria2.criteria[Criteria2].join(",")}})
                                    </option>
                                </select>
                                <div v-else-if="mainArrayData.key == 'Fundamentals'">
                                    <label class=""
                                        v-for="(Value, i) of  mainArrayData.selectedDataKeys.Fundamentals.Value"
                                        :key="i">
                                        <input type="number" class="w-full h-full"
                                            :value="mainArrayData.selectedDataKeys.Fundamentals.Value.value">
                                    </label>
                                </div>
                                <!-- MArkt type m header row k variables update horhy hn -->
                                <label class="flex gap-[.2vw] w-full"
                                    v-else-if="mainArrayData.key == 'Market' && mainArrayData.selectedDataKeys.Market.Country.key">

                                    <select v-if="mainArrayData.selectedDataKeys.Market.Country.key"
                                        @change="CountryTypeSelect"
                                        v-model="mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key].key">
                                        <option>--Select Country Type--</option>
                                        <option
                                            v-for="(ContryType, i) in Object.keys( mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key] )"
                                            :key="i">
                                            {{ContryType}}
                                        </option>
                                    </select>
                                    <select
                                        v-if=" mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key].key"
                                        v-model="mainArrayData.selectedDataKeys[mainArrayData.key].Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key][mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key].key].key"
                                        @change="HeaderCountryTypeSelectedData">
                                        <option value="">--Select Data Type--</option>
                                        <option
                                            v-for="(ContryTypeKeys, i) in  mainArrayData.selectedDataKeys[mainArrayData.key].Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key][mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key].key][mainArrayData.selectedDataKeys.Market.Country.MarketCountry[mainArrayData.selectedDataKeys.Market.Country.key].key]"
                                            :key="i">{{ContryTypeKeys}}</option>
                                    </select>
                                </label>
                            </div>


                            <div class="th">
                                <select v-if="mainArrayData.key == 'Technicals'"
                                    v-model=" mainArrayData.selectedDataKeys.Technicals.Qualifier.key">
                                    <option value="">--Select Qualifier--</option>
                                    <option
                                        v-for="(Qualifier, i) of mainArrayData.selectedDataKeys.Technicals.Qualifier.qualifier"
                                        :key="i">
                                        {{Qualifier}}</option>
                                </select>
                                <div class=" flex justify-evenly gap-[.3vw]"
                                    v-else-if="mainArrayData.key == 'Fundamentals'">
                                    <label class="text-white flex gap-[.2vw] items-center"
                                        v-for="(Checkbox, i) of Object.keys( mainArrayData.selectedDataKeys.Fundamentals.Checkbox)"
                                        :key="i">
                                        <input type="radio"
                                            :checked="Object.values( mainArrayData.selectedDataKeys.Fundamentals.Checkbox)[i]"
                                            class=" appearance-none rounded-sm h-[1vh] w-[.5vw] border border-[#00C92E] bg-black checked:bg-[#00C92E] checked:border-black-500 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                                            :name="'RadioBtn'+`${rowIndex_i}`">

                                        <span class="cursor-pointer">{{ Checkbox }}</span>
                                    </label>
                                </div>
                                <select v-else-if="mainArrayData.key == 'Market'"
                                    v-model="mainArrayData.selectedDataKeys.Market.Criteria2.key"
                                    @change="MarketCriteria2">
                                    <option>--Select Criteria 2--</option>
                                    <option
                                        v-for="(Criteria2, i) of Object.keys(mainArrayData.selectedDataKeys.Market.Criteria2.criteria)"
                                        :key="i">
                                        {{Criteria2}}
                                    </option>
                                </select>
                                <select
                                    v-if="mainArrayData.selectedDataKeys.Market.Criteria2.key && mainArrayData.key == 'Market' && rowIndex_i == rowIndex_i"
                                    v-model="mainArrayData.selectedDataKeys.Market.Criteria2.key2">
                                    <option>--Select Criteria 2 Type--</option>
                                    <option
                                        v-for="(Criteria2, i) of mainArrayData.selectedDataKeys.Market.Criteria2.criteria[mainArrayData.selectedDataKeys.Market.Criteria2.key]"
                                        :key="i">
                                        {{Criteria2}}
                                    </option>
                                </select>
                            </div>

                            <div v-if="mainArrayData.key" @click="MsgBox()" class="th" style="flex-grow:inherit;"
                                title="Saved comments">

                                <svg v-for="(myData, i) of NewSearchData[mainArrayData.key].Comment" :key="i"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="white" class="w-[.9vw] cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                </svg>

                                <!-- <input type="text" class="w-full"> -->
                            </div>
                            <div v-if="mainArrayData.key" class="th" style="flex-grow:inherit;" title="Delete Row"
                                @click="DeleteRow(rowIndex_i)">

                                <svg v-for="(myData, i) of NewSearchData[mainArrayData.key].Comment" :key="i"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="red" class="w-[1.1vw] cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <!-- <input type="text" class="w-full"> -->
                            </div>
                        </div>

                        <!-- header Row  -->

                        <div v-if="showSearch_Add_Btn_BuildSearch" class="gap-[.2vw] tr" ref="mainTrDiv">

                            <div class="th">
                                <select @change="HeaderTypeSelected">
                                    <option>--Select Type--</option>
                                    <option v-for="(myData, i) in NewSearchData" :value="myData.label" :key="i">
                                        {{myData.label}}
                                    </option>

                                </select>
                            </div>

                            <div class="th" v-if="typeSelected">

                                <!-- :class="showCalendar ? 'border-[white]':'border-[#00C92E]'" -->
                                <button class="bg-[black] text-white  rounded-[4px] w-full font-extralight border-none"
                                    v-for="(Date, i) in Object.keys( NewSearchData[typeSelected].Date)" :key="i"
                                    :title="showCalendar ? 'Hide Calendar':'Show Calendar'">
                                    <p @click="HeaderShowCalendar"
                                        class="flex justify-center items-center h-full w-full">
                                        Date
                                    </p>
                                    <div class="absolute mt-[2vh] z-[105]">

                                        <DatePicker v-if="showCalendar && typeSelected == 'Fundamentals'"
                                            v-model="NewSearchData_Selected.selectedDataKeys.Fundamentals.Date.date"
                                            color="green" is-dark />

                                        <DatePicker v-if="showCalendar && typeSelected == 'Technicals'" is-range
                                            v-model="NewSearchData_Selected.selectedDataKeys.Technicals.Date.date"
                                            color="green" is-dark />

                                        <DatePicker v-if="showCalendar && typeSelected == 'Market'"
                                            v-model="NewSearchData_Selected.selectedDataKeys.Market.Date.date"
                                            :max-date='NewSearchData.Market.Date.date'
                                            :min-date='NewSearchData.Market.Date.date' color="green" is-dark />


                                    </div>
                                </button>

                            </div>
                            <!-- owais -->
                            <div class="th">
                                <select v-if="typeSelected == 'Technicals'" @change="TechnicalCriteria1">
                                    <option>--Select Criteria 1--</option>
                                    <option
                                        v-for="(Criteria1, i) in Object.keys( NewSearchData[typeSelected].Criteria1.criteria)"
                                        :key="i">{{ Criteria1 }}
                                        ({{NewSearchData[typeSelected].Criteria1.criteria[Criteria1].join(",")}})
                                    </option>
                                </select>
                                <select v-else-if="typeSelected == 'Fundamentals'" @change="FundamentalsCriteria">
                                    <option>--Select Criteria--</option>
                                    <option v-for="(Criteria, i) in  NewSearchData[typeSelected].Criteria.criteria"
                                        :key="i">{{
                                        Criteria }}
                                    </option>
                                </select>
                                <select v-else-if="typeSelected == 'Market'" @change="MarketCountrySelect">
                                    <option>--Select Country--</option>
                                    <option v-for="(Country, i) in Object.keys( NewSearchData[typeSelected].Country )"
                                        :key="i">
                                        {{ Country }}
                                    </option>
                                </select>
                            </div>

                            <div class="th">
                                <select v-if="typeSelected" @change="OperatorChange"
                                    v-model="NewSearchData_Selected.selectedDataKeys[NewSearchData_Selected.key].Operator.key">
                                    <option>--Select Operator--</option>
                                    <option v-for="(Operator, i) of NewSearchData[typeSelected].Operator.operator"
                                        :key="i">
                                        {{Operator}}
                                    </option>
                                </select>
                            </div>

                            <div class="th">
                                <select v-if="typeSelected == 'Technicals'" @change="TechnicalCriteria2">
                                    <option>--Select Criteria 2--</option>
                                    <option
                                        v-for="(Criteria2, i) of Object.keys( NewSearchData[typeSelected].Criteria2.criteria )"
                                        :key="i">
                                        {{Criteria2}}
                                        ({{NewSearchData[typeSelected].Criteria2.criteria[Criteria2].join(",")}})
                                    </option>
                                </select>
                                <div v-else-if="typeSelected == 'Fundamentals'">
                                    <label class="" v-for="(Value, i) of  NewSearchData[typeSelected].Value" :key="i">
                                        <input type="number" class="w-full h-full" v-model="fundamentalUserValue">
                                    </label>
                                </div>
                                <label class="flex gap-[.2vw] w-full"
                                    v-else-if="typeSelected == 'Market' && countrySelected">

                                    <select v-if="countrySelected" @change="HeaderCountryTypeSeled">
                                        <option>--Select Market Type--</option>
                                        <option
                                            v-for="(ContryType, i) in Object.keys( NewSearchData[typeSelected].Country[countrySelected] )"
                                            :key="i">
                                            {{ContryType}}
                                        </option>
                                    </select>
                                    <select v-if="countryTypeSelected" @change="HeaderCountryTypeSelectedData">
                                        <option>--Select Data Type--</option>
                                        <option
                                            v-for="(ContryTypeKeys, i) in  NewSearchData[typeSelected].Country[countrySelected][countryTypeSelected][countryTypeSelected]"
                                            :key="i">{{ContryTypeKeys}}</option>
                                    </select>
                                </label>


                            </div>


                            <div class="th">
                                <select v-if="typeSelected == 'Technicals'" @change="TechnicalQualifier">
                                    <option>--Select Qualifier--</option>
                                    <option v-for="(Qualifier, i) of NewSearchData[typeSelected].Qualifier.qualifier"
                                        :key="i">
                                        {{Qualifier}}</option>
                                </select>
                                <div class=" flex justify-evenly gap-[.3vw]" v-else-if="typeSelected == 'Fundamentals'">
                                    <label class="text-white flex gap-[.2vw] items-center"
                                        v-for="(Checkbox, i) of Object.keys( NewSearchData[typeSelected].Checkbox)"
                                        :key="i">
                                        <input type="radio"
                                            class=" appearance-none rounded-sm h-[1vh] w-[.5vw] border border-[#00C92E] bg-black checked:bg-[#00C92E] checked:border-black-500 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                                            name="Value" :checked="i==headerCheckedIndex"
                                            @click="FundamentalValueTypeChecked(i)">
                                        <span class="cursor-pointer">{{ Checkbox }}</span>
                                    </label>
                                </div>
                                <select v-else-if="typeSelected == 'Market'" @change="HeaderMarketCriteria2">
                                    <option>--Select Criteria 2--</option>
                                    <option
                                        v-for="(Criteria2, i) of Object.keys(NewSearchData[typeSelected].Criteria2.criteria)"
                                        :key="i">
                                        {{Criteria2}}
                                    </option>
                                </select>
                                <select v-if="marketCriteria2 && typeSelected == 'Market'"
                                    @change="MarketCriteria2Type">
                                    <option>--Select Criteria 2 Type--</option>
                                    <option
                                        v-for="(Criteria2Type, i) of NewSearchData[typeSelected].Criteria2.criteria[marketCriteria2]"
                                        :key="i">
                                        {{Criteria2Type}}
                                    </option>
                                </select>
                            </div>
                            <div v-if="typeSelected" @click="HeaderMsgBox" class="th" style="flex-grow:inherit;"
                                title="Comments">

                                <svg v-for="(myData, i) of NewSearchData[typeSelected].Comment" :key="i"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="white" class="w-[.9vw] cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                </svg>

                                <!-- <input type="text" class="w-full"> -->
                            </div>

                        </div>
                    </div>



                    <div>
                        
                        <div v-if="typeSelected" class="th flex justify-end" style="flex-grow:inherit; ">
                            <button class="ok-button 
                  border
                  rounded
                  px-[.8vw]
                  box-border
                  text-[.7vw]
                  font-normal
                  cursor-pointer
                  flex
                  justify-center
                  py-[.4vh]
                  items-center
                  gap-[.2vw]">

                                <svg @click="AddRow()" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                    class="w-[.7vw] cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <label @click="AddRow()" class="">Add</label>
                            </button>
                        </div>


                    </div>
                </div>





                <!-- added indicator -->



            </div>

            <div class="flex justify-between h-[10%] ">
                <div class="flex">
                    <div class="flex gap-[1vw] f-full">
                        
                        <button v-if="!showSearch_Add_Btn_BuildSearch" @click="ShowHeaderRow()" class="ok-button 
                        border
                    rounded
                    border-[#00C92E]
                    text-[#00C92E]
                    px-[.8vw]
                    box-border
                    text-[.7vw]
                    h-full
                    font-normal
                    cursor-pointer
                                ">
                            <p> Add Row </p>
                        </button>
                        
                        <button  v-if="!showSearch_Add_Btn_BuildSearch" @click="ClearScreenData()" class="cancel-button 
                        border
                        rounded
                        border-[#00C92E]
                        text-[#00C92E]
                        px-[.8vw]
                        box-border
                        text-[.7vw]
                        h-full
                        font-normal
                        cursor-pointer"
                                >
                            <p> CLear Data</p>
                        </button>
                    </div>


                    
                    <button v-if="showSearch_Add_Btn_BuildSearch" type="button" class="
                    cancel-button
                    border
                    rounded
                    border-[#00C92E]
                    text-[#00C92E]
                    px-[.8vw]
                    box-border
                    text-[.7vw]
                    h-full
                    font-normal
                    cursor-pointer

                    " @click="saveSearch()">
                        <p>SAVE SEARCH</p>
                    </button>

                </div>
                <button type="button" class="               
               ok-button
                  border
                  rounded
                  px-[.8vw]
                  box-border
                  text-[.7vw]
                  font-normal
                  cursor-pointer
                ">
                    RUN SEARCH
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import AddFilter from "@/components/pages/AddFilter";
import NewAddFilter from "@/components/pages/NewAddFilter";
import AddFileModal from "@/components/pages/AddFileModal.vue"


// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';


// import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    name: 'BuildSearch',
    components: {
        AddFilter,
        NewAddFilter,
        AddFileModal,
        DatePicker
        // DatePicker, 
        // Calendar 
    },
    props: ['selectedFileIndex', 'obj', 'acc_Folder_Name', 'myArray', 'showSearch_Add_Btn_BuildSearch', 'selectedFileData'],
    data() {
        return {
            filesaveModal: false,
            filesDataArray: [],
            headerCheckedIndex: null,
            showHeaderRow: true,
            criteria1_Or_criteria2: '',
            criteria1Key: '',
            newParameterLength: [],
            newFiltermodalshow: false,
            showRowCalendar: false,
            showCalendar: false,
            fundamentalUserValue: '',
            selctedRowIndex: -1,
            marketCriteria2: '',
            headerCountryTypeSelected: '',
            countryTypeSelected: '',
            countrySelected: '',
            typeSelected: '',
            filtermodalshow: false,
            slectedIndicatorIndex: null,
            NewSearch_JSONData_Array: [],
            // owais
            NewSearchData_Selected: {
                key: '',
                selectedDataKeys: {
                    'Fundamentals': {
                        label: 'Fundamentals',
                        Date: {
                            date: ''
                        },
                        Criteria: {
                            key: '',
                            criteria: ['Dividend Per Share', 'Dividend Yield', 'P/E Ratio', 'Franking Rate', 'EPS', 'Book value', 'Market Cap', 'Earning Date']
                        },
                        Operator: {
                            key: '',
                            operator: ['>', '<', '=']
                        },
                        Value: {
                            value: ''
                        },
                        Checkbox: {
                            Absolute: false, Percent: false
                        },
                        Comment: {
                            comment: ''
                        }
                    },
                    'Technicals': {
                        label: 'Technicals',
                        Date: {
                            date: '',
                        },
                        Criteria1: {
                            key: '',
                            criteria: {
                                'Price_Close': [],
                                'Volume': [],
                                'MA': [20],
                                'EMA': [20],
                                'MACD': [12, 26, 9],
                                'BBand': [10, 20],
                                'RSI': [14]
                            }
                            // criteria: {
                            //     'Price Close': { value: 0 },
                            //     'Valume': { value: 0 },
                            //     'MA': { value: 20 },
                            //     'EMA': { value: 20 },
                            //     'MACD': { value: 12, value: 26, value: 9 },
                            //     'BBand': { value: 10, value: 20 },
                            //     'RSI': { value: 14 }
                            // }
                        },
                        Operator: {
                            key: '',
                            operator: ['>', '<', '=', 'Trending', 'Cross']
                        },
                        Criteria2: {
                            key: '',
                            criteria: {
                                'Price_Open': [],
                                'Price_Close': [],
                                'Valume': [],
                                'Custom_Value': [20],
                                'MA': [20],
                                'EMA': [20],
                                'MACD': [12, 26, 9],
                                'BBand': [10, 20],
                                'RSI': [14]
                            }
                        },
                        Qualifier: {
                            key: '',
                            qualifier: ['Up', 'Down', 'Cross', 'And', 'Or', '.=', '<', '>']
                        },
                        Comment: {
                            comment: ''
                        }
                    },
                    'Market': {
                        label: 'Market',
                        Date: { date: '' },
                        Country: {
                            key: '',
                            MarketCountry: {
                                'USA': {
                                    key: '',
                                    'Market': {
                                        key: '',
                                        'Market': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                                    },
                                    'Industry': {
                                        key: '',
                                        'Industry': ['Airlines', 'Banks', 'Coal', 'Computer Hardware', 'Construction Materials', 'Food Processing', 'Reinsurance', 'Tobacco']
                                    },
                                    'Sector': {
                                        key: '',
                                        'Sector': ['Basic Materials', 'Energy', 'Government Acivities', 'Technology']
                                    }
                                },
                                'Canada': {
                                    key: '',
                                    'Market': {
                                        key: '',
                                        'Market': ['TSX', 'ICE', 'CME'],
                                    },
                                    'Industry': {
                                        key: '',
                                        'Industry': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"]
                                    },
                                    'Sector': {
                                        key: '',
                                        'Sector': ['Academic & Educational Services', 'Consumer Goods', 'Financials', 'Healthcare', 'Technology'],
                                    }
                                },
                                'Australia': {
                                    key: '',
                                    'Market': {
                                        key: '',
                                        'Market': ['ASX', 'ASX 20', 'ASX 200']
                                    },
                                    'Industry': {
                                        key: '',
                                        'Industry': ['Agricultural', 'Auto & Truck Manufacturers', 'Civial & Social', 'Gold', 'Hotels', 'Pharma', 'Real Estate']
                                    },
                                    'Sector': {
                                        key: '',
                                        'Sector': ['Consumer Non-cycle', 'Institutions', 'Utilities']
                                    }
                                }
                            }
                        },
                        Operator: {
                            key: '',
                            operator: ['=']
                        },
                        Criteria2: {
                            key: '',
                            key2: '',

                            criteria: {
                                'DOW': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                                'Banks': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"],
                                'ICE': ['TSX', 'ICE', 'CME'],
                            }
                        },
                        Comment: {
                            comment: ''
                        }
                    }
                }
            },

            NewSearchData: {

                'Fundamentals': {
                    label: 'Fundamentals',
                    Date: {
                        singleDate: ''
                    },
                    Criteria: {
                        criteria: ['Dividend Per Share', 'Dividend Yield', 'P/E Ratio', 'Franking Rate', 'EPS', 'Book value', 'Market Cap', 'Earning Date']
                    },
                    Operator: {
                        operator: ['>', '<', '=']
                    },
                    Value: {
                        value: ''
                    },
                    Checkbox: {
                        Absolute: '', Percent: ''
                    },
                    Comment: {
                        comment: ''
                    }
                },
                'Technicals': {
                    label: 'Technicals',
                    Date: {
                        MultiDateRange: ''
                    },
                    Criteria1: {
                        criteria: {
                            'Price_Close': [],
                            'Volume': [],
                            'MA': [20],
                            'EMA': [20],
                            'MACD': [12, 26, 9],
                            'BBand': [10, 20],
                            'RSI': [14]
                        }
                    },
                    Operator: {
                        operator: ['>', '<', '=', 'Trending', 'Cross']
                    },
                    Criteria2: {
                        criteria: {
                            'Price_Open': [],
                            'Price_Close': [],
                            'Valume': [],
                            'Custom_Value': [20],
                            'MA': [20],
                            'EMA': [20],
                            'MACD': [12, 26, 9],
                            'BBand': [10, 20],
                            'RSI': [14]
                        }
                    },
                    Qualifier: {
                        qualifier: ['Up', 'Down', 'Cross', 'And', 'Or', '.=', '<', '>']
                    },
                    Comment: {
                        comment: ''
                    }
                },
                'Market': {
                    label: 'Market',
                    Date: { date: new Date() },
                    Country: {
                        'USA': {
                            'Market': {
                                'Market': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                            },
                            'Industry': {
                                'Industry': ['Airlines', 'Banks', 'Coal', 'Computer Hardware', 'Construction Materials', 'Food Processing', 'Reinsurance', 'Tobacco']
                            },
                            'Sector': {
                                'Sector': ['Basic Materials', 'Energy', 'Government Acivities', 'Technology']
                            }
                        },
                        'Canada': {
                            'Market': {
                                'Market': ['TSX', 'ICE', 'CME'],
                            },
                            'Industry': {
                                'Industry': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"]
                            },
                            'Sector': {
                                'Sector': ['Academic & Educational Services', 'Consumer Goods', 'Financials', 'Healthcare', 'Technology'],
                            }
                        },
                        'Australia': {
                            'Market': {
                                'Market': ['ASX', 'ASX 20', 'ASX 200']
                            },
                            'Industry': {
                                'Industry': ['Agricultural', 'Auto & Truck Manufacturers', 'Civial & Social', 'Gold', 'Hotels', 'Pharma', 'Real Estate']
                            },
                            'Sector': {
                                'Sector': ['Consumer Non-cycle', 'Institutions', 'Utilities']
                            }
                        }
                    },
                    Operator: {
                        operator: ['=']
                    },

                    Criteria2: {
                        criteria: {
                            'DOW': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                            'Banks': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"],
                            'ICE': ['TSX', 'ICE', 'CME'],
                        }
                    },
                    Comment: {
                        comment: ''
                    }
                }
            },
            DefaultNewSearchData: {
                'Fundamentals': {
                    label: 'Fundamentals',
                    Date: {
                        singleDate: ''
                    },
                    Criteria: {
                        criteria: ['Dividend Per Share', 'Dividend Yield', 'P/E Ratio', 'Franking Rate', 'EPS', 'Book value', 'Market Cap', 'Earning Date']
                    },
                    Operator: {
                        operator: ['>', '<', '.=']
                    },
                    Value: {
                        value: ''
                    },
                    Checkbox: {
                        Absolute: '', Percent: ''
                    },
                    Comment: {
                        comment: ''
                    }
                },
                'Technicals': {
                    label: 'Technicals',
                    Date: {
                        MultiDateRange: ''
                    },
                    Criteria1: {
                        criteria: {
                            'Price_Close': [],
                            'Volume': [],
                            'MA': [20],
                            'EMA': [20],
                            'MACD': [12, 26, 9],
                            'BBand': [10, 20],
                            'RSI': [14]
                        }
                    },
                    Operator: {
                        operator: ['>', '<', '.=', 'Trending', 'Cross']
                    },
                    Criteria2: {
                        criteria: {
                            'Price_Open': [],
                            'Price_Close': [],
                            'Valume': [],
                            'Custom_Value': [20],
                            'MA': [20],
                            'EMA': [20],
                            'MACD': [12, 26, 9],
                            'BBand': [10, 20],
                            'RSI': [14]
                        }
                    },
                    Qualifier: {
                        qualifier: ['Up', 'Down', 'Cross', 'And', 'Or', '.=', '<', '>']
                    },
                    Comment: {
                        comment: ''
                    }
                },
                'Market': {
                    label: 'Market',
                    Date: { date: new Date() },
                    Country: {
                        'USA': {
                            'Market': {
                                'Market': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                            },
                            'Industry': {
                                'Industry': ['Airlines', 'Banks', 'Coal', 'Computer Hardware', 'Construction Materials', 'Food Processing', 'Reinsurance', 'Tobacco']
                            },
                            'Sector': {
                                'Sector': ['Basic Materials', 'Energy', 'Government Acivities', 'Technology']
                            }
                        },
                        'Canada': {
                            'Market': {
                                'Market': ['TSX', 'ICE', 'CME'],
                            },
                            'Industry': {
                                'Industry': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"]
                            },
                            'Sector': {
                                'Sector': ['Academic & Educational Services', 'Consumer Goods', 'Financials', 'Healthcare', 'Technology'],
                            }
                        },
                        'Australia': {
                            'Market': {
                                'Market': ['ASX', 'ASX 20', 'ASX 200']
                            },
                            'Industry': {
                                'Industry': ['Agricultural', 'Auto & Truck Manufacturers', 'Civial & Social', 'Gold', 'Hotels', 'Pharma', 'Real Estate']
                            },
                            'Sector': {
                                'Sector': ['Consumer Non-cycle', 'Institutions', 'Utilities']
                            }
                        }
                    },
                    Operator: {
                        operator: ['.=']
                    },

                    Criteria2: {
                        criteria: {
                            'DOW': ['USA', 'NASDAQ', 'NYSE', 'DOW', 'S&P500'],
                            'Banks': ['Airlines', 'Banks', 'Chemicals', 'Electric Utilities', 'Home Furnishings', 'Software', "Toys & Children's Products"],
                            'ICE': ['TSX', 'ICE', 'CME'],
                        }
                    },
                    Comment: {
                        comment: ''
                    }

                }
            },

            searchData: {
                meta: {
                    name: "unnamed", //nullable
                    location: "folder_name/favourits/", //nullable
                },
                criteria: {
                    country: "US",

                    technicals: [
                        {
                            indicator_id: "macd",
                            indicator_name: "MACD",
                            values: [
                                { signal_r: 14, signal_n: 3, period: 12 },
                                // { signal1: 14, signal2: 3, period: 14 },
                            ],
                        },
                    ],
                    //fundamentals
                },
            },
            activeIndicatorParameters: [],
            indicatorsDropdown: {
                "macd": {
                    label: "MACD",
                    description: "lorem ipsum....",
                    parameters: [
                        {
                            id: "period",
                            placeholder: "Period",
                            type: "number",
                            default: 12
                        },
                        {
                            id: "signal_r",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        },
                        {
                            id: "signal_n",
                            placeholder: "Signal N",
                            type: "number",
                            default: 9
                        }
                    ]
                },
                "roc": {
                    label: "ROC",
                    description: "lorem ipsum....",
                    parameters: [
                        {
                            id: "period",
                            placeholder: "Period",
                            type: "number",
                            default: 12
                        }
                    ]
                },
                "rsi": {
                    label: "RSI",
                    description: "Simple moving average",
                    parameters: [
                        {
                            id: "days",
                            placeholder: "Period",
                            type: "number",
                            default: 10
                        }
                    ]
                },
                "bband": {
                    label: "BBand",
                    description: "Simple moving average",
                    parameters: [
                        {
                            id: "days",
                            placeholder: "Period",
                            type: "number",
                            default: 11
                        }
                    ]
                },
                "myfilter": {
                    label: "MyFilter",
                    description: "lorem ipsum....",
                    parameters: [
                        {
                            id: "period",
                            placeholder: "Period",
                            type: "number",
                            default: 12
                        },
                        {
                            id: "signal_r1",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        }, {
                            id: "signal_r2",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        }, {
                            id: "signal_r3",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        }, {
                            id: "signal_r4",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        }, {
                            id: "signal_r5",
                            placeholder: "Signal R",
                            type: "number",
                            default: 26
                        },
                        {
                            id: "signal_n",
                            placeholder: "Signal N",
                            type: "number",
                            default: 9
                        }
                    ]
                },


            },
            isEdit: false,
            selectedData: null
        }
    },
    methods: {

        // new file modal  work

        FileCloseModal() {
            this.filesaveModal = false
        },
        SaveFileDataModal(fileColor, fileName) {
            console.log("before prop data ", this.NewSearch_JSONData_Array)
            this.$emit('SaveFileDataModal', fileColor, fileName, this.NewSearch_JSONData_Array)
        },



        // new file modal work






        // new folder work
        ClearScreenData() {
            this.NewSearch_JSONData_Array = []
        },

        ShowHeaderRow() {
            this.$emit('ShowHeaderAndSearchBtn')
        },



        // new Search data


        HeaderShowCalendar() {
            this.showCalendar = !this.showCalendar
            this.showRowCalendar = false

        },
        ShowCalendar(index) {

            this.selctedRowIndex = index
            this.showRowCalendar = !this.showRowCalendar
            this.showCalendar = false

        },
        FundamentalValueTypeChecked(i) {
            if (i == 0) {
                this.headerCheckedIndex = i
                console.log("......this.headerCheckedIndex ", this.headerCheckedIndex)
                this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Absolute = true
                this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Percent = false
            }
            else if (i == 1) {
                this.headerCheckedIndex = i
                this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Percent = true
                this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Absolute = false
            }

        },
        OperatorChange(e) {
            if (this.typeSelected == 'Fundamentals') {
                this.NewSearchData_Selected.selectedDataKeys[this.typeSelected].Operator.key = e.target.value
                // this.NewSearchData_Selected.selectedDataKeys.Technicals.Operator.key = ''
                // this.NewSearchData_Selected.selectedDataKeys.Market.Operator.key = ''
            }
            else if (this.typeSelected == 'Technicals') {

                this.NewSearchData_Selected.selectedDataKeys[this.typeSelected].Operator.key = e.target.value
                // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Operator.key = ''
                // this.NewSearchData_Selected.selectedDataKeys.Market.Operator.key = ''
            }
            else if (this.typeSelected == 'Market') {

                this.NewSearchData_Selected.selectedDataKeys[this.typeSelected].Operator.key = e.target.value
                // this.NewSearchData_Selected.selectedDataKeys.Technicals.Operator.key = ''
                // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Operator.key = ''
            }

        },
        FundamentalsCriteria(e) {
            // this.NewSearchData_Selected.Fundamentals.Criteria.key = e.target.value
            this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Criteria.key = e.target.value
            // console.log('this.NewSearchData_Selected.Fundamentals.Criteria.criteria ', this.NewSearchData_Selected.Fundamentals.Criteria.criteria)
        },
        TechnicalCriteria1(e) {

            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key  = e.target.value
            // console.log(e.target.value, " index ",index)
            // let obj = this.NewSearchData.Technicals.Criteria1.criteria
            // const secondKey = Object.keys(obj)[e.target.value];
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key = secondKey + this.NewSearchData.Technicals.Criteria1.criteria[Object.keys(this.NewSearchData.Technicals.Criteria1.criteria)[e.target.value]]
            // + this.NewSearchData.Technicals.Criteria1.criteria[e.target.value].join(", ")
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.criteria[a[0]]
            console.log("e.target.value ", e.target.value)
            this.criteria1_Or_criteria2 = 'criteria1'
            let a = e.target.value.split(" ")

            this.newParameterLength = this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.criteria[a[0]]
            this.criteria1Key = a[0]

            for (let i in this.newParameterLength) {
                // console.log("..",this.newParameterLength[i])
                if (this.newParameterLength[i] > 0) {
                    this.newFiltermodalshow = true
                    break
                }
                else {
                    this.newFiltermodalshow = false
                }

            }
            // console.log("...........", this.newParameterLength)
        },
        newCloseModal() {
            this.newFiltermodalshow = false
        },
        newSaveModal(paramArray) {
            this.newFiltermodalshow = false
            if (this.criteria1_Or_criteria2 == 'criteria1') {
                console.log("param array ", paramArray)
                this.NewSearchData.Technicals.Criteria1.criteria[this.criteria1Key] = paramArray
                this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.criteria[this.criteria1Key] = paramArray
                this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key = `${this.criteria1Key} (${paramArray})`
                console.log("........close modal ", this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key)
            }
            else if (this.criteria1_Or_criteria2 == 'criteria2') {
                this.NewSearchData.Technicals.Criteria2.criteria[this.criteria2Key] = paramArray
                this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.criteria[this.criteria2Key] = paramArray
                this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.key = `${this.criteria2Key} (${paramArray})`
            }
            this.criteria1_Or_criteria2 = ''
            paramArray = []
        },
        TechnicalCriteria2(e) {
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.key = e.target.value
            this.criteria1_Or_criteria2 = 'criteria2'
            let a = e.target.value.split(" ")

            this.newParameterLength = this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.criteria[a[0]]
            this.criteria2Key = a[0]

            for (let i in this.newParameterLength) {
                // console.log("..",this.newParameterLength[i])
                if (this.newParameterLength[i] > 0) {
                    this.newFiltermodalshow = true
                    break
                }
                else {
                    this.newFiltermodalshow = false
                }
            }
        },
        TechnicalQualifier(e) {
            this.NewSearchData_Selected.selectedDataKeys.Technicals.Qualifier.key = e.target.value
        },
        MarketCountrySelect(e) {
            this.countrySelected = e.target.value
            this.NewSearchData_Selected.selectedDataKeys.Market.Country.key = e.target.value
            // console.log("e.target.value ", Object.keys(this.NewSearchData[this.typeSelected].Country[this.countrySelected]))
        },
        HeaderTypeSelected(e) {
            this.typeSelected = e.target.value
            this.NewSearchData_Selected.key = e.target.value
            this.showRowCalendar = false
            this.showCalendar = false
        },
        TypeSelected(e) {
            this.NewSearchData_Selected.key = e.target.value
            this.showRowCalendar = false
            this.showCalendar = false
        },
        HeaderCountryTypeSeled(e) {
            this.countryTypeSelected = e.target.value
            this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry[this.NewSearchData_Selected.selectedDataKeys.Market.Country.key].key = e.target.value
        },
        CountryTypeSelect(e) {
            this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry[this.NewSearchData_Selected.selectedDataKeys.Market.Country.key].key = e.target.value
        },
        HeaderCountryTypeSelectedData(e) {
            this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry[this.countrySelected][this.countryTypeSelected].key = e.target.value
        },
        CountryTypeSelectedData(e) {
            this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry[this.NewSearchData_Selected.selectedDataKeys.Market.Country.key][this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry[this.NewSearchData_Selected.selectedDataKeys.Market.Country.key].key].key = e.target.value
        },
        HeaderMarketCriteria2(e) {
            this.marketCriteria2 = e.target.value
            this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key = e.target.value
        },
        MarketCriteria2(e) {
            this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key = e.target.value
        },
        MarketCriteria2Type(e) {
            this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key2 = e.target.value
        },
        AddRow() {
            this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Value.value = this.fundamentalUserValue
            this.NewSearch_JSONData_Array.push(JSON.parse(JSON.stringify(this.NewSearchData_Selected)))
            this.fundamentalUserValue = ''
            this.showCalendar = false
            // this.showHeaderRow = false
            // this.NewSearchData_Selected.key = ''
            this.typeSelected = ''
            // empty keys after adding row .... important comment .....

            // this.NewSearchData_Selected.key = '--Select Type--' 
            // for Fundamentals
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Date.date = ''
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Criteria.key = '--Select Criteria--'
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Operator.key = '--Select Operator--'
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Absolute = false
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Checkbox.Percent = false
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Comment.comment = ''
            // for Technicals
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Date.date = ''
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key = '--Select Criteria 1--'
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Operator.key = '--Select Operator--'
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.key = '--Select Criteria 2--'
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Qualifier.key = '--Select Qualifier--'
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Comment.comment = ''
            // foe Market
            // this.NewSearchData_Selected.selectedDataKeys.Market.Date.date = ''
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.key = '--Select Country--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.USA.key = '--Select Country Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.USA.Market.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.USA.Industry.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.USA.Sector.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Canada.key = '--Select Country Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Canada.Market.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Canada.Industry.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Canada.Sector.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Australia.key = '--Select Country Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Australia.Market.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Australia.Industry.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Country.MarketCountry.Australia.Sector.key = '--Select Data Type--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Operator.key = '--Select Operator--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key = '--Select Criteria 2--'
            // this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key2 = ''

            // this.NewSearchData_Selected.selectedDataKeys.Market.Comment.comment = ''

            // this.NewSearchData_Selected.selectedDataKeys.Market.Criteria2.key2 = ''
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Date.date = ''
            // this.NewSearchData_Selected.selectedDataKeys.Technicals.Date.date = ''
            // this.NewSearchData_Selected.selectedDataKeys.Market.Date.date = ''

            // Default selection of Row after adding previous Row ..... mportant comment

            // For Technicals
            this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria1.key = "--Select Criteria 1--"
            this.NewSearchData_Selected.selectedDataKeys.Technicals.Criteria2.key = "--Select Criteria 2--"

            // Default selection of Row after adding previous Row ..... mportant comment



            //  old keys after adding row ..... important comment


            this.NewSearchData = this.DefaultNewSearchData
            // this.NewSearchData_Selected.selectedDataKeys.Fundamentals.Criteria.criteria = this.DefaultNewSearchData.Fundamentals.Criteria.criteria 
            // this.NewSearchData_Selected.selectedDataKeys.Technicals = this.DefaultNewSearchData.Technicals
            // this.NewSearchData_Selected.selectedDataKeys.Market = this.DefaultNewSearchData.Market

            console.log("values", this.NewSearch_JSONData_Array)


        },
        DeleteRow(id) {
            this.NewSearch_JSONData_Array.splice(id, 1)
            console.log(id)
        },
        MsgBox() {
            alert('Message box is in developed mode')
        },
        HeaderMsgBox() {
            alert('Message box is in developed mode')
        },



        // new Search data




        showFilterModal() {
            console.log("showFilterModal")
            this.filtermodalshow = true
        },
        closeModal(isEdit) {

            this.filtermodalshow = false
            this.resetDefaults(isEdit)
        },

        resetDefaults(isEdit) {
            if (isEdit) {
                this.isEdit = false
                this.selectedData = null
            }
        },
        editFilterModal(indicator, parameter, index) {
            this.slectedIndicatorIndex = index
            console.log(indicator, parameter, index);
            this.isEdit = true;
            const newObj = JSON.parse(JSON.stringify(this.searchData.criteria.technicals[index].values[0]))
            console.log();
            this.selectedData = {
                data: newObj,
                indicator
            }
            this.showFilterModal();
        },
        deleteParameter(params, idd) {
            console.log("deleteParamater")
            let a = params.splice(idd, 1)
            console.log(a)
        },
        deleteRow(idd) {
            console.log("deleteRow")
            let a = this.searchData.criteria.technicals.splice(idd, 1)
            console.log(a)
        },

        saveSearch() {
            this.filesaveModal = true
            this.$emit('saveSearch', this.NewSearch_JSONData_Array)
            this.filesDataArray.push(this.NewSearch_JSONData_Array)

        },
        saveChanges(dataObject, indicator, isEdit) {

            console.log(dataObject, indicator, isEdit)
            let data = {
                indicator_id: indicator.selectedIndicator,
                indicator_name: indicator.selectedIndicatorName,
                values: [dataObject],
            }
            if (isEdit) {
                let local = this.searchData.criteria.technicals[this.slectedIndicatorIndex]
                local.values.push(dataObject)
                console.log('local', JSON.stringify(local));
            }
            else {
                this.searchData.criteria.technicals.push(data)
            }
            this.closeModal(isEdit)
            // new work
        },
    },
    watch: {
        // selectedFileIndex(newVal, oldVal){
        //     // console.log("..............................new =",newVal," old=", oldVal)
        //     for(let a of this.obj.folder)
        //   {
        //     if(this.acc_Folder_Name == a.name)
        //     {
        //         console.log("this.selectedFileInde/x ",this.selectedFileIndex)

        //         this.NewSearch_JSONData_Array = this.obj.folder[a.id].files.saveData[this.selectedFileIndex]


        // //       this.obj.folder[a.id].files.fileName.push(newFIleName)
        // //   this.obj.folder[a.id].files.saveData.push(this.newRowData)
        // //       console.log('name mil  gya ', a.name, 'a.id', a.id)
        //     }
        //   }

        // },
        selectedFileData() {
            this.NewSearch_JSONData_Array = this.selectedFileData
        },
        myArray() {
            // console.log("array to chal h efor", this.myArray)


            for (let a of this.obj.folder) {
                if (this.acc_Folder_Name == a.name) {
                    // console.log("this.selectedFileIndex ",this.selectedFileIndex)
                    // console.log("............................a.id", a.id)
                    this.NewSearch_JSONData_Array = this.obj.folder[a.id].files.saveData[this.selectedFileIndex]
                }
            }
            if (this.myArray.length > 2) {
                this.myArray.shift()
            }

            console.log("..........................................this.obj ", this.obj)

        }

    }
}
</script>
<style scoped>
/* <div class="w-5/12 border border-[0.3px] border-[#7582785c] rounded box-border box-content pt-6 pl-4 pr-4 mx-2 pb-2">
        <div class="text-white text-sm font-semibold">Build Stock Search</div>

        <div class="space-y-4">
            <div>
                <div class=" border border-[#1E2224] bg-[#1E2224] rounded p-3 flex my-1 py-5 box-border box-content formsize">
                    <div class="flex">
                        <div class="text-[10px] font-normal text-white">
                            Select&nbsp;Country
                        </div>
                        <span class="text-white text-[10px] pl-1 opacity-40">&nbsp;(required&nbsp;field)&nbsp;</span>
                    </div>

                    <div class="flex  flex-start ml-6">
                        <div class="form-check flex">
                    <span class="flex">
                      <input class=" form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-[#1E2224] checked:bg-[#00C92E] checked:bg-[#00C92E] focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="
                          form-check-label
                          text-white text-[10px]
                          inline-block
                        " for="flexCheckDefault">&nbsp;United&nbsp;States</label>
                    </span>
                            <span class="flex mx-6">
                      <input class=" form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-[#1E2224] checked:bg-[#00C92E] checked:bg-[#00C92E] focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="
                          form-check-label
                          text-white text-[10px]
                          inline-block
                        " for="flexCheckDefault">Canada</label>
                    </span>
                            <span class="flex">
                      <input class=" form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-[#1E2224] checked:bg-[#00C92E] checked:bg-[#00C92E] focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="
                          form-check-label
                          text-white text-[10px]
                          inline-block
                        " for="flexCheckDefault">Australia</label>
                    </span>
                        </div>
                    </div>
                </div>

              
                <div class="h-[33.5vh] overflow-y-auto">
                    <div v-for="(i, index) in searchData.criteria.technicals" :key="index" class=" border border-[#1E2224] bg-[#1E2224] rounded p-3 flex justify-between my-1">
                        <div class="flex w-[40%]">
                            <div class="text-[10px] font-normal text-white p-2">
                                {{ i.indicator_name }}
                            </div>
                        </div>
                        <div class="flex gap-2 items-center grow-[1]">

                            <div class=" text-white rounded flex items-center    cursor-pointer px-1 h-[70%]" style="background: rgba(248, 248, 249, 0.03)"
                                 v-on:click="editFilterModal(i.indicator_id, i, index)">
                                 
                                <p>+</p> 
                            </div>
                            <div class=" overflow-x-auto flex gap-1 items-center h-full">

                                <div class="flex items-center justify-between min-w-fit px-1 h-[70%]" v-for="(s, indexx) of i.values"
                                     :key="indexx" style="background: rgba(0, 201, 46, 0.12)">
                                    <div class="text-[#00C92E] text-[10px]">
                                        {{ Object.values(s) }}
                                    </div>
                                    <div class=" cursor-pointer" v-on:click="deleteParameter(i.values, indexx)">
                                        <img src="images/cross2.svg" alt="cross" class="h-3 w-2" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-[5%] justify-end flex items-center">
                            <div class=" border rounded-full bg-white w-3 h-3                         cursor-pointer" v-on:click="deleteRow(index)">
                                <img src="images/cross.svg" alt="cross" />
                            </div>
                        </div>
                    </div>



                </div>

                <div class="text-[#00C92E] text-xs font-normal mt-4 cursor-pointer add-button inline-block p-1"
                     @click="showFilterModal">
                    + <span class="border-b-[1px] border-b-[#00C92E]">ADD FILTERS</span>
                </div>
            </div>

            <div class="flex justify-between">
                <button type="button" class=" cancel-button border rounded border-white text-white p-2 px-4 box-border box-content text-[10px] font-normal cursor-pointer" @click="saveSearch">
                    SAVE SEARCH
                </button>
                <button type="button" class="                ok-button border rounded border-[#00C92E] text-[#00C92E] p-2 px-4 box-border box-content text-[10px] font-normal cursor-pointer">
                    RUN SEARCH
                </button>
            </div>
        </div>
    </div> */


.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
    /* width: 20px !important; */
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}


.container {
    display: block;
    position: relative;

    cursor: pointer;
    width: 0.7em;
    height: 0.7em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;

    width: 0.7vw;
    height: 1.4vh;
    border: 0.1vw solid #828282;
    border-radius: .1vw;
    background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: rgba(0, 201, 46, 0.12);
    border: 0.1vw solid #00C92E;
    border-radius: .1vw;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;

    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: .12vw;
    bottom: .13vh;
    width: 24%;
    height: 70%;
    border: solid #00C92E;
    border-width: 0 .12vw .12vw 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* table tr td {
    color: white;

    flex-grow: 1;
    display: flex;
    justify-content: start;
    font-size: .7vw;
} */

.tr {
    display: flex;

}

.tr .th {
    /* color: white; */
    /* border: 1px solid white; */
    flex-grow: 1;
    display: flex;
    justify-content: start;
    font-size: .6vw;
    min-width: fit-content;
    height: 3vh;
    /* border-radius: 20px; */

}

select {
    width: 100%;
    background-color: black;
    color: white;
    /* border: .3px solid #00C92E; */
    font-weight: 400;
    border-radius: 4px;
}

input {
    background-color: black;
    color: white;
    /* border: .3px solid #00C92E; */
    display: flex;
}

.indicators-div::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}
</style>



