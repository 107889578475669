import axios from "axios";
import router from "@/router";

axios.interceptors.request.use(
  (req) => {

    let headers = {
      Authorization: localStorage.getItem("token"),
    };
    if (headers) {
      req.headers = headers;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
    
    }
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
     
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      router.push("/");
    }
    return Promise.reject(err);
  }
);

export default axios;
