<template>
   <div class="w-full h-full rounded-[3px]" style="border: 0.3px solid rgba(233, 233, 233, 0.2);">
      <!-- /////////////////------------------- pdf report --------------------////////////////  -->


      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
         :paginate-elements-by-height="1400" :filename="getDownloadName()" :pdf-quality="2" :manual-pagination="true"
         pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px"
         @progress="onProgress($event)" ref="html2Pdf">

         <!-- <div slot="pdf-content" class="bg-red-600 w-full h-full"> -->
         <div slot="pdf-content" class="px-10">
            <div class="page" style="page-break-after: always;">
               <StockReportHeader />
               <br>
               <div class="bg-[#f8f9f9] space-y-2 h-42 -mt-2 w-full flex flex-col justify-between rounded-md">
                  <div class="flex space-x-2 py-1 pl-2 w-full h-[65%]">
                     <div class="w-5/12 p-2 flex items-start flex-col justify-between">
                        <div class="w-full h-full">
                           <div class="flex flex-col -mt-6 justify-start items-start">
                              <span style="font-weight: 800" class="text-[44px] text-[#151515]">{{
                                 companyReportDetail?.symbol || 'N/A' }}</span>
                              <span class="text-[12px] font-normal text-[#151515]">{{ companyReportDetail?.company_name
                                 ||
                                 'N/A' }}</span>
                           </div>
                        </div>
                        <div>
                           <span class="text-[12px] text-black font-semibold">Exchange: {{ companyReportDetail?.exchange
                              ||
                              'N/A' }}</span>
                        </div>
                     </div>

                     <div class="w-7/12 text-sm p-2 flex flex-col justify-between">
                        <div class="w-full">
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-light w-6/12 text-[#333333] text-[12px]">First Trade Date:</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{
                                 companyReportDetail?.first_traded || 'N/A' }}</span>
                           </div>
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-ligh w-6/12 t text-[#333333] text-[12px]">Closing Price ($):</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReportDetail?.close ||
                                 'N/A' }}</span>
                           </div>
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-light w-6/12 text-[#333333] text-[12px]">Annual Growth (%):</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{
                                 companyReportDetail?.annual_growth ? companyReportDetail?.annual_growth : 'N/A'
                              }}</span>
                           </div>
                        </div>
                        <div class="w-full">
                           <div class="flex items-center w-full justify-between space-x-2"><span
                                 class="font-light w-6/12 text-[#333333] text-[12px]">Business Classification
                                 (GICS):</span><span class="w-6/12 font-semibold text-black text-[12px]">{{
                                    companyReportDetail?.business_classification || 'N/A' }}</span></div>
                           <div class="flex w-full items-center space-x-2 justify-between whitespace-nowrap"><span
                                 class="font-light text-[#333333] text-[12px] w-6/12 whitespace-nowrap">Sector:</span><span
                                 class="font-semibold text-black text-[12px] w-6/12 whitespace-nowrap">{{
                                    companyReportDetail?.sector || 'N/A' }}</span></div>
                           <div class="flex w-full items-center space-x-2 justify-between"><span
                                 class="font-light text-[#333333] text-[12px] w-6/12">Business Classification
                                 (TRBC):</span><span class="font-semibold text-black text-[12px] w-6/12">{{
                                    companyReportDetail?.trbc_classification || 'N/A' }}</span></div>
                        </div>

                     </div>
                  </div>
                  <div
                     class="h-[12%] font-normal text-[#151515] text-[10px] font-semibold pb-2 text-center bg-[#EFF2F3] w-full border-t border-[#E5E9EC]">
                     This Report is subject to the important notice at the bottom of the last page
                  </div>

               </div>
               <br>
               <div class="w-full bg-black my-2 h-[22vh] pt-2 rounded-[10px]"
                  style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 3px;">
                  <div class="w-full h-full bg-black apexChartdiv vld-parent" ref="areaChartHeightDiv">
                     <AreaChart :is-index-chart="false" :isStockAlert="true" v-if="symbolChartData?.length > 0"
                        class="-mt-2" :chartHeight="chartHeight" :showTrend="true" :data-chart="symbolChartData"
                        :symbol-name="stockAlert_selected_symbol" :stockAlertpdfChart="false" :pdfChart="true"
                        @value-emitted="onValueEmitted" />
                  </div>
               </div>
               <br>
               <div class="w-full px-4 -mt-2">
                  <div class="text-[16px] text-black font-bold -mt-2">Business Activity Summary</div>
                  <div class="text-[#000000] text-justify mt-1 text-[10px]">{{ companyReportDetail?.overview ||
                     'N/A' }}</div>
                     <br>
                  <div class="text-[16px] text-black font-bold mt-1 pb-2">Performance at a Glance</div>
                  <div class="w-full space-x-0.5 flex mt-1 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit Margin</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_profit ?
                        formatNumber(companyReportDetail?.net_profit, 'net_profit') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Growth Rate 5 Yrs</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail?.revenue_growth_5_yrs
                           ? formatNumber(companyReportDetail?.revenue_growth_5_yrs, 'revenue_growth_5_yrs') : 'N/A' }}
                     </div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Debt</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_debt ?
                        formatNumber(companyReportDetail?.net_debt, 'net_debt') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt Growth Rate 5 Yrs</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_growth_5_yrs
                        ?
                        formatNumber(companyReportDetail?.debt_growth_5_yrs, 'debt_growth_5_yrs') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_yield_1_yr ?
                        formatNumber(companyReportDetail?.div_yield_1_yr, 'div_yield_1_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Assets to Liabilities Ratio</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail?.assets_to_liabilities_ratio ?
                           formatNumber(companyReportDetail?.assets_to_liabilities_ratio, 'assets_to_liabilities_ratio') :
                           'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Largest Gap Down 1 Yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail['biggest_gap_down_%']
                           ? formatNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Debt to Equity LT</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_lt
                        ?
                        formatNumber(companyReportDetail?.debt_to_equity_lt, 'debt_to_equity_lt') : 'N/A' }}</div>
                  </div>
                  <StockReportFooter :current-page="1" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
               </div>
            </div>



            <div class="page" style="page-break-after: always;">
               <StockReportHeader />

               <div class="px-4">
                  <div class="text-[16px] text-black font-bold -mt-1 pb-2">Fundamental Performance</div>

                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Earnings</div>
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Value</div>
                  </div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">P/E</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['p/e'] ?
                     formatNumber(companyReportDetail['p/e']) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Quick Ratio</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.quick_ratio ?
                     formatNumber(companyReportDetail?.quick_ratio, 'quick_ratio') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EPS</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.eps ?
                     formatNumber(companyReportDetail?.eps) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Current Ratio</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.current_ratio ?
                     formatNumber(companyReportDetail?.current_ratio, 'current_ratio') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Operating Margin</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.op_mgn ?
                     formatNumber(companyReportDetail?.op_mgn, 'op_mgn') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROI</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roi ?
                     formatNumber(companyReportDetail?.roi, 'roi') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EBITDA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.ebitda ?
                     formatNumber(companyReportDetail?.ebitda, 'ebitda') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROE</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roe ?
                     formatNumber(companyReportDetail?.roe, 'roe') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Revenue</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.revenue ?
                     formatNumber(companyReportDetail?.revenue, 'revenue') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROA</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roa ?
                     formatNumber(companyReportDetail?.roa, 'roa') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit 5 Yr Av</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.net_profit_5_yr_av ?
                        formatNumber(companyReportDetail?.net_profit_5_yr_av, 'net_profit_5_yr_av') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Free Cash Flow</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.free_cash_flow ?
                     formatNumber(companyReportDetail?.free_cash_flow, 'free_cash_flow') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Income</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_income ?
                     formatNumber(companyReportDetail?.net_income, 'net_income') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 1 Yr</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.revenue_change_1_yr
                        ? formatNumber(companyReportDetail?.revenue_change_1_yr, 'revenue_change_1_yr') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Pre Tax Income</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.pre_tax_income ?
                     formatNumber(companyReportDetail?.pre_tax_income) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 3 Yr</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.revenue_change_3_yr
                        ? formatNumber(companyReportDetail?.revenue_change_3_yr, 'revenue_change_3_yr') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gross Margin</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gross_margin ?
                     formatNumber(companyReportDetail?.gross_margin, 'gross_margin') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Book Value per Share</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.bk_val ?
                     formatNumber(companyReportDetail?.bk_val) : 'N/A' }}</div>
               </div>
               <div class="px-4">
                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Dividends</div>
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Debt,Assets,Equity</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_$ ?
                        formatNumber(companyReportDetail?.div_$) : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cash per Share</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cash_per_share ?
                        formatNumber(companyReportDetail?.cash_per_share, 'cash_per_share') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['div_%'] ?
                        formatNumber(companyReportDetail['div_%'], 'div_%') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Outstanding</div>
                     <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_out ?
                        formatNumber(companyReportDetail?.shares_out, 'shares_out') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Growth 3 Yrs</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_growth_3_yr ?
                        formatNumber(companyReportDetail?.div_growth_3_yr, 'div_growth_3_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Float</div>
                     <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_float ?
                        formatNumber(companyReportDetail?.shares_float, 'shares_float') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Average 5 Yrs</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_avg_5_yr ?
                        formatNumber(companyReportDetail?.div_avg_5_yr, 'div_avg_5_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt to Equity ST</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_st
                        ? formatNumber(companyReportDetail?.debt_to_equity_st, 'debt_to_equity_st') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Franking (Australia Only)</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['frank_pct_(au)']
                        ? formatNumber(companyReportDetail['frank_pct_(au)'], 'frank_pct_(au)') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Market Cap</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.mkt_cap ?
                        formatNumber(companyReportDetail?.mkt_cap, 'mkt_cap') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Payout Ratio</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.payout_ratio ?
                        formatNumber(companyReportDetail?.payout_ratio, 'payout_ratio') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Interest Coverage</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.interest_coverage
                        ? formatNumber(companyReportDetail?.interest_coverage) : 'N/A' }}</div>
                  </div>
               </div>
               <div v-if="(Math.ceil(dataArray.length / 3) + 2) != 2">
                  <br>
                  <br>
               </div>
                  <div class="px-4">
                  <div class="text-[16px] text-black font-bold -mt-1 pb-2">Price Performance</div>

                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Persistence</div>
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Volatility</div>
                  </div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 60d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center font-semibold h-[2.5vh]"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_60d_ma'] ?
                     formatNumber(companyReportDetail['close_>_60d_ma'], 'close_>_60d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">>5% Swings Count </div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['>5%_swings_count'] ?
                     NoDecimalFormatNumber(companyReportDetail['>5%_swings_count']) : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 126d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_126d_ma'] ?
                     formatNumber(companyReportDetail['close_>_126d_ma'], 'close_>_126d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gap Down Count</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gap_down_count ?
                     formatNumber(companyReportDetail?.gap_down_count, 'gap_down_count') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 252d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_252d_ma'] ?
                     formatNumber(companyReportDetail['close_>_252d_ma'], 'close_>_252d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_$']
                     ? formatNumber(companyReportDetail['biggest_gap_down_$']) : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">60d MA > 200d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['60d_ma_>_200d_ma'] ?
                     formatNumber(companyReportDetail['60d_ma_>_200d_ma'], '60d_ma_>_200d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_%']
                     ? formatNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">52 Week High</div>
                  <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['52w_high'] ?
                     formatNumber(companyReportDetail['52w_high']) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cross 60d MA Count</div>
                  <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cross_60d_ma_count ?
                     formatNumber(companyReportDetail?.cross_60d_ma_count, 'cross_60d_ma_count') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Low Since 52 Week High</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.low_since_52_week_high ?
                        formatNumber(companyReportDetail?.low_since_52_week_high) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Highest to Lowest</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.highest_to_lowest ?
                     formatNumber(companyReportDetail?.highest_to_lowest) : 'N/A' }}</div>
               </div>
               <div class="px-4">
                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Liquidity</div>
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Price Growth</div>
                  </div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Open = Close Count</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['open_=_close'] ?
                     formatNumber(companyReportDetail['open_=_close'], 'open_=_close') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Annual Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.annual_growth ?
                     formatNumber(companyReportDetail?.annual_growth, 'annual_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Turnover</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail['50_turnover_average'] ?
                        formatNumber(companyReportDetail['50_turnover_average'], '50_turnover_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Half Year Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.half_year_growth ?
                     formatNumber(companyReportDetail?.half_year_growth, 'half_year_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">10 Day Average Turnover</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail['10_turnover_average'] ?
                        formatNumber(companyReportDetail['10_turnover_average'], '10_turnover_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Last Quarter Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.last_quarter_growth
                     ? formatNumber(companyReportDetail?.last_quarter_growth, 'last_quarter_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Volume</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['volume_50d_average']
                     ? formatNumber(companyReportDetail['volume_50d_average'], 'volume_50d_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">20 Day Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['20_day_growth'] ?
                     formatNumber(companyReportDetail['20_day_growth'], '20_day_growth') : 'N/A' }}</div>
               </div>
               <StockReportFooter :current-page="2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />

            </div>


            <div v-for="n in Math.ceil(dataArray.length / 3)" :key="n" class="page"
               :class="n !== (Math.ceil(dataArray.length / 3)) ? 'page-break-after' : ''">
               <div class="content">
                  <StockReportHeader />
                  <div>
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1)]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1)]['value'] }}
                     </div>
                  </div>
                  <br>
                  <br>
                  <div v-if="dataArray[3 * (n - 1) + 1]">
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1) + 1]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1) + 1]['value'] }}
                     </div>
                  </div>
                  <br>
                  <br>
                  <div v-if="dataArray[3 * (n - 1) + 2]">
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1) + 2]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1) + 2]['value'] }}
                     </div>
                  </div>
               </div>
               <StockReportFooter :current-page="n + 2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
            </div>
         </div>
      </vue-html2pdf>



      <div class="bg-[#1E2224] w-full h-[15%] flex items-center justify-center">
         <span @click="handleReport(item)" v-for="(item, i) in headings"
            :class="`${item == selectedHeader ? 'bg-[#2e3238] text-white' : 'text-[#5a5e60]'}`"
            class="px-3 cursor-pointer py-[2px] rounded-[3px] font-semibold tracking-wider text-[.7vw] reportHeader"
            :key="i">
            {{ item }}
         </span>
         <span @click="downloadFullReport()"
            :class="symbolChartData?.length > 0 && !pdfLoading ? '' : 'pointer-events-none'" title="download report">

            <svg v-if="pdfLoading" aria-hidden="true"
               class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#02bd2d]" viewBox="0 0 100 101"
               fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
               <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
            </svg>
            <svg v-else width="21" height="21" viewBox="0 0 21 21" class="cursor-pointer" fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M17 12V14.6667C17 15.0203 16.8478 15.3594 16.5769 15.6095C16.306 15.8595 15.9386 16 15.5556 16H5.44444C5.06135 16 4.69395 15.8595 4.42307 15.6095C4.15218 15.3594 4 15.0203 4 14.6667V12M6.88889 8.66667L10.5 12M10.5 12L14.1111 8.66667M10.5 12V4"
                  stroke="#02BD2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </span>
      </div>



      <div v-if="selectedHeader !== 'Commentary'" class="w-full h-[85%] py-1 pr-1">
         <div class="h-[10%] whitespace-nowrap w-full px-6 font-bold tracking-wider text-[.7vw] text-white reportData"
            :title="stockAlert_selected_symbol_detail?.companyName">{{
               trimText(stockAlert_selected_symbol_detail?.companyName) }}</div>
         <div v-if="reportLoading" class="h-[90%] w-full  flex items-center justify-center text-white">Loading...</div>
         <div v-else-if="Object.keys(listData).length > 0" class="h-[90%] w-full overflow-y-auto">
            <div
               class="w-full flex items-center justify-between text-[#F8F9F9]/80 p-1 font-light text-[.6vw] pl-6 pr-3 reportData"
               v-for="(item, i) in listData[selectedHeader]" :key="i">
               <span>
                  {{ getTitleByKey(item.head) }}
               </span>
               <span>
                  <!-- {{ item.value || 'N/A' }} -->
                  {{ formatNumber(item.value, item.head) || 'N/A' }}
               </span>
            </div>
            <div class="w-full pl-6 py-4 pr-3">
               <div @click="downloadFullReport()"
                  :class="symbolChartData?.length > 0 && !pdfLoading ? '' : 'pointer-events-none'"
                  title="download report"
                  class="border rounded-[4px] cursor-pointer border-[#F8F9F9]/90 text-center text-[#F8F9F9]/90">
                  Full Report Download
               </div>
            </div>
         </div>
         <div v-else class="h-[90%] w-full  flex items-center justify-center text-white">No Data Found</div>
      </div>

      <div v-else class="w-full h-[85%] py-4 pr-1">
         <div v-if="reportLoading" class="h-[90%] w-full  flex items-center justify-center text-white">Loading...</div>
         <div v-else-if="Object.keys(listData).length > 0" class="h-full w-full overflow-y-auto">
            <div class="h-[10%] w-full px-6 font-bold tracking-wider text-[.7vw] text-white reportData">Business
               Activity Summary</div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>
                  {{ listData[selectedHeader]?.overview }}
               </span>
            </div>
            <div class="h-[10%] w-full px-6 font-bold tracking-wider text-[.7vw] text-white reportData">Performance at a
               Glance</div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Net Profit Margin (%)</span>
               <span>{{ listData[selectedHeader]?.net_profit ? `${listData[selectedHeader]?.net_profit}` : 'N/A'
                  }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Net Debt ($)</span>
               <span>{{ `${formatNumber(listData[selectedHeader]?.net_debt)}` }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Dividend % 1yr</span>
               <!-- <span>{{ listData[selectedHeader]['div_yield_1_yr'] ? `${listData[selectedHeader]['div_yield_1_yr']}` : 'N/A' }}</span> -->
               <span>{{ `${formatNumber(listData[selectedHeader]['div_%'], 'div_%') || 'N/A'}` }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Largest Gap Down 1 Yr (%)</span>
               <span>{{ listData[selectedHeader]['biggest_gap_down_%'] ?
                  `${formatNumber(listData[selectedHeader]['biggest_gap_down_%'], 'biggest_gap_down_%')}` : 'N/A'
                  }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Revenue Growth Rate 5 Yrs (%)</span>
               <span>{{ `${formatNumber(listData[selectedHeader]?.revenue_growth_5_yrs, 'revenue_growth_5_yrs') ||
                  'N/A'}` }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Debt Growth Rate 5 Yrs (%)</span>
               <span>{{ listData[selectedHeader]?.debt_growth_5_yrs ?
                  `${formatNumber(listData[selectedHeader]?.debt_growth_5_yrs, 'debt_growth_5_yrs')}` : 'N/A' }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportData">
               <span>Assets to Liabilities Ratio (%)</span>
               <span>{{ listData[selectedHeader]?.assets_to_liabilities_ratio ?
                  `${formatNumber(listData[selectedHeader]?.assets_to_liabilities_ratio,
                     'assets_to_liabilities_ratio')}`
                  : 'N/A' }}</span>
            </div>
            <div
               class="w-full flex items-center text-justify justify-between text-[#F8F9F9]/90 p-1 font-light text-[.6vw] pl-6 pr-3 reportDatass">
               <span>Total Debt to Equity LT (%)</span>
               <span>{{ listData[selectedHeader]?.debt_to_equity_lt ?
                  `${formatNumber(listData[selectedHeader]?.debt_to_equity_lt, 'debt_to_equity_lt')}` : 'N/A' }}</span>
            </div>



            <div class="w-full pl-6 py-4 pr-3">
               <div @click="downloadFullReport()"
                  :class="symbolChartData?.length > 0 && !pdfLoading ? '' : 'pointer-events-none'"
                  title="download report"
                  class="border rounded-[4px] cursor-pointer border-[#F8F9F9]/90 text-center text-[#F8F9F9]/90">
                  Full Report Download
               </div>
            </div>
         </div>
         <div v-else class="h-[90%] w-full  flex items-center justify-center text-white">No Data Found</div>
      </div>

      <div>

      </div>
      <div>

      </div>
   </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import moment from 'moment'
import AreaChart from "../charts/AreaChart.vue";
import GetSymbols from "@/api/charts";
import fivesApi from '@/api/fivesApi';
import { mapGetters } from "vuex";
import StockReportFooter from "./StockReportFooter.vue";
import StockReportHeader from "./StockReportHeader.vue";


export default {
   name: "StockReport",
   components: {
      VueHtml2pdf,
      AreaChart,
      StockReportFooter,
      StockReportHeader
   },

   data() {
      return {
         pdfLoading: false,
         chartHeight: 0,
         dataArray: [],
         companyReportDetail: {},
         headings: ["Financials", "Technicals", "Commentary"],
         //   financialKeys:["mkt_cap","debt_to_equity_st","shares_float","shares_out","cash_per_share","net_profit_5_yr_av","p/e","eps","op_mgn","ebitda","revenue","net_profit_5_yr_av","net_income","mrfy_earnings_before_taxes","gross_margin","quick_ratio","current_ratio","roi","roe","roa","free_cash_flow","revenue_change_1_yr","revenue_change_3_yr","bk_val","div_$","div_yield_1_yr","div_growth_3_yr","div_avg_5_yr","frank_pct_(au)","payout_ratio"],
         financialKeys: ["p/e", "eps", "op_mgn", "ebitda", "revenue", "net_profit", "net_income", "mrfy_earnings_before_taxes", "gross_margin", "quick_ratio", "current_ratio", "roi", "roe", "roa", "free_cash_flow", "revenue_change_1_yr", "revenue_change_3_yr", "bk_val", "div_$", "div_%", "div_growth_3_yr", "div_avg_5_yr", "frank_pct_(au)", "payout_ratio", "cash_per_share", "shares_out", "shares_float", "debt_to_equity_st", "mkt_cap", "shares_float", "interest_coverage"],
         technicalKeys: ["close_>_60d_ma", "close_>_126d_ma", "close_>_252d_ma", "60d_ma_>_200d_ma", "52w_high", "low_since_52_week_high", ">5%_swings_count", "gap_down_count", "biggest_gap_down_$", "biggest_gap_down_%", "cross_60d_ma_count", "highest_to_lowest", "open_=_close", "50_turnover_average", "10_turnover_average", "volume_50d_average", "annual_growth", "half_year_growth", "last_quarter_growth", "20_day_growth"],
         commentoryKeys: ["overview", "net_profit", "net_debt", "biggest_gap_down_%", "revenue_growth_5_yrs", "debt_growth_5_yrs", "assets_to_liabilities_ratio", "debt_to_equity_lt", "div_%"],
         selectedHeader: "Financials",
         titleMapping: {
            'p/e': "P/E ($)",
            eps: "EPS ($)",
            op_mgn: "Operating Margin (%)",
            ebitda: "EBITDA ($)",
            revenue: "Total Revenue ($)",
            net_profit: "Net Profit (%)",
            net_income: "Net Income ($)",
            mrfy_earnings_before_taxes: "Pre-Tax Income ($)",
            gross_margin: "Gross Margin (%)",
            quick_ratio: "Quick Ratio (%)",
            current_ratio: "Current Ratio (%)",
            roi: "ROI (%)",
            roe: "ROE (%)",
            roa: "ROA (%)",
            free_cash_flow: "Free Cash Flow ($)",
            revenue_change_1_yr: "Revenue Change 1 Yr (%)",
            revenue_change_3_yr: "Revenue Change 3 Yr (%)",
            bk_val: "Book Value per Share ($)",
            'div_$': "Dividend ($)",
            'div_%': "Div Yield (%)",
            div_growth_3_yr: "Dividend Growth 3 Yrs (%)",
            div_avg_5_yr: "Dividend Average 5 Yrs ($)",
            'frank_pct_(au)': "Franking (Australia only) (%)",
            payout_ratio: "Payout Ratio (%)",
            cash_per_share: "Cash per Share (%)",
            shares_out: "Shares Outstanding",
            shares_float: "Shares float",
            debt_to_equity_st: "Debt to Equity ST (%)",
            mkt_cap: "Market Cap",
            interest_coverage: "Interest Coverage (%)",
            'close_>_60d_ma': "Close > 60d MA",
            'close_>_126d_ma': "Close > 126d MA",
            'close_>_252d_ma': "Close > 252d MA",
            '60d_ma_>_200d_ma': "60d Ma >200d MA",
            '52w_high': "52 Week High",
            'low_since_52_week_high': "Low Since 52 Week High ($)",
            '>5%_swings_count': ">5% Swings Count",
            'gap_down_count': "Gap Down Count",
            'biggest_gap_down_$': "Biggest Gap Down ($)",
            'biggest_gap_down_%': "Biggest Gap Down (%)",
            'cross_60d_ma_count': "Cross 60d MA Count",
            'highest_to_lowest': "Highest To Lowest (%)",
            'open_=_close': "Open = Close",
            '50_turnover_average': "50 Day Average Turnover",
            '10_turnover_average': "10 Day Average Turnover",
            'volume_50d_average': "50 Day Average Volume",
            'annual_growth': "Annual Price Growth (%)",
            'half_year_growth': "Half Year Price Growth (%)",
            'last_quarter_growth': "Last Quarter Price Growth (%)",
            '20_day_growth': "20 Day Price Growth (%)"


         },
         symbolChartData: [],
         selectedChartSymbol: "",
         reportLoading: false,
         symbolDetail: null,
         listData: {},
         reportDataKeyValue: {
            "financial_performance_overview": "Financial Performance",
            "management_summary": "Management Summary",
            "market_outlook": "Market Outlook",
            "risk_factors": "Risk Factors",
            "financial_measures": "Financial Measures",
            "growth_strategy": "Growth Strategy",
            "legal_proceedings": "Legal Proceedings",
            "industry_outlook": "Industry Outlook"
         }
      }
   },

   methods: {

      getDownloadName() {
         return `Market_Alert_Pro_Company_Report_${this.companyReportDetail?.symbol}_${this.getToday_Date()}`
      },
      getToday_Date() {
         return moment().format('D_MM_YYYY')
      },
      trimText(inputString) {
         if (inputString) {
            if (inputString?.length > 34) {
               return inputString?.slice(0, 34) + '..';
            } else {
               return inputString;
            }
         }
      },
      onProgress(e) {
         if (e < 100) {
            this.pdfLoading = true
         } else {
            this.pdfLoading = false
         }
      },

      //       formatNumber(val) {
      //       const num = typeof val === 'string' ? parseFloat(val?.replace(/,/g, '')) : val;

      //   if (num >= 1e12) {
      //     return (num / 1e12).toFixed(1) + 'T';
      //   } else if (num >= 1e9) {
      //     return (num / 1e9).toFixed(1) + 'B';
      //   } else if (num >= 1e6) {
      //     return (num / 1e6).toFixed(1) + 'M';
      //   } else if (num >= 1e3) {
      //     return (num / 1e3).toFixed(1) + 'K';
      //   } else {
      //     return num?.toString();
      //   }
      // },

      // formatNumber(val) {
      //   const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;

      //   if (isNaN(num)) {
      //     return val; // Return the original value if it's not a valid number
      //   }

      //   const isNegative = num < 0;
      //   const absoluteValue = Math.abs(num);

      //   if (absoluteValue >= 1e12) {
      //     return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(2) + 'T';
      //   } else if (absoluteValue >= 1e9) {
      //     return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(2) + 'B';
      //   } else if (absoluteValue >= 1e6) {
      //     return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(2) + 'M';
      //   } else if (absoluteValue >= 1e3) {
      //     return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(2) + 'K';
      //   } else {
      //     return (isNegative ? '-' : '') + absoluteValue.toFixed(2);
      //   }
      // },


      NoDecimalFormatNumber(val) {
         const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
         if (isNaN(num)) {
            return val; // Return the original value if it's not a valid number
         }
         const isNegative = num < 0;
         const absoluteValue = Math.abs(num);
         if (absoluteValue >= 1e12) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(0) + 'T';
         } else if (absoluteValue >= 1e9) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(0) + 'B';
         } else if (absoluteValue >= 1e6) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(0) + 'M';
         } else if (absoluteValue >= 1e3) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(0) + 'K';
         } else {
            if (isNegative) {
               return '-' + absoluteValue?.toFixed(0)
            } else {
               return absoluteValue.toFixed(0)
            }
         }
      },

      formatNumber(val, col = null) {
         const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
         let alwaysTwo = ['volume_50d_average', '50_turnover_average', '10_turnover_average', '20_day_growth', 'div_%', 'highest_to_lowest', 'revenue', 'op_mgn', 'ebitda', 'roe', 'roi', 'shares_out', 'shares_float', 'mkt_cap', 'frank_pct_(au)', 'net_income', 'gross_margin', 'quick_ratio', 'free_cash_flow', 'revenue_change_1_yr', 'revenue_change_3_yr', 'div_growth_3_yr', 'div_avg_5_yr', 'payout_ratio', 'cash_per_share', 'interest_coverage', 'debt_to_equity_st', 'current_ratio', 'roa', 'annual_growth', 'half_year_growth', 'last_quarter_growth', 'revenue_growth_5_yrs', 'debt_growth_5_yrs', 'assets_to_liabilities_ratio', 'debt_to_equity_lt', 'biggest_gap_down_%', 'div_yield_1_yr', 'net_profit', 'net_debt', 'debt_growth_5_yrs', 'free_cash_flow']
         let alwaysThree = []
         let noDecimal = ['open_=_close', '>5%_swings_count', 'cross_60d_ma_count', 'close_>_60d_ma', 'close_>_126d_ma', 'close_>_252d_ma', '60d_ma_>_200d_ma', 'gap_down_count']

         if (isNaN(num)) {
            return val; // Return the original value if it's not a valid number
         }

         const isNegative = num < 0;
         const absoluteValue = Math.abs(num);


         let fixedValue;
         if (alwaysTwo?.includes(col)) {
            fixedValue = 2
         } else if (alwaysThree?.includes(col)) {
            fixedValue = 3
         } else if (noDecimal?.includes(col)) {
            fixedValue = 0
         }
         else if (absoluteValue < 1) {
            fixedValue = 3;
         } else {
            fixedValue = 2;
         }


         if (absoluteValue >= 1e12) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(fixedValue) + 'T';
         } else if (absoluteValue >= 1e9) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(fixedValue) + 'B';
         } else if (absoluteValue >= 1e6) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(fixedValue) + 'M';
         } else if (absoluteValue >= 1e3) {
            if (col == 'free_cash_flow') {
               if (absoluteValue >= 10000) {
                  return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
               } else {
                  return (isNegative ? '-' : '') + absoluteValue?.toLocaleString();
               }
            } else {
               return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
            }
         } else {
            if (isNegative) {
               if (alwaysTwo?.includes(col)) {
                  return '-' + absoluteValue?.toFixed(2)
               } else if (alwaysThree?.includes(col)) {
                  return '-' + absoluteValue?.toFixed(3)
               } else if (noDecimal?.includes(col)) {
                  return '-' + absoluteValue?.toFixed(0)
               }
               else {
                  return '-' + absoluteValue?.toFixed(3)
               }
            } else {
               return absoluteValue.toFixed(fixedValue)
            }
         }


      },

      onValueEmitted(val) {
         console.log("val", val)
      },
      handleReport(head) {
         this.selectedHeader = head
      },
      downloadFullReport() {
         this.pdfLoading = true
         this.$refs.html2Pdf.generatePdf();
      },

      getTodayDate() {
         return moment().format('DD/MM/YYYY')
      },
      getTitleByKey(key) {
         return this.titleMapping[key] || key;
      },
      async symbolSelected(value) {
         console.log("selected==>", value);
         this.chartHeight = 0
         this.chartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
         console.log("height==", this.chartHeight, this.$refs.areaChartHeightDiv.clientHeight)
         this.symbolDetail = value
         this.symbolChartData = []
         this.selectedChartSymbol = value.name
         let symbolDataList = await GetSymbols.getSymbol(
            value.name,
            "D",
            value.id,
            5400
         );
         symbolDataList = symbolDataList?.data ? symbolDataList?.data : [];
         this.symbolChartData = symbolDataList;
         console.log("data report", this.symbolChartData)

      },
      async getCompanyReport(sym) {
         this.reportLoading = true
         this.selectedChartSymbol = sym
         let res = await fivesApi.getCompanyReportStockAlert(sym);
         this.dataArray = []
         if (res?.data?.payload !== null && Object?.keys(res?.data?.payload)?.length > 0) {
            const updatedObj = { Financials: [], Technicals: [], Commentary: {} };
            let result = res?.data?.payload

            this.companyReportDetail = result;
            this.companyReportDetail['overview'] = this.companyReportDetail?.overview.replace(/&amp;/g, '&')
            const filteredData = {};
            Object.keys(this.reportDataKeyValue).forEach(key => {
               if (key in this.companyReportDetail && this.companyReportDetail[key] !== "") {
                  filteredData[key] = this.companyReportDetail[key].replace(/&amp;/g, '&');
                  // console.log('hardik key: ',filteredData[key]);
               }
            });

            Object.keys(filteredData).map((key) => {
               this.dataArray.push({
                  "key": this.reportDataKeyValue[key],
                  "value": filteredData[key]
               })
            })


            for (const key in result) {
               if (this.financialKeys?.includes(key)) {
                  updatedObj.Financials.push({ head: key, value: result[key] });
               }
               if (this.technicalKeys?.includes(key)) {
                  updatedObj.Technicals.push({ head: key, value: result[key] });
               }
               if (this.commentoryKeys?.includes(key)) {
                  updatedObj.Commentary[key] = result[key]
               }
            }

            // Sorting Financials based on financialKeys order
            updatedObj.Financials.sort((a, b) => {
               const indexA = this.financialKeys?.indexOf(a?.head);
               const indexB = this.financialKeys?.indexOf(b?.head);
               return indexA - indexB;
            });

            // Sorting Technicals based on technicalKeys order
            updatedObj.Technicals.sort((a, b) => {
               const indexA = this.technicalKeys?.indexOf(a?.head);
               const indexB = this.technicalKeys?.indexOf(b?.head);
               return indexA - indexB;
            });

            this.listData = updatedObj
            console.log("list data==>", this.listData)

         } else {
            this.listData = {}
         }
         this.reportLoading = false
         console.log("loading normal", this.reportLoading)


      },
   },
   computed: {
      ...mapGetters(["stockAlert_selected_symbol", "stockAlert_selected_symbol_detail"]),

   },
   watch: {
      stockAlert_selected_symbol(val) {
         this.getCompanyReport(val)
         this.symbolSelected(this.stockAlert_selected_symbol_detail)
      }
   },

   async mounted() {
      await this.getCompanyReport(this.stockAlert_selected_symbol)
      await this.symbolSelected(this.stockAlert_selected_symbol_detail)

   },

}
</script>

<style>
.reportHeader {
   margin-right: 5px;
}

.page-break {
   page-break-before: always;
}

.page-break-after {
   page-break-after: always;
}

@media only screen and (max-width: 1550px) {

   .reportData {
      padding: 0 6px 0 16px !important;
   }

   .reportHeader {
      margin-right: 0px;
   }
}

.page {
   position: relative;
   /* min-height: 8in;
   max-height: 8in; */
   margin-top: 10px;
   min-height: 11.2in;
   max-height: 11.2in;
   /* page-break-after: always; Ensures each .page starts on a new page */
}
</style>