<template>
    <tailwind-modal :showModal="true" :showHeader="false">
        <div class="min-w-[38rem] p-6" style="
              border: 0.3px solid #758278;
              box-sizing: border-box;
              border-radius: 3px;
              background-color: #151515;
            ">
    
           
            <div>
                <div class="mx-2 py-0">
                    <div class="flex items-start justify-start flex-col">
                        <p class="text-[18px]">Are you sure?</p>
                        <p class="text-[14px] text-red-600">Data stream can only be changed once every 90 days. Please choose carefully.</p>
                    </div>
                    <div class="flex justify-end">
                        <button class="cancel-button rounded mt-6 mr-4 font-semibold text-sm w-28" @click="closeMessageModal">
                            Cancel
                        </button>
    
                        <button class="ok-button px-8 mt-6 font-semibold py-2 text-sm w-28" @click="changeDataStream">
                            Ok
                        </button>
                    </div>
                </div>
    
            </div>
    
        </div>
    </tailwind-modal>
    </template>
    
    <script>
    import TailwindModal from "../popups/TailwindModal.vue";
    
    
    export default {
        name:"DataStreamModal",
        data() {
            return {
                
            };
        },
        components: {
            TailwindModal,
           
        },
        methods: {
    
            closeMessageModal() {
                console.log("hello from message modal")
                this.$emit("closeDataStreamModal");
            },
            changeDataStream(){
                this.$emit("changeDataStream")
            },
            
           
    
        }
    }
    </script>
    
    <style scoped>
    .cancel-button {
        border: 0.6px solid #f8f9f9;
        border-radius: 3px;
        color: #f8f9f9;
        text-transform: uppercase;
    }
    
    .cancel-button:hover {
        background: linear-gradient(270deg,
                rgba(248, 249, 249, 0.21) 0%,
                rgba(248, 249, 249, 0) 52.08%,
                rgba(248, 249, 249, 0.21) 100%);
    }
    
    .ok-button {
        border: 0.6px solid #02bd2d;
        border-radius: 3px;
        color: #02bd2d;
        text-transform: uppercase;
    }
    
    .ok-button:hover {
        background: linear-gradient(270deg,
                rgba(2, 189, 45, 0.24) 0%,
                rgba(2, 189, 45, 0) 50%,
                rgba(2, 189, 45, 0.24) 100%);
    }
    
    .InputContainer .InputElement {
        position: absolute;
        top: 0;
        color: white !important;
    }
    
    div.stripeCard {
        color: #f3f3f3 !important;
    }
    </style>
    