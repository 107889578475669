<template>
    <tailwind-modal :showModal="true" :showHeader="false">
        <div class="min-w-[30rem] px-3 px-10 py-4" style="
          border: 0.3px solid rgba(2, 189, 45, 0.3);
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #151515;
        ">
            <div class="flex">
                <div class="w-full  rounded-md py-2 px-6">
                    <div style="font-weight: 600; font-size: 17px" class="flex pb-4 text-white">
                        Billing Address
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">Address1:
                                *</span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <input type="text" v-model="userInfo.billing.line1" placeholder="Address1"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px]">
                                <span v-if="addressError.line1.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                                    addressError.line1.message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">Address2: </span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <input type="text" v-model="userInfo.billing.line2" placeholder="Address2"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px]">
                                <!-- <span v-if="addressError.line2.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                        addressError.line2.message }}</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">City:
                                *</span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <input type="text" v-model="userInfo.billing.city" placeholder="City"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px]">
                                <span v-if="addressError.city.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                        addressError.city.message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">Country:
                                *</span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <select v-model="userInfo.billing.country"  @change="onCountryChange"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-0.5 px-4 hover:bg-[#2E3238]">
                                    <option disabled value="">Please select a Country</option>
                                    <option v-for="(countryName, countryCode) in countries" :key="countryCode"
                                        :value="countryCode">{{ countryName }}</option>
                                </select>
                                <span v-if="addressError.country.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                                    addressError.country.message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">State:
                                *</span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <select v-model="userInfo.billing.state"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-0.5 px-4 hover:bg-[#2E3238]">
                                    <option disabled value="">Please select a State</option>
                                    <option v-for="(stateName, stateCode) in states" :key="stateCode"
                                        :value="stateCode">{{ stateName }}</option>
                                </select>
                                <span v-if="addressError.state.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                                addressError.state.message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full space-x-4 justify-between items-center h-auto flex">
                        <div class="w-3/12 h-[27px]">
                            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">Postal Code:
                                *</span>
                        </div>
                        <div class="w-8/12 my-2">
                            <div class="flex flex-col">
                                <input type="number" v-model="userInfo.billing.postal_code" placeholder="123456"
                                    class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[25px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px]">
                                <span v-if="addressError.postal_code.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                        addressError.postal_code.message }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- </div> -->
            <!-- <div
          style="font-weight: 600; font-size: 17px"
          class="flex pb-4 text-white"
        >
          Enter Card Detail(s)
        </div> -->

            <!-- <div role="status" class="flex justify-center items-center vld-parent p-2 w-full vld-parent">
          <AreaChartLoader :showChartLoader="loaderState" />
        </div> -->

            <!-- <div class="pb-10" v-if="!loaderState">
          <AlertShort v-if="displayAlert" :alertType="alertType">
            <p style="font-weight: 600; font-size: 14px" class="text-sm">
              {{ alertMessage }}
            </p>
          </AlertShort>
        </div> -->

            <div class="mx-2 py-0">
                <!-- <div v-if="loaderState"></div>
  
          <div class="flex pb-10">
            <div
              class="heloworld bg-transparent block w-full text-white p-4 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
            >
              <div class="text-white text-gray-200" ref="cardContainer"></div>
            </div>
          </div> -->

                <div class="flex justify-end pb-4">
                    <button class="cancel-button rounded mt-6 mr-4 font-semibold text-sm w-28"
                        @click="closeAddressModal()">
                        Cancel
                    </button>

                    <button @click="saveAddress" :disabled="buttonDsiabled"
                        class="ok-button px-8 mt-6 font-semibold py-2 text-sm w-28"
                        :class="loaderState ? 'pointer-events-none' : ''">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </tailwind-modal>
</template>
<script>
// import { loadStripe } from "@stripe/stripe-js";
import TailwindModal from "../popups/TailwindModal.vue";
// import axios from "axios";
// import { STRIPE_ADD_CARD } from "@/api/apiRoutes";
import AlertShort from "../utilities/AlertShort.vue";
import AreaChartLoader from "../loader/AreaChartLoader.vue"
// import config from "@/api/config";
import { State } from "country-state-city";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loaderState: false,
            cardToken: null,
            messages: [],
            alertType: "Information",
            userInfo: {
                billing: {
                    line1: "",
                    line2: "",
                    postal_code: "",
                    country: '',
                    state: '',
                    city: '',
                }
            },
            addressError: {
                line1: { isError: false, message: '' },
                // line2: { isError: false, message: '' },
                city: { isError: false, message: '' },
                country: { isError: false, message: '' },
                state: { isError: false, message: '' },
                postal_code: { isError: false, message: '' },
            },
            countries: {
                AU: "Australia",
                CA: "Canada",
                GB: "United Kingdom",
                US: "United States",
                FR: "France",
                NZ: "New Zealand",
                ZA: "Africa",
                SG: "Singapore"
            },        // alertMessage: "Enter a Card to save.",
            states: null,        // alertMessage: "Enter a Card to save.",
            // InfoMessage: "Enter a Card to save.",
            success: null,
            displayAlert: false,
            cardElem: null,
            buttonDsiabled: false,
        };
    },
    components: {
        TailwindModal,
        AlertShort,
        AreaChartLoader
    },
    methods: {
        async saveAddress() {
            let hasError = false
            if (!this.userInfo.billing.line1 || this.userInfo.billing.line1.match(/^\s+$/) !== null) {

                if (!this.userInfo.billing.line1.indexOf(' ') >= 0) {
                    hasError = true
                    this.addressError.line1.isError = true
                    this.addressError.line1.message = "Blank Spaces are not allowed"

                }
                else {

                    hasError = true
                    this.addressError.line1.isError = true
                    this.addressError.line1.message = "Address1 is required"
                }

            }
            // if (!this.userInfo.billing.line2 || this.userInfo.billing.line2.match(/^\s+$/) !== null) {

            //     if (!this.userInfo.billing.line2.indexOf(' ') >= 0) {
            //         hasError = true
            //         this.addressError.line2.isError = true
            //         this.addressError.line2.message = "Blank Spaces are not allowed"

            //     }
            //     else {

            //         hasError = true
            //         this.addressError.line2.isError = true
            //         this.addressError.line2.message = "Address2 is required"
            //     }

            // }
            if (!this.userInfo.billing.city || this.userInfo.billing.city.match(/^\s+$/) !== null) {

                if (!this.userInfo.billing.city.indexOf(' ') >= 0) {
                    hasError = true
                    this.addressError.city.isError = true
                    this.addressError.city.message = "Blank Spaces are not allowed"

                }
                else {

                    hasError = true
                    this.addressError.city.isError = true
                    this.addressError.city.message = "City is required"
                }

            }
            if (!this.userInfo.billing.country || this.userInfo.billing.country.match(/^\s+$/) !== null) {

                if (!this.userInfo.billing.country.indexOf(' ') >= 0) {
                    hasError = true
                    this.addressError.country.isError = true
                    this.addressError.country.message = "Please select a country"

                }
                else {

                    hasError = true
                    this.addressError.country.isError = true
                    this.addressError.country.message = "Please select a country"
                }

            }
            if (!this.userInfo.billing.state || this.userInfo.billing.state.match(/^\s+$/) !== null) {

                if (!this.userInfo.billing.state.indexOf(' ') >= 0) {
                    hasError = true
                    this.addressError.state.isError = true
                    this.addressError.state.message = "Please select a state"

                }
                else {

                    hasError = true
                    this.addressError.state.isError = true
                    this.addressError.state.message = "Please select a state"
                }

            }
            if (!this.userInfo.billing.postal_code || this.userInfo.billing.postal_code.match(/^\s+$/) !== null) {

                if (!this.userInfo.billing.postal_code.indexOf(' ') >= 0) {
                    hasError = true
                    this.addressError.postal_code.isError = true
                    this.addressError.postal_code.message = "Blank Spaces are not allowed"

                }
                else {

                    hasError = true
                    this.addressError.postal_code.isError = true
                    this.addressError.postal_code.message = "Postal code is required."
                }

            }
            if(!hasError){
                this.$store.commit('ADDRESS_SET', this.userInfo.billing)
                this.closeAddressModal();
            }
        },
        closeAddressModal() {
            
            // if(!hasError){
                // this.$store.commit('ADDRESS_SET', this.userInfo.billing)
                this.$emit("closeAddressModal");
            // }
        },
        onCountryChange(){
            this.userInfo.billing.state = ''
        },

    },
    mounted() {

    },
    created() {
        this.userInfo.billing = { ...this.address_get };
    },
    watch: {
        'userInfo.billing.country'(newVal, oldVal) {
            let stateObj = {}
            State.getStatesOfCountry(newVal).map((x) => {
                stateObj[x.isoCode] = x.name
            })
            this.states = stateObj
            // this.userInfo.billing.state = ''
        },
        'userInfo.billing.line1': function () {
            this.addressError.line1.isError = false
            this.addressError.line1.message = ""
        },
        // 'userInfo.billing.line2': function () {
        //     this.addressError.line2.isError = false
        //     this.addressError.line2.message = ""
        // },
        'userInfo.billing.city': function () {
            this.addressError.city.isError = false
            this.addressError.city.message = ""
        },
        'userInfo.billing.postal_code': function () {
            this.addressError.postal_code.isError = false
            this.addressError.postal_code.message = ""
        },
        // 'userInfo.billing.country': function () {
        //     this.addressError.country.isError = false
        //     this.addressError.country.message = ""
        // },
        address_get:{
            handler: function (val, oldVal) {
                this.userData = val
                this.userInfo.billing.line1 = this.userInfo.billing.line1
                this.userInfo.billing.line2 = this.userInfo.billing.line2
                this.userInfo.billing.city = this.userInfo.billing.city
                this.userInfo.billing.country = this.userInfo.billing.country
                this.userInfo.billing.state = this.userInfo.billing.state
                this.userInfo.billing.postal_code = this.userInfo.billing.postal_code
            }
        }
    },
    computed: {
        ...mapGetters([
            "address_get",
        ]),
    }
};
</script>

<style scoped>
.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}

.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.InputContainer .InputElement {
    position: absolute;
    top: 0;
    color: white !important;
}

div.stripeCard {
    color: #f3f3f3 !important;
}
</style>