<template>
  <tailwind-modal :showModal="true" :showHeader="false">
    <div
      class="min-w-[38rem] "
      style="
        border: 0.3px solid rgba(2, 189, 45, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #151515;
      "
    >
      <div >
        <div class="flex justify-end">
          <span
            class="text-white h-6 w-6 text-xl block outline-none focus:outline-none cursor-pointer"
            @click="closeModal"
          >
            ×
          </span>
        </div>
        <div class="h-[25rem] overflow-y-scroll px-3">
          <div ref="termsofuse">
            <h1 style="color: " class="text-[#02BD2D] text-2xl">
              Terms of Use
            </h1>
            <div class="mt-5">
              <div  v-for="(x,i) in privacys">

                
                              <h3 class="text-[#02BD2D]">
                              {{x.id}}.  {{x.heading}}
                  
                              </h3>
                              <p >{{x.content}}</p>
              </div>
            </div>
          </div>
          <div ref="privacy" class="mt-5">
             <h1 style="color: " class="text-[#02BD2D] text-2xl">Privacy</h1>
            <!--<p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Accusamus, sint tenetur. Saepe enim totam libero aliquam rerum
              laudantium magnam quis reprehenderit repellendus adipisci
              asperiores quisquam quam aspernatur tempore, officia quas
              laboriosam cumque quaerat praesentium dicta, quia, facilis eum
              hic. Eveniet est quo velit veritatis qui illum sequi reiciendis
              quae quos dicta consectetur impedit, excepturi officiis cum nisi
              odit eos dolorum aliquid asperiores atque autem. Sed hic tenetur
              aspernatur ullam ex delectus iste qui, consectetur necessitatibus
              mollitia asperiores autem voluptatum quam neque veniam possimus
              quos deleniti est quis enim! Laboriosam quaerat, alias non officia
              tenetur sed reprehenderit eligendi. Veniam eaque distinctio
              nesciunt illum ut. Harum aliquid dolorum labore quibusdam est a
              possimus non laudantium natus minima. Obcaecati, ut omnis iure eum
              porro minima nostrum beatae quae voluptatum earum nobis laudantium
              sunt nemo similique a ipsam vero cumque dolorem repellendus culpa
              unde. Velit voluptate cumque officiis illum at praesentium. Animi
              quisquam adipisci facere a voluptas, eveniet modi voluptatum
              molestias tenetur, alias totam quos esse repellendus quaerat
              reprehenderit minus quas facilis, quam neque tempora blanditiis
              vitae. Vitae aliquid totam numquam repellendus nemo assumenda iure
              unde, eligendi quasi voluptatum ad, officiis natus labore non rem
              delectus provident? Temporibus ullam facere minima architecto
              alias ea magnam, saepe impedit. Esse est vero nemo nesciunt cumque
              velit numquam corrupti deserunt beatae voluptas vitae repellat
              laboriosam consequuntur et sit quas accusantium excepturi,
              officiis error similique laborum provident cum! Nobis adipisci
              libero, soluta quis, praesentium deserunt assumenda laboriosam aut
              esse, eveniet explicabo blanditiis eaque vel quam. Commodi optio
              inventore recusandae ea autem est minima odit modi, cupiditate
              blanditiis itaque voluptatem incidunt ullam veniam! Laboriosam a
              voluptatum in voluptate reprehenderit animi neque? Modi beatae
              est, veniam nihil natus in quod similique nemo placeat. Molestiae
              doloremque est voluptate porro. Vel aliquid suscipit doloribus
              perferendis ullam, eum voluptatibus ipsam eveniet aut porro.
            </p> -->
            <div class="">
              <div  v-for="(x,i) in privacypolicys">

                
                              <h3 class="text-[#02BD2D]">
                              {{x.id}}.  {{x.heading}}
                  
                              </h3>
                              <p >{{x.content}}</p>
              </div>
            </div>
          </div>

          <div ref="cookiespolicy">
            <h1 style="color: " class="text-[#02BD2D] text-2xl">
              Cookies Policy
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Accusamus, sint tenetur. Saepe enim totam libero aliquam rerum
              laudantium magnam quis reprehenderit repellendus adipisci
              asperiores quisquam quam aspernatur tempore, officia quas
              laboriosam cumque quaerat praesentium dicta, quia, facilis eum
              hic. Eveniet est quo velit veritatis qui illum sequi reiciendis
              quae quos dicta consectetur impedit, excepturi officiis cum nisi
              odit eos dolorum aliquid asperiores atque autem. Sed hic tenetur
              aspernatur ullam ex delectus iste qui, consectetur necessitatibus
              mollitia asperiores autem voluptatum quam neque veniam possimus
              quos deleniti est quis enim! Laboriosam quaerat, alias non officia
              tenetur sed reprehenderit eligendi. Veniam eaque distinctio
              nesciunt illum ut. Harum aliquid dolorum labore quibusdam est a
              possimus non laudantium natus minima. Obcaecati, ut omnis iure eum
              porro minima nostrum beatae quae voluptatum earum nobis laudantium
              sunt nemo similique a ipsam vero cumque dolorem repellendus culpa
              unde. Velit voluptate cumque officiis illum at praesentium. Animi
              quisquam adipisci facere a voluptas, eveniet modi voluptatum
              molestias tenetur, alias totam quos esse repellendus quaerat
              reprehenderit minus quas facilis, quam neque tempora blanditiis
              vitae. Vitae aliquid totam numquam repellendus nemo assumenda iure
              unde, eligendi quasi voluptatum ad, officiis natus labore non rem
              delectus provident? Temporibus ullam facere minima architecto
              alias ea magnam, saepe impedit. Esse est vero nemo nesciunt cumque
              velit numquam corrupti deserunt beatae voluptas vitae repellat
              laboriosam consequuntur et sit quas accusantium excepturi,
              officiis error similique laborum provident cum! Nobis adipisci
              libero, soluta quis, praesentium deserunt assumenda laboriosam aut
              esse, eveniet explicabo blanditiis eaque vel quam. Commodi optio
              inventore recusandae ea autem est minima odit modi, cupiditate
              blanditiis itaque voluptatem incidunt ullam veniam! Laboriosam a
              voluptatum in voluptate reprehenderit animi neque? Modi beatae
              est, veniam nihil natus in quod similique nemo placeat. Molestiae
              doloremque est voluptate porro. Vel aliquid suscipit doloribus
              perferendis ullam, eum voluptatibus ipsam eveniet aut porro.
            </p>
          </div>
         
        </div>
      </div>
    </div>
  </tailwind-modal>
</template>
<script>
// import { log } from "console";
import TailwindModal from "../popups/TailwindModal.vue";
// import AlertShort from "../utilities/AlertShort.vue";
import {termsandcondition} from "../../utils/termsandcondition"
import {privacypolicy} from "../../utils/privacypolicy"


export default {
  data() {
    return {
      loaderState: false,
      cardToken: null,
      messages: [],
      alertType: "Information",
      alertMessage: "Enter a Card to save.",
      InfoMessage: "Enter a Card to save.",
      success: null,
      displayAlert: true,
      cardElem: null,
      buttonDsiabled: false,
      receivedProps: this.privacyProp,
      privacys:termsandcondition,
      privacypolicys:privacypolicy
    };
  },
  props:
    // privacyProp:String,
    ["privacyProp"],
  components: {
    TailwindModal,
    // AlertShort,
  },
  methods: {
    closeModal() {
      console.log("hello from card modal");
      this.$emit("closeTermsOfUseModal");
    },
    test() {
      console.log(this.privacyProp);
    },
  },
  mounted() {
    // console.log(this.privacyProp === 'cooki');
    if (this.privacyProp === "cookiespolicy") {
      const scrollTo = this.$refs.cookiespolicy;
      scrollTo.scrollIntoView();
    }
    if (this.privacyProp === "termsofuse") {
      const scrollTo = this.$refs.termsofuse;
      scrollTo.scrollIntoView();
    }
    if (this.privacyProp === "privacy") {
      const scrollTo = this.$refs.privacy;
      scrollTo.scrollIntoView();
    }
    // console.log(termsandcondition)
  },
};
</script>

<style scoped>
.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}
.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}
.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}
.InputContainer .InputElement {
  position: absolute;
  top: 0;
  color: white !important;
}
div.stripeCard {
  color: #f3f3f3 !important;
}
</style>
