<template>
    <tailwind-modal :showModal="true" :showHeader="false">
      <div class="border-[0.3px] border-solid px-8 border-[#758278] w-[600px] h-[360px] bg-[#151515] justify-between flex flex-col rounded-md">
    
      <div class="w-full flex items-center justify-start h-[80px]">
        <span class="text-[16px] text-[#FFFFFF] Inter-Bold">RECEIPT(S)</span>
      </div>
      
      <div class="h-[300px] overflow-x-hidden overflow-y-auto w-full" v-if="loaderState">
            <!-- <svg
            v-if="loaderState"
                    class="h-6 w-6 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg> -->
                    <SkeletonTableLoader :rows="6" />
        </div>
     
<div class="w-full h-[300px] overflow-y-auto" v-if="!loaderState">

    <table class="w-full text-[11px] border-[0.1px] text-center border-solid border-gray-600">
        <thead class="sticky top-0 border-[0.1px] border-gray-600">
            <tr class="bg-[#1E2224] border-[0.1px] border-gray-600">
                <th scope="col" class="p-2 text-gray-400 border-[0.1px] border-gray-600">
                    Created
                </th>
                <th scope="col" class="p-2 border-[0.1px] text-gray-400 border-gray-600">
                    Amount
                </th>
                <th scope="col" class="p-2 border-[0.1px] border-gray-600 text-gray-400">
                    Invoice #
                </th>
                <th scope="col" class="p-2 border-[0.1px] border-gray-600 text-gray-400">
                    Status
                </th>
                <th scope="col" class="p-2 border-[0.1px] border-gray-600">
                    
                </th>
            </tr>
        </thead>
      
        <tbody>
            <tr v-for="(item,i) of invoicesData" :key="i" class="text-center hover:bg-[#1E2224] bg-opacity-[0.45] border-[0.1px] border-[white] bg-[#151515]">
                <td class="p-2 border-[0.1px] border-gray-600">
                    {{getData(item.created)}}
                </td>
                <td class="p-2 boder border-[0.1px] border-gray-600">
                  <div class="flex items-center justify-center">
                    {{item.currency.toUpperCase()}}
                    {{(item.amount_due/100).toFixed(2)}}
                  </div>
                </td>
                <td class="p-2 boder border-[0.1px] border-gray-600">
                    {{item.number}}
                </td>
                <td class="p-2 boder border-[0.1px] border-gray-600">
                    <span :class="item.status=='paid' ? 'text-[#02BD2D]' : 'text-[#FED500]'">{{item.status.toUpperCase()}}</span>
                </td>
                <td class="p-2 boder border-[0.1px] border-gray-600">
                  <a :href="item.hosted_invoice_url" target="_blank" class="text-[#02BD2D] justify-center btnBg w-full px-4 flex items-center py-1 text-[10px] rounded-md border-[0.3px] border-solid border-[#02BD2D]">
                    <svg class="w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#02BD2D"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>
                    VIEW
                  </a>
                </td>

            </tr>
        
            
        </tbody>
    </table>

      
</div>
      
     
     
     
     
      <div class="w-full flex items-center space-x-4 justify-end h-[80px]">
        <button
              class="cancel-button w-[120px] flex items-center justify-center py-4 px-6 h-[30px] border-[0.6px] rounded-md border-solid border-[#02BD2D]"
              @click="closeMessageModal"
            >
              Close
            </button>
  
            <!-- <button
              class="ok-button w-[120px] h-[30px] flex items-center justify-center py-4 px-6 border-[0.6px] rounded-md border-solid border-[#02BD2D]"
              :class="loaderState?'pointer-events-none':''"
            >
              Save
            </button> -->
      </div>

      </div>
    </tailwind-modal>
  </template>
  <script>
  import TailwindModal from "../popups/TailwindModal.vue";
  import SkeletonTableLoader from "../loader/SkeletonTableLoader.vue"
  import {
    STRIPE_INVOICES
  } from "@/api/apiRoutes";
  import axios from "axios";
  import moment from 'moment'

  export default {
 
    data() {
      return {
        loaderState: false,
        displayAlert:false,
        showMessage:false,
        message: "",
        invoicesData:[]
      };
    },
    components: {
      TailwindModal,
      SkeletonTableLoader
    },
    methods: {
    
        closeMessageModal() {
        console.log("hello from receipt modal")
        this.$emit("closeReceiptModal");
      },

      async getStripeInvoices(){
        this.loaderState = true
        try{
          let data = await axios.get(STRIPE_INVOICES);
          this.loaderState = false
          this.invoicesData = data.data.payload.data     
          console.log(this.invoicesData)  
          
        }catch(err){
          this.loaderState = false
          console.log(err)
        }
      },

      getData(createdDate){
        return  moment(createdDate*1000).format("DD/MM/YYYY hh:mm A")
      }
     


    
  },


  async mounted() {
    await this.getStripeInvoices();    
    
  },
}
  </script>
  
  <style scoped>

.btnBg:hover{
  background: linear-gradient(270deg, rgba(2, 189, 45, 0.24) 0%, rgba(2, 189, 45, 0) 50%, rgba(2, 189, 45, 0.24) 100%);
}
  .cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
  }
  .cancel-button:hover {
    background: linear-gradient(
      270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%
    );
  }
  
  .ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
  }
  .ok-button:hover {
    background: linear-gradient(
      270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%
    );
  }
  .InputContainer .InputElement {
    position: absolute;
    top: 0;
    color: white !important;
  }
  div.stripeCard {
    color: #f3f3f3 !important;
  }
  </style>
  