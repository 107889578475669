<template>
  <div class="flex ml-[9px] min-w-full h-full">
    <div class="basis-1/6  flex flex-col min-h-full">
      <MarketAlertStatus />
    </div>


    <div class="basis-5/6 ml-[9px] mr-[18px] flex flex-col min-h-full">
      <div class="basis-1/2 flex  justify-between">
        <div style="border: 0.3px solid rgba(2, 189, 45, 0.3);border-radius:6px;"
          class="h-full mr-[9px] box-border flex flex-col w-full">
          <div style=""
            class="font-inter text-gray-100 flex justify-center not-italic font-semibold text-lg leading-7">
            <h1 style="background: rgb(21, 21, 21); font-size: 1vw; padding-inline: 5px;">
              MBA Daily Situation
            </h1>
          </div>

          <div class="flex justify-evenly">
            <gauges-chart :id="2" :curves="M_curves" :currentValue="momentumValue" :strokeWidth="5"
              :radius="smallRightGaugeHeight / 2.975" :shadowRadius="smallRightGaugeHeight / 3.7"
              :shadowWidth="smallRightGaugeHeight / 8.5">
              <template v-slot:label>
                <!-- <span class="label"> {{ currentvaluessd[0] }}% </span> -->
                <span :class="handleStringFomat(clickedRowStore.alerts) === 'market_alert'
                    ? 'text-[#FF0A00]'
                    : ''
                  " class="label Inter-Bold" style="font-size: 1vw" v-if="momentumValue">
                  <p :class="handleStringFomat(clickedRowStore.alerts) ===
                      'market_alert'
                      ? 'text-[#FF0A00]'
                      : ''
                    ">
                    {{ momentumValue }}%
                  </p>
                </span>
                <span class="label Inter-Bold" style="font-size: 0.7vw" v-else>N/A</span>
              </template>
              <template v-slot:label-bottom>
                <span class="label-bottom -mt-8" style="font-size: 0.6vw">
                  Momentum (M)
                </span>
              </template>
            </gauges-chart>

            <gauges-chart :id="3" :curves="B_curves" :currentValue="brakoutValue" :strokeWidth="5"
              :radius="smallRightGaugeHeight / 2.975" :shadowRadius="smallRightGaugeHeight / 3.7"
              :shadowWidth="smallRightGaugeHeight / 8.5">
              <template v-slot:label>
                <span :class="handleStringFomat(clickedRowStore.alerts) === 'market_alert'
                    ? 'text-[#FF0A00]'
                    : ''
                  " class="label Inter-Bold" style="font-size: 1vw" v-if="brakoutValue">
                  <p :class="handleStringFomat(clickedRowStore.alerts) ===
                      'market_alert'
                      ? 'text-[#FF0A00]'
                      : ''
                    ">
                    {{ brakoutValue }}%
                  </p>
                </span>
                <span class="label Inter-Bold" style="font-size: 0.7vw" v-else>N/A</span>
              </template>
              <template v-slot:label-bottom>
                <span class="label-bottom -mt-8" style="font-size: 0.6vw">
                  Breakout (B)
                </span>
              </template>
            </gauges-chart>

            <gauges-chart :id="4" :curves="A_curves" :currentValue="advanceValue" :strokeWidth="5"
              :radius="smallRightGaugeHeight / 2.975" :shadowRadius="smallRightGaugeHeight / 3.7"
              :shadowWidth="smallRightGaugeHeight / 8.5">
              <template v-slot:label>
                <span class="label Inter-Bold" style="font-size: 1vw" v-if="advanceValue">
                  <p :class="handleStringFomat(clickedRowStore.alerts) ===
                      'market_alert'
                      ? 'text-[#FF0A00]'
                      : ''
                    ">
                    {{ advanceValue }}%
                  </p>
                </span>
                <span class="label Inter-Bold" style="font-size: 0.7vw" v-else>N/A</span>
              </template>
              <template v-slot:label-bottom>
                <span class="label-bottom -mt-8" style="font-size: 0.6vw">
                  Advance (A)
                </span>
              </template>
            </gauges-chart>
          </div>
        </div>

        <div style="border: 0.3px solid rgba(2, 189, 45, 0.3);border-radius:6px;" class=" h-full ">
          <div class=" w-[7.4vw] h-[18.5vh] box-border rounded-sm" ref="smallRigthGauge_Ref">
            <div class="font-inter text-gray-100 flex justify-center not-italic font-semibold text-lg leading-7 ">
              <h1 style="background: rgb(21, 21, 21); font-size: 1vw;padding-inline: 5px;">
                Volatility
              </h1>
            </div>
            <div class="flex justify-center items-center">
              <gauges-chart :id="5" :curves="VIX_curves" :currentValue="vixGauge" :strokeWidth="5"
                :radius="smallRightGaugeHeight / 2.975" :shadowRadius="smallRightGaugeHeight / 3.7"
                :shadowWidth="smallRightGaugeHeight / 8.5">
                <template v-slot:label>
                  <span class="label Inter-Bold" style="font-size: 1vw" v-if="vixGauge != undefined">{{ vixGauge }}
                  </span>
                  <span class="label Inter-Bold" style="font-size: 0.7vw" v-else>N/A</span>
                </template>
                <template v-slot:label-bottom>
                  <span class="label-bottom -mt-8" style="font-size: 0.6vw">
                    VIX
                  </span>
                </template>
              </gauges-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="basis-1/2 flex flex-col mt-[9px]">
        <BarChartUpd />
      </div>
    </div>
  </div>
</template>

<script>
import GuageChart from "../guage/GaugeChart";
// import BarChart from "../charts/BarChart.vue";
import MarketAlertStatus from "../charts/MArketAlertStatus.vue";
import { mapGetters } from "vuex";
import BarChartUpd from "@/components/charts/BarChartUpd";

export default {
  name: "GuagesDial",
  components: {
    BarChartUpd,
    "gauges-chart": GuageChart,
    // BarChart,
    MarketAlertStatus,
  },
  props: ["cvalue"],
  computed: {
    ...mapGetters(["clickedRowStore", "getActiveCountry"]),
    M_curves() {
      let yello_percent = this.mba_curve_percentages.momentum.yellow;
      let green_percent =
        this.mba_curve_percentages.momentum.green[this.getActiveCountry];
      // let yello_percent = 0.75;
      // let green_percent = this.getActiveCountry == "ca" ? 0.375 : this.getActiveCountry == "us" ? 0.393 : 0.393;

      return [
        {
          percent: yello_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.yellow,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500", //yellow
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500",
          y1: "80%",
          y2: "10%",
        },
        {
          percent: green_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.green,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#00C92E", //green
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#009300",
          x1: "80%",
          x2: "100%",
        },
      ];
    },
    B_curves() {
      let yello_percent = this.mba_curve_percentages.breakout.yellow;
      let green_percent =
        this.mba_curve_percentages.breakout.green[this.getActiveCountry];
      // let yello_percent = 0.75;
      // let green_percent = this.getActiveCountry == "ca" ? 0.45 : this.getActiveCountry == "us" ? 0.393 : 0.45;
      return [
        {
          percent: yello_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.yellow,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500", //yellow
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500",
          y1: "80%",
          y2: "10%",
        },
        {
          percent: green_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.green,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#00C92E", //green
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#009300",
          x1: "80%",
          x2: "100%",
        },
      ];
    },
    A_curves() {
      let yello_percent = this.mba_curve_percentages.advance.yellow;
      let green_percent =
        this.mba_curve_percentages.advance.green[this.getActiveCountry];
      // let yello_percent = 0.75;
      // let green_percent = this.getActiveCountry == "ca" ? 0.375 : this.getActiveCountry == "us" ? 0.393 : 0.405;

      return [
        {
          percent: yello_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.yellow,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500", //yellow
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#FED500",
          y1: "80%",
          y2: "10%",
        },
        {
          percent: green_percent,
          gradienColor: this.isMarketAlert()
            ? this.dialColors.red
            : this.dialColors.green,
          // color:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#00C92E", //green
          // color2:
          //   this.clickedRowStore.alerts == "Market Alert"
          //     ? "#FF0A00"
          //     : "#009300",
          x1: "80%",
          x2: "100%",
        },
      ];
    },
    VIX_curves() {
      return [
        {
          percent: 0.75,
          gradienColor: this.dialColors.green,
          x1: "80%",
          x2: "100%",
        },
        {
          percent: 0.61,
          gradienColor: this.dialColors.yellow,
          x1: "50%",
          x2: "100%",
        },
        {
          percent: 0.59,
          gradienColor: this.dialColors.red,
          x1: "50%",
          x2: "100%",
        },
        {
          percent: 0.375,
          gradienColor: this.dialColors.indigo,
          y1: "100%",
          y2: "10%",
        },
      ];
    },
  },
  data() {
    return {
      // str_market_alert: "Market Alert".toString().toUpperCase().replaceAll(" ", "_"),
      dialColors: {
        red: { dark: "#950600", light: "#FF0A00" },
        green: { dark: "#009300", light: "#00C92E" },
        yellow: { dark: "#cfae02", light: "#FED500" },
        indigo: { dark: "#56016B", light: "#A900D2" },
      },

      mba_curve_percentages: {
        advance: {
          yellow: 0.75,
          green: {
            ca: 0.375,
            us: 0.393,
            oz: 0.405,
          },
        },
        momentum: {
          yellow: 0.75,
          green: {
            ca: 0.375,
            us: 0.393,
            oz: 0.393,
          },
        },
        breakout: {
          yellow: 0.75,
          green: {
            ca: 0.45,
            us: 0.393,
            oz: 0.45,
          },
        },
      },
      smallRightGaugeHeight: this.$refs.smallRigthGauge_Ref?.clientHeight,
      currentvaluessd: [],
      momentumValue: "",
      brakoutValue: "",
      advanceValue: "",
      vixGauge: null,
      // M_curves: [
      //   {
      //     percent: 0.75,
      //     color: "#FF0A00",
      //     color2: "#950600",
      //     y1: "80%",
      //     y2: "10%",
      //   },
      //   {
      //     percent: 0.39,
      //     color: "#00C92E",
      //     color2: "#009300",
      //     x1: "80%",
      //     x2: "100%",
      //   },
      // ],
      // B_curves: [
      //   {
      //     percent: 0.75,
      //     color: "#FF0A00",
      //     color2: "#950600",
      //     y1: "80%",
      //     y2: "10%",
      //   },
      //   {
      //     percent: 0.37,
      //     color: "#00C92E",
      //     color2: "#009300",
      //     x1: "80%",
      //     x2: "100%",
      //   },
      // ],
      // A_curves: [
      //   {
      //     percent: 0.75,
      //     color: "#FF0A00",
      //     color2: "#950600",
      //     y1: "80%",
      //     y2: "10%",
      //   },
      //   {
      //     percent: 0.37,
      //     color: "#00C92E",
      //     color2: "#009300",
      //     x1: "80%",
      //     x2: "100%",
      //   },
      // ],
      // __volatility: [
      //   {
      //     percent: 0.75,
      //     color: "#00C92E",
      //     color2: "#009300",
      //     x1: "80%",
      //     x2: "100%",
      //   },
      //   {
      //     percent: 0.61,
      //     color: "#FED500",
      //     color2: "#FFEE95",
      //     x1: "50%",
      //     x2: "100%",
      //   },
      //   {
      //     percent: 0.59,
      //     color: "#FF0A00",
      //     color2: "#950600",
      //     x1: "50%",
      //     x2: "100%",
      //   },
      //   {
      //     percent: 0.375,
      //     color: "#A900D2",
      //     color2: "#56016B",
      //     y1: "100%",
      //     y2: "10%",
      //   },
      // ],
    };
  },
  mounted() {
    this.smallRightGaugeHeight = this.$refs.smallRigthGauge_Ref?.clientHeight;
    window.addEventListener("resize", this.OnResize);
  },
  watch: {
    clickedRowStore() {
      this.momentumGaugeValue(this.clickedRowStore.momentum_m),
        this.breakoutGaugeValue(this.clickedRowStore.breakout_m),
        this.advanceGaugeValue(this.clickedRowStore.advance_m);
      this.vixGaugeValue(this.clickedRowStore);
      // this.vixGaugeValue(this.clickedRowStore.vix);
    },
  },

  methods: {
    handleStringFomat(value) {
      let trimmedVal = value.replaceAll(" ", "_").toLowerCase();
      return trimmedVal;
    },
    isMarketAlert() {
      return (
        this.clickedRowStore.alerts == "Market Alert"
        // .toString()
        // .toUpperCase()
        // .replaceAll(" ", "_") == this.str_market_alert
      ); //"Market Alert"
    },
    OnResize() {
      this.smallRightGaugeHeight = this.$refs.smallRigthGauge_Ref?.clientHeight;
    },
    momentumGaugeValue(value) {
      this.momentumValue = value ? parseInt((value * 100) / 2) : 0;
      // let newarray = this.currentvaluessd
      // newarray[0] = parseInt(this.momentumValue);
      // this.currentvaluessd = newarray
      // console.log("momentum:", this.momentumValue);
    },
    breakoutGaugeValue(value) {
      this.brakoutValue = parseInt((value * 100) / 2);
      // this.currentvaluessd[1] = parseInt(this.breakoutValue);
      // console.log("breakout:", this.breakoutValue);
    },
    advanceGaugeValue(value) {
      this.advanceValue = parseInt((value * 100) / 2);
      // this.currentvaluessd[2] = parseInt(this.advanceValue);
      // console.log("advance:", this.advanceValue);
    },
    vixGaugeValue(value) {
      // let sum = ((value.momentum_m+ value.breakout_m + value.advance_m)-(0.5*value.vix))/3
      // this.vixGauge= sum > 100 ? 100 : sum < 0 ? 0 : parseInt(Math.round(sum))
      this.vixGauge = value.vix;
      // this.vixGauge = parseInt  (sum);
    },
  },
};
</script>

<style scoped>
.no-action {
  font-family: "Inter";
  font-style: normal;
  font-size: 30px;
  /* line-height: 36px; */
  text-align: center;
  color: #f8f9f9;
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f8f9f9;
}

.label-bottom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 10px; */
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #f8f9f9;
  white-space: nowrap;
}
</style>
