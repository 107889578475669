<template>
  <tailwind-modal
    class=""
    :show-modal="modalEnabled"
    v-on:change="toggleModal"
    title="Market Heat"
  >
    <div class="p-2 w-full">
      <!-- delete watchlist modal -->
      <tailwind-modal v-if="deleteWatchlistModal" :show-modal="modalEnabled">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 3vh;
            justify-content: space-between;
            height: 32vh;
            width: 30vw;
            padding: 15px;
            overflow-y:auto
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              flex-grow: 8;
              gap: 1vh;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 1vh">
              <h1 class="text-[#E32E2C] text-[1.1vw] Inter-Bold">
                Remove stock(s) from watchlist(s)
              </h1>
              <p class=".9vw">
                Remove a stock from
                <span class="Inter-Bold"> the following watchlist(s): </span>
              </p>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 2vh;
                height: 100%;
                padding: 15px;
                overflow-y: auto;
              "
            >
              <span
                v-for="(r, i) of RemovedWatchListsShow"
                :key="i"
                class="flex items-center gap-[.5vw]"
              >
                <label class="container">
                  <input
                    :id="'id-' + i"
                    @change="Check($event, i, r)"
                    type="checkbox"
                    class="checkbox-round"
                  />
                  <span class="checkmark"></span>
                </label>
                <label :for="'id-' + i" class="Inter-Bold text-[.8vw]">{{
                  r.name
                }}</label>
              </span>
            </div>
          </div>

          <div class="flex justify-end gap-[1.5vw] items-center flex-grow-[1]">
            <span class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#E32E2C"
                class="w-[1.5vw]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>

              <button
                class="text-[#E32E2C] h-full text-[1vw]"
                @click="RemoveWatchList"
                :disabled="!enableRemove"
              >
                Remove
              </button>
            </span>
            <button
              class="cancel-button h-full text-[1vw] w-[7vw]"
              @click="deleteWatchlistModal = false"
            >
              Cancel
            </button>
            <!-- <button class="ok-button h-full text-[1vw] w-[7vw]" @click="AddWatchlist_2()">
                            Add
                        </button> -->
          </div>
        </div>
      </tailwind-modal>
      <!-- save watchlist modal -->

      <tailwind-modal v-if="saveWatchlistModal" :show-modal="modalEnabled">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 3vh;
            justify-content: space-between;
            height: 30vh;
            width:30vw;
            padding: 17px;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              flex-grow: 8;
              gap: 1vh;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 1vh">
              <h1 class="text-[1vw]">
                Save <span class="Inter-Bold"> {{ clickedTicker }} </span> to
                watchlist
              </h1>
            </div>
            <div
            v-if="!showModalMessage"
              style="
                display: flex;
                flex-direction: column;
                gap: 1vh;
                height: 100%;
                padding: 15px;
              "
            >
              <select
                v-if="watxhlistCreated"
                name=""
                id=""
                class="text-[black] h-[3vh]"
                @change="selectWatchList"
              >
                <option value="">--Select Watchlist--</option>
                <option
                  v-for="(names, i) in watch_List_Api_Data.data.payload"
                  :key="i"
                  :value="i"
                >
                  {{ names.name }}
                </option>
              </select>
              <input
                v-else
                type="text"
                v-model="newWatchName"
                placeholder="Create Watchlist"
                class="rounded h-[4vh] p-[1vw] text-[black]"
              />
              <span class="text-red-600" v-if="!newWatchName || !selectwatchIndex">{{ errorMessage }}</span>
            </div>
            <AreaChartLoader :showChartLoader="showModalLoader" />

              <div
                v-if="showModalMessage && showModalLoader == false && showModalResponse"
                class="w-full flex items-center  text-sm justify-center text-[#02BD2D] h-[100%]"
              >
                {{ showModalResponse }}
              </div>
              <div
                v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]"
              >
                {{ showModalResponseError }}
              </div>
          </div>

          <div class="flex justify-end gap-[1.5vw] items-center flex-grow-[1]">
            <button
              class="cancel-button px-4 py-1 text-[.8vw]"
              @click="closeSaveWatchlistModal"
            >
              Cancel
            </button>
            <button class="ok-button px-4 py-1 text-[.8vw]" @click="saveSearchedStock">
              Save
            </button>
            <button
              v-if="watxhlistCreated"
                    class="ok-button px-4 py-1 text-[.8vw]"
                    @click="CreateFromSelectWatchlist"
                  >
                    Create
                  </button>
          </div>
        </div>
      </tailwind-modal>

      <!-- create watch list  -->
       <!-- createWatclistModal -->
       <tailwind-modal
              :show-modal="createModal"
              v-if="createModal"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 20vh;
                  width: 30vw;
                  padding: 1vw;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    flex-grow: 2;
                    gap: 1vh;
                    height: 70%;
                  "
                >
                  <div style="display: flex; flex-direction: column; gap: 2vh">
                    <h1 class="text-[1vw]">Create watchlist</h1>
                  </div>
                  <div
                    v-if="!showModalMessage"
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 1vh;
                      /* justify-content: center; */
                      /* align-items: center; */
                      height: 100%;
                    "
                  >
                    <input
                      type="text"
                      placeholder="create watchlist"
                      class="px-2 py-1 rounded text-[black] w-11/12 focus:outline-none"
                      v-model="watchlistName"
                      v-on:keyup.enter="SaveCreateWatchlist"
                    />
                  <span class="text-red-600 text-sm" v-if="!watchlistName">{{ errorMessage }}</span>  
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div
                    v-if="showModalMessage && showModalLoader == false && showModalResponse"
                    class="w-full flex items-center  text-sm justify-center text-[#02BD2D] h-[100%]"
                  >
                    {{ showModalResponse }}
                  </div>
                  <div
                    v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                    class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]"
                  >
                    {{ showModalResponseError }}
                  </div>
                </div>

                <div
                  v-if="!showModalMessage"
                  class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[30%]"
                >
                  <button
                    class="cancel-button px-4 py-1 text-[.8vw]"
                    @click="closeWatchlistModal()"
                  >
                    Cancel
                  </button>
                  <button
                    class="ok-button px-4 py-1 text-[.8vw]"
                    @click="SaveCreateWatchlist"
                    
                  >
                    Save
                  </button>
                </div>
              
              </div>
       </tailwind-modal>

      <div
        class="w-[70vw] 2xl:h-[90vh] xl:h-[90vh] lg:h-[70vh] md:h-[50vh] sm:h-[30vh] px-2"
        
      >
        <!-- top header  -->
        <div class="w-full flex space-x-2 items-center mb-2 py-1">
          <div class="font-bold w-full">
            <span
              style="font-family: 'Inter'; font-style: normal"
              class="text-gray-100 text-sm"
            >
              {{ indexUpperTable?indexUpperTable:marketHeatModalTable?.label }}
              <span class="text-sm">|</span></span
            >
            <span
              style="
                width: 96px;
                height: 45px;
                font-family: 'Inter';
                font-style: normal;
                font-size: 12px;
                line-height: 15px;
                color: #f8f9f9;
              "
              class="text-gray-100 text-xs"
            >
              Total Stocks {{count?count: marketHeatModalTable.count }}
              <span class="text-xs"> | </span>
            </span>
            <span
              style="
                width: 96px;
                height: 45px;
                font-family: 'Inter';
                font-style: normal;
                  
                font-size: 12px;
                line-height: 15px;
                color: #f8f9f9;
              "
              class="text-gray-100 text-xs"
              >Date:
              <span class="text-gray-100 text-xs">{{
                formatDate(marketHeatModalTable.date)
              }}</span>
            </span>
          </div>

          <div class="w-5/12 flex items-center justify-between">
            <button
              style="width: 21px; height: 21px"
              class="p-1 ml-auto border-0 bg-[#151515] float-right text-xl leading-none font-semibold outline-none focus:outline-none flex items-center"
              v-on:click="toggleModal()"
            >
              <span
                class="text-white h-6 w-6 text-xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>
        </div>

        <!-- main container owais  -->
        <div
          class="w-full flex flex-col space-y-2"
          :class="isError ? 'h-full' : ''"
        >
          <!-- top div  -->
          <div
            class="w-full space-x-4 items-start justify-between h-full"
            style="display: flex; flex-direction: column"
          >
            <!-- top 2 div  -->

            <div class="w-[100%] h-fit">
              <div
               
                class="border mb-2 border-[#7582783d] rounded-md"
              >
                <table class="w-full">
                  <thead>
                    <tr>
                      <td
                        class="w-[52.53px] h-[27px] flex-col items-start bg-[#1E2224]"
                        :key="i"
                        v-for="(x, i) of getModalColumns"
                        :class="'text-gray-100 text-center ' + x.headerClasses"
                        :rowspan="x.rowspan"
                      >
                        <div class="flex justify-center items-center">
                          {{ x.label }}

                          <svg
                            v-if="i > 0 && i < 6"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-[9px] w-[9px] ml-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="green"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8 7l4-4m0 0l4 4m-4-4v18"
                            />
                          </svg>

                          <svg
                            v-if="i > 5 && i < 11"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-[9px] w-[9px] ml-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="red"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16 17l-4 4m0 0l-4-4m4 4V3"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style="border-radius: 5px"
                        colspan="10"
                        class="bullishBearsih font-semibold text-xs text-gray-100 h-[24px] w-[709.18px]"
                      >
                        <span class="flex justify-between rounded">
                          <span
                            class="font-medium text-[9px] leading-3 h-[24px] w-[81.95px] flex justify-end items-center"
                            >BULLISH MARKET</span
                          >
                          <span
                            class="font-medium text-[9px] leading-3 h-[24px] w-[81.95px] flex justify-start items-center"
                            >BEARISH MARKET</span
                          ></span
                        >
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        :key="i"
                        v-for="(col, i) of getModalColumns"
                        @click.prevent="selectedProp = col.colName"
                        :style="[
                          col.colName === selectedProp
                            ? {
                                'border-bottom': '2px solid green',
                                cursor: 'default',
                              }
                            : {
                                cursor: 'pointer',
                              },
                        ]"
                        :class="
                         [ getWatchClasses(
                            'text-gray-100 text-center p-2 w-[52.53px] h-[27px] flex-col items-start bg-[#1E2224] ',
                            col.colName,
                            marketHeatModalTable
                          ),i == 0 || i == 11 ? 'pointer-events-none':'']
                        "
                      >
                        {{ marketHeatModalTable[col.colName] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <SkeletonTableLoader
                v-if="showTableLoader == true && !isError && !loadingData"
                :rows="6"
              /> -->

              
              
              <!-- after table get the data -->
              <div  v-if="
                  // marketHeatTopFiveTable.length > 0 && showTableLoader == false
                  marketHeatTopFiveTable.length > 0 
                ">
                <!-- table div -->
                 <div
                  class="overflow-x-auto border border-[#7582783d] rounded-md"
                >
                  <table class="w-full">
                    
                    <!-- @click="getSortedData('ticker')" -->
                    <thead>
                      <tr :key="i" v-for="(y, i) of this.tableHeader">
                        <td
                          style="
                            width: 220px;
                            height: 30px;
                            flex: none;
                            order: 0;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            cursor: pointer;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-2/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.stock }}

                            <!-- <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'ticker'"
                            /> -->
                          </div>
                        </td>
                        <!-- @click="getSortedData('close')"  -->
                        <td
                          style="
                            width: 100px;
                            height: 30px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.price }}

                            <!-- <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'close'"
                            /> -->
                          </div>
                        </td>
                        <td
                          @click="getSortedData('trend_rank')"
                          style="
                            width: 100px;
                            height: 30px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.trend_rank }}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'trend_rank'"
                            />
                          </div>
                        </td>
                        <!-- <td
                          @click="getSortedData('twenty_days_percent_change')"
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            order: 2;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-center top_5 w-3/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.per_change_30_days }}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="
                                selectedColumn === 'twenty_days_percent_change'
                              "
                            />
                          </div>
                        </td> -->

                        <td
                          @click="getSortedData('swings_greater_than_three')"
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.swings_greater_than_three }}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'swings_greater_than_three'"
                            />
                          </div>
                        </td>
                        <td
                          @click="getSortedData('twenty_days_percent_change')"
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.twenty_days_percent_change
 }}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'twenty_days_percent_change'"
                            />
                          </div>
                        </td>
                        <td
                          @click="getSortedData('five_days_percent_change')"
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.five_days_percent_change
 }}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'five_days_percent_change'"
                            />
                          </div>
                        </td>
                        <td
                          @click="getSortedData('average_volume_ten_days')"
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                            border: 0.3px solid #7582783d;
                            cursor: pointer;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          <div class="flex justify-center items-center">
                            {{ y.average_volume_ten_days}}

                            <table-sort-icon
                              :order="sortingOrder"
                              v-if="selectedColumn === 'average_volume_ten_days'"
                            />
                          </div>
                        </td>
                        <!-- @click="getSortedData(y.watchlist)" -->
                        <td
                          style="
                            width: 75px;
                            height: 30px;
                            flex: none;
                            order: 5;
                            align-self: stretch;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                           
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          {{ y.watchlist }}
                        </td>
                        <!-- @click="getSortedData(y.chart)" -->
                        <td
                          style="
                            height: 30px;
                            flex: none;
                            order: 6;
                            flex-grow: 0;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 9px;
                            line-height: 15px;
                          "
                          class="text-gray-100 text-xs text-center top_5 w-1/12 pt-1 pb-1 bg-[#1E2224]"
                        >
                          {{ y.chart }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="i"
                        v-for="(y, i) of this.marketHeatTopFiveTable"
                      >
                        <td
                          style="
                            width: 220px;
                            height: 30px;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                            font-size: 9px;
                          "
                          class="text-gray-100 text-center top_5_fields cursor-pointer"
                          :style="[
                            selectedStock == i
                              ? {
                                  'border-bottom': '1.5px solid green',
                                  cursor: 'default',
                                }
                              : {},
                          ]"
                          @click="ModalSelectedStock(i)"
                        >
                          {{ y.ticker }}
                        </td>

                        <td
                          style="
                            width: 100px;
                            height: 30px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            font-size: 9px;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.close }}
                        </td>
                        <td
                          style="
                            width: 100px;
                            height: 30px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            font-size: 9px;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.trend_rank }}
                        </td>

                      
                        <td
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            font-size: 9px;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.swings_greater_than_three }}
                        </td>
                        <td
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            font-size: 9px;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.twenty_days_percent_change}}
                        </td>
                        <td
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            font-size: 9px;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.five_days_percent_change}}
                        </td>
                        <td
                          style="
                            width: 150px;
                            height: 30px;
                            flex: none;
                            font-size: 9px;
                            order: 4;
                            align-self: stretch;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 text-center top_5_fields"
                        >
                          {{ y.average_volume_ten_days?.toLocaleString('en-US')}}
                        </td>

                        <td
                          style="
                            width: 75px;
                            height: 30px;
                            flex: none;
                            font-size: 9px;
                            order: 5;
                            align-self: stretch;
                            flex-grow: 0;
                            border: 0.3px solid #7582783d;
                          "
                          class="text-gray-100 content-center top_5_fields"
                          @click="CheckWatchlistFill(y.isInWatchList, y, i)"
                        >
                          <div
                            class="flex justify-center items-center cursor-pointer"
                          >
                            <svg
                              class="h-4 w-4 text-[#828282] inline-block text-center"
                              viewBox="0 0 24 24"
                              :fill="
                                topFive_StarData.includes(i)
                                  ? '#00C92E'
                                  : 'none'
                              "
                              :stroke="
                                topFive_StarData.includes(i)
                                  ? '#00C92E'
                                  : '#828282'
                              "
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polygon
                                points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                              />
                            </svg>
                          </div>
                        </td>

                        <td
                          style="
                            height: 40px;
                            flex: none;
                            order: 6;
                            flex-grow: 0;
                            border-bottom: 0.3px solid #7582783d;
                          "
                          class="text-green-400 text-xs flex justify-center items-center"
                        >
                          <a
                            :style="'border-radius:5px;margin-inline:2px;background-color:null'"
                            class="px-2"
                            :href="'/chart?t=' + y.ticker"
                          >
                            <svg
                              width="18"
                              height="20"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_571_8639)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.76867 13.7519L5.73489 10.6234L7.5432 12.1031C7.73859 12.2629 8.02254 12.2518 8.20483 12.0771L11.9751 8.4635L15.2449 12.0779C15.4298 12.2823 15.7458 12.2981 15.9501 12.1132C16.1544 11.9284 16.1703 11.6124 15.9854 11.408L12.3708 7.41252C12.2803 7.31251 12.1532 7.25334 12.0184 7.24855C11.8835 7.24371 11.7525 7.29371 11.6551 7.38701L7.83322 11.0502L6.00804 9.55676C5.80556 9.39108 5.50957 9.40983 5.32957 9.59963L2.04409 13.0649C1.85451 13.2648 1.86292 13.5811 2.06284 13.7707C2.26281 13.9603 2.57909 13.9518 2.76867 13.7519V13.7519Z"
                                  fill="#02BD2D"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.67902 6.75151L9.31929 6.38751L10.9887 6.159L10.7406 7.82565L10.3809 7.46165L7.98564 9.82908C7.80186 10.0107 7.50986 10.0221 7.3125 9.85539L5.49434 8.31964L2.50778 11.5715C2.32134 11.7744 2.00527 11.7878 1.80232 11.6014C1.59936 11.415 1.58594 11.099 1.77233 10.896L5.0829 7.29151C5.26396 7.09436 5.56831 7.07508 5.77281 7.24778L7.60822 8.79813L9.67902 6.75151V6.75151Z"
                                  fill="#02BD2D"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M17.9813 4.43245C17.9813 3.97809 17.6124 3.60919 17.158 3.60919H0.82326C0.368894 3.60919 0 3.97809 0 4.43245V15.1063C0 15.5607 0.368894 15.9295 0.82326 15.9295H17.158C17.6124 15.9295 17.9813 15.5607 17.9813 15.1063V4.43245V4.43245ZM0.998521 4.60771V14.931H16.9827V4.60771H0.998521Z"
                                  fill="#02BD2D"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_571_8639">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                    transform="translate(0 0.786682)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                   <!-- v-if="marketHeatTopFiveTable.length > 0" -->
         
         <!-- //bottom div -->
                   <div
            class="w-full flex items-start border-[0.3px] rounded-md border-solid border-[#7582783d] h-[50%] px-1 mt-2"
          >
            <!-- bottom 3 div  -->

            <div class="w-4/12 flex-col h-full vld-parent">
              <AreaChartLoader :showChartLoader="showChartLoader" class="" />

              <div class="flex justify-between p-2">
                <span
                  class="text-[14px] xl:text-[16px] Inter-Bold text-gray-100"
                >
                  {{ stockObj.stockCompanyName }}
                </span>
                <span class="flex justify-center items-center">
                  <svg
                    class="h-3 w-3 text-[#828282] inline-block text-center"
                    viewBox="0 0 24 24"
                    :fill="this.bottomStar ? '#00C92E' : 'none'"
                    :stroke="this.bottomStar ? '#00C92E' : '#828282'"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                    />
                  </svg>
                </span>
              </div>
              <div class="ml-1 Inter-Bold mb-4">
                <span
                  style="
                    font-size: 12px;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: #f8f9f9;
                    padding: 0 0 4px 4px;
                  "
                >
                  {{ stockObj.ticker }}
                </span>
              </div>
              <!-- <div class="flex justify-between items-center px-2">
                <div>
                  <span
                    class="text-lg px-0 py-1 border-box text-[#02bd2d] Inter-Bold"
                    >{{ parseFloat(stockObj.closePrice).toFixed(2) }}</span
                  >
                </div>
                <div class="">
                  <span
                    class="selectedStockValue text-base font-bold px-2 py-1 border-box"
                    :class="
                      closingDiff < 0
                        ? 'text-red-500 bg-[#ff0a00] bg-opacity-10 border-red-500'
                        : 'text-[#02bd2d] bg-[#335b3c] border-[#02bd2d]'
                    "
                    >{{ closingDiff }}
                    <span
                      class="Inter-Bold"
                      :style="stockObj.change < 0 ? { color: 'red' } : {}"
                      >({{ parseFloat(stockObj.change).toFixed(2) }}%)</span
                    >
                  </span>
                </div>
              </div> -->

              <div
                class="flex justify-between text-gray-100 pt-2 pb-1 px-1 text-[.6vw] mr-2 ml-1 font-thin"
                v-for="(config, i) of stockConfig"
                :key="i"
              >
                <div style="display: flex; flex-direction: column; gap: 8px">
                  <span> {{ config.label }}</span>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: flex-end;
                  "
                >
                  <span>{{ tickerValues(stockListData,config.key) || 'N/A' }}</span>
                  <!-- stockListData[config.key] -->
                </div>
              </div>
            </div>

            <div
              class="w-5/12 h-[340px] vld-parent border-[0.3px] border-[#7582783d]"
              ref="marketChart"
            >
              <AreaChartLoader :showChartLoader="showChartLoader" />
              <AreaChart
                :is-index-chart="false"
                :data-chart="stockObj.candles"
                :symbol-name="stockObj.ticker"
                :chartHeight="270"
               
                :showTrend="true"
              />
              <!-- :symbolData="stockObj"
                :clossingDifference = "closingDiff"
                :reportData="true" -->
            </div>

            <div class="w-5/12 flex px-2 flex-col h-fit">
              <!-- <div class="w-full px-2">
                <p
                  style="font-family: 'Inter'"
                  class="text-[14px] xl:text-[16px] Inter-Bold pb-1 text-gray-100"
                >
                  Comments
                </p>
              </div> -->
              <div class="w-full">
                <div class="">
                  <vue-editor
                    class="scroll-py-4"
                    :editor-toolbar="customToolbar"
                    v-model="htmlForEditor"
                    :disabled="disabledEditor"
                  >
                  </vue-editor>
                </div>
              </div>

              <div class="w-full flex mb-0 justify-end my-2 p-1">
                <div v-if="saveCommentProgress">
                  <svg
                    class="mr-3 h-6 w-6 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <div v-if="deleteCommentProgress">
                  <svg
                    class="mr-3 h-6 w-6 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>

                <div v-if="savedComment" class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <p class="text-[9px] text-[#02BD2D] font-bold">{{ marketHeatcommentResponseMsg }}</p>
                </div>

                <div
                  class="flex items-center"
                  data-temp-component="true"
                  v-if="isEmptyComment"
                >
                  <!-- <AlertShort class="w-[300px] py-1" alertType="Danger"> -->
                  <p
                    class="Inter-bold text-[12px] xl:text-[14px] text-red-600 pb-1"
                  >
                    Please add comment!
                  </p>
                  <!-- </AlertShort> --> 
                </div>

                <div class="flex items-center justify-end">
                  <button
                    style="color: #758278; font-size: 9px"
                    :disabled="
                      this.btndel == false && this.delCommentbtn.length <= 0
                    "
                    class="text-white-500 bg-transparent uppercase text-xs px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150 flex items-center"
                    type="button"
                    v-on:click="deleteComment()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    Delete Comment
                  </button>
                  <button
                    class="background-transparent px-4 py-1 text-xs ok-button"
                    v-on:click="htmlForEditor?saveComment():deleteComment()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

              </div>
              <div
              v-else-if ="!loadingData &&  marketHeatTopFiveTable.length == 0 "
              class="w-auto h-[50vh] self-center flex justify-center items-center"
            >
              <AlertShort class="" alertType="Warning" title="No data found" />
            </div>
               
              
               
              </div>
              <!-- <div class="w-full h-full bg-green-400">
                                <AlertShort class="" alertType="Warning" title="No data found" />
                            </div> -->
            </div>
    
           

            <SkeletonTableLoader v-if="loadingData" :rows="6" />

            <!-- v-if="topFiveFetchData.status == 200 && topFiveFetchData.data.data == []"  -->
          </div>

          <!-- bottom div  -->
       
        </div>

     
      </div>
   
  </tailwind-modal>
</template>

<script>
import TailwindModal from "@/components/popups/TailwindModal";

import MarketHeatColumnConfig, {
  ColorMap,
  getWatchClasses,
} from "@/components/config/market_heat/ColumnConfig";
import AreaChart from "@/components/charts/AreaChart";
import { VueEditor } from "vue2-editor";
import axios from "axios";
import FivesApi from "@/api/fivesApi";

import {
  MARKET_HEAT_GET_HEAT_DATA,
  MARKET_HEAT_TOP_FIVE,
  GET_MARKET_HEAT_COMMENT,
  SAVE_MARKET_HEAT_COMMENT,
  DELETE_MARKET_HEAT_COMMENT,
  SAVE_MARKET_HEAT_COMMENT_T,
  GET_MARKET_HEAT_COMMENT_T,
} from "@/api/apiRoutes";
// import { GET_SYMBOL } from "@/api/priceApiRoutes";
import { mapGetters } from "vuex";
import AlertShort from "@/components/utilities/AlertShort.vue";
import GetSymbols from "@/api/charts";
import watchListApi from "../../../api/watchListApi";
import fivesApi from "../../../api/fivesApi";
import TableSortIcon from "@/components/tables/TableSortIcon";
import moment from "moment/moment";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue";
import ColumnConfig from "@/components/config/market_watch/ColumnConfig";

//  modals

export default {
  name: "MarketHeatModal",

  props: {
    modalEnabled: {
      type: Boolean,
      default: false,
    },
    marketHeatModalTable: {
      type: Object,
    },
    currentSelectedProp: {
      type: String,
    },
  },

  data() {
    return {
      watchlistName:'',
      showModalResponse:"",
      showModalResponseError:"",
      showModalLoader:false,
      enableRemove: false,
      getcomment: false,
      btndel: false,
      indexUpperTable:null,
      delCommentbtn: "",
      newWatchName: "",
      watxhlistCreated: false,
      topFiveFetchData: {},
      showChartLoader: true,
      showTableLoader: false,
      closingDiff: "",

      tableHeader: [
        {
          stock: "Stock",
          price: "Price",
          per_change_30_days: "% Change 20 Days",
          div_yield: "Div/Yield",
          sector: "Sector",
          watchlist: "Watchlist",
          chart: "Chart",
          per_change_5_days: "% Change 5 Days",
          trend_rank: "Trend Rank",
          index: "Index",
          swings_greater_than_three: "Swings > 3%",
          five_days_percent_change: "5d % Change",
          p_e_ratio: "P/E Ratio",
          // volume: "Volume 10 Days",
          twenty_days_percent_change: "20d % Chg",
          average_volume_ten_days: "Volume 10 Days",
        },
      ],
      columns: [
        {
          key: "sector",
          label: "Sector",
        },
        "Issued",
        "Market Cap",
        "PE Ratio",
        "EPS",
        "Earning Yields",
        "Trading Basis",
        "Dividend Rate",
        "Dividend yield",
        "Franking",
        "1m Return",
        "3m Return",
        "6m Return",
        "12m Return",
      ],
      stockObj: {
        stockCompanyName: "",
        ticker: "",
        closePrice: "",
        change: "",
        sectorName: "select sector",
        watchlist_Color: "",
        candles: [],
      },
      selectwatchIndex: null,
      marketHeatModalTableIndex: null,
      watch_List_Api_Data: {},
      stockListData: {},
      stockConfig: [
        {
          key: "sector",
          label: "Sector",
        },
        {
          key: "bk_val",
          label: "Book Value",
        },
        {
          key: "volume",
          label: "Volume",
        },
        {
          key: "mkt_cap",
          label: "Market Cap",
        },
        {
          key: "p/e",
          label: "P/E Ratio",
        },
        {
          key: "eps",
          label: "EPS",
        },
        {
          key: "div_$",
          label: "Dividend ($)",
        },
        {
          key: "div_%",
          label: "Dividend Yield (%)",
        },
      ],
      selectedStock: null,
      deleteWatchlistModal: false,
      userSelectedSymbol: [],
      selectedWatchList: "",
      userId: localStorage.getItem("userId"),
      saveWatchlistModal: false,
      createModal: false,
      WatchData: [
        {
          id: "6332b97611111116b6036fbee35e261",
          name: "sain",
          symbolList: [
            {
              id: "ASX",
              name: "PLS.au",
              metaData: {
                exchange: "ASX",
                category: "Equity",
                sector: "Industrials",
                marketCap: 236.0755,
                peRatio: -1.5,
                eps: -0.148,
                earningYield: -67.3,
                tradingBasis: "",
                dividendRate: 0,
                dividendYield: 0,
                frankedPct: 100,
                issue: 1073070217,
                return1m: 37.5,
                return3m: 25.7,
                return6m: -31.2,
                return12m: -53.2,
              },
            },
            {
              id: "ASX",
              name: "PL8.au",
              metaData: {
                exchange: "ASX",
                category: "Equity",
                sector: null,
                marketCap: 668.2,
                peRatio: null,
                eps: null,
                earningYield: null,
                tradingBasis: "",
                dividendRate: 0.063,
                dividendYield: 5.3,
                frankedPct: 100,
                issue: 554536636,
                return1m: -1.6,
                return3m: 6.3,
                return6m: 2.3,
                return12m: -1.6,
              },
            },
          ],
          userId: "63316ead869e3a2fa4d3eecf",
          count: 2,
        },
      ],
      colorMap: ColorMap,
      marketHeatTopFiveTable: [],
      columnConfig: MarketHeatColumnConfig,
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
      ],
      htmlForEditor: null,
      saveCommentProgress: false,
      deleteCommentProgress: false,
      disabledEditor: false,
      savedComment: false,
      showModalMessage: false,
      marketHeatcommentResponseMsg: "",
      errorMessage:"",
      commentId: "",
      isEmptyComment: false,
      loaderState: false,
      userSavedWatchList: [],
      RemovedWatchLists: [],
      RemovedWatchListsData: [],
      RemovedWatchListsShow: [],
      deleteCheckboxWatchlists: [],
      selectedTopFiveSymbol: {},
      watchListApiData: [],
      selectedColumn: "trend_rank",
      sortingOrder: -1,
      companyChangeReport: null,
      selectedStarColor_Array: [],
      bottomStar: false,
      selectedProp: null,
      clickedTicker: null,
      tableDataLoader: false,
      chartHeight: null,
      loadingData: false,
      isError: false,
      count:null
    };
  },
  methods: {

    closeSaveWatchlistModal(){
      this.errorMessage=""
      this.saveWatchlistModal = false
    },

    closeWatchlistModal(){
      this.errorMessage=""
      this.createModal = false
    },

    async SaveCreateWatchlist() {
      const regex = /^\s*$/;
      let date = moment().format("MMMM Do YYYY, h:mm a");
      if(this.watchlistName && !regex.test(this.watchlistName)){
      try {
        this.showModalLoader = true;
        const payload = {
          name: this.watchlistName,
          symbolList: [],
          date: date,
        };
        const res = await watchListApi.createWatchList(payload);
        if (res.data.success) {
          this.showModalLoader = false;
          const res1 = await watchListApi.fetchWatchListData();
          this.watch_List_Api_Data = res1.data?.payload;
          const symbolDataList = await GetSymbols.getSymbol(
          this.marketHeatTopFiveTable[this.marketHeatModalTableIndex].ticker,
          "D"
        );
          const payload2 = {
            watchId:this.watch_List_Api_Data[this.watch_List_Api_Data.length - 1]._id,
              symbolList: [
                {
                  companyName: symbolDataList.companyName,
                  id: "ASX",
                  name: this.marketHeatTopFiveTable[
                    this.marketHeatModalTableIndex
                  ].ticker,
                },
              ],
        };
        console.log("payload2",payload2)
        const res2 = await watchListApi.updateWatchListRecord(payload2);
        if (res2.data.success) {
          this.showModalMessage = true;
          this.showModalResponse = "A new Watchlist has been created for your selected stocks.";
          setTimeout(() => {
            this.showModalResponse = ""
            this.showModalMessage = false;
            this.createModal = false;
          }, 3000);
          // this.createWatclistModal = false;
        }
        else{
          this.showModalMessage = true;
          this.showModalResponseError = res2.data.message;
          setTimeout(()=>{  
            this.showModalMessage = false;
          this.showModalResponseError = "";
          },3000)
        }
        }
        else{
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponseError = res.data.message;
          setTimeout(() => {
            this.showModalMessage = false;
            this.showModalResponseError=""
          }, 3000);
          
        }
      } catch (err) {
       
        this.showModalMessage = true;
        this.showModalLoader = false;
        this.showModalResponseError = "Server error";

        setTimeout(() => {
          this.showModalMessage = false;
        this.showModalResponseError = "";
        }, 3000);
      }
      this.SelectedStockStarColor();
      this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
    }else{
      this.errorMessage = "Empty watchlist is not allowed"
    }
    },

    //ticker detail value
    tickerValues(data,key){
      // if(key=='sector'){
        return this.formatNumber(data[key])
      // }else{
      //   let fixedValue = Number(data[key]?.replace(/,/g,'')).toFixed(2)
      //   // let localeValue = new Intl.NumberFormat('en-US').format(fixedValue)
      //   return fixedValue
      // }
    },


    formatNumber(val) {
  const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;

  if (isNaN(num)) {
    return val; // Return the original value if it's not a valid number
  }

  const isNegative = num < 0;
  const absoluteValue = Math.abs(num);


  let fixedValue;
  if (absoluteValue < 1) {
    fixedValue = 3;
  } else {
    fixedValue = 2;
  }

  if (absoluteValue >= 1e12) {
    return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(fixedValue) + 'T';
  } else if (absoluteValue >= 1e9) {
    return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(fixedValue) + 'B';
  } else if (absoluteValue >= 1e6) {
    return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(fixedValue) + 'M';
  } else if (absoluteValue >= 1e3) {
    return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
  } else {
    if(isNegative){
      return '-' + absoluteValue?.toFixed(3)
    }else{
      return absoluteValue.toFixed(fixedValue)
    }
  }
},




    // delete work started
    Check(e, i, data) {
      if (e.target.checked) {
        this.deleteCheckboxWatchlists.push(data);
        this.enableRemove = true;
      } else {
        let a = this.deleteCheckboxWatchlists.indexOf(data);
        this.deleteCheckboxWatchlists.splice(a, 1);
        this.enableRemove = false;
      }
    },
    // delete work ended

    // star work start
    AddWatchlist_2() {
      this.saveWatchlistModal = true;
      this.deleteWatchlistModal = false;
    },
    async SelectedStockStarColor() {
      let index = 0;
      // this.bottomStar = this.topFive_StarData.includes(this.selectedStock)
      this.selectedStarColor_Array = [];
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData(params);
        console.log("res ", res);
        for (let i in this.marketHeatTopFiveTable) {
          for (let Data in res.data.payload) {
            for (let List in res.data.payload[Data].symbolList) {
              if (
                this.marketHeatTopFiveTable[i].ticker ==
                  res.data.payload[Data].symbolList[List].name &&
                !this.selectedStarColor_Array.includes(i)
              ) {
                this.selectedStarColor_Array[index] = JSON.parse(i);
                this.RemovedWatchListsData[index] = res.data.payload[Data];
                console.log("yaha bhe aya");
                index = index + 1;
              }
            }
          }
        }
        // console.log("...res ", res)
        // console.log("RemovedWatchListsData ", this.RemovedWatchListsData)
        // console.log("selectedStarColor_Array", this.selectedStarColor_Array)
        this.$store.commit("TOP_FIVE_STAR_DATA", this.selectedStarColor_Array);
        this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
      } catch (err) {
        console.log("your fetch data api destroyed", err);
      }
    },
    // star work end
    formatDate(d) {
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY");
    },
    async getSortedData(col) {
      this.marketHeatTopFiveTable=[]
      this.loadingData=true

      if (this.sortingOrder === 1) {
        this.sortingOrder = -1;
      } else {
        this.sortingOrder = 1;
      }
      // this.tableDataLoader = true
      // this.showTableLoader = true
      console.log("col", col);
      let cat = `${this.getActiveCountry}_${this.getSelectedProp}`;
     
      this.selectedColumn = col;
      let res = await axios.post(MARKET_HEAT_TOP_FIVE, {
        // column:col?col:this.defaultColumn,
        column: col ? col : this.selectedColumn,

        sortingOrder: this.sortingOrder,
        date: this.marketHeatModalTable["date"],
        category: cat,
      });

      if (res.data.data) {
       this.loadingData=false
        this.marketHeatTopFiveTable = res.data.data;
        await this.SelectedStockStarColor();
        
        await this.ModalSelectedStock(0);
        
      
      } else {
        // this.tableDataLoader = false
        this.showTableLoader = false
      }
    },

    async ModalSelectedStock(index) {
      this.showTableLoader = true;
      this.showChartLoader = true;
      this.chartHeight = 300;
      this.selectedStock = index;

      let ticker_Name = this.marketHeatTopFiveTable[index].ticker;

      this.clickedTicker = this.marketHeatTopFiveTable[index].ticker;
      
      let result = await this.getComments();
      console.log("getComments against ticker ", result);
      const symbolDataList = await GetSymbols.getSymbol(ticker_Name, "D");
      console.log("symbolDataList", symbolDataList);
      this.getCompanyChangeReport(symbolDataList?.assetID);
      this.showTableLoader = false;
      // this.stockListData = symbolDataList.metaData;

      // let symboldata = await fivesApi.getCompanyReport(
      //   { symbol: ticker_Name },
      //   this.getActiveCountry
      // );
      let symboldata = await fivesApi.getCompanyReportStockAlert(ticker_Name);
      console.log("result of sym==>", symboldata);
      let payload = symboldata.data.payload;
      this.stockListData = payload;
      console.log("this.stockListData", this.stockListData);

      let candlesData = symbolDataList.data;
      // let dataSlice = candlesData.slice(
      //   candlesData.length - 180,
      //   candlesData.length - 1
      // );
      this.stockObj = {
        stockCompanyName: symbolDataList.companyName,
        ticker: this.marketHeatTopFiveTable[index].ticker,
        closePrice: this.marketHeatTopFiveTable[index].close,
        change: this.marketHeatTopFiveTable[index].twenty_days_percent_change,
        sectorName: this.marketHeatTopFiveTable[index].sector,
        watchlist_Color: this.marketHeatTopFiveTable[index].isInWatchList
          ? "green"
          : "none",
        candles: candlesData,
      };
      this.showTableLoader = false;
      this.showChartLoader = false;
      // this.SelectedStockStarColor();
      // this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
      // if(this.stockObj.candles.length == 0){
      //   this.showChartLoader = false
      // }
    },
    CreateFromSelectWatchlist() {
      this.saveWatchlistModal = false;
      this.createModal = true;
    },
    saveSearchedStock: async function () {
      const regex = /^\s*$/;
      // this.bottomStar = this.topFive_StarData.includes(this.selectedStock)
      // this.saveWatchlistModal = false;
      if (this.userSelectedSymbol.length > 0) {
        // this.selectedWatchList = this.watch_List_Api_Data.data.payload[this.selectwatchIndex].name;

        const symbolDataList = await GetSymbols.getSymbol(
          this.marketHeatTopFiveTable[this.marketHeatModalTableIndex].ticker,
          "D"
        );

        if (!this.watxhlistCreated) {
          console.log("if m aya ");
          if(this.newWatchName && !regex.test(this.newWatchName)){
          try {
            const payload = {
              name: this.newWatchName,
              symbolList: [],
            };
            console.log(payload);

            const res = await watchListApi.createWatchList(payload);
            // console.log("res",res)

            if (res.data.success) {
              const res1 = await watchListApi.fetchWatchListData();
              this.watch_List_Api_Data = res1.data.payload;
            }
            const payload2 = {
              symbolList: [
                {
                  companyName: symbolDataList.companyName,
                  id: "ASX",
                  name: this.marketHeatTopFiveTable[
                    this.marketHeatModalTableIndex
                  ].ticker,
                },
              ],
              watchId: this.watch_List_Api_Data[0]._id,
            };

            const res2 = await watchListApi.updateWatchListRecord(payload2);
            if (res2.data.success) {
              this.createWatclistModal = false;
              this.getStockListData = [];
            }
            this.saveWatchlistModal = false;
          } catch (err) {
            console.log(err);
            this.createWatclistModal = false;
          }
        }else{
          this.errorMessage = "Empty watchlist is not allowed"
        }
        } else {
          console.log("else m aya ");
          if(this.selectwatchIndex){
          this.showModalLoader = true;
            
          try {
            
            const payload = {
              // userId:
              //   this.watch_List_Api_Data.data.payload[this.selectwatchIndex].userId,
              watchId:
                this.watch_List_Api_Data.data.payload[this.selectwatchIndex]
                  ._id,
              symbolList: [
                {
                  companyName: symbolDataList.companyName,
                  id: "ASX",
                  name: this.marketHeatTopFiveTable[
                    this.marketHeatModalTableIndex
                  ].ticker,
                },
              ],
            };
            const res = await watchListApi.updateWatchListRecord(payload);
            if (res.data.success) {
              // this.singleInput=null
              // this.checkAll=false
              this.showModalLoader = false;
              this.showModalMessage = true;
              this.showModalResponse = "Stock has been saved to your watchlist";
              setTimeout(() => {
                this.showWatchlistModal = false;
                this.showModalMessage = false;
                this.showModalResponse = "";
                this.saveWatchlistModal = false;
              }, 3000);
              // this.getStockListData = [];
          }else{
            this.showModalLoader = false;
            this.showModalMessage = true;
            this.showModalResponseError = res?.data?.message;
            setTimeout(() => {
              this.showModalMessage = false;
            this.showModalResponseError = "";
            }, 3000);
          }
            this.selectwatchIndex = null
            console.log("market modal ", payload);
          } catch (err) {
            this.showModalLoader = false;
            this.showModalMessage = true;
            this.showModalResponseError = "Server Error";
            setTimeout(() => {
              this.showModalMessage = false;
            this.showModalResponseError = "";
            }, 3000);
          }}else{
            this.errorMessage="Select watchlist to save stock"
          }
        }
      } else {
        console.log("select Symbol list");
      }
      
      this.SelectedStockStarColor();
      this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
    },

    async selectWatchList(e) {
      this.selectwatchIndex = e.target.value;
     
    },
    async CheckWatchlistFill(check, y, index) {
      this.clickedTicker = y.ticker;
      this.selectedStock = index;

      console.log("name check kro ", y.ticker);
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData(params);
        this.watch_List_Api_Data = res;
        console.log(
          "...this.watch_List_Api_Data ",
          this.watch_List_Api_Data.data.payload
        );
        this.marketHeatModalTableIndex = index;
        this.marketHeatTopFiveTable;
        if (this.watch_List_Api_Data.data.payload.length > 0) {
          this.watxhlistCreated = true;
        } else {
          this.watxhlistCreated = false;
        }
        console.log(
          "this.marketHeatModalTableIndex ",
          this.marketHeatModalTableIndex
        );
      } catch (err) {
        console.log("in market heat modal window api destroyed", err);
      }
      this.selectedTopFiveSymbol = [
        {
          name: this.marketHeatTopFiveTable[index].ticker,
        },
      ];
      this.userSelectedSymbol.push({
        name: y.ticker,
        id: "ASX",
      });
      if (this.topFive_StarData.includes(index)) {
        this.deleteWatchlistModal = true;
        this.saveWatchlistModal = false;
        this.RemovedWatchListsShow = [];
        this.SelectedStockStarColor();
        let counter = 0;
        for (let a of this.RemovedWatchListsData) {
          console.log(a);
          for (let i of a.symbolList) {
            if (
              this.marketHeatTopFiveTable[index].ticker == i.name &&
              !this.RemovedWatchListsShow.includes(a)
            ) {
              this.RemovedWatchListsShow.push(a);
            }
          }
        }
        this.RemovedWatchListsData = [];
      } else {
        this.deleteWatchlistModal = false;
        this.saveWatchlistModal = true;
      }
    },
    getWatchClasses,
    isInWatch(symbol) {
      // console.log("symbol", symbol);
      let res = this.WatchData.find((d) => {
        // console.log("...", d.symbolList);
        let res2 = d.symbolList.find((e) => {
          let decision = e.name === symbol;
          // console.log("e.name... ", e.name, symbol, decision);
          return decision;
        });
        // console.log("res2", res2);
        if (res2) {
          return true;
        } else {
          return false;
        }
      });
      // console.log("ticker ", res);

      return res ? true : false;
    },

    async getCompanyChangeReport(assetID) {
      this.showChartLoader = true;
      let companyChangeReport = null;
      if (assetID) {
        let apiData = await FivesApi.fetchSnapQuotes([assetID]);
        if (apiData?.data && apiData?.data.length > 0) {
          //  update changes
          this.showChartLoader = false;
          let data = apiData.data[0];
          companyChangeReport = apiData.data[0];
          console.log("=============================>", data);
          this.closingDiff = data.closingDiff;
          console.log("company change report", this.companyChangeReport);
        }
      }
      if (companyChangeReport) {
        this.showChartLoader = false;
        this.companyChangeReport = companyChangeReport;
      }
    },
    // async AddToWatchList(y) {
    //   let payload = {
    //     symbolList: [{ name: y.ticker, id: "ASX" }],
    //     userId: localStorage.getItem("userId"),
    //     watchId: this.watchListApi_Data[0].id,
    //   };
    //   const res = await watchListApi.updateWatchListRecord(payload);
    // },
    async RemoveWatchList() {
      for (let i in this.deleteCheckboxWatchlists) {
        let payload = {
          symbolList: this.selectedTopFiveSymbol,

          watchId: this.deleteCheckboxWatchlists[i]._id,
        };
        const res = await watchListApi.removeWatchListRecord(payload);
        // console.log(this.deleteCheckboxWatchlists[i])
      }
      this.SelectedStockStarColor();
      this.deleteWatchlistModal = false;

      let a = this.topFive_StarData.indexOf(this.selectedStock);
      this.topFive_StarData.splice(a, 1);
      this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
    },
    toggleModal() {
      this.$emit("closeModal"),
        this.$emit("fetchapi")((this.isEmptyComment = false));
    },
    // async saveComment() {
    //   console.log(this.clickedTicker)
    //   console.log(this.htmlForEditor);
    //   if (this.htmlForEditor) {
    //     this.saveCommentProgress = true;
    //     this.disabledEditor = true;
    //     let result = await axios.post(`${SAVE_MARKET_HEAT_COMMENT_T}`, {
    //       ticker: this.clickedTicker,
    //       comment:this.htmlForEditor

    //     });
    //     console.log("result====>", result);

    //     });
    //     console.log("result====>", result);

    //     if (result.data.success) {
    //       this.saveCommentProgress = false;
    //       this.disabledEditor = false;
    //       this.savedComment = true;
    //       this.marketHeatcommentResponseMsg = result.data.message;

    //       setTimeout(() => {
    //         this.savedComment = false;
    //         this.marketHeatcommentResponseMsg = "";
    //       }, 2 * 1000);
    //     }
    //   } else {
    //     this.isEmptyComment = true;
    //   }
    // },
    //previous one
    // async getMarketTableData() {

    //   let result = await axios.post(
    //     `${MARKET_HEAT_GET_HEAT_DATA}/${this.getActiveCountry}`,
    //     { year: this.selectedActiveYearStore }
    //   );

    //   if (result.status == 200) {
    //     // this.loading = false;
    //     console.log("result",result)
    //   }
    //   // this.marketHeatTable = result.data.data;
    //   // console.log(this.marketHeatTable);
    // },
    async saveComment() {
      console.log(this.clickedTicker);
      console.log("htmlForEditor", this.htmlForEditor);

      // if (this.htmlForEditor) {
        console.log("this.htmlForEditor",this.htmlForEditor)
        this.saveCommentProgress = true;
        this.disabledEditor = true;
        let result = await axios.post(`${SAVE_MARKET_HEAT_COMMENT}`, {
          date: this.marketHeatModalTable.date,
          comment: this.htmlForEditor,
          country: this.getActiveCountry,
        });
        // this.delCommentbtn=result
        console.log("result====>", result.data.payload.comment._id);
        this.commentId = result.data.payload.comment._id;

        if (result.data.success) {
          this.btndel = true;
          this.saveCommentProgress = false;
          this.disabledEditor = false;
          this.$store.commit("SET_COMMENT_HIGHLIGHTER",this.formatDate(this.marketHeatModalTable?.date));
          this.savedComment = true;
          this.marketHeatcommentResponseMsg = result.data.message;

          setTimeout(() => {
            this.savedComment = false;
            this.marketHeatcommentResponseMsg = "";
          }, 2 * 1000);
          this.$emit("click");
        }
      // } else {
      //   // this.isEmptyComment = true;
      //   this.deleteComment()
      // }
    },
    // async getComments() {
    //   this.loaderState = true;
    //   this.disabeled = true;
    //   // console.log("hello from  get Commehts");
    //   let result = await axios.post(`${GET_MARKET_HEAT_COMMENT_T}`, {
    //     ticker:this.clickedTicker,
    //   });
    //   console.log("get from comment ",result);
    //   if (result) {
    //     this.loaderState = false;
    //     this.disabeled = false;
    //     // console.log(result.data.payload.comment)
    //     if(result.data.payload.comment){

    //       this.htmlForEditor=result.data.payload.comment.comment
    //     }
    //     else{
    //       this.htmlForEditor = null

    //     }
    //     // let commentObject = result.data.payload.comment;
    //     // let commentObjectLength = Object.keys(commentObject).length;
    //     // console.log(commentObjectLength);

    //     // if (Object.keys(commentObject).length > 0) {
    //     //   this.htmlForEditor = result.data.payload.comment[0].comment;
    //     //   this.commentId = result.data.payload.comment[0]._id;
    //     //   console.log(this.commentId);
    //     // } else {
    //     //   this.htmlForEditor = null;
    //     // }
    //   }
    // },

    // previous get comments
    async getComments() {
      this.loaderState = true;
      this.disabeled = true;
      // console.log("hello from  get Commehts");
      let result = await axios.post(`${GET_MARKET_HEAT_COMMENT}`, {
        date: this.marketHeatModalTable.date,
        country: this.getActiveCountry,
      });
      // console.log(result);
      if (result) {
        this.delCommentbtn = result.data.payload.comment;

        console.log("result", result.data.payload.comment);

        this.loaderState = false;
        this.disabeled = false;

        let commentObject = result.data.payload.comment;
        let commentObjectLength = Object.keys(commentObject).length;
        // console.log(commentObjectLength);
        if (Object.keys(commentObject).length > 0) {
          this.htmlForEditor = result.data.payload.comment[0].Comment;
          console.log("htmlForEditor", this.htmlForEditor);
          this.commentId = result.data.payload.comment[0]._id;
          console.log(this.commentId);
        } else {
          this.htmlForEditor = null;
        }
      }
    },
    async deleteComment() {
      // if (this.htmlForEditor) {
        // if (this.htmlForEditor) {
          this.deleteCommentProgress = true;

          let result = await axios.post(`${DELETE_MARKET_HEAT_COMMENT}`, {
            id: this.commentId,
          });
          console.log("Res", result);
          if (result.data.success) {
            this.btndel = false;
            this.deleteCommentProgress = false;
            this.savedComment = true;
            this.$store.commit("REMOVE_COMMENT",this.formatDate(this.marketHeatModalTable?.date));


            this.marketHeatcommentResponseMsg = result.data.message;

            setTimeout(() => {
              this.savedComment = false;
              this.marketHeatcommentResponseMsg = "";
              this.htmlForEditor = null;
            }, 2 * 1000);
          }
          this.getComments();
        // }
      // }
    },
    async FetchMarketCategory() {
      console.log("marketHeatModalTable",this.marketHeatModalTable)
      this.marketHeatTopFiveTable=[]
  
      this.loadingData = true;
   console.log("loadingData",this.loadingData)

   
  
      this.isError = false;
      let cat = `${this.getActiveCountry}_${this.getSelectedProp}`;
      console.log("cat",cat)
     
      this.showTableLoader = true;
      let data = await axios.post(MARKET_HEAT_TOP_FIVE, {
        date: this.marketHeatModalTable["date"],
        category: cat,
        column: this.selectedColumn,
        sortingOrder: this.sortingOrder,
      });
      if (data.status == 200) {
        this.loadingData = false;
      }
      // if (data.data.data.length == 0) {
      //   this.isError = true;
      // }
      console.log("hhhh", data.data);
      let dataList = data.data.data;
      console.log("datalist",dataList)
      this.showTableLoader = false;
      let newdataList = dataList.map((a) => {
        a.inwatchList = this.isInWatch(a.ticker);
        return a;
      });
      console.log("new",newdataList)
      this.userSavedWatchList = data.data.savedWatchList;
      this.showTableLoader = false;
      this.marketHeatTopFiveTable = newdataList;
      await this.SelectedStockStarColor();

      await this.ModalSelectedStock(0);
     
    },
  },
  components: {
    TableSortIcon,
    TailwindModal,
    AreaChart,
    VueEditor,
    AlertShort,
    AreaChartLoader,
    SkeletonTableLoader,
  },
  computed: {
    ...mapGetters([
      "getActiveCountry",
      "watchListApi_Data",
      "activeCountry",
      "topFive_StarData",
    ]),
    getModalColumns: {
      get() {
        return this.columnConfig.filter((s) => s.showInModal);
      },
    },

    CompanyChangeComputed() {
      console.log("companyChangeReport========", companyChangeReport);
      return `${this.companyChangeReport.closingDiff} (${this.companyChangeReport.closingPercent}%)`;
    },
    selectedStockData: {
      get() {
        return [];
      },
    },
    getSelectedProp: {
      get() {
        return this.selectedProp || this.currentSelectedProp;
      },
      set(value) {
        this.selectedProp = value;
      },
    },
  },
  watch: {
    newWatchName(){
      this.errorMessage=""
    },
    selectwatchIndex(){
      this.errorMessage=""
    },
    watchlistName(){
      this.errorMessage=""
    },
    chartHeight() {
      console.log("chartheight==", this.chartHeight);
    },
    async selectedProp(n, o) {
      this.chartHeight = 300;
      console.log("this.marketHeatModalTable",this.marketHeatModalTable)
      console.log("ddf", n);
      let clicked = this.columnConfig.find(f=>f.colName===n);
      // let value = this.marketHeatModalTable.find(f=>f.colName===n);
      this.count=this.marketHeatModalTable[n]

      this.indexUpperTable=clicked?.label
      let result = await this.FetchMarketCategory();
      console.log("result of fecth category", result);
    },
    async modalEnabled(n, o) {
      if (n) {
        console.log("n====>", n);
        this.chartHeight = 300;
        this.getComments();
        this.selectedProp = this.currentSelectedProp;
        this.bottomStar = this.topFive_StarData.includes(this.selectedStock);
        console.log("this.selectedProp", this.selectedProp);
        // await this.FetchMarketCategory();
      }
    },

    htmlForEditor(value) {
      if (value) {
        this.isEmptyComment = false;
      }
    },
  },
  mounted() {
    (this.chartHeight = 300), console.log("undefined==", this.chartHeight);
    this.FetchMarketCategory();
    console.log("kkkkkk", this.delCommentbtn);
  },
  created() {
    this.chartHeight = 300;
    console.log("this.chartHeight", this.chartHeight);
    console.log("market", this.marketHeatTopFiveTable);
  },
};
</script>

<style>
.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: white !important;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: white !important;
}

.ql-toolbar.ql-snow {
  border: 0.3px solid #7582783d !important;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.ql-container.ql-snow {
  border: 0.3px solid #7582783d !important;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  height: 265px;
}
</style>
<style scoped>
.container {
  display: block;
  position: relative;
  right: 2px;
  cursor: pointer;
  width: 0.7em;
  height: 0.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.container input:checked ~ .checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.bullishBearsih {
  background: linear-gradient(270deg, #ff0a00 0%, #1e2125 48.96%, #00c92e 100%);
  border: 0.3px solid rgba(248, 249, 249, 0.45);
  border-radius: 3px;
}

.basicHeaders {
  height: 27px !important;
  width: 59.29px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  align-items: center;
  text-align: center;
  color: #e5e9ec;
  border: 0.3px solid #7582783d;
}

.totalAboveHeaders {
  width: 52px;
  height: 50px;
  left: 4px;
  top: 3px;

  /* Cell Title Heat 9px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  /* Cultured 2 */

  color: #e5e9ec;
  border: 0.3px solid #7582783d;
}

.stocks {
  /* height: 25rem; */
}

.selectedStockValue {
  border-radius: 3px;
  font-size: 11px;
  font-weight: 400;
}

/* .selectedStock {
  font-size: 24px;
  font-weight: 600;
  color: #02bd2d;
  padding: 5px;
  margin-top: 1px;
} */
.top_5 {
  border: 0.3px solid rgba(117, 130, 120, 0.2);
  background-color: #1e2224;
}

.top_5_fields {
  border: 0.3px solid rgba(117, 130, 120, 0.2);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}
</style>