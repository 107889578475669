//import datesApi from "../api/datesApi";

const dateModule = {
  state: {
    get_Selected_Stoks:[],
    loadedDates: [],
    datesModalSaved:false,
    selectedDateStore: [],
    checkedDatedOfModal: {
      name:"",index:[]
    },
    marketHeatColorCode:'',
    categoryForSaveDates:""
  },
  mutations: {
    DATES_MODAL_SAVED(state, payload){
      state.datesModalSaved = payload
    },
    GET_SELECTED_STOCKS(state, payload){
      state.get_Selected_Stoks = payload 
    },
    MARKET_HEAT_COLOR_CODE(state, payload){
      state.marketHeatColorCode = payload
    },
    SAVE_LOADED_DATES(state, payload) {
      state.loadedDates = payload; // Save loaded dates:Rimsha
    },
    CHECKED_DATES_MODAL(state, payload) {
      state.checkedDatedOfModal = payload; //Rimsha
    },
    ADD_DATE_INTO_ARRAY(state, payload) {
      // console.log('payload',payload.length)
      state.selectedDateStore = payload; //checked dates:Abdul Rehman
      // console.log("ADD_DATE_INTO_ARRAY ", state.selectedDateStore)
    },
    SET_CATEGORY_FOR_DATES(state, payload){
      state.categoryForSaveDates = payload
    }
  },

  actions: {
    // async fetchLoadDatesAction({ commit }, payload) {
    //   try {
    //     const data = await datesApi.fetchLoadDates(payload);
    //     commit("SAVE_LOADED_DATES", data);
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    // async saveDatesAction(payload) {
    //   try {
    //     const data = await datesApi.saveDates(payload);
    //     return data;
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    // async deleteDatesAction(payload) {
    //   try {
    //     const data = await datesApi.deleteDates(payload);
    //     return data;
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
  },

  getters: {
    datesModalSaved: state => state.datesModalSaved,
    get_Selected_Stoks: state => state.get_Selected_Stoks,
    marketHeatColorCode: state => state.marketHeatColorCode,
    selectedDateStore: (state) => state.selectedDateStore,
    selectedSavedDateStore: (state) => state.loadedDates,
    selectedcheckedDatedModalStore: (state) => state.checkedDatedOfModal,
    categoryforSaveDates: (state) => state.categoryForSaveDates,
  },
};

export default dateModule;
