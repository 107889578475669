export const privacypolicy = [
    {id:1,heading:'We respect your privacy',content:`
    MAP respects your right to privacy and is committed to safeguarding the privacy
    of our customers and website visitors. We adhere to the Australian Privacy
    Principles contained in the Privacy Act 1988 (Cth). This policy sets out how we
    collect and treat your personal information.
    "Personal information" is information we hold which is identifiable as being about
    you, but is not personal financial information as defined by the Australian Securities
    and Investment Commission.`},
    {id:2,heading:'Collection of personal information',content:`MAP will, from time to time, receive and store personal information you enter onto
    our website, provided to us directly or given to us in other forms.
    You may provide basic information such as your name, phone number, address
    and email address to enable us to send information, provide updates and
    process your product or service order. We may collect additional information at
    other times, including but not limited to, when you provide feedback, change your
    content or email preference, respond to surveys and/or promotions, provide
    financial or credit card information, or communicate with our customer support.
     Additionally, we may also collect any other information you provide while
    interacting with us.
    
    `},
    {id:3,heading:'How we collect your personal information',content:`MAP collects personal, non-financial information from you in a variety of ways,
    including when you interact with us electronically or in person, when you access
    our website and when we provide our services to you. We may receive
    personal, non-financial information from third parties. If we do, we will protect it
    as set out in this Privacy Policy.`},
    {id:4,heading:'Use of your personal information',content:`MAP may use personal, non-financial information collected from you to provide
    you with information, updates and our services. We may also make you aware
    of new and additional products, services and opportunities available to you. We
    may use your personal contact information to improve our products and services.
     MAP may contact you by a variety of measures including, but not limited to
    telephone, email or sms.`},
    {id:5,heading:'Disclosure of your personal information',content:`We may disclose your personal information to any of our employees,
    officers, insurers, professional advisers, agents, suppliers or subcontractors
    insofar as is reasonably necessary for the purposes set out in this Policy.
    Personal information is only supplied to a third party when it is required for the
    delivery of our services.We may from time to time need to disclose personal information to comply with a
    legal requirement, such as a law, regulation, court order, subpoena, warrant, in
    the course of a legal proceeding or in response to a law enforcement agency
    request.
     We may also use your non-financial personal information to protect the
    copyright, trademarks, legal rights, property or safety of MAP,
    www.marketalertpro.com, its customers or third parties.
     Information that we collect may from time to time be stored, processed in or
    transferred between parties located in countries outside of Australia.
    If there is a change of control in our business or a sale or transfer of business
    assets, we reserve the right to transfer to the extent permissible at law our user
    databases, together with any personal information and non-personal information
    contained in those databases. This information may be disclosed to a potential
    purchaser under an agreement to maintain confidentiality. We would seek to only
    disclose information in good faith and where required by any of the above
    circumstances.
    By providing us with personal information, you consent to the terms of this
    Privacy Policy and the types of disclosure covered by this Policy. Where we
    disclose your personal information to third parties, we will request that the third
    party follow this Policy regarding handling your personal information.
    `},
    {id:6,heading:'Security of your personal information',content:`MAP is committed to ensuring that the information you provide to us is secure. In
    order to prevent unauthorised access or disclosure, we have put in place suitable
    physical, electronic and managerial procedures to safeguard and secure
    information and protect it from misuse, interference, loss and unauthorised
    access, modification and disclosure.
    The transmission and exchange of information is carried out at your own risk.
    We cannot guarantee the security of any information that you transmit to us, or
    receive from us. Although we take measures to safeguard against unauthorised
    disclosures of information, we cannot assure you that personal information that
    we collect will not be disclosed in a manner that is inconsistent with this Privacy
    Policy`},
    {id:7,heading:'Access to your personal information',content:`You may request details of personal information that we hold about you in
    accordance with the provisions of the Privacy Act 1988 (Cth). A small
    administrative fee may be payable for the provision of information. If you would
    like a copy of the information, which we hold about you or believe that any
    information we hold on you is inaccurate, out of date, incomplete, irrelevant or
    misleading, please email us at admin@marketalertpro.com.
    We reserve the right to refuse to provide you with information that we hold about
    you, in certain circumstances set out in the Privacy Act.
    `},
    {id:8,heading:'Complaints about privacy',content:`If you have any complaints about our privacy practices, please feel free to send
    in details of your complaints to 12 Winsham Rd, Karrinyup, Western Australia
    6018 or admin@marketalertpro.com. We take complaints very seriously and will
    respond shortly after receiving written notice of your complaint.`},
    {id:9,heading:'Changes to Privacy Policy',content:`Please be aware that we may change this Privacy Policy in the future. We may
    modify this Policy at any time, in our sole discretion and all modifications will be
    effective immediately upon our posting of the modifications on our website or
    notice board. Please check back from time to time to review our Privacy
    Policy.`},
    {id:10,heading:'Website',content:`When you visit our website:
    When you come to our website (www.marketalertpro.com) we may collect
    certain information such as browser type, operating system, website visited
    immediately before coming to our site, etc. This information is used in an
    aggregated manner to analyse how people use our site, such that we can
    improve our service.
    Cookies:
    We may from time to time use cookies on our website. Cookies are very small
    files which a website uses to identify you when you come back to the site and to
    store details about your use of the site. Cookies are not malicious programs that
    access or damage your computer. Most web browsers automatically accept
    cookies but you can choose to reject cookies by changing your browser settings.
    However, this may prevent you from taking full advantage of our website. Our
    website may from time to time use cookies to analyse website traffic and help us
    provide a better website visitor experience. In addition, cookies may be used to
    serve relevant ads to website visitors through third party services such as
    Google Adwords. These ads may appear on this website or other websites you
    visit.`},
]