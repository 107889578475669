<template>
 <svg class=" h-[.8vw] w-[.8vw]" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.45">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.21875 10.7812C4.21875 10.6569 4.26814 10.5377 4.35604 10.4498C4.44395 10.3619 4.56318 10.3125 4.6875 10.3125H14.0625C14.1868 10.3125 14.306 10.3619 14.394 10.4498C14.4819 10.5377 14.5312 10.6569 14.5312 10.7812C14.5312 10.9056 14.4819 11.0248 14.394 11.1127C14.306 11.2006 14.1868 11.25 14.0625 11.25H4.6875C4.56318 11.25 4.44395 11.2006 4.35604 11.1127C4.26814 11.0248 4.21875 10.9056 4.21875 10.7812ZM2.34375 7.03125C2.34375 6.90693 2.39314 6.7877 2.48104 6.69979C2.56895 6.61189 2.68818 6.5625 2.8125 6.5625H12.1875C12.3118 6.5625 12.431 6.61189 12.519 6.69979C12.6069 6.7877 12.6562 6.90693 12.6562 7.03125C12.6562 7.15557 12.6069 7.2748 12.519 7.36271C12.431 7.45061 12.3118 7.5 12.1875 7.5H2.8125C2.68818 7.5 2.56895 7.45061 2.48104 7.36271C2.39314 7.2748 2.34375 7.15557 2.34375 7.03125ZM0.46875 3.28125C0.46875 3.15693 0.518136 3.0377 0.606044 2.94979C0.693951 2.86189 0.81318 2.8125 0.9375 2.8125H10.3125C10.4368 2.8125 10.556 2.86189 10.644 2.94979C10.7319 3.0377 10.7812 3.15693 10.7812 3.28125C10.7812 3.40557 10.7319 3.5248 10.644 3.61271C10.556 3.70061 10.4368 3.75 10.3125 3.75H0.9375C0.81318 3.75 0.693951 3.70061 0.606044 3.61271C0.518136 3.5248 0.46875 3.40557 0.46875 3.28125Z" :fill="color"/>
</g>
</svg>

</template>
<script>
export default {
  name:"SvgListIcon",
  props:{
    fillColor:{
      type:String,
      default:"grey"
    },
    active:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      grey:{
        active:"#e8eae8",
        color:"#848884",
      },
      light_blue:{
        active:"#1b5e83",
        color:"#2d9cdb",
      },
      orange:{
        active:"#915c2c",
        color:"#f2994a",
      },
      purple:{
        active:"#704082",
        color:"#bb6bd9",
      },
      yellow:{
        active:"#91792e",
        color:"#f2c94c",
      }
    }
  },
  computed:{
    color(){
      let computedElement = this[this.fillColor];
      return this.active ? computedElement.active : computedElement.color
    }
  }
}
</script>