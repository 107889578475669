<template>
    <tailwind-modal :showModal="true" :showHeader="false">
        <div class="min-w-[25rem] px-6 py-4" style="
          border: 0.3px solid rgba(2, 189, 45, 0.3);
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #151515;
        ">
            <div style="font-weight: 600; font-size: 17px" class="flex pb-4 text-white">
                Update your email address
            </div>
            <div class="w-full" v-if="responseMessage" data-temp-component="true">
                <AlertShort class="w-full" :alertType="messageType">
                    <p class="text-sm text-gray-300">
                        {{ responseMessage }}
                    </p>
                </AlertShort>
            </div>

            <div class="w-full" style="display: flex; flex-direction: column">


                <input v-if ="!verify" @keyup.enter="verify" placeholder="Enter Password" type="password" v-model="verifyPassword"
                    class="h-[5vh] bg-transparent p-4 box-border text-[.8vw] text-[#FFFFFF] rounded-md mt-4 focus:outline-none"
                    id="otp" ref="otp" style="border: 0.6px solid #7582787d" />
                <div v-else class="flex flex-col">
                    
                    <input   @keyup.enter="verify" placeholder="Enter new email" type="email" v-model="newEmail"
                    :class="emailSent?'h-[5vh] bg-transparent p-4 box-border text-[.8vw] text-[#FFFFFF] rounded-md mt-4 focus:outline-none disabled':'h-[5vh] bg-transparent p-4 box-border text-[.8vw] text-[#FFFFFF] rounded-md mt-4 focus:outline-none '"
                    id="otp" ref="otp" style="border: 0.6px solid #7582787d" />
                    <input   placeholder="Enter six digit code" type="text" v-model="otp"
                    :class="emailSent? 'h-[5vh] bg-transparent p-4 box-border text-[.8vw] text-[#FFFFFF] rounded-md mt-4 focus:outline-none':'h-[5vh] bg-transparent p-4 box-border text-[.8vw] text-[#FFFFFF] rounded-md mt-4 focus:outline-none cursor-not-allowed disabled'"
                    id="otp" ref="otp" style="border: 0.6px solid #7582787d" />
                    
                </div>


            </div>
            <div v-if="!verify" class="w-full mt-4 flex items-center justify-center" :class="loaderState ? 'btnDefault' : ''">
                <VueLoadingButton
                    v-if="!verify"
                    aria-label="Post message"
                    :class="[loaderState && 'cursor-not-allowed',disableElement && 'pointer-events-none']"
                    class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                    @click.native="verifyPasswordfunction"
                    :loading="loaderState"
                  
                    ><span class="text-[15px] font-bold">{{loaderState ? 'Verifying':'VERIFY AND CONTINUE'}}</span>
                </VueLoadingButton>
               
                    
            </div>
            <div v-else class="w-full mt-4 flex items-center justify-center">
                <VueLoadingButton aria-label="Post message"
                v-if="!emailSent"
                    :class="loaderState  ? 'cursor-not-allowed pointer-events-none' : ''"
                    class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                    @click.native="SendEmailCode" :loading="loaderState"><span class="text-[15px] font-bold">{{
                        loaderState ? 'Please wait...' : 'GET CODE ' }}</span>
                </VueLoadingButton>
                <VueLoadingButton aria-label="Post message"
                v-else
                
                    :class="loaderState || verifyPassword === '' ? 'cursor-not-allowed pointer-events-none' : ''"
                    class="button flex items-center cursor-pointer justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                    @click.native="VerifyCode" :loading="loaderState"><span class="text-[15px] font-bold">{{
                        loaderState ? 'verifying...' : 'VERIFY CODE ' }}</span>
                </VueLoadingButton>

               </div> 







            <div class="flex  justify-end pb-2 mt-4">
                <button class="cancel-button rounded px-2 py-1 font-semibold text-sm w-28" @click="closeModal()">
                    Close
                </button>

            </div>
        </div>
    </tailwind-modal>
</template>
<script>
import TailwindModal from "../popups/TailwindModal.vue";
import axios from "axios";
import userApi from "../../api/userApi";
import AlertShort from "../utilities/AlertShort.vue";
import AreaChartLoader from "../loader/AreaChartLoader.vue"
import VuePhoneNumberInput from 'vue-phone-number-input';
import VueLoadingButton from "vue-loading-button";
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            resendCode: false,
            timer: 60,
            intervalId: null,
            disableElement: false,
            tokenExpired: false,
            verify: false,
            showPhoneError: false,
            showOtpError: false,
            loaderState: false,
            userVerificationToken: null,
            emailSent:false,
            messageType: "",
            newEmail:"",
            otp:"",
            verifyPassword: "",
            countryCode: 'AU',
            countries: ['US', 'CA', 'AU', 'ZA', 'FR', 'GB', 'NZ', 'SG'],
            phone: "",
            regex: /^\d{9}$/,
            phoneCode: {
                "US": "+1",
                "AU": "+61",
                "CA": "+1",
                "GB": "+44",
                "NZ": "+64",
                "FR": "+33",
                "SG": "+65",
                "ZA": "+27"
            },
            allCountryCode: {
                "+1": "US",
                "+61": "AU",
                "+1": "CA",
                "+44": "GB",
                "+27": "ZA",
                "+65": "SG",
                "+33": "FR",
                "+64": "NZ"
            },
            responseMessage: ""

        };
    },
    computed: {
        ...mapGetters(["signedInUser"])
    },
    components: {
        TailwindModal,
        AlertShort,
        AreaChartLoader,
        VueLoadingButton,
        VuePhoneNumberInput
    },
    methods: {

        handleInput(value) {
            console.log("value", value)
            this.phone = value?.formattedNumber
            this.countryCode = value?.countryCode
        },
      

       
        async verifyPasswordfunction() {
            console.log("password", this.verifyPassword);

            try {
                this.loaderState = true
                let res = await userApi.verifyPassword({ password: this.verifyPassword })
                if (res?.data?.success) {
                    this.messageType = "Success"
                    this.responseMessage = res?.data?.message
                    this.resendCode = true
                    this.verify = true
                    this.loaderState = false
                } else {
                    this.messageType = "Danger"
                    this.responseMessage = res?.data?.message
                    this.loaderState = false

                }

            }
            catch (e) {
                console.log(e)
                this.messageType = "Danger"
                this.responseMessage = "An unknown error occur"
                this.loaderState = false
            }

        },
        async SendEmailCode (){
        try{
            this.loaderState = true
                let res = await userApi.sendEmailToChangeEmail({ email: this.newEmail })
                if (res?.data?.success) {
                    this.messageType = "Success"
                    this.responseMessage = res?.data?.message
                    this.emailSent=true
                   this.loaderState = false
                  
                } else {
                    this.messageType = "Danger"
                    this.responseMessage = res?.data?.message
                    this.loaderState = false

                }
        
        }
        catch (e) {
                console.log(e)
                this.messageType = "Danger"
                this.responseMessage = "An unknown error occur"
                this.loaderState = false
            }
        

        },
        async VerifyCode (){
            try{
                this.loaderState = true
                let res = await userApi.verifyChangeEmailCode({ code: this.otp,email:this.newEmail })
                if (res?.data?.success) {
                    this.messageType = "Success"
                    this.responseMessage = res?.data?.message
                    this.emailSent=true
                   this.loaderState = false
                   this.closeModal()
                } else {
                    this.messageType = "Danger"
                    this.responseMessage = res?.data?.message
                    this.loaderState = false

                }

            }
            catch (e) {
                console.log(e)
                this.messageType = "Danger"
                this.responseMessage = "An unknown error occur"
                this.loaderState = false
            }

        },
        onInput(event) {
            const input = event.target;
            input.value = input.value.replace(/\D/g, "");
            this.otpNumber = input.value;
        },
        validatePhone(phone) {
            if (this.regex.test(phone)) {
                return true
            } else {
                return false
            }
        },

        startTimer() {
            this.intervalId = setInterval(() => {
                if (this.timer > 0) {
                    this.timer--;
                } else {
                    this.timer = 60
                    this.resendCode = false
                    this.otpNumber = ""
                    this.verify = false,
                        clearInterval(this.intervalId);
                }
            }, 1000);
        },

        closeModal() {
            console.log("close")
            this.$emit("closeEmailModal");

        },

    },
    mounted() {
        console.log("mounted")
        this.countryCode = this.allCountryCode[this.signedInUser?.countryCode]
        this.phone = this.signedInUser?.phone
    },
    watch: {
        countryCode(val) {
            if (val == 'US') {
                this.regex = /^\(\d{3}\) \d{3}-\d{4}$/
            } else if (val == 'CA') {
                this.regex = /^\(\d{3}\) \d{3}-\d{4}$/
            } else if (val == 'GB') {
                this.regex = /^\d{10}$/
            } else if (val == 'AU') {
                this.regex = /^\d{9}$/
            } else if (val == 'NZ') {
                this.regex = /^\d{10}$/
            } else if (val == 'FR') {
                this.regex = /^\d{9}$/
            } else if (val == 'SG') {
                this.regex = /^\d{8}$/
            } else if (val == 'ZA') {
                this.regex = /^\d{9}$/
            }


            //   "NZ":"+64",
            // "FR":"+33",
            // "SG":"+65",
            // "ZA":"+27"
        },
        phone() {
            this.showPhoneError = false
        },
        otpNumber() {
            this.showOtpError = false
        },
        responseMessage() {
            console.log("message")
            setTimeout(() => {
                this.responseMessage = ""
            }, 5000)
        }

    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
};
</script>
  
<style scoped>
.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}

.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.InputContainer .InputElement {
    position: absolute;
    top: 0;
    color: white !important;
}

div.stripeCard {
    color: #f3f3f3 !important;
}

.btnDefault {
    pointer-events: none;
}
</style>
  