<template>
<span class="text-[#00c92e] text-[12px] mt-6">
                <a
                    href="https://docs.marketalertpro.com/legal/terms-and-conditions"
                    target="_blank"
                    class="footerlink"
                >
                  T&Cs
                </a>
                <span class="text-[white]"> | </span>
                <a
                    href="https://docs.marketalertpro.com/legal/privacy-policy"
                    target="_blank"
                    class="footerlink"
                >
                  Privacy Policy
                </a>
                <span class="text-[white]"> | </span>
                <a
                    href="https://docs.marketalertpro.com/legal/financial-services-guide-fsg"
                    target="_blank"
                    class="footerlink"
                >
                  Financial Services Guide(FSG)
                </a>
              </span>
</template>
<script>
export default {
  name: 'FooterCompanyLinks'
}
</script>
<style scoped>

a {
  color: #02bd2d !important;
}


</style>
<style scoped>
a {
  color: white;
}

a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}

.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}
</style>