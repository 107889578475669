<template>
    <div class="w-full flex h-full mb-1">
  
      <div class="w-9/12 flex flex-col">
       
        <div class="flex flex-col p-2 w-full h-full" :class="checkSize ? 'hidden' : ''">
            <div class="w-full h-full">
                <!-- header  -->
                <StockHeader />
            </div>
        </div>
  
        <div class="w-full">
          <Table v-on:size="sizeVal" />
        </div>
      </div>
  
      <div class="w-3/12 flex px-2 flex-col h-[96vh] space-y-2 justify-between">
        <div  class="w-full reportHeight mt-2">
          <!-- <AreaChart   style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 5px;" /> -->
            <StockReport />
        </div>
  
        <div class="w-full calenderHeight">
          <calendar-component pageCalender="Stock Alert" />
        </div>
      </div>
  
  </div> 
  
  </template>
  
  <script>
  // import SideBar from "../sidebar/SideBar.vue";
  import CalendarComponent from "../calendar/CalendarComponent.vue";
  import AreaChart from "../charts/AreaChart.vue";
  import Table from "../tables/StockAlertTable.vue";
  import StockHeader from '../stockalertcomponent/StockHeader.vue';
  import StockAreaChart from '../stockalertcomponent/StockAreaChart.vue';
  import StockReport from '../stockalertcomponent/StockReport.vue';
  import {mapActions} from "vuex";
  
  
  
  
  
  export default {
    name: "StockAlert",
    
  
    components: {
      CalendarComponent,
      AreaChart,
      StockHeader,
      StockAreaChart,
      Table,
      StockReport
    },
    data() {
      return {
        minusValueOfHeight: 0,
       MarketWatchChartHeight: this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight-300,
        // maximize:false,
        size:false,
        checkSize:"",
        yearValue: 2024
      }
    },
  
   
  
    mounted() {
      
      // this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65;
      this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight-300,
      console.log("Height",this.MarketWatchChartHeight)
      window.addEventListener('resize', this.OnResize)
    },
    methods: {
      ...mapActions(['loadIndexData','getUserData']),
      OnResize() {
        // this.$nextTick(()=>{
        //   this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65
        // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeigh
        // })
        this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65,
        this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight-300
        // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeight
      },
      sizeVal(e){
      // console.log("value:",e)
      this.checkSize = e 
    },
    },
    async mounted(){
    this.$store.commit("SET_CATEGORY_FOR_DATES","stock_alert")
    this.$store.commit("ALERT_SELECTED_TABLE_COLUMN","Stock Alert")  
      // await this.getUserData()
    },
   
  };
  </script>
  
  
  
  <style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
  
  span {}
  </style>
  <style>
  .hideScrollbar::-webkit-scrollbar {
    display: none;
  }

  .reportHeight{
    height:34vh;
  }
  .calenderHeight{
    height: 59.5vh;
  }

  @media only screen and (max-width: 1550px) {
  .greenBtn{
    font-size: 10px;
  }
  .reportHeight{
    height:32.5vh;
  }
  .headerPadding{
    padding-right: 0px !important;
  }
  .calenderHeight{
    height: 60.5vh;
  }
}

  </style>