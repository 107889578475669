<template>
    <div v-if="closeModal">
        <tailwind-modal :show-modal="filesaveModal">
            <div class="min-w-[38rem] px-10 py-4" style="
                border: 0.3px solid rgba(2, 189, 45, 0.3);
                box-sizing: border-box;
                scrollbar-color: red;
                border-radius: 3px;
                background-color: #151515;
                ">
                <div class="flex pb-4">SAVE SEARCH</div>
                <div class="text-white px-4 py-2 flex ">
    
                </div>
                <div class="">
                    <div class="grid justify-center">
                        <div class="form-group  mb-2 flex justify-center">
                            <input type="text" placeholder="Select Directory Name" disabled
                                class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-800 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
                        </div>
    
                        <div class="flex gap-2">
                            <div class="form-group  mb-2 flex justify-center">
                                <input type="text" placeholder="File Name" v-model="fileName"
                                    class="p-4 bg-[#151515] block w-44 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
                            </div>
    
                            <div class="dropdown relative order-last w-full flex justify-start items-center">
                                <button
                                    class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap h-[40%] w-[12%] bg-transparent rounded-sm"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" stroke='#151515'
                                        :fill='btn_color' viewBox="0 0 512 512">
    
                                        <path
                                            d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu min-w-max absolute hidden bg-gray-800 text-base z-50  py-2 list-none  rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                                    aria-labelledby="dropdownMenuButton1">
                                    <li v-for="(color,colorindex) in colors" :key='colorindex' :value='colorindex'
                                        class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700 cursor-pointer"
                                        @click="insertColor(color,colorindex)">
                                        <img :src="color" />
                                    </li>
                                </ul>
                            </div>
                        </div>
    
                        <div class="h-20 overflow-y-auto">
                            <textarea placeholder="Description"
                                class=" p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500">
                    </textarea>
                        </div>
                    </div>
    
                </div>
    
    
                <div class="flex justify-end">
                    <button @click="CancelModal()" type="submit"
                        class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                        Close
                    </button>
                    <button @click="SaveFileModal()" type="submit" class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                        Save Changes
                    </button>
                </div>
    
    
            </div>
    
        </tailwind-modal>
    </div>
</template>
<script>
import TailwindModal from "@/components/popups/TailwindModal";
export default {
    name: 'AddFileModal',
    components: { TailwindModal },
    data() {
        return {
            closeModal:false,
            fileName:'',
            colorrr: 'imageslist/black.svg',
            colors: ['imageslist/lightPurple.svg', 'imageslist/purple.svg', 'imageslist/black.svg', 'imageslist/seeGreen.svg', 'imageslist/lightGreen.svg', 'imageslist/green.svg', 'imageslist/lightBlue.svg', 'imageslist/blue.svg', 'imageslist/darkYellow.svg', 'imageslist/yellow.svg', 'imageslist/red.svg'],
            colorsCode: ['#BB6BD9', '#9B51E0', '151515', '#6FCF97', '#27AE60', '#219653', '#56CCF2', '#2D9CDB', '#F2994A', '#F2C94C', '#EB5757'],
            selectedColor: '',
            btn_color: '151515',
        }
    },
    props: {
        filesaveModal: {
            type: Boolean,
            default: false
        },


    },
    methods: {
        insertColor(color, index) {
            this.btn_color = this.colorsCode[index]
        },
        CancelModal() {
            this.$emit('FileCloseModal')
        },
        SaveFileModal(){
            this.closeModal = false
            this.$emit('FileCloseModal')
            this.$emit('SaveFileDataModal', this.btn_color, this.fileName)
        }

    },
    watch:{
        filesaveModal(newval){
            this.closeModal = newval
        }
    }

}

</script>
<style scoped>
.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
    /* width: 20px !important; */
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}
</style>