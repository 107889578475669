// import tableApi from "../api/tableApi";

const ComponentsModule = {
    state: {
        selectedfolderStore :'example',
        alert:false,
        StockSearchObj: {
            folder: [
              {
                id:1,
                name: "Folder Title 1",
                files: {fileName:[],saveData:[]},
              },
    
            ],
          },
    },
    mutations: {
        
        STOCK_SEARH_OBJ(state, payload){
            state.StockSearchObj.folder[0].files.saveData.push(payload)
        },
        SELECTED_FOLDER(state,payload){
            state.selectedfolderStore = payload
            console.log(payload)
        },
        GET_ALERT(state,payload){
            state.selectedfolderStore = payload
            console.log(payload)
        }
    },
  
    actions: {
        selectedfolderAction(context,payload){
            context.commit('SELECTED_FOLDER',payload)
            console.log(payload)
        },
        alertAction(context,payload){
            context.commit('GET_ALERT',payload)
            console.log(payload)
        }
    },
  
    getters: {
    StockSearchObj: (state) => state.StockSearchObj,
    getSelectedFolder:(state)=> state.selectedfolderStore,
    getAlert : (state) => state.alert
    },
  };
  
  export default ComponentsModule;
  