<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-min ">
        <!--content-->
        <div
          style="border: 0.3px solid #7582783d; border-radius: 3px"
          class="border-box border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#151515] text-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-start text-white justify-between p-5 border-b border-solid border-slate-200 rounded-t"
            v-if="showHeader"
          >
            <h3 class="text-white text-3xl font-semibold text-white">
              {{ title }}
            </h3>

            <button
              class="p-1 ml-auto border-0 bg-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              v-on:click="toggleModal()"
            >
              <span
                class="text-white h-6 w-6 text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>

          <!--body-->
          <div class="relative flex-auto text-white">
            <slot />
          </div>
          <!--footer-->
          <template v-if="showFooter">
            <div
              class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
            >
              <button
                class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Close
              </button>
              <button
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Save Changes
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-90 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>
<script>
export default {
  name: "TailwindModal",
  model: {
    prop: "showModal",
    event: "change",
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
  },

  data() {
    return {
      // showModal: false
    };
  },
  methods: {
    toggleModal: function () {
      this.$emit("change");
    },
  },
};
</script>
