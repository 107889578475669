import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import MarketWatch from "../components/pages/MarketWatchPage.vue";
import WatchList from "../components/pages/WatchListPage.vue";
import SignIn from "@/components/pages/SignInPage";
import PlanSelection from "@/components/pages/PlanSelection";
import SignUp from "@/components/pages/SignUpPage";
import OtpPage from "@/components/pages/OtpPage"
import ForgetPassword from "@/components/pages/ForgetPasswordPage";
import MarketHeatPage from "@/components/pages/MarketHeatPage";
import DashboardPage from "@/components/pages/DashboardPage";
import SettingsPage from "@/components/pages/SettingsPage";
import StockListPage from "@/components/pages/StockListPage";
import StockSearchPage from "@/components/pages/StockSearchPage";
import Verify from "@/components/pages/Verify"
// import ChartPage from "@/components/pages/ChartPage";
const ChartPage = () => import("@/components/pages/ChartPage")
// import TopFives from "@/components/pages/TopFivesNew";
import ResetPassword from "@/components/pages/ResetPassword";
import UserDetail from "@/components/pages/UserDetail.vue";
import Test from "@/components/pages/Test.vue";
import ContactUs from '@/components/pages/ContactUs'
import StocklistBeta from '@/components/pages/stocklistBeta/StocklistBeta.vue'
import TopFiveBeta from '@/components/pages/topfivebeta/TopFivesBeta.vue'
import ReportPage from '@/components/pages/Report.vue'
import EmailSent from '@/components/pages/EmailSent'
import TwoFACode from '@/components/pages/TwoFACodePage.vue'
import StockAlertPage from '@/components/pages/StockAlertPage.vue'
//import TreeView from "@/components/pages/Tree";

// import DemoComponent from "../components/DemoComponent.vue";
// import MarketHeatPage from "@/components/pages/MarketHeatPage";
const isLoggedIn = () => {
  return localStorage.getItem("isAuthenticated");
};

const routes = [
  {
    name: "dashboard",
    path: "/dashboard",
    component: DashboardPage,
    children: [
     
      {
        name: "report",
        path: "/report",
        component: ReportPage,
        meta: { requiresAuth: true },
      },

      {
        name: "watchlist",
        path: "/watchlist",
        component: WatchList,
        meta: { requiresAuth: true },
      },

      {
        name: "topfivesbeta",
        path: '/topfivesbeta',
        component: TopFiveBeta,
        meta: { requiresAuth: true }
      },

      {
        name: "marketheat",
        path: "/marketheat",
        component: MarketHeatPage,
        meta: { requiresAuth: true },
      },
      // {
      // name: "DemoComponent",
      // path: "/demo",
      // component: DemoComponent,
      // meta: { requiresAuth: false },
      // },
      {
        name: "marketwatch",
        path: "/marketalert",
        component: MarketWatch,
        meta: { requiresAuth: true },
      },
      {
        name: "settings",
        path: "/settings",
        component: SettingsPage,
        meta: { requiresAuth: true },
      },
      {
        name: "stocklist",
        path: "/stocklist",
        component: StocklistBeta,
        meta: { requiresAuth: true },
      },
      {
        name: "stockalert",
        path: "/stockalert",
        component: StockAlertPage,
        meta: { requiresAuth: true },
      },
      {
        name: "chart",
        path: "/chart",
        component: ChartPage,
        meta: { requiresAuth: true },
      },
      {
        name: "topfives",
        path: "/fives",
        component: TopFiveBeta,
        meta: { requiresAuth: true },
      },
      {
        name: "stocksearch",
        path: "/stocksearch",
        component: StockSearchPage,
        meta: { requiresAuth: true },
      },

    ],
    meta: { requiresAuth: true },
  },
  {
    name: "planSelection",
    path: "/plans",
    component: PlanSelection,
    meta: { requiresAuth: false },
  },
  {
    name: "signin",
    path: "/",
    component: SignIn,
    meta: { requiresAuth: false },
  },
  {
    name: "userdetails",
    path: "/userdetails",
    component: UserDetail,
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: { requiresAuth: false },
  },
  {
    path: "/verification",
    name: "verification",
    component: OtpPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/verify",
    name: "verify",
    component: Verify,
    meta: { requiresAuth: false },
  },
  {
    path: "/verifyEmail",
    name: "EmailSent",
    component: EmailSent,
    meta: { requiresAuth: false },
  },
  {
    path: "/contactus",
    name: "contactus",
    component: ContactUs,
    meta: { requiresAuth: false },
  },
  {
    path: "/recoverpassword",
    name: "forgetpass",
    component: ForgetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/resetpassword",
    name: "resetpass",
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/verify2FA",
    name: "verify2fa",
    component: TwoFACode,
    meta: { requiresAuth: false },
  },
  {
    path: "/testing",
    name: "test",
    component: Test,
    meta: { requiresAuth: false },
  },
  
  
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "is-active",
});

router.beforeEach((to, from, next) => {
  //flag for private public route -->

  if (to.meta.requiresAuth) {
    next();
    //checking is user is logged in -->
    // if (isLoggedIn()) {
    //   next(); // <--moves to desired route
    // } else {
    //   next("/"); // <-- sign up page set as default
    // }
  }
  
  else if (!to.meta.requiresAuth) {
        //checking is user is logged in -->
    if (isLoggedIn()) {
      next('/marketalert'); // <--moves to homepage
    } else {
      next(); // <-- sign up page set as default
    }
  }
  
  else {
    next(); // <-- default page: sign up
  }
});

export default router;
