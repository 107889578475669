import { async } from "trading-vue-js";
import axios from "../axios";
import {DELETE_BREADCRUMB,APPLY_PROMOCODE,CANCEL_SUBSCRIPTION,ADD_BREADCRUMB,GET_BREADCRUMB,UPDATE_CHART_CONFIG, CHANGEPASSWORD,USERDATASTREAM,USERCONTACT,forgetPassword, resetPassword, signIn, signUp, USER_LOGOUT, GETUSER, UPDATEUSER,LINK_EXPIRE_CHECKER, UPDATEPRODUCT,bellIcon,UPDATE_VARIENT,ALERT_COLUMN_FOR_USER, SAVE_INDEX_DATA,SEND_OTP,VERIFY_OTP,miss_email,UPDATE_PHONE, VERIFY_PASSWORD, SEND_EMAIL_TO_CHANGE_EMAIL, VERIFY_CHANGE_EMAIL_CODE, DISABLED_TWO_FA, CHANGE_TWO_FA, VERIFY_TWO_FA} from "./apiRoutes";


export default {
  updateChartConfig: async (payload) => {
    const response = await axios.post(UPDATE_CHART_CONFIG, payload);
    return response;
  },
  userSignUp: async (payload) => {
    const response = await axios.post(signUp, payload);
    return response;
  },
  userSignIn: async (payload) => {
    console.log("signIn url", signIn);
    const response = await axios.post(signIn, payload);
    return response;
  },
  sendOtp: async (payload) => {
    const response = await axios.post(SEND_OTP, payload);
    return response;
  },
  missEmail: async (payload) => {
    const response = await axios.post(miss_email, payload);
    return response;
  },
  verifyOtp: async (payload) => {
    const response = await axios.post(VERIFY_OTP, payload);
    return response;
  },
  updatePhone: async (payload) => {
    const response = await axios.post(UPDATE_PHONE, payload);
    return response;
  },
  forgotPassword: async (payload) => {
    const response = await axios.post(forgetPassword, payload);
    return response;
  },
  resetPassword: async (payload) => {
    const response = await axios.post(resetPassword, payload);
    return response;
  },
  userLogout: async () => {
    const response = await axios.post(USER_LOGOUT);
    return response;
  },
  getUser: async () => {
    const response = await axios.get(GETUSER);
    return response;
  },
  updateUser: async (payload) => {
    const response = await axios.post(UPDATEUSER, payload);
    return response;
  },
  changePassword: async (payload) => {
    const response = await axios.post(CHANGEPASSWORD,payload);
    return response
  },
  
  linkExpireChecker: async (payload)=>{
    const response = await axios.post(LINK_EXPIRE_CHECKER, payload);
    return response;
  },
  updateProduct: async (payload)=>{
    const response = await axios.post(UPDATEPRODUCT, payload);
    return response;
  },
  changeDataStream: async (payload)=>{
    const response = await axios.post(USERDATASTREAM, payload);
    console.log('payload',payload)
    return response;
  },
  contactUs: async (payload)=>{
    const response = await axios.post(USERCONTACT, payload);
    console.log('payload',payload)
    return response;
  },
  bellIconApi:async(payload)=>{
    const response = await axios.post(bellIcon, payload);
    console.log('payload',payload)
    return response;
  },
  varientMarketWatch:async(payload)=>{
    const response = await axios.post(UPDATE_VARIENT, payload);
    console.log('payload',payload)
    return response;
  },
  alertColumnHandler:async(payload)=>{
    console.log(payload);
    const response = await axios.post(ALERT_COLUMN_FOR_USER,payload)
    return response;
  },
  saveIndex:async(payload)=>{
    console.log(payload);
    const response = await axios.post(SAVE_INDEX_DATA,payload)
    return response;
  },
  verifyPassword:async(payload)=>{
    console.log("payload",payload);
    const response = await axios.post(VERIFY_PASSWORD,payload)
    return response;
  },
  sendEmailToChangeEmail:async(payload)=>{
    console.log("payload",payload);
    const response = await axios.post(SEND_EMAIL_TO_CHANGE_EMAIL,payload)
    return response;
  },
  verifyChangeEmailCode:async(payload)=>{
    console.log("payload",payload);
    const response = await axios.post(VERIFY_CHANGE_EMAIL_CODE,payload)
    return response;
  },
  addBreadcrumbList:async(payload)=>{
    const response = await axios.post(ADD_BREADCRUMB,payload)
    return response;
  },
  getBreadcrumbList:async()=>{
    const response = await axios.get(GET_BREADCRUMB)
    return response;
  },

  Enable2FA:async(payload)=>{
    console.log("payload",payload);
    const response = await axios.post(CHANGE_TWO_FA,payload)
    console.log("response",response)
    return response;
  },
  Disable2FA:async(payload)=>{
    console.log("payload",payload);
    const response = await axios.post(DISABLED_TWO_FA)
    return response;
  },
  Verify2FA:async(payload)=>{
    console.log("payload",payload);
    
    const response = await axios.post(VERIFY_TWO_FA,payload)
    return response;
  },
  CancelSubscription:async()=>{
    const response = await axios.get(CANCEL_SUBSCRIPTION)
    return response;
  },

  ApplyPromoCode:async(code)=>{
    const res = await axios.get(`${APPLY_PROMOCODE}/${code}`)
    return res 
  }



};
