
<template>
  <div>
    <div class="maindivsize overflow-y-scroll px-2" style="width: 100%; height: 90vh; ">
      <!-- //////////////////////// -----------MODAL---------------//////////////////////////// -->
      <div>


        <tailwind-modal :show-modal="filtermodalshow">
          <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4">ADD FILTER</div>

            <div class="">
              <div class="flex justify-center ">
                <div class="grow basis-1/12 flex justify-center ">
                  <div class="mb-4 rounded">
                    <select name="selectIndicator" @change="selectIndicator($event)"
                      class="p-2 w-96 rounded-md bg-[#151515] border border-gray-700 text-gray-200 focus:outline-none focus:border-gray-500 leading-4 tracking-wide text-lg">
                      <option value="" disabled selected>Select Indicator</option>
                      <option v-for="(i, index) in indicatorsDropdown" :key="index" :value="index">
                        {{ i.label }}
                      </option>
                    </select>
                  </div>
                </div>




              </div>
              <div class="form-group  mb-2 flex justify-center" v-for="(p, index) in this.activeIndicatorParameters"
                :key="index">
                <div class="" id="input">
                  <input :type="p.type" v-model="p.default"
                    class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
                    :placeholder="p.placeholder" />
                </div>
              </div>
            </div>

            <div class="flex justify-end">
              <button type="submit" @click="closeModal"
                class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Close
              </button>
              <button type="submit" @click="saveChanges()"
                class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm" :disabled="!this.selectedIndicator">
                Save Changes
              </button>
            </div>

          </div>

        </tailwind-modal>

        <tailwind-modal :show-modal="showmodalgetvalues">
          <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4">ADD PARAMETER</div>

            <div class="">
              <div class="flex justify-center">
                <div class="grow basis-1/12 flex justify-center ">
                  <div class=" relative  p-1 flex">

                    <div class="text-white px-4 py-2">Selected Indicator</div>
                    <select name="selectIndicator"
                      class="p-2 w-[213px] rounded-md bg-[#151515] border border-gray-700 text-gray-200 focus:outline-none focus:border-gray-500 leading-4 tracking-wide text-lg">
                      <option value="">{{ this.getIndicator }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group  mb-2 mt-2 flex justify-center"
                v-for="(p, index) in this.activeIndicatorParameters" :key="index">
                <div class="">
                  <input :type="p.type" v-model="p.default"
                    class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
                    :placeholder="p.placeholder" />
                </div>
              </div>


            </div>



            <div class="flex justify-end">
              <button type="submit" @click="closeModal"
                class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Close
              </button>
              <!-- <button type="submit" @click="saveParamChanges" :disabled="!this.getIndicator"
                class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Save Changes -->
              <!-- </button> -->
            </div>

          </div>

        </tailwind-modal>

        <tailwind-modal :show-modal="togglefoldermodel">
          <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4">ADD FOLDER</div>
            <div class="flex flex-center">
              <input v-model="typefolderName" placeholder="Folder Name"
                class="p-4 bg-[#151515] block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
            </div>


            <div class="flex justify-end">
              <button type="submit" @click="closeModal"
                class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Close
              </button>
              <button type="submit" @click="saveFolderChanges" :disabled='!typefolderName'
                class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Save Changes
              </button>
            </div>

          </div>

        </tailwind-modal>

        <tailwind-modal :show-modal="alertModel">
          <div class=" px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4 font-extrabold  text-lg">ALERT</div>
            <div class="flex justify-center text-white">
              Please Select a Folder
            </div>


            <div class="flex justify-center">
              <button type="submit" @click="closeModal"
                class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Cancel
              </button>
              <button type="submit" @click="selectFolder()"
                class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                OK
              </button>
            </div>

          </div>
        </tailwind-modal>




      </div>

      <!-- //////////////////////// -----------HEADER---------------//////////////////////////// -->
      <div class="flex w-full justify-between items-center h-[5%] mb-2">
        <div class="flex items-center gap-3">

          <div class="text-white text-[1.6vw] Inter-Bold">&nbsp;Stock&nbsp;Search</div>

          <span class="text-white font-normal text-[.7vw]">My&nbsp;Favourites&nbsp;|</span>
          <div class=" flex gap-2">
            <img src="images/dot1.svg" alt="dot" class=" w-[.43vw]" />
            <span class="text-white text-[.6vw] font-normal">Search&nbsp;Name</span>
            <img src="images/dot2.svg" alt="dot" class=" w-[.43vw]" />
            <span class="text-white text-[.6vw] font-normal ">Search&nbsp;Name</span>
            <img src="images/dot3.svg" alt="dot" class=" w-[.43vw]" />
            <span class="text-white text-[.6vw] font-normal ">Search&nbsp;Name</span>
          </div>
        </div>

        <img src="images/threedots.svg" class="h-[40%]" alt="dot" />
      </div>



      <!-- /////////////////////-----------TOP 3 COLUMNS---------------/////////////////////////////// -->

      <div class="flex h-[4%]">
        <div class="flex cursor-pointer absolute{!important} z-10 -mb-[.15vh] p-[.8vh] " @click="defaultopenTab" :style="{
          backgroundColor: defaultTab ? '#1E2125' : null,
          borderTop: defaultTab ? '0.3px  solid #7582785c' : null,
          borderLeft: defaultTab ? '0.3px  solid #7582785c' : null,
          borderRight: defaultTab ? '0.3px  solid #7582785c' : null,
          borderRadius: defaultTab ? '4px 4px 0 0' : null,
        }">
          <!-- <span><img src="images/lock.svg" alt="dot" /></span> -->
          <span class="text-white text-[.55vw] font-normal">MY&nbsp;SEARCHES</span>
        </div>
        <div class="flex  cursor-pointer absolute{!important} z-10 -mb-[.15vh] p-[.8vh]" @click="openTab" :style="{
          backgroundColor: activeTab ? '#132918' : null,
          borderTop: activeTab ? '0.3px  solid #7582785c' : null,
          borderLeft: activeTab ? '0.3px  solid #7582785c' : null,
          borderRight: activeTab ? '0.3px  solid #7582785c' : null,
          borderRadius: activeTab ? '4px 4px 0 0' : null,
        }">
          <span class="mr-[.2vw]"><img src="images/lock.svg" class="w-[.7vw]" alt="dot" /></span>
          <span class="text-[.55vw] font-normal text-[#00C92E]">PREMIUM&nbsp;PRE&nbsp;-&nbsp;BUILD&nbsp;SEARCH</span>
        </div>
      </div>

      <div class="flex w-full h-[50%] gap-[.5vw]">
        <!-- /////////////////////----------- COLUMN 1 ---------------/////////////////////////////// -->

        <div v-if="defaultTab" class="
           
             border-[0.3px] border-[#7582785c]
            rounded
            box-border 
            w-[20%]
            pt-[2vh]
            px-[.8vw]
            bg-[#1E2125]
          ">


          <div class="text-white "
          style="display:flex; flex-direction: column; gap:1vh;"
          >
            <div class="flex justify-between ">
              <div class="text-white text-[1vw] font-semibold">My&nbsp;Searches</div>
              <div class="text-white self-center font-bold text-sm cursor-pointer" @click="openModel">+</div>
            </div>

            <form class="px-[.3vw] bg-black w-full h-fit">
              <label for="default-search" class=" text-sm font-medium text-gray-900 sr-only">Search</label>
              <div class="relative ">
                <div class="
                    flex
                    absolute
                    inset-y-0
                    left-0
                    items-center
                    pointer-events-none
                  ">

                  <svg aria-hidden="true" class="w-[.9vw]" fill="none" stroke="#f1f4f7" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>
                <input type="search" id="default-search" v-model="search" class=" 
                    block
                    py-[.5vh]
                    pl-[1vw]
                    w-full
                    text-[.9vw] text-white
                    bg-black
                    border border-black
                    placeholder-[#dae0e4]
                    placeholder-[.7vw]
                    focus:outline-none
                  " placeholder="Search" required />
              </div>
            </form>
            <div class="min-h-[30vh] overflow-y-scroll hideScrollbar">
              <!-- <TreeView :title='obj' :fileType="fileType" :dotsimage='dotsimage' :search="search"
                :searchFunc="searchFunc" :folderName="folderName" :savesearchModel='savesearchModel' @close='closeModal'
                @save='saveModal' @SelectedFile='SelectedFile' @Acc_FolderName = "Acc_FolderName" /> -->

                
              <TreeBrowser v-for="(n,i) of newConfig" :key="i" :node="n" :map="[n.name]"  @onClick="treeEvent"
               :style="[i==treeFolderIndex ? {'border':'.3px solid #7582785c', 'padding':'.2vw'}:{}]"  />
            </div>


          </div>
        </div>

        <div v-if="activeTab" class="
            border-[0.3px] border-[#7582785c]
            rounded
            box-border 
            w-[20%]
            pt-[2vh]
            px-[.8vw]
            bg-[#1E2125]
          " style="background: rgba(2, 189, 45, 0.08)">


          <div class="text-white" style="display: flex; flex-direction: column; gap:.9vh;">

            <div class="flex justify-between ">
              <div class="text-[#00C92E]  text-[1vw] font-semibold">
                Pre&nbsp;-&nbsp;Build&nbsp;Search
              </div>
              <div class="text-white self-center font-bold text-sm cursor-pointer" @click="openModel">+</div>
            </div>

            <form class="px-[.3vw] bg-black w-full h-fit">
              <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
              <div class="relative">
                <div class="
                    flex
                    absolute
                    inset-y-0
                    left-0
                    items-center
                    pointer-events-none
                  ">
                  <svg aria-hidden="true" class="w-[.9vw] text-white dark:text-white" fill="none" stroke="#f1f4f7"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>
                <input type="search" id="default-search" v-model='search' class="
                    block
                    py-[.5vh]
                    pl-[1vw]
                    w-full
                    text-[.9vw]
                     text-white
                    bg-black
                    border border-black
                    placeholder-[#dae0e4]
                    placeholder-[.7vw]
                    focus:outline-none
                  " placeholder="Search" required />
              </div>
            </form>




            <div class="h-[50vh] overflow-y-auto pt-[.5vh]">
              <!-- <TreeView :title='obj' :fileType="fileType" :iconColor='iconColor' :dotsimage='dotsimage' :shortdesc='shortdesc'/> -->
              <TreeView :title='obj' :fileType="fileType" :dotsimage='dotsimage' :search="search"
                :searchFunc="searchFunc" :folderName="folderName" :savesearchModel='savesearchModel' @close='closeModal'
                @save='saveModal' @SelectedFile='SelectedFile' />
            </div>

          </div>
        </div>

        <!-- /////////////////////----------- COLUMN 2 ---------------/////////////////////// -->

        <BuildStockSearch @saveSearch="saveSearch" @ShowHeaderAndSearchBtn="ShowHeaderAndSearchBtn"
          :selectedFileIndex="selectedFileIndex" :obj="obj" :myArray="myArray" :selectedFileData="selectedFileData"
          :showSearch_Add_Btn_BuildSearch="showSearch_Add_Btn_BuildSearch" @SaveFileDataModal="SaveFileDataModal" />

        <!-- /////////////////////----------- COLUMN 3 ---------------///////////////////////// -->
        <div class="
            w-[25%]
            border-[0.3px] border-[#7582785c]
            rounded
            box-border 
            pt-[2vh]
            pb-[1vh]
            px-[.8vw]
            h-full
            
          " style="display:flex; flex-direction:column; justify-content:space-between;">

          <div class="h-full" style="display:flex; flex-direction:column; gap:.9vh;">
            <div class=" font-semibold text-white text-[1vw]">Quick Calc.</div>

            <div class="flex flex-col box-border h-[50%]">
              <table class=" h-full
                      text-center
                      border-[0.3px] border-[#7582785c]
                    ">
                <thead class="border-b border-[#7582785c]">
                  <tr class="">
                    <th scope="col" class="
                              text-[.55vw]
                              font-medium
                              text-white
                             
                              border-r border-[#7582785c]
                            ">
                      Date
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                              
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      Symbol
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                             
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      Open Price
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                             
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      Close Price
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                              
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      %Change
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                            
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      Total ARR
                    </th>
                    <th scope="col" class="
                               text-[.55vw]
                              font-medium
                              text-white
                            
                              border-r border-[#7582785c]
                            ">
                      +Watchlist
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-[0.3px] border-[#7582785c]" v-for="(item,index) in arrayy" :key="index">
                    <td class=" 
                               text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                            ">
                      {{ item.Date }}
                    </td>
                    <td class="
                              
                             
                     text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      {{ item.Symbol }}
                    </td>
                    <td class="
                             
                              text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      {{ item.OpenPrice }}
                    </td>
                    <td class="
                              
                               text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      {{ item.ClosePrice }}
                    </td>
                    <td class="
                              
                               text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      {{ item.Change }}
                    </td>
                    <td class="
                             
                               text-[.6vw]
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                              bg-black
                            ">
                      {{ item.TotalArr }}
                    </td>
                    <td class="
                             h-full
                              flex
                              justify-center
                              items-center
                              font-medium
                              text-white
                              border-r border-[#7582785c]
                            ">
                      <img src="images/star.svg" class="w-[.6vw]" alt="star" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div style="display: flex; flex-direction: column; gap:1.5vh;">
            <div class="
                  ok-button
                  border
                  rounded
                  px-[.8vw]
                  box-border
                  text-[.7vw]
                  font-normal
                  cursor-pointer
                  flex
                  justify-center
                  py-[.4vh]
                ">
              RUN QUICK CALC.
            </div>
            <div class="
                  cancel-button
                  border
                  rounded
                  px-[.8vw]
                  box-border
                  text-[.7vw]
                  font-normal
                  cursor-pointer
                  flex
                  justify-center
                  py-[.4vh]
                ">
              ADD TOP 5 TO WATCHLIST
            </div>
          </div>
        </div>
      </div>

      <!-- ////////////////////----------BOTTOM 2 COLUMNS--------------//////////////////////////////// -->
      <div class="
      p-[1vh]
      mt-[1vh]
          flex
          gap-[.5vw]
          h-[40%]
          w-full
          border-[0.3px]
        border-[#7582785c]
          rounded
          box-border
        ">
        <!-- ////////////////////----------COLUMNS  1--------------//////////////////////////////// -->
        <div class=" box-border h-full w-[45%] py-[.4vh]"
        style="display: flex; flex-direction: column; gap: 1vh; ">



          <div class="flex w-full justify-between items-center h-[15%]">
            <div class="flex items-center gap-3">

              <div class="text-white text-[1.15vw] Inter-Bold">Search&nbsp;Results</div>

              <span class="text-white font-normal text-[.7vw]">My&nbsp;Favourites&nbsp;|</span>
              <div class=" flex gap-2">
                <img src="images/dot1.svg" alt="dot" class=" w-[.43vw]" />
                <span class="text-white text-[.6vw] font-normal">Search&nbsp;Name</span>
                <img src="images/dot2.svg" alt="dot" class=" w-[.43vw]" />
                <span class="text-white text-[.6vw] font-normal ">Search&nbsp;Name</span>
                <img src="images/dot3.svg" alt="dot" class=" w-[.43vw]" />
                <span class="text-white text-[.6vw] font-normal ">Search&nbsp;Name</span>
              </div>
            </div>

            <img src="images/threedots.svg" class="h-[40%]" alt="dot" />
          </div>


          <div class="flex h-[80%] w-full"> 
            <table class="w-full h-full text-center border-[#7582785c]">
              <thead class="border-b border-[#7582785c] w-full">
                <tr class="border border-[#7582785c] w-full h-[3vh]">
                  <th scope="col" class="
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[18%]
                          ">
                    Date
                  </th>
                  <th scope="col" class="
                            text-[9px]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            bg-black
                            w-[14%]
                          ">
                    Symbol
                  </th>
                  <th scope="col" class="
                        text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            bg-black
                            w-[14%]
                          ">
                    Open Price
                  </th>
                  <th scope="col" class="
                        text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            bg-black
                            w-[14%]
                          ">
                    Close Price
                  </th>
                  <th scope="col" class="
                        text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            bg-black
                            w-[14%]
                          ">
                    %Change
                  </th>
                  <th scope="col" class="
                        text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            bg-black
                            w-[14%]
                          ">
                    Total ARR
                  </th>
                  <th scope="col" class="
                        text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[10%]
                          ">
                    +Watchlist
                  </th>
                </tr>
              </thead>
              <tbody class="w-full border-[#7582785c]">
                <tr class="w-full border border-[#7582785c]"
                
                v-for="(item,index) in arrayy" :key="index">
                  <td class="
                            whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[18%]
                          ">
                    {{ item.Date }}
                  </td>
                  <td class="
                            whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[14%]
                            bg-black
                          ">
                    {{ item.Symbol }}
                  </td>
                  <td class="
                           whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[14%]
                            bg-black
                          ">
                    {{ item.OpenPrice }}
                  </td>
                  <td class="
                            whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[14%]]
                            bg-black
                          ">
                    {{ item.ClosePrice }}
                  </td>
                  <td class="
                            whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[14%]
                            bg-black
                          ">
                    {{ item.Change }}
                  </td>
                  <td class="
                            whitespace-nowrap
                            text-[.5vw]
                            font-medium
                            text-white
                            border-r border-[#7582785c]
                            w-[14%]
                            bg-black
                          ">
                    {{ item.TotalArr }}
                  </td>
                  <td style="height:100%;" class="
                            
                              flex
                              justify-center
                              h-full
                              text-center
                              text-white
                              border-r border-[#7582785c]
                            ">
                      <img src="images/star.svg" class="w-[.6vw] h-full" alt="star" />
                    </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="
              text-[#E5E9EC]
              border border-[#7582785c]
              rounded
              text-center
              text-[.45vw]
              font-normal
              opacity-40
              cursor-pointer
              h-[5%]
            ">
            LOAD MORE RESULTS
          </div>
        </div>

        <!-- ////////////////////----------COLUMNS  2--------------//////////////////////////////// -->
        <div class="
            relative
            w-[55%]
            h-full
            border-[0.3px] border-[#7582785c]
            rounded
            box-border
            
          ">
          <div class="flex w-full h-full">
            <div class="
               w-[25%]
               p-[.5vw]
                border-r-[0.3px] border-r-[#7582785c]
                box-border
               
              ">
              <div class="flex justify-between">
                <div class="text-white text-[1vw] Inter-Bold">
                  Selected&nbsp;stock
                </div>
                <img src="images/star2.svg" alt="star" />
              </div>
              <div class="text-[#005500] text-[.5vw] Inter-Bold">
                TICKER SYMBOL
              </div>
              <div class="flex justify-between">
                <div class="text-[.9vw] text-[#02BD2D] font-semibold">238.43</div>
                <div class="
                    w-[4vw]
                    h-[2.2vh]
                    flex
                    justify-center
                    items-center
                    text-[.5vw] text-[#02BD2D]
                    self-center
                    border
                    rounded
                    bg-[#0D541E]
                    border-[#02BD2D]
                  ">
                <span>+5.56 <b class="Inter-Bold">(2.38%)</b></span>  
                </div>
              </div>

              <div class="" style="display: flex; flex-direction: column; gap: 1vh; padding-top: 1.5vh;">
                <div v-for="(a,i) in 8" :key="i" class=" 
                    flex
                    justify-between
                    text-[.5vw]
                    font-normal
                    text-white
                 
                  ">
                  <div class="">Symbol</div>
                  <div class="">TextHere</div>
                </div>
               
                <div class="
                    flex
                    justify-between
                    text-[.5vw]
                    font-normal
                    text-[#02BD2D]
                  ">
                  <div class="">WatchList</div>
                  <div class="">TextHere</div>
                </div>
              </div>
            </div>

            <div class="h-full w-[75%] text-white apexChartdiv" ref="areaChartHeightDiv">
              <area-chart-watch-list :watchListChartHeight="watchListChartHeight"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


//import VFolder from 'v-folder';
import AreaChartWatchList from "../charts/AreaChartWatchList.vue";
import TailwindModal from "../popups/TailwindModal.vue";
import TreeView from '../tree/TreeView.vue';
import { mapGetters } from 'vuex';
import BuildStockSearch from './BuildSearch.vue'
import TreeBrowser from '../tree/TreeBrowser.vue'

export default {
  components: {
    TailwindModal,
    AreaChartWatchList,
    TreeView,
    BuildStockSearch,
    TreeBrowser
    // VFolder

  },
  name: "StockSearchPage",

  data() {
    return {
      // new tree work
      treeFolderIndex:null,
      selectedFolder: '',
      selectedFileData: [],
      nodeObject: {},
      mapArray: [],
      // new tree work
      showSearch_Add_Btn_BuildSearch: false,
      selectedFileIndex: null,
      newRowData: [],
      myObj: {},
      myArray: [],
      i: 1,
      addParamIndex: null,
      splitIndicatorVal: '',
      clickedFolder: false,
      shortdesc: '- short desc...',
      dotsimage: 'images/horizontaldots.svg',
      iconColor: 'text-green-600',
      fileType: 'StockSearch',
      selectedRowFile: [],



      // obj: {
      //   folder: [
      //     {
      //       id: 1,
      //       name: "Folder Title 1",
      //       files: [
      //         {
      //           fileName: "",
      //           fileData: "",
      //           id: 1
      //         },
      //         ]
      //     }
      //   ]
      // },

      uid: 0,
      conf: {
        // tree node name
        node: 'sourceDir',
        // KEY NAME of dirs/branches/parents etc.. .
        branch: 'dirs',
        // KEY NAME of  files/leafs/children etc...
        leaf: 'files'
      },
      data: {
        // root
        sourceDir: 'root',
        // children
        files: ['file1', 'file2', 'file3'],
        dirs: ['folder1', 'folder2', 'folder3']
      },
      // ajax settings
      ajax: {
        method: 'GET',
        url: 'http://localhost:1234',
        params: {},
        data: {},
        headers: {},
        // params key of path
        pathAs: 'path',
        // process response data
        process: (res) => res.data
      },
      watchListChartHeight: this.$refs.areaChartHeightDiv?.clientHeight,
      filtermodalshow: false,
      showmodalgetvalues: false,
      selectedIndicator: '',
      defaultTab: true,
      activeTab: false,
      getIndicator: '',
      getParameter: '',
      getParametersignal1: '',
      getParametersignal2: '',
      getParameterperiod: '',
      paramArray: [],
      typetext: '',
      showfooter: true,
      typefolderName: '',
      folderName: '',
      arrayy: [
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
        {
          Date: "-",
          Symbol: "-",
          OpenPrice: "-",
          ClosePrice: "-",
          Change: "-",
          TotalArr: "-",
          WatchList: "-",
        },
      ],

      //    [starts]   MR suggestion for search component
      searchData: {
        meta: {
          name: "unnamed", //nullable
          location: "folder_name/favourits/", //nullable
        },
        criteria: {
          country: "US",

          technicals: [
            {
              indicator_id: "macd",
              indicator_name: "MACD",
              values: [
                { signal1: 14, signal2: 3, period: 12 },
                // { signal1: 14, signal2: 3, period: 14 },
              ],
            },

          ],
          //fundamentals
        },
      },


      activeIndicatorParameters: [],
      indicatorsDropdown: {
        "macd": {
          label: "MACD",
          description: "lorem ipsum....",
          parameters: [
            {
              id: "period",
              placeholder: "Period",
              type: "int",
              default: 12
            },
            {
              id: "signal_r",
              placeholder: "Signal R",
              type: "int",
              default: 26
            },
            {
              id: "signal_n",
              placeholder: "Signal N",
              type: "int",
              default: 9
            }
          ]
        },
        "roc": {
          label: "ROC",
          description: "lorem ipsum....",
          parameters: [
            {
              id: "period",
              placeholder: "Period",
              type: "int",
              default: 12
            }
          ]
        },
        "rsi": {
          label: "RSI",
          description: "Simple moving average",
          parameters: [
            {
              id: "days",
              placeholder: "Period",
              type: "int",
              default: 10
            }
          ]
        },
        "bband": {
          label: "BBand",
          description: "Simple moving average",
          parameters: [
            {
              id: "days",
              placeholder: "Period",
              type: "int",
              default: 11
            }
          ]
        },
        "myfilter": {
          label: "MyFilter",
          description: "lorem ipsum....",
          parameters: [
            {
              id: "period",
              placeholder: "Period",
              type: "int",
              default: 12
            },
            {
              id: "signal_r1",
              placeholder: "Signal R",
              type: "int",
              default: 26
            }, {
              id: "signal_r2",
              placeholder: "Signal R",
              type: "int",
              default: 26
            }, {
              id: "signal_r3",
              placeholder: "Signal R",
              type: "int",
              default: 26
            }, {
              id: "signal_r4",
              placeholder: "Signal R",
              type: "int",
              default: 26
            }, {
              id: "signal_r5",
              placeholder: "Signal R",
              type: "int",
              default: 26
            },
            {
              id: "signal_n",
              placeholder: "Signal N",
              type: "int",
              default: 9
            }
          ]
        },


      },
      //    [ends]     MR suggestion for search component

      togglefoldermodel: false,
      alertModel: false,
      savesearchModel: false,
      search: '',


      obj: {
        folder: [
          {
            id: 0,
            name: "Folder Title 1",
            files: { fileName: [], },
          },

        ],
      },
      newConfig: [

        // {
        //   name: "Top Five Originals",
        //   type: "main-folder",
        //   saveData: [],
        //   children: [
        //     {
        //       name: "EPS",
        //       filecolor: '',
        //       pageType:'StockSearch',
        //       children: [],
        //       isFile: true,
        //       isIcon: true,
        //       isImage: false,
        //     },
        //   ],
        // },

      ],
    };
  },

  methods: {

    // new tree browser work start

    treeEvent(node, map) {
      this.nodeObject = node
      this.mapArray = map


      for (let i in this.newConfig) {
        if (this.newConfig[i].name == this.mapArray[0]) {
console.log("............", i)
        this.treeFolderIndex = i

          for (let data in this.newConfig[i].children) {
            if (this.newConfig[i].children[data].name == this.mapArray[1]) {
              this.selectedFileData = this.newConfig[i].saveData[(data)]
              this.selectedFolder = this.newConfig[i].children[data].name
              // console.log("file name found is ", this.newConfig[i].children[data].name)
            }
          }
        }
      }

      // console.log("check selected data ",  this.selectedFileData)
      // console.log('node in stock search', node)
      // console.log('map in stock search',  map)
    },
    SaveFileDataModal(fileColor, fileName, fileData) {
      for (let i in this.newConfig) {
        if (this.newConfig[i].name == this.mapArray[0]) {
          this.newConfig[i].children.push(
            {
              name: fileName,
              filecolor: fileColor,
              pageType: 'StockSearch',
              isFile: true,
              children: [],
              isIcon: true,
              isImage: false,
            },
          )
          this.newConfig[i].saveData.push(fileData)
        }
      }

      console.log("this.newConfig..................", this.newConfig,)
      this.showSearch_Add_Btn_BuildSearch = false
      // console.log("file color prop..................",fileColor, )
    },


    // new tree browser work end


    Acc_FolderName(name) {
      this.acc_Folder_Name = name
    },

    openModel() {

      this.togglefoldermodel = true
    },


    saveModal(newFIleName, file_clor_sel) {


      console.log("...............................", this.obj)




      for (let a of this.obj.folder) {
        if (this.acc_Folder_Name == a.name) {
          this.obj.folder[a.id].filecolor.push(file_clor_sel)
          this.obj.folder[a.id].files.fileName.push(newFIleName)
          this.obj.folder[a.id].files.saveData.push(JSON.parse(JSON.stringify(this.newRowData)))

        }
      }
      this.showSearch_Add_Btn_BuildSearch = false
      // for(let a in this.obj.folder )
      // {
      //   if(this.obj.folder[a].name == selectedFolderName){
      //     break;
      //   }owais

      // this.$store.commit('STOCK_SEARH_OBJ', this.obj.folder[0].files.saveData[this.obj.folder[0].files.saveData.length - 1])
      // console.log(this.obj.folder[0].files.saveData)
      // else if(a == this.obj.folder.length -1){
      //   alert('sorry this directory is not exist')
      // }
      // }
      // console.log("......",this.obj.folder[0].files)
      // console.log("file name ", newFIleName, "select folder name ", selectedFolderName) 

      // let newFile={
      //   id:0,
      //   fileName:newFIleName,
      //   fileData:""
      // }
      // this.obj.folder[0].files.push(newFile)


      this.savesearchModel = false
    },

    saveFolderChanges() {

      this.togglefoldermodel = false
      this.folderName = this.typefolderName

      this.newConfig.push({
        name: this.folderName,

        saveData: [],
        children: [],
        type: 'main-folder'
      })

      // let a = 
      // this.obj.folder.push({

      //   id: this.obj.folder.length,
      //   name: this.folderName,
      //   filecolor:[],
      //   files: { fileName: [], saveData: [] },

      // })

    },


    deleteRow(idd) {

      let a = this.searchData.criteria.technicals.splice(idd, 1)

    },
    editFilterModal(indicator, parameter, index) {

      this.showmodalgetvalues = true
      this.getIndicator = indicator
      this.getParameter = parameter.values
      this.addParamIndex = index
      this.activeIndicatorParameters = this.indicatorsDropdown[this.searchData.criteria.technicals[this.addParamIndex].indicator_id].parameters

    },
    deleteParameter(params, idd) {

      let a = params.splice(idd, 1)

    },
    showFilterModal() {

      this.filtermodalshow = true
    },




    saveSearch(selectedRowData) {
      this.newRowData = selectedRowData
      this.savesearchModel = true

      // if (this.newConfig.length > 0) {
      //   this.newConfig[0].children.push({
      //     name: 'test file',
      //     children: [],
      //     isFile: true,
      //     isImage: false,
      //     isIcon: true
      //   })

      // }

    },


    selectIndicator($event) {

      if (this.indicatorsDropdown[$event.target.value] === undefined)
        return

      this.activeIndicatorParameters = this.indicatorsDropdown[$event.target.value].parameters

      this.selectedIndicator = $event.target.options[$event.target.options.selectedIndex].text


      this.indicatorsDropdown[$event.target.value].parameters.map((z) => {

        this.getParametersignal1 = z.default

        this.paramArray.push(this.getParametersignal1)


      })
    },


    saveChanges() {



      this.filtermodalshow = false
      // new work

      // for (let a of this.activeIndicatorParameters) {
      //   // this.searchData.criteria.technicals[0].values.push({id: a.default})
      //   this.myObj[a.id] = JSON.parse(a.default)
      // }
      // // this.searchData.criteria.technicals[0].values.push(this.myObj)
      // this.searchData.criteria.technicals.push({ values: [this.myObj] })
      // this.myObj = {}
      // console.log("this.selectedIndicator ", this.selectedIndicator.toLowerCase())
      // this.searchData.criteria.technicals[this.i].indicator_id = this.selectedIndicator.toLowerCase(),
      //   this.searchData.criteria.technicals[this.i].indicator_name = this.selectedIndicator,

      //   this.i = this.i + 1



      // old work

      // console.log("this.indicator_id: ", this.indicatorsDropdown)
      // values[0].signal2 = this.indicatorsDropdown.macd.parameters[1].default 
      // values[0].period = this.indicatorsDropdown.macd.parameters[2].default 
      // }

      // this.searchData.criteria.technicals[0].values[0].signal2 = this.indicatorsDropdown.macd.parameters[1].default 


      // this.searchData.criteria.technicals[0].values[0].period = this.indicatorsDropdown.macd.parameters[2].default 


      // }
      // }


      // console.log("saveChanges")
      // this.searchData.criteria.technicals.map((item) => {
      //   if (item.indicator_name == this.selectedIndicator) {
      //     //  console.log("values",item)
      //     return
      //   }
      // })
      // this.searchData.criteria.technicals.push({
      //   indicator_name: this.selectedIndicator,
      //   values: [  //this.paramArray
      //     {
      //       //   signal1 : this.typetext ? this.typetext : null,
      //       // signal2 : this.typetext ? this.typetext : null,
      //       // period : this.typetext ? this.typetext : null, 

      //       signal1: this.paramArray[0],
      //       signal2: this.paramArray[1],
      //       period: this.paramArray[2],
      //       //  signal1 :   this.paramArray[1].default ? 'hello' : 'heyyyy',
      //       //   signal2 : this.paramArray[2].default ? 'hello' : 'heyyyy',
      //       //   period : this.paramArray[3].default ? 'hello' : 'heyyyy',
      //     },
      //   ]
      // })

      // this.selectedIndicator = ''
      // this.selectedIndicator = ''
      //  this.getParametersignal1 = ''
      //   this.getParametersignal2  = ''
      //   this.getParameterperiod = ''

    },
    ShowHeaderAndSearchBtn() {
      this.showSearch_Add_Btn_BuildSearch = true
    },

    // saveParamChanges(item) {
    //   console.log("saveParamChanges", item)

    //   // console.log(this.getParameter)
    //   // New Work
    //   // ADDED OBJECT : this.getParameter  
    //   // selected index: this.addParamIndex
    //   // this.searchData.criteria.technicals[this.addParamIndex].values.push(this.getParameter)
    //   this.myObj = {}

    //   this.activeIndicatorParameters = this.indicatorsDropdown[this.searchData.criteria.technicals[this.addParamIndex].indicator_id].parameters
    //   // console.log("check it necessary ",this.indicatorsDropdown[this.searchData.criteria.technicals[this.addParamIndex].indicator_id])
    //   // this.searchData.criteria.technicals[this.addParamIndex]
    //   console.log("this.activeIndicatorParameters  ", this.activeIndicatorParameters)
    //   for (let a of this.activeIndicatorParameters) {
    //     this.myObj[a.id] = JSON.parse(a.default)
    //   }
    //   // console.log("myObj ", this.myObj)
    //   console.log("addParamIndex ", this.addParamIndex)



    //   // this.searchData.criteria.technicals[this.addParamIndex].values.push(this.myObj)

    //   this.myObj = {}










    //   this.showmodalgetvalues = false
    //   // Old Work
    //   // this.searchData.criteria.technicals.map((item) => {
    //   //   // 
    //   //   if (this.selectedIndicator == 'MACD') {
    //   //     item.values.push({
    //   //       signal1: this.indicatorsDropdown.macd.parameters[0].default,
    //   //       signal2: this.indicatorsDropdown.macd.parameters[1].default,
    //   //       period: this.indicatorsDropdown.macd.parameters[2].default

    //   //     })
    //   //     console.log("MACD", item)
    //   //   }
    //   //   else if (this.selectedIndicator == 'ROC') {
    //   //     console.log("values", item)
    //   //     item.values.push({
    //   //       period: this.indicatorsDropdown.roc.parameters[0].default,

    //   //     })
    //   //   }
    //   //   else if (this.selectedIndicator == 'RSI') {
    //   //     console.log("values", item)
    //   //     item.values.push({
    //   //       period: this.indicatorsDropdown.rsi.parameters[0].default,

    //   //     })
    //   //   }
    //   //   else if (this.selectedIndicator == 'BBand') {
    //   //     console.log("values", item)
    //   //     item.values.push({
    //   //       period: this.indicatorsDropdown.bband.parameters[0].default,

    //   //     })
    //   //   }
    //   // })


    // },
    closeModal() {

      this.filtermodalshow = false
      this.showmodalgetvalues = false
      this.togglefoldermodel = false
      this.alertModel = false
      this.savesearchModel = false
      // buil search search btn
      this.showSearch_Add_Btn_BuildSearch = true
    },


    openTab() {

      this.activeTab = true;
      this.defaultTab = false;
    },
    defaultopenTab() {
      ;
      this.defaultTab = true;
      this.activeTab = false;
    },
    onResize() {
      this.watchListChartHeight = this.$refs?.areaChartHeightDiv?.clientHeight - 10
      this.$nextTick(() => {
        this.watchListChartHeight = this.$refs?.areaChartHeightDiv?.clientHeight - 10
      })
    },




    SelectedFile(index) {

      this.selectedFileIndex = index
      this.myArray.push(index)


    },

    selectFolder() {

      this.alertModel = false
    },

  },
  computed: {
    ...mapGetters(['StockSearchObj']),
    compVal: {
      get() {
        // console.log(this.paramArray.map(i => i))
        // return  `${this.paramArray.map(i => i)}`

        return `${this.activeIndicatorParameters.map(i => i.default)}`

      },
      set(val) {

        this.typetext = val
        return `${this.typetext}`
      },
    },
    searchFunc() {
      return this.obj.folder.filter((item) => {
        return (item.name.toLowerCase().includes(this.search.toLowerCase()))
      })
    }
  },
  watch: {
    search() {
      // console.log("hello")
      let a = this.obj.folder.filter((item) => {
        return (item.name.toLowerCase().includes(this.search.toLowerCase()))
      })

    }

  },
  mounted() {

    this.watchListChartHeight = this.$refs?.areaChartHeightDiv?.clientHeight - 10;
    window.addEventListener("resize", this.onResize);

  }

};
</script>

<style scoped>
.add-button {


  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

/* @media screen and (max-width: 1400px) {
  .formsize {
    display: grid;
  }
} */

@media screen and (min-width: 1200px) {
  .maindivsize {
    width: 100%;
  }
}
</style>
