<template>
  <div
    style="
      width: 10.5vw;
     

      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      border-radius: 6px;
    "
    ref="dialsDivRef"
    class="h-full"
  >
    <div
      style=""
      class="text-gray-100 flex justify-center not-italic font-semibold text-lg"
    >
      <h1 style="background: rgb(21, 21, 21); font-size: 1vw; padding-inline: 5px;">
        Market Alert Status
      </h1>
    </div>
    <div class="w-[10.5vw] h-[25vh] flex justify-center items-center">
      <gauge-chart
        :curves="curves"
        type="large"
        :id="1"
        :currentValue="currentvaluessd"
        :radius="DialsDivHeight / 2.98"
        :shadowRadius="DialsDivHeight / 4"
        :shadowWidth="DialsDivHeight / 6"
      >
        <!-- owais comment -->
        <!-- <gauge-chart
      :curves="curves"
      type="large"
      :id="1"
      style="left: 14px; top: 10px"
      :currentValue="currentvaluessd"
      :radius=" 110 - DialsDivHeight"
      :shadowRadius="90 -( DialsDivHeight / 1.1)"
      :shadowWidth="35 - (DialsDivHeight / 1.1)"
    > -->
        <template v-slot:label>
          <div class="no-action">
            <span class="label" v-if="currentvaluessd">
              <span class="text-[#FF0A00]" v-if="currentvaluessd <= 33">
                <p class="label text-[1vw] Inter-Bold"><b>Market</b></p>
                <p class="label text-[1vw] Inter-Bold">Alert</p>
              </span>
              <span v-if="currentvaluessd > 33 && currentvaluessd <= 66">
                <p class="label text-[1vw] Inter-Bold"><b>MBA</b></p>
                <p class="label text-[1vw] Inter-Bold">Alert</p>
              </span>
              <span v-if="currentvaluessd > 66">
                <p class="label text-[1vw] Inter-Bold"><b>No</b></p>
                <p class="label text-[1vw] Inter-Bold">Alert</p>
              </span>
              <!-- <span class="label" >Market Alert</span> -->
              <!-- <span class="label" >MBA Alert</span> -->
              <!-- {{ currentvaluessd }}% -->
            </span>
            <span class="label text-[1vw] Inter-Bold" v-else>NA</span>
            <!-- {{currentvaluessd}}% -->
          </div>
        </template>
      </gauge-chart>
    </div>
  </div>
</template>

<script>
import GaugeChart from "../guage/GaugeChart.vue";
import { mapGetters } from "vuex";

export default {
  name: "MarketAlertStatus",
  components: {
    "gauge-chart": GaugeChart,
  },
  props: ["cvalue"],
  data() {
    return {
      dialColors: {
        red: { dark: "#950600", light: "#FF0A00" },
        green: { dark: "#009300", light: "#00C92E" },
        yellow: { dark: "#cfae02", light: "#FED500" },
        indigo: { dark: "#56016B", light: "#A900D2" },
      },
      DialsDivHeight: this.$refs.dialsDivRef?.clientWidth / 17,
      red1: 0,
      red2: 33,
      yellow1: 33,
      yellow2: 66,
      green1: 66,
      green2: 100,
      r1: 0,
      r2: 0,
      y1: 0,
      y2: 0,
      g1: 0,
      g2: 0,

      zones: {
        red: [0, 33],
        yellow: [33, 66],
        green: [66, 100],
      },
      /**
       * @Todo Calculate mid point values on component load, currently it is hard-coded
       */
      midPoints: {
        red: [11.0, 22.0],
        yellow: [44.0, 55.0],
        green: [77.33, 77.33],
      },
      currentvaluessd: 0,
      // curves: [
      //   {
      //     percent: 0.75,
      //     color: "#FF0A00",
      //     color2: "#950600",
      //     y1: "80%",
      //     y2: "-40%",
      //   },
      //   {
      //     percent: 0.5,
      //     color: "#FED500",
      //     color2: "#FFEE95",
      //     x1: "50%",
      //     x2: "100%",
      //   },
      //   {
      //     percent: 0.25,
      //     color: "#00C92E",
      //     color2: "#009300",
      //     y1: "90%",
      //     y2: "-40%",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters(["clickedRowStore"]),
    curves() {
      return this.isMarketAlert()
        ? [
            {
              percent: 0.75,
              gradienColor: this.dialColors.red, //this.isMarketAlert()
              // ? this.dialColors.red
              // : this.dialColors.yellow,
              // color: this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#FF0A00",
              // color2: this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#950600",
              y1: "80%",
              y2: "-40%",
            },
          ]
        : [
            {
              percent: 0.75,
              gradienColor: this.dialColors.red, //this.isMarketAlert()
              // ? this.dialColors.red
              // : this.dialColors.yellow,
              // color: this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#FF0A00",
              // color2: this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#950600",
              y1: "80%",
              y2: "-40%",
            },
            {
              percent: 0.5,
              gradienColor: this.dialColors.yellow, //this.isMarketAlert()
              // ? this.dialColors.red
              // : this.dialColors.yellow,
              // color: this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#FED500",
              // color2:this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#FFEE95",
              x1: "50%",
              x2: "100%",
            },
            {
              percent: 0.25,
              gradienColor: this.dialColors.green, //this.isMarketAlert()
              // ? this.dialColors.red
              // : this.dialColors.green,
              // color:this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#00C92E",
              // color2:this.clickedRowStore.alerts =="Market Alert" ? "#FF0A00": "#009300",
              y1: "90%",
              y2: "-40%",
            },
          ];
    },
  },
  mounted() {
    this.DialsDivHeight = this.$refs.dialsDivRef?.clientWidth;
    window.addEventListener("resize", this.OnResize);
  },
  watch: {
    // cvalue: function (newVal, oldVal) {
    //   // watch it
    //   console.log("Prop changed: ", newVal, " | was: ", oldVal);
    //   this.currentvaluessd = parseInt(newVal);
    // },

    clickedRowStore() {
      this.r1 = parseInt(this.red1 + (this.red2 - this.red1) * (1 / 3));
      this.r2 = parseInt(this.red1 + (this.red2 - this.red1) * (2 / 3));

      this.y1 = parseInt(this.red2 + (this.yellow2 - this.yellow1) * (1 / 3));
      this.y2 = parseInt(this.red2 + (this.yellow2 - this.yellow1) * (2 / 3));

      this.g1 = parseInt(this.yellow2 + (this.green2 - this.green1) * (1 / 3));
      this.g2 = parseInt(this.yellow2 + (this.green2 - this.green1) * (2 / 3));

      switch (this.handleStringFomat(this.clickedRowStore.alerts)) {
        case "market_alert":
          if (this.clickedRowStore.market_direction == "Rising") {
            this.currentvaluessd = this.r2;
          }
          if (this.clickedRowStore.market_direction == "Falling") {
            this.currentvaluessd = this.r1;
          }
          break;

        case "mba_down":
          // if (this.clickedRowStore.alerts == "MBA Down") {
          if (this.clickedRowStore.market_direction == "Rising") {
            this.currentvaluessd = this.y2;
          }
          if (this.clickedRowStore.market_direction == "Falling") {
            this.currentvaluessd = this.y1;
          }
          // }
          break;

        case "no_alert":
          // if (this.clickedRowStore.alerts == "No Alert") {
          if (this.clickedRowStore.market_direction == "Rising") {
            this.currentvaluessd = this.g2;
          }
          if (this.clickedRowStore.market_direction == "Falling") {
            this.currentvaluessd = this.g1;
          }
          // }
          break;
      }
    },
  },
  methods: {
    handleStringFomat(value) {
      let trimmedVal = value.replaceAll(" ", "_").toLowerCase();
      return trimmedVal;
    },
    isMarketAlert() {
      return (
        this.clickedRowStore.alerts == "Market Alert"
        // .toString()
        // .toUpperCase()
        // .replaceAll(" ", "_") == this.str_market_alert
      ); //"Market Alert"
    },
    OnResize() {
      this.DialsDivHeight = this.$refs.dialsDivRef?.clientWidth;
    },
  },
};
</script>

<style scoped>
.no-action {
  font-family: "Inter";
  font-style: normal;
  font-size: 30px;
  /* line-height: 36px; */
  text-align: center;
  color: #f8f9f9;
}

.statusHeight{
  height: 27.3vh;
}

@media only screen and (max-width: 1600px) {
  .statusHeight {
    height: 27.1vh;
  }
}
</style>