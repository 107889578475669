
const MarketHeatModule = {
  state: {
   topFive_StarData:[]
  },

  mutations: {
   
   TOP_FIVE_STAR_DATA(state, payload){
    state.topFive_StarData = payload
   
   }
  },

  getters: {

    topFive_StarData: state => state.topFive_StarData
  },
};

export default MarketHeatModule;
