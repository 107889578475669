<template>
  <div class="ml-[1vw]" >
    <div :style="{ 'margin-left': `-${depth * 2}px` }" 
      class="flex justify-between items-center pr-[1vw]"
      >
      <span  class="type text-white">
        <!-- {{ expanded ? '<font-awesome-icon class="w-[.4vw] text-white" icon="fa-solid fa-angle-down" />' : '<font-awesome-icon class="w-[.4vw] text-white" icon="fa-solid fa-angle-right" />'}} -->
        <span v-if="hasChildren && expanded">
          <font-awesome-icon :style="getIconStyle(node)" class="w-[.4vw] text-white" v-if="!node.isFile"
                             icon="fa-solid fa-angle-down"/>
          <font-awesome-icon class="w-[.8vw] mx-2" v-if="isFolderType && !node.isFile"
                             icon="fa-solid fa-folder-open"/>
        </span>
        <span v-if="hasChildren && !expanded">
          <font-awesome-icon :style="getIconStyle(node)" class="w-[.4vw] text-white" v-if="!node.isFile"
                             icon="fa-solid fa-angle-right"/>
          <font-awesome-icon class="w-[.8vw] mx-2" v-if="isFolderType && !node.isFile"
                             icon="fa-solid fa-folder"/>
        </span>

        <span v-if="isFileType && node.isIcon" :style="getFileStyle(node)" class="align-middle">
          <span class="" v-if="node.pageType == 'StockSearch'">
            <svg xmlns="http://www.w3.org/2000/svg" stroke='#2D9CDB' :fill="this.node.filecolor"
                 viewBox="0 0 512 512" class="inline mb-[.15vw] mx-[.1vw] w-[1vw] h-[1vh]">
  <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license 
  (Commercial License) Copyright 2022 Fonticons, Inc. -->
  <path
      d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"/>
  </svg>
          </span>
          <font-awesome-icon class="w-[.4vw]" :class="[isFileColor ? ['text-[#2D9CDB]'] : 'text-[#F8F9F9]']"
                             icon="fa-solid fa-bars-staggered"/>
                             

        </span>

          <span v-if="isFileType && iconColorComp" :style="getFileStyle(node)">
<!--          <img :src="iconImg" class="w-4 h-4 inline-flex"/>-->
            <SvgListIcon  class="inline-flex w-3 h-3" :active="isActiveColor" :fill-color="iconColorComp"/>
        </span>

        <button  :ref="'e_'+id" @click="nodeClicked($event.target)" class="title text-[.78vw] align-middle cursor-pointer" :disabled="node.role == 'PREMIUM' && signedInUser.role=='plus'"  :style="[getStyle(), node.role == 'PREMIUM' ? {color:'#02BD2D'}:{color:null},]">{{ node.name }}</button>
     
      </span>
      
      <CardButtons v-if="node.role == 'PREMIUM' && signedInUser.role=='premium'" btnType="premium-unlock" class="w-fit"  btnText="Premium" />
      <CardButtons v-if="node.role == 'PREMIUM' && signedInUser.role=='plus'" btnType="premium-lock" class="w-fit"  btnText="Premium" />

      <!-- <button v-if="node.role"
        class="bg-transparent ml-6 my-[4px] text-[10px] h-4 border border-solid text-[#00C92E] border-[#00C92E] py-[2px] px-2 inline-flex items-center rounded float-right mr-[.5vw]">
        <font-awesome-icon class="text-[#00C92E] mr-2" icon="fa-solid fa-lock" />
        <span class="text-[.4vw]">{{ node.role }}</span>
      </button> -->
    </div>

    <TreeBrowser :id="child.id" :ref="child.id" v-for="(child,i) in node.children" :key="i" :node="child"
                 :depth="depth + 1"
                 :map="[...map,child.name]"
                 :idMap="[...idMap,child.id]"
                 :criteria="{...criteria,...child.criteria}"
                 @onClick="(n,mapProp,criteriaProp,idProp,idMapProp) => $emit('onClick', n,mapProp,criteriaProp,idProp,idMapProp)"
                 :style="expandable"
                 @treeLoad="treeLoad"
    />
  </div>

</template>

<script>
import {Tree_event} from "@/components/tree/tree_event";
import SvgListIcon from "@/components/tree/SvgListIcon";
import CardButtons from '@/components/buttons/CardButtons.vue'
import {mapGetters} from 'vuex'


export default {
  name: "TreeBrowser",
  components: {SvgListIcon, CardButtons},
  props: {
    node: Object,
    map: Array,
    idMap: Array,
    depth: {
      type: Number,
      default: 0,
    },
    mainNode: {
      type: Boolean,
      default: false,
    },
    criteria: {
      type: Object,
      default(){
        return {}
      },
    },
    id:[Number, String],
  },
  data() {
    return {
      borderIs: '',
      expanded: false,
      isActiveColor: false,
      isFileColor: false,
      childNodes: [],
      nodeRef:null
    };
  },

  methods: {
    treeLoad(id){
      this.childNodes.push(id)
    },
    fireOnClick() {
      this.$emit("onClick", this.node, this.map, this.criteria, this.id, this.idMap);
    },
    nodeClicked(e) {

console.log("clicked node role ", this.node.role)
      if(this.node.type === 'folder'){
        this.expanded = !this.expanded;
        this.fireOnClick();
      }else{
        if(!this.expanded){
          this.expanded = true;
          // if(e !== undefined)
          this.fireOnClick();
          if (!this.hasChildren) {
          }
          if (this.node.isFile) {
            this.isFileColor = !this.isFileColor;
          }

          if (this.isFileType) {
            //  console.log(this.node.name)
            this.isActiveColor = true;
          }
        }else{
          if(this.node.children && this.node.children.length > 0){
            this.expanded = !this.expanded;
            // if(e !== undefined)
            this.fireOnClick();
          }
        }
      }
      
      
    },

    getIconStyle() {
      if (this.node.isRole === false) {
        return {
          color: "#02BD2D",
        };
      }
    },

    getStyle() {
      if (this.node.isRole === false) {
        return {
          color: "#02BD2D",
          marginLeft: "10px",
        };
      }
      
      if (this.isFileType) {
        if (!this.isActiveColor) {
          return {
            color: "gray",
            marginLeft: "10px",
          };
        } else {
          return {
            color: "white",
            marginLeft: "10px",
          };
        }
      }

      return {
        color: "white",
      };
    },

    getFileStyle() {
      if (this.node.isFile) {
        return {
          marginLeft: `-${this.depth * 7}px`,
        };
      }
    }
  },
  computed: {
    ...mapGetters(["signedInUser"]),

    iconColorComp(){
      let iconColorComp = this.node.favColor ? this.node.favColor : this.node.iconColor;
      // console.log('iconColorComp',iconColorComp)
      return iconColorComp
    },
    isFileType(){
      return this.node.type === 'file'
    },
    isFolderType(){
      return this.node.type === 'folder'
    },
    expandable(){
      return this.expanded ? {  } :{ display:'none' }
      // return this.node?.expanded ? {  } :{ display:'none' }
    },
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
  },
  mounted() {
    // console.log("role",this.signedInUser.role)
    this.nodeRef = this.$refs['e_'+this.id]
    // console.log('treeLoad',this.id,this.depth)
    this.$emit('treeLoad',this.id)
    Tree_event.$on('disableTree', (eventId) => {
      let idsNotMatched = this.id !== eventId;
      let isChildren = this.childNodes.includes(eventId)
      let fileType = this.node?.type === 'file';
      if(idsNotMatched && fileType && !isChildren){       
        // console.log('disableTree this.id',this.id)
        this.isActiveColor = false;
        this.expanded = false;
      }      
    })
  }
};
</script>

<style scoped>
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  /* line-height: 15px; */
}
.btnDisabled{
  pointer-events: none;
 
}
</style>
