<template>
  <div class="text-[#F8F9F9] w-full">
    <div class="w-[317px] h-[40px] ml-4">
      <span class="xl:text-[25px] lg:text-[20px] md:text-[18px] Inter-Bold text-[#F8F9F9]">Account Settings</span>
    </div>
    <div class="h-[90vh] pb-1 ml-4 flex space-x-6">
      <div class="w-5/12 overflow-y-auto rounded-md py-2 px-6 bg-[#2E3238]">
        <div class="w-full h-[40px] flex items-center space-x-2">
          <span class="lg:text-[21px] md:text-[14px] Inter-Bold text-[#F8F9F9]">Account Details</span>
          <!-- <span v-if="usererror" class="text-[14px] text-[#FF0A00]">{{usererror}}</span>
          <span v-if="usersuccess" class="text-[14px] text-[#02BD2D]">{{usersuccess}}</span> -->
        </div>
        <div class="text-[#E5E9EC]" :class="disabled ? 'divDisabled' : ''">
          <div class="w-full space-x-4 justify-between items-center h-auto flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px] ">First Name: *</span>
            </div>
            <div class="w-8/12 h-[50px]">
              <div class="flex flex-col">
                <input type="text" v-model="userInfo.firstName" placeholder="First name"
                  class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none rounded-md w-full h-[40px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px]">
                <span v-if="userError.firstName.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                  userError.firstName.message }}</span>
              </div>
            </div>
          </div>

          <div class="w-full justify-between items-center flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px]">Last Name: *</span>
            </div>
            <div class="w-8/12 h-[50px]">
              <input type="text" v-model="userInfo.lastName" placeholder="Last name"
                class="xl:text-[14px] lg:text-[14px] md:text-[12px] outline-none xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px] rounded-md w-full h-[40px] bg-[#1E2224] border-[0.3px] border-solid border-[#758278] py-2 px-4 hover:bg-[#2E3238]">
              <div class="flex flex-col">
                <span v-if="userError.lastName.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                  userError.lastName.message }}</span>
              </div>
            </div>
          </div>
          <div class="w-full justify-between items-center h-auto flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px]">Username: *</span>
            </div>
            <div class="w-8/12 h-[50px] flex items-center justify-between">
              <div class="flex justify-between items-center space-x-2 ">
                <p> {{ signedInUser?.username ? signedInUser?.username : 'Username not available ' }}</p> <span
                  class="text-xs">( Usernames are permanent - <a class="text-[#02BD2D]"
                    href="https://docs.marketalertpro.com/web-application/faqs/can-i-change-my-username"
                    target="_blank">Why?</a> )</span>
              </div>
            </div>
          </div>
          <div class="w-full justify-between items-center h-auto flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px]">Email: *</span>
            </div>
            <div class="w-8/12  -w-full h-[50px] flex items-center justify-between">
              <div class="w-10/12  break-words">
                {{ getEmail() }}
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" @click="emailChangeModal = true" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 cursor-pointer hover:text-gray-200 text-gray-400">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </div>
          </div>
          <div class="w-full justify-between items-center h-auto flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px]">Phone: </span>
            </div>

            <div class="w-8/12 h-[50px] flex items-center justify-between">
              <div class="flex">
                <span v-if="signedInUser?.phoneCountry">
                  <img :src="require(`../../assets/flag/${getFlag()}`)" class="w-6 h-6 mr-2" />
                </span>

                {{ getPhone() }}

              </div>
              <svg xmlns="http://www.w3.org/2000/svg" @click="phoneModal = true" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor"
                class="w-5 h-5 cursor-pointer hover:text-gray-200 text-gray-400">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </div>
            <!-- <div class="w-8/12 h-fit bg-[#1E2224] hover:bg-[#2E3238] rounded">
              <VuePhoneNumberInput  autocomplete="false" :no-example="true" @update="(item)=>handleInput(item)"  :default-country-code="countryCode" v-model="signedInUser.phone" :only-countries="countries"  :dark = "true" size="xl:lg sm" class="cursor-auto" /> 
                <div class="flex flex-col bg-[#2E3238]">
                  <span v-if="userError.phone.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{userError.phone.message}}</span>
                </div>
            </div> -->
          </div>

          <!-- <div class="w-full justify-between space-x-2 items-center h-auto flex">
          <div class="w-3/12 h-[23px]">
            <span class="whitespace-nowrap xl:text-[14px] lg:text-[14px] md:text-[12px]">Profile Image:</span>
          </div>
          <div class="w-8/12 h-auto space-x-4 flex">
            <div class="bg-[#1E2224] flex items-center justify-center w-3/12 h-[80px]" id="imagePreview">
              <img v-if="userInfo?.imagePreview" :src="userInfo?.imagePreview" alt="Image Preview" />
              <svg v-else width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" id="defaultIcon">
                <path d="M29.3101 11V10.68C29.2419 7.97099 28.1048 5.39896 26.1469 3.52541C24.189 1.65187 21.5695 0.628958 18.8601 0.679999C16.8423 0.663534 14.8627 1.23052 13.1595 2.31273C11.4564 3.39493 10.1023 4.94625 9.2601 6.78C6.8889 7.04451 4.69727 8.17016 3.10098 9.94341C1.50469 11.7167 0.614785 14.0141 0.600098 16.4C0.60272 17.6642 0.854478 18.9154 1.34098 20.0822C1.82748 21.249 2.53918 22.3085 3.4354 23.2001C4.33162 24.0916 5.39477 24.7978 6.56409 25.2783C7.7334 25.7587 8.98594 26.0039 10.2501 26H14.0001V24H10.2501C8.31724 23.8954 6.49589 23.0619 5.15319 21.6677C3.81048 20.2734 3.04625 18.4219 3.01454 16.4865C2.98284 14.5511 3.68601 12.6756 4.98232 11.238C6.27863 9.80052 8.0717 8.90787 10.0001 8.74H10.6701L10.9001 8.11C11.5203 6.49913 12.6201 5.11748 14.0508 4.15176C15.4815 3.18603 17.1742 2.68277 18.9001 2.71C21.0791 2.6588 23.1901 3.47096 24.7729 4.96949C26.3557 6.46801 27.2821 8.53141 27.3501 10.71C27.3639 10.9765 27.3639 11.2435 27.3501 11.51L27.2701 12.23L27.9201 12.53C29.1773 13.0964 30.201 14.0785 30.8191 15.3111C31.4371 16.5436 31.6118 17.9515 31.3137 19.2977C31.0155 20.644 30.2628 21.8465 29.1822 22.7029C28.1015 23.5593 26.7588 24.0173 25.3801 24H20.0001V26H25.3801C27.1586 26.0128 28.8906 25.4325 30.3025 24.351C31.7143 23.2694 32.7255 21.7482 33.1763 20.0278C33.627 18.3073 33.4916 16.4857 32.7915 14.8508C32.0913 13.2159 30.8663 11.8609 29.3101 11Z" fill="#2E3238" />
                <path d="M21.28 19.85C21.4795 19.8532 21.6753 19.7966 21.8424 19.6876C22.0095 19.5786 22.1401 19.4221 22.2175 19.2382C22.295 19.0543 22.3156 18.8515 22.2768 18.6558C22.2381 18.4601 22.1417 18.2805 22 18.14L17 13.14L12 18.14C11.8362 18.3313 11.7505 18.5774 11.7603 18.8291C11.77 19.0807 11.8743 19.3195 12.0524 19.4976C12.2305 19.6757 12.4692 19.78 12.7209 19.7897C12.9726 19.7994 13.2187 19.7138 13.41 19.55L16 17V29.25C16 29.5152 16.1053 29.7696 16.2929 29.9571C16.4804 30.1446 16.7348 30.25 17 30.25C17.2652 30.25 17.5196 30.1446 17.7071 29.9571C17.8946 29.7696 18 29.5152 18 29.25V17L20.57 19.57C20.7603 19.7535 21.0156 19.8542 21.28 19.85Z" fill="#2E3238" />
              </svg>
            </div>
            <div class="w-8/12">
              <div class="w-full">
                <label for="uploadImage" class="text-[#02BD2D] cursor-pointer underline text-[15px] font-medium" >Upload Image</label>
                <input type="file" id="uploadImage" name="uploadImage" accept="image/jpeg, image/png" class="hidden" @change="onFileChange">
              </div>
              <div class="w-full leading-4">
                <span class="text-[#E5E9EC] text-[11px] font-medium">
                  Supported Files: JPEG and PNG max.
                  <br />
                  600 KB
                </span>
                <br />
                <span class="text-red-500 text-[10px]">This feature will be available soon</span>
              </div>
            </div>
          </div>
          </div> -->
       
          <div class="w-full h-[40px]">
            <span class="lg:text-[21px] md:text-[14px] Inter-Bold text-[#F8F9F9]">Security</span>
          </div>

          <div class="w-full justify-between items-center h-auto flex">
            <div class="w-4/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[12px] md:text-[10px]">Current Password</span>
            </div>
            <div class="w-8/12 h-[50px]">
              <div class="flex flex-col">
                <div
                  class="flex flex-row border-[0.3px] bg-[#1E2224] border-solid border-[#758278] rounded-md hover:bg-[#2E3238]">
                  <div class="w-full">
                    <input :type="type" v-model="userSecurity.currentpassword" placeholder="Current password"
                      class="w-full bg-[#1E2224] hover:bg-[#2E3238] rounded-l-md outline-none xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px] h-[40px]  py-2 px-4">
                  </div>
                  <div class=" w-2/12 items-center  rounded-r-md flex justify-center">
                    <font-awesome-icon @click="showPassword" class="text-white" style="  cursor: pointer"
                      :icon="['fas', iconName]" />
                  </div>
                </div>
                <span v-if="securityError.currentpassword.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                  securityError.currentpassword.message }}</span>
              </div>
            </div>
          </div>

          <div class="w-full justify-between items-center h-auto flex">
            <div class="w-3/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[12px] md:text-[10px]">New Password</span>
            </div>

            <div class="w-8/12 h-[50px]">
              <div class="flex flex-col">
                <div
                  class="flex flex-row border-[0.3px] border-solid border-[#758278] rounded-md hover:bg-[#2E3238] bg-[#1E2224]"
                  :class="!userSecurity.currentpassword ? 'opacity-[0.25]' : ''">

                  <input :type="newPasswordtype" :disabled="!userSecurity.currentpassword"
                    v-model="userSecurity.password" @input="checkInputPassword(userSecurity.password)"
                    placeholder="New password"
                    class="hover:bg-[#2E3238] outline-none rounded-l-md w-full h-[40px] bg-[#1E2224] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px] py-2 px-4">
                  <div class=" w-2/12 items-center rounded-r-md flex justify-center"
                    :class="!userSecurity.currentpassword ? 'opacity-[0.25]' : ''">
                    <font-awesome-icon @click="showNewPassword" class="text-white" style="  cursor: pointer"
                      :icon="['fas', iconNameNew]" />
                  </div>
                </div>
                <span v-if="securityError.password.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                  securityError.password.message }}</span>
                <div v-if="!securityError.password.isError"
                  class="w-full h-[.1vh] bg-transparent flex justify-between mt-2">
                  <div class="h-full w-[19%] rounded-xl" v-for="(n, i) in 5" :class="passwordStrengthPoints > i
                    ? 'bg-[' + passwordMeterColors[i] + ']'
                    : 'bg-gray-400'
                    " :key="i"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full justify-between items-center mt-6 h-auto flex">
            <div class="w-4/12 h-[23px]">
              <span class="whitespace-nowrap xl:text-[14px] lg:text-[12px] md:text-[10px]">Confirm password</span>
            </div>
            <div class="w-8/12 h-[50px]">
              <div class="flex flex-col w-full">

                <div  
                  class="flex flex-row border-[0.3px] bg-[#1E2224] border-solid border-[#758278] rounded-md hover:bg-[#2E3238]"
                  :class="!userSecurity.currentpassword ? 'opacity-[0.25]' : userSecurity.password != userSecurity.confirmpassword && userSecurity.confirmpassword ? 'border-red-600' : ''">
                  <input :type="retypePasswordtype" v-model="userSecurity.confirmpassword"
                    :disabled="!userSecurity.currentpassword" placeholder="Retype password"
                    class="hover:bg-[#2E3238] outline-none w-full rounded-l-md h-[40px] bg-[#1E2224] xl:placeholder:text-[14px] lg:placeholder:text-[14px] md:placeholder:text-[12px] py-2 px-4">
                  <div class=" w-2/12 items-center  rounded-r-md flex justify-center"
                    :class="!userSecurity.currentpassword ? 'opacity-[0.25]' : userSecurity.password != userSecurity.confirmpassword && userSecurity.confirmpassword ? 'border-red-600' : ''">
                    <font-awesome-icon @click="showRetypePassword" class="text-white" style="  cursor: pointer"
                      :icon="['fas', iconNameRetype]" />
                  </div>
                </div>
                <span v-if="securityError.confirmpassword.isError" class="mb-1 mt-1 text-[#FF0A00] text-[11px]">{{
                  securityError.confirmpassword.message }}</span>
              </div>
            </div>
          </div>

          <div class="w-full  justify-between items-center h-auto flex ">
            <div class="w-4/12 h-[23px] ">
              <span class="xl:text-[14px] lg:text-[14px] md:text-[12px] whitespace-nowrap ">2FA Settings</span>
            </div>
            <div v-if="!signedInUser?.two_FA[0]?.isEnabled" class="w-8/12 h-[50px] flex flex-col items-start">
              <button style="background:rgba(2, 189, 45, 0.12)"
                class="w-full rounded-md text-[#02BD2D]  font-normal h-[40px] border-[0.3px] border-solid border-[#02BD2D] xl:text-[14px] lg:text-[14px] md:text-[12px]"
                @click="two_faModal = true">Set Two Factor Authentication (2FA)</button>
              <!-- <span v-if="disbaled2FA" class="text-green-500 lg:text-[10px] md:text-[10px]">Your 2FA has been disabled successfully!</span> -->

            </div>
            <div v-else class="h-[50px] w-8/12 items-center flex mt-2">

              <div class="text-[#02BD2D] w-9/12">
                <div class="w-full flex items-center space-x-2">
                  {{ signedInUser?.two_FA[0]?.two_FA_by.toUpperCase() }}

                  <svg class="h-5 mt-1 ml-2 cursor-pointer hover:text-green-200" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7.8125L6.875 9.6875L10 5.9375" stroke="#02BD2D" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                      stroke="#02BD2D" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" @click="two_faModal = true" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor"
                    class="w-4 h-4 cursor-pointer hover:text-gray-200 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>
                </div>
              </div>
              <div class="text-[#FF0A00] w-3/12 text-[15px] cursor-pointer" @click=handleDisabled2FA()>Disable 2FA</div>

            </div>
          </div>

          <div class="w-full bg-red mt-8">
            <span v-if="usererror" class="text-[14px] text-[#FF0A00]">{{ usererror }}</span>
            <span v-if="usersuccess" class="text-[14px] text-[#02BD2D]">{{ usersuccess }}</span>
            <span v-if="securityerror" class="text-[14px] text-[#FF0A00]">{{ securityerror }}</span>
            <span v-if="securitysuccess" class="text-[14px] text-[#02BD2D]">{{ securitysuccess }}</span>
          </div>

          <div class="w-full mt-2 h-[40px] flex items-center justify-center">


            <button v-if="isSecurity"
              class="flex items-center justify-center cursor-progress bg-transparent btnLoadingBg text-[#F8F9F9] text-[13px] rounded-md border-[0.3px] border-solid border-[#F8F9F9] w-[100%] h-[100%]">
              <svg class="h-5 w-5 animate-spin text-[rgba(0, 0, 0, 0.5)] mr-2" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              UPDATING PASSWORD

            </button>

            <button v-else @click="handleSecurity"
              class="bg-[#2E3238] btnBg text-[#02BD2D] text-[13px] rounded-md border-[0.3px] border-solid border-[#02BD2D] w-[100%] h-[100%]">SAVE
              CHANGES</button>
          </div>

        </div>
      </div>

      <div class="w-5/12 overflow-y-auto py-2 px-6 rounded-md bg-[#1E2224]">

        <div id="planerror" class="w-full xl:flex items-center justify-between">
          <div class="">
            <span class="xl:text-[21px] lg:text-[18px] md:text-[14px] Inter-Bold whitespace-nowrap">My Plan</span>
          </div>

          <div class="flex items-center justify-center h-full" v-if="loaderCancellationButton == false">
            <div role="status" class="shadow animate-pulse flex justify-between w-full" style="max-width: 50%;">
              <div class="flex justify-between w-6/12 items-center pt-4">
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="flex items-center space-x-2">
            <span class="text-[12px] text-[#F8F9F9] opacity-[0.45]">Valid Until:{{ planExpireON }}</span>
            <span class="text-[12px] text-[#F8F9F9] opacity-[0.45]">-</span>

            <template v-if="userPlanStatus">
              <span v-if="isCancellationRequested" class="text-[12px] pr-1 text-[#EE4B2B] opacity-[1]"> {{
                CancellationMessage }} </span>

              <span v-else class="text-[12px] pr-1 text-[#02bd2d] opacity-[1] cursor-pointer hover:underline"
                @click="handleRequestToCancel">Request to Cancel</span>
            </template>
            <template v-else>
              <span class="text-[12px] pr-1 text-[#02bd2d] opacity-[1]">{{ CancellationMessage }}</span>
            </template>

            <div
              class="h-[22px] xl:mt-0 mt-4 cursor-default border-[0.6px] select-none p-2 flex items-center justify-center border-solid border-[#E5E9EC] rounded-md">
              <span class="text-[8px]">Powered by <span class="Inter-Bold text-[11px]">stripe</span></span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <!-- <span v-if="planAlert" class="text-[12px] text-[#FF0A00]">{{planAlert}}</span>
            <span v-if="datastreammessage"  class="text-[12px] text-[#02BD2D]">{{datastreammessage}}</span> -->
        </div>

        <div class="w-full h-[180px] space-y-2 flex-col flex" :class="planAlert ? 'mt-2' : 'mt-4'">

          <div class="w-full flex justify-between space-x-4">
            <div role="status" class="shadow animate-pulse flex flex justify-between w-full h-[100%] "
              v-if="stripeProducts.length == 0">
              <div class="flex justify-between w-6/12 items-center pt-4">
                <div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                  <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
              </div>
              <div class="flex justify-between  w-6/12 items-center pt-4">
                <div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                  <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
              </div>

              <span class="sr-only">Loading...</span>

            </div>

            <template v-else>
              <div v-for="(result, i) of stripeProducts" :key="i" @click="getProductClicked(result)"
                class="cursor-pointer w-full px-4 h-[40px] items-center flex justify-between border-[0.3px] border-solid bg-[#1E2224] border-[#758278] hover:bg-[#2E3238] rounded-md">
                <span
                  :class="result.id == userProductId ? 'xl:text-[15px] lg:text-[12px] md:text-[10px] uppercase text-[#02BD2D]' : 'uppercase text-[#E5E9EC] xl:text-[15px] lg:text-[12px] md:text-[10px] opacity-[0.45] hover:bg-[#2E3238]'">
                  <!-- <span :class=  "result.id == userProductId ? 'text-[15px] text-[#02BD2D]' : 'text-[#E5E9EC] text-[15px] opacity-[0.45]'"> -->
                  {{ getUpdatedProductName(result.name) }}
                </span>

                <svg v-if="result.id == userProductId" class="xl:w-5 xl:h-5 lg:h-4 lg:w-4 md:w-3 md:h-3"
                  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7.8125L6.875 9.6875L10 5.9375" stroke="#02BD2D" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="#02BD2D" />
                </svg>


                <svg v-else class="opacity-[0.45] xl:w-5 xl:h-5 lg:h-4 lg:w-4 md:w-3 md:h-3" viewBox="0 0 15 15"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="#E5E9EC" />
                </svg>

              </div>
            </template>
          </div>
          <div class="w-full">
            <div role="status" class="shadow animate-pulse w-full flex justify-around w-full h-[100%] "
              v-if="loaderState">
              <div class="flex justify-between w-6/12 items-center">
                <div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                  <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
              </div>
              <div class="flex justify-between w-6/12 items-center pt-4">
                <div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                  <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
              </div>
              <span class="sr-only">Loading...</span>

            </div>
            <div v-else class="w-full h-[100%] space-y-2">
              <div class="flex space-x-4  justify-between items-center w-full">
                <span class="xl:text-[21px] lg:text-[18px] md:text-[14px] Inter-Bold whitespace-nowrap">Billing
                  Cycle</span>
                <span v-if="promoCodeErrorMessage" class="text-[12px] text-[#FF0A00]">{{ promoCodeErrorMessage }}</span>
                <span v-if="promoCodeSuccessMessage" class="text-[12px] text-[#02BD2D]">{{ promoCodeSuccessMessage
                  }}</span>

                <span v-if="!showPromoField && userPlanId == 'price_1OMTWRLjbEHKohEJDCMXQH1z'"
                  :class="(enablePromo && userPlanId == 'price_1OMTWRLjbEHKohEJDCMXQH1z' && !promoCodeSuccessMessage && !promoCodeErrorMessage) ? 'block' : 'hidden'"
                  class="text-[12px] text-[#F8F9F9] opacity-[0.45] cursor-pointer hover:underline"
                  @click="handlePromoCodeClick">Apply Promo Code</span>
                <div v-else-if="showPromoField && userPlanId == 'price_1OMTWRLjbEHKohEJDCMXQH1z'"
                  :class="(enablePromo && userPlanId == 'price_1OMTWRLjbEHKohEJDCMXQH1z') ? 'block' : 'hidden'"
                  class="w-4/12 flex justify-end space-x-3 items-center">
                  <div :class="codeFieldError ? 'border-red-600' : 'border-[#758278]'"
                    class="w-full border rounded-[3px] flex relative">
                    <input
                      class="text-[12px] px-2 focus:outline-none w-10/12 py-[2px] text-[#F8F9F9]  bg-transparent placeholder:text-[#F8F9F9] placeholder:opacity-[0.45]"
                      placeholder="code.." v-model="promoCode" />
                    <span
                      class="text-[11px] top-[1px] text-[#F8F9F9] opacity-[0.45] cursor-pointer hover:underline absolute right-2"
                      title="click to apply code" @click="handleApplyCode">Apply</span>
                  </div>
                </div>


              </div>
              <div class="flex space-x-4  justify-between w-full">
                <div v-for="(item, i) of priceList" :key="i" @click="handlePrice(item)"
                  class="cursor-pointer border-[#758278] flex hover:bg-[#2E3238] flex-col justify-center w-full px-6 lg:h-[60px] md:h-[68px] border-[0.3px] border-solid bg-[#1E2224] rounded-md">
                  <div class="items-center flex justify-between">
                    <span
                      :class="item.id == userPlanId ? 'xl:text-[15px] lg:text-[12px] md:text-[10px] text-[#02BD2D]' : ' text-[#E5E9EC] xl:text-[15px] lg:text-[12px] md:text-[10px] opacity-[0.45]'">
                      {{ item.label.toUpperCase() }}
                    </span>

                    <div class="flex space-x-2 items-center">
                      <span v-if="item.time_period == 'year'"
                        class="text-[#FED500] lg:text-[12px] md:text-[9px] opacity-[0.8]">Save USD {{ userProductId ==
                          "prod_PAp7BKzWL1cCam" ? 120 : 240 }}</span>

                      <span
                        v-if="item.time_period == 'month' && userPlanId == 'price_1OMTWRLjbEHKohEJDCMXQH1z' && coupenApplied"
                        class="text-[#FED500] lg:text-[12px] md:text-[9px] opacity-[0.8]">USD60OFF</span>

                      <svg v-if="item.id == userPlanId" class="xl:w-5 xl:h-5 lg:h-4 lg:w-4 md:w-3 md:h-3"
                        viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 7.8125L6.875 9.6875L10 5.9375" stroke="#02BD2D" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                          stroke="#02BD2D" />
                      </svg>

                      <svg v-else class="opacity-[0.45] xl:w-5 xl:h-5 lg:h-4 lg:w-4 md:w-3 md:h-3" viewBox="0 0 15 15"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                          stroke="#E5E9EC" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <span
                      class="text-[#E5E9EC] xl:text-[15px] lg:text-[12px] whitespace-nowrap md:text-[10px] opacity-[0.45]">USD
                      {{ item.price / 100 }} / {{ item.time_period }}</span>
                  </div>
                  <!-- <span v-if="item.time_period=='year'" class="text-[#FED500] lg:text-[9px] md:text-[7px]">GO ANNUAL & GET 2 MONTHS FOR FREE</span> -->
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="w-full h-[40px] flex mt-5 items-center justify-between">
          <span
            class="xl:text-[21px] lg:text-[18px] md:text-[14px] whitespace-nowrap xl:mr-0 mr-4 w-4/12 Inter-Bold text-[#F8F9F9]">Data
            Stream</span>
          <span class="text-[.55vw] w-8/12 italic opacity-[0.45] text-[#F8F9F9]">
            Changing your data stream its only available for Plus Members. Data Stream change can only be requested once
            every 90 days. Changes take up to 24h to process.
          </span>
        </div>

        <div class="w-full justify-between items-center mt-4 h-auto flex">
          <div class="w-4/12 h-[23px]">
           <!--  <span class="xl:text-[14px] lg:text-[15px] text-[12px] text-[#E5E9EC]">Data Stream: </span> -->
          </div>
          <div class="w-8/12 flex flex-col">

            <div v-if="userIsPremium"
              class="w-full lg:flex lg:h-[40px] h-[55px] justify-between items-center bg-[#1E2224] rounded-md border-[0.3px] border-solid hover:bg-[#2E3238] border-[#758278]">

              <select name="countries" disabled
                class="w-full bg-transparent xl:text-[15px] lg:text-[12px] md:text-[10px]   px-4 text-[#02BD2D]  outline-none">
                <option class="xl:text-[15px] lg:text-[12px] md:text-[10px] text-[#02BD2D]" selected disabled>All
                  Countries</option>
              </select>

              <span
                class="text-[#E5E9EC] mr-2 lg:ml-0 ml-2 lg:text-[12px] text-center xl:text-[15px] md:text-[10px] whitespace-nowrap opacity-[0.45] cursor-not-allowed">
                Request a change
              </span>

            </div>

            <div v-else-if="userIsPlus"
              class="w-full flex h-[40px] justify-between items-center bg-[#1E2224] rounded-md border-[0.3px] border-solid border-[#758278]">
              <select name="countries" v-model="userSelectCountry" @change="selectCountry($event)"
                class="w-[150px] bg-[#1E2224]  datastream cursor-pointer px-4 text-[#02BD2D]  outline-none"
                :class="changeDataStream ? 'divDisabled' : ''">
                <option class="text-[15px] text-[#02BD2D]" value="oz"
                  :disabled="signedInUser.dataStreamCountry[0].country == 'oz'">Australia</option>
                <option class="text-[15px] text-[#02BD2D]" value="us"
                  :disabled="signedInUser.dataStreamCountry[0].country == 'us'">USA</option>
                <option class="text-[15px] text-[#02BD2D]" value="ca"
                  :disabled="signedInUser.dataStreamCountry[0].country == 'ca'">Canada</option>
              </select>

              <span class="text-[#E5E9EC] mr-2 text-[12px] xl:text-[15px] opacity-[0.45]"
                :class="countryChanged || changeDataStream ? 'cursor-not-allowed pointer-events-none' : 'cursor-pointer opacity-[0.85]'"
                @click="handleRequestChangeModal">Request a change</span>

            </div>

            <div v-else
              class="w-full flex h-[40px] justify-between items-center bg-[#1E2224] rounded-md border-[0.3px] border-solid border-[#758278]">
              <select name="countries" disabled
                class="w-[150px] cursor-not-allowed bg-[#1E2224]  datastream  px-4 text-[#02BD2D]  outline-none">
                <option class="text-[15px] text-[#02BD2D]" selected value="oz">Australia</option>
              </select>

              <span title="To change data stream update your plan from starter to plus"
                class="text-[#E5E9EC] cursor-default opacity-[0.45] mr-2 text-[12px] xl:text-[15px]">
                Request a change
              </span>

            </div>
            <!--                
                <select name="countries" v-else disabled :class="!userIsPlus ? 'cursor-not-allowed' : 'cursor-pointer'" class="w-[150px] bg-[#1E2224]  datastream cursor-pointer px-4 text-[#02BD2D]  outline-none">
                  <option class="text-[15px] text-[#02BD2D]" selected value="oz">Australia</option>
                </select> 
               
                <span 
                 class="text-[#E5E9EC] mr-2 text-[12px] xl:text-[15px] opacity-[0.45]" 
                :class="!userIsPlus || countryChanged ? 'cursor-not-allowed pointer-events-none' : 'cursor-pointer opacity-[0.85]'"
                @click="handleRequestChange"
                >Request a change</span> -->



            <span v-if="signedInUser.role == 'plus' && signedInUser.dataStreamCountry[0].updatedDate"
              class="lg:text-[11px] md:text-[8px] mt-1 italic opacity-[0.45] text-[#F8F9F9]">Last change
              {{ userselectdate }}, Next change {{ next3MonthDate }}</span>
          </div>
        </div>

        <div class="w-full card-details">
          <div class="w-full lg:flex lg:items-center  lg:justify-between mt-5">
            <div>
              <span class="xl:text-[21px] lg:text-[18px] md:text-[14px] whitespace-nowrap Inter-Bold text-[#F8F9F9]">My
                Cards</span>
            </div>
            <div class="flex items-center justify-center space-x-4">
              <span class="lg:text-[13px] md:text-[8px] cursor-pointer text-[#E5E9EC] select-none hover:underline"
                @click="receiptModal = true">View Invoice/s</span>
              <div
                class="w-[120px] h-[24px] flex items-center  justify-center border-[0.6px] border-solid border-[#02BD2D] rounded-md">
                <button
                  class="btnBg w-full xl:text-[13px] lg:text-[11px] md:text-[8px] select-none text-[#02BD2D] cursor-pointer"
                  @click="cardModalHandle">ADD CARD</button>
              </div>
              <div
                class="w-[120px] h-[24px] flex items-center  justify-center border-[0.6px] border-solid border-[#02BD2D] rounded-md">
                <button
                  class="btnBg w-full xl:text-[13px] lg:text-[11px] md:text-[8px] select-none text-[#02BD2D] cursor-pointer"
                  @click="addressModals = true">ADD ADDRESS</button>
              </div>
            </div>
          </div>

          <div class="w-full items-center">
            <span class="lg:text-[10px] md:text-[7px] italic opacity-[0.45] text-[#F8F9F9]">
              Credit card payments are processed by Stripe. Market Alert Pro holds only enough information for you to
              identify your card/s.
              <!-- Credit card information is held and secured by Stripe. Market Alert Pro doesn't hold any credit card details. -->
            </span>
          </div>

          <div class="w-full h-[106px] flex flex-col items-center  mt-4 overflow-y-auto"
            :class="disabledd ? 'divDisabled' : ''">
            <div v-if="cardDetails.length == 0" role="status" class="shadow animate-pulse w-full">
              <div class="flex justify-around items-center mt-4">
                <div class="w-2/12">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-10 h-10">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                  </svg>
                </div>
                <div class="w-4/12">
                  <!-- <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10/12 mb-2">Please add card</div>
                  <div class="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div> -->
                  <div class="h-2.5 rounded-full w-10/12 mb-2">Please add card</div>
                  <div class="w-full h-2 rounded-full "></div>
                </div>
                <div class="w-4/12">
                  <div class="h-2.5 rounded-full w-10/12 mb-2"></div>
                  <div class="w-full h-2 rounded-full"></div>
                </div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div v-for="item in cardDetails" :key="item.id" :disabled="disabled"
                class="w-full flex items-center hover:bg-[#2E3238] bg-opacity-[0.45] justify-between text-center space-x-6 mt-2 rounded-md border-[0.3px] border-solid border-[#758278] p-2">

                <div class="ml-4 w-1/12 select-none">
                  <img v-if="noCardIcon == false" :src="cardImageSelector(item.brand)" alt="" class="w-8 h-8" />
                  <span v-else>{{ item.brand }}</span>
                </div>

                <span
                  class="text-[8px] xl:text-[15px] whitespace-nowrap w-4/12 select-none  text-[#E5E9EC] opacity-[0.45]">Ending
                  {{ item.last4digit }}</span>

                <div class="xl:space-x-1 space-x-6 select-none justify-between flex items-center w-3/12 p-1">
                  <span class="w-6/12 xl:text-[15px] lg:text-[12px] md:text-[10px] text-[#E5E9EC] opacity-[0.45]">{{
                    getMonth(item.expireMonth
                    ) }}/{{ item.expireYear }}</span>
                  <span v-if="!item.default" class="text-[11px] text-red-500 italic">{{ checkExpiry(item.expireMonth,
                    item.expireYear) }}</span>
                  <svg v-if="item.default" class="xl:w-5 xl:h-5 md:h-4 md:w-4  mr-3 " viewBox="0 0 15 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7.8125L6.875 9.6875L10 5.9375" stroke="#02BD2D" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                      stroke="#02BD2D" />
                  </svg>
                </div>

                <div v-if="item.default" class=" h-[20px]  w-3/12 flex items-center justify-center">
                  <span
                    class="lg:text-[11px] md:text-[8px] whitespace-nowrap text-[#02BD2D] select-none italic cursor-auto">DEFAULT
                    CARD</span>
                </div>
                <div v-else :disabled="disabled" @click="MakeDefault(item)"
                  class="w-3/12 btnLoadingBg cursor-pointer h-[24px] py-3 flex items-center justify-center border-[0.6px] border-solid border-[#E5E9EC] opacity-[0.45] rounded-md">
                  <svg v-if="loaderStateCard" class="h-3 w-3 animate-spin text-white" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                  <span v-else class="text-[11px] text-[#E5E9EC] opacity-[0.65] select-none italic">MAKE DEFAULT</span>

                </div>
              </div>
            </div>
          </div>
        </div>

       <!--  <div v-else>
          <div class="w-full lg:flex lg:items-center  lg:justify-between mt-20">
            <div>
              <span class="xl:text-[21px] lg:text-[18px] md:text-[14px] whitespace-nowrap Inter-Bold text-[#F8F9F9]">My
                Cards</span>
            </div>
            <div class="flex items-center justify-center space-x-4">
              <VueLoadingButton aria-label="Subscribe"
                class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                @click.native="subscribe" :loading="loaderState" type="button">
                <span class="text-[15px] font-bold">SKIP TRIAL, SUBSCRIBE NOW</span>
              </VueLoadingButton>
            </div>
          </div>
        </div> -->

        <div class="w-full mt-5">
          <span v-if="planAlert" class="text-[12px] text-[#FF0A00]">{{ planAlert }}</span>
          <span v-if="datastreammessage" class="text-[12px] text-[#02BD2D]">{{ datastreammessage }}</span>
        </div>
        <div class="w-full h-[40px] mt-2">
          <button v-if="isPlanBtn"
            class="flex items-center justify-center cursor-progress bg-transparent btnLoadingBg text-[#F8F9F9] text-[13px] rounded-md border-[0.3px] border-solid border-[#F8F9F9] w-[100%] h-[100%]">
            <svg class="h-5 w-5 animate-spin text-[rgba(0, 0, 0, 0.5)] mr-2" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            UPDATING PLAN INFO
          </button>

          <a href="#updatebillingcycle" v-else @click="handleUpdatePlan">
            <button
              class="bg-transparent btnBg text-[#02BD2D] text-[13px] rounded-md border-[0.3px] border-solid border-[#02BD2D] w-[100%] h-[100%]">
              SAVE CHANGES
            </button>
          </a>
        </div>

      </div>

    </div>

    <template v-if="cardModals">
      <CardModal v-on:closeModal="toggleCardModal" />
    </template>
    
    <template v-if="addressModals">
      <AddressModal v-on:closeAddressModal="toggleAddressModal" />
    </template>

    <template v-if="messageModal">
      <MessageModal v-on:closeMessageModal="toggleMessageModal" :productId="newProductId" :planId="newPlanId"
        :coupon="coupenApplied" :isCancelRequest="false" />
    </template>

    <template v-if="requestCancel">
      <RequestCancelModal v-on:closeMessageModal="toggleCloseRequestModal" />
    </template>

    <template v-if="dataStreamModal">
      <DataStreamModal v-on:closeDataStreamModal="toggleDataStreamModal" v-on:changeDataStream="handleRequestChange" />

    </template>

    <template v-if="receiptModal">
      <ReceiptModal v-on:closeReceiptModal="toggleReceiptModal" />

    </template>
    <template v-if="phoneModal">
      <PhoneModal v-on:closePhoneModal="togglePhoneModal" />
    </template>
    <!-- <template v-if="emailChangeModal"> -->
    <template v-if="emailChangeModal">
      <EmailChangeModal v-on:closeEmailModal="togglePhoneModal" />
    </template>
    <template v-if="two_faModal">
      <Select2FAModalVue v-on:close2famodal="togglePhoneModal" />
    </template>

  </div>
</template>



<script>
import CardModal from "../popups/CardModal.vue";
import MessageModal from "../popups/MessageModal.vue";
import DataStreamModal from "../popups/DataStreamModal.vue"
import ReceiptModal from '../popups/ReceiptModal.vue'
import PhoneModal from '../popups/PhoneModal.vue'
import EmailChangeModal from '../popups/EmailChangeModal.vue'
import RequestCancelModal from '../popups/RequestCancelModal.vue'
import router from "@/router";
import AddressModal from "../popups/AddressModal.vue"

import {
  STRIPE_PRODUCT_LIST,
  MAKE_DEFAULT,
  subscribe_To_Package,
  USERDATASTREAM
} from "@/api/apiRoutes";
import axios from "axios";
import userApi from "@/api/userApi";
import { mapActions, mapGetters } from "vuex";
import LoadingButton from '../buttons/LoadingButton.vue'
import { passwordStrength } from "check-password-strength";
import moment from 'moment'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import "./SettingPage.css"
import Select2FAModalVue from "../popups/Select2FAModal.vue";
import VueLoadingButton from "vue-loading-button";
import {GET_COSTOMER_DETAILS} from "../../api/apiRoutes"

export default {
  name: "SettingsPage",
  components: {
    CardModal,
    MessageModal,
    VueLoadingButton,
    LoadingButton,
    ReceiptModal,
    PhoneModal,
    VuePhoneNumberInput,
    DataStreamModal,
    EmailChangeModal,
    Select2FAModalVue,
    RequestCancelModal,
    AddressModal
  },
  data() {
    return {
      isMonthlyCode: false,
      requestCancel: false,
      showCodeArea: false,
      showPromoField: false,
      promoCodeErrorMessage: null,
      promoCodeSuccessMessage: null,
      coupenApplied: false,
      enablePromo: false,
      codeFieldError: false,
      promoCode: "",
      regex: /^\d{8}$/,
      countries: ['US', 'CA', 'GB', 'AU'],
      two_faModal: false,
      countryCode: 'US',
      phone: "",
      yourValue: null,
      isSecurity: false,
      isPlanBtn: false,
      isUser: false,
      dataStream: "",
      loaderState: false,
      usersuccess: "",
      type: "password",
      newPasswordtype: "password",
      retypePasswordtype: "password",
      iconName: "fa-eye",
      iconNameNew: "fa-eye",
      iconNameRetype: "fa-eye",
      noCardIcon: false,
      passwordMeterColors: [
        "#FF0A00",
        "#FF0A00",
        "#02BD2D",
        "#02BD2D",
        "#02BD2D",
      ],
      passwordStrengthPoints: 0,
      planAlert: "",
      usererror: "",
      securityerror: "",
      securitysuccess: "",
      datastreammessage: "",
      cardModals: false,
      addressModals:false,
      messageModal: false,
      receiptModal: false,
      dataStreamModal: false,
      phoneModal: false,
      emailChangeModal: false,
      validatedPhone: null,
      mainLoaderstate: false,
      stripeProducts: [],
      newSelectedProduct: null,
      selectedProductList: [],
      loaderStateCard: false,
      loaderCancellationButton: false,
      isCancellationRequested: false,
      CancellationMessage: "",
      next3MonthDate: "",
      countryFlag: {
        "AU": "australia",
        "CA": "canada",
        "GB": "uk",
        "US": "usa",
        "FR": "france",
        "NZ": "newzealand",
        "ZA": "africa",
        "SG": "singapore"
      },
      cardIconObject: {
        visa: "https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg",
        master:
          "	https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg",
        american_express:
          "	https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg",
        discover:
          "https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg",
        diners_club:
          "	https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg",
        jcb: "	https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg",
        union_pay:
          "	https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg",
      },
      isPlan: false,
      userProductId: "",
      userPlanId: "",
      userPlanStatus: false,
      priceList: [],
      newProductId: "",
      newPlanId: "",
      isplanExpired: false,
      planExpireOn: "",
      disabled: false,
      changeDataStream: null,
      userSelectCountry: "",
      disabledd: false,
      lastselected: "",
      countryChanged: true,
      userselectdate: "",
      selectedPlan: "Plus",
      selectedCardClass: "",
      userData: {},
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        imageFile: null,
        imagePreview: null,
      },
      userError: {
        firstName: { isError: false, message: "" },
        lastName: { isError: false, message: "" },
        phone: { isError: false, message: "" }
      },
      securityError: {
        currentpassword: { isError: false, message: "" },
        password: { isError: false, message: "" },
        confirmpassword: { isError: false, message: "" }
      },


      userSecurity: {
        currentpassword: "",
        password: "",
        confirmpassword: ""
      },
      selectCountry(e) {
        this.countryChanged = false;
        this.dataStream = e.target.value

      },
      cardDetails: [],
      disbaled2FA: false
    };
  },
  computed: {
    ...mapGetters(["signedInUser", "userIsPlus", 'userIsPremium','address_get','newCard_get','cardStore_get']),
    planExpireON() {
      // Convert the date string to a Date object
      const date = new Date(this.planExpireOn);
      const day = date.getDate();
      const month = date.getMonth() + 1; // January is 0!
      const year = date.getFullYear();

      // Padding with zeros if necessary
      const paddedDay = day < 10 ? '0' + day : day;
      const paddedMonth = month < 10 ? '0' + month : month;

      return `${paddedDay}-${paddedMonth}-${year}`;
    }
  },
  methods: {
    ...mapActions(["getUserData"]),
    handlePromoCodeClick() {
      this.codeFieldError = false
      this.showPromoField = true
    },
    async handleApplyCode() {
      const isEmpty = /^\s*$/.test(this.promoCode);

      if (isEmpty) {
        this.codeFieldError = true
      } else {
        try {
          const res = await userApi.ApplyPromoCode(this.promoCode)
          if (res?.data?.success) {
            // const updatedArr = this.priceList?.map((item)=>{
            //   if(item?.id == "price_1OMTWRLjbEHKohEJDCMXQH1z"){
            //      return {
            //       ...item,
            //       price: res?.data?.payload?.discount
            //      } 
            //   }
            //   return item
            // })
            // this.priceList = updatedArr
            this.coupenApplied = true
            this.promoCodeSuccessMessage = "Hurray, enjoy a USD60 discount on your Premium plan!"
            setTimeout(() => {
              this.promoCodeSuccessMessage = null
            }, 3000);
            this.promoCode = ""
            this.showPromoField = false
          } else {
            this.promoCodeErrorMessage = res?.data?.message
            this.coupenApplied = false
            setTimeout(() => {
              this.promoCodeErrorMessage = null
            }, 3000);
          }
        } catch (err) {
          console.log("error==>", err)
        }

      }
    },
    getFlag() {
      if (this.signedInUser?.phoneCountry) {
        let flag = this.countryFlag[this.signedInUser?.phoneCountry]
        return flag + '.svg'
      }
    },
    async handleDisabled2FA() {
      try {

        let res = await userApi.Disable2FA();
        if (res.data.success) {
          this.disbaled2FA = true
          this.getUserData()
          setTimeout(() => {
            this.disbaled2FA = false
          }, 2000)
        }
        else {
          this.loaderState = false
        }

      }
      catch (e) {
        console.log(e)

      }
    },

    getPhone() {
      return `${this.signedInUser?.countryCode} ${this.signedInUser?.phone}`
    },
    getEmail() {
      return `${this.signedInUser?.email} `
    },

    async getUserDetail() {
      this.loaderStateCard = true
      const res = await userApi.getUser();
      // console.log("userdata", res.data.payload.user)
      if (res.data.success) {
        let userDetails = res.data.payload.user;
        if (userDetails?.chartConfig?.overlays) {
          this.$store.commit("SET_ALL_INDICATORS", userDetails.chartConfig.overlays)
        }
        this.$store.commit("SET_USER_DETAILS", userDetails);
      } else {
        console.log("error occur")
      }
    },


    changeDataStreamDisable(date1, date2) {
      // this.changeDataStream = true
      if (date1 && date2) {
        var daysDiff = moment(date1).diff(moment(date2), 'minutes');
        let days = Math.abs(daysDiff)
        // console.log("days",days)
        if (days > 1) {
          this.changeDataStream = false
        } else {
          this.changeDataStream = true
        }
      }
    },

    handleRequestChangeModal() {
      this.dataStreamModal = true
    },
    toggleDataStreamModal() {
      this.dataStreamModal = false
    },

    toggleCardModal() {
      this.cardModals = false;
      this.getUser()
    },
    cardModalHandle(){
      if(this.address_get.line1.length==0|| this.address_get.city.length==0|| this.address_get.country.length==0|| this.address_get.state.length==0|| this.address_get.postal_code.length==0) {
        this.planAlert = "Please add address details first"
      } else{
        this.cardModals = true;
      }
    },
    toggleAddressModal(){
      this.addressModals = false;
      this.getUser()
    },
    togglePhoneModal() {
      this.phoneModal = false;
      this.emailChangeModal = false;
      this.two_faModal = false;
      this.getUser()

    },
    getUpdatedProductName(n) {
     
     // console.log('newplanid',this.newProductId,userProductId, this.newPlanId,productresult);
      if (n == "Plus Package") {
        return "Plus Plan"
      } else if (n == "Premium Package") {
        return "Premium Plan"
      } else {
        return n
      }
     
    },

    handleInput(value) {
      this.countryCode = value?.countryCode
      this.userInfo.phone = value.phoneNumber
    },

    toggleCloseRequestModal() {
      this.requestCancel = false
    },

    toggleMessageModal() {
      this.messageModal = false;
      this.planAlert = ""
      this.userProductId = this.userData.productId
      this.userPlanId = this.userData.planId

    },

    checkExpiry(cardMonth, cardYear) {

      const d = new Date();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      if (cardMonth > month && cardYear > year) {
        return "expired"
      }

    },

    getMonth(createdDate) {
      if (createdDate < 10) {
        return "0" + createdDate
      } else {
        return createdDate
      }
    },

    toggleReceiptModal() {
      this.receiptModal = false;
    },

    handleRequestToCancel() {
      this.requestCancel = true

    },

    async getProducts() {
      //console.log("get product")
      // this.mainLoaderstate = true;
      this.loaderState = true
      let data = await axios.get(STRIPE_PRODUCT_LIST);
      if (data) {
        const userDetails = await this.getUserData();
        this.loaderstate = false;
        this.stripeProducts = data.data.payload;
        this.isPlan = true
        
        const payloadArray = Object.values(data.data.payload);
        const filteredItems = payloadArray.filter(item => item.id === userDetails.productId);
       
        if(filteredItems.length > 0){
          filteredItems.forEach(filteredItem => {
            if(filteredItem['name']=='Premium Package'){
              this.newProductId=userDetails.productId;
              this.selectedPlan="Premium";
            }else if(filteredItem['name']=='Plus Package'){
              this.newProductId=userDetails.productId;
              this.selectedPlan="Plus";
            }
          
            if(filteredItem['pricing'].length>0){
            
              filteredItem['pricing'].forEach((filteredProductBillingType) => {
                if(filteredProductBillingType['time_period']=='year' && filteredProductBillingType['id']==userDetails.planId){
                  this.newPlanId=userDetails.planId;
                  this.selectedCardClass='yearly'
                }else if(filteredProductBillingType['time_period']=='month' && filteredProductBillingType['id']==userDetails.planId){
                  this.newPlanId=userDetails.planId;
                  this.selectedCardClass='monthly'
                }
              });
            }else{
              console.log("No Monthly/Yearly pricing found in product list of plus/premium");
            }

          // Now you can access each item properties such as item.id, item.name, etc.
            });
        }else{
            console.log("No Products based on user product ID");
        } 


      }
    },

    cardImageSelector(value) {
      let updatedValue = value.toLowerCase();
      switch (updatedValue) {
        case "visa":
          return this.cardIconObject.visa;
        case "mastercard":
          return this.cardIconObject.master;
        case "american express":
          return this.cardIconObject.american_express;
        case "jcb":
          return this.cardIconObject.jcb;
        case "unionpay":
          return this.cardIconObject.union_pay;
        default:
          return (this.noCardIcon = true);
      }
    },


    getProductClicked(value) {
      let namepackage = value.name
      const planinfo = namepackage.split(' ')[0];
      this.selectedPlan = planinfo;

      // console.log("product change hgya ha");
      // this.newSelectedProduct = value;
      this.userProductId = value.id
      this.enablePromo = false
      this.showPromoField = false
      this.getPriceData()
      this.newProductId = value.id

    },

    async getPriceData() {
      this.loaderState = true
      if (this.userProductId) {
        let priceData = this.stripeProducts.find((f) => f.id === this.userProductId);
        this.loaderState = false
        this.priceList = priceData.pricing;
        const checkMonthPrice = this.priceList?.some((item) => item.id == 'price_1OMTWRLjbEHKohEJDCMXQH1z')
        if (checkMonthPrice) {
          this.enablePromo = true
        } else {
          this.enablePromo = false
        }
      }
    },

    changeDate() {
      let streamDate = this.signedInUser.dataStreamCountry[0].updatedDate
      let threeMonthsFromNow = moment(streamDate).add(60, 'minutes');
      this.next3MonthDate = threeMonthsFromNow.format('DD/MM/YYYY:hh:mm')
    },

    handleUpdatePlan() {
      if (this.newProductId.length==0 || this.newPlanId.length==0) {
        this.planAlert = "Please select a billing cycle"
        
      } else if(this.address_get.line1.length==0|| this.address_get.city.length==0 || this.address_get.country.length==0|| this.address_get.state.length==0|| this.address_get.postal_code.length==0) {
        this.planAlert = "Please add address details"

      }else if(!this.cardDetails.length>0){
        this.planAlert = "Please add card details"
      } else{
        this.messageModal = true
      }
    },

    handlePrice(item) {
      if (item?.time_period == "month") {
        this.enablePromo = true
        this.selectedCardClass='monthly';
      } else {
        this.enablePromo = false
        this.selectedCardClass='yearly';
      }

      this.userPlanId = item?.id
      this.newPlanId = item?.id
      this.showPromoField = false
      this.promoCode = ""
      this.codeFieldError = false
     

    },

    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.iconName = "fa-eye-slash";
      } else {
        this.type = "password";
        this.iconName = "fa-eye";
      }
    },

    showNewPassword() {
      if (this.newPasswordtype === "password") {
        this.newPasswordtype = "text";
        this.iconNameNew = "fa-eye-slash";
      } else {
        this.newPasswordtype = "password";
        this.iconNameNew = "fa-eye";
      }
    },

    showRetypePassword() {
      if (this.retypePasswordtype === "password") {
        this.retypePasswordtype = "text";
        this.iconNameRetype = "fa-eye-slash";
      } else {
        this.retypePasswordtype = "password";
        this.iconNameRetype = "fa-eye";
      }
    },

    async handleRequestChange() {
      try {
        const res = await userApi.changeDataStream({ country: this.dataStream })
        if (res.data.success) {
          this.dataStreamModal = false
          this.datastreammessage = res.data.message
          this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", this.dataStream);

          // localStorage.setItem("activeCountry", this.dataStream); 
          await this.getUserDetail()
          await this.getUser()
          this.changeDate()
        } else {
          this.dataStreamModal = false
          this.planAlert = res.data.message
          // await this.getUserData()
        }
      } catch (err) {
        console.log(err)
        this.dataStreamModal = false
        this.planAlert = "Server Error"
      }



    },

    async getUser() {
      this.loaderStateCard = true
      const res = await this.getUserData();
      if (res) {
        this.loaderStateCard = false
        let isStreamExist = res.dataStreamCountry?.length > 0;
        this.userSelectCountry = isStreamExist ? res.dataStreamCountry[0].country : "oz"
        if (isStreamExist) {
          let lastChange = isStreamExist ? moment(res.dataStreamCountry[0].updatedDate) : moment();
          this.userselectdate = lastChange.format("DD/MM/YYYY:hh:mm")
        }

        const currentTime = Date.now();
        //console.log('Marked check', res.subscriptioninfo.data.endPeriod);
        //if (subscription['status'] != "trailing" && subscription['status'] != "active") {
        if (currentTime > res.subscriptioninfo.data?.endPeriod) {
          this.isplanExpired = true
          this.planExpireOn = res?.subscriptioninfo?.data?.endPeriod;
        } else {
          this.isplanExpired = false
          this.planExpireOn = res?.subscriptioninfo?.data?.endPeriod;
        }
        this.loaderCancellationButton = true
        this.userPlanStatus = (res.status == 'trialing') ? false : true;
        if (res.status == 'trialing') {
          this.CancellationMessage = "Trial Version"
        } else if (res.status == 'active' && res.subscriptioninfo.data.cancellation_request === true) {
          this.isCancellationRequested = true
          this.CancellationMessage = "Applied For Cancellation"
        }


      } else {
        console.log("error occur")
      }
    },
    async subscribe() {
      // Example subscription logic
      let isNotValid = false;
      this.alertStatus = false;

      if (this.selectedCardClass == "" || this.selectedPlan == "") {
        if(this.selectedPlan == ""){
          this.planAlert = "Please select a Plan";
        }else if(this.selectedCardClass == ""){
          this.planAlert = "Please select a Billing cycle";
        }
       
     
        //this.showErrors("Please select at least one subscription plan", "Danger", true);
        isNotValid = true;
      }

      if (isNotValid) {
        return;
      }

      let condition = this.selectedPlan + "_" + this.selectedCardClass;
      //let condition = "Plus_monthly";
      let payload = { "plan": condition, "user": localStorage.getItem("email") }
      try {
        const res = await axios.post(subscribe_To_Package, {
          payload
        });
        window.location = res.data.payload.sessionUrl;
      }
      catch (error) {
        console.log(error.message)
      }
    },

    /*   async handleUserInfoUpdate(){
         if(this.userInfo.firstName && this.userInfo.lastName){
           if(this.userInfo.phone && this.userInfo.phone.length != 7){
             this.userError.phone.isError = true
             this.userError.phone.message = "Phone length must be 7 digits"
         }
         else{
             /!*
              this.disabled = true
              this.isUser = true
              try{
                 const res = await userApi.updateUser(this.userInfo)
                if(res.status == 200){
                  this.disabled = false
                  this.isUser = false
                  this.usersuccess = res.data.message
                } 
              }catch(err){
                this.isUser = false
                this.disabled = false
                this.usererror = "Server Error"
              }*!/
         }
         }else{
           this.userError.firstName.isError = true
           this.userError.firstName.message = "firstName required"
           this.userError.lastName.isError = true
           this.userError.lastName.message = "lastName required"
         }
       },
 */

    validatePhone(phone) {
      if (this.regex.test(phone)) {
        this.validatedPhone = true;
      } else {
        this.validatedPhone = false;
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.userInfo.imageFile = file;
        this.userInfo.imagePreview  = URL.createObjectURL(file);
      }
    },
    async handleSecurity() {
      let hasError = false
      if (!this.userInfo.firstName || this.userInfo.firstName.match(/^\s+$/) !== null || this.userInfo.firstName.indexOf(' ') >= 0) {

        if (!this.userInfo.firstName.indexOf(' ') >= 0) {
          hasError = true
          this.userError.firstName.isError = true
          this.userError.firstName.message = "Blank Spaces are not allowed"

        }
        else {

          hasError = true
          this.userError.firstName.isError = true
          this.userError.firstName.message = "First Name required"
        }


      }
      if (!this.validatedPhone) {
        hasError = false
        this.userError.phone.isError = true
        this.userError.phone.message = "Please enter a valid phone number"

        setTimeout(() => {
          this.userError.phone.isError = false
          this.userError.phone.message = ""
        }, 3000);
      }
      if (!this.userInfo.lastName || this.userInfo.lastName.match(/^\s+$/) !== null || this.userInfo.lastName.indexOf(' ') >= 0) {

        if (!this.userInfo.lastName.indexOf(' ') >= 0) {
          hasError = true
          this.userError.lastName.isError = true
          this.userError.lastName.message = "Blank Spaces are not allowed"

        }
        else {

          hasError = true
          this.userError.lastName.isError = true
          this.userError.lastName.message = "Last Name required"
        }

      }

      let findSec = false
      for (const key in this.userSecurity) {
        let value = this.userSecurity[key];
        if (value && value.length) {
          findSec = true
        }
      }
      if (findSec) {
        if (!this.userSecurity.currentpassword) {
          hasError = true
          this.securityError.currentpassword.isError = true
          this.securityError.currentpassword.message = "Current password is required"
        }
        if (!this.userSecurity.password) {
          hasError = true
          this.securityError.password.isError = true
          this.securityError.password.message = "Password is required"
        } else {
          if (this.passwordStrengthPoints < 3) {
            hasError = true
            this.securityError.password.isError = true
            this.securityError.password.message = "You entered a weak a password"
          }
        }
        if (!this.userSecurity.confirmpassword) {
          hasError = true
          this.securityError.confirmpassword.isError = true
          this.securityError.confirmpassword.message = "Confirm password is required"
        } else {
          if (this.userSecurity.password != this.userSecurity.confirmpassword) {
            hasError = true
            this.securityError.confirmpassword.isError = true
            this.securityError.confirmpassword.message = "Password must match"
          }
        }

      }

      console.log('hasError', hasError)
      if (!hasError) {
        try {
          this.disabled = true
          this.isSecurity = true
          let finalObj = {
            ...this.userInfo, ...this.userSecurity
          }
          //  let finalObj = {
          //    userInfo:this.userInfo,userSecurity:this.userSecurity
          //  }
          const res = await userApi.updateUser(finalObj)
          //  console.log(JSON.stringify(finalObj))
          if (res.data.success) {
            this.getUser();
            this.disabled = false
            this.isSecurity = false
            this.usersuccess = "User updated successfully"
          } else {
            this.disabled = false
            this.isSecurity = false
            this.usererror = res.data.message
          }
        } catch (err) {
          this.disabled = false
          this.isSecurity = false
          this.err = "Server Error"
        }


      }
    },

    async MakeDefault(item) {
      const updatedCards = this.cardDetails.map(card => {
        if (card.cardId === item.cardId) {
          return {
            ...card,
            default: true
          };
        }
        else{

          return {
            ...card,
            default: false
          };
        }
      });


      this.cardDetails = updatedCards
      let isNewCard = item.cardFingerPrint == null ? true : false
        this.$store.commit('CHANGE_DEFAULT_CARD', { isNewCard: isNewCard, cardId: item.cardId })

    },

    validatePassword(value) {
      let _passwordStrength = passwordStrength(value);
      this.passwordStrengthPoints =
        _passwordStrength.contains.length +
        (_passwordStrength.length < 6 ? 0 : 1);

      if (_passwordStrength.length < 6)
        throw "Password must be at least 6 characters long.";

      return this.passwordStrengthPoints >= 3;
    },

    checkInputPassword(value) {
      if (value) {
        this.validatePassword(value);
      }
    },

  },

  watch: {
    signedInUser: {
      handler(n, o) {
        this.userData = n
        // const response = await axios.get(GET_COSTOMER_DETAILS)
        // this.signedInUser.cardInfo[0] = response.data.payload.card[0]
        this.userInfo.firstName = this.userData.firstName
        this.userInfo.lastName = this.userData.lastName
        this.userInfo.email = this.userData.email
        this.userInfo.phone = this.userData.phone
        // this.cardDetails = this.userData.cardInfo
        this.userProductId = this.userData.productId
        this.userPlanId = this.userData.planId
        this.userPlanStatus = (this.userData.status == 'trialing') ? false : true;

        // this.changeDataStreamDisable(this.userData.dataStreamCountry[0].updatedDate,moment().utc().startOf("day").toDate())
        this.changeDataStreamDisable(this.userData.dataStreamCountry[0].updatedDate, moment().utc().toDate())
        //console.log('final user Selected Plan', this.selectedPlan);


      },
      deep: true
    },
    newProductId: function () {
      // this.planAlert = "You are about to change your plan"
    },
    promoCode: function () {
      this.codeFieldError = false
    },
    newPlanId: function () {
      // this.planAlert = "You are about to change your plan"
    },

    planAlert: function () {
      setTimeout(() => {
        this.planAlert = ""
      }, 5000)
    },

    usersuccess: function () {
      this.usererror = ""
      this.securityerror = ""
      this.securitysuccess = ""
      setTimeout(() => {
        this.usersuccess = ""
      }, 5000)
    },
    usererror: function () {
      this.usersuccess = ""
      this.securityerror = ""
      this.securitysuccess = ""
      setTimeout(() => {
        this.usererror = ""
      }, 5000)
    },
    securitysuccess: function () {
      this.securityerror = ""
      this.usersuccess = ""
      this.usererror = ""
      setTimeout(() => {
        this.securitysuccess = ""
      }, 5000)
    },
    securityerror: function () {
      this.securitysuccess = ""
      this.usersuccess = ""
      this.usererror = ""
      setTimeout(() => {
        this.securityerror = ""
      }, 5000)
    },

    datastreammessage: function () {
      setTimeout(() => {
        this.datastreammessage = ""
      }, 5000)
    },

    newProductId: function () {
      this.newPlanId = ""
    },
    countryCode(val) {
      if (val == 'US') {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/
      } else if (val == 'CA') {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/
      } else if (val == 'GB') {
        this.regex = /^\d{10}$/
      } else if (val == 'AU') {
        this.regex = /^\d{8}$/
      }
    },

    'userInfo.firstName': function () {
      this.userError.firstName.isError = false
      this.userError.firstName.message = ""

    },
    'userInfo.lastName': function () {
      this.userError.lastName.isError = false
      this.userError.lastName.message = ""


    },
    'userInfo.phone': function (val) {
      this.validatePhone(val)


    },

    'userSecurity.currentpassword': function () {
      this.securityError.currentpassword.isError = false
      this.securityError.currentpassword.message = ""


    },
    'userSecurity.password': function () {
      this.securityError.password.isError = false
      this.securityError.password.message = ""

      if (this.userSecurity.password == "") {
        this.passwordStrengthPoints = 0
      }

    },
    'userSecurity.confirmpassword': function () {
      this.securityError.confirmpassword.isError = false
      this.securityError.confirmpassword.message = ""
    },
    newCard_get:{
      handler(n,o){
        let newCardDetails = this.cardStore_get[this.cardStore_get.length-1]
        let isAvailable = this.cardDetails.find(x => x.cardId == newCardDetails.cardId)
        if (!isAvailable) {
          if (this.cardDetails.length == 0) {
            newCardDetails.default = true
          }
          this.cardDetails.push(newCardDetails)
        }
      }
    }
  },

  async mounted() {
    await this.getProducts();
    await this.getUser();
    await this.getPriceData()
    this.countryCode = this.signedInUser?.countryCode
    // this.getPhone()
    // this.dayDifference()
    if (this.signedInUser.dataStreamCountry[0].updatedDate) {
      // this.changeDataStreamDisable(this.signedInUser.dataStreamCountry[0].updatedDate,moment().utc().startOf("day").toDate())
      this.changeDataStreamDisable(this.signedInUser.dataStreamCountry[0].updatedDate, moment().utc().toDate())
    }
    this.changeDate()
    const response = await axios.get(GET_COSTOMER_DETAILS)
    if(this.signedInUser.cardInfo.length>0 ){
      this.signedInUser.cardInfo[0] = response.data.payload.card[0]
      this.cardDetails = this.signedInUser.cardInfo
    }
      if(response.data.payload.billingAddress){
        this.$store.commit("ADDRESS_SET",response.data.payload.billingAddress)
      }

    const res = await userApi.getUser();
      // console.log("userdata", res.data.payload.user)
      if (res.data.success) {
        let userDetails = res.data.payload.user;
        this.newPlanId = userDetails.planId
        // if (userDetails?.chartConfig?.overlays) {
        //   this.$store.commit("SET_ALL_INDICATORS", userDetails.chartConfig.overlays)
        // }
        // this.$store.commit("SET_USER_DETAILS", userDetails);
        // console.log("get User data", userDetails)
      } else {
      }
  },
}
</script>

<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.datastream option:hover {
  background-color: #1BA1E2 !important;
}


.divDisabled {
  pointer-events: none;
}


.btnBg:hover {
  background: linear-gradient(270deg, rgba(2, 189, 45, 0.24) 0%, rgba(2, 189, 45, 0) 50%, rgba(2, 189, 45, 0.24) 100%);
}

.btnLoadingBg:hover {
  background: linear-gradient(270deg, rgba(248, 249, 249, 0.21) 0%, rgba(248, 249, 249, 0) 52.08%, rgba(248, 249, 249, 0.21) 100%)
}
</style>
