<template>
    <tailwind-modal :show-modal="newFiltermodalshow">
        <div class="min-w-[38rem] px-10 py-4" style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    ">
            <div class="flex pb-4">ADD FILTER</div>

            <div class="">
                
                <div class="form-group  mb-2 flex justify-center" v-for="(p, index) in newParameterLength" :key="index">
                    <input type="number"  
                    :placeholder="'Enter Parameter '+ (index + 1)"  
                    v-model.number="newParameterLength[index]"
                    class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" />
                </div>
            </div>

            <div class="flex justify-end">
                <button class="ok-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm"
                    @click="newCloseModal">Okay</button>
                    <button class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm" @click="CancelModal">Cancel</button>
                
            </div>

        </div>

    </tailwind-modal>
</template>
<script>
import TailwindModal from "@/components/popups/TailwindModal";
export default {
    name: 'AddFilter',
    components: { TailwindModal },
    data() {
        return {
            activeIndicatorParameters: [],
            selectedIndicator: '',
            selectedIndicatorName: '',
            parameterDataObject: []
        }
    },
    props: {
        newFiltermodalshow: {
            type: Boolean,
            default: false
        },
        newParameterLength: {
            type: Array,
            default: null,
        },
        // criteria1_Or_criteria2:{
        //     type:String,
        //     default: null
        // }
        // NewSearch_JSONData_Array:{
        //     type:Array,
        //     default:null
        // },
        // NewSearchData_Selected:{
        //     type:Object,
        //     default:null
        // },
        // typeSelected:{
        //     type: String,
        //     default: null
        // },
        // criteria1Key:{
        //     type:String,
        //     default: null
        // }
        // isEdit:{
        //     type:Boolean,
        //     default:false
        // },
        // indicatorsDropdown:{
        //     type:Object
        // },
        // selectedData:{
        //     type:Object
        // }
    },
    methods: {
        newCloseModal() {
            this.parameterDataObject = this.newParameterLength
            this.$emit('newSaveModal', this.parameterDataObject)
            this.parameterDataObject = []
        },
        CancelModal(){
            this.$emit('newCloseModal')
        }
        // saveChanges() {
        //     console.log('this.dataObject', this.dataObject)
        //     this.$emit('saveChanges', this.dataObject, { selectedIndicator: this.selectedIndicator, selectedIndicatorName: this.selectedIndicatorName }, this.isEdit)
        // }
    }
    //         selectIndicator(value) {
    //             // console.log("........ owais1", value)
    //             if (this.indicatorsDropdown[value] === undefined)
    //                 return

    //             let newObj = {}
    //             let params = this.indicatorsDropdown[value].parameters
    //             for (const p of params) {
    //                 // console.log(p)
    //                 newObj[p.id] = p.default;
    //             }
    //             this.activeIndicatorParameters = this.indicatorsDropdown[value].parameters
    //             this.dataObject = newObj
    //             this.selectedIndicator = value                
    //             this.selectedIndicatorName = this.indicatorsDropdown[value].label

    //         },
    //     },
    //     updated(){
    //         console.log('updated',this.selectedData && this.isEdit)
    //         if(this.selectedData && this.isEdit){
    //             this.dataObject = this.selectedData.data
    //             this.selectedIndicator = this.selectedData.indicator
    //             // this.activeIndicatorParameters = this.indicatorsDropdown[this.selectedData.indicator]
    //             // this.selectedIndicatorName = $event.target.options[$event.target.options.selectedIndex].text

    //         }
    //     },
    //     watch:{
    //         selectedIndicator(n){

    //             this.selectIndicator(n)
    //         }
    //     }
}

</script>
<style scoped>
.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
    /* width: 20px !important; */
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}
</style>