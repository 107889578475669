export default //  [
//   { colName: "name", label: "Symbol" },
//   { colName: "sector", label: "Sector", metaInfo: true },

//   { colName: "revenue", label: "Revenue", metaInfo: true },
//   { colName: "roe", label: "ROE", metaInfo: true },
//   { colName: "roi", label: "ROI", metaInfo: true },
//   { colName: "mkt_cap_m", label: "Market Cap", metaInfo: true },
//   { colName: "volume", label: "Volume", metaInfo: true },

//   // new column
//   { colName: "20_mav_gap", label: "20_MAV_GAP", metaInfo: true },
//   { colName: "allordinaries", label: "AllOrdinaries", metaInfo: true },
//   { colName: "bk_val", label: "Book Value", metaInfo: true },
//   { colName: "chg%_26_weeks", label: "Chg 26 Week", metaInfo: true },
//   { colName: "chg%_52_weeks", label: "Chg 52 Week", metaInfo: true },
//   { colName: "shares_out", label: "Shares Out", metaInfo: true },
//   { colName: "tgt_price", label: "Target Price", metaInfo: true },
//   { colName: "turnover", label: "Turnover", metaInfo: true },
//   { colName: "tgt__close", label: "Target Last Close", metaInfo: true },

//   { colName: "dividend_$", label: "Dividend_$", metaInfo: true },
//   { colName: "dividend_yield_%  ", label: "Dividend Yield", metaInfo: true },
//   { colName: "ebitda", label: "Ebtida", metaInfo: true },
//   { colName: "eps", label: "EPS", metaInfo: true },
//   { colName: "industry", label: "Industry", metaInfo: true },
//   { colName: "last_close", label: "Last Close", metaInfo: true },
//   { colName: "p_e", label: "P E", metaInfo: true },
//   { colName: "company_name", label: "Company Name", metaInfo: true },
//   { colName: "operating_margin", label: "Operating Margin", metaInfo: true },
//   {
//     colName: "two_hundred_mav_gap",
//     label: "Two Hundred Mav Gap",
//     metaInfo: true,
//   },
//   { colName: "thirty_day_%_change", label: "30 Day %Change", metaInfo: true },
//   { colName: "ten_day_%_change", label: "10 Day %Change", metaInfo: true },
//   {
//     colName: "t_o_less_than_10k_last_30_days",
//     label: "T-O < 10k Last 30 Days",
//     metaInfo: true,
//   },
//   { colName: "one_day_%_change", label: "One Day %Change", metaInfo: true },
//   { colName: "not_in_index", label: "Not in Index", metaInfo: true },
//   {
//     colName: "no_zero_volume_days_last_30_days",
//     label: "No 0 Volume Days Last 30 Days",
//     metaInfo: true,
//   },
//   { colName: "marketId", label: "Market Id", metaInfo: true },
//   {
//     colName: "lowest_turnover_last_30_days",
//     label: "Lowest Turnover Last 30 Days",
//     metaInfo: true,
//   },
//   {
//     colName: "lowest_turnover_30d",
//     label: "Lowest Turnover 30 Days",
//     metaInfo: true,
//   },
//   { colName: "hundred_mav_gap", label: "100 MAV Gap", metaInfo: true },
//   { colName: "five_day_%_change", label: "5 Days %Change", metaInfo: true },
//   { colName: "first_trade_date", label: "First Trade Date", metaInfo: true },
//   { colName: "exchange", label: "Exchange", metaInfo: true },
//   {
//     colName: "estimated_eps_calendar_year",
//     label: "Est EPS Calendar Year",
//     metaInfo: true,
//   },
//   {
//     colName: "days_since_volume_zero",
//     label: "Days Since Volume 0",
//     metaInfo: true,
//   },
//   {
//     colName: "days_since_lowest_turnover",
//     label: "Days Since lowest Turnover",
//     metaInfo: true,
//   },
//   { colName: "cls_52_weeks_ago", label: "Cls 52 Weeks Ago", metaInfo: true },
//   { colName: "cls_26_weeks_ago", label: "Cls 26 Weeks Ago", metaInfo: true },
//   {
//     colName: "close_below_52_week_low",
//     label: "Cls Below 52 Weeks Low",
//     metaInfo: true,
//   },
//   {
//     colName: "close_below_26_week_low",
//     label: "Cls Below 26 Weeks Low",
//     metaInfo: true,
//   },
//   {
//     colName: "close_below_13_week_low",
//     label: "Cls Below 13 Weeks Low",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_200_Day_Mov_Avg",
//     label: "Cls Above 200 Day Mov Avg",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_100_Day_Mov_Avg",
//     label: "Cls Above 100 Day Mov Avg",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_52_week_high",
//     label: "Cls Above 52 Week High",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_26_week_high",
//     label: "Cls Above 26 Week High",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_13_week_high",
//     label: "Cls Above 13 Week High",
//     metaInfo: true,
//   },
//   {
//     colName: "close_above_20_Day_Mov_Avg",
//     label: "Cls Above 20 Day Mov Avg",
//     metaInfo: true,
//   },
//   { colName: "close_13_weeks_ago", label: "Cls 13 Weeks Ago", metaInfo: true },
//   { colName: "chg%_13_weeks", label: "Change% 13 Weeks", metaInfo: true },
//   { colName: "category", label: "Category", metaInfo: true },
//   { colName: "avg_vol_30d", label: "Avg Volume 30d", metaInfo: true },
//   {
//     colName: "avg_turnover_last_30_days",
//     label: "Avg Turnover Last 30d",
//     metaInfo: true,
//   },
//   {
//     colName: "Lowest V last 30 days",
//     label: "Lowest V Last 30d",
//     metaInfo: true,
//   },
//   { colName: "asx20", label: "ASX20", metaInfo: true },
//   { colName: "asx50", label: "ASX50", metaInfo: true },
//   { colName: "asx100", label: "ASX100", metaInfo: true },
//   { colName: "asx200", label: "ASX200", metaInfo: true },
//   { colName: "asx300", label: "ASX300", metaInfo: true },
//   { colName: "allordinaries", label: "All Ordinaries", metaInfo: true },
//   { colName: "nasdaq100", label: "NASDAQ100", metaInfo: true },
//   { colName: "russell_1000", label: "Russell1000", metaInfo: true },
//   { colName: "russell_2000", label: "Russell2000", metaInfo: true },
//   { colName: "russell_3000", label: "Russell3000", metaInfo: true },
//   { colName: "sp100", label: "SP100", metaInfo: true },
//   { colName: "sp500", label: "SP500", metaInfo: true },
//   { colName: "spmidcap400", label: "SPMIDCAP400", metaInfo: true },
//   { colName: "spmidcap600", label: "SPMIDCAP600", metaInfo: true },
//   { colName: "tsx60", label: "TSX60", metaInfo: true },
//   { colName: "tsxcompletion", label: "TSXCompletion", metaInfo: true },
//   { colName: "tsxcomposite", label: "TSXComposite", metaInfo: true },
//   { colName: "tsxdivarist", label: "TSXDivArist", metaInfo: true },
//   { colName: "tsxmidcap", label: "TSXMidCap", metaInfo: true },
//   { colName: "tsxsmallcap", label: "TSXSmallCap", metaInfo: true },
// ];
[
  {
    colName: "symbol",
    label: "Symbol",
    metaInfo: true,
  },
  {
    colName: "company_name",
    label: "Company Name",
    metaInfo: true,
  },
  {
    colName: "sector",
    label: "Sector"    ,
    metaInfo: true,
  },
  {
    colName: "industry",
    label: "Industry",
    metaInfo: true,
  },
  {
    colName: "first_traded",
    label: "First Traded",
    metaInfo: true,
  },
  {
    colName: "market_heat",
    label: "Market Heat",
    metaInfo: true,
  },
  {
    colName: "fs%",
    label: "Fundamental Score",
    metaInfo: true,
  },
  {
    colName: "ts%",
    label: "Technical Score",
    metaInfo: true,
  },
  {
    colName: "stock_alerts",
    label: "Stock Alert",
    metaInfo: true,
  },
  {
    colName: "confidance_indicator",
    label: "Confidance Indicator",
    metaInfo: true,
  },
  {
    colName: "close",
    label: "Close",
    metaInfo: true,
  },
  {
    colName: "tgt_price",
    label: "Tgt Price",
    metaInfo: true,
  },
  {
    colName: "tgt__close",
    label: "Tgt-Close",
    metaInfo: true,
  },
  {
    colName: "volume",
    label: "Volume",
    metaInfo: true,
  },
  {
    colName: "days_since_vol_0",
    label: "Vol 0 Days",
    metaInfo: true,
  },
  {
    colName: "avg_vol_30d",
    label: "Avg Vol 20d",
    metaInfo: true,
  },

  {
    colName: "lwst_vol_30_days",
    label: "Lwst Vol 20d",
    metaInfo: true,
  },
  {
    colName: "turnover",
    label: "Turnover",
    metaInfo: true,
  },
  {
    colName: "avg_t_o_30d",
    label: "Avg T/O 20d",
    metaInfo: true,
  },
  {
    colName: "lwst_t/o_30d",
    label: "Lwst T/O 20d",
    metaInfo: true,
  },
  {
    colName: "days_since_lwst_t/o",
    label: "Lwst T/O D",
    metaInfo: true,
  },
  {
    colName: "t/o_ls_than_100k_20d",
    label: "T/O < 100k 20d",
    metaInfo: true,
  },
  {
    colName: "gt_than_200d_avg",
    label: "> 200d Avg",
    metaInfo: true,
  },
  {
    colName: "200_mav_gap",
    label: "200 MA Gap",
    metaInfo: true,
  },
  {
    colName: "gt_than_100d_avg",
    label: "> 100d Avg",
    metaInfo: true,
  },
  {
    colName: "100_mav_gap",
    label: "100 MA Gap",
    metaInfo: true,
  },
  {
    colName: "gt_than_20d_avg",
    label: "> 20d Avg",
    metaInfo: true,
  },
  {
    colName: "20_mav_gap",
    label: "20 MA Gap",
    metaInfo: true,
  },
  {
    colName: "13w_low",
    label: "13w Low",
    metaInfo: true,
  },
  {
    colName: "26w_low",
    label: "26w Low",
    metaInfo: true,
  },
  {
    colName: "52w_low",
    label: "52w Low",
    metaInfo: true,
  },
  {
    colName: "13w_high",
    label: "13w High",
    metaInfo: true,
  },
  {
    colName: "26w_high",
    label: "26w High",
    metaInfo: true,
  },
  {
    colName: "52w_high",
    label: "52w High",
    metaInfo: true,
  },
  {
    colName: "1d_%_chg",
    label: "1d % Chg",
    metaInfo: true,
  },
  {
    colName: "5d_%_chg",
    label: "5d % Chg",
    metaInfo: true,
  },
  {
    colName: "10d_%_chg",
    label: "10d % Chg",
    metaInfo: true,
  },
  {
    colName: "30d_%_chg",
    label: "20d % Chg",
    metaInfo: true,
  },
  {
    colName: "cls_13w_ago",
    label: "Cls 13w Ago",
    metaInfo: true,
  },
  {
    colName: "chg%_13w",
    label: "Chg% 13w",
    metaInfo: true,
  },
  {
    colName: "cls_26w_ago",
    label: "Cls 26w Ago",
    metaInfo: true,
  },
  {
    colName: "chg%_26w",
    label: "Chg% 26w",
    metaInfo: true,
  },
  {
    colName: "cls_52w_ago",
    label: "Cls 52w Ago",
    metaInfo: true,
  },
  {
    colName: "chg%_52w",
    label: "Chg% 52w",
    metaInfo: true,
  },
  {
    colName: "div_%",
    label: "Div %",
    metaInfo: true,
  },
  {
    colName: "eps",
    label: "EPS",
    metaInfo: true,
  },
  {
    colName: "ex_dividend",
    label: "Ex Dividend",
    metaInfo: true,
  },
  {
    colName: "dividend_pay",
    label: "Div Pay Date",
    metaInfo: true,
  },
  {
    colName: "div_$",
    label: "Div $",
    metaInfo: true,
  },
 
  {
    colName: "p_e",
    label: "P/E",
    metaInfo: true,
  },
  {
    colName: "est_eps_yr",
    label: "Est EPS Yr",
    metaInfo: true,
  },
  {
    colName: "revenue",
    label: "Revenue",
    metaInfo: true,
  },
  {
    colName: "op_mgn",
    label: "Op Mgn",
    metaInfo: true,
  },
  {
    colName: "bk_val",
    label: "Bk Val",
    metaInfo: true,
  },
  {
    colName: "ebitda",
    label: "EBITDA",
    metaInfo: true,
  },
  {
    colName: "roi",
    label: "ROI",
    metaInfo: true,
  },
  {
    colName: "roe",
    label: "ROE",
    metaInfo: true,
  },
  {
    colName: "shares_out",
    label: "Shares Out",
    metaInfo: true,
  },
  {
    colName: "mkt_cap_m",
    label: "Market Cap",
    metaInfo: true,
  },
  {
    colName: "highest_index",
    label: "Highest Index",
    metaInfo: true,
  },
  {
    colName: "exchange",
    label: "Exchange",
    metaInfo: true,
  },
  // {
  //   colName: "category",
  //   label: "Category",
  //   metaInfo: true,
  // },
  // {
  //   colName: "djia_dow",
  //   label: "DJIA DOW",
  //   metaInfo: true,
  // },
  {
      colName: "frank_pct_au",
    label: "Franking",
    metaInfo: true,
  },
  // {
  //   colName: "s&p_100",
  //   label: "s&p 100",
  //   metaInfo: true,
  // },
  // {
  //   colName: "s&p_500",
  //   label: "s&p 500",
  //   metaInfo: true,
  // },
  // {
  //   colName: "nasdaq_100",
  //   label: "NASDAQ 100",
  //   metaInfo: true,
  // },
  // {
  //   colName: "s&p_400",
  //   label: "s&p 400",
  //   metaInfo: true,
  // },
  // {
  //   colName: "s&p_600",
  //   label: "s&p 600",
  //   metaInfo: true,
  // },
  // {
  //   colName: "russell_1000",
  //   label: "RUSSELL 1000",
  //   metaInfo: true,
  // },
  // {
  //   colName: "russell_2000",
  //   label: "RUSSELL 2000",
  //   metaInfo: true,
  // },
  // {
  //   colName: "russell_3000",
  //   label: "RUSSELL 3000",
  //   metaInfo: true,
  // },
  // {
  //   colName: "asx20",
  //   label: "ASX20",
  //   metaInfo: true,
  // },
  // {
  //   colName: "asx50",
  //   label: "ASX50",
  //   metaInfo: true,
  // },
  // {
  //   colName: "asx100",
  //   label: "ASX100",
  //   metaInfo: true,
  // },
  // {
  //   colName: "asx200",
  //   label: "ASX200",
  //   metaInfo: true,
  // },
  // {
  //   colName: "asx300",
  //   label: "ASX300",
  //   metaInfo: true,
  // },
  // {
  //   colName: "allordinaries",
  //   label: "ALL ORDINARIES",
  //   metaInfo: true,
  // },
  // {
  //   colName: "tsx60",
  //   label: "TSX60",
  //   metaInfo: true,
  // },
  // {
  //   colName: "tsxcomposite",
  //   label: "TSXCOMPOSITE",
  //   metaInfo: true,
  // },
  // {
  //   colName: "tsxsmallcap",
  //   label: "TSXSMALLCAP",
  //   metaInfo: true,
  // },
];

const headers = [
  "Symbol",
  "Company Name",
  "Sector",
  "Industry",
  "First Traded",
  "Close",
  "Tgt Price",
  "Tgt - Close",
  "Volume",
  "Vol 0 Days",
  "Avg Vol 30d",
  "0 Vol Days",
  "Lwst Vol 20d",
  "Turnover",
  "Avg T/O 30d",
  "Lwst T/O 30d",
  "Lwst T/O D",
  "t/o_ls_than_100k_20d",
  "> 200d Avg",
  "200 MAV Gap",
  "> 100d Avg",
  "100 MAV Gap",
  "> 20d Avg",
  "20 MAV Gap",
  "13w Low",
  "26w Low",
  "52w Low",
  "13w High",
  "26w High",
  "52w High",
  "1d % Chg",
  "5d % Chg",
  "10d % Chg",
  "20d % Chg",
  "Cls 13w Ago",
  "Chg% 13w",
  "Cls 26w Ago",
  "Chg% 26w",
  "Cls 52w Ago",
  "Chg% 52w",
  "Div %",
  "Div $",
  "EPS",
  "P/E",
  "Est EPS Yr",
  "Revenue",
  "Op Mgn",
  "Bk Val",
  "EBITDA",
  "ROI",
  "ROE",
  "Shares Out",
  "Mkt Cap",
  "Highest Index",
  "Exchange",
];
