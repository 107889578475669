<template>
  <div class="ml-[0.6vw]">
    <!-- <div
      :style="{ 'margin-left': `-${depth * 2}px` }"
      :class="{ 'cursor-pointer': node.children && node.children.length > 0 }"
      class="flex justify-between items-center pr-[1vw]"
    > -->
    <div
      :class="getIndentStyle(node)"
      class="flex justify-between items-center pr-[1vw] 0 "
    >
      

      <span class="type text-white">
        <span v-if="hasChildren && expanded">
          <font-awesome-icon :style="getIconStyle(node)" class="w-[.4vw] text-white" v-if="node.children && node.children.length"
                               icon="fa-solid fa-angle-down"/>
          <font-awesome-icon
            class="w-[.8vw] mx-2"
            v-if="node.children"
            icon="fa-solid fa-folder-open"
          />
        </span>

        <span v-if="hasChildren && !expanded">
          <font-awesome-icon :style="getIconStyle(node)" class="w-[.4vw] text-white" v-if="node.children && node.children.length"
                               icon="fa-solid fa-angle-right"/>
          <font-awesome-icon
            class="w-[.8vw] mx-2"
            v-if="node.children"
            icon="fa-solid fa-folder"
          />
        </span>

    
        <!-- Criteria node -->
        <span v-if="!node.children" @mouseover="hoverKey = node.key" @mouseleave="hoverKey = ``"  :class="!node.permission.includes(signedInUser.role) ? 'cursor-not-allowed' : ''">     
          <div class="inline-flex ml-4">
            <svg
              class="h-[.8vw] w-[.8vw]"
              :stroke="node.key == hoverKey && node.permission.includes(signedInUser.role)  ? 'green' : ''"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.45">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.21875 10.7812C4.21875 10.6569 4.26814 10.5377 4.35604 10.4498C4.44395 10.3619 4.56318 10.3125 4.6875 10.3125H14.0625C14.1868 10.3125 14.306 10.3619 14.394 10.4498C14.4819 10.5377 14.5312 10.6569 14.5312 10.7812C14.5312 10.9056 14.4819 11.0248 14.394 11.1127C14.306 11.2006 14.1868 11.25 14.0625 11.25H4.6875C4.56318 11.25 4.44395 11.2006 4.35604 11.1127C4.26814 11.0248 4.21875 10.9056 4.21875 10.7812ZM2.34375 7.03125C2.34375 6.90693 2.39314 6.7877 2.48104 6.69979C2.56895 6.61189 2.68818 6.5625 2.8125 6.5625H12.1875C12.3118 6.5625 12.431 6.61189 12.519 6.69979C12.6069 6.7877 12.6562 6.90693 12.6562 7.03125C12.6562 7.15557 12.6069 7.2748 12.519 7.36271C12.431 7.45061 12.3118 7.5 12.1875 7.5H2.8125C2.68818 7.5 2.56895 7.45061 2.48104 7.36271C2.39314 7.2748 2.34375 7.15557 2.34375 7.03125ZM0.46875 3.28125C0.46875 3.15693 0.518136 3.0377 0.606044 2.94979C0.693951 2.86189 0.81318 2.8125 0.9375 2.8125H10.3125C10.4368 2.8125 10.556 2.86189 10.644 2.94979C10.7319 3.0377 10.7812 3.15693 10.7812 3.28125C10.7812 3.40557 10.7319 3.5248 10.644 3.61271C10.556 3.70061 10.4368 3.75 10.3125 3.75H0.9375C0.81318 3.75 0.693951 3.70061 0.606044 3.61271C0.518136 3.5248 0.46875 3.40557 0.46875 3.28125Z"
                  fill="grey"
                />
                
              </g>
            </svg>
            <!-- :fill="node.key == svgColorKey ? 'green':'grey'" -->
          </div>
          <div class="inline-flex">
            <button
            @click="nodeClicked(node.key)"
            :ref="'e_' + id"
            :disabled="!node.permission.includes(signedInUser.role)"
           
            class="title text-[.78vw] align-middle  ml-[10px]"
            :class="[
              criteriaTextDefault(node),
              node.key == hoverKey ? 'text-green-600' : '',
            ]"
            :title="
              node.permission.includes(signedInUser.role)
                ? ''
                : 'Upgrade Your Plan'
            "
          >
            {{ node.title }}
          </button>

          </div>
        </span>
        <!-- Folder node -->
        <span v-else>
          <button
          @click="nodeClicked(node.key)"
            :ref="'e_' + id"
            :disabled="!node.permission.includes(signedInUser.role)"
            class="title text-[.78vw] align-middle cursor-pointer ml-[10px]"
            :class="[treeStyle(node),hasChildren && expanded ? 'Inter-Bold':'']"
            :title="
              node.permission.includes(signedInUser.role)
                ? ''
                : 'Upgrade Your Plan'
            "
          >
            {{ node.title }}
          </button>
        </span>

      </span>


      <CardButtons
        v-if="node.permission.includes(signedInUser.role) && !node.children && signedInUser.role=='premium' && !node.permission.includes('plus')"
        btnType="premium-unlock"
        class="w-fit"
        btnText="Premium"
      />

      <CardButtons
        v-if="!node.permission.includes(signedInUser.role) && !node.children"
        btnType="premium-lock"
        class="w-fit"
        btnText="Premium"
      />
    
    </div>

    <TreeBrowserBeta
      :id="child.key"
      :ref="child.key"
      v-for="(child, i) in node.children"
      :key="i"
      :node="child"
      :depth="depth + 1"
      :map="[...map, child.title]"
      :idMap="[...idMap, child.key]"
      :criteria="{ ...criteria, ...child.criteria }"
      @onClick="
        (n, mapProp, criteriaProp, idProp, idMapProp) =>
          $emit('onClick', n, mapProp, criteriaProp, idProp, idMapProp)
      "
      :style="expandable"
      @treeLoad="treeLoad"
    />
  </div>
</template>

<script>
import { Tree_event } from "@/components/tree/tree_event";
import SvgListIcon from "@/components/tree/SvgListIcon";
import CardButtons from "@/components/buttons/CardButtons.vue";
import { mapGetters } from "vuex";
import TreeStyle from "@/components/stocklistStyle/treeStyle";

export default {
  name: "TreeBrowserBeta",
  components: { SvgListIcon, CardButtons },
  props: {
    node: Object,
    map: Array,
    idMap: Array,
    depth: {
      type: Number,
      default: 0,
    },
    mainNode: {
      type: Boolean,
      default: false,
    },
    criteria: {
      type: Object,
      default() {
        return {};
      },
    },
    id: [Number, String],
  },

  data() {
    return {
      hoverKey: "",
      svgColorKey:"",
      borderIs: "",
      expanded: false,
      isActiveColor: false,
      isFileColor: false,
      childNodes: [],
      nodeRef: null,
    };
  },

  methods: {
  
    getChildIconStyle(nodeItem) {
      let level2 = TreeStyle.level_2.icon;
      if (nodeItem.style == "level_2") {
        return `hover:stroke-[${level2.hover.color}] active:stroke-[${level2.active.color}]`;
      }
    },

    criteriaTextDefault(node) {
      return node.permission.includes(this.signedInUser.role) ? 
      `cursor-pointer hover:underline text-[${TreeStyle.level_2.text.default.color}] active:text-[#ced4d6] focus:text-[#02BD2D]` : 
      `cursor-not-allowed text-[#747474]`;
    },

    treeStyle(nodeItem) {
      let level1 = TreeStyle.level_1.text;
      let level2 = TreeStyle.level_2.text;

      if (
        !nodeItem.children &&
        !this.node.permission.includes(this.signedInUser.role)
      ) {
        return "text-[#00C92E]";
      }

      if (nodeItem.style == "level_1") {
        return `hover:text-[${level1.hover.color}] focus:font-black text-[${level1.default.color}] active:text-[${level1.active.color}]`;
      }
      if (nodeItem.style == "level_2") {
        return `hover:text-[${level2.hover.color}] text-[${level2.default.color}] focus:text-red-600 active:text-[${level2.active.color}]`;
      }
    },

    treeLoad(id) {
      
      this.childNodes.push(id);
    },
    fireOnClick() {
      this.$emit(
        "onClick",
        this.node,
        this.map,
        this.id,
        this.idMap,
        this.expanded
      );
    },

    nodeClicked(key) {
      // this.svgColorKey = key
      // console.log("svg color key",key)
      if (this.node.children && this.node.children.length > 0) {
        this.expanded = !this.expanded;
        
        this.fireOnClick();
      } else {
        // if (!this.expanded) {
        //   this.expanded = true;
        //   this.fireOnClick();
        // } else {
        //   if (this.node.children && this.node.children.length > 0) {
        //     this.expanded = !this.expanded;
        //   }
        // }
        this.fireOnClick();
      }
    },

    getIconStyle() {
      if (this.node.isRole === false) {
        return {
          color: "#02BD2D",
        };
      }
    },


    getIndentStyle(item) {
      if (item.children && item.children.length > 0) {
        return `ml-[${this.depth * 2}px]`;
      }
    },

    getFileStyle(item) {
      if (!item.children) {
        return {
          marginLeft: `-${this.depth * 7}px`,
        };
      }
    },
  },
  computed: {
    ...mapGetters(["signedInUser"]),

    iconColorComp() {
      let iconColorComp = this.node.favColor
        ? this.node.favColor
        : this.node.iconColor;
      return iconColorComp;
    },
    isFileType() {
      if (!this.node.children) {
        return true;
      }
    },
    isFolderType() {
      if (this.node.children && this.node.children.length > 0) {
        return true;
      }
    },
    expandable() {
      return this.expanded ? {} : { display: "none" };
    },
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
  },
  async mounted() {
    this.nodeRef = this.$refs["e_" + this.id];
    // console.log("id",this.id)
    this.$emit("treeLoad", this.id);
    Tree_event.$on("disableTree", (idMap) => {
        // console.log("idsNotMatched",this.idMap,idMap)
        if(this.idMap && this.idMap.length > 0 && this.idMap[0] !== idMap[0]){       
            this.expanded = false;
        } 
    });
  },

};
</script>

<style scoped>
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  /* line-height: 15px; */
}
.btnDisabled {
  pointer-events: none;
}
</style>
