import axios from "../axios";
import { deleteDates, loadDates, saveDates } from "./apiRoutes";

export default {
  loadDates: async (payload) => {
    const response = await axios.post(loadDates,payload);
    return response;
  },
  saveDates: async (payload) => {
    const response = await axios.post(saveDates, payload);
    return response;
  },
  deleteDates: async (payload) => {
    const response = await axios.post(deleteDates, payload);
    return response;
  },
};
