import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
// import { createStore } from "vuex";
import userModule from "./userStore";
import dateModule from "./dateStore";
import tableModule from "./tableStore";
import watchListModule from "./wachListStore";
import ComponentsModule from "./ComponentsStore";
import ChartModule from "@/store/chartStore";
import MarketHeatModule from "@/store/HeatStore";
import AccountSettingsModule from "./AccountStore";
// localStorage.removeItem("showLegendProps")
const store = new Vuex.Store({
  modules: {
    userStore: userModule,
    chartStore: ChartModule,
    dateStore: dateModule,
    tableStore: tableModule,
    watchListStore: watchListModule,
    HeatStore: MarketHeatModule,
    ComponentsModule: ComponentsModule,
    AccountStore: AccountSettingsModule,

  },
});

export default store;
