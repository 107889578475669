<template>
  <div class="main  min-h-screen ">
    <!-- this div represets right side of the page  -->
    <div
      class="w-full -bg-red-300 flex items-center md:justify-start sm:justify-center"
    >
      <div
        class="bg-black-200 w-full md:w-[40%] sm:w-[50%] sm:px-0 px-6 sm:pt-16 pt-28 flex justify-center -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600"
        :class="loaderState ? 'pointer-events-none' : ''"
      >
        <div class="xl:w-7/12 md:w-8/12 sm:w-full w-full">
          <!--  -->
          <div>
            <router-link to="/">
              <img class="w-full" :src="require('../../assets/mapWhite.svg')" />
            </router-link>
          </div>
          <!-- this div represets welcome back heading -->
          <div class="mt-1">
            <h3 class="text-white font-bold">Have an Issue,</h3>
          </div>
          <!-- this div represets sign in to your acoount back heading -->

          <div class="">
            <p class="text-[#02BD2D] text-[24px] font-black Inter-Bold">
              Contact Us
            </p>
          </div>
          <div class="mt-2" data-temp-component="true" v-if="alertStatus">
            <AlertShort class="w-[385px]" :alertType="alertType">
              <p class="text-sm text-gray-300">
                {{ messages }}
              </p>
            </AlertShort>
          </div>
          <div class="mt-4">
            <form @submit.prevent="null">
              <div class="form-group">
                <input
                  v-model="name"
                  type="text"
                  id="name"
                  class="px-4 py-2 bg-transparent block text-gray-200 bordershadow-inner rounded-md leading-tight focus:outline-none xl:w-full md:w-full sm:w-full w-full"
                  placeholder="Name*"
                  style="border: 0.6px solid #7582787d"
                  required
                  :disabled="disabled"
                />
              </div>
              <div class="form-group mt-4">
                <input
                  v-model="email"
                  type="email"
                  ref="email"
                  id="email"
                  class="px-4 py-2 bg-transparent block text-gray-200 bordershadow-inner rounded-md leading-tight focus:outline-none xl:w-full md:w-full sm:w-full w-full"
                  placeholder="Email*"
                  style="border: 0.6px solid #7582787d"
                  required
                  :disabled="disabled"
                  @input="validInput"
                />
              </div>
              <p
                v-if="validationObject.email.status"
                :class="
                  'p-0 m-0 mt-0 text-[' +
                  pageColors.MAP_RED +
                  '] text-[1vw]'
                "
              >
                {{ validationObject.email.message }}
              </p>

              <!-- <div class="form-group mt-4">
                  <input
                    v-model="phone"
                    type="tel"
                    id="phone"
                    class="p-4 bg-transparent block text-gray-200 bordershadow-inner rounded-md leading-tight focus:outline-none xl:w-full md:w-full sm:w-full w-full"
                    placeholder="Phone"
                    style="border: 0.6px solid #7582787d"
                    :disabled="disabled"
                  />
                </div> -->
              <!-- <div class="mt-2">
                      <VuePhoneNumberInput @update="(item)=>handleInput(item)" :only-countries="countries" :no-example="true" class="mb-[8px]" :class="disabled && 'pointer-events-none'" :default-country-code="countryCode" v-model="phone" :dark="true" />
                  </div>
                  <span class="text-[12px] text-red-600 font-bold" v-if="showPhoneError">Phone number is not valid</span>
 -->

              <div class="form-group mt-4">
                <textarea
                  id="message"
                  v-model="issue"
                  rows="4"
                  style="border: 0.6px solid #7582787d"
                  class="block p-2.5 w-full text-sm bg-transparent text-gray-200 bordershadow-inner rounded-md leading-tight focus:outline-none"
                  placeholder="Write your message..."
                ></textarea>
              </div>

              <div class="">
                <button
                  class="flex items-center justify-center rounded bg-[#02BD2D] px-4 py-2 text-white w-full mt-5"
                  type="submit"
                  @click.prevent="handleContact"
                >
                  <svg
                    v-if="loaderState"
                    class="mr-3 h-6 w-6 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span class="font-bold">
                    {{ loaderState ? "Sending" : "Send" }}
                  </span>
                </button>
              </div>
            </form>
          </div>

          <div class="donthaveacc flex justify-center mt-7">
            <p>
              Done with Issue?
              <router-link
                :to="`/`"
                :class="[disabled ? pointer_events_none : '']"
                ><span> <a href="">Back to Home</a> </span></router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full pb-6 flex sm:mt-20 mt-28 justify-center">
      <PageFooter />
    </div>
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js" async defer></script>

<script>
import router from "@/router";
import userApi from "@/api/userApi";
import AlertShort from "../utilities/AlertShort.vue";
import { Colors } from "../../helper/globalUtils";
import watchListApi from "../../api/watchListApi";
import VuePhoneNumberInput from "vue-phone-number-input";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"

export default {
  name: "handleContactPageComponents",

  data() {
    return {
      pageColors: Colors,
      alertStatus: false,
      contactModals: false,
      email: "",
      name: "",
      countryCode: "AU",
      countries: ["US", "CA", "GB", "AU"],
      phone: "",
      regex: /^\d{9}$/,
      phoneCode: {
        US: "+1",
        AU: "+61",
        CA: "+1",
        GB: "+44",
      },
      issue: "",
      password: "",
      sign: false,
      messages: "",
      validatedPhone: null,
      success: null,
      alertType: "",
      type: "password",
      validatedEmail: null,
      iconName: "fa-eye",
      loaderState: false,
      disabled: false,
      pointer_events_none: "pointer-events-none",
      booleanValue: false,
      validationObject: {
        email: {
          status: false,
          message: "Please enter a valid email address",
          borderColorChnage: false,
        },
        password: {
          status: false,
          message: "Please enter a password",
          borderColorChnage: false,
        },
      },
      watchListApi_Data: [],
    };
  },
  components: {
    AlertShort,
    VuePhoneNumberInput,
    FooterCompanyLinks,
    PageFooter
  },

  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    handleInput(value) {
      this.phone = value?.formattedNumber;
      this.countryCode = value?.countryCode;
    },

    fetchwatchListApi_Data: async function (index = 0) {
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData(params);
        this.watchListApi_Data = res.data.data;
        console.log("this.watchListApi_Data", this.watchListApi_Data);
        this.$store.commit("WATCHLIST_API_DATA", this.watchListApi_Data);
        console.log("api call");
      } catch (err) {
        console.log("something went wrong", err);
      }
    },
    FieldsBorderColor(refrence, color) {
      for (const refvalues of refrence) {
        this.$refs[refvalues].style.border = `0.6px solid ${color}`;
      }
    },
    validatePassword() {
      if (this.$refs.password.value == 0) {
        this.$refs.password.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
      }
    },
    validInput(event) {
      this.email = event.target.value;
      this.validateEmail(event.target.value);
      if (this.validatedEmail) {
      }
    },

    validateEmail(value) {
      if (
        /^[a-zA-Z0-9.'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
          value
        )
      ) {
        this.validatedEmail = true;
      } else {
        console.log("email not true");
        this.validatedEmail = false;
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.iconName = "fa-eye-slash";
      } else {
        this.type = "password";
        this.iconName = "fa-eye";
      }
    },
    showErrors(message, alertType, alertStatus) {
      this.messages = message;
      this.alertStatus = alertStatus;
      this.alertType = alertType;
      // setTimeout(() => {
      //   this.alertStatus = false;
      // }, 3000);
    },

    //       validatePhone(phone) {

    //   var regex =/^\d{7}$/;
    //   if (regex.test(phone)) {
    //     this.validatedPhone = true;
    //     console.log("phone is true");
    //   } else {
    //     console.log("phone is false");
    //     this.validatedPhone = false;
    //   }
    // },

    async handleContact() {
      let validationErrors = [];
      let isNotValid = false;
      let isFormEmpty = false;
      let validNumber = this.validatePhone(this.phone);
      let phoneCode = this.phoneCode[this.countryCode];
      if (
        !this.email ||
        !this.name ||
        !this.issue ||
        this.name.match(/^\s+$/) !== null ||
        this.issue.match(/^\s+$/) !== null ||
        this.email.match(/^\s+$/) !== null
      ) {
        this.showErrors("Fields with (*) are required", "Danger", true);
        isNotValid = true;
        // setTimeout(() => {
        //   this.alertStatus = false;
        // }, 3000);
      }

      // if(this.phone){{
      //   if(!validNumber){
      //    this.showErrors("Invalid Number", true);
      //     isNotValid = true;
      //     setTimeout(() => {
      //       this.alertStatus = false;
      //     }, 3000);
      //   }
      // }
      // }

      if (this.validatedEmail == false) {
        this.validationObject.email.status = true;
        isNotValid = true;
      }

      if (isNotValid) {
        return;
      }
      try {
        const payload = {
          name: this.name,
          email: this.email,
          // phone:this.phone,
          message: this.issue,
        };
        this.loaderState = true;
        this.disabled = true;

        const res = await userApi.contactUs(payload);
        console.log("res:", res);
        if (res) {
          this.loaderState = false;
          this.disabled = false;
          if (res.data.success) {
            
            this.loaderState = false;
            this.disabled = false;
            this.showErrors("Your issue has been sent", "Success", true);
            this.email = "";
            this.name = "";
            this.issue = "";
           
          } else {
            this.loaderState = false;
            this.disabled = false;
            this.showErrors(res.data.message, "Danger", true);

         
          }
        }
      } catch (err) {
        this.loaderState = false;
        this.disabled = false;
        this.showErrors("Server Error", "Danger", true);
      }
    },
    validatePhone(phone) {
      if (this.regex.test(phone)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},

  watch: {

    name(val){
      if(val != ""){
        this.alertStatus = false;
      }
    },
    // email(val){
    //   this.alertStatus = false;
    // },
    issue(val){
      if(val != ""){
        this.alertStatus = false;
      }
    },

    email(value) {
      if(val != ""){
        this.alertStatus = false;
      }
      this.FieldsBorderColor(["email"], "#7582787d");
      this.validationObject.email.status = false;
      this.email = value;
      this.validateEmail(value);
    },

    countryCode(val) {
      if (val == "US") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "CA") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "GB") {
        this.regex = /^\d{10}$/;
      } else if (val == "AU") {
        this.regex = /^\d{9}$/;
      }
    },
  },
};
</script>

<style scoped>
a {
  color: #02bd2d;
}
a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}
input:focus {
  border: 1px solid #02bd27 !important;
}
textarea:focus {
  border: 1px solid #02bd27 !important;
}
.layer {
  /* background-color: black; */
  /* position: absolute; */
  /* width: 40%; */
}
/* logo market alert pro */

.main {
  background-image: url("../../assets/map-main-background.jpg");
  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-size: 100% 100%;
  height: auto;
}

.loginImage {
  width: 60vw;
  height: 100vh;
  position: fixed;

  right: 0px;
}

/* background and main setting page  */
.maintemplate {
  background-image: url("../../assets/map-main-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  width: 100vw;
  height: 100%;
}
.test {
  color: aliceblue;
}

.left {
  margin-left: 8rem;
  margin-top: 8rem;
}
.logoLogin {
  width: 350px;
}

.welcome {
  color: #e5e9ec;
  font-size: 18px;
  font-weight: 400;
  margin-left: 9px;
  margin-top: 35px;
}
.handleContacttoacc {
  color: #02bd2d;
  font-size: 24px;
  font-weight: 600;
  margin-left: 9px;
}
.textfields {
  border: 1px solid rgba(117, 130, 120, 0.6);
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 9px;
  width: 350px;
  color: #758278;
  text-indent: 10px;
  height: 45px;
  display: flex;
  flex-direction: column;
}

.textfields::placeholder {
  color: #758278;
  font-size: 12px;
  margin-left: 5rem;
  /* padding: 10px; */
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #fff;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #02bd2d;
}
.container {
  /* display: block; */
  position: relative;
  /* padding-left: 25px; */
  /* margin-bottom: 11px; */
  /* cursor: pointer; */
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #e5e9ec;
  /* margin-top: 10px; */
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: black;

  border-radius: 15px;
  border: 1px solid #758278;
  /* margin-left: 8.5px; */
  /* margin-top: 3px; */
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
    background-color: #ccc;
  } */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #02bd2d;
}

/* Create the checkmark/indicator (hidden when not checked) */
/* .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  } */

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  /* top: 5px; */
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.remindMe {
  /* width: 20px; */
  display: flex;
}
.forgetPassword {
  font-size: 12px;
  color: #02bd2d;
  /* margin-top: 12px; */
  /* margin-left: 10rem; */
}
.forgetPassword a {
  text-decoration: none;
}
.handleContactbtn {
  background-color: #02bd2d;
  width: 351px;
  text-align: center;
  border-radius: 2px;
  height: 45px;
  color: #f8f9f9;
  margin-top: 17px;
  padding: 14px;
  cursor: pointer;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 9px;
}

.or {
  /* width: 100%;  */
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  opacity: 0.3px;
  line-height: 0.1em;
  margin: 15px 0 20px;
}
.or span {
  background: black;
  color: #fff;
  opacity: 0.3px;
  padding: 0 10px;
}
.or h5 {
  margin-top: -11px;
  z-index: 100;
  color: #e5e9ec;
  margin-left: 8rem;
  /* padding: 10px; */
  background-color: black;
  width: 27px;
  height: 20px;
  padding-inline: 6.5px;
  font-weight: 100;
}

.socialIcons {
  height: 45px;
  width: 350px;
  /* border: 1px solid white; */
  margin-top: -30px;
  margin-left: 65px;
}

/* .donthaveacc {
    margin-left: 25px;
    padding-inline: 10px;
    font-size: 17px;
    margin-top: 30px;
  } */
.donthaveacc p {
  color: #f8f9f9;
  font-weight: 100;
}
.donthaveacc span {
  color: #02bd2d;
  font-weight: 600;
}

/* .haveanyissue p {
    font-size: 12px;
    color: #e5e9ec;
    margin-left: 40px;  
  } */
/* .haveanyissue span {
    color: #758278;
  } */
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}

/* media queries  */
@media only screen and (min-width: 1669px) {
  .maintemplate {
    /* background: #02bd2d; */
    background-image: url("../../assets/map-main-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;

    width: 100%;
    height: 100vh;
  }
}
@media only screen and (max-width: 1115px) {
  .loginImage {
    width: 55vw;
    height: 100%;
    position: fixed;
    right: 0px;
    /* z-index: 50; */
  }
}
</style>
