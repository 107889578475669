<template>
  <div> 
    <div style="border-radius:10px;" class="h-[18vh] bg-[#151515] pl-2 pt-2">
      <div class="flex flex-row-reverse justify-between">
        <div style="color:white;" class="flex items-center">
          <button style="height: 20px; font-size: 12px" @click="this.VasChanger('1D')" :style="
            'border-radius:5px;background-color:' +
            (vas == '1D' ? 'rgba(46, 50, 56, 1)' : null)
          " class="px-2">
            1D
          </button>
          <button style="height: 20px; font-size: 12px" @click="this.VasChanger('1W')" :style="
            'border-radius:5px;background-color:' +
            (vas == '1W' ? 'rgba(46, 50, 56, 1)' : null)
          " class="px-2">
            1W
          </button>
          <button style="height: 20px; font-size: 12px" @click="this.VasChanger('1M')" :style="
            'border-radius:5px;background-color:' +
            (vas == '1M' ? 'rgba(46, 50, 56, 1)' : null)
          " class="px-2">
            1M
          </button>
          <button style="height: 20px; font-size: 12px" @click="this.VasChanger('ALL')" :style="
            'border-radius:5px;background-color:' +
            (vas == 'ALL' ? 'rgba(46, 50, 56, 1)' : null)
          " class="px-2">
            All
          </button>
          <button style="height: 20px; font-size: 12px"
            :style="'border-radius:5px;background-color:null'" class="px-2">
            <img style="height: 20px" :src="require('../../assets/areacharticon.png')" />
          </button>
        </div>
        <div class="flex items-center gap-4">
          <div style="
            color: green;
            font-size: 15px;"
            class="text-green-900  text-sm">
            199.49
          </div>
          <div style="
            color: green;
            border: 1px solid green;
            background-color:    rgba(57, 255, 20, 0.1);
            padding: 2px;
            box-sizing: border-box;
            font-size: 10px;
          ">
            +5.56(2.38%)
          </div>
        </div>
      </div>
      <apex-chart ref="apexChartHeight" type="area" class="" :height="watchListChartHeight" width = "100%" :options="this.chartOptions"
        :series="this.series"></apex-chart>
    </div>
  </div>
</template>

<script>
//  import VueApexCharts from "vue3-apexcharts";
export default {
  name: "AreaChartWatchList",
  components: {
    //  apexchart: VueApexCharts,
  },
  props: ['watchListChartHeight'],
  methods: {
    VasChanger(x) {
      return (this.vas = x);
    },
    Clr() { },
  },
  data() {
    return {
      vas: "",
      series: [
        {
          name: "Series 1",
          data: [30, 52, 38, 54, 40, 80, 56],
        },
      ],
      chartOptions: {
        colors: ["#1E2224", "#1E2224", "#1E2224", "#1E2224", "#1E2224"],
        markers: {
          size: 0,
          colors: "green",
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        grid: {
          show: true,
          borderColor: "#ffffff09",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: "#000",
            opacity: 0.35,
          },
        },
        fill: {
          colors: ["#00FF00"],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: ["#00FF00"],
          width: 2,
          curve: "straight",
        },

        labels: [10, 12, 13, 14, 15, 16, 17],
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          opposite: true,
        },
        legend: {
          horizontalAlign: "left",
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: false,
            format: "dd MMM",
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
          z: {
            formatter: undefined,
            title: "Size: ",
          },
          marker: {
            show: true,
          },
          items: {
            display: "flex",
          },
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetX: 110,
            offsetY: 10,
          },
        },
      },
    };
  },

};
</script>

<style>
</style>