<template>
  <!--  -->
  <div>

    <div v-if="loading" class="w-full h-[60vh] overflow-y-auto overflow-x-hidden">
      <SkeletonTableLoader :rows="16" />
    </div>

    <!-- alert message  -->
    <div v-if="tableData.length == 0 && loading == false" class="w-full flex items-center justify-center h-[57vh]">
      <AlertShort alertType="Warning" :title="'No Data Found'" />
    </div>

    <div v-if="tableData.length > 0 && loading == false">
      <div class="w-full flex items-center ml-2">
        <div class="4/12 flex gap-[.5vw] flex-1 items-center">
          <div @click="scrollToRow(0)"
            class="grayBtn cursor-pointer flex items-center justify-center px-2 py-1 rounded-[3px]">
            <span class="text-[#E5E9EC] text-[9px] mr-2">Back to top</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-3 h-3 text-[#E5E9EC]">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          </div>
          <span class="text-[#E5E9EC] text-[9px] font-semibold">VIEW MODES</span>
          <button @click="getVariant(2)" :class="variantIs == 2 ? 'btnActive' : 'btnInActive'">
            Classic Mode
          </button>
          <button @click="getVariant(3)" style="margin: 0px" :class="variantIs == 3 ? 'btnActive' : 'btnInActive'">
            Modern Mode
          </button>
        </div>

        <div class="5/12 flex-1 p-1 mr-2">
          <div class="w-full flex space-x-2 items-center justify-end">

            <div v-if="tableData?.length > 0"
              class="flex items-center space-x-1 px-2 py-1 justify-center greenBtn text-[#02BD2D] font-medium rounded-[3px]">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                stroke="currentColor" class="w-3 h-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <span class="text-[10px]"> {{ tableData?.length }} tradings days loaded </span>

            </div>


            <div class="flex items-center space-x-2 justify-center mr-2">
              <button class="grayBtn px-2 py-1 rounded-[3px]" @click="handlePrevYear" :disabled="currentYear <= 2000"
                :class="[currentYear <= 2000 && 'cursor-not-allowed']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-4 h-4 text-[#E5E9EC]">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>

              </button>
              <span class="text-[#E5E9EC] text-[12px]">{{ currentYear }}</span>
              <button class="grayBtn px-2 py-1 rounded-[3px]" @click="handleNextYear"
                :disabled="currentYear == currentFullYear" :class="currentYear == currentFullYear && 'cursor-not-allowed'">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-4 h-4 text-[#E5E9EC]">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            </div>

            <div @click="handleSize"
              class="grayBtn cursor-pointer flex items-center justify-center px-2 py-1 rounded-[3px]">
              <span class="text-[#E5E9EC] text-[12px] mr-2">{{ maximize ? 'Small Screen' : 'Full Screen' }}</span>
              <svg v-if="maximize" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="gray" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
              </svg>

              <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="gray" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
              </svg>
            </div>

          </div>
        </div>
      </div>

      <!-- @scroll="handleTableScroll" -->
      <!-- @scroll="handleTableScroll" -->
      <!-- market watch table  -->
      <div class="w-full flex mx-1 justify-center text-center overflow-x-hidden overflow-y-auto" ref="tableBody"
        :class="[maximize ? 'h-[100vh]' : 'h-[59vh] watchTable']" 
        
        style="border-top-left-radius: 8px;border-top-right-radius: 8px;text-align: center;border: 0.1px solid rgba(117, 130, 120, 0.3); padding-bottom: 10px;">
        <!-- table  -->
        <table class="w-full h-fit" ref="myTable">
          <!-- table head  -->
          <thead v-if="(!loading && !noDataFound) && (!loading && tableData.length > 0)"
            class="text-gray-100 text-center non-italic text-sm sticky top-0 z-10 w-full h-fit">
            <tr class="headings py-8 z-10">
              <th rowspan="2" class="border-[#3d3a3a] text-[.7vw]"></th>
              <th class="text-[.7vw] font-medium" rowspan="2">Date</th>
              <th class="text-[.7vw] bg-[#151515] font-medium border-l border-solid border-l-[#758278]">Index</th>
              <th class="index cursor-pointer bg-[#151515] hover:bg-[#26282b]" colspan="2"
                @click="handleHighlightColumn('FT')">
                <span v-if="hideColumnOption.FT == false" class="flex justify-center">
                  <span class="flex items-center border-indigo-600 border-2 rounded pr-2 pl-2 pt-0 pb-0 "
                    style="background-color: rgba(43, 83, 255, 0.3)">
                    <!-- Fast Trend -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="blue">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
                <p class="font-medium text-[.7vw]" style="border-left: 1px solid #758278;" v-else>Fast Trend</p>
              </th>
              <th class="index cursor-pointer bg-[#151515] hover:bg-[#26282b]" colspan="3"
                @click="handleHighlightColumn('MD')">
                <span v-if="hideColumnOption.MD == false" class="flex justify-center">
                  <span class="flex items-center border-indigo-600 border-2 rounded pr-2 pl-2 pt-0 pb-0"
                    style="background-color: rgba(43, 83, 255, 0.3)">
                    Market Direction
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="blue">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
                <p class="font-medium text-[.7vw] border-l border-solid border-l-[#758278]" v-else>Market Direction</p>
              </th>
              <th class="index cursor-pointer bg-[#151515] hover:bg-[#26282b]" colspan="5"
                @click="handleHighlightColumn('MBA')">
                <span v-if="hideColumnOption.MBA == false" class="flex justify-center">
                  <span class="flex items-center border-indigo-600 border-2 rounded pr-2 pl-2 pt-0 pb-0"
                    style="background-color: rgba(43, 83, 255, 0.3)">
                    MBA Direction
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="blue">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
                <p class="font-medium text-[.7vw] border-l border-solid border-l-[#758278]" v-else>MBA Direction</p>
              </th>
              <th class="index cursor-pointer bg-[#151515] hover:bg-[#26282b] border-t-0"
                @click="handleHighlightColumn('ST')" colspan="2">
                <span v-if="hideColumnOption.ST === false" class="flex justify-center">
                  <span class="flex border-2 border-indigo-600 rounded pr-2 pl-2 pt-0 pb-0 items-center"
                    style="background-color: rgba(43, 83, 255, 0.3)">
                    Slow Trend
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="blue">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd " />
                    </svg>
                  </span>
                </span>
                <p class="font-medium text-[.7vw] border-l border-solid border-l-[#758278]" v-else>Slow Trend</p>
              </th>
              <th class="index font-medium text-[.7vw] bg-[#151515] border-l border-solid border-l-[#758278]" rowspan="2">
                Date
              </th>
            </tr>

            <tr class="inpDate secondHeader z-10">
              <th class="inpuDate bg-[#1e2224]" style="outline: 0.5px solid #758278; outline-offset: -1px;">
                <span class="font-[500] text-[.7vw] Inter-Medium">{{ SnP_500 }}</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;outline-offset: -1px; cursor: pointer;"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }"
                @click="getActiveDatesForCalendar('fast_trend', 'Fast Trend')">
                <span class="font-[500] text-[.5vw] Inter-Medium">Fast trend</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278; outline-offset: -1px;"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">VIX</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278; outline-offset: -1px;"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Depth</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px; cursor: pointer;" @click="getActiveDatesForCalendar('market_direction', 'Rise / Fall')"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Rise/Fall</span>
              </th>

              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Total</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Momentum(M)</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Breakout(B)</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">Advance(A)</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">MBA Avg</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px; cursor: pointer;"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }"
                @click="getActiveDatesForCalendar('alerts', 'Market Alert')">
                <span class="font-[500] text-[.5vw] Inter-Medium">Market Alert</span>
              </th>
              <th class="inpuDate" style="outline: 0.5px solid #758278;
  outline-offset: -1px;" :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }">
                <span class="font-[500] text-[.5vw] Inter-Medium">MBA%</span>
              </th>
              <th class="inpuDate px-2"
                :style="variantIs == 3 ? { 'background-color': '#151515' } : { 'background-color': '#1e2224' }"
                @click="getActiveDatesForCalendar('slow_trend', 'Slow Trend')" style="border-right: 0px; cursor: pointer; font-weight: 100 !important; outline: 0.5px solid #758278;
  outline-offset: -1px;">
                <span class="font-[500] text-[.5vw] Inter-Medium whitespace-nowrap">Slow Trend</span>
              </th>
            </tr>
          </thead>
          <!-- table body  -->
          <tbody ref="tbody" class="">
            <tr ref="myRow" @click="marketwatchClickedRow(x)" class="hover:brightness-90"
              :class="[rowHilighted.includes(i) ? 'opacity-70' : '', selectedDateStore.includes(x.date) ? 'opacity-70' : '']"
              :key="i" v-for="(x, i) of tableData">

              <td style="border-bottom-color: #3d3a3a; font-size: 0.9vw">
                <label class="container ml-2">
                  <input type="checkbox" class="checkbox-round" @change="getSelectedDate($event, i)"
                    :checked="selectedDateStore.includes(x.date)" />
                  <span class="checkmark"></span>
                </label>
              </td>


              <td :key="j" v-for="(p, j) in ColumnConfig" :class="marketwatchCellColor2(p, x, j)" class="text-[0.5vw]">
                <div v-if="x[p.name + '_color_bg'] == 'bg_purple' && (variantIs == 2)">
                  {{ p.isDate
                    ? formatDate(x[p.name])
                    : numberIndex?.includes(j)
                      ? x[p.name].toFixed(2)
                      : (x[p.name]).toString().toUpperCase()
                  }}
                </div>
                <span v-else>
                  {{
                    p.isDate
                    ? formatDate(x[p.name])
                    : numberIndex?.includes(j)
                      ? x[p.name].toFixed(2)
                      : (x[p.name]).toString().toUpperCase()
                  }}
                </span>
              </td>
            </tr>
            <!-- <tr v-if="moreDataLoader" class="w-full flex items-center justify-center">
        
            <div role="status" class="bg-transparent flex items-center justify-center self-center left-[30vw] absolute">
          <svg aria-hidden="true" class="w-6 h-6 mt-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-700" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
          </tr> -->
          </tbody>


        </table>
      </div>


    </div>

  </div>
</template>

<script>
import './market_alert.css'
import { mapGetters } from "vuex";
import moment from "moment";
import AlertShort from "../utilities/AlertShort.vue";
import userApi from '@/api/userApi';
// import ForInformation from "@/views/ForInformation.vue";
// import Calendar from './'
import tableApi from "../../api/tableApi";
import SkeletonTableLoader from "../loader/SkeletonTableLoader.vue"
import ColumnConfig, {
  colorMap,
  textColorMap,
  // marketwatchCellColor2,
  // marketwatchCellColor,
} from "@/components/config/market_watch/ColumnConfig";
import axios from 'axios';
export default {
  name: "MarketWatchTableUpdated",
  components: {
    // ForInformation,
    AlertShort,
    SkeletonTableLoader
  },
  props: {
    isFullSize: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      numberIndex: [3, 7, 8, 9, 10],
      moreDataLoader: false,
      scrollToRowPreviousIndex: null,
      SnP_500: 'All Ords',
      showScrollBtn: false,
      scrollCounter: 0,
      variantEffects_Txt: {
        'first': ''
      },
      variantIs: localStorage.getItem('tableVarient'),

      firstIndexS_P: null,
      loadDateSelected: false,
      rowHilighted: [],
      ColumnConfig,
      textForBadge: "No Data Found",
      title: "",
      propval: "prop",
      tableData: [],
      maximize: false,
      table: {},
      tableSample: [],
      filterTabledates: [],
      selectedDates: [],
      loading: false,
      noDataFound: false,
      noRowFound: false,
      hideColumnOption: {
        FT: null,
        MD: null,
        MBA: null,
        ST: null,
      },
      colorMap: colorMap,
      calendarColorMap: {
        "#009300": "greens",
        "#DC0000": "redss",
        "#FFFF00": "yellows",
        "#4B0082": "redss",
        "#00005E": "greens",
        "#005500": "darkgreens",
      },
      tableIndex: null,
      currentFullYear: "",
      currentYear: null,
    };
  },

  methods: {
    /**
     *
     * @param {MarketAlertTableConfigurationStructure} tableConfig //Each column meta information
     * @param {MarketAlertTableRowStructure} dbRow //Market alert data table row coming from DB
     * @returns {string} //css classes for text and background colors
     */
    marketwatchCellColor2(currentColumnConfig, dbRow, tdIndex) {
      // let backgroundColor = "",
      //   textColor = ""; 

      // //check if configuration is set to change or apply color on a specific cell
      // if (currentColumnConfig.applyColor) {
      //   // @TODO: Check and see if the regex is impacting on performance
      //   // if ((alerts + "").toUpperCase().replace(/\s/g, "_") === "MARKET_ALERT") {

      //   if (dbRow.alerts === "Market Alert") {
      //     backgroundColor = "redRow"; //Market alert found, turn on red color for the row
      //   } else {
      //     backgroundColor =
      //       dbRow["color_" + currentColumnConfig.name] !== undefined &&
      //       colorMap[dbRow["color_" + currentColumnConfig.name]] !== undefined
      //         ? colorMap[dbRow["color_" + currentColumnConfig.name]]
      //         : "";

      //     if (currentColumnConfig.name == "sp_500")
      //       console.log(
      //         "color_" +
      //           currentColumnConfig.name +
      //           " : " +
      //           dbRow["color_" + currentColumnConfig.name] + " || " + 
      //           textColorMap[dbRow["color_" + currentColumnConfig.name]]
      //       );

      //     textColor =
      //       dbRow["font_color_" + currentColumnConfig.name] !== undefined &&
      //       textColorMap[dbRow["font_color_" + currentColumnConfig.name]] !== undefined
      //         ? textColorMap[dbRow["font_color_" + currentColumnConfig.name]]
      //         : "";


      // ====================pre work end=================

      let backgroundColor = "",
        textColor = "";

      //check if configuration is set to change or apply color on a specific cell
      if (currentColumnConfig.applyColor) {
        // @TODO: Check and see if the regex is impacting on performance
        // if ((alerts + "").toUpperCase().replace(/\s/g, "_") === "MARKET_ALERT") {

        if (dbRow.alerts === "Market Alert" && false) {
          backgroundColor = "redRow"; //Market alert found, turn on red color for the row
        }
        // else if(this.variantIs == 3)
        // {
        //   backgroundColor = currentColumnConfig.name + "_" + dbRow[currentColumnConfig.name +'_color_bg'] + "_" +  this.variantIs
        // } 
        else {
          // console.log(
          //   "." + currentColumnConfig.name + "_" + dbRow[currentColumnConfig.name + ]
          // )
          // console.log("currentColumnConfig: ");
          //console.log(currentColumnConfig);
          // console.log("dbRow",dbRow)

          /**
           * CSS Skin Code genertor
           * https://jsfiddle.net/48p2m0be/
           */
          // if(!this.templateGenerated){

          //   let myCss = "";
          //   const variant = "1";
          //   const color_options = [
          //     {color: "bg_red", code: "#DC0000"},
          //     {color: "bg_green", code: "#009300"},
          //     {color: "bg_blue", code: "#00005E"},
          //     {color: "bg_purple", code: "#4B0082"},
          //     {color: "bg_cream", code: "#FFFFE0"},
          //     {color: "bg_yellow", code: "#FFFF00"} 
          //   ];
          //   const text_color_options = [
          //     {color: "text_white", code: "#FFFFFF"},
          //     {color: "text_green", code: "#009300"},
          //     {color: "text_red", code: "#DC0000"},
          //     {color: "text_black", code: "#000000"}
          //   ];
          //   const columns = [
          //     "advance_m",
          //     "alerts",
          //     "breakout_m",
          //     "date",
          //     "dircetion_total",
          //     "direction_depth",
          //     "fast_trend",
          //     "market_direction",
          //     "mba_avg",
          //     "mba_percentage",
          //     "momentum_m",
          //     "slow_trend",
          //     "sp_500",
          //     "vix",
          //   ];
          //   /**
          //    * Background color template generator
          //    */
          //   columns.map((column) => {
          //     color_options.map((color_option) => {
          //       myCss += "." + column + "_" + color_option.color + "_" + variant;
          //       myCss += "{"+
          //                   "background-color:"+color_option.code
          //               +"}"
          //     })
          //   });
          //   /**
          //    * Text color template generator
          //    */
          //   columns.map((column) => {
          //     text_color_options.map((text_color_option) => {
          //       myCss += "." + column + "_" + text_color_option.color + "_" + variant;
          //       myCss += "{"+
          //                   "color:"+text_color_option.code
          //               +"}"
          //     })
          //   });
          // console.log(".....", dbRow[currentColumnConfig.name + '_color_bg'])
          backgroundColor = currentColumnConfig.name + '_' + dbRow[currentColumnConfig.name + '_color_bg'] + '_' + this.variantIs
          // textColor = dbRow[currentColumnConfig.name +'_color_text']
          textColor = currentColumnConfig.name + '_' + dbRow[currentColumnConfig.name + '_color_text'] + '_' + this.variantIs
          //   // console.log("." + backgroundColor + "{background: "+dbRow["color_"+currentColumnConfig.name]+" !important;}")
          //   console.log(myCss)
          // }
          // this.templateGenerated = true
          // console.log(dbRow) 
          // console.log("backgroundColor",backgroundColor)
          // dbRow["color_" + currentColumnConfig.name] !== undefined &&
          // colorMap[dbRow["color_" + currentColumnConfig.name]] !== undefined
          //   ? colorMap[dbRow["color_" + currentColumnConfig.name]]
          //   : "";

          // if (currentColumnConfig.name == "sp_500")
          // console.log(
          //   "color_" +
          //     currentColumnConfig.name +
          //     " : " +
          //     dbRow["color_" + currentColumnConfig.name] + " || " + 
          //     textColorMap[dbRow["color_" + currentColumnConfig.name]]
          // );


          // backgroundColor = colorMap[tableConfig.bgColor] ? colorMap[props.bgColor] : "";
          // textColor = colorMap[props.textColor] ? colorMap[props.textColor] : "";
        }

        // if(currentColumnConfig.name  == 'sp_500' && dbRow[currentColumnConfig.name] == 'Up' && this.variantIs == 2){
        //   return {'background-color':'green !important'}
        // }

      }


      // if(dbRow?.alerts === 'Market Alert' && tdIndex==11){
      //   return 'alertClass'
      // }
      // if(dbRow?.alerts === 'Market Alert' && tdIndex==10){
      //   return 'alertClass2'
      // }
      // if(dbRow?.mba_avg_color_bg === 'bg_black' && tdIndex==10){
      //   return '!bg-green-700  !border-2 !border-white !text-white'
      // }



      let _classes = currentColumnConfig.class;
      _classes = `${_classes} ${backgroundColor} ${textColor}`;

      // console.log("_classes====>",_classes)
      _classes = `${_classes} ${this.hideColumnOption[currentColumnConfig.category]
        ? "brightness-50"
        : ""
        }`;
      // console.log(" || " + _classes);
      // if (currentColumnConfig.name == "sp_500")
      //    console.log("defaultClasses", currentColumnConfig.classes);
      return _classes;
    },
    // UpRateGreen(tr, td) {


    //   if (tr[td.name] == 'Up' && this.variantIs == 1) {
    //     return { 'background-color': '#009300 !important' }
    //   }
    //    if (tr[td.name] == 'Up' && this.variantIs == 2) {
    //     return { 'background-color': '#02bd2d !important' }
    //   }
    //   if (tr[td.name] == 'Up' && this.variantIs == 3) {
    //     return { 'background-color': '#132918 !important' }
    //   }
    // },
    getVariant(variant) {
      this.variantIs = variant;
      localStorage.setItem("tableVarient", variant)
      let payload = { varientForMarketWatch: variant }
      let res = userApi.varientMarketWatch(payload)


    },
    // checkColor(){
    //   console.log("black ",this.nestedHeaderColor)
    //   this.nestedHeaderColor = 'black'
    // },
    handleSize() {
      this.maximize = !this.maximize
      this.$emit('size', this.maximize)
    },
    // goToTop(){
    //   this.tableIndex=0
    // },
    formatDate(d) {
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY");
    },
    handleScroll() {
      this.scrollCounter = this.scrollCounter + 1
      if (this.scrollCounter == 1) {

        this.showScrollBtn = true
      } else {
        return
      }

    },
    handleRowHighlight(x) {
      // console.log(x);

      if (x.date === this.getClickedCalenderDate[0]) return "opacity-[.8]";
      else {
        return "";
      }
    },

    async handleTableScroll(event) {

      if (!this.moreDataLoader) {
        const tableBody = this.$refs.tableBody;
        if (tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 1) {
          this.moreDataLoader = true
          this.currentYear = this.currentYear - 1
        } else if (tableBody.scrollTop === 0) {
          this.moreDataLoader = true;
          const currentDate = new Date();
          const fullYear = currentDate.getFullYear();
          this.currentYear = this.currentYear != fullYear ? this.currentYear + 1 : this.currentYear;
        } else {
          return;
        }

        let payload = {
          year: this.currentYear,
          startDate: this.selectedStartDate,
          marketId: this.getActiveCountry,
          endDate: this.selectedEndDate,
        };



        const res = await tableApi.fetchTableData(payload);

        if (res.status === 200 && res?.data?.data.length > 0) {
          this.moreDataLoader = false
          // this.tableData = this.tableData.concat(res?.data?.data);
          this.tableData = res?.data?.data;
          this.$store.commit("CHANGE_YEAR_MA", this.currentYear);
        } else {
          this.moreDataLoader = false
        }
      }

    },

    handlePrevYear() {
      this.currentYear = this.currentYear - 1;
      this.$store.commit("CHANGE_YEAR_MA", this.currentYear);
    },
    handleNextYear() {
      this.currentYear = this.currentYear + 1;
      this.$store.commit("CHANGE_YEAR_MA", this.currentYear);
    },

    // async checkDataDelayed(row){
    //   const response = await tableApi.jobData()
    //   if(Object.keys(row).length>0){
    //     console.log("response",response)
    //     const watchData = response?.find((item) => item?.jobDetails?.marketId == this.getActiveCountry && item?.jobDetails?.type == "watch")
    //     console.log("watchdata",watchData)
    //         const parsedGivenDate = moment(row?.date, 'YYYY-MM-DD');
    //         const currentTime = moment.utc().format('HH:mm');;
    //         const currentTimeMoment = moment(currentTime,'HH:mm');;
    //         const givenTimeMoment = moment(watchData?.runAt, 'HH:mm');
    //         const currentDate = moment();
    //         if (parsedGivenDate?.isSame(currentDate, 'day')) {
    //           this.$store.commit("SET_BADGE", false);
    //       } else {
    //         const dayOfWeek = parsedGivenDate?.day();
    //         if (dayOfWeek === 0 || dayOfWeek === 6) {
    //           this.$store.commit("SET_BADGE", false); 
    //         } else {
    //          const givenDateMoment = moment(row?.date, 'YYYY-MM-DD');
    //           const currentDateMoment = moment();
    //           const differenceInDays = currentDateMoment?.diff(givenDateMoment, 'days');
    //           console.log("day difference",differenceInDays)
    //           if(differenceInDays > 2){
    //             this.$store.commit("SET_BADGE", true);
    //           }else if(differenceInDays == 2){
    //             const twoDaysAgo = currentDateMoment.subtract(2, 'days');
    //             const isCurrentDateWeekend = currentDateMoment.isoWeekday() > 5;
    //             const isTwoDaysAgoWeekend = twoDaysAgo.isoWeekday() > 5;
    //             if(isCurrentDateWeekend || isTwoDaysAgoWeekend){
    //               this.$store.commit("SET_BADGE", false);
    //             }else{
    //               this.$store.commit("SET_BADGE", true);
    //             }
    //           }
    //           else{
    //             if(currentTimeMoment?.isAfter(givenTimeMoment)){
    //               this.$store.commit("SET_BADGE", true);
    //             }else{
    //               this.$store.commit("SET_BADGE", false);
    //             }
    //           }
    //         }

    //       }
    //   }
    //   else{
    //     console.log("in else")
    //     this.$store.commit("SET_BADGE", true);
    //   }


    // },


    isWeekend(date) {
      const dayOfWeek = date
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return dayOfWeek === 0 || dayOfWeek === 6

      }

    },
    // async checkDataDelayed(row) {

    //   const response = await tableApi.jobData()
    //   if (Object.keys(row).length === 0) {
    //     this.$store.commit("SET_BADGE", true);
    //     return;
    //   }



    //   const currentDate = moment();
    //   const parsedGivenDate = moment(row.date, 'YYYY-MM-DD');
    //   const currentYear = moment().year();
    //   if (parsedGivenDate.year() !== currentYear) {
    //     this.$store.commit("SET_BADGE", false);
    //     return
    //   }

    //   if (this.isWeekend(currentDate.day())) {
    //     this.$store.commit("SET_BADGE", false);
    //     return;
    //   }
    //   if (parsedGivenDate?.isSame(currentDate, 'day')) {
    //     console.log("in same wala")
    //     this.$store.commit("SET_BADGE", false);
    //     return

    //   }

    //   const watchData = response?.find((item) => item?.jobDetails?.marketId == this.getActiveCountry && item?.jobDetails?.type == "watch");
    //   const givenTimeMoment = moment(watchData?.runAt, 'HH:mm');
    //   const currentTime = moment.utc().format('HH:mm');
    //   const currentTimeMoment = moment(currentTime, 'HH:mm');
    //   const differenceInDays = currentDate?.diff(parsedGivenDate, 'days');


    //   console.log("differenceInDays", differenceInDays, currentDate.day())





    //   if (differenceInDays > 3) {
    //     this.$store.commit("SET_BADGE", true);
    //     return
    //   }
    //   if (differenceInDays > 1 && !this.isWeekend(currentDate.day()) && currentDate.day() !== 1) {
    //     this.$store.commit("SET_BADGE", true);
    //     return
    //   }
    //   if (differenceInDays === 3 && currentDate.day() === 1) {
    //     console.log("in three")

    //     console.log("on monday", currentTimeMoment?.isAfter(givenTimeMoment), currentTimeMoment, givenTimeMoment)
    //     if (currentTimeMoment?.isAfter(givenTimeMoment)) {
    //       this.$store.commit("SET_BADGE", true);
    //       return
    //     }
    //   }

    //   if (!parsedGivenDate?.isSame(currentDate, 'day') && differenceInDays == 1) {
    //     console.log("time wala")
    //     console.log("abc", currentTimeMoment?.isAfter(givenTimeMoment))
    //     if (currentTimeMoment?.isAfter(givenTimeMoment)) {
    //       this.$store.commit("SET_BADGE", true);
    //       return;
    //     }
    //     else {
    //       this.$store.commit("SET_BADGE", false);


    //     }

    //   }





    // },

    async checkDataDelayed(country){
      try {
      const payload = {marketId:country,type:'watch'}
      const data = await tableApi.checkDataDelayed(payload);
      console.log("data for data delay",data)
      if(data?.data?.success){
        if(data?.data?.payload){
          this.$store.commit("SET_BADGE", true);
        }
        else{
          this.$store.commit("SET_BADGE", false);
        }
      }
     } catch (error) {
        console.log("err",error)
      }
    },



    getTableData: async function () {
      console.log("this.getActiveCountry in table", this.getActiveCountry)
      this.currentYear = this.selectedActiveYearStoreMA
      this.loading = true;
      this.noDataFound = false;
      this.tableData = [];
      console.log(this.getActiveCountry);
      let payload = {
        year: parseInt(this.selectedActiveYearStoreMA),
        startDate: this.selectedStartDate,
        marketId: this.getActiveCountry,
        endDate: this.selectedEndDate,
      };
      try {
        this.$store.commit("SET_MARKET_ALERT_DATA", []);
        this.$store.commit("ACTIVE_DATES_FOR_CALENDAR", []);
        this.$store.commit("ADD_DATE_INTO_ARRAY", []);
        this.rowHilighted = []
        const res = await tableApi.fetchTableData(payload);
        if (res) {
          if (res.status === 200 && res.data.data.length <= 0) {
            this.loadingFalse();
          }
          if (res.status === 200 && res.data?.data?.length >= 0) {
            if (res.data?.data?.length > 0) {
              this.marketwatchClickedRow(res.data.data[0]);
            }
            this.checkDataDelayed(this.getActiveCountry)
            this.loading = false;
          }
          this.tableData = res.data.data;
          console.log("before", this.tableData.length)
          // this.tableData = []
          this.$store.commit("SET_MARKET_ALERT_DATA", this.tableData);
        }
      } catch (err) {
        this.noDataFound = true;
        this.loading = false;
        alert(err);
      }
    },

    reformatDate(i, _date) {
      var dt = moment(_date); // ISO 8601 date format
      if (dt.isValid()) {
        this.tableData[i]["utc_date"] = _date;
        this.tableData[i]["date"] = dt.format("YYYY-MM-DD");
        return dt.format("YYYY-MM-DD");
      } else {
        return "1970/01/01";
      }
    },


    getActiveDatesForCalendar(colName, colNameSelected) {
      this.$store.commit('ALERT_SELECTED_TABLE_COLUMN', colNameSelected)
      let classes = "";
      let classPrefix = "ca_"
      let changeDate = "";
      let array = [];
      let previous_class = "";
      let calendarData = JSON.parse(JSON.stringify(this.tableData)).sort(
        function (a, b) {
          return new Date(a.date) - new Date(b.date);
        }
      );
      console.log("calender daATA", calendarData)
      // colName = "color_" + colName;
      for (let i = 0; i < calendarData.length; i++) {
        changeDate = calendarData[i].date.replaceAll("-", "/");
        let currentDay = moment(calendarData[i].date, "YYYY-MM-DD").format(
          "DD"
        );
        // let color = "";
        // if (calendarData && calendarData[i] && calendarData[i][colName]) {
        //   color = calendarData[i][colName];
        //   if (color) {
        //     classes = this.calendarColorMap[color]
        //       ? this.calendarColorMap[color]
        //       : "";
        //   }
        // }
        // _color_bg
        // _color_text
        // fast_trend_color_text
        console.log("new", calendarData[colName])
        classes = classPrefix + colName + '_' + calendarData[i][colName + "_color_bg"] + '_' + this.variantIs
        classes = classes + " " + classPrefix + colName + '_' + calendarData[i][colName + "_color_text"] + '_' + this.variantIs
        // console.log("classes",classes)
        // console.log("classes", colName)
        if (i === 0) {
          previous_class = classes;
          classes = classes + " start";
        } else {
          if (classes !== previous_class) {
            previous_class = classes;

            if (i !== calendarData.length - 1) {
              array[i - 1].className = array[i - 1].className + " end";
              classes = classes + " start";
            }
          }

          if (i !== calendarData.length - 1) {
            let tomorrow = new Date(calendarData[i + 1].date);
            let yesterday = new Date(calendarData[i - 1].date);
            let today = new Date(calendarData[i].date);
            let day = (tomorrow - today) / (1000 * 3600 * 24);
            let diff = (today - yesterday) / (1000 * 3600 * 24);
            if (day > 1) {
              classes = classes + " end";
            }
            if (diff > 1) {
              classes = classes + " start";
            }
            if (currentDay == 1) {
              classes = classes + " start";
            }

            if (moment(calendarData[i + 1].date, "YYYY-MM-DD").format("DD") == 1) {
              classes = classes + " end";
            }
          }

          if (i === calendarData.length - 1) {
            classes = classes + " end";
          }
        }

        array.push({
          date: changeDate,
          className: classes,
        });
      }

      this.$store.commit("ACTIVE_DATES_FOR_CALENDAR", array);
    },

    scrollToRow(index) {
      this.showScrollBtn = false
      // if (index <= 20) {
      //   this.tableIndex = 0
      // }
      if (this.tableData.length > 0) {
        // const tbody = this.$refs.table.$el.querySelector('tbody')
        const tbody = this.$refs.tbody;
        const row = tbody.querySelectorAll("tr")[index >= 3 ? index - 2 : index];
        if (index < 3) {
          console.log("i am top", index)
          row.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        } else {
          console.log("i am center", index)
          row.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        }

      }
    },

    marketwatchClickedRow(row) {
      this.$store.commit("SET_CLICKED_ROW", row);
    },

    getSelectedDate(e, i) {
      let isChecked = e.target.checked;
      if (isChecked && this.tableData[i].date) {
        let checkDates = this.selectedDateStore;
        checkDates.push(this.tableData[i].date);
        this.rowHilighted.push(i)
        this.$store.commit("ADD_DATE_INTO_ARRAY", checkDates);
      } else {
        this.rowHilighted = this.rowHilighted.filter(a => a !== i)
        let filteredDates = this.selectedDateStore;
        let index = filteredDates.indexOf(this.tableData[i].date);
        if (index > -1) {
          filteredDates.splice(index, 1);
          this.$store.commit("ADD_DATE_INTO_ARRAY", filteredDates);
        }
      }
    },

    loadingFalse() {
      this.noDataFound = true;
      this.loading = false;
    },

    handleHighlightColumn(val) {
      Object.keys(this.hideColumnOption).map((item) => {
        if (item != val) {
          if (this.hideColumnOption[item] == null) {
            this.hideColumnOption[item] = true;
          }
        } else {
          if (this.hideColumnOption[val] == false) {
            this.hideColumnOption[val] = true;
          } else {
            this.hideColumnOption[item] = false;
          }
        }
      });

      if (this.checkAllFalse()) {
        Object.keys(this.hideColumnOption).map((i) => {
          this.hideColumnOption[i] = null;
        });
      }
      if (this.checkAllTrue()) {
        Object.keys(this.hideColumnOption).map((i) => {
          this.hideColumnOption[i] = null;
        });
      }
    },
    checkAllTrue() {
      let counter = 0;
      Object.keys(this.hideColumnOption).map((item) => {
        if (this.hideColumnOption[item] == true) {
          counter = counter + 1;
        }
      });
      if (counter == 4) {
        return true;
      } else {
        return false;
      }
    },
    checkAllFalse() {
      let counter = 0;
      Object.keys(this.hideColumnOption).map((item) => {
        if (this.hideColumnOption[item] == false) {
          counter = counter + 1;
        }
      });
      if (counter == 4) {
        return true;
      } else {
        return false;
      }
    },


  },
  async mounted() {
    console.log("get active country", this.getActiveCountry)
    let year = new Date().getFullYear();
    this.currentYear = this.selectedActiveYearStoreMA
    this.currentFullYear = year
    console.log("full year", this.currentFullYear)
    await this.getTableData();
    this.getActiveDatesForCalendar('alerts', 'Market Alert');
    if (this.getActiveCountry == 'oz') {
      this.SnP_500 = 'All Ords'
    }
    else if (this.getActiveCountry == 'us') {
      this.SnP_500 = 'S&P 500'
    }
    else {
      this.SnP_500 = 'TSX-C'
    }

    if (this.isFullSize) {
      this.handleSize();
    }


    // this.scrollToRow();

    // ColumnConfig
  },
  computed: {
    ...mapGetters([
      "datesModalSaved",
      "selectedDateStore",
      "selectedActiveYearStoreMA",
      "selectedcheckedDatedModalStore",
      "selectedEndDate",
      "selectedStartDate",
      "getActiveCountry",
      "getClickedCalenderDate",
    ]),
  },
  watch: {
    // variantIs(val){
    //   if(val == 3){
    //     this.nestedHeaderColor = '#758278'
    //     console.log()
    //   }
    //   else{
    //     this.nestedHeaderColor = '#758278'
    //   }
    // },
    loading(val){
      this.$store.commit("TABLE_LOADER_UPDATE", val);
    },

    datesModalSaved(n, o) {
      if (n) {
        this.rowHilighted = []
        this.$store.commit("DATES_MODAL_SAVED", false);
      }
    },
    async selectedActiveYearStoreMA(n, o) {
      // if(n !== this.currentYear){
      await this.getTableData();
      this.tableIndex = this.tableData.findIndex(
        (x) => x.date === this.selectedActiveYearStoreMA[0]);
      if (this.tableIndex > -1) {

        this.scrollToRow(this.tableIndex - 2);
      }
      // }
      this.getActiveDatesForCalendar('alerts', 'Market Alert');
    },
    currentYear(n, o) {
      if (n.length) {
        let x = n[0].split('-')[0]
        let num = Number(x)
        this.currentYear = num
      } else {
        this.currentYear = n
      }
    },
    selectedcheckedDatedModalStore(n, o) {
      let hasData = n.name !== o.name;
      // console.log('hasData',hasData,n)
      if (hasData) {
        let showVal = n.index
        // console.log('showVal',showVal)
        if (showVal) {
          this.loadDateSelected = true;
          this.rowHilighted = n.index.map(v => v)
          // this.$store.commit("CHECKED_DATES_MODAL", false);  
        }

      }
    },
    async getActiveCountry() {
      console.log("country iss", this.getActiveCountry)
      this.$store.commit("ADD_DATE_INTO_ARRAY", []);
      await this.getTableData();
      this.getActiveDatesForCalendar('alerts', 'Market Alert');
      if (this.getActiveCountry == 'oz') {
        this.SnP_500 = 'All Ords'
      }
      else if (this.getActiveCountry == 'us') {
        this.SnP_500 = 'S&P 500'
      }
      else if (this.getActiveCountry == 'ca') {
        this.SnP_500 = 'TSX-C'
      }
    },
    getClickedCalenderDate() {
      console.log("date wala watch chala");
      this.tableIndex = this.tableData.findIndex(
        (x) => x.date === this.getClickedCalenderDate[0]);
      console.log(this.tableIndex);
      // this.rowHilighted.pop(this.tableIndex)
      this.rowHilighted[this.scrollToRowPreviousIndex] = null
      this.rowHilighted.push(this.tableIndex)
      this.scrollToRowPreviousIndex = this.rowHilighted.indexOf(this.tableIndex)

      if (this.tableIndex > -1) {
        var table = this.$refs.myTable;
        var tableHeight = table.offsetHeight;
        console.log("tableheight", tableHeight)
        this.scrollToRow(this.tableIndex);

        // if(tableHeight == 2972){
        //   this.scrollToRow(this.maximize? this.tableIndex+16:this.tableIndex+9);
        // }else if(tableHeight == 2977){
        //   this.scrollToRow(this.maximize? this.tableIndex+13:this.tableIndex+7);
        // }else if(tableHeight == 2999){
        //   this.scrollToRow(this.maximize? this.tableIndex+12:this.tableIndex+6);
        // }
        // else{
        //   this.scrollToRow(this.tableIndex);

        // }
      } else {
        this.noRowFound = true;
        setTimeout(() => {
          this.noRowFound = false;
        }, 2000);
      }
    },


  },


};
</script>

<style scoped>
@import './market_alert.css';
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

/* checkbox */

/* to remove ended */
.target-variant-Btn:active {
  border-color: #00BA22 !important;
}

.text_green {
  color: #02bd2d !important;
}

.text_red {
  color: rgba(255, 10, 0, 1) !important;
}

.text_white {
  color: #fff !important;
}

.text_black {
  color: #000 !important;
}

.div1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
  border: 0.1px solid rgba(207, 17, 59, 0.3);
}

/* to remove ended */

.secondHeader {
  /* cursor: pointer; */
  margin-top: 10rem;
}

.secondHeader th:hover {
  /* color: rgb(255, 255, 255, 0.4); */
}

.box {
  height: 100px;
  width: 100px;
  background-color: #00c92e;
  margin-top: 20rem;
}

.loading-text2 {
  text-align: center;

  background: linear-gradient(to right,
      #00c92e 20%,
      #00c92e 40%,
      #009300 60%,
      #00c92e 80%);
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.redRow {
  background-color: red !important;
  border: 0.1px solid rgba(30, 34, 36, 0.2);
  border-radius: 0px;
  width: 89px;
  height: 30px;
  left: 90px;
  top: 0px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #030303 !important;
  font-weight: bolder !important;
}


.loading-text {
  background: linear-gradient(to right,
      #00c92e 20%,
      #00c92e 40%,
      #009300 60%,
      #00c92e 80%);
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

tbody {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
}

thead {
  width: auto;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  /* height: 485px; */
}

tbody td {
  width: 40px;
}

table {
  border-collapse: collapse;


}

tr {
  /* github.com/w3c/csswg-drafts/issues/1899 */
  transform: scale(1);
  /* position: relative; */
}

td {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;


}

:invalid .if-valid {
  display: none;
}

/* Moz hack to enable :before on radio
input[type=radio]{appearance: none;}
input[type=radio]::before{content: '⚪';}
input[type=radio]:checked::before{content: '🔘';padding: 3px 5px;}
*/

.container {
  display: block;
  position: relative;
  right: 2px;
  cursor: pointer;
  width: 0.7em;
  height: 0.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 2px;
  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}


.container input:checked~.checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}


.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}


.container input:checked~.checkmark:after {
  display: block;
}


.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
}

/* .checkbox-round {
  width: 0.7em;
  height: 0.7em;
  background-color: black;
  border-radius: 20%;
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
} */

/* .checkbox-round:checked {
  background-color: #02bd2d;
} */

/* * {
  margin-left: auto;
  margin-right: auto;
} */

tr {
  background-color: white;
}

.date {
  height: 60px;
  width: 90px;
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  border: 0.1px solid rgba(117, 130, 120, 0.3);
}

.date span {
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(248, 249, 249, 1);
  text-align: center;
}

.dates {
  height: 60px;
  width: 90px;
  left: 0px;
  top: 0px;
}

.dates span {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(248, 249, 249, 1);
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 8px;
  line-height: 18px;
}

/* owais */
.inpDate {
  border-radius: 0px;
  color: rgba(248, 249, 249, 1);
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;

  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  background: #151515;
  font-weight: 900 !important;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 0px;
  border-left: 0px;
}

.fasty {
  font-weight: 400 !important;
}

/* .inpoutline{
  outline: 0.03rem solid #758278;
} */
.inpuDate {
  border-radius: 0px;
  color: rgba(248, 249, 249, 1);
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 0px;
}

.btnActive {
  background-color: rgba(2, 189, 45, 0.12);
  width: 95px;
  height: 23px;
  color: #E5E9EC;
  font-weight: 400;
  font-size: 9px;
  border: 0.3px solid rgba(2, 189, 45, 0.3);
  border-radius: 3px;
}

.btnInActive {
  background-color: #1E2224;
  width: 95px;
  height: 23px;
  color: #E5E9EC;
  font-weight: 400;
  font-size: 9px;
  border-radius: 3px;
}

.grayBtn {
  background-color: #1E2224;
}

.grayBtn:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.1) 0%,
      rgba(230, 230, 30, 0) 52.08%,
      rgba(248, 249, 249, 0.1) 100%);
}

.greenBtn {
  background-color: rgba(2, 189, 45, 0.12);
}

.greenBtn:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.btnActive:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.btnInActive:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.1) 0%,
      rgba(230, 230, 30, 0) 52.08%,
      rgba(248, 249, 249, 0.1) 100%);
}

.purple {
  border-radius: 0px;
  background-color: #31112A !important;
  color: white;
  /* background: #fed500; */
  /* owais */
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  border: 0.6px solid #FF16CC;
  border-top: 0.6px solid #FF16CC !important;
  border-left: 0.6px solid #FF16CC !important;

  /* box-sizing: border-box; */
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 400 !important;
  line-height: 12px;
  letter-spacing: 0em;
  z-index: 105 !important;
  text-align: center;
}


.bg_red {
  background: rgba(255, 10, 0, 1);
  border: 0.1px solid rgba(30, 34, 36, 0.2);
  border-radius: 0px;
  color: rgba(30, 34, 36, 1);
  width: 89px;
  height: 30px;
  left: 90px;
  top: 0px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #1e2224;
  font-weight: 900;
}

.inpppDate {
  border-radius: 0px;
  border: 0.1px solid #758278;
  top: 0px;
  color: rgba(248, 249, 249, 1);
  box-sizing: border-box;
  width: 90px;
  height: 22px;
  left: 0px;
  top: 7.33px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 8px;
  align-items: center;
  text-align: center;
}

.inppDate {
  border-radius: 0px;
  border: 0.1px solid rgba(117, 130, 120, 0.3);
  top: 0px;
  color: rgba(248, 249, 249, 1);
  box-sizing: border-box;
  width: 90px;
  height: 22px;
  left: 0px;
  top: 7.33px;
  background: #151515;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  align-items: center;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
}

.ma {
  border-radius: 0px;
  color: rgba(30, 34, 36, 1);
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  border: 0.1px solid rgba(117, 130, 120, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  background: #ff0a00;
  text-align: center;
}

.mba {
  border-radius: 0px;
  color: rgba(30, 34, 36, 1);
  background: #fed500;
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  border: 0.1px solid rgba(117, 130, 120, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

.bg_yellow {
  border-radius: 0px;
  /* color: black !important; */

  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  border: 0.1px solid rgba(30, 34, 36, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 900 !important;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

.bg_cream {
  background: #eee !important;
}

.bg_blue {
  background: blue !important;

}

.bg_green {
  border-radius: 0px;
  background: #02BD2D;
  border: 0.1px solid rgba(30, 34, 36, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  color: #151515;

  font-weight: 900 !important;
  line-height: 12px;
  text-align: center;
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
}

.darkgreen {
  border-radius: 0px;
  background: #145223;
  border: 0.1px solid rgba(30, 34, 36, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 500 !important;
  line-height: 12px;
  text-align: center;
  color: white;
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
}

.dg {
  border-radius: 0px;
  background: #153d1e;
  width: 90px;
  height: 30px;
  left: 90px;
  top: 0px;
  border: 0.1px solid rgba(30, 34, 36, 0.3);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 900 !important;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

.red span {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  line-height: 11px;
  align-items: center;
  text-align: center;
  color: #1e2224;
  font-weight: 900 !important;

  font-size: 8px;
}

.green span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(3, 5, 5);
}

.dg span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 8px;
  line-height: 11px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(248, 249, 249, 1);
  display: flex;
  align-items: center;
  text-align: center;
}

.yellow span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inppDate span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inpDate span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  /* font-weight: 900 !important; */
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.headings th span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 380 !important;
  font-size: 12px;
}

/* tr:hover td {
  background-color:white !important;
  color: black;
  opacity: 0.8;
  border: none;
  font-weight: bold;
   border: 0.1px solid rgba(117, 130, 120, 0.2);
} */

table td {
  height: 23px !important;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #222;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay__content {
  margin-top: 18vh;
  transform: translateX(1%);
}

.overlay__content_alert {
  margin-top: 18vh;
  transform: translateX(40%);
}

/* .overlay__content_badge {
  transform: translate(-50%, -50%);
} */

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}


/* .alertClass2{
  background-color: #ff0a00 !important;
  font-weight: bold;
  color: white !important;
  border:1px solid gray !important
} */
mbaClass
/* .rowsSelected {
  margin-left: 16px;
  font-size: 12px;
  padding: 1rem;
  z-index: 110;
  color: #828282;

} */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

table th {
  background-color: #151515;
}

table td {
  background-color: #151515;
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

@media only screen and (max-width: 1550px) {
  .watchTable{
    height:57vh
  }

}

</style>