export const products = process.env.VUE_APP_ENVIRONMENT == 'development'
  ? [
    //////-------TEST PRODUCTS-------//////
    "prod_PApAqMWNIsuJsE", // Premium plan Strip test ID
    "prod_PAp7BKzWL1cCam", // Plus plan Strip test ID
  ]
  : [
    //////-------LIVE PRODUCTS-------//////
    "prod_PWGfTLDgNH8wux", //Preumium Plan Stripe live ID
    "prod_PWGfDFrXxvQS7w", // Plus plan Strip live ID
  ];


export const price = process.env.VUE_APP_ENVIRONMENT == 'development'
  ? [
    //////-------TEST PRICE-------//////
    "price_1OMTWRLjbEHKohEJDCMXQH1z", // default premium monthly test 
    "price_1OMTWRLjbEHKohEJmjS7IQKX", // premium yearly test
    "price_1OMTTeLjbEHKohEJ4kQGbsqv", //default plus monthly test
    "price_1OMTTeLjbEHKohEJOIERFxQp", //plus yearly test
  ]
  : [
    //////-------LIVE PRICE-------//////
    "price_1OhE8NLjbEHKohEJcmWYXhN9", // default premium monthly live
    "price_1OhE8NLjbEHKohEJO88kLnk0", // premium yearly live
    "price_1OhE8bLjbEHKohEJcpHcD2Fv", //default plus monthly live
    "price_1OhE8bLjbEHKohEJwCZ9MHvh", //plus yearly live
  ];
