<template>
    <tailwind-modal :showModal="true" :showHeader="false">
        <div class="min-w-[34rem] px-6 py-4" style="
          border: 0.3px solid rgba(2, 189, 45, 0.3);
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #151515;
        ">
            <div style="font-weight: 600; font-size: 17px" class="flex pb-4 text-white">
                Set Two Factor Authentication (2FA)
            </div>
            <div class="w-9/12" v-if="responseMessage" data-temp-component="true">
                <AlertShort class="w-full" :alertType="messageType">
                    <p class="text-sm text-gray-300">
                        {{ responseMessage }}
                    </p>
                </AlertShort>
            </div>
            <div class="flex justify-between w-full mt-5">

                <div v-for="option in Enable2FaBy" :key="option.by"
                    class="cursor-pointer border-[#758278] flex hover:bg-[#2E3238] flex-col justify-center w-5/12 px-6  h-[45px] border-[0.3px] border-solid bg-[#1E2224] rounded-md"
                    @click="handleSelect(option.by)">
                    <div class="items-center flex justify-between">
                        <span
                            :class="[selectedOption === option.by ? 'text-[15px] text-[#02BD2D]' : 'text-[#E5E9EC] text-[15px] opacity-[0.45]']">
                            {{ option.name }}
                        </span>

                        <svg v-if="selectedOption == option.by" class="h-5" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7.8125L6.875 9.6875L10 5.9375" stroke="#02BD2D" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z" stroke="#02BD2D"/>
                </svg>

                        <svg v-else class="opacity-[0.45] h-5 " viewBox="0 0 15 15"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                                stroke="#E5E9EC" />
                        </svg>
                    </div>
                </div>
            </div>



          







            <div class="flex justify-end space-x-2 mt-8">
                <button class="cancel-button rounded px-2 py-1 font-semibold text-sm w-28" @click="closeModal()">
                    CANCEL
                </button>
                <button @click="handleRequest()" aria-label="Post message"
                :class="[loaderState ? 'cursor-not-allowed':'']"
                    class="ok-button px-2"
                     :loading="loaderState"><span class="text-[15px] font-bold">{{ loaderState ?
                        'PLEASE WAIT...' : 'SAVE CHANGES' }}</span></button>

            

            </div>
        </div>
    </tailwind-modal>
</template>
<script>
import TailwindModal from "../popups/TailwindModal.vue";
import userApi from "../../api/userApi";
import AlertShort from "../utilities/AlertShort.vue";
import AreaChartLoader from "../loader/AreaChartLoader.vue"
import VueLoadingButton from "vue-loading-button";
import { mapGetters,mapActions } from 'vuex'

export default {
    data() {
        return {

            loaderState: false,
            responseMessage: null,
            Enable2FaBy: [{ name:"By Phone",by: 'phone' }, { name:"By Email",by: 'email' }],
            selectedOption:  "",
            messageType:"",

        };
    },
    computed: {
        ...mapGetters(["signedInUser"])
    },
    components: {
        TailwindModal,
        AlertShort,
        AreaChartLoader,
        VueLoadingButton,

    },
    methods: {
        ...mapActions(["getUserData"]),
        handleSelect(value){
            this.selectedOption=value

        },
       async handleRequest(){
        console.log(this.selectedOption)
          
            try{
                if(!this.selectedOption){
                    this.responseMessage="kindly select first!"
                    this.messageType="Danger"
                    return

                }
                this.loaderState=true;
                 let res = await userApi.Enable2FA({Enable2FABy:this.selectedOption});
                 console.log("res from modal",res
                 )
                 if(res.data.success){
                    setTimeout(()=>{
                    this.responseMessage=""   
                    this.closeModal() 
                    },1000)
                    this.responseMessage=`Your 2FA is enabled by ${this.selectedOption}.` 
                    this.messageType="Success" 
                     this.loaderState=false
                    

              }
              else{
                  this.loaderState=false
                  this.messageType="Danger" 
                  this.responseMessage=`Something went wrong please try again later.` 
                setTimeout(()=>{
                    this.responseMessage=""   
                    this.closeModal() 
                    },1000) 
              }

            }
            catch(e){

            }
        },
       






        closeModal() {

            this.$emit("close2famodal");
            this.getUserData()
        },
    

    },
    mounted(){
        this.selectedOption = this.signedInUser?.two_FA[0].isEnabled ===true? this.signedInUser?.two_FA[0].two_FA_by : ""
    },



};
</script>
  
<style scoped>
.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}

.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.InputContainer .InputElement {
    position: absolute;
    top: 0;
    color: white !important;
}

div.stripeCard {
    color: #f3f3f3 !important;
}

.btnDefault {
    pointer-events: none;
}
</style>
  