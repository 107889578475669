<template>

     <div v-if="screen==='large'" role="status" class="space-y-2.5 ml-1 rounded px-2 py-1  animate-pulse w-full h-full">
      <div class="flex items-center space-x-2 w-full" v-for="x in rows" :key="x">
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-700 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-10"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
      </div>
   </div>   
      <div v-else role="status" class="space-y-2.5 ml-1 rounded px-2 py-1  animate-pulse w-full h-full">
        <div class="flex items-center space-x-2 w-full" v-for="x in rows" :key="x">
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-700 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-20"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
        <div class="h-6 bg-gray-600 rounded-md dark:bg-gray-600 w-40"></div>
      </div>      
    </div>
  </template>

<script>
export default {
    name: 'SkeletonTableLoader',
    props:["rows","screen"],
    
    data() {
        return {

        }
    }
}

</script>