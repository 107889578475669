<template>
  <div class="main" @click="outside">
    <!-- this div represets right side of the page  -->
    <div class="w-full">
      <div
        class="w-full flex items-center md:justify-start sm:justify-center h-[90vh]"
      >
        <div
          class="lg:w-[40%] md:w-[50%] sm:px-0 px-6 sm:w-[70%] -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600 w-full flex items-center -mt-6"
        >
          <div
            class="bg-black-200 w-full -bg-green-300 flex justify-center"
            :class="loaderState ? 'pointer-events-none' : ''"
          >
            <div
              class="xl:w-7/12 md md:w-7/12 sm: sm:w-7/12 w-full -bg-red-300"
            >
              <!--  -->
              <div>
                <img
                  class="w-full"
                  :src="require('../../assets/mapWhite.svg')"
                />
              </div>
              <!-- this div represets welcome back heading -->
              <div class="mt-1">
                <h3 class="text-white font-bold">Welcome back,</h3>
              </div>
              <!-- this div represets sign in to your acoount back heading -->

              <div class="">
                <p
                  class="text-[#02BD2D] text-[24px] sm:whitespace-nowrap font-black Inter-Bold"
                >
                  Sign in to your account
                </p>
              </div>
              <div class="mt-2" data-temp-component="true" v-if="alertStatus">
                <AlertShort class="w-full" :alertType="alertType">
                  <p class="text-sm text-gray-300">
                    <span :style="{ color: textColor }">{{ messages }}</span>
                    
                  </p>
                </AlertShort>
              </div>
              <div class="mt-4">
                <form @submit.prevent="null">
                  <div class="form-group">
                    <input
                      v-model="email"
                      type="text"
                      ref="email"
                      id="email"
                      class="p-4 bg-transparent block text-gray-200 bordershadow-inner rounded-md leading-tight focus:outline-none xl:w-full md:w-full sm:w-full w-full"
                      placeholder="Email*"
                      style="border: 0.6px solid #7582787d"
                      :disabled="disabled"
                      @input="validInput"
                    />
                  </div>
                  <!-- validationObject.email.status -->
                  <p
                    v-if="validationObject.email.status"
                    :class="
                      'p-0 m-0 mt-0 text-[' +
                    pageColors.MAP_RED +
                    '] text-[1vw]'
                    "
                  >
                    {{ validationObject.email.message }}
                  </p>

                  <div
                    style="border: 0.6px solid #7582787d"
                    class="form-group rounded-md w-full justify-between flex mt-4"
                    ref="password"
                  >
                    <div class="w-10/12" @click.stop>
                      <input
                        v-model="password"
                        id="password"
                        :type="type"
                        class="p-4 bg-transparent password-class w-full rounded-md text-gray-200 focus:outline-none"
                        @click="notValidMethod"
                        placeholder="Password*"
                        :disabled="disabled"
                      />
                    </div>

                    <div
                      class="z-50 w-2/12 items-center -bg-[#081b2a] rounded-r-md flex justify-center"
                    >
                      <font-awesome-icon
                        @click="showPassword"
                        class="text-white"
                        style="color: whitesmoke; height: px; cursor: pointer"
                        :icon="['fas', iconName]"
                        :disabled="disabled"
                      />
                    </div>
                  </div>
                  <p
                    v-if="validationObject.password.status"
                    :class="
                      'p-0 m-0 mt-0 text-[' +
                    pageColors.MAP_RED +
                    '] text-[1vw]'
                    "
                  >
                    {{ validationObject.password.message }}
                  </p>
                  <div class="mt-3">
                    <div class="container">
                      <!-- <label class="ml-4 cursor-pointer"
                        >Remember Me
                        <input type="checkbox" />
                        <span class="checkmark mt-1 ml-0"></span>
                      </label> -->
                    </div>
                    <div
                      class="w-fit font-bold text-[#02bd2d] text-xs flex bg-[#00000073] px-1"
                    >
                      <router-link
                        to="/recoverpassword"
                        :class="[disabled ? pointer_events_none : '']"
                        class="whitespace-nowrap"
                      >
                        <span> Forgot password? </span>
                      </router-link>
                    </div>
                  </div>

                  <!-- <div class="">
                    <button
                      class="flex items-center justify-center rounded bg-[#02BD2D] px-4 py-2 text-white w-full mt-5"
                      type="submit"
                      @click.prevent="signIN"
                    >
                      <svg
                        v-if="loaderState"
                        class="mr-3 h-6 w-6 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span class="font-bold">
                        {{ loaderState ? "SIGNING IN" : "SIGN IN" }}
                      </span>
                    </button>
                  </div> -->

                  <div
                    @click.stop
                    class="w-full flex items-center justify-center mt-2"
                    :class="loaderState ? 'btnDefault' : ''"
                  >
                    <VueLoadingButton
                      aria-label="Post message"
                      :class="loaderState ? 'cursor-not-allowed' : ''"
                      class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                      @click.native="signIN"
                      :loading="loaderState"
                      :styled="isStyled"
                      :type="submit"
                      ><span class="text-[15px] font-bold"
                        >SIGN IN</span
                      ></VueLoadingButton
                    >
                  </div>
                </form>
              </div>

              <div class="donthaveacc flex justify-center mt-7">
                <p>
                  Don't have an account?
                  <router-link
                    :to="`/signup?product=${getProductId()}&price=${getPriceId()}`"
                    :class="[disabled ? pointer_events_none : '']"
                    ><span>Register Now </span></router-link
                  >
                </p>
              </div>
              <div class="flex justify-center mt-10">
                <p class="text-white text-sm">
                  Have an issue?
                  <router-link to="/contactus">
                    <span class="text-sm cursor-pointer"
                      ><a
                        href="/contactus"
                        target="_blank"
                        style="color: #9e9898"
                      >
                        Contact Support.</a
                      ></span
                    >
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full -mt-6 pb-6 flex flex-col items-center justify-center">
      <PageFooter />

      <div class="w-full px-2 mt-2 flex items-center justify-end">
        <span class="text-gray-400 text-[10px]">Version 0.1.0.4.15.1</span>
      </div>
    </div>
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js" async defer></script>

<script>
import router from "@/router";
import userApi from "@/api/userApi";
import AlertShort from "../utilities/AlertShort.vue";
import { Colors } from "../../helper/globalUtils";
import watchListApi from "../../api/watchListApi";
import VueLoadingButton from "vue-loading-button";
import ButtonLoader from "../buttons/ButtonLoader.vue";
import "../customcss/index.css";
import { products, price } from "../../utils/products";
import WatchConfig from "@/components/config/market_watch/WatchConfig";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"

export default {
  data() {
    return {
      indexData: {
        assetID: 14681,
        desc: "All Ordinaries",
        securityName: "ASX All Ordinaries Index",
        symbol: "$XAO.au",
        productId:null,
        priceId:null
      },
      inputColor: false,
      isLoading: false,
      isStyled: false,
      stocklistNodePath: ["fd", "fd_au", "fd_au_allords", "fd_au_allords_bookvalue"],
      WatchConfig,
      data: [
        {
          colName: "symbol",
          label: "Symbol",
          metaInfo: true,
        },
        {
          colName: "company_name",
          label: "Company Name",
          metaInfo: true,
        },
        {
          colName: "sector",
          label: "Sector",
          metaInfo: true,
        },
        {
          colName: "industry",
          label: "Industry",
          metaInfo: true,
        },

        {
          colName: "first_traded",
          label: "First Traded",
          metaInfo: true,
        },
      ],
      pageColors: Colors,
      alertStatus: false,
      email: "",
      password: "",
      sign: false,
      messages: "",
      success: null,
      alertType: "",
      type: "password",
      validatedEmail: null,
      iconName: "fa-eye",
      loaderState: false,
      disabled: false,
      pointer_events_none: "pointer-events-none",
      booleanValue: false,
      validationObject: {
        email: {
          status: false,
          message: "Please enter a valid email address",
          borderColorChnage: false,
        },
        password: {
          status: false,
          message: "Please enter a password",
          borderColorChnage: false,
        },
      },
      watchListApi_Data: [],
      fiveNodePath: ["oz", "oz_bottom_breakout"],
      indicators_settings: [],
      textColor: '',
    };
  },
  components: {
    AlertShort,
    VueLoadingButton,
    ButtonLoader,
    FooterCompanyLinks,
    PageFooter
 
  },

  watch: {
    email(value) {
      this.FieldsBorderColor(["email"], "#7582787d");
      this.validationObject.email.status = false;
      this.email = value;
      this.validateEmail(value);
      this.alertStatus = false;
    },
    password(value) {
      console.log("passowrd trueeeee");
      this.password = value;
      console.log(this.password);
      this.validationObject.password.status = false;
      // this.FieldsBorderColor(["password"], "#7582787d");
      this.validatePassword(value);
      this.alertStatus = false;
    },
  },

  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },

    outside() {
      this.inputColor = false;
      if (this.inputColor == false) {
        this.FieldsBorderColor(["password"], "#7582787d");
      }
    },

    notValidMethod() {
      this.inputColor = true;
      if (this.inputColor == true) {
        this.FieldsBorderColor(["password"], this.pageColors.MAP_GREEN);
      } else {
      }
    },
    handleClick() {
      console.log("click"); /* eslint-disable-line */
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);
    },

    fetchwatchListApi_Data: async function (index = 0) {
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData(params);
        this.watchListApi_Data = res.data.data;
        console.log("this.watchListApi_Data", this.watchListApi_Data);
        this.$store.commit("WATCHLIST_API_DATA", this.watchListApi_Data);
        console.log("api call");
      } catch (err) {
        console.log("something went wrong", err);
      }
    },

    FieldsBorderColor(refrence, color) {
      for (const refvalues of refrence) {
        this.$refs[refvalues].style.border = `0.6px solid ${color}`;
      }
    },
    validatePassword() {
      if (this.$refs.password.value == 0) {
        this.$refs.password.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
      }
    },
    validInput(event) {
      this.email = event.target.value;
      this.validateEmail(event.target.value);
      if (this.validatedEmail) {
      }
    },

    validateEmail(value) {
      if (
        /^[a-zA-Z0-9.'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
          value
        )
      ) {
        this.validatedEmail = true;
      } else {
        console.log("email not true");
        this.validatedEmail = false;
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.iconName = "fa-eye-slash";
      } else {
        this.type = "password";
        this.iconName = "fa-eye";
      }
    },

    getProductId() {
      return products[0];;
    },
    getPriceId() {
      return price[0];
    },


    showErrors(message, alertType, alertStatus) {
      this.messages = message;
      this.alertStatus = alertStatus;
      this.alertType = alertType;
      // setTimeout(() => {
      //   this.alertStatus = false;
      // }, 5000);
    },
    async signIN() {
      let validationErrors = [];
      let isNotValid = false;
      let isFormEmpty = false;
      if (!this.email && !this.passowrd) {
        this.showErrors("Fields with (*) are required", "Danger", true);
        this.FieldsBorderColor(["email", "password"], this.pageColors.MAP_RED);
        isNotValid = true;
        // setTimeout(() => {
        //   this.alertStatus = false;
        // }, 5000);
      }
      if (this.validatedEmail == false) {
        this.validationObject.email.status = true;
        this.FieldsBorderColor(["email"], this.pageColors.MAP_RED);
        isNotValid = true;
      }
      if (!this.password) {
        this.showErrors("Fields with (*) are required", "Danger", true);
        this.FieldsBorderColor(["password"], this.pageColors.MAP_RED);
        isNotValid = true;
      }
      if (isNotValid) {
        return;
      }
      try {
        const payload = {
          email: this.email?.toLowerCase(),
          password: this.password,
        };
        this.loaderState = true;
        this.disabled = true;
        const res = await userApi.userSignIn(payload);
        console.log("res:", res);
        if (res) {
          this.loaderState = false;
          this.disabled = false;
          if (res.data.success) {
            let user = res?.data?.payload;
            if(user?.twoFA&&user?.token){
              router.push(`/verify2FA?Token=${user?.token}`)
            }

            else if (!user?.emailVerification) {
              router.push(`/verifyEmail?Token=${user?.emailVerificationToken}`);
            } else if (!user?.phoneVerification) {
              router.push(
                `/verification?Token=${user?.emailVerificationToken}`
              );
            } else if (!user?.personalInfo) {
              router.push(`/userdetails?Token=${user?.emailVerificationToken}`);
            } 
            else {
              localStorage.setItem("email", res.data.payload.email);
              localStorage.setItem(
                "isAuthenticated",
                res.data.payload.termsAndCondition
              );
              localStorage.setItem("token", res.data.payload.token);
              localStorage.setItem("userId", res.data.payload.userId); // needs to be removed today 6/8/22
              localStorage.setItem(
                "userDetails",
                JSON.stringify(res.data.payload)
              );
              await this.fetchwatchListApi_Data();
              console.log("res.data.payload=======>", res.data.payload);
              this.$store.commit("SET_USER_DETAILS", res.data.payload);

              localStorage.setItem(
                "tableVarient",
                res.data.payload.varientForMarketWatch
              );
              localStorage.setItem("tableColumns", JSON.stringify(this.data));
              localStorage.setItem("miniChartRange", "1Y");
              localStorage.setItem(
                "WatchConfig",
                JSON.stringify(this.WatchConfig)
              );
              localStorage.setItem(
                "fiveNodePath",
                JSON.stringify(this.fiveNodePath)
              );
              localStorage.setItem(
                "stocklistNodePath",
                JSON.stringify(this.stocklistNodePath)
              );
              localStorage.setItem('indicators_settings', JSON.stringify(this.indicators_settings))
              if (res.data.payload.indexData.length) {
                console.log(
                  "res.data.payload.IndexData[0]",
                  res.data.payload.indexData[0]
                );
                localStorage.setItem(
                  "indexData",
                  JSON.stringify(res.data.payload.indexData[0])
                );
              } else {
                localStorage.setItem(
                  "indexData",
                  JSON.stringify(this.indexData)
                );
              }

              router.push("/marketalert");
            }
          } else {
            this.loaderState = false;
            this.disabled = false;
            this.showErrors(res.data.message, "Danger", true);
            console.log("Mydata",res.data);
            if (res.data.payload.email_password === false) {
              this.FieldsBorderColor(
                ["email", "password"],
                this.pageColors.MAP_RED
              );
            }
            // if(res.data.payload.subscribtionStatus==false){
            //   this.textColor = 'green';
            // }
            // setTimeout(() => {
            //   this.alertStatus = false;
            // }, 5000);
          }
        }
      } catch (err) {
        this.loaderState = false;
        this.disabled = false;
        this.showErrors("Server Error", "Danger", true);
      }
    },
  },
  mounted() {
    let currentYear = new Date().getFullYear();
    this.$store.commit("CHANGE_YEAR", currentYear);
    this.productId = products[0];
    this.priceId = price[0]
    console.log("product id==>",this.productId,this.priceId)
  },

  created() {
    let currentYear = new Date().getFullYear();
    this.$store.commit("CHANGE_YEAR", currentYear);
  },
};
</script>

<style scoped>
a {
  color: #02bd2d;
}

a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}

input:focus {
  border: 1px solid #02bd27 !important;
}

.main {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-image: url("../../assets/map-main-background.jpg");

  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-size: 100% 100%;
  height: auto;
  /* opacity: 0.3; */
}

.loginImage {
  width: 60vw;
  height: 100vh;
  position: fixed;

  right: 0px;
}

.divColor:focus {
  background-color: Aqua;
}

/* background and main setting page  */
.maintemplate {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-image: url("../../assets/map-main-background.jpg");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  width: 100vw;
  height: 100%;
}

.test {
  color: aliceblue;
}

.left {
  margin-left: 8rem;
  margin-top: 8rem;
}

.logoLogin {
  width: 350px;
}

.welcome {
  color: #e5e9ec;
  font-size: 18px;
  font-weight: 400;
  margin-left: 9px;
  margin-top: 35px;
}

.signintoacc {
  color: #02bd2d;
  font-size: 24px;
  font-weight: 600;
  margin-left: 9px;
}

.textfields {
  border: 1px solid rgba(117, 130, 120, 0.6);
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 9px;
  width: 350px;
  color: #758278;
  text-indent: 10px;
  height: 45px;
  display: flex;
  flex-direction: column;
}

.textfields::placeholder {
  color: #758278;
  font-size: 12px;
  margin-left: 5rem;
  /* padding: 10px; */
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #fff;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #02bd2d;
}

.container {
  /* display: block; */
  position: relative;
  /* padding-left: 25px; */
  /* margin-bottom: 11px; */
  /* cursor: pointer; */
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #e5e9ec;
  /* margin-top: 10px; */
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: black;

  border-radius: 15px;
  border: 1px solid #758278;
  /* margin-left: 8.5px; */
  /* margin-top: 3px; */
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
      background-color: #ccc;
    } */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #02bd2d;
}

/* Create the checkmark/indicator (hidden when not checked) */
/* .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    } */

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  /* top: 5px; */
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.remindMe {
  /* width: 20px; */
  display: flex;
}

.forgetPassword {
  font-size: 12px;
  color: #02bd2d;
  /* margin-top: 12px; */
  /* margin-left: 10rem; */
}

.forgetPassword a {
  text-decoration: none;
}

.signinbtn {
  background-color: #02bd2d;
  width: 351px;
  text-align: center;
  border-radius: 2px;
  height: 45px;
  color: #f8f9f9;
  margin-top: 17px;
  padding: 14px;
  cursor: pointer;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 9px;
}

.or {
  /* width: 100%;  */
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  opacity: 0.3px;
  line-height: 0.1em;
  margin: 15px 0 20px;
}

.or span {
  background: transparent;
  color: #fff;
  opacity: 0.3px;
  padding: 0 10px;
}

.or h5 {
  margin-top: -11px;
  z-index: 100;
  color: #e5e9ec;
  margin-left: 8rem;
  /* padding: 10px; */
  background-color: black;
  width: 27px;
  height: 20px;
  padding-inline: 6.5px;
  font-weight: 100;
}

.socialIcons {
  height: 45px;
  width: 350px;
  /* border: 1px solid white; */
  margin-top: -30px;
  margin-left: 65px;
}

/* .donthaveacc {
      margin-left: 25px;
      padding-inline: 10px;
      font-size: 17px;
      margin-top: 30px;
    } */
.donthaveacc p {
  color: #f8f9f9;
  font-weight: 100;
}

.donthaveacc span {
  color: #02bd2d;
  font-weight: 600;
}

/* .haveanyissue p {
      font-size: 12px;
      color: #e5e9ec;
      margin-left: 40px;  
    } */
/* .haveanyissue span {
      color: #758278;
    } */
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.btnDisabled {
  pointer-events: none;
}

.spinner[data-v-3a698022] {
  right: 4.7rem !important;
  top: 53%;
}

.btnDefault {
  pointer-events: none;
}

.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}

/* media queries  */
@media only screen and (min-width: 1669px) {
  .maintemplate {
    /* background: #02bd2d; */
    background-image: url("../../assets/map-main-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;

    width: 100%;
    height: 100vh;
  }
}

@media only screen and (max-width: 1115px) {
  .loginImage {
    width: 55vw;
    height: 100%;
    position: fixed;
    right: 0px;
    /* z-index: 50; */
  }
}

.password-class:focus {
  border: none !important;
}
</style>
