var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex ml-[9px] min-w-full h-full"},[_c('div',{staticClass:"basis-1/6 flex flex-col min-h-full"},[_c('MarketAlertStatus')],1),_c('div',{staticClass:"basis-5/6 ml-[9px] mr-[18px] flex flex-col min-h-full"},[_c('div',{staticClass:"basis-1/2 flex justify-between"},[_c('div',{staticClass:"h-full mr-[9px] box-border flex flex-col w-full",staticStyle:{"border":"0.3px solid rgba(2, 189, 45, 0.3)","border-radius":"6px"}},[_vm._m(0),_c('div',{staticClass:"flex justify-evenly"},[_c('gauges-chart',{attrs:{"id":2,"curves":_vm.M_curves,"currentValue":_vm.momentumValue,"strokeWidth":5,"radius":_vm.smallRightGaugeHeight / 2.975,"shadowRadius":_vm.smallRightGaugeHeight / 3.7,"shadowWidth":_vm.smallRightGaugeHeight / 8.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.momentumValue)?_c('span',{staticClass:"label Inter-Bold",class:_vm.handleStringFomat(_vm.clickedRowStore.alerts) === 'market_alert'
                  ? 'text-[#FF0A00]'
                  : '',staticStyle:{"font-size":"1vw"}},[_c('p',{class:_vm.handleStringFomat(_vm.clickedRowStore.alerts) ===
                    'market_alert'
                    ? 'text-[#FF0A00]'
                    : ''},[_vm._v(" "+_vm._s(_vm.momentumValue)+"% ")])]):_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"0.7vw"}},[_vm._v("N/A")])]},proxy:true},{key:"label-bottom",fn:function(){return [_c('span',{staticClass:"label-bottom -mt-8",staticStyle:{"font-size":"0.6vw"}},[_vm._v(" Momentum (M) ")])]},proxy:true}])}),_c('gauges-chart',{attrs:{"id":3,"curves":_vm.B_curves,"currentValue":_vm.brakoutValue,"strokeWidth":5,"radius":_vm.smallRightGaugeHeight / 2.975,"shadowRadius":_vm.smallRightGaugeHeight / 3.7,"shadowWidth":_vm.smallRightGaugeHeight / 8.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.brakoutValue)?_c('span',{staticClass:"label Inter-Bold",class:_vm.handleStringFomat(_vm.clickedRowStore.alerts) === 'market_alert'
                  ? 'text-[#FF0A00]'
                  : '',staticStyle:{"font-size":"1vw"}},[_c('p',{class:_vm.handleStringFomat(_vm.clickedRowStore.alerts) ===
                    'market_alert'
                    ? 'text-[#FF0A00]'
                    : ''},[_vm._v(" "+_vm._s(_vm.brakoutValue)+"% ")])]):_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"0.7vw"}},[_vm._v("N/A")])]},proxy:true},{key:"label-bottom",fn:function(){return [_c('span',{staticClass:"label-bottom -mt-8",staticStyle:{"font-size":"0.6vw"}},[_vm._v(" Breakout (B) ")])]},proxy:true}])}),_c('gauges-chart',{attrs:{"id":4,"curves":_vm.A_curves,"currentValue":_vm.advanceValue,"strokeWidth":5,"radius":_vm.smallRightGaugeHeight / 2.975,"shadowRadius":_vm.smallRightGaugeHeight / 3.7,"shadowWidth":_vm.smallRightGaugeHeight / 8.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.advanceValue)?_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"1vw"}},[_c('p',{class:_vm.handleStringFomat(_vm.clickedRowStore.alerts) ===
                    'market_alert'
                    ? 'text-[#FF0A00]'
                    : ''},[_vm._v(" "+_vm._s(_vm.advanceValue)+"% ")])]):_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"0.7vw"}},[_vm._v("N/A")])]},proxy:true},{key:"label-bottom",fn:function(){return [_c('span',{staticClass:"label-bottom -mt-8",staticStyle:{"font-size":"0.6vw"}},[_vm._v(" Advance (A) ")])]},proxy:true}])})],1)]),_c('div',{staticClass:"h-full",staticStyle:{"border":"0.3px solid rgba(2, 189, 45, 0.3)","border-radius":"6px"}},[_c('div',{ref:"smallRigthGauge_Ref",staticClass:"w-[7.4vw] h-[18.5vh] box-border rounded-sm"},[_vm._m(1),_c('div',{staticClass:"flex justify-center items-center"},[_c('gauges-chart',{attrs:{"id":5,"curves":_vm.VIX_curves,"currentValue":_vm.vixGauge,"strokeWidth":5,"radius":_vm.smallRightGaugeHeight / 2.975,"shadowRadius":_vm.smallRightGaugeHeight / 3.7,"shadowWidth":_vm.smallRightGaugeHeight / 8.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.vixGauge != undefined)?_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"1vw"}},[_vm._v(_vm._s(_vm.vixGauge)+" ")]):_c('span',{staticClass:"label Inter-Bold",staticStyle:{"font-size":"0.7vw"}},[_vm._v("N/A")])]},proxy:true},{key:"label-bottom",fn:function(){return [_c('span',{staticClass:"label-bottom -mt-8",staticStyle:{"font-size":"0.6vw"}},[_vm._v(" VIX ")])]},proxy:true}])})],1)])])]),_c('div',{staticClass:"basis-1/2 flex flex-col mt-[9px]"},[_c('BarChartUpd')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-inter text-gray-100 flex justify-center not-italic font-semibold text-lg leading-7"},[_c('h1',{staticStyle:{"background":"rgb(21, 21, 21)","font-size":"1vw","padding-inline":"5px"}},[_vm._v(" MBA Daily Situation ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-inter text-gray-100 flex justify-center not-italic font-semibold text-lg leading-7"},[_c('h1',{staticStyle:{"background":"rgb(21, 21, 21)","font-size":"1vw","padding-inline":"5px"}},[_vm._v(" Volatility ")])])
}]

export { render, staticRenderFns }