<template>
  <div>
    <MarketIndex v-if="modelOpen" v-on:closeModal="Close" v-on:selectIndex="selectIndex" :indice="indexList" />
    <div class="flex-col h-full justify-between w-full items-center">
      <div class="px-1">
        <div class="w-full flex justify-between items-center flex-wrap">
          <template v-if="isIndexChart" >
            <div @click="Model" style="margin-top:-1px"
              class="h-[20px] w-max inline-block bg-transparent cursor-pointer justify-between items-center gap-[2px] p-[1px] rounded">
              <div class="flex">
                <div class="flex items-center h-full w-fit text-[1vw] font-semibold text-gray-200 pl-2">
                  {{ indexData?.desc }}
                </div>

                <div class="flex items-center h-full w-fit justify-center pb-1 pl-1 pt-1 pr-1">
                  <svg width="16" height="12" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.628407 16.8988L5.52015 15.272C5.60111 15.2521 5.67777 15.211 5.74103 15.1487L16.3866 4.67277C16.9282 4.13981 17.199 3.43774 17.199 2.73616C17.199 2.0345 16.9282 1.33252 16.3866 0.799557C15.845 0.266598 15.1316 7.82013e-05 14.4186 7.82013e-05C13.7056 7.82013e-05 12.9922 0.266598 12.4507 0.799557L1.81206 11.2686C1.75847 11.3192 1.71605 11.3828 1.69058 11.4568L1.69157 11.4586L0.0383568 16.272C-0.00613403 16.3743 -0.0139904 16.4925 0.0251255 16.6063C0.109312 16.8516 0.379652 16.9831 0.62882 16.9002L0.628407 16.8988ZM5.14395 14.4106L1.23342 15.7111L2.5551 11.8631L13.1242 1.4624C13.4798 1.11246 13.9491 0.937577 14.4186 0.937577C14.8882 0.937577 15.3575 1.11246 15.713 1.4624C16.0685 1.81225 16.2463 2.27409 16.2463 2.73616C16.2463 3.19824 16.0685 3.65999 15.713 4.00992L5.14395 14.4106Z"
                      fill="#02BD2D" />
                    <path
                      d="M14.9164 5.45673C15.1024 5.63975 15.1024 5.93654 14.9164 6.11957C14.7304 6.30259 14.4288 6.30259 14.2428 6.11957L10.9805 2.90912C10.7945 2.72618 10.7945 2.42938 10.9805 2.24628C11.1664 2.06325 11.4681 2.06325 11.654 2.24628L14.9164 5.45673Z"
                      fill="#02BD2D" />
                    <path
                      d="M5.74112 14.4858C5.92711 14.6688 5.92711 14.9656 5.74112 15.1486C5.55514 15.3316 5.25354 15.3316 5.06755 15.1486L1.80521 11.9383C1.61922 11.7552 1.61922 11.4584 1.80521 11.2754C1.99119 11.0924 2.29279 11.0924 2.47878 11.2754L5.74112 14.4858Z"
                      fill="#02BD2D" />
                  </svg>
                </div>
              </div>
            </div>
            <!-- <CardButtons  @click="Model" btnType='premium-lock' btnText='premium' /> -->
          </template>

          <div class="flex items-center space-x-2" v-else>
            <h1 class="font-semibold px-[4px] text-[white] pt-2.5 cursor-pointer inline-block"
              :class="stockAlertpdfChart ? '' : 'mb-5'">
              {{ symbolName }}
            </h1>
            <div v-if="isStockAlert" class="flex pt-2.5 w-full items-center pl-1 space-x-2">
              <div :style="changeInValueColor" :class="stockAlertpdfChart ? '' : 'mb-5'" class="flex items-center">
                {{
                  valueData < 1000 ? parseFloat(valueData).toFixed(2) : valueData }} <!-- 41.578 -->
              </div>
              <div :style="changeInPriceColor" class="rounded-sm text-[11px] flex items-center px-2 py-2"
                :class="!stockAlertpdfChart ? 'mb-1 h-2' : pdfChart ? 'h-2 mt-6' : 'h-4'">
                <span :class="pdfChart ? 'pb-3' : ''">{{ closingDiff
                  }}<span class="pl-1 Inter-Bold">({{ closingPercent }}%)</span></span>
              </div>
              <div v-if="showOHLC" class="w-full flex items-center space-x-6">
                <div class="ml-2" :class="stockAlertpdfChart ? '' : 'mb-2'">
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.12537 6C6.12537 5.75 6.00037 5.5 5.87537 5.375L1.75037 1.25C1.37537 0.875 0.750366 0.875 0.375366 1.25C0.000365705 1.625 0.000365678 2.25 0.375366 2.625L3.75037 6L0.375365 9.375C0.00036535 9.75 0.000365323 10.375 0.375365 10.75C0.750365 11.125 1.37537 11.125 1.75037 10.75L5.75037 6.75C6.00037 6.5 6.12537 6.25 6.12537 6Z"
                      fill="#BDBDBD" />
                  </svg>
                </div>
                <div v-for="(item, i) in ohlc" :class="stockAlertpdfChart ? 'space-x-6' : 'space-x-2 mb-5'"
                  class="flex items-center" :key="i">
                  <div :class="stockAlertpdfChart ? '' : 'text-[12px] space-x-0'"
                    class="flex ohlcText items-center space-x-1 text-white font-bold">{{ item.key }}</div>
                  <div :class="stockAlertpdfChart ? '' : 'text-[12px] space-x-0'"
                    class="flex ohlcText items-center text-white/40 space-x-1">{{ item.key === 'V' ? item.value :
                      item.value?.toFixed(2) }}</div>
                </div>
              </div>

            </div>
          </div>

          <div class="flex justify-between text-white" :class="isIndexChart?'mt-1':'mt-3'">
            <div v-if="!pdfChart" class="dropdown relative w-[20%] px-2 h-[20px]" data-te-dropdown-ref  style="margin-top: -1px;">
              <button
                class="dropdown-toggle text-[#A5A9B2] transition text-[.8vw] duration-150 ease-in-out space-x-4 flex items-center justify-between whitespace-nowrap w-[100%] h-[100%]"
                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ selectedRange }}
                <span class="text-xs ml-1">▼</span>
              </button>

              <ul
                class="dd-menu dropdown-menu min-w-max h-35 overflow-y-auto absolute hidden z-[105] bg-[#1E2224] float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
                aria-labelledby="dropdownMenuButton1">
                <li
                  class="dropdown-item text-sm py-3 px-6 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700"
                  v-for="(r, i) in showtrendRanges" :key="i" @click="VasChanger(r.id, i, r)">
                  {{ r.desc }}
                </li>
              </ul>
            </div>

            <router-link :style="'border-radius:5px;margin-inline:2px;background-color:null'" class="px-2"
              :class="pdfChart ? 'mt-1.5' : ''" v-if="stockAlertpdfChart"
              :to="`/chart?t=${isIndexChart ? indexData?.symbol : symbolName}`">
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.5564 3.32435C21.5564 1.4904 20.0676 0 18.2349 0H3.3215C1.48877 0 0 1.4904 0 3.32435V11.6807C0 13.5147 1.48877 15 3.3215 15H18.2349C20.0676 15 21.5564 13.5147 21.5564 11.6807V3.32435ZM19.5183 3.32435C19.5183 2.61704 18.9433 2.04109 18.2349 2.04109H3.3215C2.61305 2.04109 2.03808 2.61704 2.03808 3.32435V11.6807C2.03808 12.388 2.61305 12.964 3.3215 12.964H18.2349C18.9433 12.964 19.5183 12.388 19.5183 11.6807V3.32435Z"
                  fill="#02BD2D" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.50729 12.0242L7.6029 8.79074L9.10194 10.0083C9.50237 10.3367 10.0825 10.3115 10.4572 9.95275L13.979 6.55261L17.2645 10.1548C17.6444 10.5742 18.2913 10.6045 18.7071 10.2256C19.1229 9.84665 19.1537 9.19997 18.7738 8.78569L14.7849 4.40543C14.595 4.19829 14.3383 4.08208 14.0611 4.07198C13.7839 4.06188 13.5169 4.16292 13.3218 4.3549L9.68718 7.85608L8.15734 6.61829C7.74665 6.27979 7.14601 6.32021 6.77638 6.70418L3.03392 10.6095C2.64376 11.0188 2.65916 11.6654 3.06472 12.0545C3.47028 12.4435 4.11713 12.4283 4.50729 12.0242Z"
                  fill="#02BD2D" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.2889 3.95023L11.0527 3.71278C11.0168 3.67236 11.0014 3.61678 11.0168 3.56121C11.0322 3.51069 11.0784 3.47027 11.1297 3.46016C11.525 3.37933 12.2386 3.23787 12.5979 3.16714C12.6442 3.15703 12.6955 3.17219 12.7314 3.20755C12.7674 3.24292 12.7776 3.29344 12.7725 3.33891C12.7006 3.69762 12.5569 4.42008 12.4799 4.81416C12.4696 4.86468 12.4285 4.91015 12.3772 4.92531C12.3259 4.94046 12.2694 4.9253 12.2283 4.88994L12.0127 4.66764L9.9695 6.61779C9.78469 6.79462 9.49206 6.80472 9.29185 6.64305L7.37698 5.07182L4.36351 8.26986C4.16842 8.477 3.845 8.48711 3.63965 8.29513C3.43431 8.09809 3.42404 7.77475 3.61912 7.57266L6.95602 4.02601C7.14083 3.83403 7.44372 3.81382 7.6542 3.98054L9.5896 5.56693L11.2889 3.95023Z"
                  fill="#02BD2D" />
              </svg>

            </router-link>
          </div>


        </div>




        <div v-if="!isStockAlert" class="flex w-full pl-1 space-x-1">
          <div :style="changeInValueColor" class="mt-2 flex items-center">

            {{
              valueData < 1000 ? parseFloat(valueData).toFixed(2) : valueData }} </div>


              <div :style="changeInPriceColor" class="rounded-sm text-[11px] flex items-center p-3"
                :class="pdfChart ? 'h-2 mt-6' : 'h-5 mt-1.5'">
                <span :class="pdfChart ? 'pb-3' : ''">{{ closingDiff
                  }}<span class="pl-1 Inter-Bold">({{ closingPercent }}%)</span></span>
              </div>
          </div>
        </div>



        <div class="h-[18.5vh] mb-2 vld-parent" ref="marketChart">
          <span v-if="showLoader">
            <AreaChartLoader :showChartLoader="isLoading" />
          </span>
          <apex-chart class="px-2" id="apex-lines" type="area" :height="chartHeightProp" :options="this.chartOptions"
            :series="this.series" ref="chart"></apex-chart>
        </div>
      </div>
    </div>
</template>

<script>
//  import VueApexCharts from "vue3-apexcharts";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import MarketIndex from "../popups/MarketIndex.vue";
import CardButtons from "@/components/buttons/CardButtons.vue";
import FivesApi from "@/api/fivesApi";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import userApi from "../../api/userApi";
export default {
  name: "AreaChart",
  components: {
    MarketIndex,
    CardButtons,
    AreaChartLoader,
    //  apexchart: VueApexCharts,
  },
  methods: {
    // handleMouseLeave(){
    //   if(this.isStockAlert){ 
    //     let lastData = this.dataChart[this.dataChart?.length - 1]
    //     if(lastData?.close){
    //       this.ohlc = [{key:"O",value:lastData?.open,heading:"open"},{key:"H",value:lastData?.high,heading:"high"},{key:"L",value:lastData?.low,heading:"low"},{key:"C",value:lastData?.close,heading:"close"},{key:"V",value:lastData?.volume,heading:"volume"},]
    //     }
    //   }
    // }, 

    updateToolTip(goToTime) {
      if (this.isStockAlert) {
        // setTimeout(() => {
          this.showTooltipOnSpecificDate(goToTime)
        // }, 500);
      }
    },
    updateDataPointsValues(indx) {
      if (indx >= 0 && this.dataChart?.length > 0) {

        let value = this.vas == "YTD" ? this.getYTD() : this.vas;
        let limit = moment().subtract(value, "months");
        let mapValue = [];
        for (let e of this.dataChart) {
          let date = moment(e.date);
          if (date.valueOf() > limit.valueOf()) {
            mapValue.push(e);
          }
        }
        const newData = mapValue[indx]
        const updatedOhlc = this.ohlc?.map(item => {
          const heading = item.heading;
          const value = newData[heading];
          return { ...item, value };
        });
        this.ohlc = updatedOhlc;
      }
    },
    Model() {
      this.modelOpen = true;
    },
    Close(e) {
      this.indexName = e;
      this.modelOpen = false;
    },
    getSymbolName(val) {
      let symbol = val.slice(1);
      this.symbolName = symbol;
    },
    async selectIndex(e) {
      this.indexData = e;
      this.FetchData();
      this.getSymbolName(e.symbol);
      let resultOfSaveIndex = await userApi.saveIndex(this.indexData);
      if (resultOfSaveIndex?.data.success) {
        localStorage.setItem("indexData", JSON.stringify(this.indexData));
      }
      this.indexName = e.desc;
      this.modelOpen = false;
    },
    async fetchIndexList() {
      this.isLoading = true;
      let indexList = await FivesApi.fetchIndicies();
      this.indexList = indexList?.data ? indexList.data : [];
      if (this.indexList.length > 0) {
        this.isLoading = false;
      }
    },
    ...mapActions(["loadIndexData"]),
    VasSelected(x) {
      return x === this.vas;
    },
    VasChanger(x, index, value) {
      // this.chartAllConfig?.clearAnnotations();
      // selectedRange(value.desc)
      localStorage.setItem("miniChartRange", value.desc);
      this.currentRangeIndex = index;
      this.$emit("timeValue", x);
      this.vas = x;
      this.selectedRange = value.desc;
      this.FetchData("VasChanger");

      return this.vas;
    },
    IndexType(i) {
      this.index = i;
      this.FetchData("IndexType");
    },
    Clr() { },

    sendingValues() {
      let payload = {
        value: this.valueData,
        closingDiff: this.closingDiff,
        closingPercent: this.closingPercent,
      };
      this.$emit("value-emitted", payload);
    },

    setColors(candles) {
      if (candles?.length >= 2) {
        // let seriesData = candles[candles.length -1];
        // let currentIndex = config?.dataPointIndex;
        // let prevIndex = currentIndex - 1;
        let candleLast = candles[candles.length - 1];
        let yesterdayClose = candleLast?.close ? candleLast.close : 0;
        let candleSecLast = candles[candles.length - 2];
        let dayBeforeYesterday = candleSecLast?.close ? candleSecLast.close : 0;
        if (yesterdayClose && dayBeforeYesterday) {
          // let close = Number(series[1])
          // let pclose = Number(seriesPrev[1])
          let closingDiff = yesterdayClose - dayBeforeYesterday;
          
          let closingPercent = closingDiff / dayBeforeYesterday;
          this.closingDiff = closingDiff.toFixed(2);
          this.closingPercent = (closingPercent * 100).toFixed(2);
          this.colorProp = this.closingDiff <= 0 ? "red" : "#00C92E";
          this.colorPropShade =
            this.closingDiff <= 0
              ? "rgba(255, 10, 0, 0.12)"
              : "rgba(57, 255, 20, 0.1)"
          this.valueData = yesterdayClose;
          this.valueData = yesterdayClose?.toLocaleString("en-US");

          this.sendingValues();
        }
      }
    },
    async getIndexData(payload) {
      const selectedYear = localStorage.getItem('miniChartRange') ?? '';
      if (selectedYear == 'YTD') {
        this.vas = 'YTD'
      }
      let value = this.vas == "YTD" ? this.getYTD() : this.vas;
      let map = [];
      console.log("hardik zzzzzzzzzz", this.vas, payload);
      if (selectedYear == '5Y') {
        // this.$store.commit("CHART_CLICKED", {chartClickedOldDate:this.selectedActiveYearStore })
        let limit_start = moment(`${new Date().getFullYear() - 5}-01-01`).startOf('year').subtract(2, 'months');;
        let limitup = moment(`${new Date().getFullYear()}-01-01`).endOf('year');
        // if (this.selectedActiveYearStore < this.getFiveYearChartRange.endYear && this.selectedActiveYearStore >= this.getFiveYearChartRange.startYear) {
        //   limit_start = moment(`${this.getFiveYearChartRange.startYear}-01-01`).startOf('year');
        //   limitup = moment(`${this.getFiveYearChartRange.endYear}-01-01`).endOf('year');
        // } else {
        //   this.$store.commit("FIVE_YEAR_CHART_RANGE", { startYear: this.selectedActiveYearStore - 5, endYear: this.selectedActiveYearStore })
        // }
        this.setColors(this.dataChart);
        let today = moment().startOf('day');
        let endOfYear = moment().endOf('year').add(2, 'months');
        const todaycount = moment();

        // Get the last day of the current year
        // const endOfcurrentYear = moment().endOf('year');

        // // Calculate the difference in days
        // const remainingDays = 1596 - endOfcurrentYear.diff(todaycount, 'days');

        setTimeout(() => {
          if (this.selectedActiveYearStore == new Date().getFullYear()) {
            this.chartAllConfig?.addXaxisAnnotation({
              x: moment().startOf('year').valueOf(),
              borderColor: '#7CFC00 ',
              line: {
                strokeDashArray: 5, // Adjust the dash length
                position: 'bottom',
              },
            })
            this.chartAllConfig?.addXaxisAnnotation({
              x: moment().endOf('year').valueOf(),
              borderColor: '#7CFC00 ',
              line: {
                strokeDashArray: 5, // Adjust the dash length
                position: 'bottom',
              },
            })
          }
        }, 1500);
        let resp = await FivesApi.fetchIndexList(payload, 5400);
        if (resp.data && resp.data.data) {


          this.candles = resp.data.data;
          for (const e of resp.data.data) {
            let date = moment(e.date);
            if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {

              map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            }
          }
          while (today.isBefore(endOfYear)) {
            map.push([today.format('YYYY-MM-DDTHH:mm:ss'), null]);
            today.add(1, 'days');
          }
          // return map;

        }
        return map;
      } else {
        const currentDate = new Date().getMonth() + 1;
        let limit_start
        if (selectedYear == '1Y') {
          limit_start = moment(`${this.selectedActiveYearStore - 1}-${currentDate.toString()}-01`);
        } else if (selectedYear == 'YTD') {
          limit_start = moment(`${this.selectedActiveYearStore - 1}-12-01`);
        }


        // let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
        let limitup = moment();
        let days = moment().subtract(value, "months");
        let limit = moment().diff(days, "days");
        // if(selectedYear == '1Y'){
        //   limit = 252
        // } else if(selectedYear == 'YTD'){
        //   const currentDate = new Date().getMonth()+1;
        //   limit = currentDate*21
        // }
        let resp = await FivesApi.fetchIndexList(payload, 500);
        setTimeout(() => {
          if (this.selectedActiveYearStore == new Date().getFullYear()) {
            this.chartAllConfig?.clearAnnotations();
            this.chartAllConfig?.addXaxisAnnotation({
              x: moment().startOf('year').valueOf(),
              borderColor: '#7CFC00 ',
              line: {
                strokeDashArray: 5, // Adjust the dash length
                position: 'bottom',
              },
            })
            // this.chartAllConfig?.addXaxisAnnotation({
            //   x: moment().endOf('year').valueOf(),
            //   borderColor: '#7CFC00 ',
            //   line: {
            //     strokeDashArray: 5, // Adjust the dash length
            //     position: 'bottom',
            //   },
            // })
          }
        }, 1000);
        if (resp.data && resp.data.data) {


          this.candles = resp.data.data;
          for (const e of resp.data.data) {
            // let date = e.date;
            // map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            let date = moment(e.date);
            // let date = moment(e.date).utc()
            if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
              map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            }
          }

          return map;

        } else {
          return [];
        }
      }
    },
    FilterIndexData() {
      let limit = moment().subtract(this.vas, "months");
      let map = [];
      this.setColors(this.candles);
      for (let e of this.candles) {
        let date = moment(e.date);
        if ((date.valueOf() > limit.valueOf())) {
          map.push([date.valueOf(), Number(e.close).toFixed(2)]);
        }
      }
      if (map.length > 0) {
        this.series = [
          {
            name: this.symbolName,
            data: map,
          },
        ];
      }
    },

    getYTD() {
      const moment = require("moment");
      const currentDate = moment(); // get the current date/time
      const currentMonth = currentDate.month(); // get the current month (0-11)
      const ytdMonths = currentMonth + 1;

      return ytdMonths;
    },

    FetchData() {

      if (this.isIndexChart) {

        this.getIndexData(this.indexData).then((d) => {
          let newData = d.map((d) => {

            let timestamp = moment.utc(d[0]).valueOf();
            let value = parseFloat(d[1]);
            if (isNaN(value)) {
              value = null;
            }
            return [timestamp, value];
          });

          this.setColors(this.candles);
          this.series = [
            {
              data: newData,
            },
          ];
        });
      } else {

        const selectedYear = localStorage.getItem('miniChartRange') ?? '';
        if (selectedYear == 'YTD') {
          this.vas = 'YTD'
        }
        let value = this.vas == "YTD" ? this.getYTD() : this.vas;
        // let limit = moment().subtract(value, "months");
        let map = [];
        let currentYear = new Date().getFullYear()
        if ((selectedYear == '1Y' || selectedYear == '6M') && this.selectedActiveYearStore != currentYear && this.selectedRange != '6M' && this.selectedRange != '3M' && this.selectedRange != '1M') {
          let limit_start = moment(`${this.selectedActiveYearStore}-01-01`).startOf('year');
          let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
          this.setColors(this.dataChart);
          for (let e of this.dataChart) {

            let date = moment(e.date);
            // let date = moment(e.date).utc()
            if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
              map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            }
          }
          // this.$store.commit("YEAR_FIRST_LAST_DATE", { start_date: moment(map[0][0]).format('YYYY-MM-DD'), last_date: moment(map[map.length-1][0]).format('YYYY-MM-DD') })
        } else if (this.selectedRange == '6M') {
          if (currentYear == this.selectedActiveYearStore) {
            let limit = moment().subtract(value, "months");
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              let date = moment(e.date);
              // let date = moment(e.date);
              // let date = moment(e.date).utc()
              if (date.valueOf() > limit.valueOf()) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }
          } else {

            let limit_start = moment(`${this.selectedActiveYearStore}-07-01`);
            let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              let date = moment(e.date);
              // let date = moment(e.date).utc()
              if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }
          }
        } else if (this.selectedRange == '3M') {
          if (currentYear == this.selectedActiveYearStore) {
            let limit = moment().subtract(value, "months");
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              let date = moment(e.date);
              // let date = moment(e.date);
              // let date = moment(e.date).utc()
              if (date.valueOf() > limit.valueOf()) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }
          } else {
            let limit_start = moment(`${this.selectedActiveYearStore}-10-01`);
            let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              // let date = moment(e.date).subtract(value, "months");
              let date = moment(e.date);
              // let date = moment(e.date).utc()
              if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }

          }
        } else if (this.selectedRange == '1M') {
          if (currentYear == this.selectedActiveYearStore) {
            let limit = moment().subtract(value, "months");
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              let date = moment(e.date);
              // let date = moment(e.date);
              // let date = moment(e.date).utc()
              if (date.valueOf() > limit.valueOf()) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }
          } else {

            let limit_start = moment(`${this.selectedActiveYearStore}-12-01`);
            let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
            this.setColors(this.dataChart);
            for (let e of this.dataChart) {
              // let date = moment(e.date).subtract(value, "months");
              let date = moment(e.date);
              // let date = moment(e.date).utc()
              if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
                map.push([date.valueOf(), Number(e.close).toFixed(2)]);
              }
            }
          }
        } else if (selectedYear == '5Y') {
          this.$store.commit("CHART_CLICKED", { chartClickedOldDate: this.selectedActiveYearStore })
          let limit_start = moment(`${new Date().getFullYear() - 5}-01-01`).startOf('year').subtract(2, 'months');
          let limitup = moment(`${new Date().getFullYear()}-01-01`).endOf('year');
          // if (this.selectedActiveYearStore < this.getFiveYearChartRange.endYear && this.selectedActiveYearStore >= this.getFiveYearChartRange.startYear) {
          //   limit_start = moment(`${this.getFiveYearChartRange.startYear}-01-01`).startOf('year');
          //   limitup = moment(`${this.getFiveYearChartRange.endYear}-01-01`).endOf('year');
          // } else {
          //   this.$store.commit("FIVE_YEAR_CHART_RANGE", { startYear: this.selectedActiveYearStore - 5, endYear: this.selectedActiveYearStore })
          // }
          this.setColors(this.dataChart);
          let today = moment().startOf('day');
          let endOfYear = moment().endOf('year').add(2, 'months');
          
          while (today.isBefore(endOfYear)) {
            map.push([today.valueOf(), null]);
            today.add(1, 'days');
          }
          setTimeout(() => {
            if (this.selectedActiveYearStore == new Date().getFullYear()) {
              this.chartAllConfig?.addXaxisAnnotation({
                x: moment().startOf('year').valueOf(),
                borderColor: '#7CFC00 ',
                line: {
                  strokeDashArray: 5, // Adjust the dash length
                  position: 'bottom',
                },
              })
              this.chartAllConfig?.addXaxisAnnotation({
                x: moment().endOf('year').valueOf(),
                borderColor: '#7CFC00 ',
                line: {
                  strokeDashArray: 5, // Adjust the dash length
                  position: 'bottom',
                },
              })
            }
            }, 1000);
          for (let e of this.dataChart) {
            // let date = moment(e.date).subtract(value, "months");
            let date = moment(e.date);
            // let date = moment(e.date).utc()
            if ((date.valueOf() > limit_start.valueOf()) && (date.valueOf() < limitup.valueOf())) {
              map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            }
          }
        } else {
          let limit = moment().subtract(value, "months");
          // let limit = moment(`${this.selectedActiveYearStore}-01-01`).startOf('year');
          // let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
          setTimeout(() => {
            if (this.selectedActiveYearStore == new Date().getFullYear()) {
              // this.chartAllConfig?.clearAnnotations();
              this.chartAllConfig?.addXaxisAnnotation({
                x: moment().startOf('year').valueOf(),
                borderColor: '#7CFC00 ',
                line: {
                  strokeDashArray: 5, // Adjust the dash length
                  position: 'bottom',
                },
              })
              this.chartAllConfig?.addXaxisAnnotation({
                x: moment().endOf('year').valueOf(),
                borderColor: '#7CFC00 ',
                line: {
                  strokeDashArray: 5, // Adjust the dash length
                  position: 'bottom',
                },
              })
            }
            }, 1000);
          // let map = [];
          this.setColors(this.dataChart);
          for (let e of this.dataChart) {
            let date = moment(e.date);
            // let date = moment(e.date);
            // let date = moment(e.date).utc()
            if (date.valueOf() > limit.valueOf()) {
              map.push([date.valueOf(), Number(e.close).toFixed(2)]);
            }
          }
        }
        if (map.length > 0) {
          let updatedMap = (this.vas == "12" || this.vas == "YTD") ? map?.slice(2) : map;
          this.series = [
            {
              name: this.symbolName,
              data: map,
            },
          ];
        }
      }
    },

    showTooltipOnSpecificDate(dT) {
      const specificDate = dT;
      this.rowClickDate = dT;
      const dataIndexDates = this.series[0].data.findIndex(x => x[0] == specificDate);
      this.chartAllConfig?.clearAnnotations();
      if (dataIndexDates !== -1) {
        const foundDateIndex = dataIndexDates;
        const dataIndexValue = this.chartAllConfig?.w.globals.series[0][foundDateIndex];


        const seriesData = this.series[0]?.data
        // Extract the numeric values from the second element of each sub-array
        const values = seriesData?.map(item => parseFloat(item[1]));

        // Find the highest value
        const highestValue = Math.max(...values);
        // Clear existing annotations
        // Add an annotation for the specific date
        this.chartAllConfig?.clearAnnotations();
        // setTimeout(() => {
        this.chartAllConfig?.clearAnnotations();
        const chartCenterX = this.chartAllConfig.w.globals.svgWidth / 2;
        // const datePixel = this.chartAllConfig.ctx.getXForValue(specificDate);
        const xAxisMin = this.chartAllConfig.w.globals.minX;
        const xAxisMax = this.chartAllConfig.w.globals.maxX;

        // Get the pixel value for the specific date
        const datePixel = ((specificDate - xAxisMin) / (xAxisMax - xAxisMin)) * this.chartAllConfig.w.globals.svgWidth;
        const offsetX = datePixel > chartCenterX ? -50 : 50;


        let setDate
        if (new Date().getFullYear() == new Date(this.yearFirstLastDateChart.start_date).getFullYear()) {
          setDate = moment().startOf('year').format('YYYY-MM-DD')
          this.yearFirstLastDateChart.last_date = moment().endOf('year').format('YYYY-MM-DD')
        } else {
          setDate = this.yearFirstLastDateChart.start_date
        }
        this.chartAllConfig?.addXaxisAnnotation({
          x: moment(`${setDate}T00:00:00Z`).valueOf(),
          borderColor: '#7CFC00 ',
          line: {
            strokeDashArray: 5, // Adjust the dash length
            position: 'bottom',
          },
        });
        this.chartAllConfig?.addXaxisAnnotation({
          x: moment(`${this.yearFirstLastDateChart.last_date}T00:00:00Z`).valueOf(),
          borderColor: '#7CFC00 ',
          line: {
            strokeDashArray: 5, // Adjust the dash length
            position: 'bottom',
          },
        });

        if (this.tableRowClicked) {
          this.chartAllConfig?.addPointAnnotation({
            x: specificDate,
            y: dataIndexValue,

            markers: {
              size: 0,
            },

            label: {
              top: 0,
              offsetY: dataIndexValue == highestValue ? this.chartHeightProp - 140 : 0,
              fixed: dataIndexValue != highestValue ? true : false,
              offsetX: offsetX,

              style: {
                color: '#fff',
                background: '#2d2d2d',
                fontSize: '12px', // Adjust the font size
                zIndex: '999'
              },
              text: `${this.symbolName} ${dataIndexValue}`,
            },


          });

          this.chartAllConfig?.addXaxisAnnotation({
            x: specificDate,
            borderColor: '#ffffff',
            line: {
              strokeDashArray: 5, // Adjust the dash length
              position: 'bottom',
            },
          });
        }
        // this.chartAllConfig?.addPointAnnotation({
        //      x: specificDate,
        //      markers: {
        //        size: 0,
        //      },
        //      label: {
        //      offsetY: this.chartHeightProp - 25,
        //      offsetX: 0,
        //      style: {
        //        color: '#fff',
        //        background: '#2d2d2d',
        //        fontSize: '12px', // Adjust the font size
        //        zIndex:'50px'
        //      },
        //        text: this.getProperTime(specificDate),
        //      },


        //    });
        // }, 100);

        // this.chartAllConfig?.toggleDataPointSelection(specificDate, 0);
      }
    },

    getProperTime(timeInSec) {
      let formattedDate = moment(timeInSec).format('DD/MM/YYYY');
      return formattedDate
    },

  },
  data() {
    return {
      rowClickDate: null,
      datestring: null,
      chartHeightProp: 0,
      chartAllConfig: null,
      currentRangeIndex: 0,
      closingDiff: 0,
      closingPercent: null,
      isLoading: false,
      goToDateVal: null,
      colorProp: "green",
      ohlc: [{ key: "O", value: 0, heading: "open" }, { key: "H", value: 0, heading: "high" }, { key: "L", value: 0, heading: "low" }, { key: "C", value: 0, heading: "close" }, { key: "V", value: 0, heading: "volume" }],
      colorPropShade: "rgba(57, 255, 20, 0.1)",
      showtrendRanges: [
        // { id: "1", desc: "1M" },
        // { id: "3", desc: "3M" },
        // { id: "6", desc: "6M" },
        { id: "YTD", desc: "YTD" },
        { id: "12", desc: "1Y" },
        { id: "60", desc: "5Y" },
        // { id: "120", desc: "10Y" },
        // { id: "180", desc: "15Y" },
        // { id: "240", desc: "All" },
      ],
      ranges: [
        // { id: "6", desc: "6M" },
        // { id: "3", desc: "3M" },
        // { id: "1", desc: "1M" },
      ],
      indexList: [],
      indexName: "",
      modelOpen: false,
      valueData: null,
      dataPointIndex: null,
      dataPointSeries: [],
      indexData: null,
      selectedRange: "1Y",

      vas: "1",
      index: "",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#1E2224", "#1E2224", "#1E2224", "#1E2224", "#1E2224"],
        markers: {
          size: 0,
          colors: "#02BD2D",
          strokeColors: "#fff",
          strokeWidth: 3,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 5,
          },
        },
        grid: {
          show: true,
          borderColor: "#2E3238",
          strokeDashArray: 0,
          position: "back",

          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: -1,
            right: 30,
            bottom: 0,
            left: 10,
          },
        },
        chart: {
          id: "chart-bar",
          toolbar: {
            show: false,
          },

          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: "#000",
            opacity: 0.35,
          },
          events: {
            mounted: (chartContext, config) => {
              this.chartAllConfig = chartContext;
              // this.showTooltipOnSpecificDate(chartContext);
            },
            click: (event, chartContext, config) => {
              let selectedDate = config.config.series[config.seriesIndex].data[config.dataPointIndex][0]
              if ((moment(selectedDate).year() >= new Date().getFullYear()-5) && selectedDate <= this.series[0].data[this.series[0].data.length - 1][0] && this.$route.path == '/stockalert') {
                this.chartAllConfig?.clearAnnotations();
                if (this.stockAlertAreaChart) {
                  this.chartAllConfig?.clearAnnotations();
                  var selectedValue = config.config.series[config.seriesIndex].data[config.dataPointIndex][1];
                  const seriesData = this.series[0]?.data
                  let getdata = seriesData.map(item => [new Date(item[0]), item[1]]).filter(item => item[0].getFullYear() === moment(selectedDate).year());
                  // Extract the numeric values from the second element of each sub-array
                  const values = seriesData?.map(item => parseFloat(item[1]));

                  // Find the highest value
                  const highestValue = Math.max(...values);
                  const chartCenterX = this.chartAllConfig.w.globals.svgWidth / 2;
                  // const datePixel = this.chartAllConfig.ctx.getXForValue(specificDate);
                  const xAxisMin = this.chartAllConfig.w.globals.minX;
                  const xAxisMax = this.chartAllConfig.w.globals.maxX;

                  // Get the pixel value for the specific date
                  const datePixel = ((selectedDate - xAxisMin) / (xAxisMax - xAxisMin)) * this.chartAllConfig.w.globals.svgWidth;
                  const offsetX = datePixel > chartCenterX ? -50 : 50;
                  this.$store.commit("CHANGE_YEAR", moment(selectedDate).year());
                  let thisyearstartdatefetch
                  let thisyearenddatefetch
                  if(new Date().getFullYear()== moment(getdata[0][0]).year()){
                    thisyearstartdatefetch = moment().startOf('year').format('YYYY-MM-DD')
                    thisyearenddatefetch = moment().endOf('year').format('YYYY-MM-DD')
                  } else{
                    thisyearstartdatefetch =moment(getdata[0][0]).format('YYYY-MM-DD')
                    thisyearenddatefetch = moment(getdata[getdata.length-1][0]).format('YYYY-MM-DD')
                  }
                  this.$store.commit("YEAR_FIRST_LAST_DATE", { start_date: thisyearstartdatefetch, last_date: thisyearenddatefetch })
                  // awaitfetchStockAlertData()
                  // let limit_start = moment(`${this.selectedActiveYearStore-5}-01-01`).startOf('year');
                  // let limitup = moment(`${this.selectedActiveYearStore}-01-01`).endOf('year');
                  this.chartAllConfig?.addPointAnnotation({
                    x: selectedDate,
                    y: selectedValue,

                    markers: {
                      size: 0,
                    },

                    label: {
                      top: 0,
                      offsetY: selectedValue == highestValue ? this.chartHeightProp - 140 : 0,
                      fixed: selectedValue != highestValue ? true : false,
                      offsetX: offsetX,

                      style: {
                        color: '#fff',
                        background: '#2d2d2d',
                        fontSize: '12px', // Adjust the font size
                        zIndex: '999'
                      },
                      text: `${this.symbolName} ${selectedValue}`,
                    },


                  });
                  let setDate
                  if (new Date().getFullYear() == new Date(this.yearFirstLastDateChart.start_date).getFullYear()) {
                    setDate = moment().startOf('year').format('YYYY-MM-DD')
                    this.yearFirstLastDateChart.last_date = moment().endOf('year').format('YYYY-MM-DD')
                  } else {
                    setDate = this.yearFirstLastDateChart.start_date
                  }
                  this.chartAllConfig?.addXaxisAnnotation({
                    x: moment(`${setDate}T00:00:00Z`).valueOf(),
                    borderColor: '#7CFC00 ',
                    line: {
                      strokeDashArray: 5, // Adjust the dash length
                      position: 'bottom',
                    },
                  });
                  this.chartAllConfig?.addXaxisAnnotation({
                    x: moment(`${this.yearFirstLastDateChart.last_date}T00:00:00Z`).valueOf(),
                    borderColor: '#7CFC00 ',
                    line: {
                      strokeDashArray: 5, // Adjust the dash length
                      position: 'bottom',
                    },
                  });
                  if (moment(selectedDate)?.year() != this.chartClickedFlag.chartClickedOldDate && localStorage.getItem('miniChartRange')=='5Y') {
                    this.$store.commit("CHART_CLICKED", {chartClickedOldDate:moment(selectedDate)?.year() })
                    // setTimeout(()=>{
                      let convertedDate = moment(selectedDate)?.format("YYYY-MM-DD")
                      let addInArray = [convertedDate]
                      this.$store.commit("TABLE_ROW_CLICKED", true)
                      this.$store.commit("CLICKED_CALENDER_DATE", addInArray);
                      // this.chartAllConfig?.removeAnnotations('a');
                      this.chartAllConfig?.addXaxisAnnotation({
                        x: selectedDate,
                        borderColor: '#ffffff ',
                        line: {
                          strokeDashArray: 5, // Adjust the dash length
                          position: 'bottom',
                        },
                      });
                    // },1000)
                  } else {
                    let convertedDate = moment(selectedDate)?.format("YYYY-MM-DD")
                    let addInArray = [convertedDate]
                    this.$store.commit("TABLE_ROW_CLICKED", true)
                    this.$store.commit("CLICKED_CALENDER_DATE", addInArray);
                    this.chartAllConfig?.addXaxisAnnotation({
                      x: selectedDate,
                      borderColor: '#ffffff ',
                      line: {
                        strokeDashArray: 5, // Adjust the dash length
                        position: 'bottom',
                      },
                    });
                  }

                  // const dataIndexClickValue = this.chartAllConfig?.w.globals.series[0][foundDateIndex];
                  // this.chartAllConfig?.addPointAnnotation({
                  //   x: selectedDate,
                  //   y: dataIndexValue,

                  //   markers: {
                  //     size: 0,
                  //   },

                  //   label: {
                  //     top:0,
                  //     offsetY: dataIndexValue == highestValue ?  this.chartHeightProp - 140 : 0,
                  //     fixed: dataIndexValue != highestValue ? true : false,
                  //   offsetX: 0,
                  //   style: {
                  //     color: '#fff',
                  //     background: '#2d2d2d',
                  //     fontSize: '12px', // Adjust the font size
                  //     zIndex:'999'
                  //   },
                  //     text: `${this.symbolName} ${dataIndexValue}`,
                  //   },


                  // });
                  // if(selectedDate){
                  //   let convertedDate = moment(selectedDate)?.format("YYYY-MM-DD")
                  //   let addInArray = [convertedDate]
                  //   this.$store.commit("CLICKED_CALENDER_DATE", addInArray);
                  // }

                }
              }

            }
          },
        },
        fill: {
          colors: [" #00C92E"],

          gradient: {
            // shade: 'dark',
            type: "vertical",
            // shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 90, 100],
            colorStops: [],
          },
          // colors: ["rgba(196, 196, 196, 0)"],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: ["#00C92E"],
          width: 2,
          curve: "straight",
        },

        labels: [10, 12, 13, 14, 15, 16, 17],
        xaxis: {
          labels: {
            style: {
              colors: ["#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9"],
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          type: "datetime",
        },
        yaxis: {
          labels: {
            align: "right",

            style: {
              colors: ["#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9", "#F8F8F9"],
            },
            formatter: (value) => {
              return value.toLocaleString("en-US");
            },
          },

          opposite: true,
        },
        legend: {
          horizontalAlign: "left",
        },

        tooltip: {

          enabled: true,
          enabledOnSeries: undefined,
          shared: false,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: "11px",
            fontWeight: "bold",
            colors: "",
            fontFamily: undefined,
          },

          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: false,
            format: "dd/MM/yy",
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName, w) => {
                this.dataPointIndex = w?.dataPointIndex
                this.dataPointSeries = w?.series
                if (seriesName == "series-1") {
                  return ''
                } else {
                  return seriesName
                }
              },
            },
          },
          z: {
            formatter: undefined,
            title: "Size: ",
          },
          marker: {
            show: true,
          },
          items: {
            display: "flex",
          },
          fixed: {
            enabled: false,
            position: "topCenter",
            offsetX: 110,
            offsetY: 10,
          },
        },
      },

    };
  },
  computed: {
    ...mapGetters([
      "indexDataValues",
      "indexUpdated",
      "indexDataMap",
      "getActiveCountry",
      "indexDataValuesDefault",
      "signedInUser",
      "getStockAlertChartDate",
      "getTableData",
      "selectedActiveYearStore",
      "tableRowClicked",
      "yearFirstLastDateChart",
      "getFiveYearChartRange",
      "chartClickedFlag",
    ]),
    ...mapActions(["getUserData"]),

    changeInPriceColor() {
      return {
        color: this.colorProp,

        border: `1px solid ${this.colorProp}`,
        backgroundColor: this.colorPropShade,
      };
    },

    changeInValueColor() {
      return {
        marginInline: "5px",
        fontSize: "17px",
        color: this.colorProp,
      };
    },
  },

  props: {
    MarketWatchChartHeight: {},
    isStockAlert: {
      type: Boolean,
      default: false
    },
    showTrend: {
      type: Boolean,
      default: false,
    },
    showOHLC: {
      type: Boolean,
      default: true,
    },
    isIndexChart: {
      type: Boolean,
      default: true,
    },
    pdfChart: {
      type: Boolean,
      default: false,
    },
    stockAlertpdfChart: {
      type: Boolean,
      default: true,
    },
    stockAlertAreaChart: {
      type: Boolean,
      default: false,
    },
    dataChart: {
      type: Array,
      default() {
        return [];
      },
    },
    symbolData: {
      type: Object,
      default() {
        return {};
      },
    },
    clossingDifference: {
      type: String,
      default: "",
    },
    symbolName: {
      type: String,
      default: "XAO.au",
    },
    reportData: {
      type: Boolean,
      default: false,
    },
    chartHeight: {
      type: Number,
      default: undefined,
    },
    closingDiffPercent: {
      type: String,
      default: "0",
    },
    isWatchlistReport: {
      type: Boolean,
      default: false,
    },
    watchlistReport: {
      type: Object,
      default() {
        return {};
      },
    },
    closingPrice: {
      type: String,
      default: "",
    },
    showLoader: {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    let range = localStorage.getItem("miniChartRange");
    if (range) {
      this.selectedRange = range;
      const rangeIndex = this.showtrendRanges.findIndex(
        (item) => item.desc === range
      );
      const resultObj = this.showtrendRanges.find(
        (item) => item.desc === range
      );
      if (rangeIndex && resultObj) {
        this.VasChanger(resultObj.id, rangeIndex, resultObj);
      }
    }

    await this.fetchIndexList();
    this.index = this.isIndexChart ? this.indexDataMap[0] : 0;
    if (this.isIndexChart && this.indexList?.length > 0) {

      let parseData = localStorage.getItem("indexData");
      if (parseData) {
        let parseAgain = JSON.parse(parseData);
        this.indexData = parseAgain;
        this.FetchData();
      }
    } else {
      this.FetchData("onMount");
    }
    if (this.stockAlertAreaChart || !this.stockAlertpdfChart) {
      this.chartHeightProp = this.chartHeight;
    } else {
      this.chartHeightProp = this.$refs?.marketChart?.clientHeight;
    }

    if (this.showOHLC && this.dataChart?.length > 0) {
      let value = this.vas == "YTD" ? this.getYTD() : this.vas;
      let limit = moment().subtract(value, "months");
      let mapValue = [];
      for (let e of this.dataChart) {
        let date = moment(e.date);
        if (date.valueOf() > limit.valueOf()) {
          mapValue.push(e);
        }
      }
      let latest = mapValue?.length - 1;
      if (latest >= 0) {
        this.updateDataPointsValues(latest);
      }
    }
  },
  watch: {
    selectedActiveYearStore(year, oldVal) {
      this.chartAllConfig?.clearAnnotations();
      this.chartAllConfig?.addXaxisAnnotation({
        x: moment(`${this.yearFirstLastDateChart.start_date}T00:00:00Z`).valueOf(),
        borderColor: '#7CFC00 ',
        line: {
          strokeDashArray: 5, // Adjust the dash length
          position: 'bottom',
        },
      });
      this.chartAllConfig?.addXaxisAnnotation({
        x: moment(`${this.yearFirstLastDateChart.last_date}T00:00:00Z`).valueOf(),
        borderColor: '#7CFC00 ',
        line: {
          strokeDashArray: 5, // Adjust the dash length
          position: 'bottom',
        },
      });
    },
    getStockAlertChartDate(val) {
      if (this.stockAlertAreaChart) {
        const dateObject = new Date(val);
        const data = this.series[0]?.data
        const timeInMil = dateObject?.getTime()
        const currentDate = new Date();
        const dateToCheck = new Date(timeInMil);
        const isSameDate = dateToCheck?.toDateString() === currentDate?.toDateString();
        const result = data?.find(([timestamp]) => timestamp === timeInMil);
        const targetDateFormatted = moment(val).format("YYYY-MM-DDTHH:mm:ss[Z]");
        const isPresent = this.dataChart?.find(item => item.date === targetDateFormatted);
        const selectedYearlocalstorage = localStorage.getItem('miniChartRange') ?? '';
        if (result?.length > 0) {
          if (selectedYearlocalstorage == '5Y' && !this.tableRowClicked) {
            // this.VasChanger(12, 4, { id: "12", desc: "1Y" })
            // this.VasChanger(60, 5, { id: "60", desc: "5Y" })
            // this.VasChanger(60, 5, { id: "60", desc: "5Y" })
            this.updateToolTip(timeInMil)
          } else {
            this.updateToolTip(timeInMil)
            this.$store.commit("TABLE_ROW_CLICKED", false)
          }
        }
        else if (isPresent) {
          if (selectedYearlocalstorage == '1Y') {
            // if(this.vas < 12){
            this.VasChanger(12, 4, { id: "12", desc: "1Y" })
              this.updateToolTip(timeInMil)
            // }

          } else if (selectedYearlocalstorage == '6M' || selectedYearlocalstorage == '3M' || selectedYearlocalstorage == '1M') {
            // if(this.vas < 12){
            this.VasChanger(12, 4, { id: "12", desc: "1Y" })
            this.updateToolTip(timeInMil)
            // }

          } else if (selectedYearlocalstorage == '5Y') {
            // if(this.vas == 12){
            this.VasChanger(60, 5, { id: "60", desc: "5Y" })
            this.updateToolTip(timeInMil)
            // }
          }
          // this.updateToolTip(timeInMil)
          // else if(this.vas == 60){
          //   this.VasChanger(120, 6, { id: "120", desc: "10Y" })
          // this.updateToolTip(timeInMil)
          // }else if(this.vas == 120){
          //   this.VasChanger(180, 7, { id: "180", desc: "15Y" })
          // this.updateToolTip(timeInMil)
          // }else if(this.vas == 180){
          //   this.VasChanger(240, 8, { id: "240", desc: "All" })
          // this.updateToolTip(timeInMil)
          // }
        }
        else {
          return
        }
      }
    },
    selectedRange(n, o) {
      if (n) {
        localStorage.setItem("miniChartRange", n);
      }
    },
    chartHeight(val) {
      this.chartHeightProp = val
    },
    indexUpdated(n, o) {
      if (n && this.isIndexChart) {
        this.FetchData();
      }
    },
    getActiveCountry(n, o) {
      if (n && this.isIndexChart) {
        this.index = this.indexDataMap[0];
        if (
          this.indexDataValues &&
          this.indexDataValues[n] &&
          Object.keys(this.indexDataValues[n]).length > 0
        ) {
          this.FetchData("getActiveCountry");
        } else {
          this.loadIndexData();
        }
      }
    },
    symbolName(n, o) {
      if (n.length > 0) {
        this.FetchData("symbolName");
        if (this.showOHLC && this.dataChart?.length > 0) {
          let value = this.vas == "YTD" ? this.getYTD() : this.vas;
          let limit = moment().subtract(value, "months");
          let mapValue = [];
          for (let e of this.dataChart) {
            let date = moment(e.date);
            if (date.valueOf() > limit.valueOf()) {
              mapValue.push(e);
            }
          }
          let latest = mapValue?.length - 1;
          if (latest >= 0) {
            this.updateDataPointsValues(latest);
          }
        }
      }
    },
    signedInUser: {
      handler(n, o) {
        this.fetchIndexList();
        this.indexData = n.IndexData[0];
      },
      deep: true,
    },
    dataPointIndex(val) {
      this.updateDataPointsValues(val)
    },
  },
};
</script>

<style>
.area_chart_btn:focus {
  border: 0.3px solid #079027;
}

.area_chart_img {
  height: 20px;
}

.area_chart_img:focus {
  height: 31px;
}

.apexcharts-tooltip span {
  color: #f8f8f9;
}

.apexcharts-tooltip {
  border: 0.5px solid #f8f8f9;
  padding-top: 0%;
}

.selectedStockValue {
  border-radius: 3px;
  font-weight: 400;
}

.apexcharts-tooltip-marker {
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.apexcharts-xaxistooltip-bottom {
  background-color: #1e2224 !important;
  border: 0.5px solid #f8f8f9 !important;
  border-radius: 20px !important;
  color: #f8f8f9 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;

  border-radius: 2px !important;
}

.apexcharts-tooltip-y-group {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
  justify-self: center !important;
}

p.normal {
  font-weight: normal;
}

p.thick {
  font-weight: bold;
}

p.thicker {
  font-weight: 900;
}

@media only screen and (max-width: 1550px) {
  .ohlcText {
    font-size: 11.5px !important;
  }
}
</style>