export default {
  mockingNewFeature: false,

  ////--------------------------------------------Live configuration-------------------------------------------///
  chartApi: process.env.VUE_APP_CHART_API || "https://core.marketalertpro.com/api/v1/",
  mapApi: process.env.VUE_APP_MAP_API || "https://stg-api.marketalertpro.com/",
  stripe_id: "pk_live_51MBqE7LjbEHKohEJ7lxdoFp4hR4hnOegE6UW3iudl64VylUbKlCJ9SV8ptYBXYxuVyxRvEBL5yElLOairXFwGmQx00G4BTWBmO",


  // ////--------------------------------------------Developer configuration-------------------------------------------///
  // chartApi: "https://core.marketalertpro.com/api/v1/",
  // mapApi: "http://localhost:4000/",
  // stripe_id: "pk_test_51MBqE7LjbEHKohEJ586i99h05EGgbW68YtEEyJzpFOIZxJrEQ5dwq3fmdyqt9yxd8w2mVBtZR7MBgoCsufJe2pRq00Wni5KuGX"

};
