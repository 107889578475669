<template>
  <div class="w-full flex h-full overflow-none" v-if="checkSize">

    <div class="w-9/12 flex flex-col justify-between">

      <div class="flex" :class="'hidden'">

        <div class="w-3/12 mt-2">
          <SelectCountry />
        </div>

        <div class="w-9/12 mt-2">
          <GuagesDial />

        </div>
      </div>

      <div class="w-full pr-1 h-full">
        <Table v-on:size="sizeVal" :isFullSize="true"/>
      </div>
    </div>

    <div class="w-3/12 flex flex-col justify-between px-2 mt-1 ml-[10px]">
      <div class="w-full h-[32.5%] mt-[7px]">
        <AreaChart style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 5px;" class="h-full" />
      </div>

      <div class="w-full calenderStyle mt-3">
        <calendar-component pageCalender="Market Alert" />
      </div>
    </div>
  </div>
  <div class="w-full flex h-full overflow-none" v-else>
    <div class="w-full flex flex-col justify-between">

      <div class="flex" :class="checkSize ? 'hidden' : ''">

        <div class="w-2/12 mt-2">
          <SelectCountry />
        </div>

        <div class="w-7/12 mt-2">
          <GuagesDial />

        </div>
        <div class="w-3/12 mt-2 mr-1">
          <AreaChart style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 6px;" class="h-full" />
        </div>
      </div>
      <div class="flex" :class="'mb-3 mt-2'">

        <div class="w-9/12 pr-1 mr-2 mt-2">
          <Table v-on:size="sizeVal" />
        </div>

        <div class="w-3/12 mt-3 mr-1">
          <calendar-component pageCalender="Market Alert" />
        </div>

      </div>


    </div>
  </div>



</template>

<!-- <div>
  <div class="flex flex-row">
    <div class="flex flex-col basis-3/4 flex justify-around">
      <div class="basis-1/3 flex justify-start flex-row">
        <div class="basis-1/6 flex  h-fit ">
          <SelectCountry />
        </div>

        <div class="basis-5/6 flex justify-between ">
          <GuagesDial />
                       
        </div>
      </div>

      <div class="basis-1/3 grow justify-between">
      
        <Table />
      
      </div>
    </div>

    <div class="basis-1/4 flex-col flex justify-around h-[93vh]">
      <div ref="MarketWatchAreaChartDiv_Ref" class="basis-1/5 my-2 mx-2 w-[23.5vw] h-[37vh]">
        <AreaChart :MarketWatchChartHeight="MarketWatchChartHeight" />
      </div>

      <div class="basis-2/3  mx-2 w-[22.5vw] h-fit">
        <calendar-component />
      </div>
    </div>
  </div>
</div> -->







<script>
// import SideBar from "../sidebar/SideBar.vue";
import SelectCountry from "../sidebar/SelectCountry.vue";
import CalendarComponent from "../calendar/CalendarComponent.vue";
import AreaChart from "../charts/AreaChart.vue";
import Table from "../tables/MarketWatchTableUpdated.vue";
// import GuagesDial2 from "@/components/sidebar/GuagesDial2";
import GuagesDial from "../sidebar/GuagesDial.vue";
import { mapActions } from "vuex";
// import { methods } from "v-folder";
// import DropDownList from "../../components/popups/DropDownList.vue";
// Import component





export default {
  name: "MarketWatch",


  components: {
    // GuagesDial2,
    GuagesDial,
    // SideBar,
    SelectCountry,
    CalendarComponent,
    AreaChart,

    Table,
    // DropDownList,
  },
  data() {
    return {
      minusValueOfHeight: 0,
      MarketWatchChartHeight: this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - 300,
      // maximize:false,
      size: false,
      checkSize: ""
    }
  },



  mounted() {

    // this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65;
    this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight - 300,
      console.log("Height", this.MarketWatchChartHeight)
    window.addEventListener('resize', this.OnResize)
  },
  methods: {
    ...mapActions(['loadIndexData', 'getUserData']),
    OnResize() {
      // this.$nextTick(()=>{
      //   this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65
      // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeigh
      // })
      this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65,
        this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight - 300
      // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeight
    },
    sizeVal(e) {
      // console.log("value:",e)
      this.checkSize = e
    },
  },
  async mounted() {
    await this.getUserData()
    this.$store.commit("SET_CATEGORY_FOR_DATES", "market_alert")
  }
};
</script>



<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

span {}
</style>
<style>
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.calenderStyle {
  height: 65%
}

@media only screen and (max-width: 1550px) {
  .calenderStyle {
    height: 64.5%
  }

}
</style>