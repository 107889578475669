<template>
  <div class="w-full px-3 pb-4 box-border rounded-sm" style="border: 0.3px solid rgba(2, 189, 45, 0.3);border-radius:6px;">
    
    <div style="

      " class="text-gray-100 flex justify-center not-italic font-semibold relative top-2 mb-[.5vh]">
      
       <h1 style="background: rgb(21, 21, 21); padding-inline: 5px;padding-bottom: 5px;margin-top: -7px;" class="text-[1vw]">Market Health</h1> 
    </div>

    <div style="position:relative; top:-7px;" class="overflow-hidden">
      <div :style="Transforming" class="z-50 ease-in-out duration-2000">
        <span style="margin-left: -5px; font-weight: 300" class="text-white text-[.8vw]">{{Marketheatvalue >0 ? Marketheatvalue : 0}}</span>
        <img :src="require('../../assets/markethealthmarker.png')" class="h-[2vh]"/>
      </div>
    </div>
    <div class="flex justify-between -mt-6">
      <!-- <div class="flex flex-col"> -->

      <div
          class=" box-border flex h-[2.6vh] w-full border border-[#484848]  rounded"
          style="
          background-image: linear-gradient(270deg,#009300 0%,#44cc22 40.69%,#ffd600 55.01%,#fed500 50.56%,#ff0a00 70.2%,#51006d 79.69%);

      "
      >
        <div class="-mt-1 w-1/6 font-thin  text-cyan-50 text-[.8vw] opacity-30 flex items-end" v-for="n in 10" :key="n">|</div>

      </div>
    </div>

    <div class="mt-1 flex justify-between">
      <!-- <div class="flex flex-col"> -->
      <!-- margin-inline: 15px;
          border: 0.3px solid rgba(229, 233, 236, 0.6);
          border-radius: 3px; -->
      <div class="z-0 box-border flex h-[1.5vh] w-full text-xs font-thin">
        <!--        <div class="-mt-1 w-1/6 font-thin text-white flex start" >0</div>-->
        <div class="-mt-1 w-1/6 font-thin text-white flex justify-start text-[.6vw]" v-for="n in 10" :key="n">
          {{ (n - 1) * 10 }}
          <span v-if="n === 10" class="w-full flex justify-end relative left-[.5vw]">100</span>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "BarChartUpd",
  props: ["cvalue"],
  watch: {
    // clickedRowStore() {
    //   this.Marketheatvalue = parseInt(
    //       (this.clickedRowStore.breakout_m * 50 +
    //           this.clickedRowStore.advance_m * 50 +
    //           this.clickedRowStore.momentum_m * 50 -
    //           0.5 * this.clickedRowStore.vix) /
    //       3
    //   );
    // },
  },
  data() {
    return {
      // Marketheatvalue: 0,
    };
  },
  updated() {
    this.Transforming;
  },
  computed: {
    Marketheatvalue(){
      const _breakout=this.breakoutGaugeValue(this.clickedRowStore?.breakout_m)||0
      const _advance=this.advanceGaugeValue(this.clickedRowStore?.advance_m)||0
      const _momentum=this.momentumGaugeValue(this.clickedRowStore?.momentum_m)||0
      const _factoredVix=this.calcVixFactor(this.clickedRowStore?.vix)*this.clickedRowStore?.vix||0
      return Math.round(((_breakout+_advance +_momentum)/3)-(_factoredVix))>0?Math.round(((_breakout+_advance +_momentum)/3)-(_factoredVix)): 0
    },
    Transforming() {
      if (this.Marketheatvalue <= 100 || this.Marketheatvalue >= 0) {
        return {
          transform: `translateX(${this.Marketheatvalue - 0.6}%)`,
          
          transition: "0.3s ease-in-out",
          "-webkit-transition": "0.3s ease-in-out",
        };
      } else {
        return {
          transform: `translateX(${this.Marketheatvalue - 1}%)`,
        };
      }
    },
    ...mapGetters(["clickedRowStore"]),
  },
  methods:{
    calcVixFactor(value) {
      switch (true) {
        case value < 14:
          return -1;
        case (value >= 14 && value <=18):
          return 0.2;
        
        case (value > 18 && value <=22):
          return 0.3;
        
        case (value > 22 && value <=25):
          return 0.4;
        
        case (value > 25 && value <=30):
          return 0.5;
        
        case (value > 30 && value <=40):
          return 0.6;
      
        default:
          return 0.65;
      }
    },
     momentumGaugeValue(value) {
      return value ? ((value * 100) / 2) : 0;
    },
    breakoutGaugeValue(value) {
      return ((value * 100) / 2);
    },
    advanceGaugeValue(value) {
      return ((value * 100) / 2);
    },
  }
};
</script>

<style>
.marker {
  width: 671px;
  margin-left: -600px;
  margin-top: -30px;
}
</style>
