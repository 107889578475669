<template>
    <div>
        <div v-if="btnType == 'premium-lock'"
            class="h-[2vh] w-[4vw] bg-transparent flex justify-between cursor-pointer items-center gap-[.2px] set-ok-button border border-[#00C92E] p-[1px] rounded">
            <div class="flex items-center h-full w-[30%] justify-center">
                <svg class="w-[.75vw]" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.5 4.875C4.38583 4.87367 4.27399 4.90734 4.17954 4.97149C4.08509 5.03564 4.01256 5.12718 3.97171 5.2338C3.93086 5.34042 3.92365 5.457 3.95106 5.56783C3.97846 5.67867 4.03917 5.77846 4.125 5.85375V6.375C4.125 6.47446 4.16451 6.56984 4.23484 6.64017C4.30516 6.71049 4.40054 6.75 4.5 6.75C4.59946 6.75 4.69484 6.71049 4.76517 6.64017C4.83549 6.56984 4.875 6.47446 4.875 6.375V5.85375C4.96083 5.77846 5.02154 5.67867 5.04894 5.56783C5.07635 5.457 5.06915 5.34042 5.0283 5.2338C4.98745 5.12718 4.91491 5.03564 4.82046 4.97149C4.72601 4.90734 4.61417 4.87367 4.5 4.875ZM6.375 3.375V2.625C6.375 2.12772 6.17746 1.65081 5.82583 1.29917C5.4742 0.947544 4.99728 0.75 4.5 0.75C4.00272 0.75 3.52581 0.947544 3.17418 1.29917C2.82254 1.65081 2.625 2.12772 2.625 2.625V3.375C2.32663 3.375 2.04048 3.49353 1.8295 3.7045C1.61853 3.91548 1.5 4.20163 1.5 4.5V7.125C1.5 7.42337 1.61853 7.70952 1.8295 7.9205C2.04048 8.13147 2.32663 8.25 2.625 8.25H6.375C6.67337 8.25 6.95952 8.13147 7.1705 7.9205C7.38147 7.70952 7.5 7.42337 7.5 7.125V4.5C7.5 4.20163 7.38147 3.91548 7.1705 3.7045C6.95952 3.49353 6.67337 3.375 6.375 3.375ZM3.375 2.625C3.375 2.32663 3.49353 2.04048 3.7045 1.8295C3.91548 1.61853 4.20163 1.5 4.5 1.5C4.79837 1.5 5.08452 1.61853 5.2955 1.8295C5.50647 2.04048 5.625 2.32663 5.625 2.625V3.375H3.375V2.625ZM6.75 7.125C6.75 7.22446 6.71049 7.31984 6.64017 7.39017C6.56984 7.46049 6.47446 7.5 6.375 7.5H2.625C2.52554 7.5 2.43016 7.46049 2.35984 7.39017C2.28951 7.31984 2.25 7.22446 2.25 7.125V4.5C2.25 4.40054 2.28951 4.30516 2.35984 4.23484C2.43016 4.16451 2.52554 4.125 2.625 4.125H6.375C6.47446 4.125 6.56984 4.16451 6.64017 4.23484C6.71049 4.30516 6.75 4.40054 6.75 4.5V7.125Z"
                        fill="#00C92E" />
                </svg>
            </div>
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.5vw] text-[#00C92E]">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>

        

        <div v-if="btnType == 'premium-unlock'"
            class="h-[2vh] w-[4vw] flex justify-between items-center gap-[.2px] border border-[#00C92E] p-[5px] rounded set-ok-button">
            <div class="flex items-center h-full w-[30%] justify-center">
                <svg class="w-[.75vw]" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_663_3521)">
                        <path
                            d="M3.5 0.671875C3.86458 0.671875 4.17708 0.802083 4.4375 1.0625C4.69792 1.32292 4.82812 1.63542 4.82812 2C4.82812 2.21875 4.94271 2.32812 5.17188 2.32812C5.39062 2.32812 5.5 2.21875 5.5 2C5.5 1.45833 5.30208 0.989583 4.90625 0.59375C4.51042 0.197917 4.04167 0 3.5 0C2.95833 0 2.48958 0.197917 2.09375 0.59375C1.69792 0.989583 1.5 1.45833 1.5 2V3C1.21875 3 0.981771 3.09635 0.789062 3.28906C0.596354 3.48177 0.5 3.71875 0.5 4V7C0.5 7.28125 0.596354 7.51823 0.789062 7.71094C0.981771 7.90365 1.21875 8 1.5 8H5.5C5.78125 8 6.01823 7.90365 6.21094 7.71094C6.40365 7.51823 6.5 7.28125 6.5 7V4C6.5 3.71875 6.40365 3.48177 6.21094 3.28906C6.01823 3.09635 5.78125 3 5.5 3H2.17188V2C2.17188 1.63542 2.30208 1.32292 2.5625 1.0625C2.82292 0.802083 3.13542 0.671875 3.5 0.671875ZM5.5 3.67188C5.71875 3.67188 5.82812 3.78125 5.82812 4V7C5.82812 7.21875 5.71875 7.32812 5.5 7.32812H1.5C1.28125 7.32812 1.17188 7.21875 1.17188 7V4C1.17188 3.78125 1.28125 3.67188 1.5 3.67188H5.5ZM3.17188 5.9375V6.32812C3.17188 6.55729 3.28125 6.67188 3.5 6.67188C3.71875 6.67188 3.82812 6.55729 3.82812 6.32812V5.9375C4.27604 5.78125 4.5 5.46875 4.5 5C4.5 4.71875 4.40365 4.48177 4.21094 4.28906C4.01823 4.09635 3.78125 4 3.5 4C3.21875 4 2.98177 4.09635 2.78906 4.28906C2.59635 4.48177 2.5 4.71875 2.5 5C2.5 5.20833 2.5625 5.39844 2.6875 5.57031C2.8125 5.74219 2.97396 5.86458 3.17188 5.9375ZM3.5 4.67188C3.71875 4.67188 3.82812 4.78125 3.82812 5C3.82812 5.21875 3.71875 5.32812 3.5 5.32812C3.28125 5.32812 3.17188 5.21875 3.17188 5C3.17188 4.78125 3.28125 4.67188 3.5 4.67188Z"
                            fill="#00C92E" />
                    </g>
                    <defs>
                        <clipPath id="clip0_663_3521">
                            <rect width="6.6" height="8.1" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.5vw] text-[#00C92E]">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
        <div v-if="btnType == 'plus'"
            class="h-[2vh] w-[4vw] bg-transparent flex justify-center items-center  border border-[#F8F9F9] p-[5px] rounded set-plus-button">
           
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.5vw] text-[#F8F9F9]">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
        <div v-if="btnType == 'upgrade'"
            class="h-[2vh] w-[4vw] bg-[#0C0C0C] plusbtn flex justify-center items-center  border border-[#F8F9F9] p-[5px] rounded opacity-50">
           
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.5vw] text-[#F8F9F9]">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
      
        <div v-if="btnType == 'upgrade-plus'"
            class="h-[2vh] w-[4vw] flex justify-center plusbtn items-center  border border-[#F8F9F9] p-[5px] rounded">
           
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.5vw] text-[#F8F9F9]">+&nbsp;{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
      
        <div v-if="btnType == 'coming-soon'"
            class="h-[2vh] w-[4vw] flex justify-center items-center bg-[#F8F9F9] border border-[#F8F9F9] p-[5px] rounded">
           
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.4vw] text-[#000000] font-bold">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
        <div v-if="btnType == 'new-feature'"
            class="h-[2vh] w-[4vw] flex justify-center items-center bg-[#FED500] border border-[#FED500] p-[5px] rounded">
           
            <div class="flex items-center h-full w-full justify-center">
                <span class="text-[.4vw] text-[#000000] font-bold">{{ btnText.toUpperCase() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardButtons',
    props: ['btnText', 'btnType'],
    data() {
        return {

        }
    }
}
</script>

<style>


.set-ok-button:hover{
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}
.set-plus-button{
    background: linear-gradient(
                270deg,
                rgba(248, 249, 249, 0.21) 0%,
                rgba(248, 249, 249, 0) 52.08%,
                rgba(248, 249, 249, 0.21) 100%
        );
}

.plusbtn:hover{
    background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}
</style>