<template>
  <div class="w-full h-[100%] items-stretch flex flex-col overflow-y-hidden">
    <!-- header area  -->

    <!-- <div class="flex items-center w-full h-[5%]  gap-[.5vw] mb-2"> -->
    <div class="w-full flex items-center space-x-2">
      <!-- header area  -->

      <div class="text-white text-[1.6vw] Inter-Bold">
        &nbsp;Plus&nbsp;Lists
      </div>
      <div class="flex text-[.65vw]">
        <span class="text-[#F8F8F9] opacity-40 font-normal">&nbsp;My&nbsp;Favourites</span>
      </div>
      <div class="flex text-[.6vw]">
        <span class="text-[#F8F8F9] opacity-40 font-normal">|</span>
      </div>
      <div class="flex gap-[.5vw] items-center">
        <button class="flex gap-[.2vw] cursor-pointer items-center" v-for="(f, i) in favouriteList" :key="i"
          @click="navigateToTreeFromList(f, i)">
          <SvgListIcon class="w-[.5vw]" :fill-color="f.favColor" />
          <span class="text-[.5vw]" :class="favId == i ? 'text-[green]' : 'text-[#FFFFFF]'">{{ f.desc }}</span>
        </button>
      </div>
    </div>

    <div class="w-full h-[90vh] px-2 flex space-x-1">
      <!-- main div sidebar  -->
      <div class="w-3/12 pt-[1vw] bg-[#1E2125] border-[0.3px] border-[#7582785c] rounded-md">
        <div class="w-full h-[85vh] px-4">
          <div class="w-full h-[65vh]">
            <div class="w-full h-[3vh] flex items-center justify-between">
              <div class="text-white font-semibold text-[.8vw]">
                Plus Lists Browser
              </div>

              <div v-if="userIsNotPremium"
                class="text-white border rounded grayGradient text-[.45vw] self-center cursor-pointer w-[4vw] h-[2vh] flex justify-center items-center"
                @click="$router.push('/settings')">
                + UPGRADE
              </div>
            </div>

            <div class="w-full h-[60vh] text-white">
              <!-- <div class="w-full flex space-x-4 py-2">
                <div class="flex items-center w-full space-x-2">
                  <div class="relative w-9/12">
                    <div
                      class="flex absolute inset-y-0 left-0 items-center pl-[.5vw] pointer-events-none"
                    >
                      <svg
                        aria-hidden="true"
                        class="w-[.8vw] text-white dark:text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="default-search"
                      :class="showInputError ? 'border-[red]' : 'border-[gray]'"
                      class="rounded block p-[.35vw] pl-[1.5vw] w-full text-[.7vw] text-white bg-transparent border-[0.5px] placeholder-white focus:outline-none"
                      placeholder="Search"
                      v-model="searchNodeKey"
                      v-on:keyup.enter="handleSearch"
                    />
                  </div>

                  <button
                    @click="handleSearch"
                    class="w-3/12 bgGradient text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer flex justify-center p-[.35vw] text-[.7vw]"
                  >
                    SEARCH
                  </button>
                </div>
              </div> -->

              <div class="h-[58vh] mt-4 overflow-y-auto">
                <TreeBrowserBeta mainNode :id="n.key" :ref="n.key" @treeLoad="treeLoad" :node="n"
                  v-for="(n, i) of newConfig" :key="i" @onClick="treeEvent" :map="[n.title]" :idMap="[n.key]" />
              </div>
            </div>
          </div>
          <div
            class="w-full bg-[#151515] border-[0.3px] flex flex-col gap-[.6vh] border-[#7582785c] h-[20vh] overflow-y-auto p-[1vw] rounded-b-md">
            <div class="flex items-center space-x-2">
              <img src="images/info.svg" alt="info" class="w-[.7vw]" />
              <div class="Inter-Bold text-md text-base text-white">
                {{ info.name }}
              </div>
            </div>
            <p class="text-[#E5E9EC] text-[.6vw] leading-[2vh]">
              {{ info.desc ? info.desc : "" }}
            </p>
          </div>
        </div>
      </div>

      <div class="w-9/12 border-[0.3px] border-[#7582785c] rounded-b-md rounded-md">
        <!-- table top div  -->
        <div class="flex flex-wrap justify-between h-[10%] items-center px-[.5vw]">
          <div class="flex items-center">
            <div class="text-white flex flex-nowrap">
              <span class="Inter-Bold text-[.9vw]" v-for="(stocks, i) of selectedStock" :key="i">{{ stocks }}
                <span class="opacity-40" v-if="i !== selectedStock.length - 1">
                  &nbsp;|&nbsp;</span>
                <span class="opacity-40" v-if="i === selectedStock.length - 1">
                  :&nbsp;</span>
              </span>
              <span></span>
            </div>
            <div class="flex flex-wrap p-[] text-[.6vw] font-normal text-[#E5E9EC]">
              <span class="opacity-40" v-if="lastUpdated">Updated: {{ lastUpdated }}</span>
            </div>
          </div>
          <div class="flex justify-end items-center gap-[.6vw] h-full">

            <button v-if="watch_List_Api_Data.length > 0 && getStockListData?.length > 0"
              :disabled="getStockListData.length < 1" :class="showError ? 'pointer-events-none' : ''"
              title="Add To Watchlist" @click="SaveWatchlistModal('add')"
              class="text-[#02BD2D] bgGradient border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
              + Add To Watchlist
            </button>
            <button v-if="!watch_List_Api_Data.length && getStockListData?.length > 0"
              :disabled="getStockListData.length < 1" title="Add To Watchlist" @click="SaveWatchlistModal('create')"
              class="text-[#02BD2D] bgGradient border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]"
              :class="showError ? 'pointer-events-none' : ''">
              + ADD TO WATCHLIST
            </button>

            <template v-if="favScanLength > 0">
              <button v-if="!currentFavSaved && favouriteList.length < 4" title="ADD TO FAV" @click="AddToFav"
                class="text-[#02BD2D] bgGradient border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]"
                :class="showError ? 'pointer-events-none' : ''">
                + ADD TO FAV
              </button>
              <button v-if="currentFavSaved" title="REMOVE FROM FAV" @click="RemoveFromFav"
                class="text-[#02BD2D] border bgGradient border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                + REMOVE FROM FAV
              </button>
            </template>

            <!-- pagination  -->
            <div class="flex justify-end items-center gap-[.5vw]"
              :class="stocksListTableData.length > 0 ? '' : 'hidden'">
              <select name="data" v-model="length" class="text-center bg-[#7582785c] text-white focus:outline:none"
                @change="handleChange(length)">
                <option v-for="(num, i) of dataValues" :key="i" :value="num" class="bg-[#151515]">
                  {{ num }}
                </option>
              </select>
            </div>

            <!-- v-if="showWatchlistModal" -->
            <tailwind-modal :show-modal="showWatchlistModal" v-if="showWatchlistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  gap: 3vh;
                  justify-content: space-between;
                  height: 20vh;
                  width: 25vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;
                    flex-grow: 8;
                    gap: 1vh;
                    height: 80%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Save to watchlist</h1>
                  </div>

                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2vh;
                      height: 100%;
                    ">
                    <select class="text-[black] w-8/12 h-[3vh] focus:outline-none" @change="selectWatcRowIndex">
                      <option value="">
                        <span>--Select Watchlist--</span>
                      </option>
                      <option v-for="(names, i) in watch_List_Api_Data" :key="i" :value="i">
                        {{ names.name }}
                      </option>
                    </select>
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponse"
                    class="w-full flex items-center justify-center text-[#02BD2D] h-[100%]">
                    {{ showModalResponse }}
                  </div>

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                    class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]">
                    {{ showModalResponseError }}
                  </div>
                </div>

                <div v-if="!showModalMessage" class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[20%]">
                  <button class="cancel-button w-[20%] h-full text-[.6vw]" @click="showWatchlistModal = false">
                    Cancel
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="SaveWatchlist"
                    :disabled="selectwatchIndex == null">
                    Save
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="CreateFromSelectWatchlist">
                    Create
                  </button>
                </div>
                <!-- <div
                v-if="showModalMessage"
                  class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[20%]"
                >
                  <button
                    class="cancel-button w-[30%] h-full text-[.8vw]"
                    @click="showWatchlistModal = false"
                  >
                    Close
                  </button>
                
                </div> -->
              </div>
            </tailwind-modal>

            <!-- createWatclistModal -->
            <tailwind-modal :show-modal="createWatclistModal" v-if="createWatclistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 20vh;
                  width: 20vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;

                    flex-grow: 8;
                    gap: 1vh;
                    height: 70%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Create watchlist</h1>
                  </div>
                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      gap: 2vh;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    ">
                    <input type="text" placeholder="create watchlist"
                      class="p-2 rounded text-[black] w-9/12 focus:outline-none" v-model="newWatchName"
                      v-on:keyup.enter="SaveCreateWatchlist" />
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponse"
                    class="w-full flex items-center justify-center text-[#02BD2D] h-[100%]">
                    {{ showModalResponse }}
                  </div>

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                    class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]">
                    {{ showModalResponseError }}
                  </div>
                </div>

                <div v-if="!showModalMessage" class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[30%]">
                  <button class="cancel-button h-[70%] w-[30%] text-[.7vw]" @click="createWatclistModal = false">
                    Cancel
                  </button>
                  <button class="ok-button h-[70%] w-[30%] text-[.7vw]" @click="SaveCreateWatchlist"
                    :disabled="newWatchName == ''">
                    Save
                  </button>
                </div>
                <!-- <div
                v-if="showModalMessage"
                  class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[20%]"
                >
                  <button
                    class="cancel-button w-[30%] h-full text-[.8vw]"
                    @click="createWatclistModal = false"
                  >
                    Close
                  </button>
                
                </div> -->
              </div>
            </tailwind-modal>
          </div>
        </div>

        <!-- table div  -->

        <!-- table skeleton  -->
        <div class="w-full p-2 min-h-fit overflow-x-hidden overflow-y-auto" v-if="isLoading">
          <SkeletonTableLoader :rows="18" screen="large" />
        </div>

        <!-- no data found  -->
        <div v-if="showError" class="h-[90%] flex w-full">
          <AlertShort alertType="Warning"
            :title="packageErrorMessage ? packageErrorMessage : 'No Stocks Found by This Search Today'" class="m-auto" />
        </div>

        <!-- table  -->
        <div v-if="
          stocksListTableData.length > 0 &&
          showError == false &&
          isLoading == false
        " class="h-[90%] min-h-fit overflow-scroll rounded-b-md">
          <table class="w-full overflow-x-auto">
            <thead class="border-b-[#3d3a3a] border-b sticky top-0 bg-[#151515]">
              <tr class="">
                <th class="text-[0.9vw] pl-3 text-center" v-if="tableConfig.length > 0">
                  <label class="container mb-2">
                    <input type="checkbox" class="" v-model="checkAll" />
                    <span class="checkmark"></span>
                  </label>
                </th>

                <th class="text-[.6vw] bg-[#151515] pl-4 font-bold text-white select-none border-[#7582785c]">
                  Chart
                </th>
                <th :key="i" v-for="(x, i) of tableConfig" @click.prevent="updateSort(x.key, currentSort?.order, i)"
                  class="text-[.6vw] pl-2 font-bold text-white border-[#7582785c] cursor-pointer select-none" :class="[
                    //x.type == 'string' ? 'text-left' : 'text-right',
                    `width-[${x.width}]`,
                  ]">
                  <div class="flex gap-[.2vw] items-center">
                    {{ x.title }}
                    <table-sort-icon :order="currentSort?.order" v-if="currentSort?.name === x.key" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr :key="i" v-for="(rowData, i) of stocksListTableData">
              <td v-for="(colConf,i) of tableConfig" :key="i">{{rowData[colConf.key]}}</td>
            </tr> -->

              <tr :key="i" v-for="(x, i) of stocksListTableData"
                class="border-b-[1px] border-[#7582785c] h-[5vh] pl-2 hover:bg-[#2E2E2F]">
                <td class="text-[0.9vw] pl-3" v-if="tableConfig.length > 0">
                  <label class="container mb-2">
                    <input type="checkbox" class="" @change="CheckRowTrue($event.target.checked, i, x)"
                      :checked="checkAll || singleInput" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td v-if="stocksListTableData.length > 0" class="">
                  <div class="flex justify-center items-center pl-4">
                    <router-link :to="`/chart?t=${x.symbol}`">
                      <svg width="18" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_571_8639)">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.76867 13.7519L5.73489 10.6234L7.5432 12.1031C7.73859 12.2629 8.02254 12.2518 8.20483 12.0771L11.9751 8.4635L15.2449 12.0779C15.4298 12.2823 15.7458 12.2981 15.9501 12.1132C16.1544 11.9284 16.1703 11.6124 15.9854 11.408L12.3708 7.41252C12.2803 7.31251 12.1532 7.25334 12.0184 7.24855C11.8835 7.24371 11.7525 7.29371 11.6551 7.38701L7.83322 11.0502L6.00804 9.55676C5.80556 9.39108 5.50957 9.40983 5.32957 9.59963L2.04409 13.0649C1.85451 13.2648 1.86292 13.5811 2.06284 13.7707C2.26281 13.9603 2.57909 13.9518 2.76867 13.7519V13.7519Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.67902 6.75151L9.31929 6.38751L10.9887 6.159L10.7406 7.82565L10.3809 7.46165L7.98564 9.82908C7.80186 10.0107 7.50986 10.0221 7.3125 9.85539L5.49434 8.31964L2.50778 11.5715C2.32134 11.7744 2.00527 11.7878 1.80232 11.6014C1.59936 11.415 1.58594 11.099 1.77233 10.896L5.0829 7.29151C5.26396 7.09436 5.56831 7.07508 5.77281 7.24778L7.60822 8.79813L9.67902 6.75151V6.75151Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.9813 4.43245C17.9813 3.97809 17.6124 3.60919 17.158 3.60919H0.82326C0.368894 3.60919 0 3.97809 0 4.43245V15.1063C0 15.5607 0.368894 15.9295 0.82326 15.9295H17.158C17.6124 15.9295 17.9813 15.5607 17.9813 15.1063V4.43245V4.43245ZM0.998521 4.60771V14.931H16.9827V4.60771H0.998521Z"
                            fill="#02BD2D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_571_8639">
                            <rect width="18" height="18" fill="white" transform="translate(0 0.786682)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </router-link>
                  </div>
                </td>

                <td v-for="(column, i) of tableConfig" :key="i" :style="[
                  columnHighlight == i
                    ? { 'background-color': '#3a3a3a5c' }
                    : {},
                  i === 1 ? { width: '30%' } : {}
                ]" class="text-[.65vw] pl-2 font-medium border-[#7582785c]" :class="[
                    `width-[${column.width}]`,
                    //column.type == 'string' ? 'text-left' : 'text-right',
                    selectedTreeValue == column.highlighter && i == tableConfig.length - 1 ? 'bg-[#3a3a3a5c]' : 'bg-transparent'
                  ]">
                  <span v-if="getTableValue(x, column) == null" class="text-gray-500">{{ column?.type == 'string' ?
                    'N/A' : 0
                    }}</span>
                  <span :class="x[column.key] < 0 ? 'text-[red]' : 'text-white'">{{ getTableValue(x, column) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watchListApi from "@/api/watchListApi";
import TailwindModal from "@/components/popups/TailwindModal";
import TreeView from "../../tree/TreeView.vue";
import { mapGetters } from "vuex";
import TreeBrowserBeta from "@/components/tree/TreeBrowserBeta";
import FivesApi from "@/api/fivesApi";
import moment from "moment";
import { Tree_event } from "@/components/tree/tree_event";
import SvgListIcon from "@/components/tree/SvgListIcon";
import TableSortIcon from "@/components/tables/TableSortIcon";
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue";
import AlertShort from "@/components/utilities/AlertShort.vue";
import structure from "./structure.json";
import us_ColumnConfig, { us_oberbought, us_turnover } from "@/components/config/stocklist/us_Columnconfig";
import au_ColumnConfig, { au_oberbought, au_turnover } from "@/components/config/stocklist/au_Columnconfig";
import ca_ColumnConfig, { ca_oberbought, ca_turnover } from "@/components/config/stocklist/ca_Columnconfig";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";

export default {
  name: "StockListBeta",
  components: {
    SkeletonTableLoader,
    TableSortIcon,
    AreaChartLoader,
    SvgListIcon,
    TreeBrowserBeta,
    TailwindModal,
    AlertShort,
    // VFolder
    TreeView,
  },
  data() {
    return {
      tableConfig: [],
      showInputError: false,
      favId: null,
      searchNodeKey: "",
      root: structure,
      showError: false,
      isLoading: true,
      singleInput: null,
      newWatchName: "",
      createWatclistModal: false,
      showModalResponse: "",
      showModalResponseError: "",
      showModalLoader: false,
      showModalMessage: false,
      colHighlight: false,
      columnHighlight: null,
      selectedColumn: "EPS",
      isBackArrowActive: false,
      isNextArrowActive: false,
      packageErrorMessage: null,
      page: 1,
      nodeMeta: "",
      currentSort: {
        name: "",
        order: 0,
      },
      start: 0,
      length: 20,
      newSortVal: "",
      dataValues: [20, 50, 100, "ALL"],
      perPage: 10,
      nodePath: ["fd", "fd_au", "fd_au_allords", "fd_au_allords_bookvalue"],
      checkAll: false,
      getStockListData: [],
      // watchListIDs: [],
      selectwatchIndex: null,
      watch_List_Api_Data: {},
      showWatchlistModal: false,
      selectedStock: [],
      selectedTreeValue: null,
      newConfig: [],
      image: "images/premium.png",
      fileType: "StockList",
      textColor: "gray",
      stocksListTableData: [],
      // currentFav:"",
      currentFav: {
        idMapList: [],
        desc: "",
        favColor: "",
        saved: false,
      },
      info: {
        name: "",
        desc: ``,
      },
      currentFavSaved: false,
      favouriteList: [],
      favColorMap: ["light_blue", "orange", "purple", "yellow"],
      lastUpdated: null,
      columnConfig: null,
      uid: 0,
      result: [],
      currentFolder: ""
    };
  },
  //owais
  methods: {
    handleSearch() {
      this.findSearchVal(this.searchNodeKey);
    },

    CreateFromSelectWatchlist() {
      console.log("add from select watchlist");
      //close select watchlist modal
      this.showWatchlistModal = false;

      //open create watchlist modal

      this.createWatclistModal = true;
    },

    wrapperSearch(searchVal, root, result, parentNodes = []) {
      for (const item of root) {
        let keyRef = [...parentNodes, item.key];
        if (item.title.toLowerCase() == searchVal.toLowerCase()) {
          result.push(keyRef);
        }

        if (item.children && item.children.length > 0) {
          this.wrapperSearch(searchVal, item.children, result, keyRef);
        }
      }
    },

    findSearchVal(val) {
      if (val) {
        let result = [];
        this.wrapperSearch(val, this.root, result);
        this.result = result;
        let baseNode;
        let dataNodes;
        console.log("result", this.result);

        for (const item of this.result) {
          baseNode = item[0];
          console.log("baseNode", baseNode);
        }

        this.navigateToTree(this.result[6], baseNode);
      } else {
        this.showInputError = true;
      }
      // let newNodes = this.root.filter(function(node1){
      //     if(node1.title == val){
      //       return node1.title == val
      //     }else{
      //       console.log("else block")
      //       return node1.children?.filter(function(node2){
      //         console.log("=>",node2.title,val,node2.title == val)
      //         if(node2.title == val){
      //           return node2.title == val
      //         }else{
      //         return  node2.children?.filter(function(node3){
      //             if(node3.title == val){
      //               return node3.title == val
      //             }
      //           })
      //         }
      //       })
      //     }
      // })

      // let newNodes = this.root.map((element) => {
      //         return {...element, children: element.children.filter((subElement) => subElement.title == val)}
      //       })

      // console.log("newNodes",newNodes)

      // if(newNodes.length){
      //   let result  = newNodes.map(x => x.key)
      //   console.log("results",result)
      // }
    },

    getTableValue(x, column) {
      // return x[column.key]
      // console.log("x[column.key] outside ", column.key === "_1_day_chg");

      // console.log("x[column.key] inside ", column.key === "_1_day_chg");
      try {
        switch (column.type) {
          case "number":
            if (x[column.key] >= 0 || x[column.key] <= 0) {
              return x[column.key].toLocaleString("en-US");
            }
            break;

          case "string":
            if (typeof (x[column.key]) == "number") {
              parseFloat(x[column.key]).toFixed(2);
            }
            return x[column.key];

          case "percent":
            if (x[column.key] !== undefined && x[column.key] !== null && !isNaN(parseFloat(x[column.key]))) {
              return `${parseFloat(x[column.key]).toFixed(2).toLocaleString("en-US")}%`;
            } else {
              return "0%"; // Return "0%" if the value is not a valid number or does not exist
            }

          case "boolean":
            return x[column.key];

          case "decimal":

            if (!isNaN(parseFloat(x[column.key]))) {
              return parseFloat(x[column.key]).toFixed(2);
            } else {
              return "0"; // Return "0" if the value is not a valid number
            }

          case "float":
            if (!isNaN(parseFloat(x[column.key]))) {
              return parseFloat(x[column.key]).toFixed(2);
            } else {
              return "0.00"; // Return "0.00" if the value is not a valid float
            }

          default:
            return x[column.key];
        }
      }
      catch (error) {
        console.log(" error ", x[column.key], " - ", column.type)
      }

    },

    async getStockListTableData(nodeData) {
      console.log("get stocklist beta", nodeData)
      let nodeParams = {}
      console.log("nodeParams", nodeParams)
      console.log("this.newSortVal", this.newSortVal)
      // console.log("nodeData", nodeData);
      this.isLoading = true;
      this.showError = false;
      this.packageErrorMessage = null
      this.nodeMeta = nodeData;
      const country = nodeData.key.split("_")[1];

      console.log("country", typeof country, country);
      console.log("dev ", nodeData.key);
      if (country === "us") {
        (nodeData.key.includes('overbought') || nodeData.key.includes('oversold')) ? this.columnConfig = us_oberbought :
          (nodeData.key.includes('turnover') ? this.columnConfig = us_turnover : this.columnConfig = us_ColumnConfig);
        // console.log(us_ColumnConfig)
      }
      if (country === "ca") {
        (nodeData.key.includes('overbought') || nodeData.key.includes('oversold') ||
          nodeData.key.includes('over_bought') || nodeData.key.includes('over_sold')) ? this.columnConfig = ca_oberbought :
          (nodeData.key.includes('turnover') ? this.columnConfig = ca_turnover : this.columnConfig = ca_ColumnConfig);
      }
      if (country === "au") {
        (nodeData.key.includes('overbought') || nodeData.key.includes('oversold')) ? this.columnConfig = au_oberbought :
          (nodeData.key.includes('turnover') ? this.columnConfig = au_turnover : this.columnConfig = au_ColumnConfig);
      }

      let criteriaByKey = [];
      nodeParams = {
        key: nodeData.key,
        country: country,
        sorted: this.newSortVal,
        pagination: {
          length: this.length,
        },
      };
      console.log("node params", nodeParams.sorted);

      let response = await FivesApi.fetchStockListTableData(nodeParams);
      if (response) {
        this.newSortVal = {}
      }
      console.log("response", response);

      if (response.payload.length > 0) {
        this.stocksListTableData = response.payload;
        console.log("this.stocksListTableData", this.stocksListTableData);
        this.columnConfig.map((columnConfigObject) => {
          // console.log("columnConfigObject",columnConfigObject)
          if (
            this.stocksListTableData[0][columnConfigObject.key] !== undefined
          ) {
            console.log("columnConfigObject.key", columnConfigObject.key);
            // console.log("columnConfigObject",columnConfigObject)
            criteriaByKey.push(columnConfigObject);
          }
        });
        console.log("criteriaByKey", criteriaByKey);

        this.tableConfig = criteriaByKey;
        console.log("stocklist table data", this.stocksListTableData);
        console.log("table config", this.tableConfig);
      } else {
        this.showError = true;
        this.stocksListTableData = [];
      }
      this.isLoading = false;

    },

    async getStockList(nodeMeta, length = 10, sorted = null) {
      console.log("node meta", nodeMeta);
      this.nodeMeta = nodeMeta;
      this.showError = false;
      this.isLoading = true;
      // let response = await FivesApi.fetchStockListByCategory(nodeMeta, length, this.start, sorted);
      console.log("response ", response);
      let res = response.data;

      let _data = [];
      res.data.map((x, _i) => {
        response.columns.map((column, _i) => {
          if (column.width) response.columns[_i].width = 10;

          if (column.type) response.columns[_i].type = "string";

          switch (column.type) {
            case "int":
              if (parseInt(x[column.name]) < 0)
                x[column.name] = `<span style="color:red">${x[
                  column.name
                ].toLocaleString()}</span>`;
              else x[column.name] = x[column.name].toLocaleString();
              break;
            case "float":
              if (
                parseFloat(x[column.name]) < 0 &&
                parseFloat(x[column.name]) !== null
              ) {
                console.log(
                  "parseFloat(x[column.name]",
                  column.name,
                  x[column.name],
                  parseFloat(x[column.name])
                );

                x[column.name] = `<span style="color:red">${x[column.name]
                  .toFixed(2)
                  .toLocaleString()}</span>`;
              } else {
                x[column.name] =
                  x[column.name] === null
                    ? "Not Available"
                    : x[column.name].toLocaleString();
              }

              break;
            case "percent":
              if (
                parseFloat(x[column.name]) < 0 &&
                parseFloat(x[column.name]) !== null
              ) {
                x[column.name] = `<span style="color:red">${x[column.name]
                  .toFixed(2)
                  .toLocaleString()}</span>`;
              } else {
                x[column.name] = x[column.name].toLocaleString();
              }
              break;
            default:
              x[column.name] = x[column.name] + "";
          }
        });
        _data.push({ ...x });
      });
      this.columnConfig = response.columns;
      this.stocksListTableData = _data;
      if (this.stocksListTableData.length > 0) {
        this.showError = false;
        this.isLoading = false;
      } else {
        this.showError = true;
      }
      this.isLoading = false;
      // this.showError = true
      this.lastUpdated = this.formatDate(response.lastUpdated);
      // this.getStockListData = [];
      // this.watchListIDs = []
    },

    async SaveCreateWatchlist() {
      let date = moment().format("MMMM Do YYYY, h:mm a");

      try {
        this.showModalLoader = true;
        const payload = {
          name: this.newWatchName,
          symbolList: this.getStockListData,
          date: date,
        };
        console.log("create watchlist", payload);

        const res = await watchListApi.createWatchList(payload);
        // console.log("res",res)

        if (res.data.success) {
          this.selectwatchIndex = null
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponse = "A new Watchlist has been created for your selected stocks.";
          setTimeout(() => {
            this.createWatclistModal = false;
            this.showModalMessage = false;
            this.showModalResponse = ""
            this.newWatchName = ""
            // this.getStockListData = []
          }, 3000);
          const res1 = await watchListApi.fetchWatchListData();
          this.watch_List_Api_Data = res1.data.payload;
          console.log("this.watch_List_Api_Data", this.watch_List_Api_Data);

          // this.createWatclistModal = false;
          // this.showModalResponse =res.data.message;
        }
        else {
          console.log('inelse', res.data.message)
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponseError = res.data.message;
          setTimeout(() => {
            this.showModalMessage = false;
            this.showModalResponseError = "";
          }, 3000);

          return
        }
        // const payload2 = {
        //   symbolList: this.getStockListData,
        //   watchId:
        //     this.watch_List_Api_Data[this.watch_List_Api_Data.length - 1]._id,
        // };
        // const res2 = await watchListApi.updateWatchListRecord(payload2);
        // if (res2.data.success) {
        //   this.selectwatchIndex=null
        //   this.showModalMessage = true;
        //   this.showModalResponse = "A new Watchlist has been created for your selected stocks.";
        //   setTimeout(() => {
        //     this.createWatclistModal = false;
        //     this.showModalMessage = false;
        //   }, 3000);
        //   // this.createWatclistModal = false;
        // }
        // else{
        //   this.showModalMessage = true;
        //   this.showModalResponseError = res2.data.message;
        //   setTimeout(() => {
        //     this.showModalMessage = false;
        //     this.showModalResponseError = "";
        //   }, 3000);
        // }

      } catch (err) {
        console.log('in catch')
        console.log("error====>", err);
        // this.createWatclistModal = false;
        this.showModalMessage = true;
        this.showModalLoader = false;
        this.showModalResponseError = "Server error";
        setTimeout(() => {
          this.createWatclistModal = false;
          this.showModalMessage = false;
          this.showModalResponseError = "";
        }, 3000);
      }
    },

    navigateToTreeFromList(data, i) {

      this.favId = i;
      // this.currentFav.desc = data.desc;
      // console.log("current fav", this.favId,data.desc);

      this.navigateToTree(data.idMapList, data.idMapList[0]);
    },
    navigateToTree(nodePath, baseNode) {
      // console.log('navigateToTree',nodePath,baseNode)
      // debugger;
      let refNode = this;
      let nodeid = nodePath[0];
      let startsWith = nodeid.startsWith(baseNode);
      const clickRef = (refNode, lastNode) => {
        // console.log('find node params',lastNode,refNode[0].nodeRef,refNode[0].expanded , refNode[0].isFolderType)
        if (refNode && refNode[0] && refNode[0].nodeRef) {
          if (refNode[0].expanded && refNode[0].isFolderType) {
            return refNode[0];
          }
          if (refNode[0].isActiveColor && refNode[0].isFileType) {
            return refNode[0];
          }
          refNode[0].nodeRef.click();
          // lastNode ? refNode[0].nodeRef.click() : refNode[0].nodeClicked()
          return refNode[0];
        }
      };
      let len = nodePath.length - 1;
      for (const id in nodePath) {
        nodeid = nodePath[id];
        if (startsWith && refNode) {
          refNode = refNode.$refs[nodeid];
          refNode = clickRef(refNode, len === Number(id));
        }
      }
    },
    treeLoad(baseNode) {
      console.log("tree load", baseNode);
      let x = localStorage.getItem("stocklistNodePath");
      let nodePath = JSON.parse(x);
      if (nodePath.length === 4) {
        this.nodePath = nodePath;
      }
      setTimeout(() => {
        this.navigateToTree(this.nodePath, baseNode);
      }, 10);
    },

    treeEvent(node, map, id, idMap, expanded) {
      this.getStockListData = [];

      console.log(
        "tree event",
        node,
        "->",
        map,
        "->",
        id,
        "->",
        idMap,
        expanded
      );


      // this.selectedColumn = node?.title

      localStorage.setItem("stocklistNodePath", JSON.stringify(idMap))

      if (this.singleInput == false) {
        this.singleInput = null;
      } else {
        this.singleInput = false;
      }
      this.checkAll = false;

      this.selectedStock = map;
      this.selectedTreeValue = node?.highlighter

      Tree_event.$emit("disableTree", idMap);

      if (node.title) {
        this.info = {
          // desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni commodi, distinctio unde quia rerum reiciendis id dolorem asperiores cumque deleniti culpa perspiciatis repellendus dolor non quos error sequi. Molestiae velit ea quae?',
          desc: node.desc,
          name: node.title,
        };
      }


      if (node.key && !node?.children) {
        console.log("children==>")
        let favDesc = map.join(">");
        let hasFav = this.favouriteList.find((d) => d.desc === favDesc);
        this.currentFavSaved = !!hasFav;
        this.currentFav = {
          idMapList: idMap,
          desc: favDesc,
          favColor: "",
        };
        console.log("cureent fav", this.currentFav);

        this.currentSort = {
          name: "",
          order: 0,
        };
        this.columnHighlight = null
        this.getStockListTableData(node);
      } else if (node.key && node?.children && idMap?.length == 3 && expanded) {

        let firstChild = node?.children[0]
        let newIdMap = [...idMap, firstChild?.key]
        let newMap = [...map, firstChild?.title]
        this.selectedStock = newMap;
        if (firstChild?.title) {
          this.info = {
            // desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni commodi, distinctio unde quia rerum reiciendis id dolorem asperiores cumque deleniti culpa perspiciatis repellendus dolor non quos error sequi. Molestiae velit ea quae?',
            desc: firstChild?.desc,
            name: firstChild?.title,
          };
        }

        let favDesc = newMap?.join(">");
        let hasFav = this.favouriteList?.find((d) => d.desc === favDesc);
        this.currentFavSaved = !!hasFav;
        this.currentFav = {
          idMapList: newIdMap,
          desc: favDesc,
          favColor: "",
        };
        this.currentSort = {
          name: "",
          order: 0,
        };
        this.columnHighlight = null
        localStorage.setItem("stocklistNodePath", JSON.stringify(newIdMap));

        console.log("firstChild==>", firstChild.permission.includes(this.signedInUser.role), this.signedInUser.role)

        if (firstChild.permission.includes(this.signedInUser.role)) {
          this.getStockListTableData(firstChild);
        } else {
          this.isLoading = false
          this.stocksListTableData = [];
          this.showError = true;
          this.packageErrorMessage = "This feature is only for premium users."
        }
      }

    },

    getTextColour(data) {
      console.log("textcolor", data);
      console.log("currentFav", this.currentFav);

      if (data.desc == this.currentFav.desc) {
        console.log("green");
        return "text-[green]";
      }
    },

    async AddToFav() {
      let map = this.favColorMap;
      let favLen = this.favouriteList.length;
      this.currentFavSaved = true;
      this.currentFav.favColor = map[favLen];
      let favouriteListApi = this.favouriteList;
      favouriteListApi.push(this.currentFav);
      if (await FivesApi.saveFavScans(favouriteListApi, "stock")) {
        this.favouriteList = favouriteListApi;
      }

      console.log("idMapList", this.currentFav);
    },
    async RemoveFromFav() {
      console.log("remove", this.currentFav);
      console.log("fav list", this.favouriteList);
      let filterData = this.favouriteList.filter(
        (d) => d.desc !== this.currentFav.desc
      );

      console.log("filtered data", filterData)
      if (filterData > 0) {
        let map = this.favColorMap;
        let favouriteListApi = filterData.map((v, i) => {
          v.favColor = map[i];
        });
        if (await FivesApi.saveFavScans(favouriteListApi, "stock")) {
          this.favouriteList = favouriteListApi;
        }
      } else {
        if (await FivesApi.saveFavScans(filterData, "stock")) {
          this.favouriteList = filterData;
        }
      }
      this.currentFavSaved = false;
      console.log("idMapList", this.currentFav);
    },
    formatDate(d) {
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY");
    },
    handleChange(value) {
      this.length = value;
      if (this.nodeMeta) this.getStockListTableData(this.nodeMeta);

      // this.getStockList(this.nodeMeta, value)
    },
    updateSort(value, sort, index) {

      this.columnHighlight = index;
      this.selectedTreeValue = null
      // if(this.columnHighlight.includes(index)){
      //   this.columnHighlight[index] = null
      //   console.log("match hua")
      // }
      // else{
      //   this.columnHighlight[index] = index
      // }

      if (this.nodeMeta) {

        let order = sort === 0 || sort === -1 ? 1 : sort === 1 ? -1 : 1;
        this.currentSort = {
          name: value,
          order: order,
        };
        if (value == 'volume') {
          value = 'volume_sort'
        } else if (value == '_turnover') {
          value = '_turnover_value'
        } else if (value == '_5day_volume') {
          value = '_5day_volume_sort'
        } else if (value == 'volume_2') {
          value = 'volume_2_numeric'
        }
        let newSort = {};
        newSort[value] = order;


        this.newSortVal = newSort;
        console.log("sort", this.newSortVal);
        // this.getStockList(this.nodeMeta, newSort)
        this.getStockListTableData(this.nodeMeta);
      }
    },
    handleBackArrow() {
      if (this.perPage > 10) {
        this.page -= 1;
        this.perPage -= 10;
        console.log(this.perPage);
      }
    },
    handleForwardArrow() {
      if (this.perPage >= 0) {
        this.page += 1;
        this.perPage += 10;
        console.log(this.perPage);
      }
    },
    selectWatcRowIndex(e) {
      console.log(e.target.value);
      this.selectwatchIndex = e.target.value;
    },
    async SaveWatchlist() {
      this.showModalLoader = true;
      let date = moment().format("MMMM Do YYYY, h:mm a");

      try {
        const payload = {
          watchId: this.watch_List_Api_Data[this.selectwatchIndex]._id,
          symbolList: this.getStockListData,
          updatedDate: date,
        };
        console.log(payload);

        const res = await watchListApi.updateWatchListRecord(payload);
        if (res.data.success) {
          // this.singleInput=null
          // this.checkAll=false
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponse = "Stock has been saved to your watchlist";
          setTimeout(() => {
            this.showWatchlistModal = false;
            this.showModalMessage = false;
            this.showModalResponse = "";
          }, 3000);
          // this.getStockListData = [];
        } else {
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponseError = res?.data?.message;
          setTimeout(() => {
            this.showModalMessage = false;
            this.showModalResponseError = "";
          }, 3000);
        }
      } catch (err) {
        this.showModalLoader = false;
        this.showModalMessage = true;
        this.showModalResponseError = "Server Error";
        setTimeout(() => {
          this.showWatchlistModal = false;
          this.showModalResponseError = "";
        }, 3000);
        console.log("market heat modal update-symbol-api destroyed");
      }

      // this.getStockListData = [];
      // this.watchListIDs = []
    },

    async SaveWatchlistModal(type) {
      try {
        const res = await watchListApi.fetchWatchListData();
        this.watch_List_Api_Data = res.data.payload;
        console.log("...api in stoclist ", this.watch_List_Api_Data);
      } catch (err) {
        console.log("something went wrong", err);
      }
      if (type == "add") {
        this.showWatchlistModal = true;
      } else if (type == "create") {
        this.createWatclistModal = true;
      }
    },

    //     CheckRowTrue(checked, i,row) {

    // if (checked) {
    //   this.getStockListData.push({ id: this.nodeMeta.exchange, name: this.PremiumFivesData[i].symbol });
    //   this.watchListIDs.push(this.PremiumFivesData[i].symbol);
    // } else if (!checked) {
    //   for (let v in this.getStockListData) {
    //     if (this.getStockListData[v].name === this.PremiumFivesData[i].symbol) {
    //       this.getStockListData.splice(v, 1)
    //       this.watchListIDs.splice(v, 1)
    //       break
    //     }
    //   }
    // }
    // },

    CheckRowTrue(checked, i, row) {
      console.log(".........", row);
      if (checked) {
        //exchange is undefined
        this.getStockListData.push({
          companyName: row.company_name,
          name: this.stocksListTableData[i].symbol,
        });
        console.log(".... owais ", this.getStockListData);
        // this.watchListIDs.push(this.stocksListTableData[i].symbol);
      } else if (!checked) {
        for (let v in this.getStockListData) {
          if (
            this.getStockListData[v].name === this.stocksListTableData[i].symbol
          ) {
            this.getStockListData.splice(v, 1);
            // this.watchListIDs.splice(v, 1)
            break;
          }
        }
      }
    },

    async getFolders() {
      // this.treeEvent()
      // console.log("my structure",this.root)
      // let result = await FivesApi.fetchStockListFolders()
      // console.log("get folders ",result);
      // this.newConfig = result.data

      //for structure array
      this.newConfig = this.root;

      console.log("new config", this.newConfig);

      // this.obj.folder = result.data
    },
    payload() { },
    onChange(result) { },
  },
  computed: {
    ...mapGetters(["get_Selected_Stoks", "userIsNotPremium", "signedInUser"]),
    favScanLength() {
      return this.currentFav.idMapList.length;
    },
  },
  watch: {
    checkAll(newVal) {
      if (newVal) {
        this.getStockListData = [];
        // this.watchListIDs = []
        for (let i of this.stocksListTableData) {
          this.getStockListData.push({
            name: i.symbol,
            companyName: i.company_name,
          });
          console.log("this.nodeMeta.exchange ", this.getStockListData);
        }
      } else {
        this.getStockListData = [];
        // this.watchListIDs = []
      }
    },
    length(val) {
      if (val && this.singleInput == false) {
        this.singleInput = null;
      } else {
        this.singleInput = false;
      }
      this.checkAll = false;
      // this.singleInput =  null
    },
    currentFav: (val) => {
      console.log("current saved:", val);
    },
    searchNodeKey() {
      this.showInputError = false;
    },
  },
  async mounted() {
    this.favouriteList = await FivesApi.getFavScans("stock");

    console.log("this.favouriteList", this.favouriteList);
    await this.getFolders();

    // fetch watchlist data

    try {
      const res = await watchListApi.fetchWatchListData();
      this.watch_List_Api_Data = res.data.payload;
    } catch (err) {
      console.log("something went wrong", err);
    }
  },
};
</script>

<style scoped>
.grayGradient:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.bgGradient:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
  /* width: 20px !important; */
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.container {
  display: block;
  position: relative;
  /* right: 2px; */
  cursor: pointer;
  width: 0.1em;
  height: 0.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.container input:checked~.checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
}

/* @media screen and (max-width: 900px) {
  .btnsize {
    margin-left: 10px;
  }
} */
th {
  padding-right: 10px !important;
  padding-bottom: 10px;
}

td {
  padding-right: 10px !important;
}

.maindiv {}

/* [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    background-color: #00FF00;
    color: #111;
    font-size: 150%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
} */
[data-title]:hover {
  color: red;
}

.hideScroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 8px;
}
</style>
