import userApi from "../api/userApi";
import router from "@/router";
import {GET_COSTOMER_DETAILS} from "../api/apiRoutes";
import axios from "axios";
// import tableModule from "./tableStore";

const tableModule = {
  state: {
    userDetails: {},
    isAuthenticated: false,
    bannerValues: [],
  },

  mutations: {
    SET_USER_DETAILS(store, payload) {
      // console.log("SET_USER_DETAILS", payload);
      store.userDetails = payload;
    },
    SET_AUTHENTICATION(state, payload) {
      state.isAuthenticated = payload; //token varification: Hassaan
    },
    ADD_BANNER_VALUE(state, payload) {
      if (Array.isArray(payload)) {
        state.bannerValues = payload;
      } else {
        state.bannerValues = [...state.bannerValues, payload];
      }
    },
    REMOVE_BANNER_VALUE(state, payload) {
      let updatedArr = state.bannerValues?.filter(
        (item) => item.symbol != payload.symbol
      );
      state.bannerValues = updatedArr;
    },
  },

  actions: {
    async getUserData({ commit }, payload) {
      let data = null;
      try {
        console.log("calling dev getUserData");
        // console.log(payload);
        const res = await userApi.getUser();
        const stripe_response = await axios.get(GET_COSTOMER_DETAILS)
    // this.signedInUser.cardInfo[0] = response.data.payload.card[0]
        console.log("hardik dev res", stripe_response.data.payload.card,res.data.payload.user.cardInfo);

        if (res.data.success) {
          let userDetails = res.data.payload.user;
          if(stripe_response.data.payload.card.length>0){
            
            userDetails.cardInfo = stripe_response.data.payload.card
          } else{
            userDetails.cardInfo = []
          }
          commit("SET_USER_DETAILS", userDetails);
          let chartConfig = userDetails?.chartConfig;
          if(chartConfig?.overlays){
            commit("SET_ALL_INDICATORS",chartConfig.overlays)
            
          }
          if(chartConfig?.timeFrame){
            commit("SET_TIME_FRAME",chartConfig.timeFrame)            
          }
          if(chartConfig?.selectedSymbol){
            commit("SET_SELECTED_SYMBOL",chartConfig.selectedSymbol)
          }
          // console.log(
          //   "response",
          //   res.data.payload?.user?.dataStreamCountry[0]?.country
          // );

          if (res.data?.payload?.user?.role === "plus") {
            console.log("im plus user");
            commit(
              "SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE",
              res.data?.payload?.user?.dataStreamCountry[0]?.country
            );
          }
          // else{
          //   commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", 'oz');

          // }
          data = userDetails;
        }
        else{
          var email = localStorage.getItem('email');
          localStorage.clear();
          localStorage.setItem('email', email);
          alert("user plan is expired.")
          router.push("/plans");
        }
        return data;
      } catch (error) {
        console.log("error in getUserData", error.message);
        return data;
      }
    },
    // async userSignUpAction({ commit }, payload) {
    //   try {
    //     console.log(payload);
    //     const data = await userApi.userSignUp(payload);
    //     console.log(commit);
    //     return data;
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    // async userResetPasswordAction({ commit },payload) {
    //   try {
    //     const data = await userApi.userResetPassword(payload);
    //     console.log(commit)
    //     return data;
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    // async userForgotPasswordAction({ commit },payload) {
    //   try {
    //     const data = await userApi.userForgotPassword(payload);
    //     console.log(commit)
    //     return data;
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
  },

  getters: {
    signedInUser: (state) => state.userDetails,
    userIsNotPremium: (state) => state.userDetails.role !== "premium",
    userIsPlus: (state) => state.userDetails.role === "plus",
    userIsPremium: (state) => state.userDetails.role === "premium",
    getBannerValues: (state) => state.bannerValues,
  },
};

export default tableModule;
