<template>
  <div
    class="text-gray-200 w-full h-full"
    style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 5px"
  >
    <div
      style="border-bottom: 0.1px solid gray"
      class="text-gray-200 not-italic font-semibold text-lg"
    >
      <!-- dropdown -->
      <div class="flex justify-between items-center px-2 w-full h-[4.5vh] ">
        <span :class="pageCalender=='Market Alert'?'': 'text-lg'" class="text-[1vw]">{{ pageCalender }} Calendar</span> 

        <div class="dropdown relative">
          <button
            class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
          <div class="space-x-2">
          <span class="text-[10px]">Saved Dates</span>
          <font-awesome-icon
            class="text-white bg-gray-800"
            style="color: white; height: 1.6vh; cursor: pointer"
            :icon="['fas', 'ellipsis-vertical']"
          />
        </div>
          </button>

          <ul
            class="dd-menu dropdown-menu min-w-max bg-[#2E3238]  text-gray-200 absolute hidden z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1  m-0 bg-clip-padding border-none"
            aria-labelledby="dropdownMenuButton1"
          >
            <!-- class="dropdown-item text-sm py-3 px-6 font-normal cursor-pointer block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700" -->
            <li :class="handleDisableClass()" @click="handleOpenSaveDateModel" >
              Save selected date(s)
            </li>
            <li
              class="abc dropdown-item bg-[#2E3238] text-sm py-3 px-6 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700"
              @click="handleOpenLoadDateModel"
            >
              Load saved date(s)
            </li>
          </ul>
        </div>
      </div>

      <tailwind-modal
        :show-modal="saveDateModelState"
        v-on:change="toggleModal"
        :showHeader="false"
      >
        <SaveDates v-on:change="toggleModal" />
      </tailwind-modal>

      <tailwind-modal
        :show-modal="loadDateModelState"
        v-on:change="toggleModal"
        :showHeader="false"
      >
        <LoadDates v-on:change="toggleModal" />
      </tailwind-modal>
    </div>

    <Year-calendar
      v-model="year"
      @toggleDate="toggleDate"
      :lang="lang"
      prefixClass="your_customized_wrapper_class"
      :activeClass="activeClass"
      :showYearSelector="showYearSelector"
      :hideWeekend="hideWeekend"
      :hideSunday="hideSunday"
      @monthClick="monthClicked"
      :enable-range-selection="true"
    ></Year-calendar>
  </div>
</template>

<script>
import SaveDates from "../popups/SaveDates.vue";
import LoadDates from "../popups/LoadDates.vue";
import YearCalendar from "../calendar/components/YearCalendar.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import datesApi from "../../api/datesApi";
import TailwindModal from "../popups/TailwindModal.vue";

export default {
  name: "CalendarComponent",
  //props: ["dates"],
  props: ["propsDatesDropDown","pageCalender"],
  components: {
    YearCalendar,
    SaveDates,
    LoadDates,
    TailwindModal,
  },
  data() {
    return {
      lang: "en", // 'en', 'tw', 'pt', 'ru'
      year: this.$route.path == "/marketalert" ? this.selectedActiveYearStoreMA : this.selectedActiveDateStore,
      activeDates: [],
      activeClass: "redss",
      showYearSelector: true,
      hideWeekend: false,
      hideSunday: false,
      datesDropDown: false,
      index: 0,
      savedYear: null,
      saveDateModelState: false,
      loadDateModelState: false,
      DropdownOpen: false,
    };
  },

  methods: {
    handleDisableClass() {
      if (this.selectedDateStore.length == 0) {
        return "text-sm py-3 px-6 font-normal  block w-full whitespace-nowrap bg-transparent text-gray-500  disable";
      } else {
        return "abc dropdown-item bg-[#2E3238] text-sm py-3 px-6 font-normal cursor-pointer block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700";
      }
    },
    handleOpenSaveDateModel() {
      if (this.selectedDateStore.length) {
        this.saveDateModelState = true;
      }
    },
    handleOpenLoadDateModel() {
      this.loadDateModelState = true;
    },

    updateYear(value){
      this.year = value
      console.log("saved year ====>",this.year)
    },

    toggleModal(e) {
      this.updateYear(e)
      this.saveDateModelState = false;
      this.loadDateModelState = false;
    },
    modalEnabledFunc() {
      this.saveDateModelState = false;
      this.loadDateModelState = false;
    },
    openModalSave() {
      console.log("open");
      this.saveDateModelState = true;
    },
    async openModalLoad() {
      try {
        const payload = {
          email: localStorage.getItem("email"),
        };
        const res = await datesApi.loadDates(payload);
        if (res.data.data) {
          this.dateArray = res.data.data;
          this.$store.commit("SAVE_LOADED_DATES", res.data.data);
          this.loadDateModelState = true;
        }
      } catch (err) {
        alert(err);
      }
    },
    tableRowClickTrigger() {
      this.$store.commit("TABLE_ROW_CLICKED", true)
    },
    toggleDate(dateInfo) {
      this.activeDates = [
        ...this.activeDates,
        { date: "2022/01/16", className: "yellows" },
      ];
      console.log("this is from CAL", dateInfo);
      let selectedDateArray = [];
      selectedDateArray.push(dateInfo.date);
      console.log("click date==>",selectedDateArray)
      this.tableRowClickTrigger()
      this.$store.commit("CLICKED_CALENDER_DATE", selectedDateArray);
    },

    add_sat_and_sun_of_year() {
      let theDate = dayjs(`${this.year}-01-01`);

      while (theDate.diff(theDate.endOf("year"), "day") !== 0) {
        if (theDate.day() === 6 || theDate.day() === 0) {
          this.activeDates.push(theDate.format("YYYY-MM-DD"));
        }
        theDate = theDate.add(1, "day");
      }
    },
    addWeekendCurrentYear() {
      this.removeWeekendCurrentYear();
      let theDate = dayjs(`${this.year}-01-01`);
      let isActiveDateUsingString =
        this.activeDates.length && typeof this.activeDates[0] === "string";
      while (theDate.diff(theDate.endOf("year"), "day") !== 0) {
        if (theDate.day() === 6 || theDate.day() === 0) {
          // add weekend to activeDates
          let oDate = isActiveDateUsingString
            ? theDate.format("YYYY-MM-DD")
            : { date: theDate.format("YYYY-MM-DD")};
          this.activeDates.push(oDate);
        }
        theDate = theDate.add(1, "day");
      }
    },
    removeWeekendCurrentYear() {
      this.activeDates = this.activeDates.filter((oDate) => {
        let date = typeof oDate === "string" ? oDate : oDate.date;
        let day = dayjs(date).day();
        let isCurrentYear = dayjs(date).year() === this.year;
        let isWeekend = [6, 0].includes(day);
        return !(isCurrentYear && isWeekend);
      });
    },
    monthClicked(monthYearInfo) {
      console.log(monthYearInfo);
    },
    modalOpen() {
      console.log("modal open");
      this.datesDropDown != this.datesDropDown;
      console.log("hello from modal");
      console.log(this.datesDropDown);
    },
    open() {
      console.log("modal open");
      console.log("hello");
      this.datesDropDown = !this.datesDropDown;
      console.log(this.datesDropDown);
    },
  },
  computed: { ...mapGetters(["selectedActiveDateStore", "selectedDateStore", "selectedActiveYearStore"]) },

  updated() {
    // console.log("this fucntion is working ");
    this.activeDates = this.selectedActiveDateStore;
  },
  mounted() {
    if(this.$route.path == "/marketalert"){
      this.year = this.selectedActiveYearStoreMA;
    }else{
      this.year = this.selectedActiveYearStore;
    }
    
      //this.$store.commit("CHANGE_YEAR_MA", new Date().getFullYear());
  },
  created() {
    if(this.$route.path == "/marketalert"){
      this.year = this.selectedActiveYearStoreMA;
    }else{
      this.year = this.selectedActiveYearStore;
    }
      //this.$store.commit("CHANGE_YEAR_MA", new Date().getFullYear());
  },
 

};
</script>

<style scoped>
.dd-menu {
  background: #151515;
  box-shadow: 3px 3px 21px rgba(0, 0, 0, 0.45);
  border-radius: 0px 0px 3px 3px;
}
.modal-container {
  justify-content: center;
  align-items: center;
  justify-content: center;
  border: 5px solid red;
}
.modal-content {
  /* position: relative; */
  width: 50%;
  height: 600px;
  padding: 16px;
  overflow: auto;
  margin: auto;
  background-color: #151515;
  border-radius: 4px;
}

.abc:focus,.abc:hover {
  color: rgb(229 231 235);
}
.modal-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: auto;
  cursor: pointer;
  color: aliceblue;
}
.modal-close:hover {
  color: gray;
}
.btn {
  background: #151515;
}
</style>
<!-- <style> -->
<style lang="stylus">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  background-color: #ffffff; /* 151515; */
}

.forpopup {
  color: white;
  background-color: #151515;
}

.forpopupmenu {
  font-size: 12px;
  justify-content: center;
  height: 45px;
  text-align: center;
  padding: 15px;
}

.forpopupmenu:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  opacity: 0.7;
  color: #f7f7f3;
  filter: brightness(190%);
  width: auto;
  z-index: 1;
}

button:focus {
  outline: none;
}

.forpopupmenu:focus {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  opacity: 2;
  color: #f7f7f3;
  font-weight: bold;
  filter: brightness(100%);
}

.your_customized_wrapper_class {
  
  

  &.redss {
    background-color: #a00;
    color: white !important;
  }

  &.redsstart {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #a00;
    color: white !important;
  }

  &.redend {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #a00;
    color: white !important;
  }

  &.greens {
    background-color: green !important;
    color: white !important;
  }

  &.darkgreens {
    background-color: #005500;
    color: white !important;
  }

  &.greenstart {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: green !important;
    color: white !important;
  }

  &.start {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.end {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.greenend {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: green !important;
    color: white !important;
  }

  &.yellows {
    background-color: yellow !important;
    color: black !important;
    font-weight:bold

  }

  &.yellowstart {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: yellow !important;
    color: black !important;
    font-weight:bold

  }

  &.yellowend {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: yellow !important;
    color: black !important;
    font-weight:bold
  }

  &.yellowss {
    background-color: yellow;
    color: white !important;
    background-color: #FFFF00;
  }

  &.red {
    background-color: #a00;
    color: white;

    &:after {
      background-image: url('./assets/baseline-remove_circle-24px.svg');
      background-size: 100% 100%;
    }
  }

  &.blue {
    background-color: #0000aa;
    color: white;
  }

  &.your_customized_classname {
    background-color: yellow;
    color: black;
  }
}
</style>
