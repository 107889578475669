<template>
    <div class="main">
        <!-- Main section -->
        <div class="w-full">
            <div class="w-full flex items-center md:justify-start sm:justify-center h-[90vh]">
                <div
                    class="lg:w-[40%] md:w-[50%] sm:px-0 px-6 sm:w-[70%] -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600 w-full flex items-center -mt-6">
                    <div class="bg-black-200 w-full -bg-green-300 flex justify-center"
                        :class="loaderState ? 'pointer-events-none' : ''">

                        <div class="xl:w-7/12 md:w-7/12 sm:w-7/12 w-full -bg-red-300">
                            <div>
                                <img class="w-full" :src="require('../../assets/mapWhite.svg')" />
                            </div>

                            <div class="mt-2" v-if="alertStatus">
                                <AlertShort class="w-full" :alertType="alertType">
                                    <p class="text-sm text-gray-300">{{ messages }}</p>
                                </AlertShort>
                            </div>

                            <!-- Subscription form -->
                            <form @submit.prevent="subscribe">
                                <div class="mt-1">
                                    <h1 class="text-white font-bold" style="font-size: large;">
                                        Please select a subscription
                                    </h1>
                                </div>

                                <!-- Radio Button Selection for Plus and Premium -->
                                <div class="radio-buttons">
                                    <input type="radio" id="plus" name="plan" value="Plus" v-model="selectedPlan"
                                        class="radio-input" />
                                    <label for="plus" class="radio-label">Plus</label>

                                    <input type="radio" id="premium" name="plan" value="Premium" v-model="selectedPlan"
                                        class="radio-input" />
                                    <label for="premium" class="radio-label">Premium</label>
                                </div>

                                <!-- Card Container Section -->
                                <div class="card-container">
                                    <!-- Monthly Subscription Card -->
                                    <div class="card" @click="cardClicked('M')"
                                        :class="selectedCardClass === 'monthly' ? 'card-selected' : ''">
                                        <h3 class="font-bold">Monthly</h3>
                                        <p>{{ monthlyPrice }}</p>
                                    </div>

                                    <!-- Yearly Subscription Card -->
                                    <div class="card" @click="cardClicked('Y')"
                                        :class="selectedCardClass === 'yearly' ? 'card-selected' : ''">
                                        <h3 class="font-bold">Yearly</h3>
                                        <p>{{ yearlyPrice }}</p>
                                    </div>
                                </div>

                                <!-- Subscribe button -->
                                <div class="w-full flex items-center justify-center mt-2">
                                    <VueLoadingButton aria-label="Subscribe"
                                        class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                                        @click.native="subscribe" :loading="loaderState" type="button">
                                        <span class="text-[15px] font-bold">Subscribe</span>
                                    </VueLoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer section -->
        <div class="w-full -mt-6 pb-6 flex flex-col items-center justify-center">
            <PageFooter />
            <div class="w-full px-2 mt-2 flex items-center justify-end">
                <span class="text-gray-400 text-[10px]">Version 0.1.0.4.15.1</span>
            </div>
        </div>
    </div>
</template>

<script>
import VueLoadingButton from "vue-loading-button";
import PageFooter from "@/components/Footer/Footer.vue";
import AlertShort from "../utilities/AlertShort.vue";
import priceIds from "../../utils/products";
import axios from "axios";
import {
    subscribe_To_Package,
} from "@/api/apiRoutes";

export default {
    data() {
        return {
            selectedCardClass: '',
            selectedPlan: 'Plus', // Default selection
            loaderState: false,
            messages: "",
            alertStatus: false,
            alertType: "",
        };
    },
    components: {
        AlertShort,
        VueLoadingButton,
        PageFooter,
    },
    computed: {
        monthlyPrice() {
            return this.selectedPlan === 'Premium'
                ? 'USD 79.95 per month'
                : 'USD 49.95 per month';
        },
        yearlyPrice() {
            return this.selectedPlan === 'Premium'
                ? 'USD 719.50 per year'
                : 'USD 479.50 per year';
        },
    },
    methods: {
        cardClicked(cardType) {
            this.selectedCardClass = cardType === 'M' ? 'monthly' : 'yearly';
        },
        showErrors(message, alertType, alertStatus) {
            this.messages = message;
            this.alertStatus = alertStatus;
            this.alertType = alertType;
        },

        async subscribe() {
            // Example subscription logic
            let validationErrors = [];
            let isNotValid = false;
            let isFormEmpty = false;
            this.alertStatus = false;

            if (this.selectedCardClass == "" || this.selectedPlan == "") {
                this.showErrors("Please select at least one subscription plan", "Danger", true);
                isNotValid = true;
            }

            if (isNotValid) {
                return;
            }
            let condition = this.selectedPlan + "_" + this.selectedCardClass;
            let payload = { "plan": condition, "user": localStorage.getItem("email") }
            console.log("dev condition payload", condition, payload);
            try {
                const res = await axios.post(subscribe_To_Package, {
                    payload
                });
                window.location = res.data.payload.sessionUrl;
            }
            catch (error) {
                console.log(error.message)
            }
        },
    },
};
</script>



<style scoped>
.card {
    background-color: white;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card h3 {
    font-size: 18px;
    margin-bottom: 8px;
}

.card p {
    font-size: 14px;
    color: #666;
}

.card-container {
    display: flex;
    gap: 16px;
    /* Space between cards */
    justify-content: center;
    /* Center the cards */
    margin-top: 10px;
    /* Space between heading and cards */
}

/* Highlighted card when selected */
.card-selected {
    border: 2px solid #33ff00;
    background-color: rgb(228, 241, 225)
}

/* Style for the radio buttons and their labels */
.radio-buttons {
    margin-top: 10px;
    color: white;
    /* White text for visibility against a dark blue background */
}

.radio-label {
    margin-right: 20px;
    /* Adequate spacing between labels */
    cursor: pointer;
    transition: color 0.3s;
    /* Smooth transition for hover effect */
}

.radio-input {
    margin-right: 5px;
    /* Spacing between radio button and label */
}

/* Hover effect to change the label color */
.radio-label:hover {
    color: lightgray;
    /* Lighter shade on hover */
}

/* Ensure selected radio button has distinct style */
.radio-input:checked+.radio-label {
    color: #33ff00;
    /* Gold color for checked state */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        padding: 12px;
        /* Adjusted padding */
    }

    .card h3 {
        font-size: 16px;
        /* Smaller text size */
    }

    .card p {
        font-size: 12px;
        /* Adjusted text size */
    }
}

a {
    color: #02bd2d;
}

a:hover {
    text-decoration: underline !important;
    text-decoration-color: #02bd27 !important;
}

input:focus {
    border: 1px solid #02bd27 !important;
}

.main {
    /* background-image: url("../../assets/map-main-background.jpg"); */
    background-image: url("../../assets/map-main-background.jpg");

    background-color: black !important;
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: fixed;
    background-size: 100% 100%;
    height: auto;
    /* opacity: 0.3; */
}

.loginImage {
    width: 60vw;
    height: 100vh;
    position: fixed;

    right: 0px;
}

.divColor:focus {
    background-color: Aqua;
}

/* background and main setting page  */
.maintemplate {
    /* background-image: url("../../assets/map-main-background.jpg"); */
    background-image: url("../../assets/map-main-background.jpg");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    width: 100vw;
    height: 100%;
}

.test {
    color: aliceblue;
}

.left {
    margin-left: 8rem;
    margin-top: 8rem;
}

.logoLogin {
    width: 350px;
}

.welcome {
    color: #e5e9ec;
    font-size: 18px;
    font-weight: 400;
    margin-left: 9px;
    margin-top: 35px;
}

.signintoacc {
    color: #02bd2d;
    font-size: 24px;
    font-weight: 600;
    margin-left: 9px;
}

.textfields {
    border: 1px solid rgba(117, 130, 120, 0.6);
    border-radius: 3px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 9px;
    width: 350px;
    color: #758278;
    text-indent: 10px;
    height: 45px;
    display: flex;
    flex-direction: column;
}

.textfields::placeholder {
    color: #758278;
    font-size: 12px;
    margin-left: 5rem;
    /* padding: 10px; */
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #fff;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: #02bd2d;
}

.container {
    /* display: block; */
    position: relative;
    /* padding-left: 25px; */
    /* margin-bottom: 11px; */
    /* cursor: pointer; */
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #e5e9ec;
    /* margin-top: 10px; */
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: black;

    border-radius: 15px;
    border: 1px solid #758278;
    /* margin-left: 8.5px; */
    /* margin-top: 3px; */
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
      background-color: #ccc;
    } */

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #02bd2d;
}

/* Create the checkmark/indicator (hidden when not checked) */
/* .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    } */

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    /* top: 5px; */
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.remindMe {
    /* width: 20px; */
    display: flex;
}

.forgetPassword {
    font-size: 12px;
    color: #02bd2d;
    /* margin-top: 12px; */
    /* margin-left: 10rem; */
}

.forgetPassword a {
    text-decoration: none;
}

.signinbtn {
    background-color: #02bd2d;
    width: 351px;
    text-align: center;
    border-radius: 2px;
    height: 45px;
    color: #f8f9f9;
    margin-top: 17px;
    padding: 14px;
    cursor: pointer;

    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 9px;
}

.or {
    /* width: 100%;  */
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    opacity: 0.3px;
    line-height: 0.1em;
    margin: 15px 0 20px;
}

.or span {
    background: transparent;
    color: #fff;
    opacity: 0.3px;
    padding: 0 10px;
}

.or h5 {
    margin-top: -11px;
    z-index: 100;
    color: #e5e9ec;
    margin-left: 8rem;
    /* padding: 10px; */
    background-color: black;
    width: 27px;
    height: 20px;
    padding-inline: 6.5px;
    font-weight: 100;
}

.socialIcons {
    height: 45px;
    width: 350px;
    /* border: 1px solid white; */
    margin-top: -30px;
    margin-left: 65px;
}

/* .donthaveacc {
      margin-left: 25px;
      padding-inline: 10px;
      font-size: 17px;
      margin-top: 30px;
    } */
.donthaveacc p {
    color: #f8f9f9;
    font-weight: 100;
}

.donthaveacc span {
    color: #02bd2d;
    font-weight: 600;
}

/* .haveanyissue p {
      font-size: 12px;
      color: #e5e9ec;
      margin-left: 40px;  
    } */
/* .haveanyissue span {
      color: #758278;
    } */
a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.btnDisabled {
    pointer-events: none;
}

.spinner[data-v-3a698022] {
    right: 4.7rem !important;
    top: 53%;
}

.btnDefault {
    pointer-events: none;
}

.footerlink {
    color: #00c92e !important;
}

.footerlink:hover {
    color: #3ef705 !important;
    text-decoration: none !important;
}

/* media queries  */
@media only screen and (min-width: 1669px) {
    .maintemplate {
        /* background: #02bd2d; */
        background-image: url("../../assets/map-main-background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: black;

        width: 100%;
        height: 100vh;
    }
}

@media only screen and (max-width: 1115px) {
    .loginImage {
        width: 55vw;
        height: 100%;
        position: fixed;
        right: 0px;
        /* z-index: 50; */
    }
}

.password-class:focus {
    border: none !important;
}
</style>
