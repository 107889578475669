// const domain_prefix = "http://192.168.2.105:5124/api/v1/"
import Config from "@/api/config";

const domain_prefix = Config.mapApi
const domain_prefix_chart = Config.chartApi

export const CHART_SEARCH = domain_prefix_chart + "symbols/startWith";
// export const CHART_SEARCH = domain_prefix_chart + "symbols/startWithNew";
export const GET_SYMBOL = domain_prefix_chart + "symbols";

export const WATCH_LIST_DATA = domain_prefix + "api/v1/watch/watchlist";
export const SYMBOL_LIST_REMOVE = domain_prefix + "api/v1/watch/watchlist/removeSymbols";
export const SYMBOL_LIST_UPDATE = domain_prefix + "api/v1/watch/watchlist/updateSymbols";
export const CREATE_WATCH_LIST = domain_prefix + "api/v1/watch/watchlist/create";
export const REMOVE_WATCH_LIST = domain_prefix + "api/v1/watch/watchlist/delete";
export const GET_SINGLE_WATCH_LIST = domain_prefix + "api/v1/watch/watchlist";
export const COPY_SYMBOLS = domain_prefix + "api/v1/watch/copySymbol";
export const MOVE_SYMBOLS = domain_prefix + "api/v1/watch/moveSymbol";
export const SAVE_TABLE_COLUMNS = domain_prefix + "api/v1/watch/save-column";
export const GET_TABLE_COLUMNS_LIST = domain_prefix + "api/v1/watch/get-column";
export const DELETE_TABLE_COLUMN = domain_prefix + "api/v1/watch/delete-column";
export const UPDATE_TABLE_COLUMN = domain_prefix + "api/v1/watch/update-column";
export const SAVE_LAST_COLUMNS_LIST = domain_prefix + "user/save-last-selection-watchlist";
export const GET_LAST_COLUMNS_LIST = domain_prefix + "user/get-last-selection-watchlist";
