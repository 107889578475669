<template>
    <div class="main min-h-screen">
      <div class="flex justify-center flex-col items-center w-full pt-20">
      
  
       
      
        <div
          class="relative -bg-green-300 sm:pt-0 pt-8 sm:w-fit w-full sm:px-0 px-6"
          :class="loaderState ? 'pointer-events-none' : null"
         
        >
          <div class="">
            <router-link to="/">
              <img class="w-full" :src="require('../../assets/mapWhite.svg')" />
            </router-link>
          </div>
  
          <!-- <div>
            <h6 class="text-[#e5e9ec] mt-2 font-semibold">
             Its seems that your 2FA is enabled
            </h6>
          </div>
          <div>
            <h4 class="text-[#02bd2d] mt-2 font-semibold text-lg">
              Enter your OTP code
            </h4>
          </div> -->
  
          <div class="text-gray-300 ">
            <AlertShort
              class="mt-1 w-[385px]"
              v-if="alertMessage"
              :alertType="alertType"
            >
              <p class="text-sm text-white">{{ alertMessage }}</p>
            </AlertShort>
          </div>
          <div>
            <div class="form-group flex flex-row mt-1">
           
              <div
                style="border: 0.6px solid #7582787d"
                class="form-group rounded-md lg:w-full w-full -bg-red-300 justify-between flex mt-4"
                :class="showOutline ? 'focus' : ''"
                ref="password"
              >
                <div class="w-10/12">
                  <input
                    @keyup.enter="VerifyCode"
                  
                    :type="type"
                    id=""
                    v-model="code"
                    placeholder="Enter 6-digit OTP code"
                    :disabled="disabled"
                    class="w-full bg-transparent -h-[5vh] p-4 box-border -text-[.8vw] text-[#ffffff] rounded-md focus:outline-none"
                  />
                </div>
              
              </div>
            </div>
           
           
  
         
            <div
              class="w-full flex items-center justify-center mt-5"
              :class="loaderState ? 'btnDefault' : ''"
            >
              <VueLoadingButton
                aria-label="Post message"
                :class="loaderState ? 'cursor-not-allowed' : ''"
                class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full"
                @click.native="VerifyCode"
                :loading="loaderState"
                :styled="isStyled"
                :type="submit"
                ><span class="text-[15px] font-bold">{{
                  loaderState ? "VERIFYING" : "VERIFY OTP"
                }}</span></VueLoadingButton
              >
            </div>
          </div>
  
          <div
            class="w-[100%] h-[3.6vh] bg-transparent flex items-center justify-center mt-5"
            style="gap: 0.3vw"
          >
            <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
            <p class="text-[#E5E9EC] text-sm">or</p>
            <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
          </div>
          <div class="mt-5 flex justify-center">
            <p class="text-[#e5e9ec]">
              Go back to
              <router-link to="/"
                ><span class="font-semibold"
                  ><a href=""> Login Area </a></span
                ></router-link
              >
            </p>
          </div>
          <div class="mt-8 flex justify-center">
            <p class="text-[#e5e9ec] TEXT-SM">
              Have an issue?
              <router-link to="/contactus">
                <span class="text-[#758278] text-sm font-semibold cursor-pointer"
                  >Contact Support.</span
                >
              </router-link>
            </p>
          </div>
        </div>
  
        <div class="w-full relative sm:mt-20 mt-36 flex justify-center">
        <PageFooter />
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .supermain {
    background-color: black;
  }
  
  a {
    color: #02bd2d !important;
  }
  
  .main {
    /* background-image: url("../../assets/map-main-background.jpg"); */
    background-color: black !important;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-image: url("../../assets/map-main-background.jpg");
    height: auto;
  } 
  
  
  
  .overlay__content {
    transform: translateX(600%);
    position: absolute;
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  </style>
  
  <script>
  import AlertShort from "../utilities/AlertShort.vue";
  import router from "@/router";
  import userApi from "../../api/userApi";
  import watchListApi from "../../api/watchListApi";
  import VueLoadingButton from "vue-loading-button";
  import WatchConfig from "@/components/config/market_watch/WatchConfig";
  import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
  import PageFooter from "@/components/Footer/Footer.vue"


  // import router from '@/router';
  
  export default {
    components: {
      FooterCompanyLinks,
      AlertShort,
     
      VueLoadingButton,
      PageFooter
    },
    data() {
      return {
        token: null,
        alertTrue: false, 
        alertType: "Success",
        alertMessage: "Enter a 6-digit code that we just sent you to the registered email/phone.",
        loaderState: false,
        code:"",
        WatchConfig,
        data: [
        {
          colName: "symbol",
          label: "Symbol",
          metaInfo: true,
        },
        {
          colName: "company_name",
          label: "Company Name",
          metaInfo: true,
        },
        {
          colName: "sector",
          label: "Sector",
          metaInfo: true,
        },
        {
          colName: "industry",
          label: "Industry",
          metaInfo: true,
        },

        {
          colName: "first_traded",
          label: "First Traded",
          metaInfo: true,
        },
      ],
      stocklistNodePath: ["fd", "fd_au", "fd_au_allords", "fd_au_allords_bookvalue"],
      fiveNodePath: ["oz", "oz_bottom_breakout"],
      
      

      };
    },
    watch: {
     
    },
    methods: {
      fullYear() {
        const currentYear = new Date().getFullYear();
        return currentYear;
      },
     async VerifyCode(){
        let payload={
            code:this.code,
            token:this.token,

        }
        console.log(payload)
        try{
          this.loaderState=true
            if(this.code.length>6){
              
               this.alertMessage="Invalid code."
               this.alertType="Danger" 
              this.loaderState=false
               return
            }
            let res = await userApi.Verify2FA(payload);
            console.log(res);
            if(res.data.success) {
                localStorage.setItem("email", res.data.payload.email);
              localStorage.setItem(
                "isAuthenticated",
                res.data.payload.termsAndCondition
              );
              localStorage.setItem("token", res.data.payload.token);
              localStorage.setItem("userId", res.data.payload.userId); // needs to be removed today 6/8/22
              localStorage.setItem(
                "userDetails",
                JSON.stringify(res.data.payload)
              );
              await this.fetchwatchListApi_Data();
              console.log("res.data.payload=======>", res.data.payload);
              this.$store.commit("SET_USER_DETAILS", res.data.payload);

              localStorage.setItem(
                "tableVarient",
                res.data.payload.varientForMarketWatch
              );
              localStorage.setItem("tableColumns", JSON.stringify(this.data));
              localStorage.setItem("miniChartRange", "1Y");
              localStorage.setItem(
                "WatchConfig",
                JSON.stringify(this.WatchConfig)
              );
              localStorage.setItem(
                "fiveNodePath",
                JSON.stringify(this.fiveNodePath)
              );
              localStorage.setItem(
                "stocklistNodePath",
                JSON.stringify(this.stocklistNodePath)
              );

              if (res.data.payload.indexData.length) {
                console.log(
                  "res.data.payload.IndexData[0]",
                  res.data.payload.indexData[0]
                );
                localStorage.setItem(
                  "indexData",
                  JSON.stringify(res.data.payload.indexData[0])
                );
              } else {
                localStorage.setItem(
                  "indexData",
                  JSON.stringify(this.indexData)
                );
              }

              router.push("/marketalert");

            }
            else{
              this.loaderState=false
              this.alertMessage=res.data.message
              this.alertType="Danger"
            }

        }
        catch(e){
            console.log(e)
          this.alertMessage="Server Error"
          this.alertType="Danger"  
        }

      },
      fetchwatchListApi_Data: async function (index = 0) {
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData(params);
        this.watchListApi_Data = res.data.data;
        console.log("this.watchListApi_Data", this.watchListApi_Data);
        this.$store.commit("WATCHLIST_API_DATA", this.watchListApi_Data);
        console.log("api call");
      } catch (err) {
        console.log("something went wrong", err);
      }
    },
     
      FieldsBorderColor(refrence, color) {
        for (const refvalues of refrence) {
          this.$refs[refvalues].style.border = `0.6px solid ${color}`;
        }
      },
    
    },
    mounted() {
      let route = this.$route.query;
      console.log(route);
      this.token=route.Token
    },
  };
  </script>
  <style scoped>
  a {
    color: white;
  }
  a:hover {
    text-decoration: underline !important;
    text-decoration-color: #02bd27 !important;
  }
  .focus {
    border: 1px solid #02bd27 !important;
  }

  </style>
  