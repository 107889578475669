<template>
  <div>
    <!-- ALERT FOR WARNING -->
    <div
      v-if="alertType === 'Warning'"
      class="border-2 p-3 rounded"
      style="border-color: #fed500; background-color: rgba(254, 213, 0, 0.12)"
      role="alert"
    >
      <div class="flex justify-between items-center pb-2">
        <p
          v-if="title"
          class="font-bold text-sm uppercase"
          style="color: #fed500"
        >
          {{ title }}
        </p>
        <span v-if="closeAlert" class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="yellow"
            stroke-width="2"
            @click="handleCloseAlert"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>

      <div class="pb-6">
        <slot />
      </div>
    </div>

    <!-- ALERT FOR DANGER -->

    <div
      v-if="alertType === 'Danger'"
      class="border-2 p-3 rounded"
      style="border-color: #ff0a00; background-color: rgba(255, 10, 0, 0.12)"
      role="alert"
    >
      <div class="flex justify-between items-center pb-2">
        <p
          v-if="title"
          class="font-bold text-sm uppercase"
          style="color: #ff0a00"
        >
          {{ title }}
        </p>
        <span class="" v-if="closeAlert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="red"
            stroke-width="2"
            @click="handleCloseAlert"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>

      <div class="pb-6">
        <slot />
      </div>
    </div>

    <!-- ALERT FOR SUCCESS -->
    <div
      v-if="alertType === 'Success'"
      class="border-2 p-3 rounded"
      style="border-color: #00c92e; background-color: rgba(0, 201, 46, 0.12)"
      role="alert"
    >
      <div class="flex justify-between items-center pb-2">
        <p
          v-if="title"
          class="font-bold text-sm uppercase"
          style="color: #00c92e"
        >
          {{ title }}
        </p>
        <span class="" v-if="closeAlert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="green"
            stroke-width="2"
            @click="handleCloseAlert"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>

      <div class="pb-6">
        <slot />
      </div>
    </div>

    <!-- ALERT FOR INFORMATION -->
    <div
      v-if="alertType === 'Information'"
      class="border-2 p-3 rounded"
      style="border-color: #2b53ff; background-color: rgba(43, 83, 255, 0.06)"
      role="alert"
    >
      <div class="flex justify-between items-center pb-2">
        <p
          v-if="title"
          class="font-bold text-sm uppercase"
          style="color: #2b53ff"
        >
          {{ title }}
        </p>
        <span class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="blue"
            stroke-width="2"
            @click="handleCloseAlert"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div class="pb-6">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleCloseAlert() {
      this.$emit("change");
    },
  },
  props: {
    alertType: { type: String, default: "Information" },
    title: { type: String, default: "" },
  },
 
};
</script>

<style></style>
