import watchListApi from "@/api/watchListApi";

const watchListModule = {
  state: {
    watchListApi_Data: [],
  },

  mutations: {
   
    WATCHLIST_API_DATA(state, payload) {
      console.log("payload from watch list", payload);
      state.watchListApi_Data = payload;
      console.log(".....in store watchListApi_Data", state.watchListApi_Data);
    },
  },

  actions: {
    async fetchAllWatchListData({ commit }) {
      try {
        const params = {
          userId: localStorage.getItem("userId"),
        };
        const res = await watchListApi.fetchWatchListData();
        if (res.data.payload && res.data.payload.length > 0) {
          commit("WATCHLIST_API_DATA", res.data.payload);
        }
      } catch (err) {
        console.log("something went wrong in wachlist api");
      }
    },
  },

  getters: {

    watchListApi_Data: (state) => state.watchListApi_Data,
  },
};

export default watchListModule;
