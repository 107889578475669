 export const  Colors = {
    MAP_RED :'#FF0A00',
    MAP_YELLOW:'#FED500',
    MAP_GREEN:'#02BD2D',
}


export  function pleaseEnterMessage(message) {
    return `please enter a valid ${message}`
}



