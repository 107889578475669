export const watchlistHeaderDef = {
    "first_traded":"Date the stock first traded.",
    "market_heat":"The Market Heat rating of the stock. Only the top five trend ranked stocks appear in the Market Heat table.",
    "close":"The closing price of the stock at the last trading session.",
    "tgt_price":"The consensus price given by analysts of the stock.",
    "volume":"The volume traded in the last trading session.",
    "days_since_vol_0":"The number of days since the volume was 0. ",
    "lwst_vol_30_days":"The lowest daily volume in the last 20 trading days.",
    "turnover":"The closing price x volume traded in the last session.",
    "avg_t_o_30d":"The average volume traded over the last 20 trading sessions.",
    "lwst_t/o_30d":"The lowest turnover in the last 20 trading sessions.",
    "days_since_lwst_t/o":"The number of days since the lowest turnover in the last 20 trading sessions.",
    "t/o_ls_than_100k_20d":"The number of days turnover was below $100,000 in the last 20 sessions.",
    "gt_than_200d_avg":"Is the close above the 200 day moving average of price.",
    "gt_than_200d_avg":"Is the close above the 200 day moving average of price.",
    "200_mav_gap":"The difference between the last close and the 200 day moving average of price. ",
    "gt_than_100d_avg":"Is the close above the 100 day moving average of price.",
    "100_mav_gap":"The difference between the last close and the 100 day moving average of price.",
    "gt_than_20d_avg":"Indicates if the close is above the 20 day moving average of price.",
    "20_mav_gap":"The difference between the last close and the 20 day moving average of price.",
    "13w_low":"Indicates if a new 13 week low occured.",
    "26w_low":"Indicates if a new 26 week low occured.",
    "52w_low":"Indicates if a new 52 week low occured.",
    "13w_high":"Indicates if a new 13 week high occured.",
    "26w_high":"Indicates if a new 26 week high occured.",
    "52w_high":"Indicates if a new 52 week high occured.",
    '1d_%_chg':'The percentage change since 1 trading day ago.',
    '5d_%_chg':'The percentage change since 5 trading days ago.',
    '10d_%_chg':'The percentage change since 10 trading days ago.',
    '30d_%_chg':'The percentage change since 20 trading days ago.',
    'cls_13w_ago':'The closing price 13 weeks ago.',
    'cls_26w_ago':'The closing price 26 weeks ago.',
    'chg%_13w':'The percentage change in price since 13 weeks ago.',
    'chg%_26w':'The percentage change in price since 26 weeks ago.',
    'cls_52w_ago':'The closing price 52 weeks ago.',
    'chg%_52w':'The percentage change in price since 52 weeks ago.',
    'div_%':'Dividend percentage.',
    'div_$':'Dividend in dollars.',
    'ex_dividend':'The date the stock is ex-dividend.',
    'dividend_pay':'The dividend payment date.',
    'eps':'Earnings per share.',
    'p_e':'Price to earnings ratio.',
    'est_eps_yr':'Estimated EPS 1yr forecast.',
    'revenue':'Revenue in dollars from the income statement.',
    'op_mgn':'Operating margin percentage.',
    'bk_val':'Book value.',
    'ebitda':'Earnings before interest, tax, depreciation and amortisation.',
    'roi':'Return on investment',
    'roe':'Return on (shareholder) Equity',
    'shares_out':'Number of shares outstanding.',
    'mkt_cap_m':'Market capitalisation.',
    'highest_index':'The highest index the stock is assigned to. Stocks may be in more than one index. E.g. S&P100 and S&P500.',
    'exchange':'The exchange the stock is listed on.',
    'frank_pct_au':'Australia only. Franking is the amount of tax pre-paid on divivends by a company. ',   
}