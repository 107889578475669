<template>
    <tailwind-modal :show-modal="createCopyWatchListState" v-on:change="toggleModal" :showHeader="false">
        <div class="min-w-[38rem] px-10 py-4" style="
            border: 0.3px solid rgba(2, 189, 45, 0.3);
            box-sizing: border-box;
            scrollbar-color: red;
            border-radius: 3px;
            background-color: #151515;
          ">
            <div class="flex pb-4">Select Watchlist</div>

            <div v-if="showCopyMsg" class="w-full flex items-center justify-center">
            <span class="text-[#02bd2d]">{{showCopyResponseMsg}}</span>
            </div>

            <div v-else>

            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 2vh;
                height: 100%;
                padding: 15px;
              "
            >
              <select
                name=""
                id=""
                class="text-[black] h-[3vh] outline-none"
                @change="selectWatchList"
              >
                <option value="">--Select Watchlist--</option>
                <option
                  v-for="(names, i) in watchlistData"
                  :key="i"
                  :value="i"
                >
                  {{ names.name }}
                </option>
              </select>
              
            </div>
        </div>
           
            <div class="w-full flex justify-end">
                <button type="submit" @click="toggleModal" class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                    {{showCopyMsg ? 'Close':'Cancel'}}
                </button>
                
                <button v-if="!showCopyMsg" type="submit" @click="handleCopyWatchList" class="ok-button px-8 mt-6 font-semibold py-2 text-sm" :disabled = "newWatchName == ''">
                    Copy
                </button>
            </div>
            </div>


  
    </tailwind-modal>
    </template>
    
    <script>
    import TailwindModal from "@/components/popups/TailwindModal";
    import watchListApi from "../../../api/watchListApi";
    import moment from "moment"

    export default {
        name: "CopyToWatchlist",
        components: {
            TailwindModal
        },
        data() {
            return {
                watchListName: "",
                error: "",
                showMessage: false,
                newWatchName:"",
            };
        },
        props: {
            createCopyWatchListState: {
                type: Boolean,
                default: true,
            },
            watchlistData:{
                type:Array,
                default:[]
            },
            symbols:{
                type:Array,
                default:[]
            },
            showCopyResponseMsg:{
                type:String,
                default:""
            },
            showCopyMsg:{
                type:Boolean,
                default:false
            }
        },
        methods: {
            selectWatchList(e) {
               
            this.newWatchName = e.target.value;
      
            },
            toggleModal() {
                this.watchListName = ""
                this.error = ''
                this.$emit("closeCopyModal");
                // console.log(this.watchlistData)
            },
            showResponseMsg(error) {
                this.showMessage = true
                this.error = error
            },
           async handleCopyWatchList(){
            let watchId = this.watchlistData[this.newWatchName]._id
            this.$emit("copySymbol",watchId)
            // let date = moment().format('MMMM Do YYYY, h:mm a');
            //     const payload = {
            //         watchId:watchId,
            //         symbolList:this.symbols,
            //         updatedDate:date
            //     }

            //     const res = await watchListApi.copySymbols(payload)
            //     if(res.data.success){
            //         this.showResponseMsg("Your Symbol(s) has been copied successfully")
            //         console.log("response move symbol",res);
            //     }else{
            //         this.showResponseMsg("Server Error")
            //     }
                // console.log("copy watchlist", this.watchlistData[this.newWatchName].name)
                // console.log("copy watchlist id", this.watchlistData[this.newWatchName]._id)
                // console.log("symbols",this.symbols)
            }
         
        },
       
    
    };
    </script>
    
    <style scoped>
    .ok-button {
        border: 0.6px solid #02bd2d;
        border-radius: 3px;
        color: #02bd2d;
        text-transform: uppercase;
    }
    
    .ok-button:hover {
        background: linear-gradient(270deg,
                rgba(2, 189, 45, 0.24) 0%,
                rgba(2, 189, 45, 0) 50%,
                rgba(2, 189, 45, 0.24) 100%);
    }
    
    .cancel-button {
        border: 0.6px solid #f8f9f9;
        border-radius: 3px;
        color: #f8f9f9;
        text-transform: uppercase;
    }
    
    .cancel-button:hover {
        background: linear-gradient(270deg,
                rgba(248, 249, 249, 0.21) 0%,
                rgba(248, 249, 249, 0) 52.08%,
                rgba(248, 249, 249, 0.21) 100%);
    }
    </style>
    