<template>
  <div class="main  min-h-screen ">
    <div class="w-full h-full flex flex-col justify-center items-center">
      <!-- emailSent -->
      <div v-if="emailSent" class="relative sm:pt-2 pt-16">
        <div class="px-4 justify-center items-center h-full w-full relative">
          <div class="grid justify-items-stretch">
            <img
              class="sm:w-7/12 w-full justify-self-center"
              :src="require('../../assets/mapWhite.svg')"
            />
          </div>
          <div class="mt-4">
            <AlertLong
              alertType="Success"
              closeAlert="false"
              title="EMAIL SENT"
            >
              <p
                class="text-white flex items-center justify-center h-full mt-2 mb-5"
              >
              A password reset link has been sent to the email nominated in your account. Please allow time for it to arrive.
              </p>
            </AlertLong>

          </div>
          <div class="donthaveacc flex mt-1 justify-start">
            <p>
              <router-link
                :to="`/`"
                :class="[disabled ? pointer_events_none : '']"
                ><span> <a href="">Back to Home</a> </span></router-link
              >
            </p>
          </div>

          <div class="w-full flex items-center justify-center flex-col">
          <div class="flex justify-center mt-4 items-center w-4/12">

            <VueLoadingButton
                  aria-label="Post message"
                  :class="loaderState ? 'cursor-not-allowed' : ''"
                  class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full"
                  @click.native="Forgetpass"
                  :loading="loaderState"
                  :styled="isStyled"
                  :type="submit"
                  ><span class="text-[15px] font-bold">{{
                    loaderState ? "PLEASE WAIT.." : "RESEND"
                  }}</span></VueLoadingButton
                >
          </div>
  
        </div>
       
        </div>
      </div>
      <div
        class="relative w-full sm:px-0 sm:pt-20 px-6 sm:w-fit -bg-purple-300 -xl:bg-red-200 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600 pt-28"
        v-else
        :class="loaderState ? 'pointer-events-none' : ''"
      >
        <div class="">
          <router-link to="/">
            <img class="w-full" :src="require('../../assets/mapWhite.svg')" />
          </router-link>
        </div>
        <div>
          <h6 class="text-[#e5e9ec] mt-2 font-semibold">
            No worries, it happens
          </h6>
        </div>
        <div>
          <h2 class="text-[#02bd2d] font-bold">
            Please help us to find your account
          </h2>
        </div>
        <div class="mt-4 mb-2">
          <AlertShort v-if="alertTrue" class="w-[385px]" :alertType="alertType">
            <p class="text-sm text-gray-100 bold">
              {{ alertMessage }}
            </p>
          </AlertShort>
        </div>
        <div>
          <form @submit.prevent="null">
            <input
              v-model="email"
              id="email"
              ref="email"
              type="email"
              class="p-4 bg-transparent block w-full mt-6 text-gray-200 border border-[#7582785d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
              placeholder="What is your email?"
              :diabled="disabled"
            />
            <p
              v-if="validationObject.email.status"
              :class="
                'p-0 m-0 mt-0 text-[' +
                pageColors.MAP_RED +
                '] text-[1vw]'
              "
            >
              {{ validationObject.email.message }}
            </p>

            <div
              class="w-full flex items-center justify-center mt-5"
              :class="loaderState ? 'btnDefault' : ''"
            >
              <VueLoadingButton
                aria-label="Post message"
                :class="loaderState ? 'cursor-not-allowed' : ''"
                class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full"
                @click.native="Forgetpass"
                :loading="loaderState"
                :styled="isStyled"
                :type="submit"
                ><span class="text-[15px] font-bold">{{
                  loaderState ? "SENDING EMAIL" : "RESET MY PASSWORD"
                }}</span></VueLoadingButton
              >
            </div>
          </form>
        </div>

        <div
          class="w-[100%] h-[3.6vh] bg-transparent flex items-center justify-center mt-8"
          style="gap: 0.3vw"
        >
          <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
          <p class="text-[#E5E9EC] text-sm">or</p>
          <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
        </div>
        <div class="mt-5 flex justify-center">
          <p class="text-[#e5e9ec]">
            Go back to
            <router-link to="/" :class="[disabled ? pointer_events_none : '']"
              ><span class="font-semibold">Login Area</span></router-link
            >
          </p>
        </div>
        <div class="mt-8 flex justify-center">
          <p class="text-[#e5e9ec] TEXT-SM">
            Have an issue?
            <router-link to="/contactus">
              <span class="text-sm font-semibold cursor-pointer"
                ><a href="/contactus" target="_blank" style="color: #9e9898">
                  Contact Support.</a
                ></span
              >
            </router-link>
          </p>
        </div>
      </div>

      <div class="w-full pb-6 sm:mt-20 mt-40 flex justify-center">
       <PageFooter />
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "../../api/userApi";
import AlertShort from "../utilities/AlertShort.vue";
import { Colors } from "../../helper/globalUtils";
import AlertLong from "../utilities/AlertLong.vue";
import VueLoadingButton from "vue-loading-button";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"

export default {
  components: {
    FooterCompanyLinks,
    AlertShort,
    AlertLong,
    VueLoadingButton,
    PageFooter
  },
  data() {
    return {
      isStyled: false,
      pageColors: Colors,
      email: "",
      alertTrue: false,
      alertMessage: "",
      alertType: "Success",
      loaderState: false,
      validatedEmail: false,
      showTextfield: true,
      disabled: false,
      pointer_events_none: "pointer-events-none",
      emailSent: false,
      validationObject: {
        email: {
          status: false,
          message: "Please enter a valid email address",
        },
      },
    };
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
      this.FieldsBorderColor(["email"], "#7582787d");
      this.validationObject.email.status = false;
      this.alertTrue = false;
    },
  },
  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    validateEmail(value) {
      if (
        /^[a-zA-Z0-9.'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
          value
        )
      ) {
        this.validatedEmail = true;
      } else {
        this.validatedEmail = false;
      }
    },
    FieldsBorderColor(refrence, color) {
      for (const refvalues of refrence) {
        this.$refs[refvalues].style.border = `0.6px solid ${color}`;
      }
    },
    showErrors(message, alertType, alertTrue) {
      this.alertTrue = alertTrue;
      this.alertType = alertType;
      this.alertMessage = message;
    },
    async Forgetpass() {
      let isNotValid = false;

      try {
        if (!this.email || !this.validatedEmail) {
          isNotValid = true;
          this.FieldsBorderColor(["email"], this.pageColors.MAP_RED);
          this.validationObject.email.status = true;
        }
        if (isNotValid) {
          return;
        } else {
          this.loaderState = true;
          let payload = { email: this.email?.toLowerCase() };
          const response = await userApi.forgotPassword(payload);

          if (response.data.success) {
            this.loaderState = false;
            this.emailSent = true;
          } else {
            this.loaderState = false;

            this.alertType = "Danger";
            this.alertTrue = true;
            this.alertMessage = response.data.message;
            this.disabled = false;

            // setTimeout(() => {
            //   this.alertTrue = false;
            // }, 5000);
          }
        }
      } catch (error) {
        console.log(error);
        this.loaderState = false;
        this.alertType = "Danger";
        this.alertTrue = true;
        this.alertMessage = error.message;
        // setTimeout(() => {
        //   this.alertTrue = false;
        // }, 5000);
      }
    },
    gRecaptcha() {
      return "g-recaptcha";
    },
  },
  mounted() {
    console.log("hello");
    //  $window.g-recaptcha.render(elem[0], '6Lf1WLghAAAAAHVQcxNQ67wPBCrp1vta_cUrI-IR');
    this.gRecaptcha();
  },
};
</script>
<style scoped>
.main {
  background-image: url("../../assets/map-main-background.jpg");
  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-size: 100% 100%;
  /* height: f; */
  /* height: 100vh; */
  /* height:fit-content;  */
}

.overlay__content {
  transform: translateX(600%);
  position: absolute;
}
.btnDefault {
  pointer-events: none;
}

.spinner {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

a {
  color: #02bd2d;
}
a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}
input:focus {
  border: 1px solid #02bd27 !important;
}
.footerlink {
  color: #00c92e !important;
}

.donthaveacc p {
  color: #f8f9f9;
  font-weight: 100;
}
.donthaveacc span {
  color: #02bd2d;
  font-weight: 600;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}
</style>
