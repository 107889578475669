import axios from "@/axios";
import {CHART_SEARCH, GET_SYMBOL} from "@/api/priceApiRoutes";
import config from "@/api/config";
let cancelToken;
export default {
    async getListOfSymbolsStartWith(symbol) {
        if(cancelToken){
            cancelToken.cancel("New request generated")
        }
        cancelToken = axios.CancelToken.source()
        try {
            let list = []
            let url = `${CHART_SEARCH}/${symbol}/false/something`;
            const res = config.mockingNewFeature ? {status:200,data:{data:[]}} : await axios.get(url,{ cancelToken: cancelToken.token })
            if (res.data && res.data.data && res.status === 200) {
                for (const exchange of res.data.data) {
                    console.log(exchange)
                    let newMap = exchange.slice(0, 5).map(s => { return { name: s.symbol, id: s.exchange,companyName:s.company_name } })
                    list = [...list, ...newMap]
                }
                return list
            } else {
                return []
            }
        } catch (error) {
            return [] 
        }
    },
    async getSymbol(symbol,timeFrame,market = "ASX",limit = 5400){
        try {
            // console.log(".............................................smbol ", symbol)
            // let list = []
            let url = `${GET_SYMBOL}/${symbol}?timeFrame=${timeFrame}&isIntra=false&market=${market}&limit=${limit}`;
            // console.log(url)
            const res = config.mockingNewFeature ? {status:200,data:{data:[]}} : await axios.get(url)
            // console.log('getSymbol',res.data)
            if (res.status === 200) {
                if(res.data.data){
                    return res.data.data
                }else{
                    return { symbol: 'Error', ohlcvData: [] }
                }

            } else {
                return { symbol: 'Error', ohlcvData: [] }
            }
        } catch (error) {
            return { symbol: 'Error', ohlcvData: [] }
        }
    },
    async getIndexData(symbol,timeFrame){
        try {
            // console.log(".............................................smbol ", symbol)
            // let list = []
            let url = `http://139.180.181.26:9090/api/v1/symbols/${symbol}?timeFrame=${timeFrame}&isIntra=false&market=Indices`;
            console.log(url)
            const res = config.mockingNewFeature ? {status:200,data:{data:[]}} : await axios.get(url)
            console.log('getSymbol',res.data)
            if (res.status === 200) {
                if(res.data.data){
                    return res.data.data
                }else{
                    return { symbol: 'Error', ohlcvData: [] }
                }

            } else {
                return { symbol: 'Error', ohlcvData: [] }
            }
        } catch (error) {
            return { symbol: 'Error', ohlcvData: [] }
        }
    },
   
}