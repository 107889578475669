<template>
  <div class=" w-full" >
    <div v-if="showYearSelector" class="container__year flex justify-between items-center h-[3.8vh] px-2">
      <div class="text-[.8vw]">{{alertSelected_TableColumn}}</div>
      <div>
      <button
        class="year__chooser"
        :class="[(Yearsx ==currentYear-5 && this.$route.path == '/stockalert') ? 'cursor-not-allowed':'',getTableLoader ?'pointer-events-none':'']"
        :disabled="(Yearsx ==currentYear-5 && this.$route.path == '/stockalert') ? true : false"
        @click="changeYeartoback()"
      >
        <font-awesome-icon
          style="color: #e5e9ec; height: 1.3vh; display:flex;"
          :icon="['fas', 'chevron-left']"
        />
      </button>

      <span
        style="
          padding-block: 3px;
          padding-inline: 15px;
          color: white;
          font-size:.8vw;
        "
      >
        {{ Yearsx }}
      </span>
 
      <button
        :class="[Yearsx==currentYear ? 'cursor-not-allowed':'',getTableLoader ?'pointer-events-none':'']"
        :disabled="Yearsx==currentYear ? true : false"
        class="year__chooser"
        @click="changeYeartofront()"
      >
        <font-awesome-icon
          style="color: #e5e9ec; height: 1.3vh; display:flex;"
          :icon="['fas', 'chevron-right']"
        />
      </button>
      </div>
    </div>
    <div
      class="container__months w-full h-fit"
      style="padding-inline: 2px"
      :class="{ 'hide-weekend': hideWeekend, 'hide-sunday': hideSunday }"
    >
      <div v-for="i in 4" :key="i" class="w-full flex">
        <month-calendar
          class="container__month grow-[1]"
          
          :style="
            'border-top:0.2px solid rgba(233,233,233,0.1);border-left:' +
            (n == 2
              ? '0.2px solid rgba(233,233,233,0.1)'
              : n == 3
              ? '0.2px solid rgba(233,233,233,0.1)'
              : null)
          "
          style="font-size: 1vw;"
          v-for="n in 3"
          :key="`month-${i}-${n}-${activeYearMA}`"
          :year="activeYearMA"
          :month="monthsss(i, n)"
          :activeDates="month[monthsss(i, n)]"
          :activeClass="activeClass"
          @toggleDate="toggleDate"
          :lang="lang"
          :prefixClass="prefixClass"
          @monthClickEvent="monthClick"
        >
        </month-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import MonthCalendar from "./MonthCalendar";
// import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import { mapGetters } from "vuex";
import tableApi from "../../../api/tableApi";

// import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
export default {
  name: "year-calendar",
  props: {
    showYearSelector: {
      type: Boolean,
      default: () => true,
    },
    activeDates: {
      type: Array,
      default: () => [],
      validator: (dateArray) => {
        let isGood = true;
        let curDate = null;

        dateArray.forEach((date) => {
          if (typeof date === "string") {
            curDate = date;
          } else if (typeof date === "object") {
            curDate = date.date;
          }

          // 以下程式碼參考「How to validate date with format mm/dd/yyyy in JavaScript?」in Stackoverflow
          // 由於 「^\d{4}\-\d{1,2}\-\d{1,2}$」會被ESLint 判為錯誤，所以暫時關閉 EsLint 對下一行的驗證 by丁丁
          // eslint-disable-next-line no-useless-escape
          if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(curDate)) {
            isGood = false;
          }
          // Parse the date parts to integers
          var parts = curDate.split("-");
          var day = parseInt(parts[2], 10);
          var month = parseInt(parts[1], 10);
          var year = parseInt(parts[0], 10);

          if (year < 1000 || year > 3000 || month === 0 || month > 12)
            isGood = false;
          let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
            monthLength[1] = 29;
          if (!(day > 0 && day <= monthLength[month - 1])) isGood = false;
        });
        return isGood;
      },
    },
    value: {
      type: [String, Number],
      //default: () => dayjs().year(),
      default: new Date().getFullYear(),
    },
    lang: {
      type: String,
      default: "en",
    },
    activeClass: {
      type: String,
      default: () => "",
    },
    prefixClass: {
      type: String,
      default: () => "calendar--active",
    },
    hideWeekend: {
      type: Boolean,
      default: false,
    },
    hideSunday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUsingString: true,
      Yearsx: this.value,
      startDate: "",
      endDate: "",
      showEndDate: false,
      currentYear:null
    };
  },
  components: {
    MonthCalendar,
    // Datepicker,
  },
  computed: {
    month() {
      const month = [];
      this.selectedActiveDateStore.forEach((date) => {
        let oDate;

        if (typeof date === "string") {
          oDate = {
            date: date,
            className: this.activeClass,
          };
        } else {
          // 若 activeDate 裡的物件少了 className 屬性，就自動填入空字串。否則會變成undefined
          oDate = {
            date: date.date,
            className: date.className || "",
          };
        }
        if (dayjs(oDate.date).year() !== parseInt(this.Yearsx)) return; // 讓2020年1月的資料，不會放到 2019年的1月資料裡
        let m = (dayjs(oDate.date).month() + 1).toString();
        if (!month[m]) month[m] = [];
        month[m].push(oDate);
      });
      return month;
    },
    activeYearMA: {
      get() {
        console.log("active year is called", parseInt(this.Yearsx));
        return parseInt(this.Yearsx); // this.value 為從外層傳進來的 v-model="year"
      },
      set(val) {
        console.log("active year freom ", val);
        this.$emit("input", val);
      },
    },
    ...mapGetters(["getTableLoader","selectedEndDate", "selectedStartDate", 
    "getTableData","selectedActiveDateStore", "alertSelected_TableColumn", 
    "selectedActiveYearStore", "selectedActiveYearStoreMA", "stockAlert_selected_symbol","tableRowClicked",]),
  },
  methods: {
    // emptyRange() {
    //   this.$store.commit("SET_START_DATE", "");
    //   this.$store.commit("SET_END_DATE", "");
    // },
    calenderDisableClass() {
      if (this.getTableData.length <= 0) {
        return "flex disable";
      } else {
        return "flex";
      }
    },
    hello() {
      console.log(this.getTableData.length);
    },
    monthsss(i, n) {
      if (i == 1) {
        return n;
      } else if (i == 2) {
        return n + 3;
      } else if (i == 3) {
        return n + 6;
      } else {
        return n + 9;
      }
    },
    async changeYeartoback() {
      this.Yearsx = this.activeYearMA - 1;
      if(this.$route.path == "/marketalert"){
        this.$store.commit("CHANGE_YEAR_MA", this.Yearsx);
      }else{
        let payload = {
          year: this.Yearsx,
          startDate: this.selectedStartDate,
          marketId: "au",
          endDate: this.selectedEndDate,
          stock: this.stockAlert_selected_symbol?.toUpperCase()
        };
        this.$store.commit("TABLE_ROW_CLICKED", true)
        const res = await tableApi.fetchStockAlertData(payload);
        this.$store.commit("TABLE_ROW_CLICKED", false)
        if(res.status === 200 && res?.data?.data.length > 0){
          const tabl_data = res?.data?.data
          this.$store.commit("YEAR_FIRST_LAST_DATE", { start_date: res?.data?.data[0].date, last_date: tabl_data[tabl_data.length - 1].date })
          this.handleChartDate(tabl_data[tabl_data.length - 1].date);
          this.$store.commit("CHANGE_YEAR", this.Yearsx);
        }else{
          this.Yearsx = this.activeYearMA + 1;
        }
      }
      
    },
    handleChartDate(chartDate){
       this.$store.commit("SET_STOCKALERT_CHART_DATE", chartDate);
    },
    async changeYeartofront() {
      this.Yearsx = this.activeYearMA + 1;
      if(this.$route.path == "/marketalert"){
        this.$store.commit("CHANGE_YEAR_MA", this.Yearsx);
      }else{
        let payload = {
          year: this.Yearsx,
          startDate: this.selectedStartDate,
          marketId: "au",
          endDate: this.selectedEndDate,
          stock: this.stockAlert_selected_symbol?.toUpperCase()
        };
        this.$store.commit("TABLE_ROW_CLICKED", true)
        const res = await tableApi.fetchStockAlertData(payload);
        this.$store.commit("TABLE_ROW_CLICKED", false)
        if(res.status === 200 && res?.data?.data.length > 0){
          const tabl_data = res?.data?.data
        this.$store.commit("YEAR_FIRST_LAST_DATE", { start_date: res?.data?.data[0].date, last_date: tabl_data[tabl_data.length - 1].date })
          this.handleChartDate(tabl_data[tabl_data.length - 1].date);
          this.$store.commit("CHANGE_YEAR", this.Yearsx);
        }else{
          this.Yearsx = this.activeYearMA - 1;
        }
      }
    },
    toggleDate(dateObj) {
      console.log("year obj:", dateObj, this.value);

      // console.log("Clicked Date:",activeDate)
      let activeDate = dayjs()
        // .set("year", this.value)
        .set("year", this.Yearsx)
        .set("month", dateObj.month - 1)
        .set("date", dateObj.date)
        .format("YYYY-MM-DD");
      this.$emit("toggleDate", {
        date: activeDate,
        selected: dateObj.selected,
        className: dateObj.className,
      });

      let dateIndex;
      let newDates;

      if (this.isUsingString) {
        dateIndex = this.selectedActiveDateStore.indexOf(activeDate);
        newDates = this.modifiedActiveDates(dateIndex, activeDate);
      } else {
        let oDate = {
          date: activeDate,
          className: dateObj.className, // 原為 this.defaultClassName ，修正bug(丁丁)
        };

        dateIndex = this.selectedActiveDateStore.indexOf(
          this.selectedActiveDateStore.find((i) => i.date === activeDate)
        );
        newDates = this.modifiedActiveDates(dateIndex, oDate);
      }
      this.$emit("update:selectedActiveDateStore", newDates);
    },
    modifiedActiveDates(dateIndex, activeDate) {
      let newDates = [...this.selectedActiveDateStore];
      if (dateIndex === -1) {
        newDates.push(activeDate);
      } else {
        newDates.splice(dateIndex, 1);
      }
      return newDates;
    },
    monthClick(monthYearInfo) {
      this.$emit("monthClick", monthYearInfo);
    },
  },
  created() {
    this.isUsingString =
      this.selectedActiveDateStore.length && typeof this.selectedActiveDateStore[0] === "string";
  },

  watch: {
    startDate(newVal) {
      if (newVal) {
        this.showEndDate = true;
      }
      console.log(this.startDate);
      this.$store.commit(
        "SET_START_DATE",
        moment(this.startDate).format("YYYY-MM-DD")
      );
    },
    endDate() {
      console.log(this.endDate);
      this.$store.commit(
        "SET_END_DATE",
        moment(this.endDate).format("YYYY-MM-DD")
      );
    },
    selectedStartDate() {
      this.startDate = this.selectedStartDate;
    },
    selectedEndDate() {
      this.endDate = this.selectedEndDate;
    },

    Yearsx: function(newVal,oldVal){
      // console.log("change from",this.selectedActiveYearStore)
    },
    selectedActiveYearStore: function(newVal,oldVal){
      // console.log("change from year===>",newVal)
      if(newVal.length){
        this.Yearsx = newVal[0].split('-')[0]
      }else{
        this.Yearsx = newVal
      }
    },

    selectedActiveYearStoreMA: function(newVal,oldVal){
      // console.log("change from year===>",newVal)
      if(newVal.length){
        this.Yearsx = newVal[0].split('-')[0]
      }else{
        this.Yearsx = newVal
      }
    }
  },

  mounted() {
    this.hello();
    let date = new Date()
    this.currentYear=date.getFullYear()
    if(this.$route.path == "/marketalert"){
      this.Yearsx = this.selectedActiveYearStoreMA
    }else{
      this.Yearsx = this.selectedActiveYearStore
    }
    console.log(this.currentYear);
    
  },
};
</script>

<style lang="stylus" scoped>
.disable {
  border: none;
  outline: none;
  cursor: not-allowed;
  color: slategray;
}

.vue-calendar__container {
  border-radius: 2px;
  width: 360px;
  position: fixed;
  text-decoration: none;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  .container__year {
    user-select: none;
    width: full;
    background-color: #fff;


    display: flex;

    .year__chooser {
      height: 100%;
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(black, 0.9);

      &:nth-child(4n-3) {
        color: rgba(black, 0.3);
      }

      &:nth-child(2n) {
        color: rgba(black, 0.6);
      }

      &:nth-child(3) {
        box-shadow: inset 0px -3px #4792BD;
      }
    }
  }

  .p-0 {
    padding: 0px;
  }
}
</style>
<style lang="stylus">
.disable {
  border: none;
  outline: none;
  cursor: not-allowed;
  color: slategray;
}

.container__months.hide-sunday {
  .calendar__day:nth-of-type(7n) {
    display: none;
  }

  .calendar__day {
    flex: 16.66%;
  }
}

.container__months.hide-weekend {
  .calendar__day:nth-of-type(7n), .calendar__day:nth-of-type(7n-1) {
    display: none;
  }

  .calendar__day {
    flex: 12%;
  }
}
</style>
