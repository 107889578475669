<template>
  <div>
    <div v-if="modalEnabled">
      <!-- :click="getComment" -->
      <!-- @fetchapi="getMarketTableData" -->
<market-heat-modal

:modalEnabled="modalEnabled"
v-on:closeModal="toggleModal"

:marketHeatModalTable="marketHeatModalTable"
:currentSelectedProp="currentSelectedProp"
:SelectedObj="selectedObj"
/>
</div>
 
    <div class="MainContentMarketHeat mx-2">
      <market-heat-top-bar v-on:highlightYear="tableYearHighlighter" v-on:changeDate="changeDate" :hideDatePicker="hideDatePicker" :moreData="moreDataLoader" :tableData="marketHeatTable" />
      
        <div v-if="loading" class="w-[100%] h-[90vh] overflow-auto overflow-x-hidden">
            <SkeletonTableLoader :rows="25" screen="large" />
        </div>

        <!-- moreDataLoader -->
       
        <div v-if="moreDataLoader && marketHeatTable.length > 0" class="w-[82vw] h-[92vh] absolute" style="background-color: rgba(0, 0, 0, 0.8);z-index: 999;">
          <div role="status" class="w-full h-full flex flex-col space-y-2 items-center justify-center">
            <img src= "../../assets/spinner.svg" />
            <span class="text-sm text-white">Loading year {{ currentYear && currentYear }}...</span>
        </div>
      </div>

       
                   
        <div v-if="marketHeatTable.length == 0 && loading==false"   class="w-[100%] h-[90vh]  flex justify-center items-center ">
                  <AlertShort class="" alertType="Warning" title="No data found" />
        </div>
                <!-- @scroll="handleScroll" -->
            
      <div v-if="marketHeatTable.length > 0 && loading==false"
        style="border-top-left-radius: 7px; border-top-right-radius: 7px"
        class="w-full flex mx-1 justify-center text-center overflow-x-hidden overflow-y-auto"
        :class="['h-[90vh]']"
        @scroll="handleScroll"
        ref="tableBody"
        >
        <table class="tableForMarketHeat" ref="abc">
         
          <thead class="sticky top-0 bg-[#151515]">
            <tr>
              <th
           style=" outline: 1px solid #7582783d;
  outline-offset: -1px;"
                class="bg-[#1E2224]"
                :key="i"
                v-for="(x, i) of this.columnConfig"
                :rowspan="x.rowspan"
                :class="x.headerClasses + ' text-gray-100 text-center'"
                :style="
                  i != 0 && i != 13
                    ? { fontSize: '10px' }
                    : { fontSize: '13px' }
                "
              >
                <div class="flex justify-center items-center Inter-Medium">
                  {{ x.label }}

                  <svg
                    v-if="i > 1 && i < 7"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-[9px] w-[9px] ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="green"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 7l4-4m0 0l4 4m-4-4v18"
                    />
                  </svg>

                  <svg
                    v-if="i >= 7 && i < 12"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-[9px] w-[9px] ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="red"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16 17l-4 4m0 0l-4-4m4 4V3"
                    />
                  </svg>
                </div>
              </th>

              <th
                class="text-gray-100 text-center bg-[#1E2224] totalAboveHeaders"
                rowspan="2"
              >
                <div class="flex justify-center items-center">
                  <svg
                    class="h-4 w-4 text-gray-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
                    />
                  </svg>
                </div>
              </th>
            </tr>

            <tr>
              <th
                colspan="10"
                class="bullishBearsih font-semibold text-xs text-gray-100"
                style="width: 1146.76px; height: 28.79px; border-radius: 5px"
              >
                <span class="flex justify-between">
                  <span
                    style="
                      font-family: 'Inter';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 15px;
                      display: flex;
                      align-items: center;
                      text-transform: uppercase;
                      color: #f8f9f9;
                      opacity: 0.81;
                      width: 185.16px;
                      height: 28.79px;
                      padding-left: 8px;
                    "
                    >BULLISH MARKET</span
                  >

                  <span
                    style="
                      font-family: 'Inter';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 15px;
                      display: flex;
                      align-items: center;
                      text-transform: uppercase;
                      color: #f8f9f9;
                      opacity: 0.81;
                      width: 185.16px;
                      height: 28.79px;
                      display: flex;
                      justify-content: end;
                      padding-right: 8px;
                    "
                    >BEARISH MARKET</span
                  ></span
                >
              </th>
            </tr>
          </thead>
          <!-- :style="[(i == 1 && marketHeatColorCode != '#153D1E') || (i == 12 && marketHeatColorCode != '#550B08') ? {backgroundColor:'#1E2224'}: '' ]" -->

          <tbody ref="tbody">
           
            <tr
              :key="i"
              v-for="(x, i) of marketHeatTable"
              class="hover:bg-[#2E2E2F]  "
              :class="i==tableIndex || i == bgHighlightIndex ?'bg-[#2E2E2F]':''"
            >
              <td
                :key="i"
                v-for="(col, i) of columnConfig"
                :class="[getWatchClasses(col.dataClasses, col.colName, x),i == 1 || i == 12 || signedInUser.role=='plus' ? 'pointer-events-none':'']"
                @click="getTableCell(col.colName, x, col.label,col.showInModal)"
                ref="marketHeatTd_Ref"
                class="xl:text-[9px] 2xl:text-[9px] 3xl:text-[12px] "
                style="border: 0.3px solid #7582783d;"

              >
                {{ col.isDate ?  formatDate(x[col.colName]) : x[col.colName] }}
              </td>

              <td class="mesaggeIcon">
                <div
                  class="flex justify-center items-center"
                  v-if="getCommentHighlight(x) "
                >
                
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-5 w-5 text-gray-500 pt-1 border-box"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="flex justify-center items-center" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-5 w-5 text-gray-500 pt-1 border-box"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                  </svg>
                </div>
              </td>
              <!-- <td class="mesaggeIcon cursor-pointer" @click="getTableCell('comment',x,'Comment',true)">
                <div
                  class="flex justify-center items-center"
                  v-if="x.isComment"
                >
                
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="h-5 w-5 text-gray-500 pt-1 border-box"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="flex justify-center items-center cursor-pointer" @click="getTableCell('comment',x,'Comment',true)" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-5 w-5 text-gray-500 pt-1 border-box"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                  </svg>
                </div>
              </td> -->
            </tr>
          <!-- <tr v-if="moreDataLoader">
        
        <div role="status" class="bg-transparent flex items-center justify-center self-center left-[55vw] bottom-4 absolute">
      <svg aria-hidden="true" class="w-6 h-6 mt-4 mr-2 text-white animate-spin dark:text-gray-600 fill-gray-700" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
      </tr> -->

         
           
         
          </tbody>
        </table>
       
      </div>
     
     
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertShort from "@/components/utilities/AlertShort.vue"

import {
  MARKET_HEAT_GET_HEAT_DATA,
  GET_ALL_MARKET_HEAT_COMMENT,
} from "@/api/apiRoutes";
import {mapActions, mapGetters} from "vuex";
import MarketHeatColumnConfig, {
  ColorMap,
  getWatchClasses,
} from "@/components/config/market_heat/ColumnConfig";
import MarketHeatTopBar from "@/components/topbar/MarketHeatTopBar";
// import LargeTableSkeletonLoader from "@/components/loader/LargeTableSkeletonLoader.vue"
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue"
import MarketHeatModal from "@/components/popups/market_heat/MarketHeatModal";
import tableApi from "@/api/tableApi";
import moment from "moment";
export default {
  components: { MarketHeatModal,AlertShort, MarketHeatTopBar,SkeletonTableLoader },
  data() {
    return {
      moreDataLoader:false,
      loading: false,
      columnConfig: MarketHeatColumnConfig,
      modalEnabled: false,
      marketValriable: "oz",
      currentSelectedProp: "",
      marketHeatTable: [],
      marketHeatTopFiveTable: [],
      marketHeatModalTable: {},
      colorMap: ColorMap,
      allComments: [],
      tableIndex:null,
      hideDatePicker:false,
      currentYear:null,
      yearIndex:{},
      bgHighlightIndex:null
    };
  },
  computed: {
    ...mapGetters([
      "getActiveCountry",
      "selectedActiveYearStoreMH",
      "marketHeatColorCode",
      "commentHighlightArr",
      "signedInUser"
      
    ]),
    ...mapActions(["getUserData"]),
    colorClass(val) {
      console.log(val);
      return "";
    },
  },
  methods: {
//     getComment(){
// console.log("EMITTTT")
//     },

getKeyByValue(object, value) {
   
    for (const key in object) {
      if (object[key]?.includes(value)) {  
        return key;
      }
    }
    return null;
},

    async handleScroll(event){
      if(!this.moreDataLoader){
        const tableBody = this.$refs.tableBody;
        const table = this.$refs.abc;
        const rows = table?.querySelectorAll('tr')
        const scrollTop = tableBody.scrollTop
        const rowHeight = rows[0].offsetHeight
        const index = Math.floor(scrollTop / rowHeight)
        const keyYear = this.getKeyByValue(this.yearIndex, index+14);
        if(keyYear){
          console.log("keyYear",keyYear,this.yearIndex,index)
          if(Number(keyYear) == this.currentYear){
            // console.log("keyYear==>",keyYear,this.yearIndex,index)
            this.$store.commit("CHANGE_YEAR_MH", Number(keyYear));
          }else{
            this.$store.commit("CHANGE_YEAR_MH", Number(keyYear));
          }
        }

        if(index == 0){
          const currentDate = new Date();
          const fullYear = currentDate.getFullYear();
          this.$store.commit("CHANGE_YEAR_MH", fullYear);
        }

        if (tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 1) {
        this.moreDataLoader = true
        this.currentYear = this.currentYear - 1
        this.$store.commit("CHANGE_YEAR_MH", this.currentYear);
        }else{
          return
        }
       
        let result = await axios.post(
        `${MARKET_HEAT_GET_HEAT_DATA}/${this.getActiveCountry}`,
        { year: this.currentYear }
      );
      
      if (result.status == 200 && result?.data?.data.length > 0) {
        this.moreDataLoader = false
        // this.loading = false;
        let prev=this.marketHeatTable?.length
        this.marketHeatTable = this.marketHeatTable.concat(result.data.data);
        let arrOfIndex = [prev,this.marketHeatTable?.length - 1]
        let arrOfAllIndexes = Array.from({ length: arrOfIndex[1] - arrOfIndex[0] + 1 }, (_, index) => index + arrOfIndex[0]);
        this.yearIndex[this.currentYear]=arrOfAllIndexes
        // this.yearIndex[this.currentYear]=[prev,this.marketHeatTable?.length]
        this.$store.commit("CHANGE_YEAR_MH", this.currentYear);
        setTimeout(()=>{
        this.scrollToRow(this.yearIndex[this.currentYear],true)
      },500)
      }else{
        // this.loading = false;
        this.moreDataLoader = false
      }
    
      }
    },  

   
    getCommentHighlight(x){
      if(x?.date){
        // console.log("x==>",x,this.commentHighlightArr)
        let formateDate = this.formatDate(x?.date)
        let isPresent = this.commentHighlightArr?.includes(formateDate)
        return isPresent
      }
    },
    
    formatDate(d){
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY")
    },
    getWatchStyles(i) {
      if (i == 1 || i == 12) {
        return { backgroundColor: "#1E2224" };
      }
    },
    getWatchClasses,
    toggleModal() {
      
      // this.marketHeatTable
      // this.getMarketTableData()
      console.log("EMITT",this.marketHeatTable)
      // console.log(this.modalEnabled);
      this.hideDatePicker = !this.hideDatePicker
      this.modalEnabled = !this.modalEnabled;
      // this.getMarketTableData()
      // this.hideDatePicker = false
      
    },
    scrollToRow(index,isScroll=false) {
      // const tbody = this.$refs.table.$el.querySelector('tbody')
      if(isScroll){
          const tbody = this.$refs.tbody;
          if(index[0] !== 0){
            this.bgHighlightIndex = index[0]
          }
          let row;
          if(index[0] == 0){
            row = tbody?.querySelectorAll("tr")[index[0]];
          }else{
            row= tbody?.querySelectorAll("tr")[index[0]-2];
          }
          row?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        }else{
          const tbody = this.$refs.tbody;
          const row = tbody?.querySelectorAll("tr")[index];
          row?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      
    },
  changeDate(v){
   let formatedDate=moment(v).format("YYYY-MM-DD")
   console.log("formatedDate",formatedDate)
    this.tableIndex = this.marketHeatTable.findIndex(
    (x) => x.date === formatedDate);
    console.log("change date")
    this.scrollToRow(this.tableIndex)
  },
 
  
    closeModal() {
      this.toggleModal();
      
      console.log("hello from close modal");
    },
    async getTableCell(e, x, cellLabel,showInModal) {
      
      console.log(".....-.......", cellLabel);
      if(showInModal){
        // this.hideDatePicker = true
        this.toggleModal();


        this.marketHeatModalTable = { ...x, label: cellLabel,count:x[e],colName:e };
        this.currentSelectedProp = e;  
      }
      
    },

    async loadPreviousYearData(){
        // this.moreDataLoader = true
      this.loading=true
        let result = await axios.post(
        `${MARKET_HEAT_GET_HEAT_DATA}/${this.getActiveCountry}`,
        { year: this.currentYear-1 }
      );      
      if (result.status == 200 && result?.data?.data.length > 0) {
        this.moreDataLoader = false
        let prev=this.marketHeatTable?.length
        this.marketHeatTable = this.marketHeatTable.concat(result.data.data);
        let arrOfIndex = [prev,this.marketHeatTable?.length - 1]
        let arrOfAllIndexes = Array.from({ length: arrOfIndex[1] - arrOfIndex[0] + 1 }, (_, index) => index + arrOfIndex[0]);
        this.yearIndex[this.currentYear-1]=arrOfAllIndexes
        // setTimeout(()=>{
        //   this.scrollToRow(this.yearIndex[this.currentYear-1],true)
        // },500)
      }
      // this.moreDataLoader = false
      this.loading=false
    },

    async tableYearHighlighter(){
      this.bgHighlightIndex = null
      const today = new Date();
      const todayYear = today.getFullYear();
      if(this.currentYear in this.yearIndex){
        // console.log("highlight",this.selectedActiveYearStore,this.yearIndex,this.yearIndex[this.selectedActiveYearStore])
        this.scrollToRow(this.yearIndex[this.currentYear],true)
      }else if(this.currentYear == todayYear){
        this.scrollToRow([0],true)
      }
      else{
        this.moreDataLoader = true
        let result = await axios.post(
        `${MARKET_HEAT_GET_HEAT_DATA}/${this.getActiveCountry}`,
        { year: this.currentYear }
      );      
      if (result.status == 200 && result?.data?.data.length > 0) {
        // this.loading = false;
        this.moreDataLoader = false
        let prev=this.marketHeatTable?.length
        this.marketHeatTable = this.marketHeatTable.concat(result.data.data);
        let arrOfIndex = [prev,this.marketHeatTable?.length - 1]
        let arrOfAllIndexes = Array.from({ length: arrOfIndex[1] - arrOfIndex[0] + 1 }, (_, index) => index + arrOfIndex[0]);
        this.yearIndex[this.currentYear]=arrOfAllIndexes
        this.moreDataLoader = false
        setTimeout(()=>{
        this.scrollToRow(this.yearIndex[this.currentYear],true)
      },500)
      }
      
      }

    },

    // async checkDataDelayed(row){
    //   const response = await tableApi.jobData()
    //   if(row){
    //     console.log("row found")
    //     const watchData = response?.find((item) => item?.jobDetails?.marketId == this.getActiveCountry && item?.jobDetails?.type == "heat" )
    //     console.log("marketid",this.getActiveCountry)
    //         const parsedGivenDate = moment(row?.date, 'YYYY-MM-DD');
    //         const currentTime = moment().format('HH:mm');
    //         const currentTimeMoment = moment(currentTime, 'HH:mm').utc();
    //         const givenTimeMoment = moment(watchData?.runAt, 'HH:mm');
    //         const currentDate = moment();
    //         if (parsedGivenDate?.isSame(currentDate, 'day')) {
    //           console.log("same date")
    //           this.$store.commit("SET_BADGE", false);
    //       } else {
    //         console.log("else ==>")
    //         const dayOfWeek = parsedGivenDate?.day();
    //         if (dayOfWeek === 0 || dayOfWeek === 6) {
    //           this.$store.commit("SET_BADGE", false); 
    //         } else {
    //           console.log("else time ==>",currentTimeMoment,givenTimeMoment)
    //           if(currentTimeMoment?.isAfter(givenTimeMoment)){
    //             this.$store.commit("SET_BADGE", true);
    //           }else{
    //             this.$store.commit("SET_BADGE", false);
    //           }
    //         }
           
    //       }
    //   }else{
    //     console.log("row not found")
    //   }


    // },

    // async checkDataDelayed(row){
    //   const response = await tableApi.jobData()
    //   if(Object.keys(row).length>0){
    //     console.log("response",response)
    //     const watchData = response?.find((item) => item?.jobDetails?.marketId == this.getActiveCountry && item?.jobDetails?.type == "heat")
    //     console.log("watchdata",watchData)
    //         const parsedGivenDate = moment(row?.date, 'YYYY-MM-DD');
    //         const currentTime = moment.utc().format('HH:mm');;
    //         const currentTimeMoment = moment(currentTime,'HH:mm');;
    //         const givenTimeMoment = moment(watchData?.runAt, 'HH:mm');
    //         const currentDate = moment();
    //         if (parsedGivenDate?.isSame(currentDate, 'day')) {
    //           this.$store.commit("SET_BADGE", false);
    //       } else {
    //         const dayOfWeek = parsedGivenDate?.day();
    //         if (dayOfWeek === 0 || dayOfWeek === 6) {
    //           this.$store.commit("SET_BADGE", false); 
    //         } else {
    //          const givenDateMoment = moment(row?.date, 'YYYY-MM-DD');
    //           const currentDateMoment = moment();
    //           const differenceInDays = currentDateMoment?.diff(givenDateMoment, 'days');
    //           if(differenceInDays > 2){
    //             this.$store.commit("SET_BADGE", true);
    //           }
    //           else if(differenceInDays == 2){
    //             const twoDaysAgo = currentDateMoment.subtract(2, 'days');
    //             const isCurrentDateWeekend = currentDateMoment.isoWeekday() > 5;
    //             const isTwoDaysAgoWeekend = twoDaysAgo.isoWeekday() > 5;
    //             if(isCurrentDateWeekend || isTwoDaysAgoWeekend){
    //               this.$store.commit("SET_BADGE", false);
    //             }else{
    //               this.$store.commit("SET_BADGE", true);
    //             }
    //           }else{
    //             if(currentTimeMoment?.isAfter(givenTimeMoment)){
    //               this.$store.commit("SET_BADGE", true);
    //             }else{
    //               this.$store.commit("SET_BADGE", false);
    //             }
    //           }
    //         }
           
    //       }
    //   }
    //   else{
    //     this.$store.commit("SET_BADGE", true);

    //   }


    // },

    isWeekend(date) {
      const dayOfWeek = date
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return dayOfWeek === 0 || dayOfWeek === 6

      }
      // if(dayOfWeek===1){
      //   return dayOfWeek === 1 

      // }
    },
    // async checkDataDelayed(row) {

    //   const response = await tableApi.jobData()
    //   if (Object.keys(row).length === 0) {
    //     this.$store.commit("SET_BADGE", true);
    //     return;
    //   }



    //   const currentDate = moment();
    //   const parsedGivenDate = moment(row.date, 'YYYY-MM-DD');

    //   // const parsedGivenDate = moment(row.date, 'YYYY-MM-DD');
    //   const currentYear = moment().year();
    //   if (parsedGivenDate.year() !== currentYear) {
    //     this.$store.commit("SET_BADGE", false);
    //     return
    //   }

    //   if (this.isWeekend(currentDate.day())) {
    //     this.$store.commit("SET_BADGE", false);
    //     return;
    //   }
      
    //   const watchData = response?.find((item) => item?.jobDetails?.marketId == this.getActiveCountry && item?.jobDetails?.type == "heat");
    //   const givenTimeMoment = moment(watchData?.runAt, 'HH:mm');
    //   const currentTime = moment.utc().format('HH:mm');
    //   const currentTimeMoment = moment(currentTime, 'HH:mm');
    //   const differenceInDays = currentDate?.diff(parsedGivenDate, 'days');
    //   console.log("differenceInDays", differenceInDays)
    //   if(parsedGivenDate?.isSame(currentDate, 'day')){
    //     this.$store.commit("SET_BADGE", false);
    //     return

    //   }



    //   if (differenceInDays > 3) {
    //     this.$store.commit("SET_BADGE", true);
    //     return
    //   }
    //   if (differenceInDays > 1 && !this.isWeekend(currentDate.day())&&currentDate.day() !== 1) {
    //     this.$store.commit("SET_BADGE", true);
    //     return
    //   }
    //   if (differenceInDays === 3 && currentDate.day() === 1) {

    //     console.log("on monday", currentTimeMoment?.isAfter(givenTimeMoment), currentTimeMoment, givenTimeMoment)
    //     if (currentTimeMoment?.isAfter(givenTimeMoment)) {
    //       this.$store.commit("SET_BADGE", true);
    //       return
    //     }
    //   }

    //   if (!parsedGivenDate?.isSame(currentDate, 'day') && differenceInDays == 1) {
    //     console.log("time wala")
    //     console.log("abc",currentTimeMoment?.isAfter(givenTimeMoment))
    //     if (currentTimeMoment?.isAfter(givenTimeMoment)) {
    //       this.$store.commit("SET_BADGE", true);
    //       return;
    //     }
    //     else{
    //       this.$store.commit("SET_BADGE", false);

    //     }

    //   }





    // },

    async checkDataDelayed(country){
      try {
      const payload = {marketId:country,type:'heat'}
      const data = await tableApi.checkDataDelayed(payload);
      console.log("data for data delay",data)
      if(data?.data?.success){
        if(data?.data?.payload){
          this.$store.commit("SET_BADGE", true);
        }
        else{
          this.$store.commit("SET_BADGE", false);
        }
      }
      } catch (error) {
        console.log("err",error)        
      }
    },

    formatDate(d) {
      return moment(d).format("DD/MM/YYYY");
    },

  
    async getMarketTableData() {
      // this.currentYear = this.selectedActiveYearStore 
      this.loading=true
      let result = await axios.post(
        `${MARKET_HEAT_GET_HEAT_DATA}/${this.getActiveCountry}`,
        { year: this.currentYear }
      );
      
      if (result.status == 200) {
        this.loading = false;
        this.marketHeatTable = result.data.data;
        if(this.marketHeatTable?.length >= 0){
          this.checkDataDelayed(this.getActiveCountry)
          if(this.marketHeatTable?.length > 0){
          let commentRow = this.marketHeatTable?.filter((item) => item.isComment == true)
          if(commentRow?.length > 0){
            let extractDates = commentRow?.map(obj => this.formatDate(obj?.date))
            this.$store.commit("SET_COMMENT_HIGHLIGHTER",extractDates);
          }
          }

        }
        // this.yearIndex[this.currentYear]=[5,this.marketHeatTable?.length];
      }
      // console.log(this.marketHeatTable);
    },
  },
 async mounted() {
    // let year = new Date().getFullYear();
    this.currentYear = this.selectedActiveYearStoreMH
    // this.$store.commit("CHANGE_YEAR", year)
    this.getMarketTableData();
    await this.getUserData()
  },
  watch: {
    marketHeatTable(data){
      if(data?.length > 0 && data?.length < 30){
      let year = new Date().getFullYear();
        if(year == this.currentYear){
          this.loadPreviousYearData()
        }
      }
    },
loading(val){
      this.$store.commit("TABLE_LOADER_UPDATE", val);
    },
getActiveCountry() {
      let year = new Date().getFullYear();
      this.$store.commit("CHANGE_YEAR_MH", year)
      this.currentYear = year
      this.yearIndex = {}
      this.getMarketTableData()
    },

  commentHighlightArr(){
    
  },
  bgHighlightIndex(){
    setTimeout(() => {
      this.bgHighlightIndex = null
    }, 5000);
  },
  
  selectedActiveYearStoreMH(n,o){
    console.log("year==>",n,o)
    this.currentYear = n
  },
   
    modalEnabled() {
       console.log("this.modalEnabled from watcher", this.marketHeatModalTable);

      if (!this.modalEnabled) {
        this.marketHeatModalTable = {};
      }
    },
  },
};
</script>

<style scoped>
.overlay__content {
  margin-top: 30vh;
  transform: translate(750%, 10%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.scrollable {
  width: 100%;
  /* height: 800px; */
  overflow-y: scroll;
}

thead {
}

.tableForMarketHeat {
  width: 100%;
  max-height: 80vh;
  /* overflow-y: hidden; */
}

.dateHeaders {
  width: 95.88px;
  /* border: 0.3px solid #7582783d; */
  /* outline: 0.3px solid #7582783d;
  outline-offset: 0px; */
}

.totalAboveHeaders {
  width: 52px;
  height: 50px;
  left: 4px;
  top: 3px;

  /* Cell Title Heat 9px */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  /* font-size: 9px; */
  line-height: 11px;

  /* Cultured 2 */

  color: #e5e9ec;
  /* border: 0.3px solid #7582783d; */
  outline: 0.3px solid #7582783d;
  outline-offset: -1px;
}

.mesaggeIcon {
  border: 0.3px solid rgba(117, 130, 120, 0.2);
}

.basicHeaders {
  height: 27px !important;
  width: 59.29px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  color: #e5e9ec;
  /* border: 0.3px solid #7582783d; */
  
  /* outline: 0.03rem solid #7582783d;
  outline-offset: 0px; */
}

.bullishBearsih {
  background: linear-gradient(270deg, #ff0a00 0%, #1e2125 48.96%, #00c92e 100%);
  /* border: 0.3px solid rgba(248, 249, 249, 0.45); */
    outline: 1px solid rgba(248, 249, 249, 0.45);
  outline-offset: -1px;
 
  border-radius: 3px;
}

.tableDateCoulmn {
  border-top: 0.3px solid rgba(117, 130, 120, 0.2);
  border-left: 0.3px solid rgba(117, 130, 120, 0.2);
  font-size: 10px;
}

.tableWholeBodyColumn {
  border-top: 0.3px solid rgba(117, 130, 120, 0.2);
  border-left: 0.3px solid rgba(117, 130, 120, 0.2);
  border-bottom: 0.3px solid rgba(117, 130, 120, 0.2);
  font-size: 9px;
}
</style>
