export const termsandcondition = [
    { id: 1, heading: "General Advice Warning", content: `Market Alert Pro provides general advice only. Market Alert Pro and its representatives are not
    aware of your personal financial goals, situation or objectives and do not take these matters into
    consideration when providing general advice to you. If you are considering the purchase or sale of a
    financial product we recommend you seek advice from a suitably qualified personal financial
    adviser and obtain a product disclosure statement for the product you are considering.
    Trading financial products comes with financial risk. We recommend you assess the risks associated
    with any financial product you are considering to buy or sell in light of your circumstances and seek
    professional financial advice. Market Alert Pro is unaware of any decision you make with respect to
    the buying or selling of a financial product`},
    { id: 2, heading: "Refund Policy", content: `Please carefully consider your subscription. Market Alert Pro offers limited refunds as follows:
     Monthly subscriptions  No Refunds.
     12 Monthly subscriptions  Current month/s not refundable, balance of 12
    months refunded.`},
    { id: 3, heading: "Access", content: `Access to Market Alert Pro is strictly limited to the named subscriber. The subscription is
    not transferrable and providing your username or password to a third party is strictly
    forbidden. Market Alert Pro will instantly cancel (without refund) any subscription found
    to be accessed by a third party without the express written permission of Market Alert
    Pro. `},
    { id: 4, heading: "Fees", content: `Access to Market Alert Pro is via a subscription as described at www.marketalertpro.com
    Subscribers are required to maintain up to date payment details via the My Profile
    facility at www.marketalertpro.com. Market Alert Pro reserves the right to discontinue
    access to its website in the event that a payment cannot be obtained as of the due date
    of the subscription. Subscriptions are non transferable and apply to the subscriber.
    Passing on of usernames and passwords to third parties is expressly forbidden. `},
    { id: 5, heading: "Trading" , content: `Trading securities is a volatile and complex business that involves risk of loss. The valuation of such
    instruments may fluctuate, and, as a result, you may incur significant losses. Before you trade, you
    should consider your financial experience, goals and financial resources and know how much you
    can afford to lose.`},
    { id: 6, heading: "Suitability of Investment", content: `Market Alert Pro and its representatives do not provide advice concerning the suitability or value of
    any particular investment in securities or regarding any investment strategy, nor do we endorse or
    recommend products or the securities of companies referred to or discussed on our website.`},
    { id: 7, heading: "Technological Failure", content: `Market Alert Pro is not liable in any way for any inaccuracy, error or delay in or omission of any
    information or the transmission or delivery of any information, or for any loss or damage arising
    from or occasioned by any such inaccuracy, error, delay or omission, or for any non-performance or
    interruption of information due to any act or omission, or for any force majeure (i.e. flood,
    extraordinary weather condition, earthquake, or other act of God, fire, war, insurrection, riot, labor
    dispute, accident, action of government, communications, power failure or equipment or software
    malfunction) or any other cause beyond our reasonable control.`},
    { id: 8, heading: "Tax Issues", content:`Your tax affairs are your own responsibility, and you should consult your own attorneys or other tax
    advisors in order to understand the tax consequences of any products and services mentioned on
    this site.
    `},
    { id: 9, heading: " Third Party Information", content: `Any data, information or opinions appearing this site and provided by third parties have been
    obtained from sources believed to be reliable, but we cannot guarantee accuracy and/or
    completeness. The information is not intended to be used as the sole basis for investment
    decisions, nor should it be construed as advice designed to meet the particular needs of an
    individual investor. Please seek the advice of financial advice professionals, as appropriate,
    regarding the evaluation of any specific information, opinion, advice or other content.
    Although the information provided to you on this site is obtained or compiled from sources we
    believe to be reliable, we cannot and do not guarantee the accuracy, validity, timeliness or
    completeness of any information or data made available to you for any particular purpose.
    `},
    { id: 10, heading: " Disclaimer of Warranty and Limitation of Liability", content: `The information on this site is provided “AS IS”. We do not warrant the accuracy of the materials
    provided herein, either expressly or impliedly, for any particular purpose, and expressly disclaim
    any warranties of merchantability or fitness for a particular purpose. We will not be responsible for
    any loss or damage that could result from interception by third parties of any information made
    available to you via this site. Neither we nor any of our directors, officers or employees, nor any
    third party vendor, will be liable or have any responsibility of any kind for any loss or damage that
    you incur in the event of any failure or interruption of this site, or resulting from the act or omission
    of any other party involved in making this site or the data contained therein available to you, or
    from any other cause relating to your access to, inability to access, or use of the site or these
    materials, whether or not the circumstances giving rise to such cause may have been within our
    control or the control of any vendor providing software or services support. In no event will we or
    any such parties be liable to you for any direct, special, indirect, consequential, incidental damages
    or any other damages of any kind, even if we or any other party have been advised on the
    possibility thereof.
    `},
    { id: 11, heading: " Downloading Information", content: `If you download any information or data from this site, you agree that you will not copy it or
    remove or obscure any copyright or other notices or legends contained in any such information.
    `},
    { id: 12, heading: "Use of Links", content: `Should you leave this site via a link contained herein, and view content that is not provided by us,
    you do so at your own risk. The content to which you link will not have been developed, checked for
    accuracy, or otherwise reviewed by us. We are not responsible for damages or losses caused by any
    delays, defects or omissions that may exist in the services, information or other content provided in
    such site, whether actual, alleged, consequential or punitive. We make no guarantees or
    representations as to, and shall have no liability for, any electronic content delivered by any third
    party, including, without limitation, the accuracy, subject matter, quality or timeliness of any
    electronic content. By creating a link to a third party website, we do not endorse or recommend any
    products or services offered on that website. There are inherent risks in the use of any software and
    information found in the internet, and you acknowledge that you understand these risks before
    using any of these services.
    We prohibit caching, unauthorized hypertext links to the site and the framing of any information
    and content including any software programs available on or through the site (“Content”). We
    reserve the right to disable any unauthorized links or frames, and specifically disclaim any
    responsibility for the Content available on any other internet sites linked to this site. Access to any
    other internet sites linked to this site is at the user’s own risk.
    You should be aware that linked sites may contain rules and regulations, privacy provisions,
    confidentiality provisions, transmission of personal data provisions and other provisions that differ
    from the provisions provided on this site. We are not responsible for such provisions, and expressly
    disclaim any and all liability related to such provisions.`},
    { id: 13, heading: "Access to Certain Information and Services", content: `Certain information and services on this website may include materials and information from third
    parties over which we have minimal or no control. We therefore cannot, and do not, guarantee,
    represent or warrant that the content contained in this website is accurate and/or appropriate to
    you or your business.
    `},
    { id: 14, heading: "Transmission of Personal Data", content: `You acknowledge and agree that by providing us with any personal or proprietary user information
    through this site, you consent to the transmission of such personal or proprietary user information
    over international borders as necessary for processing in accordance with our standard business
    practices. You should be aware that linked sites may contain transmission of personal data
    provisions that differ from the provisions provided herein. We are not responsible for such
    provisions, and expressly disclaim any and all liability related to such provisions`},
    { id: 15, heading: "Violations of Terms of Use", content: `We reserve the right to seek all remedies available at law and in equity for violations of these rules
    and regulations, including the right to block access from a particular internet address to the site. We
    may amend these terms of use by posting the amended terms to this site. By continued access to
    and use of our website, you agree to any such amendments to the terms of use.`},
    { id: 16, heading: "Access to Password Protected/Secure Areas", content: `Access to and use of password protected and/or secure areas of this site is restricted to authorized
    users only. Unauthorized individuals attempting to access these areas of the site may be subject to
    prosecution.`},
    { id: 17, heading: "Ownership and Copyright", content: `All content, materials and software posted to or used in conjunction with this website or the
    products and services described herein, including, without limitation, text, graphics, logos, button
    icons, images, audio clips, and software, are our property or our licensors, and are protected by
    international copyright, trademarks and other proprietary rights and Intellectual Property Rights
    laws. For purposes of these terms of use, the term “Intellectual Property Rights” means,
    collectively, rights under patent, trademark, copyright and trade secret laws, any applications or
    registrations relating to such rights, and any other intellectual property or proprietary rights
    recognized in any country or jurisdiction worldwide, including, without limitation, moral rights and
    similar rights. Except to the minimum extent otherwise expressly permitted under copyright law, no
    copying or exploitation of material from this website is permitted without our express written
    permission and any other applicable copyright owner. You acknowledge that you do not acquire any
    ownership rights by virtue of downloading copyrighted material from this website, and you may not
    resell any of the products or services (or any part thereof) described herein. All rights not expressly
    granted hereunder are expressly reserved to us and our licensors, and we reserve the right to
    remove or disable access to any content or other materials that violate or allegedly infringe on a
    party’s copyright or other Intellectual Property Rights. If you believe your rights under applicable
    copyright laws are being infringed, you may notify us appropriately.`},
    { id: 18, heading: "Trademarks", content: `This website includes our and our licensors’ registered and common law trademarks. The “look and
    feel” of this website is also our and/or our licensors’ trademark and proprietary trade dress,
    including the color combinations, button shapes, layout, and all other graphical and navigational
    elements on this website. The “look and feel” of this website, including without limitation its color
    combinations, button shapes, layout, and all other graphical and navigational elements, is also our
    licensors’ trademarks and proprietary trade dress. The websites may contain our or our licensors’
    other registered and common law trademarks in addition to those listed.`},
    { id: 19, heading: "Individual Non-commercial Use", content:`You are only authorized to visit, view and to retain a single copy of pages of this website solely for
    your own individual use, and you shall not duplicate, download, publish, modify or otherwise
    distribute or exploit any material on this website for any purpose other than for your own
    individual, non-commercial use unless otherwise specifically authorised by us.
    `},
    { id: 20, heading: "Security, Cracking and Hacking", content: `You may not violate or attempt to violate the security of this website or any of the products or
    services described herein. Accordingly, you shall not: (i) access data or materials not intended for
    you; (ii) log into a server or account which you are not authorized to access; or (iii) attempt to
    probe, scan or test the vulnerability of a system or network or to breach security or authentication
    measures without proper authorization. Violations of system or network security may result in civil
    or criminal liability. We reserve the right to investigate occurrences which may involve such
    violations and may involve, and cooperate with, law enforcement authorities in prosecuting users
    who have participated in such violations. You agree that it is your responsibility to install anti-virus
    software and related protections against viruses, Trojan horses, worms, time bombs, cancelbots or
    other computer programming routines or engines that are intended to damage, destroy, disrupt or
    otherwise impair a computer’s functionality or operation which may be transferred to your
    computer via this website.`},
    { id: 21, heading: "Spidering", content: `The use of any tools, programs, robotic algorithms or products to automatically download or
    “spider” the site or any of the pages of this website is expressly prohibited and infringes on our `},
    { id: 22, heading: "Framing and Inlining", content: `You may not frame this website or any page or portion of this website by calling content from
    within the website to appear to a user as part of a page on another website, whether within a
    visible frame or inline by framing without a border around the framed content, without our prior
    written consent.`},
    { id:23, heading: "Further Prohibited Acts", content:`Spoofing or otherwise impersonating any person or entity, including, without limitation, any other
    users or any of our personnel, or falsely stating or otherwise misrepresenting your identity or
    affiliation in any way, or forging any TCP/IP packet header or any part of the header information in
    any e-mail or other posting;Any fraudulent or illegal purpose, or any use which violates the accepted norms of the internet
    community, whether or not expressly mentioned in these terms of use, as well as any activity that
    could damage our commercial reputation and goodwill or the commercial reputation and good will
    of our vendors and customers, including, without limitation, any regulated insider information; E-mailing, uploading, or otherwise transmitting or using this website or the products and services
    described herein in furtherance of the use or distribution of any unlawful, harmful, harassing,
    defamatory, tortious, libellous, abusive, threatening, vulgar, sexually explicit, obscene, hateful,
    racially, ethnically or otherwise objectionable material of any kind, or any material that is invasive
    of another’s privacy or exploits children, or transmitting any sexually explicit materials, including
    images and other content;
    Transmitting material that contains viruses, Trojan horses, worms, time bombs, cancelbots or other
    computer programming routines or engines, including without limitation, via means of submitting a
    virus to this website, that are intended to damage, destroy, disrupt, overload, flood, mailbomb or
    crash or otherwise impair a computer’s functionality or the operation of this (or anyone else’s)
    website, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or
    information, or transmit any materials that otherwise violate our rules or policies;
    To provide computer time sharing, third party training, virtual or actual hosting or as a service
    bureau for any third parties;
    To copy, use, analyze, modify, decompile, disassemble, reverse engineer, translate or convert any
    software provided to you in connection with use of this website or the products or services
    described herein or distribute any software or the products or services described herein to any third
    party.`},
    { id: 24, heading: "Third Party Intellectual Property Rights", content: `Without limiting the foregoing, you may not, and by using any or all of this website and you agree
    not to, use this website or the products or services described herein to: (i) transmit material that is
    copyrighted, unless you are the copyright owner or have obtained the permission of the copyright
    owner to transmit it; (ii) transmit material that reveals trade secrets, unless you own them or have
    the permission of the owner to so transmit them; or (iii) transmit material that infringes on any
    Intellectual Property Rights of others, or violates the privacy or rights of publicity of others.`},
    { id: 25, heading: "Compliance with Laws", content: `You may use this website only for lawful purposes. This website and the products and services
    described herein are subject to, and you agree that you shall at all times comply with, all applicable
    local, state, national, and international laws, statutes, rules, regulations, ordinances and the like
    applicable to the use thereof. This obligation includes, without limitation, your agreement to
    comply with all industry-specific regulations and rules relating to the export of technical and other
    data from Australia (and from your country if you are not located in Australia) and your agreement
    not to export or re-export any such data or any other content or materials in violation of such laws,
    rules or regulations without first obtaining all necessary licenses, consents and approvals, as well as
    authorization from us.
    `},
    { id: 26, heading: "Regulatory Considerations", content: `We may monitor data as may be necessary to meet federal, state and other regulatory
    requirements. You shall also permit us by any reasonable and appropriate means to verify that you
    have complied with the terms of this Agreement, and you agree to cooperate fully with any such
    verification process.`},
    { id: 27, heading: "Jurisdiction", content: `The information provided on this site is not intended for distribution to, or use by, any person or
    entity in any jurisdiction or country where such distribution or use would be contrary to law or
    regulation or that would subject Market Alert Pro Pty Ltd or our affiliates (“we” or “us”), to any
    regulation under, or registration requirement within, such jurisdiction or country. Products or
    services described herein may not be available in all jurisdictions.
    `},
    { id: 28, heading: "Non-Solicitation", content: `Neither the information, nor any opinion contained in this site, constitutes a solicitation or offer by
    us to buy or to sell any securities or other financial instruments or to provide any investment advice
    or service.`},




];
