const SYMBOL = {
  index: 0,
  key: "symbol",
  title: "Symbol",
  definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
  type: "string",
  head_style: "",
  body_style: "",
  width: "32px"
};
const _TURNOVER =  {
  index: 81,
  key: "_turnover",
  title: "Turnover $",
  definition: "",
  type: "string",
  head_style: "",
  body_style: "",
  width: "32px",
  highlighter: "turnover"
};

const _1_DAY_CHANGE =  {
  index: 80,
  key: "_1_day_change",
  title: "1 Day Change %",
  definition: "",
  type: "float",
  head_style: "",
  body_style: "",
  width: "32px",

};

const OVERSOLD =   {
  index: 79,
  key: "Oversold",
  title: "Oversold",
  definition: "",
  type: "number",
  head_style: "",
  body_style: "",
  width: "32px",
  highlighter: "oversold"
};
const OBERBOUGHT =  {
  index: 79,
  key: "Overbought",
  title: "Overbought",
  definition: "",
  type: "number",
  head_style: "",
  body_style: "",
  width: "32px",
  highlighter: "overbought"
};

const COMPANY_NAME = {
  index: 1,
  key: "company_name",
  title: "Company Name",
  definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
  type: "string",
  head_style: "",
  body_style: "",
  width: "128px"
};

const CLOSE = {
  index: 82,
  key: "close",
  title: "Close",
  definition: "",
  type: "float",
  head_style: "",
  body_style: "",
  width: "32px"
};

const CLOSE_1day_AGO =
{
  index: 72,
  key: "close_1day_ago",
  title: "Close 1 Day Ago",
  definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
  type: "float",
  head_style: "",
  body_style: "",
  width: "32px"
};

const VOLUME =   {
  index: 21,
  key: "volume",
  title: "Volume",
  definition: "",
  type: "string",
  head_style: "",
  body_style: "",
  width: "32px",
  highlighter: "volume"
};

const _1_DAY_CHG =  {
  index: 17,
  key: "_1_day_chg",
  title: "1 Day % Chg",
  definition: "",
  type: "percent",
  head_style: "",
  body_style: "",
  width: "32px"
};

const us_ColumnConfig = [
  SYMBOL,
  COMPANY_NAME,
  {
    index: 2,
    key: "sector",
    title: "Sector",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "64px"
  },
  {
    index: 3,
    key: "industry",
    title: "Industry",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "64px"
  },
  {
    index: 4,
    key: "first_trade_date",
    title: "First Trade Date",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "date",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 5,
    key: "last_close",
    title: "Last Close",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },

  CLOSE,
  CLOSE_1day_AGO,
  {
    index: 80,
    key: "_1_day_change",
    title: "1 Day Change %",
    definition: "",
    type: "float",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 6,
    key: "target_price",
    title: "Target Price",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 7,
    key: "target-last_close",
    title: "Target-Last Close",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 8,
    key: "no_zero_volume_days_last_30_days",
    title: "No. zero volume days last 30 days",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 9,
    key: "days_since_volume_zero",
    title: "Days Since Volume Zero",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 10,
    key: "lowest_v_last_30_days",
    title: "Lowest V last 30 days",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 11,
    key: "turnover",
    title: "Turnover",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 12,
    key: "avg_turnover_30_days",
    title: "Avg turnover 30 Days",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 13,
    key: "lowest_turnover_last_30_days",
    title: "Lowest Turnover Last 30 days",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 14,
    key: "days_since_lowest_turnover",
    title: "Days Since Lowest Turnover",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 15,
    key: "to_less_than_10k_last_30_days",
    title: "T/O less than 10k last 30 Days",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 16,
    key: "close_above_200_day_mov_avg",
    title: "Close Above 200 Day Mov Avg",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  _1_DAY_CHG,
  {
    index: 18,
    key: "_200mav_gap",
    title: "200MAV Gap",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 19,
    key: "close_above_100_day_mov_avg",
    title: "Close Above 100 Day Mov Avg",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 20,
    key: "_100mav_gap",
    title: "100MAV Gap",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  VOLUME,
  {
    index: 22,
    key: "close_above_20_day_mov_avg",
    title: "Close Above 20 Day Mov Avg",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 23,
    key: "_20mav_gap",
    title: "20MAV Gap",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    // index: 24,
    // key: "_20_mav_price",
    // title: "20 MAV Price",
    // definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    // type: "decimal",
    // head_style: "",
    // body_style: "",
    // width: "32px",
    // highlighter:"abv20mav"

    //  index: 24,
    // key: "_1_day_chg",
    // title: "1 Day % Chg",
    // definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    // type: "percent",
    // head_style: "",
    // body_style: "",
    // width: "32px"

    index: 24,
    key: "_200_mav_price",
    title: "200 MAV Price",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "abv200mav"


  },
  {
    index: 25,
    key: "close_below_13_week_low",
    title: "Close Below 13 Week Low",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 26,
    key: "close_below_26_week_low",
    title: "Close Below 26 Week Low",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 27,
    key: "close_below_52_week_low",
    title: "Close Below 52 Week Low",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 28,
    key: "close_above_13_week_high",
    title: "Close Above 13 Week High",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 29,
    key: "close_above_26_week_high",
    title: "Close Above 26 Week High",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 30,
    key: "close_above_52_week_high",
    title: "Close Above 52 Week High",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "boolean",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    // index: 31,
    // key: "volume",
    // title: "Volume",
    // definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    // type: "number",
    // head_style: "",
    // body_style: "",
    // width: "32px"

    index: 31,
    key: "_100_mav_price",
    title: "100 MAV Price",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "abv100mav"
  },
  {
    index: 32,
    key: "lowest_turnover_30d",
    title: "Lowest Turnover 30d",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 33,
    key: "days_since_vol_0",
    title: "Days Since Vol 0",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 34,
    key: "avg_volume_30d",
    title: "Avg Volume 30d",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    // index: 35,
    // key: "_1_day_chg",
    // title: "1 Day % Chg",
    // definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    // type: "percent",
    // head_style: "",
    // body_style: "",
    // width: "32px"

    index: 35,
    key: "_20_mav_price",
    title: "20 MAV Price",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "abv20mav"
  },
  {
    index: 36,
    key: "close_5_days_ago",
    title: "Close 5 days ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 37,
    key: "_5_day_chg_percent",
    title: "5 Day % Chg",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "5dchange"
  },
  {
    index: 38,
    key: "close_10_days_ago",
    title: "Close 10 days ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 39,
    key: "_10_day_change",
    title: "10 Day Change %",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "10dchange"

  },
  {
    index: 40,
    key: "close_30_days_ago",
    title: "Close 20 days ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 41,
    key: "_20_day_change",
    title: "20 Day Change %",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "30dchange"

  },
  {
    index: 42,
    key: "close_13_weeks_ago",
    title: "Close 13 Weeks Ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 43,
    key: "chg__13_weeks",
    title: "Chg% 13 Weeks",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "13wh"
  },
  {
    index: 44,
    key: "cls_26_weeks_ago",
    title: "Cls 26 Weeks Ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 45,
    key: "chg__26_weeks",
    title: "Chg% 26 Weeks",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "26wh"
  },
  {
    index: 46,
    key: "cls_52_weeks_ago",
    title: "Cls 52 Weeks Ago",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 47,
    key: "chg__52_weeks",
    title: "Chg% 52 Weeks",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "52wh"
  },
  {
    index: 48,
    key: "dividend_yield",
    title: "Dividend Yield %",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "dividendyield"
  },
  {
    index: 49,
    key: "dividend",
    title: "Dividend $",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "dividend"
  },
  {
    index: 50,
    key: "eps",
    title: "EPS",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "eps"
  },
  {
    index: 51,
    key: "p_e",
    title: "P/E",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "pe"
  },
  {
    index: 52,
    key: "estimated_eps_calendar_year",
    title: "Estimated EPS Calendar Year",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 53,
    key: "rev_share",
    title: "Rev/Share",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "revshare"
  },
  {
    index: 54,
    key: "operating_margin",
    title: "Operating Margin",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 55,
    key: "book_value",
    title: "Book Value",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "bookvalue"
  },
  {
    index: 56,
    key: "ebitda",
    title: "EBITDA",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 57,
    key: "roi",
    title: "ROI",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "roi"
  },
  {
    index: 58,
    key: "roe",
    title: "ROE",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "decimal",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "roe"
  },
  {
    index: 59,
    key: "large_number",
    title: "Shares Out",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 60,
    key: "mkt_cap_m",
    title: "Market Capitalization",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 61,
    key: "sp100",
    title: "S&P100",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 62,
    key: "sp_midcap_400",
    title: "S&P Midcap 400",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 63,
    key: "sp_smallcap_600",
    title: "S&P Smallcap 600",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 64,
    key: "sp_500",
    title: "S&P 500",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 65,
    key: "nasdaq_100",
    title: "NASDAQ 100",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 66,
    key: "russell_1000",
    title: "Russell 1000",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 67,
    key: "russell_2000",
    title: "Russell 2000",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 68,
    key: "russell_3000",
    title: "Russell 3000",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 69,
    key: "highest_index",
    title: "Highest Index",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 70,
    key: "spx_per_change",
    title: "SPX % Change",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 71,
    key: "stock_per_change",
    title: "Stock Per Change",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 73,
    key: "_1_day_gain",
    title: "1 Day Gain %",
    definition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas sunt fuga repudiandae! Provident, sint ullam? Iste rem est perferendis nisi corrupti magni ab nesciunt? Perferendis aliquid numquam sed maxime alias!",
    type: "float",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "biggestgainer"
  },
  {
    index: 74,
    key: "_1_day_loss",
    title: "1 Day Loss %",
    definition: "",
    type: "float",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "biggestloser"
  },
  {
    index: 75,
    key: "_5day_volume",
    title: "5 Day Volume",
    definition: "",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 77,
    key: "price_change",
    title: "Price Change %",
    definition: "",
    type: "percent",
    head_style: "",
    body_style: "",
    width: "32px"
  },
  {
    index: 76,
    key: "volume_2",
    title: "Volume * 2",
    definition: "",
    type: "string",
    head_style: "",
    body_style: "",
    width: "32px",
    highlighter: "volume_2"
  },
  {
    index: 77,
    key: "price_chg",
    title: "Price Chg %",
    definition: "",
    type: "float",
    head_style: "",
    body_style: "",
    width: "32px"
  },

 
  {
    index: 81,
    key: "_turnover",
    title: "Turnover $",
    definition: "",
    type: "number",
    head_style: "",
    body_style: "",
    width: "32px"
  }
]

const us_oberbought = [
  SYMBOL,
  COMPANY_NAME,
  CLOSE,
  CLOSE_1day_AGO,
  VOLUME,
  _1_DAY_CHG,
  OBERBOUGHT,
  OVERSOLD
]

const us_turnover = [
  SYMBOL,
  COMPANY_NAME,
  CLOSE,
  CLOSE_1day_AGO,
  VOLUME,
  _1_DAY_CHANGE,
  _TURNOVER
]

export default us_ColumnConfig;
export {us_oberbought,us_turnover};
