<template>
  <div class="w-full h-[100%] items-stretch flex flex-col overflow-y-hidden">
    <!-- header area  -->

    <div class="w-full flex items-center space-x-2">
      <!-- header area  -->


      <div class="text-white text-[1.6vw] Inter-Bold">&nbsp;Premium Lists&nbsp;</div>
      <div class="flex text-[.65vw]">
        <span class="text-[#F8F8F9] opacity-40 font-normal">&nbsp;My&nbsp;Favourites</span>
      </div>
      <div class="flex text-[.6vw]">
        <span class="text-[#F8F8F9] opacity-40 font-normal">|</span>
      </div>
      <div class="flex gap-[.5vw] items-center">
        <button class="flex gap-[.2vw] cursor-pointer items-center" v-for="(f, i) in favouriteList" :key="i"
          @click="navigateToTreeFromList(f, i)">
          <SvgListIcon class="w-[.5vw]" :fill-color="f.favColor" />
          <span class="text-[.5vw]" :class="favId == i ? 'text-[green]' : 'text-[#FFFFFF]'">{{ f.desc }}</span>
        </button>
      </div>
    </div>

    <div class="w-full h-[90vh] px-2 flex space-x-1">
      <!-- main div sidebar  -->
      <div class="w-3/12 pt-[1vw] bg-[#1E2125] border-[0.3px] border-[#7582785c] rounded-md"
        :class="showChartLoader ? 'disableTreeArea' : ''">
        <div class="w-full h-[85vh] px-4">
          <div class="w-full h-[65vh]">
            <div class="w-full h-[3vh] flex items-center justify-between">
              <div class="text-white font-semibold text-[.8vw]">
                Premium Lists Browser
              </div>

              <div class="">
                <CardsButton btnType="premium-lock" btnText="premium" />
              </div>
            </div>

            <div class="w-full h-[60vh] text-white">


              <div class="h-[50vh] mt-4 overflow-y-auto">
                <TreeBrowserBeta mainNode :id="n.key" :ref="n.key" @treeLoad="treeLoad" :node="n"
                  v-for="(n, i) of newConfig" :key="i" @onClick="treeEvent" :map="[n.title]" :idMap="[n.key]" />
              </div>
            </div>
          </div>
          <div
            class="w-full bg-[#151515] border-[0.3px] flex flex-col gap-[.6vh] border-[#7582785c] h-[20vh] overflow-y-auto p-[1vw] rounded-b-md">
            <div class="flex items-center space-x-2">
              <img src="images/info.svg" alt="info" class="w-[.7vw]" />
              <div class="Inter-Bold text-md text-base text-white">
                {{ info.name }}
              </div>
            </div>
            <p class="text-[#E5E9EC] text-[.6vw] leading-[2vh]">
              {{ info.definition ? info.definition : "" }}
            </p>
          </div>
        </div>
      </div>

      <div class="w-9/12 border-[0.3px] border-[#7582785c] rounded-b-md rounded-md"
        :class="showChartLoader ? 'disableTreeArea' : ''">
        <!-- table top div  -->
        <div class="flex flex-wrap justify-between h-[10%] items-center px-[.5vw]">
          <div class="flex items-center">
            <div class="text-white flex flex-nowrap">
              <span class="Inter-Bold text-[.9vw]" v-for="(stocks, i) of selectedStock" :key="i">{{ stocks }}
                <span class="opacity-40" v-if="i !== selectedStock.length - 1">
                  &nbsp;|&nbsp;</span>
                <span class="opacity-40" v-if="i === selectedStock.length - 1">
                  :&nbsp;</span>
              </span>
              <span></span>
            </div>
            <div class="flex flex-wrap p-[] text-[.6vw] font-normal text-[#E5E9EC]">
              <span class="opacity-40" v-if="lastUpdated">Updated: {{ lastUpdated }}</span>
            </div>
          </div>
          <div class="flex justify-end items-center gap-[.6vw] h-full">
            <button v-if="watch_List_Api_Data.length > 0 && getFivesData?.length > 0"
              :disabled="getFivesData.length < 1" title="Add To Watchlist" @click="SaveWatchlistModal('add')"
              class="text-[#02BD2D] bgGradient border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
              + ADD TO WATCHLIST
            </button>
            <button v-if="!watch_List_Api_Data.length && getFivesData?.length > 0" :disabled="getFivesData.length < 1"
              title="Add To Watchlist" @click="SaveWatchlistModal('create')"
              class="text-[#02BD2D] border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
              + ADD TO WATCHLIST
            </button>

            <template v-if="favScanLength > 0">
              <button v-if="!currentFavSaved && favouriteList.length < 4" title="Add To Fav" @click="AddToFav"
                class="text-[#02BD2D] bgGradient border border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                + ADD TO FAV
              </button>
              <button v-if="currentFavSaved" title="Remove From Fav" @click="RemoveFromFav"
                class="text-[#02BD2D] border bgGradient border-1 border-[#02BD2D] rounded self-center cursor-pointer text-[.6vw] w-[8vw] h-[40%]">
                + REMOVE FROM FAV
              </button>
            </template>
            <div class=" flex justify-end items-center gap-[.5vw]" :class="fivesTableData.length > 0 ? '' : 'hidden'">
              <!-- <button @click="handleBackArrow" :class="page <= 1 ? ['hidden'] : ''">
                <font-awesome-icon style="color: #e5e9ec; " :icon="['fas', 'chevron-left']"
                  class="market-heat-header-font text-xs" />
              </button> -->
              <select name="data" v-model="length" class=" text-center bg-black text-white"
                @change="handleChange(length)">
                <option v-for="(num, i) of dataValues" :key="i" :value="num">
                  {{ num }}
                </option>
              </select>
              <!-- <button @click="handleForwardArrow" :class="page >= 10 ? ['hidden'] : ''">
                <font-awesome-icon style="color: #e5e9ec; " :icon="['fas', 'chevron-right']"
                  class="market-heat-header-font text-xs" />
              </button> -->
            </div>

            <tailwind-modal :show-modal="showWatchlistModal" v-if="showWatchlistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  gap: 3vh;
                  justify-content: space-between;
                  height: 20vh;
                  width: 25vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;
                    flex-grow: 8;
                    gap: 1vh;
                    height: 80%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Save to watchlist</h1>
                  </div>

                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2vh;
                      height: 100%;
                    ">
                    <select class="text-[black] w-8/12 h-[3vh] focus:outline-none" @change="selectWatcRowIndex">
                      <option value="">
                        <span>--Select Watchlist--</span>
                      </option>
                      <option v-for="(names, i) in watch_List_Api_Data" :key="i" :value="i">
                        {{ names.name }}
                      </option>
                    </select>
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponse"
                    class="w-full flex items-center justify-center text-[#02BD2D] h-[100%]">
                    {{ showModalResponse }}
                  </div>
                  <div v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                    class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]">
                    {{ showModalResponseError }}
                  </div>

                </div>

                <div v-if="!showModalMessage" class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[20%]">
                  <button class="cancel-button w-[20%] h-full text-[.6vw]" @click="showWatchlistModal = false">
                    Cancel
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="SaveWatchlist"
                    :disabled="selectwatchIndex == null">
                    Save
                  </button>
                  <button class="ok-button w-[20%] h-full text-[.6vw]" @click="CreateFromSelectWatchlist">
                    Create
                  </button>
                </div>

              </div>
            </tailwind-modal>

            <!-- createWatclistModal -->
            <tailwind-modal :show-modal="createWatclistModal" v-if="createWatclistModal">
              <div style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 20vh;
                  width: 20vw;
                  padding: 1vw;
                ">
                <div style="
                    display: flex;
                    flex-direction: column;

                    flex-grow: 8;
                    gap: 1vh;
                    height: 70%;
                  ">
                  <div style="display: flex; flex-direction: column; gap: 1vh">
                    <h1 class="text-[1vw]">Create watchlist</h1>
                  </div>
                  <div v-if="!showModalMessage" style="
                      display: flex;
                      flex-direction: column;
                      gap: 2vh;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    ">
                    <input type="text" placeholder="create watchlist"
                      class="p-2 rounded text-[black] w-9/12 focus:outline-none" v-model="newWatchName"
                      v-on:keyup.enter="SaveCreateWatchlist" />
                  </div>

                  <AreaChartLoader :showChartLoader="showModalLoader" />

                  <div v-if="showModalMessage && showModalLoader == false && showModalResponse"
                    class="w-full flex items-center justify-center text-[#02BD2D] h-[100%]">
                    {{ showModalResponse }}
                  </div>
                  <div v-if="showModalMessage && showModalLoader == false && showModalResponseError"
                    class="w-full flex items-center justify-center text-[#FF0A00] h-[100%]">
                    {{ showModalResponseError }}
                  </div>

                </div>

                <div v-if="!showModalMessage" class="flex justify-end gap-[.5vw] items-center flex-grow-[1] h-[30%]">
                  <button class="cancel-button h-[70%] w-[30%] text-[.7vw]" @click="createWatclistModal = false">
                    Cancel
                  </button>
                  <button class="ok-button h-[70%] w-[30%] text-[.7vw]" @click="SaveCreateWatchlist"
                    :disabled="newWatchName == ''">
                    Save
                  </button>
                </div>

              </div>
            </tailwind-modal>
          </div>
        </div>

        <!-- table div  -->

        <!-- table skeleton  -->
        <div class="w-full p-2 min-h-fit overflow-x-hidden overflow-y-auto" v-if="isLoading">
          <SkeletonTableLoader :rows="18" screen="large" />
        </div>

        <!-- no data found  -->
        <div v-if="showError" class="h-[90%] flex w-full">
          <AlertShort alertType="Warning" title="No Stocks Match this Search Today" class="m-auto" />
        </div>

        <!-- table  -->
        <div v-if="fivesTableData.length > 0 &&
          isLoading == false && showError == false
        " class="min-h-fit overflow-auto rounded-b-md" :class="showFooter ? 'h-[35%]' : 'h-[90%]'">
          <table class="w-full overflow-x-auto">
            <thead class="border-b-[#3d3a3a] border-b sticky top-0 bg-[#151515]">
              <tr class="">
                <th class="text-[0.9vw] pl-3 text-center">
                  <label class="container mb-2">
                    <input type="checkbox" class="" v-model="checkAll" />
                    <span class="checkmark"></span>
                  </label>
                </th>

                <th
                  class="text-[.6vw] bg-[#151515] text-center pl-4 font-bold text-white select-none border-[#7582785c]">
                  Chart
                </th>
                <th :key="i" v-for="(x, i) of tableConfig" @click.prevent="updateSort(x.key, currentSort?.order, i)"
                  class="text-[.6vw] pl-2 font-bold text-white border-[#7582785c] cursor-pointer select-none" :class="[
                    x.type == 'string' ? 'text-left' : 'text-left',
                    `width-[${x.width}]`,
                  ]" :style="{ whiteSpace: 'nowrap' }">
                  <div class="flex gap-[.2vw] items-center">
                    {{ x.title }}
                    <table-sort-icon :order="currentSort?.order" v-if="currentSort?.name === x.key" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>

              <tr :key="i" v-for="(x, i) of fivesTableData"
                class="border-b-[1px] border-[#7582785c] h-[5vh] pl-2 hover:bg-[#2E2E2F]">
                <td class="text-[0.9vw] pl-3" v-if="tableConfig.length > 0">
                  <label class="container mb-2">
                    <input type="checkbox" class="" @change="CheckRowTrue($event.target.checked, i, x)"
                      :checked="checkAll || singleInput" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td v-if="fivesTableData.length > 0" class="pl-4">
                  <div class="flex justify-center items-center">
                    <router-link :to="`/chart?t=${x.symbol}`">
                      <svg width="18" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_571_8639)">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.76867 13.7519L5.73489 10.6234L7.5432 12.1031C7.73859 12.2629 8.02254 12.2518 8.20483 12.0771L11.9751 8.4635L15.2449 12.0779C15.4298 12.2823 15.7458 12.2981 15.9501 12.1132C16.1544 11.9284 16.1703 11.6124 15.9854 11.408L12.3708 7.41252C12.2803 7.31251 12.1532 7.25334 12.0184 7.24855C11.8835 7.24371 11.7525 7.29371 11.6551 7.38701L7.83322 11.0502L6.00804 9.55676C5.80556 9.39108 5.50957 9.40983 5.32957 9.59963L2.04409 13.0649C1.85451 13.2648 1.86292 13.5811 2.06284 13.7707C2.26281 13.9603 2.57909 13.9518 2.76867 13.7519V13.7519Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.67902 6.75151L9.31929 6.38751L10.9887 6.159L10.7406 7.82565L10.3809 7.46165L7.98564 9.82908C7.80186 10.0107 7.50986 10.0221 7.3125 9.85539L5.49434 8.31964L2.50778 11.5715C2.32134 11.7744 2.00527 11.7878 1.80232 11.6014C1.59936 11.415 1.58594 11.099 1.77233 10.896L5.0829 7.29151C5.26396 7.09436 5.56831 7.07508 5.77281 7.24778L7.60822 8.79813L9.67902 6.75151V6.75151Z"
                            fill="#02BD2D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.9813 4.43245C17.9813 3.97809 17.6124 3.60919 17.158 3.60919H0.82326C0.368894 3.60919 0 3.97809 0 4.43245V15.1063C0 15.5607 0.368894 15.9295 0.82326 15.9295H17.158C17.6124 15.9295 17.9813 15.5607 17.9813 15.1063V4.43245V4.43245ZM0.998521 4.60771V14.931H16.9827V4.60771H0.998521Z"
                            fill="#02BD2D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_571_8639">
                            <rect width="18" height="18" fill="white" transform="translate(0 0.786682)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </router-link>
                  </div>
                </td>

                <td v-for="(column, i) of tableConfig" :key="i" :style="[

                  columnHighlight == i
                    ? { 'background-color': '#3a3a3a5c' }
                    : {},
                ]" class="text-[.65vw] pl-2 font-medium border-[#7582785c]" :class="[
          `width-[${column.width}]`,
          //column.type == 'string' ? 'text-left' : 'text-right',
        ]">
                  <span v-if="getTableValue(x, column) == null" class="text-gray-500">{{ column?.type == 'string' ?
                    'N/A'
                    : 0 }}</span>
                  <span v-else @click.prevent="getCompanyReport(x)" class="cursor-pointer"
                    :class="x[column.key] < 0 ? 'text-[red]' : 'text-white'">{{ getTableValue(x, column) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- ---------------------pdfReport------------------------->
        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
         :paginate-elements-by-height="1400" :filename="getDownloadName()" :pdf-quality="2" :manual-pagination="true"
         pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px"
         @progress="onProgress($event)" ref="html2Pdf">

         <!-- <div slot="pdf-content" class="bg-red-600 w-full h-full"> -->
         <div slot="pdf-content" class="px-10">
            <div class="page" style="page-break-after: always;">
               <StockReportHeader />
               <br>
               <div class="bg-[#f8f9f9] space-y-2 h-42 -mt-2 w-full flex flex-col justify-between rounded-md">
                  <div class="flex space-x-2 py-1 pl-2 w-full h-[65%]">
                     <div class="w-5/12 p-2 flex items-start flex-col justify-between">
                        <div class="w-full h-full">
                           <div class="flex flex-col -mt-6 justify-start items-start">
                              <span style="font-weight: 800" class="text-[44px] text-[#151515]">{{
                                 companyReportDetail?.symbol || 'N/A' }}</span>
                              <span class="text-[12px] font-normal text-[#151515]">{{ companyReportDetail?.company_name
                                 ||
                                 'N/A' }}</span>
                           </div>
                        </div>
                        <div>
                           <span class="text-[12px] text-black font-semibold">Exchange: {{ companyReportDetail?.exchange
                              ||
                              'N/A' }}</span>
                        </div>
                     </div>

                     <div class="w-7/12 text-sm p-2 flex flex-col justify-between">
                        <div class="w-full">
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-light w-6/12 text-[#333333] text-[12px]">First Trade Date:</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{
                                 companyReportDetail?.first_traded || 'N/A' }}</span>
                           </div>
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-ligh w-6/12 t text-[#333333] text-[12px]">Closing Price ($):</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReportDetail?.close ||
                                 'N/A' }}</span>
                           </div>
                           <div class="flex items-center w-full space-x-2 justify-between">
                              <span class="font-light w-6/12 text-[#333333] text-[12px]">Annual Growth (%):</span>
                              <span class="font-semibold w-6/12 text-black text-[12px]">{{
                                 companyReportDetail?.annual_growth ? companyReportDetail?.annual_growth : 'N/A'
                              }}</span>
                           </div>
                        </div>
                        <div class="w-full">
                           <div class="flex items-center w-full justify-between space-x-2"><span
                                 class="font-light w-6/12 text-[#333333] text-[12px]">Business Classification
                                 (GICS):</span><span class="w-6/12 font-semibold text-black text-[12px]">{{
                                    companyReportDetail?.business_classification || 'N/A' }}</span></div>
                           <div class="flex w-full items-center space-x-2 justify-between whitespace-nowrap"><span
                                 class="font-light text-[#333333] text-[12px] w-6/12 whitespace-nowrap">Sector:</span><span
                                 class="font-semibold text-black text-[12px] w-6/12 whitespace-nowrap">{{
                                    companyReportDetail?.sector || 'N/A' }}</span></div>
                           <div class="flex w-full items-center space-x-2 justify-between"><span
                                 class="font-light text-[#333333] text-[12px] w-6/12">Business Classification
                                 (TRBC):</span><span class="font-semibold text-black text-[12px] w-6/12">{{
                                    companyReportDetail?.trbc_classification || 'N/A' }}</span></div>
                        </div>

                     </div>
                  </div>
                  <div
                     class="h-[12%] font-normal text-[#151515] text-[10px] font-semibold pb-2 text-center bg-[#EFF2F3] w-full border-t border-[#E5E9EC]">
                     This Report is subject to the important notice at the bottom of the last page
                  </div>

               </div>
               <br>
               <div class="w-full bg-black my-2 h-[22vh] pt-2 rounded-[10px]"
                  style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 3px;">
                  <div class="w-full h-full bg-black apexChartdiv vld-parent" ref="areaChartHeightDiv">
                     <AreaChart :is-index-chart="false" :isStockAlert="true" v-if="symbolChartData?.length > 0"
                        class="-mt-2" :chartHeight="chartHeight" :showTrend="true" :data-chart="symbolChartData"
                        :symbol-name="stockAlert_selected_symbol" :stockAlertpdfChart="false" :pdfChart="true"
                        @value-emitted="onValueEmitted" />
                  </div>
               </div>
               <br>
               <div class="w-full px-4 -mt-2">
                  <div class="text-[16px] text-black font-bold -mt-2">Business Activity Summary</div>
                  <div class="text-[#000000] text-justify mt-1 text-[10px]">{{ companyReportDetail?.overview ||
                     'N/A' }}</div>
                     <br>
                  <div class="text-[16px] text-black font-bold mt-1 pb-2">Performance at a Glance</div>
                  <div class="w-full space-x-0.5 flex mt-1 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit Margin</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_profit ?
                        formatNumber(companyReportDetail?.net_profit, 'net_profit') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Growth Rate 5 Yrs</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail?.revenue_growth_5_yrs
                           ? formatNumber(companyReportDetail?.revenue_growth_5_yrs, 'revenue_growth_5_yrs') : 'N/A' }}
                     </div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Debt</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_debt ?
                        formatNumber(companyReportDetail?.net_debt, 'net_debt') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt Growth Rate 5 Yrs</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_growth_5_yrs
                        ?
                        formatNumber(companyReportDetail?.debt_growth_5_yrs, 'debt_growth_5_yrs') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_yield_1_yr ?
                        formatNumber(companyReportDetail?.div_yield_1_yr, 'div_yield_1_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Assets to Liabilities Ratio</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail?.assets_to_liabilities_ratio ?
                           formatNumber(companyReportDetail?.assets_to_liabilities_ratio, 'assets_to_liabilities_ratio') :
                           'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Largest Gap Down 1 Yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                        companyReportDetail['biggest_gap_down_%']
                           ? formatNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Debt to Equity LT</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_lt
                        ?
                        formatNumber(companyReportDetail?.debt_to_equity_lt, 'debt_to_equity_lt') : 'N/A' }}</div>
                  </div>
                  <StockReportFooter :current-page="1" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
               </div>
            </div>



            <div class="page" style="page-break-after: always;">
               <StockReportHeader />

               <div class="px-4">
                  <div class="text-[16px] text-black font-bold -mt-1 pb-2">Fundamental Performance</div>

                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Earnings</div>
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Value</div>
                  </div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">P/E</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['p/e'] ?
                     formatNumber(companyReportDetail['p/e']) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Quick Ratio</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.quick_ratio ?
                     formatNumber(companyReportDetail?.quick_ratio, 'quick_ratio') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EPS</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.eps ?
                     formatNumber(companyReportDetail?.eps) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Current Ratio</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.current_ratio ?
                     formatNumber(companyReportDetail?.current_ratio, 'current_ratio') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Operating Margin</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.op_mgn ?
                     formatNumber(companyReportDetail?.op_mgn, 'op_mgn') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROI</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roi ?
                     formatNumber(companyReportDetail?.roi, 'roi') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EBITDA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.ebitda ?
                     formatNumber(companyReportDetail?.ebitda, 'ebitda') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROE</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roe ?
                     formatNumber(companyReportDetail?.roe, 'roe') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Revenue</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.revenue ?
                     formatNumber(companyReportDetail?.revenue, 'revenue') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROA</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roa ?
                     formatNumber(companyReportDetail?.roa, 'roa') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit 5 Yr Av</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.net_profit_5_yr_av ?
                        formatNumber(companyReportDetail?.net_profit_5_yr_av, 'net_profit_5_yr_av') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Free Cash Flow</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.free_cash_flow ?
                     formatNumber(companyReportDetail?.free_cash_flow, 'free_cash_flow') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Income</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_income ?
                     formatNumber(companyReportDetail?.net_income, 'net_income') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 1 Yr</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.revenue_change_1_yr
                        ? formatNumber(companyReportDetail?.revenue_change_1_yr, 'revenue_change_1_yr') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Pre Tax Income</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.pre_tax_income ?
                     formatNumber(companyReportDetail?.pre_tax_income) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 3 Yr</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.revenue_change_3_yr
                        ? formatNumber(companyReportDetail?.revenue_change_3_yr, 'revenue_change_3_yr') : 'N/A' }}</div>
               </div>
               <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gross Margin</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gross_margin ?
                     formatNumber(companyReportDetail?.gross_margin, 'gross_margin') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Book Value per Share</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.bk_val ?
                     formatNumber(companyReportDetail?.bk_val) : 'N/A' }}</div>
               </div>
               <div class="px-4">
                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Dividends</div>
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Debt,Assets,Equity</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_$ ?
                        formatNumber(companyReportDetail?.div_$) : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cash per Share</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cash_per_share ?
                        formatNumber(companyReportDetail?.cash_per_share, 'cash_per_share') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['div_%'] ?
                        formatNumber(companyReportDetail['div_%'], 'div_%') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Outstanding</div>
                     <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_out ?
                        formatNumber(companyReportDetail?.shares_out, 'shares_out') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Growth 3 Yrs</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_growth_3_yr ?
                        formatNumber(companyReportDetail?.div_growth_3_yr, 'div_growth_3_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Float</div>
                     <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_float ?
                        formatNumber(companyReportDetail?.shares_float, 'shares_float') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Average 5 Yrs</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_avg_5_yr ?
                        formatNumber(companyReportDetail?.div_avg_5_yr, 'div_avg_5_yr') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt to Equity ST</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_st
                        ? formatNumber(companyReportDetail?.debt_to_equity_st, 'debt_to_equity_st') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Franking (Australia Only)</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['frank_pct_(au)']
                        ? formatNumber(companyReportDetail['frank_pct_(au)'], 'frank_pct_(au)') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Market Cap</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.mkt_cap ?
                        formatNumber(companyReportDetail?.mkt_cap, 'mkt_cap') : 'N/A' }}</div>
                  </div>
                  <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Payout Ratio</div>
                     <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.payout_ratio ?
                        formatNumber(companyReportDetail?.payout_ratio, 'payout_ratio') : 'N/A' }}</div>
                     <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Interest Coverage</div>
                     <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                     <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.interest_coverage
                        ? formatNumber(companyReportDetail?.interest_coverage) : 'N/A' }}</div>
                  </div>
               </div>
               <div v-if="(Math.ceil(dataArray.length / 3) + 2) != 2">
                  <br>
                  <br>
               </div>
                  <div class="px-4">
                  <div class="text-[16px] text-black font-bold -mt-1 pb-2">Price Performance</div>

                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Persistence</div>
                     <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Volatility</div>
                  </div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 60d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center font-semibold h-[2.5vh]"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_60d_ma'] ?
                     formatNumber(companyReportDetail['close_>_60d_ma'], 'close_>_60d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">>5% Swings Count </div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['>5%_swings_count'] ?
                     NoDecimalFormatNumber(companyReportDetail['>5%_swings_count']) : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 126d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_126d_ma'] ?
                     formatNumber(companyReportDetail['close_>_126d_ma'], 'close_>_126d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gap Down Count</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gap_down_count ?
                     formatNumber(companyReportDetail?.gap_down_count, 'gap_down_count') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 252d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_252d_ma'] ?
                     formatNumber(companyReportDetail['close_>_252d_ma'], 'close_>_252d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_$']
                     ? formatNumber(companyReportDetail['biggest_gap_down_$']) : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">60d MA > 200d MA</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['60d_ma_>_200d_ma'] ?
                     formatNumber(companyReportDetail['60d_ma_>_200d_ma'], '60d_ma_>_200d_ma') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_%']
                     ? formatNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">52 Week High</div>
                  <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['52w_high'] ?
                     formatNumber(companyReportDetail['52w_high']) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cross 60d MA Count</div>
                  <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cross_60d_ma_count ?
                     formatNumber(companyReportDetail?.cross_60d_ma_count, 'cross_60d_ma_count') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Low Since 52 Week High</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail?.low_since_52_week_high ?
                        formatNumber(companyReportDetail?.low_since_52_week_high) : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Highest to Lowest</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.highest_to_lowest ?
                     formatNumber(companyReportDetail?.highest_to_lowest) : 'N/A' }}</div>
               </div>
               <div class="px-4">
                  <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Liquidity</div>
                     <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Price Growth</div>
                  </div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Open = Close Count</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['open_=_close'] ?
                     formatNumber(companyReportDetail['open_=_close'], 'open_=_close') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Annual Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.annual_growth ?
                     formatNumber(companyReportDetail?.annual_growth, 'annual_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Turnover</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail['50_turnover_average'] ?
                        formatNumber(companyReportDetail['50_turnover_average'], '50_turnover_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Half Year Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.half_year_growth ?
                     formatNumber(companyReportDetail?.half_year_growth, 'half_year_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">10 Day Average Turnover</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                     companyReportDetail['10_turnover_average'] ?
                        formatNumber(companyReportDetail['10_turnover_average'], '10_turnover_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Last Quarter Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.last_quarter_growth
                     ? formatNumber(companyReportDetail?.last_quarter_growth, 'last_quarter_growth') : 'N/A' }}</div>
               </div>
               <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Volume</div>
                  <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['volume_50d_average']
                     ? formatNumber(companyReportDetail['volume_50d_average'], 'volume_50d_average') : 'N/A' }}</div>
                  <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">20 Day Price Growth</div>
                  <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                  <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['20_day_growth'] ?
                     formatNumber(companyReportDetail['20_day_growth'], '20_day_growth') : 'N/A' }}</div>
               </div>
               <StockReportFooter :current-page="2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />

            </div>


            <div v-for="n in Math.ceil(dataArray.length / 3)" :key="n" class="page"
               :class="n !== (Math.ceil(dataArray.length / 3)) ? 'page-break-after' : ''">
               <div class="content">
                  <StockReportHeader />
                  <div>
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1)]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1)]['value'] }}
                     </div>
                  </div>
                  <br>
                  <br>
                  <div v-if="dataArray[3 * (n - 1) + 1]">
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1) + 1]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1) + 1]['value'] }}
                     </div>
                  </div>
                  <br>
                  <br>
                  <div v-if="dataArray[3 * (n - 1) + 2]">
                     <div class="px-4">
                        <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                           dataArray[3 * (n - 1) + 2]['key'] }}
                        </div>
                     </div>
                     <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                        {{ dataArray[3 * (n - 1) + 2]['value'] }}
                     </div>
                  </div>
               </div>
               <StockReportFooter :current-page="n + 2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
            </div>
         </div>
      </vue-html2pdf>











        <div
          class="border-[0.3px] border-[#7582785c] vld-parent rounded p-[1vw] h-[55%] overflow-y-auto xl:overflow-hidden"
          v-if="!isLoading && showFooter && tableConfig.length && fivesTableData.length > 0">
          <div v-if="isCompanyReport">
            <AreaChartLoader :showChartLoader="showChartLoader" />
            <div style="
              display: flex;
              flex-direction: column;
              gap: 1vh;
              height: 100%;
            " class="mb-4">
              <div class="w-full flex flex-wrap justify-between items-center">
                <div class="flex flex-wrap items-center gap-[.5vw]">
                  <div class="text-[.8vw] Inter-Bold text-white flex flex-nowrap">
                    <!-- Company&nbsp;Reports&nbsp;Premium -->
                    Company Report
                  </div>
                  <!-- <div class="flex flex-wrap p-[7px] text-[.5vw] font-normal text-[#E5E9EC]">
                    <span class="opacity-40">Updated: {{ formatDate(companyReport.date_time) }}</span>
                  </div> -->
                </div>
                <div class="flex items-center space-x-2">
                  <div v-if="signedInUser.role == 'premium'"
                    class="flex text-[#02BD2D] text-[10px] border border-1 border-[#02BD2D] rounded self-center p-1 cursor-pointer ok-button"
                    @click="generatePDF()">
                    DOWNLOAD REPORT
                  </div>
                  <div>
                    <svg @click="closeFooter()" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="#FF0A00" class="w-6 h-6 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="w-full flex h-[92%]">
                <div class="w-6/12">
                  <div class="flex text-white text-[.6vw]">
                    <span class="font-semibold">Company Name: </span>
                    <span class=""> &nbsp;{{ companyReport?.company_name }}</span>
                  </div>
                  <div class="text-[#00C92E] Inter-Bold text-[1vw] pb-[.9vh]">
                    Symbol: {{ companyReport?.symbol }}
                  </div>
                  <div class="flex space-x-24">
                    <div class="w-4/12">
                      <div style="display: flex; flex-direction: column; gap: 1.5vh">
                        <div class="text-[#E5E9EC] h-[22vh]" style="display: flex; flex-direction: column; gap: 1vh">
                          <div>
                            <p class="text-[.8vw] font-semibold">
                              Price and Volume:
                            </p>
                          </div>
                          <div class="text-[.55vw]">
                            <p class="w-full flex items-center justify-between">
                              <span>Closing Price ($)</span>
                              <span>{{ formatNumber(companyReport?.close) || 'N/A' }}</span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>Volume</span>
                              <span>{{ formatNumber(companyReport?.volume) || 'N/A' }}</span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                1 Yr Target Price ($)
                              </span>
                              <span>
                                {{ formatNumber(companyReport?.tgt_price) || 'N/A' }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Book Value ($)
                              </span>
                              <span>

                                {{ formatNumber(companyReport?.bk_val) || 'N/A'}}

                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Beta
                              </span>
                              <span>
                                {{ formatNumber(companyReport?.beta) || 'N/A' }}
                              </span>
                            </p>
                            <!-- <p>Highs Lows</p> -->
                          </div>

                          <div>
                            <p class="text-[.8vw] font-semibold">
                              Highs and Lows:
                            </p>
                          </div>

                          <div class="text-[.55vw]">
                            <p class="w-full flex items-center justify-between">
                              <span>
                                52 Week High
                              </span>
                              <span>
                                {{ getCompanyDetails("52w_high") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                52 Week Low
                              </span>
                              <span>
                                {{ getCompanyDetails("52w_low") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                26 Week High
                              </span>
                              <span>
                                {{ getCompanyDetails("26w_high") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                26 Week Low
                              </span>
                              <span>
                                {{ getCompanyDetails("26w_low") }}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="text-white" style="display: flex; flex-direction: column; gap: 1vh">
                          <span>
                            <p class="text-[.8vw] font-semibold pt-2">
                              Dividends and Shares:
                            </p>
                          </span>
                          <span class="text-[.55vw]">
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Shares Float
                              </span>
                              <span>
                                {{ getCompanyDetails("shares_float") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Shares Out
                              </span>
                              <span>
                                {{ getCompanyDetails("shares_out") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Last Split Ratio
                              </span>
                              <span>
                                {{ getCompanyDetails("split_ratio") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Dividend Per Share ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("div_$") }}
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="w-4/12">
                      <div sstyle="display: flex; flex-direction: column; gap:1vh;">
                        <div class="text-white h-[15vh] mt-2" style="display: flex; flex-direction: column; gap: 1vh">
                          <span>
                            <p class="text-[.8vw] font-semibold">Earnings:</p>
                          </span>
                          <span class="text-[.55vw]">
                            <p class="w-full flex items-center justify-between">
                              <span>
                                EPS ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("eps") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Est EPS ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("est_eps_yr") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                P/E ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("p/e") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Qrtly Revenue Growth ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("qrtly_rev_growth") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                PEG Ratio (%)
                              </span>
                              <span>
                                {{ getCompanyDetails("peg_ratio") }}
                              </span>
                            </p>

                            <p class="w-full flex items-center justify-between">
                              <span>
                                EBITDA ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("ebitda") }}
                              </span>
                            </p>
                          </span>
                          <!-- <div class="mt-4"></div> -->
                        </div>

                        <div class="text-white" style="display: flex; flex-direction: column; gap: 1vh">
                          <span>
                            <p class="font-semibold text-[.8vw]">
                              Returns:
                            </p>
                          </span>
                          <span class="text-[.55vw]">
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Profit Margin (%)
                              </span>
                              <span>
                                {{ getCompanyDetails("net_profit") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Operating Margin (%)
                              </span>
                              <span>
                                {{ getCompanyDetails("op_mgn") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Return on Assets (%)
                              </span>
                              <span>
                                {{ getCompanyDetails("roa") }}
                              </span>
                            </p>
                            <p class="w-full flex items-center justify-between">
                              <span>
                                Return on Equity (%)
                              </span>
                              <span>
                                {{ getCompanyDetails("roe") }}
                              </span>
                            </p>

                            <p class="w-full flex items-center justify-between">
                              <span>
                                Sales Per Share ($)
                              </span>
                              <span>
                                {{ getCompanyDetails("sps") }}
                              </span>
                            </p>
                          </span>
                          <!-- <div class="mt-4 pb-2"></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="h-40 w-1 bg-[#D9D9D9] opacity-10 my-auto"></div> -->

                <div class="text-white flex w-6/12 h-full apexChartdiv" ref="areaChartHeightDiv">
                  <AreaChart :showLoader="false" :is-index-chart="false" :symbol-name="selectedSymbol"
                    :data-chart="symbolDataList" :showTrend="true" class="flex justify-center items-center w-full" />

                  <!-- :closingPrice="companyReport?.closing_price" -->

                  <!-- <AreaChart class=" flex justify-center items-center w-full" /> -->
                </div>
              </div>
            </div>
          </div>

          <div v-else class="h-full flex items-center justify-center">
            <AlertShort alertType="Warning"
              :title="isReportMessage ? 'Generating Company Report....' : 'No Company Report'" class="m-auto" />
          </div>



        </div>

      </div>
    </div>
  </div>
</template>

<script>
import GetSymbols from "@/api/charts";
import watchListApi from "@/api/watchListApi";
import TailwindModal from "@/components/popups/TailwindModal";
import TreeView from "../../tree/TreeView.vue";
import { mapGetters } from "vuex";
import TreeBrowserBeta from "@/components/tree/TreeBrowserBeta";
import FivesApi from "@/api/fivesApi";
import moment from "moment";
import { Tree_event } from "@/components/tree/tree_event";
import SvgListIcon from "@/components/tree/SvgListIcon";
import TableSortIcon from "@/components/tables/TableSortIcon";
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue";
import AlertShort from "@/components/utilities/AlertShort.vue";
import structure from "./fivesStructure.json";
import AreaChart from "@/components/charts/AreaChart";
import CardsButton from "@/components/buttons/CardButtons.vue";
import au_ColumnConfig, { au_capp, au_candle, au_etf_breakout, au_breakout } from "@/components/config/fives/au_Columnconfig";
import ca_ColumnConfig, { ca_capp, ca_candle, ca_etf_breakout, ca_breakout } from "@/components/config/fives/ca_Columnconfig";
import us_ColumnConfig, { us_capp, us_candle, us_etf_breakout, us_breakout } from "@/components/config/fives/us_Columnconfig";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import VueHtml2pdf from "vue-html2pdf";
import StockReportHeader from '@/components/stockalertcomponent/StockReportHeader.vue';
import StockReportFooter from "@/components/stockalertcomponent/StockReportFooter.vue";

export default {
  name: "TopFivesBeta",
  components: {
    VueHtml2pdf,
    CardsButton,
    SkeletonTableLoader,
    TableSortIcon,
    AreaChart,
    AreaChartLoader,
    SvgListIcon,
    TreeBrowserBeta,
    TailwindModal,
    AlertShort,
    // VFolder
    TreeView,
    StockReportHeader,
    StockReportFooter,
  },
  data() {
    return {
      watchListChartHeight: 0,
      pdfChartHeight: 0,
      county: "",
      companyReport: {},
      isCompanyReport: false,
      symbolDataList: [],
      showFooter: false,
      tableConfig: [],
      showInputError: false,
      favId: null,
      searchNodeKey: "",
      root: structure,
      showError: false,
      isLoading: false,
      singleInput: null,
      newWatchName: "",
      createWatclistModal: false,
      showModalResponse: "",
      showModalResponseError: "",
      showModalLoader: false,
      showModalMessage: false,
      colHighlight: false,
      columnHighlight: null,
      isBackArrowActive: false,
      isNextArrowActive: false,
      page: 1,
      nodeMeta: "",
      currentSort: {
        name: "",
        order: 0,
      },
      start: 0,
      length: 20,
      newSortVal: "",
      dataValues: [20, 50, 100, "ALL"],
      perPage: 10,
      checkAll: false,
      getFivesData: [],
      // watchListIDs: [],
      selectwatchIndex: null,
      watch_List_Api_Data: {},
      showWatchlistModal: false,
      selectedStock: [],
      newConfig: [],
      image: "images/premium.png",
      fileType: "StockList",
      textColor: "gray",
      fivesTableData: [],
      // currentFav:"",
      currentFav: {
        idMapList: [],
        desc: "",
        favColor: "",
        saved: false,
      },
      info: {
        name: "",
        definition: ``,
      },
      currentFavSaved: false,
      favouriteList: [],
      favColorMap: ["light_blue", "orange", "purple", "yellow"],
      lastUpdated: null,
      // columnConfig: us_Columnconfig,
      columnConfig: null,
      uid: 0,
      result: [],
      fiveNodePath: ["us", "us_bottom_breakout"],
      showChartLoader: true,
      noCompanyReport: false,
      selectedSymbol: "",
      isReportMessage: false,
      norGateValue: {},
      yearChange: "",
      pdfLoading: false,
      chartHeight: 0,
      dataArray: [],
      companyReportDetail: {},
      headings: ["Financials", "Technicals", "Commentary"],
      //   financialKeys:["mkt_cap","debt_to_equity_st","shares_float","shares_out","cash_per_share","net_profit_5_yr_av","p/e","eps","op_mgn","ebitda","revenue","net_profit_5_yr_av","net_income","mrfy_earnings_before_taxes","gross_margin","quick_ratio","current_ratio","roi","roe","roa","free_cash_flow","revenue_change_1_yr","revenue_change_3_yr","bk_val","div_$","div_yield_1_yr","div_growth_3_yr","div_avg_5_yr","frank_pct_(au)","payout_ratio"],
      financialKeys: ["p/e", "eps", "op_mgn", "ebitda", "revenue", "net_profit", "net_income", "mrfy_earnings_before_taxes", "gross_margin", "quick_ratio", "current_ratio", "roi", "roe", "roa", "free_cash_flow", "revenue_change_1_yr", "revenue_change_3_yr", "bk_val", "div_$", "div_%", "div_growth_3_yr", "div_avg_5_yr", "frank_pct_(au)", "payout_ratio", "cash_per_share", "shares_out", "shares_float", "debt_to_equity_st", "mkt_cap", "shares_float", "interest_coverage"],
      technicalKeys: ["close_>_60d_ma", "close_>_126d_ma", "close_>_252d_ma", "60d_ma_>_200d_ma", "52w_high", "low_since_52_week_high", ">5%_swings_count", "gap_down_count", "biggest_gap_down_$", "biggest_gap_down_%", "cross_60d_ma_count", "highest_to_lowest", "open_=_close", "50_turnover_average", "10_turnover_average", "volume_50d_average", "annual_growth", "half_year_growth", "last_quarter_growth", "20_day_growth"],
      commentoryKeys: ["overview", "net_profit", "net_debt", "biggest_gap_down_%", "revenue_growth_5_yrs", "debt_growth_5_yrs", "assets_to_liabilities_ratio", "debt_to_equity_lt", "div_%"],
      selectedHeader: "Financials",
      titleMapping: {
        'p/e': "P/E ($)",
        eps: "EPS ($)",
        op_mgn: "Operating Margin (%)",
        ebitda: "EBITDA ($)",
        revenue: "Total Revenue ($)",
        net_profit: "Net Profit (%)",
        net_income: "Net Income ($)",
        mrfy_earnings_before_taxes: "Pre-Tax Income ($)",
        gross_margin: "Gross Margin (%)",
        quick_ratio: "Quick Ratio (%)",
        current_ratio: "Current Ratio (%)",
        roi: "ROI (%)",
        roe: "ROE (%)",
        roa: "ROA (%)",
        free_cash_flow: "Free Cash Flow ($)",
        revenue_change_1_yr: "Revenue Change 1 Yr (%)",
        revenue_change_3_yr: "Revenue Change 3 Yr (%)",
        bk_val: "Book Value per Share ($)",
        'div_$': "Dividend ($)",
        'div_%': "Div Yield (%)",
        div_growth_3_yr: "Dividend Growth 3 Yrs (%)",
        div_avg_5_yr: "Dividend Average 5 Yrs ($)",
        'frank_pct_(au)': "Franking (Australia only) (%)",
        payout_ratio: "Payout Ratio (%)",
        cash_per_share: "Cash per Share (%)",
        shares_out: "Shares Outstanding",
        shares_float: "Shares float",
        debt_to_equity_st: "Debt to Equity ST (%)",
        mkt_cap: "Market Cap",
        interest_coverage: "Interest Coverage (%)",
        'close_>_60d_ma': "Close > 60d MA",
        'close_>_126d_ma': "Close > 126d MA",
        'close_>_252d_ma': "Close > 252d MA",
        '60d_ma_>_200d_ma': "60d Ma >200d MA",
        '52w_high': "52 Week High",
        'low_since_52_week_high': "Low Since 52 Week High ($)",
        '>5%_swings_count': ">5% Swings Count",
        'gap_down_count': "Gap Down Count",
        'biggest_gap_down_$': "Biggest Gap Down ($)",
        'biggest_gap_down_%': "Biggest Gap Down (%)",
        'cross_60d_ma_count': "Cross 60d MA Count",
        'highest_to_lowest': "Highest To Lowest (%)",
        'open_=_close': "Open = Close",
        '50_turnover_average': "50 Day Average Turnover",
        '10_turnover_average': "10 Day Average Turnover",
        'volume_50d_average': "50 Day Average Volume",
        'annual_growth': "Annual Price Growth (%)",
        'half_year_growth': "Half Year Price Growth (%)",
        'last_quarter_growth': "Last Quarter Price Growth (%)",
        '20_day_growth': "20 Day Price Growth (%)"


      },
      symbolChartData: [],
      selectedChartSymbol: "",
      reportLoading: false,
      symbolDetail: null,
      listData: {},
      reportDataKeyValue: {
        "financial_performance_overview": "Financial Performance",
        "management_summary": "Management Summary",
        "market_outlook": "Market Outlook",
        "risk_factors": "Risk Factors",
        "financial_measures": "Financial Measures",
        "growth_strategy": "Growth Strategy",
        "legal_proceedings": "Legal Proceedings",
        "industry_outlook": "Industry Outlook"
      }
    };
  },

  methods: {
    onValueEmitted(value) {
      if (value) {
        // this.norGateValue = value
        this.norGateValue = Object.entries(value).reduce((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {});

        console.log("value recieved", this.norGateValue)
      }
    },

    CreateFromSelectWatchlist() {
      console.log("add from select watchlist");
      //close select watchlist modal
      this.showWatchlistModal = false;
      //open create watchlist modal
      this.createWatclistModal = true;
    },

    closeFooter() {
      this.pdfChartHeight = 0
      this.showFooter = false
      this.companyReport = {}
    },

    async getCompanyReport(value) {
      console.log("getCompanyReport", value);

      if (value) {
        // this.pdfChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 55;
        this.isReportMessage = true
        this.showFooter = true;
        this.showChartLoader = true;
        let symbolDataList = await GetSymbols.getSymbol(
          value.symbol,
          "D",
          value
        );
        let assetID = symbolDataList?.assetID;
        symbolDataList = symbolDataList?.data ? symbolDataList?.data : [];
        console.log("symbolDataList", symbolDataList);
        this.selectedSymbol = value.symbol;
        this.symbolDataList = symbolDataList;
        this.showChartLoader = false;

        let result = await FivesApi.getCompanyReportStockAlert(value.symbol);
        console.log("result of company report", result);
        // this.showFooter=false
        this.dataArray = []
        if (result.data.payload) {
          this.showFooter = true;
          const updatedObj = { Financials: [], Technicals: [], Commentary: {} };
          this.showChartLoader = false;
          this.isCompanyReport = true
          let payload = result.data.payload;
          this.companyReportDetail = payload;
          this.companyReportDetail['overview'] = this.companyReportDetail?.overview.replace(/&amp;/g, '&')
          this.companyReport = payload;
          const filteredData = {};
          Object.keys(this.reportDataKeyValue).forEach(key => {
            if (key in this.companyReportDetail && this.companyReportDetail[key] !== "") {
              filteredData[key] = this.companyReportDetail[key].replace(/&amp;/g, '&');
            }
          });

          Object.keys(filteredData).map((key) => {
            this.dataArray.push({
              "key": this.reportDataKeyValue[key],
              "value": filteredData[key]
            })
          })
          console.log("company report =>", this.companyReport);
        } else {
          this.isReportMessage = false
          this.isCompanyReport = false
        }
      }

      this.watchListChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
      console.log("Height", this.watchListChartHeight);

    },
    getDownloadName() {
      return `Market_Alert_Pro_Company_Report_${this.companyReport?.symbol}_${this.getToday_Date()}`
    },

    getToday_Date() {
      return moment().format('D_MM_YYYY')
    },

    getTodayDate() {
      return moment().format("D/MM/YYYY");
    },
    onProgress(event) {
      if (event == "100") {
        this.showPdfLoader = false;
      }
      console.log(`event processing ${event}`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.showPdfLoader = true;
      this.$refs.html2Pdf.generatePdf();
    },
    onResize() {
      this.watchListChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
      this.pdfChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 55;
      this.$nextTick(() => {
        this.watchListChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
        this.pdfChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 55;

      });
    },

    handleSearch() {
      this.findSearchVal(this.searchNodeKey);
    },

    wrapperSearch(searchVal, root, result, parentNodes = []) {
      for (const item of root) {
        let keyRef = [...parentNodes, item.key];
        if (item.title.toLowerCase() == searchVal.toLowerCase()) {
          result.push(keyRef);
        }

        if (item.children && item.children.length > 0) {
          this.wrapperSearch(searchVal, item.children, result, keyRef);
        }
      }
    },

    findSearchVal(val) {
      if (val) {
        let result = [];
        this.wrapperSearch(val, this.root, result);
        this.result = result;
        let baseNode;
        let dataNodes;
        console.log("result", this.result);

        for (const item of this.result) {
          baseNode = item[0];
          console.log("baseNode", baseNode);
        }

        this.navigateToTree(this.result[6], baseNode);
      } else {
        this.showInputError = true;
      }
    },

    getCompanyDetails(column) {
      return this.companyReport && this.companyReport[column] !== undefined && this.companyReport[column] !== null
        ? this.formatNumber(this.companyReport[column], column)
        : "N/A";
    },

    handleReport(x, column) {
      this.companyReport.ticker = x.symbol;
      this.companyReport.company_name = x.company_name;
      this.companyReport.volume = x.volume;
      this.companyReport.eps = x.eps;
      this.showFooter = true;
    },

    getTableValue(x, column) {
      // return x[column.key]

      switch (column.type) {
        case "number":
          if (x[column.key]) {
            return (typeof (x[column.key]) == "number" ? parseFloat(x[column.key]).toFixed(2) :
              x[column.key].toLocaleString("en-US"));
          }
          break;

        case "string":
          return x[column.key];

        case "percent":
          return `${x[column.key].toLocaleString("en-US")}%`;

        case "boolean":
          return x[column.key];

        case "decimal":
          return x[column.key].toFixed(2);

        case "float":
          if (!isNaN(parseFloat(x[column.key]))) {
            return parseFloat(x[column.key]).toFixed(2);
          } else {
            return "0.00";
          }

        case "date":
          const dateValue = new Date(x[column.key]);
          if (!isNaN(dateValue.getTime())) {
            const options = { day: "2-digit", month: "2-digit", year: "numeric" };
            return dateValue.toLocaleDateString("en-US", options);
          } else {
            return "";
          }

        default:
          return x[column.key];
      }

    },

    async getFivesTableData(nodeData) {
      this.isLoading = true;
      this.showError = false;
      this.nodeMeta = nodeData;
      const country = nodeData?.key.split("_")[0];
      this.county = nodeData?.key.split("_")[0];
      if (this.county == "us") {
        switch (nodeData.key) {
          case "us_capp_all":
            this.columnConfig = us_capp;
            break;

          case "us_candles_all":
            this.columnConfig = us_candle;
            break;

          case "us_etf_breakout":
          case "us_etf_all":

            this.columnConfig = us_etf_breakout;
            break;

          case "us_breakout_up":
          case "us_breakout_dn":
            this.columnConfig = us_breakout;
            break;

          default:
            this.columnConfig = us_ColumnConfig;
            break;
        }

      }
      if (this.county == "ca") {
        switch (nodeData.key) {
          case "ca_capp_all":
            this.columnConfig = ca_capp;
            break;

          case "ca_candles_all":
            this.columnConfig = ca_candle;
            break;

          case "ca_etf_breakout":
          case "ca_etf_all":
            this.columnConfig = ca_etf_breakout;
            break;

          case "ca_breakout_up":
          case "ca_breakout_dn":
            this.columnConfig = ca_breakout;
            break;

          default:
            this.columnConfig = ca_ColumnConfig;
            break;
        }
      }
      if (this.county == "oz") {

        switch (nodeData.key) {
          case "oz_capp_all":
            this.columnConfig = au_capp;
            break;

          case "oz_candles_all":
            this.columnConfig = au_candle;
            break;

          case "oz_etf_breakout":
          case "oz_etf_all":
            this.columnConfig = au_etf_breakout;
            break;

          case "oz_breakout_up":
          case "oz_breakout_dn":
            this.columnConfig = au_breakout;
            break;

          default:
            this.columnConfig = au_ColumnConfig;
            break;
        }
      }

      let criteriaByKey = [];
      let nodeParams = {
        key: nodeData.key,
        country: country,
        sorted: this.newSortVal,
        pagination: {
          length: this.length,
        },
      };
      console.log("node params", nodeParams);

      let response = await FivesApi.fetchFivesTableData(nodeParams);
      console.log("response1111", response);

      if (response.payload.length > 0) {
        console.log("greater than 0");
        // this.isLoading = false;
        this.fivesTableData = response.payload;
        this.getCompanyReport(this.fivesTableData[0]);
        this.columnConfig.map((columnConfigObject) => {
          if (
            this.fivesTableData[0][columnConfigObject.key] !== undefined
          ) {
            criteriaByKey.push(columnConfigObject);
          }
        });
        this.tableConfig = criteriaByKey;

        console.log("stocklist table data=====>", this.fivesTableData);
        console.log("table config", this.tableConfig);
      } else {
        console.log("in else===?>");
        // this.isLoading = false;
        this.showError = true;
        this.fivesTableData = [];
        this.showChartLoader = false;
      }
      this.isLoading = false;
    },



    async SaveCreateWatchlist() {
      let date = moment().format("MMMM Do YYYY, h:mm a");
      try {
        this.showModalLoader = true;
        const payload = {
          name: this.newWatchName,
          symbolList: this.getFivesData,
          date: date,
        };
        console.log(payload);

        const res = await watchListApi.createWatchList(payload);
        // console.log("res",res)

        if (res.data.success) {
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponse = "A new Watchlist has been created for your selected stocks.";
          setTimeout(() => {
            this.createWatclistModal = false;
            this.showModalMessage = false;
            this.showModalResponse = ""
            this.newWatchName = ""
            // this.getFivesData = [];
          }, 3000);
          const res1 = await watchListApi.fetchWatchListData();
          this.watch_List_Api_Data = res1.data.payload;

          console.log("this.watch_List_Api_Data", this.watch_List_Api_Data);
        } else {
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponseError = res.data.message;
          setTimeout(() => {
            this.showModalMessage = false;
            this.showModalResponseError = "";
          }, 3000);

          return
        }
        // const payload2 = {
        //   symbolList: this.getFivesData,
        //   watchId:
        //     this.watch_List_Api_Data[this.watch_List_Api_Data.length - 1]._id,
        // };
        // console.log("payload2", payload2);
        // const res2 = await watchListApi.updateWatchListRecord(payload2);
        // if (res2.data.success) {
        //   this.showModalMessage = true;
        //   this.showModalResponse = "A new Watchlist has been created for your selected stocks.";
        //   setTimeout(() => {
        //     this.createWatclistModal = false;
        //     this.showModalMessage = false;
        //   }, 2000);
        //   // this.createWatclistModal = false;
        //   this.getFivesData = [];
        // } else{
        //   this.showModalMessage = true;
        //   this.showModalResponse = res2.data.message;
        //   setTimeout(() => {
        //     this.showModalMessage = false;
        //     this.showModalResponse = "";
        //   }, 2000);
        // }
      } catch (err) {
        console.log(err);
        // this.createWatclistModal = false;
        this.showModalMessage = true;
        this.showModalLoader = false;
        this.showModalResponseError = "Server Error";
        setTimeout(() => {
          this.createWatclistModal = false;
          this.showModalMessage = false;
          this.showModalResponseError = "";
        }, 3000);
      }
    },

    navigateToTreeFromList(data, i) {

      this.favId = i;
      // this.currentFav.desc = data.desc;
      this.navigateToTree(data.idMapList, data.idMapList[0]);
    },
    navigateToTree(nodePath, baseNode) {
      // console.log('navigateToTree',nodePath,baseNode)
      // debugger;
      let refNode = this;
      let nodeid = nodePath[0];
      let startsWith = nodeid.startsWith(baseNode);
      const clickRef = (refNode, lastNode) => {
        // console.log('find node params',lastNode,refNode[0].nodeRef,refNode[0].expanded , refNode[0].isFolderType)
        if (refNode && refNode[0] && refNode[0].nodeRef) {
          if (refNode[0].expanded && refNode[0].isFolderType) {
            return refNode[0];
          }
          if (refNode[0].isActiveColor && refNode[0].isFileType) {
            return refNode[0];
          }
          refNode[0].nodeRef.click();
          // lastNode ? refNode[0].nodeRef.click() : refNode[0].nodeClicked()
          return refNode[0];
        }
      };
      let len = nodePath.length - 1;
      for (const id in nodePath) {
        nodeid = nodePath[id];
        if (startsWith && refNode) {
          refNode = refNode.$refs[nodeid];
          refNode = clickRef(refNode, len === Number(id));
        }
      }
    },
    treeLoad(baseNode) {
      console.log("tree load", baseNode);
      let x = localStorage.getItem("fiveNodePath");
      let nodePath = JSON.parse(x);
      if (nodePath.length === 2) {
        this.fiveNodePath = nodePath;
      }
      console.log("nodePath", nodePath);
      setTimeout(() => {
        this.navigateToTree(this.fiveNodePath, baseNode);
      }, 10);
    },

    treeEvent(node, map, id, idMap) {
      console.log("node check", node, idMap, map);
      this.getFivesData = []
      localStorage.setItem("fiveNodePath", JSON.stringify(idMap));

      if (this.singleInput == false) {
        this.singleInput = null;
      } else {
        this.singleInput = false;
      }
      this.checkAll = false;

      this.selectedStock = map;

      Tree_event.$emit("disableTree", idMap);

      if (node.title) {
        this.info = {
          // desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni commodi, distinctio unde quia rerum reiciendis id dolorem asperiores cumque deleniti culpa perspiciatis repellendus dolor non quos error sequi. Molestiae velit ea quae?',
          definition: node.definition,
          name: node.title,
        };
      }


      if (node.key && !node?.children) {
        let favDesc = map.join(">");
        let hasFav = this.favouriteList.find((d) => d.desc === favDesc);
        this.currentFavSaved = !!hasFav;
        this.currentFav = {
          idMapList: idMap,
          desc: favDesc,
          favColor: "",
        };
        console.log("cureent fav", this.currentFav);

        this.currentSort = {
          name: "",
          order: 0,
        };
        this.columnHighlight = null

        this.getFivesTableData(node);

        // this.getCompanyReport("RCM")
      } else if (node.key && node?.children) {
        console.log("children==>")
        let firstChild = node?.children[0]
        let newId = [node?.key, firstChild?.key]
        let newMap = [node?.title, firstChild?.title]
        this.selectedStock = newMap;
        if (firstChild?.title) {
          this.info = {
            // desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni commodi, distinctio unde quia rerum reiciendis id dolorem asperiores cumque deleniti culpa perspiciatis repellendus dolor non quos error sequi. Molestiae velit ea quae?',
            definition: firstChild?.definition,
            name: firstChild?.title,
          };
        }

        let favDesc = newMap?.join(">");
        let hasFav = this.favouriteList?.find((d) => d.desc === favDesc);
        this.currentFavSaved = !!hasFav;
        this.currentFav = {
          idMapList: newId,
          desc: favDesc,
          favColor: "",
        };
        this.currentSort = {
          name: "",
          order: 0,
        };
        this.columnHighlight = null
        localStorage.setItem("fiveNodePath", JSON.stringify(newId));
        this.getFivesTableData(firstChild);
      }
    },

    getTextColour(data) {
      console.log("textcolor", data);
      console.log("currentFav", this.currentFav);

      if (data.desc == this.currentFav.desc) {
        console.log("green");
        return "text-[green]";
      }
    },

    async AddToFav() {
      let map = this.favColorMap;
      let favLen = this.favouriteList.length;
      this.currentFavSaved = true;
      this.currentFav.favColor = map[favLen];
      let favouriteListApi = this.favouriteList;
      favouriteListApi.push(this.currentFav);
      if (await FivesApi.saveFavScans(favouriteListApi, "fives")) {
        this.favouriteList = favouriteListApi;
      }

      console.log("idMapList", this.currentFav);
    },
    async RemoveFromFav() {
      console.log("remove", this.currentFav);
      console.log("fav list", this.favouriteList);
      let filterData = this.favouriteList.filter(
        (d) => d.desc !== this.currentFav.desc
      );
      if (filterData > 0) {
        let map = this.favColorMap;
        let favouriteListApi = filterData.map((v, i) => {
          v.favColor = map[i];
        });
        if (await FivesApi.saveFavScans(favouriteListApi, "fives")) {
          this.favouriteList = favouriteListApi;
        }
      } else {
        if (await FivesApi.saveFavScans(filterData, "fives")) {
          this.favouriteList = filterData;
        }
      }
      this.currentFavSaved = false;
      console.log("idMapList", this.currentFav);
    },
    formatDate(d) {
      // console.log(moment(d).format("DD/MM/YYYY"))
      return moment(d).format("DD/MM/YYYY");
    },
    handleChange(value) {
      this.length = value;
      if (this.nodeMeta) this.getFivesTableData(this.nodeMeta);

      // this.getStockList(this.nodeMeta, value)
    },
    updateSort(value, sort, index) {
      this.columnHighlight = index;
      // if(this.columnHighlight.includes(index)){
      //   this.columnHighlight[index] = null
      //   console.log("match hua")
      // }
      // else{
      //   this.columnHighlight[index] = index
      // }

      if (this.nodeMeta) {
        let order = sort === 0 || sort === -1 ? 1 : sort === 1 ? -1 : 1;
        this.currentSort = {
          name: value,
          order: order,
        };
        if (value == 'volume') {
          value = 'volume_sort'
        }
        else if (value == 'mkt_cap_m') {
          value = 'mkt_cap_m_sort'
        }
        else if (value == 'company_name') {
          value = 'company_name_sorting'
        }

        let newSort = {};
        newSort[value] = order;

        this.newSortVal = newSort;
        console.log("sort", this.newSortVal);
        // this.getStockList(this.nodeMeta, newSort)
        this.getFivesTableData(this.nodeMeta);
      }
    },
    handleBackArrow() {
      if (this.perPage > 10) {
        this.page -= 1;
        this.perPage -= 10;
        console.log(this.perPage);
      }
    },
    handleForwardArrow() {
      if (this.perPage >= 0) {
        this.page += 1;
        this.perPage += 10;
        console.log(this.perPage);
      }
    },

    //     formatNumber(val) {
    //   const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;

    //   if (isNaN(num)) {
    //     return val; // Return the original value if it's not a valid number
    //   }

    //   const isNegative = num < 0;
    //   const absoluteValue = Math.abs(num);

    //   if (absoluteValue >= 1e12) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(1) + 'T';
    //   } else if (absoluteValue >= 1e9) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(1) + 'B';
    //   } else if (absoluteValue >= 1e6) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(1) + 'M';
    //   } else if (absoluteValue >= 1e3) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(1) + 'K';
    //   } else {
    //     return (isNegative ? '-' : '') + absoluteValue.toFixed(2);
    //   }
    // },

    // formatNumber(val,col=null) {
    //   const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
    //   let alwaysTwo = ['roe','op_mgn','ebitda','shares_out','shares_float','qrtly_rev_growth','peg_ratio','volume']
    //   let alwaysThree = ['split_ratio']

    //   if (isNaN(num)) {
    //     return val; // Return the original value if it's not a valid number
    //   }

    //   const isNegative = num < 0;
    //   const absoluteValue = Math.abs(num);


    //   let fixedValue;
    //   if(alwaysTwo?.includes(col)){
    //     fixedValue = 2
    //   }else if(alwaysThree?.includes(col)){
    //     fixedValue = 3
    //   }
    //   else if(absoluteValue < 1) {
    //     fixedValue = 3;
    //   } else {
    //     fixedValue = 2;
    //   }

    //   if (absoluteValue >= 1e12) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(fixedValue) + 'T';
    //   } else if (absoluteValue >= 1e9) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(fixedValue) + 'B';
    //   } else if (absoluteValue >= 1e6) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(fixedValue) + 'M';
    //   } else if (absoluteValue >= 1e3) {
    //     return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
    //   } else {
    //     if(isNegative){
    //       if(alwaysTwo?.includes(col)){
    //         return '-' + absoluteValue?.toFixed(2)
    //       }else if(alwaysThree?.includes(col)){
    //         return '-' + absoluteValue?.toFixed(3)
    //       }
    //       else{
    //         return '-' + absoluteValue?.toFixed(3)
    //       }
    //     }else{
    //       return absoluteValue.toFixed(fixedValue)
    //     }
    //   }
    // },

    NoDecimalFormatNumber(val) {
      const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
      if (isNaN(num)) {
        return val; // Return the original value if it's not a valid number
      }
      const isNegative = num < 0;
      const absoluteValue = Math.abs(num);
      if (absoluteValue >= 1e12) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(0) + 'T';
      } else if (absoluteValue >= 1e9) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(0) + 'B';
      } else if (absoluteValue >= 1e6) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(0) + 'M';
      } else if (absoluteValue >= 1e3) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(0) + 'K';
      } else {
        if (isNegative) {
          return '-' + absoluteValue?.toFixed(0)
        } else {
          return absoluteValue.toFixed(0)
        }
      }
    },

    formatNumber(val, col = null) {
      const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
      let alwaysTwo = ['volume_50d_average', '50_turnover_average', '10_turnover_average', '20_day_growth', 'div_%', 'highest_to_lowest', 'revenue', 'op_mgn', 'ebitda', 'roe', 'roi', 'shares_out', 'shares_float', 'mkt_cap', 'frank_pct_(au)', 'net_income', 'gross_margin', 'quick_ratio', 'free_cash_flow', 'revenue_change_1_yr', 'revenue_change_3_yr', 'div_growth_3_yr', 'div_avg_5_yr', 'payout_ratio', 'cash_per_share', 'interest_coverage', 'debt_to_equity_st', 'current_ratio', 'roa', 'annual_growth', 'half_year_growth', 'last_quarter_growth', 'revenue_growth_5_yrs', 'debt_growth_5_yrs', 'assets_to_liabilities_ratio', 'debt_to_equity_lt', 'biggest_gap_down_%', 'div_yield_1_yr', 'net_profit', 'net_debt', 'debt_growth_5_yrs', 'free_cash_flow', 'qrtly_rev_growth', 'peg_ratio']
      let alwaysThree = ['split_ratio']
      let noDecimal = ['open_=_close', '>5%_swings_count', 'cross_60d_ma_count', 'close_>_60d_ma', 'close_>_126d_ma', 'close_>_252d_ma', '60d_ma_>_200d_ma', 'gap_down_count']

      if (isNaN(num)) {
        return val; // Return the original value if it's not a valid number
      }

      const isNegative = num < 0;
      const absoluteValue = Math.abs(num);


      let fixedValue;
      if (alwaysTwo?.includes(col)) {
        fixedValue = 2
      } else if (alwaysThree?.includes(col)) {
        fixedValue = 3
      } else if (noDecimal?.includes(col)) {
        fixedValue = 0
      }
      else if (absoluteValue < 1) {
        fixedValue = 3;
      } else {
        fixedValue = 2;
      }

      // if(col == 'shares_float'){
      //  if(isNegative){
      //     return '-' + absoluteValue?.toLocaleString()
      //  }else{
      //     return absoluteValue?.toLocaleString()
      //  }
      // }else{
      if (absoluteValue >= 1e12) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(fixedValue) + 'T';
      } else if (absoluteValue >= 1e9) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(fixedValue) + 'B';
      } else if (absoluteValue >= 1e6) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(fixedValue) + 'M';
      } else if (absoluteValue >= 1e3) {
        if (col == 'free_cash_flow') {
          if (absoluteValue >= 10000) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
          } else {
            return (isNegative ? '-' : '') + absoluteValue?.toLocaleString();
          }
        } else {
          return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
        }
      } else {
        if (isNegative) {
          if (alwaysTwo?.includes(col)) {
            return '-' + absoluteValue?.toFixed(2)
          } else if (alwaysThree?.includes(col)) {
            return '-' + absoluteValue?.toFixed(3)
          } else if (noDecimal?.includes(col)) {
            return '-' + absoluteValue?.toFixed(0)
          }
          else {
            return '-' + absoluteValue?.toFixed(3)
          }
        } else {
          return absoluteValue.toFixed(fixedValue)
        }
      }


      // }


    },

    selectWatcRowIndex(e) {
      console.log(e.target.value);
      this.selectwatchIndex = e.target.value;
    },
    async SaveWatchlist() {
      this.showModalLoader = true;

      let date = moment().format("MMMM Do YYYY, h:mm a");
      try {
        const payload = {
          watchId: this.watch_List_Api_Data[this.selectwatchIndex]._id,
          symbolList: this.getFivesData,
          updatedDate: date,
        };
        console.log("add to watchlist", payload);

        const res = await watchListApi.updateWatchListRecord(payload);
        if (res.data.success) {
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponse = "Stock has been saved to your watchlist";
          setTimeout(() => {
            this.showWatchlistModal = false;
            this.showModalMessage = false;
            this.showModalResponse = ""
          }, 3000);
          // this.getFivesData = [];
        } else {
          this.showModalLoader = false;
          this.showModalMessage = true;
          this.showModalResponseError = res?.data?.message;
          setTimeout(() => {
            this.showModalMessage = false;
            this.showModalResponseError = "";
          }, 3000);
        }
      } catch (err) {
        this.showModalLoader = false;
        this.showModalMessage = true;
        this.showModalResponseError = "Server Error";
        setTimeout(() => {
          this.showWatchlistModal = false;
          this.showModalResponseError = "";
        }, 3000);
        console.log("market heat modal update-symbol-api destroyed");
      }
      // this.watchListIDs = []
    },

    async SaveWatchlistModal(type) {
      try {
        const res = await watchListApi.fetchWatchListData();
        this.watch_List_Api_Data = res.data.payload;
        console.log("...api in stoclist ", this.watch_List_Api_Data);
      } catch (err) {
        console.log("something went wrong", err);
      }
      if (type == "add") {
        this.showWatchlistModal = true;
      } else if (type == "create") {
        this.createWatclistModal = true;
      }
    },

    CheckRowTrue(checked, i, row) {
      console.log(".........", row);
      if (checked) {
        //exchange is undefined
        this.getFivesData.push({
          companyName: row.company_name,
          name: this.fivesTableData[i].symbol,
        });
        console.log("this.getFivesData", this.getFivesData);
        // this.watchListIDs.push(this.fivesTableData[i].symbol);
      } else if (!checked) {
        for (let v in this.getFivesData) {
          if (
            this.getFivesData[v].name === this.fivesTableData[i].symbol
          ) {
            this.getFivesData.splice(v, 1);
            // this.watchListIDs.splice(v, 1)
            break;
          }
        }
      }
    },

    async getFolders() {
      this.newConfig = this.root;

      console.log("new config", this.newConfig);

      // this.obj.folder = result.data
    },
    payload() { },
    onChange(result) { },
  },
  computed: {
    ...mapGetters(["signedInUser", "get_Selected_Stoks", "userIsNotPremium"]),
    favScanLength() {
      return this.currentFav.idMapList.length;
    },
    updateChart() {
      let updateChartDecision = this.symbolDataList?.length > 0;
      console.log("updateChartDecision", updateChartDecision);
      return updateChartDecision;
    },
  },
  watch: {
    pdfChartHeight(val) {
      console.log("pdf chart", val)
    },
    checkAll(newVal) {
      if (newVal) {
        this.getFivesData = [];
        // this.watchListIDs = []
        for (let i of this.fivesTableData) {
          this.getFivesData.push({
            name: i.symbol,
            companyName: i.company_name,
          });
        }
      } else {
        this.getFivesData = [];
        // this.watchListIDs = []
      }
    },
    length(val) {
      if (val && this.singleInput == false) {
        this.singleInput = null;
      } else {
        this.singleInput = false;
      }
      this.checkAll = false;
      // this.singleInput =  null
    },
    currentFav: (val) => {
      console.log("current saved:", val);
    },
    searchNodeKey() {
      this.showInputError = false;
    },
  },
  async mounted() {
    this.favouriteList = await FivesApi.getFavScans("fives");
    await this.getFolders();
    try {
      const res = await watchListApi.fetchWatchListData();
      this.watch_List_Api_Data = res.data.payload;
    } catch (err) {
      console.log("something went wrong", err);
    }

    window.addEventListener("resize", this.onResize);
    this.watchListChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
    this.pdfChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 55;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

};
</script>

<style scoped>
.grayGradient:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.bgGradient:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
  /* width: 20px !important; */
}

.cancel-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.container {
  display: block;
  position: relative;
  /* right: 2px; */
  cursor: pointer;
  width: 0.1em;
  height: 0.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.container input:checked~.checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}

.apexChartdiv .apexcharts-canvas {
  /* background-color:green; */
  width: fit-content !important;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
}

/* @media screen and (max-width: 900px) {
    .btnsize {
      margin-left: 10px;
    }
  } */
th {
  padding-right: 10px !important;
  padding-bottom: 10px;
}

td {
  padding-right: 10px !important;
}

.maindiv {}

/* [data-title]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
  }
  [data-title]:after {
      content: attr(data-title);
      background-color: #00FF00;
      color: #111;
      font-size: 150%;
      position: absolute;
      padding: 1px 5px 2px 5px;
      bottom: -1.6em;
      left: 100%;
      white-space: nowrap;
      box-shadow: 1px 1px 3px #222222;
      opacity: 0;
      border: 1px solid #111111;
      z-index: 99999;
      visibility: hidden;
  } */
[data-title]:hover {
  color: red;
}

.hideScroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 8px;
}

.disableTreeArea {
  pointer-events: none;
}
</style>
