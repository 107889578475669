const TreeStyle = {
  level_1: {
    text: {
      default: {
        color: "#ffffff"
      },
      hover: {
        color: "#ced4d6"
      },
      focus: {
        color: "red"
      },
      active: {
        color: "#7582785c"
      }
    },
    icon: {
      default: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      },
      hover: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      },
      focus: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      },
      active: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      }
    }
  },
  level_2: {
    text: {
      default: {
        color: "#ffffff"
      },
      hover: {
        color: "#60f542"
      },
      focus: {
        color: "red"
      },
      active: {
        color: "#048c22"
      }
    },
    icon: {
      default: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      },
      hover: {
        icon_class: "",
        icon_svg: "",
        color: "#60f542"
      },
      focus: {
        icon_class: "",
        icon_svg: "",
        color: "#ffffff"
      },
      active: {
        icon_class: "",
        icon_svg: "",
        color: "#048c22"
      }
    }
  }
}

  export default TreeStyle