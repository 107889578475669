<template>
  <div class="accordion  w-[90%]" id="accordionExample" @click="getmarketName()">
    <div class="accordion-item" @click="selectFolderr(title) ">
      <h2 class="accordion-header mb-0 " id="headingOne">
        <button v-on:click="isOpen = !isOpen"
          class="text-white flex items-center justify-between transition focus:outline-none w-full p-[.5vh]"
          @click="StoclistIndex(title, subChildListIndex)">

          <!-- :class="selectedclass(title,gett=this.text)" -->
          <div class="text-white flex items-center transition focus:outline-none gap-[.4vw]">

            <div>
              <font-awesome-icon class="w-[.4vw]" v-if="isOpen" icon="fa-solid fa-angle-down" />
              <font-awesome-icon class="w-[.4vw]" v-else icon="fa-solid fa-angle-right" />
            </div>

            <div v-if="type == 'main-folder'">
              <font-awesome-icon class="w-[.8vw]" v-if="isOpen" icon="fa-solid fa-folder-open" :class="iconColor" />
              <font-awesome-icon class="w-[.8vw]" v-else icon="fa-solid fa-folder" :class="iconColor" />
            </div>

            <div v-if="type == 'sub-folder'">
              <font-awesome-icon class="w-[.8vw]" v-if="isOpen" icon="fa-solid fa-folder-open" />
              <font-awesome-icon class="w-[.8vw]" v-else icon="fa-solid fa-folder" />
            </div>
            <!-- :style="(subChildListIndex == myValue) && (title == myValueText) ? {color:'blue'}:{}" -->
            <!-- :style="getValues" -->
            <div class=" flex justify-between gap-[.4vw]">
              <div class="text-[.7vw]">{{ title }}</div>
              <div class="italic text-[.6vw]" v-if="isOpen">{{this.gettext}}</div>
            </div>
          </div>



          <!-- img div -->

          <div>
            <img :src="image" />
          </div>
        </button>
      </h2>
      <div :class="isOpen ? 'd-block ml-[.8vw]' : 'hidden'">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccordionTree",

  data() {
    return {
      // renamefolderName : '',
      subMainFolderSelect:null,
      selectedStockArray: [],
      myValueIndex: -1,
      myValueText: '',
      isOpen: false,
      selectedfolder: "",
      text: "Selected",
      gettext: "",
      watchVariable: "",
    };
  },
  // watch: {
  //   isOpen() {
  //     console.log(this.isOpen);
  //   },
  // },
  methods: {
    
    StoclistIndex(text, index) {


      for (let a in this.mainObj.folder) {
        // console.log(this.mainObj.folder[a])

        if (this.folderType == this.mainObj.folder[a].name) {


          this.selectedStockArray = []
          this.selectedStockArray.push(this.folderType)
          this.$store.commit('GET_SELECTED_STOCKS',this.selectedStockArray)

          if (this.type == "sub-folder") {
            this.selectedStockArray = []
            this.selectedStockArray.push(this.folderType)
            this.selectedStockArray.push(this.mainObj.folder[a].subFolders[this.MainSubListIndex].name)
            this.$store.commit('GET_SELECTED_STOCKS',this.selectedStockArray)
          }
         else if(this.type=='child-folder'){
            this.selectedStockArray = []

            this.selectedStockArray.push(this.folderType)
            this.selectedStockArray.push( this.mainObj.folder[a].subFolders[this.MainSubListIndex].name)
            this.selectedStockArray.push( this.mainObj.folder[a].subFolders[this.MainSubListIndex].child[this.subChildListIndex].name)
            this.$store.commit('GET_SELECTED_STOCKS',this.selectedStockArray)

            }
        }
      }
      this.selectedStockArray = []




      this.myValueIndex = index
      this.myValueText = text
      // console.log("......index is ", this.myValueIndex)
      this.$emit('StockListSelectedStock', text, index)
    },


    selectFolderr(value,) {

      if (this.type == "main-folder") {
        this.$emit("mainfolder");
      } else if (this.type == "sub-folder") {
        this.$emit("subfolder");
      } else {
        const payload = {
          name: value,
          metaData: this.metaData,
        };
        this.$emit("subfile", payload);
      }


      this.$emit('Acc_FolderName', value, this.stockSearchItemIndex)
      //  let a = this.fileType[2]
      if (this.fileType == "StockSearch") {
        this.selectedfolder = value;
        //    console.log("selectFolderr",this.selectedfolder)
      }
    },
    selectedclass(value, text) {
      // console.log(value,this.gettext)
      if (this.fileType == "StockSearch") {
        this.gettext = text;
        if (this.selectedfolder == value && this.isOpen) {
          //      console.log("selectFolderr",value)
          return `bg-green-900 `;
        }

        else {
          return `'bg-[#1E2125]'`;
          // console.log("selectFolderr")
        }
      }
      //  if(this.fileType[2]){
      // return  this.selectedfolder == value && this.isOpen ? 'bg-green-900'  : 'bg-[#1E2125]'
      //  }
    },
  },

  props: {
    folderType: {
      type: String,
      default: ''
    },
    mainObj: {
      type: Object,
      default: null
    },
    MainListIndex: {
      type: Number,
      default: null
    },
    MainSubListIndex: {
      type: Number,
      default: null
    },
    subChildListIndex: {
      type: Number,
      default: null
    },
    stockSearchItemIndex: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: "",
    },
    title2: {
      type: String,
      default: "",
    },
    title3: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    metaData: {
      type: Object,
      default: () => { },
    },
    textColor: {
      type: String,
      default: "white",
    },
    fileType: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    dotsimage: {
      type: String,
      default: "",
    },
    showRenameField: {
      type: Boolean,
      default: false,
    },
    selectFolder: {
      type: Function,
      default: () => { },
    },
    selectedfolderName: {
      type: String,
      default: "",
    },
    selectedFolder: {
      type: Function,
      default: () => { },
    },

  },


  computed: {
    ...mapGetters(["getSelectedFolder", 'get_Selected_Stoks']),

    getValues() {
      if (this.type == "child-folder") {
        if (this.isOpen == true) {
          return `color : white;`;
        }
      }
      return { color: this.textColor };
    },
  },
  watch: {
    watchVariable(newValue) {
      this.$store.dispatch("selectedfolderAction", 'this.selectedfolder');
    },
    getSelectedFolder() {
      this.watchVariable = this.getSelectedFolder;
    },
  },
  mounted() {

  },
};
</script>
