<template>
   <div class="sm:w-8/12 w-full sm:px-0 px-4">
        <div class="w-full flex justify-end">
          <span
            class="text-white text-[8px] flex items-center border border-solid border-[#fff] rounded-md px-2"
            >Powered by
            <span class="text-[14px] ml-1 font-bold"> stripe</span></span
          >
        </div>
        <div class="w-full flex justify-center mt-4">
          <div class="border-t border-gray-800 pt-6">
            <span class="text-[#FFFFFF] font-bold text-[18px]"
              >Important Notice</span
            >
            <p
              class="sm:text-[12px] text-[10px] text-gray-200 sm:mt-8 mt-2 text-justify font-light"
            >
            Market Alert Pro is an Authorised Representative (No. 001297846) of Equity Analyst Pty Ltd - ACN 60643403285 and AFSL 534455. The Market Alert Pro website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. Market Alert Pro are not financial advisors, and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily, and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.
            <!-- The Market Alert Pro website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. Market Alert Pro are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose. -->
              
            </p>
          </div>
        </div>

        <div
          class="w-full mt-12 flex justify-center flex-col self-center items-center"
        >
          <span class="text-[#FFFFFF] text-[12px]"
            ><span class="text-[#00c92e]">Market Alert Pro</span> ©
            {{ fullYear() }} All Rights Reserved.</span
          >
          <FooterCompanyLinks />
        </div>
      </div>
</template>

<script>
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";

export default {

    components:{
        FooterCompanyLinks
    },

    methods:{
        fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    }
}
</script>

<style>

</style>