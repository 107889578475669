// const domain_prefix = "http://localhost:4000/";
  // const domain_prefix = "http://207.148.85.228:4000/"
// const domain_prefix = "http://192.168.0.100:4000/";
// const domain_prefix = "http:139.180.180.87:4000/";
import Config from "@/api/config";

const domain_prefix = Config.mapApi
const job_api = Config.jobApi


export const bellIcon = domain_prefix + "user/notify-email"
export const tableData = domain_prefix + "user/get_marketwatch_data";
export const stockAlertableData = domain_prefix + "user/get_stock_alert_data";
export const loadDates = domain_prefix + "date/indivualData";
export const saveDates = domain_prefix + "date/SaveDates";
export const deleteDates = domain_prefix + "date/deleteDates";
export const resetPassword = domain_prefix + "user/new_password";
export const forgetPassword = domain_prefix + "user/forget_password";
export const USER_LOGOUT = domain_prefix + "user/logout";
export const signUp = domain_prefix + "user/register";
export const signUpRe = domain_prefix + "user/signup";
export const sent_verification_email = domain_prefix + "user/verificationEmail";
export const verify_token = domain_prefix + "user/verifytoken";
export const miss_email = domain_prefix + "user/missVerificationEmail";
export const user_further_details = domain_prefix + "user/userdetails";
export const subscribe_To_Package = domain_prefix + "user/subscribeToPackage";
export const signIn = domain_prefix + "user/login";
export const LINK_EXPIRE_CHECKER = domain_prefix+"user/linkExpireChecker"

export const MARKET_HEAT_TOP_FIVE = domain_prefix + "marketHeat/getTopFive";
export const STOCK_LIST_PLUS = domain_prefix + "stock/getStockList";
export const STOCK_LIST_PLUS_BETA = domain_prefix + "stock/getStockListBeta";

export const FIVES_BETA = domain_prefix + "stock/getFivesData";

export const SAVE_FAV_SCANS = domain_prefix + "stock/saveFavScans";
export const GET_FAV_SCANS = domain_prefix + "stock/getFavScans";
export const GET_FOLDERS = domain_prefix + "stock/getFolder";
export const GET_FOLDERS_STOCKLIST = domain_prefix + "stock/getStockListFolder";
export const GET_INDICIES = domain_prefix + "stock/getIndicies";
export const GET_INDEX_LIST = domain_prefix + "stock/getIndexData";

export const FIVES_FOLDER_LIST = domain_prefix + "stock/getFivesFolder";

export const GET_COMPANY_REPORTS = domain_prefix + "companyreport/getCompanyReport";
export const GET_COMPANY_REPORTS_STOCKALERT = domain_prefix + "companyreport/getCompanyReportStockAlert";

export const MARKET_HEAT_GET_HEAT_DATA =
  domain_prefix + "marketHeat/get_market_heat_data";
export const GET_MARKET_HEAT_COMMENT =
  domain_prefix + "marketHeat/get_market_heat_comment";
export const GET_MARKET_HEAT_COMMENT_T =
  domain_prefix + "marketHeat/get_market_heat_commentT";
export const SAVE_MARKET_HEAT_COMMENT =
  domain_prefix + "marketHeat/save_market_heat_comment";
export const SAVE_MARKET_HEAT_COMMENT_T =
  domain_prefix + "marketHeat/save_market_heat_commentT";
export const DELETE_MARKET_HEAT_COMMENT =
  domain_prefix + "marketHeat/delete_market_heat_comment"; 
export const GET_ALL_MARKET_HEAT_COMMENT =
  domain_prefix + "marketHeat/getAll_market_heat_comment"; 
// export const GET_ALL_MARKET_HEAT_COMMENT_T =
//   domain_prefix + "marketHeat/getAll_market_heat_commentT"; 

export const UPDATE_CHART_CONFIG = domain_prefix + "user/UpdateChartConfig";
export const GETUSER = domain_prefix + "user/getUser";
export const UPDATEUSER = domain_prefix + "user/update-user";
export const CHANGEPASSWORD = domain_prefix + "user/change-password"
export const UPDATEPRODUCT = domain_prefix + "stripe/update-product"
export const USERDATASTREAM = domain_prefix + "user/change-data-stream"
export const USERCONTACT = domain_prefix + "user/complain-form"
export const APPLY_PROMOCODE = domain_prefix + "stripe/get-promo-details"


export const STRIPE_PRODUCT_LIST = domain_prefix + "stripe/stripeProduct";
export const STRIPE_ADD_CARD = domain_prefix + "stripe/addCard";
export const STRIPE_UPDATE_SUBSCRIPTION =
  domain_prefix + "stripe/updateSubcribtion";
export const MAKE_DEFAULT = domain_prefix + "stripe/makeDefault";

export const STRIPE_INVOICES = domain_prefix + "stripe/get-invoices";
export const CANCEL_SUBSCRIPTION = domain_prefix+"stripe/cancel-subscription";
export const GET_COSTOMER_DETAILS = domain_prefix+"stripe/get-customer-details";


export const UPDATE_MARQUEE = domain_prefix + "stock/updateBannerSettings"
export const GET_SNAP_QUOTES = domain_prefix + "stock/snapQuote";
export const UPDATE_VARIENT = domain_prefix + "user/updateVarient"
export const ALERT_COLUMN_FOR_USER = domain_prefix + "user/addAlertColumns"
export const SAVE_INDEX_DATA = domain_prefix + "user/saveIndex"

// twilio 
export const SEND_OTP = domain_prefix + "twilio/send-otp"
export const VERIFY_OTP = domain_prefix + "twilio/verify-otp"
export const UPDATE_PHONE = domain_prefix + "user/update-phone"
export const VERIFY_PASSWORD = domain_prefix + "user/verify-password"
export const SEND_EMAIL_TO_CHANGE_EMAIL = domain_prefix + "user/send-email-to-change-email"
export const VERIFY_CHANGE_EMAIL_CODE = domain_prefix + "user/verify-change-email-code"
export const CHANGE_TWO_FA = domain_prefix + "user/enable-2FA"
export const DISABLED_TWO_FA = domain_prefix + "user/disable-2FA"
export const VERIFY_TWO_FA = domain_prefix + "user/verify-code"
export const ADD_BREADCRUMB = domain_prefix + "user/save-previous-search"
export const GET_BREADCRUMB = domain_prefix + "user/get-previous-search"

export const ALL_JOBS = job_api + "admin/get-configuration"
export const DATA_DELAYED = domain_prefix + "admin/get-data-delay"