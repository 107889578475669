<template>
  <div class="c-wrapper w-full">
    <span class="calendar" @mouseup="mouseUp" @mouseleave.stop="mouseUp">
      <div class="calendar__title " @click="monthClickEvent(year)">
        {{ monthTitle }}
      </div>
      <span class="calendar__body">
        <span
          v-for="(day, key) in 7"
          :key="`title${day}`"
          class="calendar__day day__weektitle"
          :style="{ fontSize: weekTitleFontSizeAdjustLang }"
          >{{ showDayTitle(key) }}</span
        >
        <span
          v-for="(dayObj, key) in showDays"
          class="calendar__day"
          :key="`day${key}`"
        >
          <span
            @mouseover="dragDay(dayObj)"
            @mousedown="mouseDown(dayObj)"
            class="day"
            :class="classList(dayObj)"
            >{{ dayObj.value }}</span
          >
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "month-calendar",
  props: {
    activeDates: {
      type: Array,
      default: () => [],
    },
    month: {
      type: [String, Number],
      default: () => dayjs().month() + 1,
    },
    year: {
      type: [String, Number],
      default: () => dayjs().year(),
    },
    lang: {
      type: String,
      default: "en",
    },
    activeClass: {
      type: String,
      default: () => "",
    },
    prefixClass: {
      type: String,
      default: () => "calendar--active",
    },
  },
  data() {
    return {
      showDays: [],
      isMouseDown: false,
    };
  },
  computed: {
    weekTitleFontSizeAdjustLang() {
      const fontSizeMapping = {
        en: "9px",
      };
      return fontSizeMapping[this.lang];
    },
    monthTitle() {
      const monthMapping = {
        en: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      };
      return monthMapping[this.lang][this.month - 1];
    },
  },
  methods: {
    initCalendar() {
      console.log("this is the yea:", this.year);
      if (!this.year || !this.month) return [];
      const activeMonth = dayjs()
        .set("date", 1)
        .set("year", this.year)
        .set("month", this.month - 1);
      let firstDay = activeMonth.startOf("month").day();
      if (firstDay < 0) firstDay += 7;
      const lastDate = activeMonth.endOf("month").date();
      const weekRow = firstDay >= 5 ? 6 : 5;
      const WEEK = 7;
      let day = 0;
      const fullCol = Array.from(Array(weekRow * WEEK).keys()).map((i) => {
        let value = firstDay <= i ? (day++ % lastDate) + 1 : "";
        return {
          value,
          active: false,
          isOtherMonth: firstDay > i || day > lastDate,
        };
      });
      this.showDays = fullCol;

      // 把 toggleDate 的內容合併在 initCalendar 裡。
      this.activeDates.forEach((date) => {
        let oDate;

        if (typeof date === "string") {
          oDate = {
            date: date,
            className: this.activeClass,
          };
        } else if (typeof date === "object") {
          oDate = date;
        }

        let dayjsObj = dayjs(oDate.date);
        if (dayjsObj.year() !== this.year) return;
        let activeDate = dayjsObj.date();
        let row = Math.floor(activeDate / 7);
        let activeArrayKey = (activeDate % 7) - 1 + firstDay + 7 * row;
        this.showDays[activeArrayKey].active = true; // to array index
        this.showDays[activeArrayKey].className = oDate.className;
      });
    },
    showDayTitle(day) {
      const dayMapping = {
        en: ["S", "M", "T", "W", "T", "F", "S"],
      };
      return dayMapping[this.lang][day];
    },
    toggleDay(dayObj) {
      console.log("month obj:", dayObj);
      if (dayObj.isOtherMonth) return;
      this.$emit("toggleDate", {
        month: this.month,
        date: dayObj.value,
        selected: !dayObj.active,
        className: this.activeClass,
      });
    },
    dragDay(dayObj) {
      if (this.isMouseDown) this.toggleDay(dayObj);
    },
    mouseDown(dayObj) {
      this.toggleDay(dayObj);
      this.isMouseDown = true;
    },
    mouseUp() {
      this.isMouseDown = false;
    },
    classList(dayObj) {
      let oClassList = {
        "calendar__day--otherMonth": dayObj.isOtherMonth,
        [this.prefixClass]: dayObj.active,
      };

      if (dayObj.active) oClassList[dayObj.className] = true;

      return oClassList;
    },
    monthClickEvent(year) {
      let monthYearInfo = {
        monthTitle: this.monthTitle,
        month: this.month,
        year: year,
      };
      this.$emit("monthClickEvent", monthYearInfo);
    },
  },
  watch: {
    year() {
      this.initCalendar();
    },
    // 外層來的資料有變化時
    activeDates() {
      this.initCalendar();
    },
  },
  created() {
    this.initCalendar();
  },
};
</script>

<!-- <style  scoped> -->
<style lang="stylus" scoped>
.calendar {
  width: 23.333%;

  text-align: center;
  color: rgba(229, 233, 236, 0.7);
  border-radius: 2px;
  position: relative;
  text-decoration: none;

  .calendar__title {
    font-weight: 100;
    align-items: center;
    justify-content: center;
    font-size: .60vw;
    height: 1.5vh;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .calendar__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    
  }

  .calendar__day {
    flex: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .49vw !important;
    height: fit-content;
    color: white;
    
  }

  .day__weektitle {
    color: rgba(229, 233, 236, 0.7);
  }

  .day {
    font-size: .43vw;
    padding: 0px;
    cursor: pointer;
    user-select: none;
    width: .57vw;
    height: fit-content;
    display: flex;
    flex-grow:1;
    justify-content: center;
    align-items: center;
    position: relative;
    color: rgba(229, 233, 236, 0.7);
    margin-bottom:.2vh;



    &:after {
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      z-index: 1;
      background-color: transparent;
    }

    &.calendar--active:after {
      background-image: url('../assets/baseline-remove_circle-24px.svg');
      background-size: 100% 100%;
    }

    &.calendar--active {
      background-color: rgba(#FFBABA, 0.5);
      color: #BCBCBC;

      &.info {
        background-color: rgba(#17a2b8, 0.8);
        color: #fff;

        &:after {
          background-image: url('../assets/RecordIt.svg');
          background-size: 100% 100%;
        }
      }

      &.warning {
        background-color: rgba(#ffc107, 0.7);
        border: red;
        color: #fff;

        &:after {
          background-image: url('../assets/round-warning-24px.svg');
          background-color: rgba(#eaeaea, 0.3);
          background-size: 100% 100%;
        }
      }
    }
  }

  & .calendar__day--otherMonth {
    color: rgba(229, 233, 236, 0.2);
    cursor: auto;
  }
}
</style>
