import axios from "../axios";
import {
  WATCH_LIST_DATA,
  SYMBOL_LIST_UPDATE,
  SYMBOL_LIST_REMOVE,
  CREATE_WATCH_LIST,
  GET_SINGLE_WATCH_LIST,
  REMOVE_WATCH_LIST,
  COPY_SYMBOLS,
  MOVE_SYMBOLS,
  SAVE_TABLE_COLUMNS,
  GET_TABLE_COLUMNS_LIST,
  SAVE_LAST_COLUMNS_LIST,
  GET_LAST_COLUMNS_LIST,
  DELETE_TABLE_COLUMN,
  UPDATE_TABLE_COLUMN
} from "./priceApiRoutes";
import config from "@/api/config";
import list from "@/api/mocks/watchlist/list";

export default {
  fetchWatchListData: async () => {
    // console.log("params",params)
    return config.mockingNewFeature
      ? list
      : await axios.get(`${WATCH_LIST_DATA}`);
  },
  fetchSingleWatchListData: async (params) => {
    console.log("params of watch list:",params)
    return config.mockingNewFeature
      ? list
      : await axios.get(`${GET_SINGLE_WATCH_LIST}/${params}`);
  },

  removeWatchListRecord: async (payload) => {
    console.log("remove from api ", payload)
    return config.mockingNewFeature
      ? list
      : await axios.delete(SYMBOL_LIST_REMOVE, { data: payload });
  },

  updateWatchListRecord: async (payload) => {
    console.log("update watchlist ..........................................owais", payload)
    return config.mockingNewFeature
      ? list
      : await axios.put(SYMBOL_LIST_UPDATE, payload);
  },
  createWatchList: async (payload) => {
    console.log("createWatchList ", payload)
    return config.mockingNewFeature
      ? list
      : await axios.post(CREATE_WATCH_LIST, payload);
  },
  deleteWatchList: async (payload) => {
    return config.mockingNewFeature
      ? list
      : await axios.delete(REMOVE_WATCH_LIST, {data: payload});
  },
  saveTableColumn: async (payload) => {
    return config.mockingNewFeature
      ? list
      : await axios.post(SAVE_TABLE_COLUMNS,payload);
  },
  saveLastColumn: async (id) => {
    return config.mockingNewFeature
      ? list
      : await axios.post(SAVE_LAST_COLUMNS_LIST,{last_selection_id:id});
  },
  getLastColumn: async () => {
    return config.mockingNewFeature
      ? list
      : await axios.get(GET_LAST_COLUMNS_LIST);
  },
  deleteColumn: async (id) => {
    return config.mockingNewFeature
      ? list
      : await axios.delete(`${DELETE_TABLE_COLUMN}/${id}`);
  },
  editColumn: async (payload) => {
    return config.mockingNewFeature
      ? list
      : await axios.post(UPDATE_TABLE_COLUMN,payload);
  },
  getTableColumnList: async () => {
    return config.mockingNewFeature
      ? list
      : await axios.get(GET_TABLE_COLUMNS_LIST);
  },

  copySymbols: async (payload) => {
    console.log("move symbols ", payload)
    return config.mockingNewFeature
      ? list
      : await axios.post(COPY_SYMBOLS, { data: payload });
  },

  moveSymbols: async (payload) => {
    console.log("move symbols ", payload)
    return config.mockingNewFeature
      ? list
      : await axios.post(MOVE_SYMBOLS, { data: payload });
  },


};
