<template>
<tailwind-modal :show-modal="createWatchListState" v-on:change="toggleModal" :showHeader="false">
    <div class="min-w-[38rem] px-10 py-4" style="
        border: 0.3px solid rgba(2, 189, 45, 0.3);
        box-sizing: border-box;
        scrollbar-color: red;
        border-radius: 3px;
        background-color: #151515;
      ">
        <div class="flex pb-4">Create a new Watchlist</div>

        <div class="pb-10">
            <input v-on:keyup.enter="handleCreateWatchList" type="text" class="p-4 flex bg-transparent block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500" placeholder="Please Enter Name." v-model="watchListName" />
            <span v-if="showError" class="text-[16px] text-[#FF0A00] text-center">{{error}}</span>
        </div>

        <div class="buttons flex justify-end">
            <!-- <button
          type="submit"
          @click="toggleModal"
          class="bg-red-500 mr-2 hover:bg-red-800 px-8 mt-6 font-semibold py-2 text-sm"
        >
          Close
        </button>

        <button
          type="submit"
          @click="handleCreateWatchList"
          class="bg-green-500 hover:bg-green-800 px-8 mt-6 font-semibold py-2 text-sm"
        >
          Save
        </button> -->
            <button type="submit" @click="toggleModal" class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm">
                Cancel
            </button>

            <button type="submit" @click="handleCreateWatchList" class="ok-button px-8 mt-6 font-semibold py-2 text-sm">
                Save

            </button>
        </div>
    </div>
</tailwind-modal>
</template>

<script>
import TailwindModal from "@/components/popups/TailwindModal";
import watchListApi from "../../../api/watchListApi";
import moment from "moment"
export default {
    name: "CreateWatchModal",
    components: {
        TailwindModal
    },
    data() {
        return {
            watchListName: "",
            error: "",
            showError: false,
        };
    },
    props: {
        createWatchListState: {
            type: Boolean,
            default: true,
        },
        userId: {
            type: String,
            default: "",
        },
        watchId: {
            type: String,
            default: "256862548",
        },
        userName: {
            type: String,
            default: "",
        },
        symbolList: {
            type: Array,
            default: []
        }
    },
    methods: {
        toggleModal() {
            this.watchListName = ""
            this.error = ''
            this.$emit("close");
        },
        showErrorMsg(error) {
            this.showError = true
            this.error = error
            // setTimeout( () => {
            //   console.log("showErrorMsg timeout")
            //   this.showError = false
            //   this.error = ""
            // },2000)
        },
        handleCreateWatchList: async function () {

            
             if (this.watchListName && this.watchListName.length < 16 && this.watchListName.replace(/^\s+|\s+$/g, "").length != 0) {
                // let date = moment().format('MMM, DD YYYY');
                let date = moment().format('MM/DD/YYYY');
                try {
                    const payload = {
                        name: this.watchListName,
                        // symbolList: this.symbolList,
                        date: date,

                    };
                    console.log("save event", payload)
                    const res = await watchListApi.createWatchList(payload);
                    if (res.data.success) {
                        // console.log(res)
                        this.watchListName = ""
                        this.$emit("createWatchList", res.data.data);
                        this.$emit("close");

                    } else {
                        this.showErrorMsg(res?.data?.message)
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                this.showErrorMsg("Kindly write watchlist name with in a 15 characters")
                // console.log("select Symbol list");
            }
        },
    },

    watch: {
        watchListName: function () {
            this.showError = false
            this.error = ""
        }

    },

    mounted() {

    }
};
</script>

<style scoped>
.ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
}

.ok-button:hover {
    background: linear-gradient(270deg,
            rgba(2, 189, 45, 0.24) 0%,
            rgba(2, 189, 45, 0) 50%,
            rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
    border: 0.6px solid #f8f9f9;
    border-radius: 3px;
    color: #f8f9f9;
    text-transform: uppercase;
}

.cancel-button:hover {
    background: linear-gradient(270deg,
            rgba(248, 249, 249, 0.21) 0%,
            rgba(248, 249, 249, 0) 52.08%,
            rgba(248, 249, 249, 0.21) 100%);
}
</style>
