<template>
    <div class="">
      <!-- tokenExpired -->
    <div v-if="tokenExpired">
        <div class="mainafter h-[100vh]">
            <div class="px-4 flex justify-center items-center h-full w-full relative">
                <AlertLong alertType="Danger" closeAlert="false" title="Link Expired">
                    <p class="text-white flex items-center justify-center h-full mt-2">
                        Oops! it seems your link is not valid
                    </p>
                </AlertLong>
            </div>
        </div>
    </div>
    </div>
  
  </template>
  
  <script>
  import router from "@/router";
  import axios from "axios";
  import userApi from "../../api/userApi";
  import {verify_token} from "@/api/apiRoutes"

  import "../customcss/index.css"
  import AlertLong from "../utilities/AlertLong.vue";
  
  export default {
    data() {
      return {
        tokenExpired:false,
      };
    },
    computed: {
     
    },
    components: {
      AlertLong,
    },
    methods:{
    
    async verifyToken() {
            let result = await axios.post(verify_token, {
                token: this.userVerificationToken,
            });
            if (!result.data.success) {
              console.log("expired")
                this.tokenExpired = true;
            }else{
                router.push(`/verification?Token=${this.userVerificationToken?.Token}`);
            }
        },

  
  },
  
  async mounted() {
        let route = this.$route.query;
        console.log("route", route);
        let len = Object.keys(route).length;
        if (len > 0) {
            this.userVerificationToken = route;
            await this.verifyToken();
        } else {
            this.tokenExpired = true;

        }
    },
    beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
  </script>
  
  <style scoped>
  
  
  a{
    color:#02BD2D;
  }
  a:hover{
    text-decoration: underline !important;
    text-decoration-color: #02BD27 !important;
  }
  input:focus{
  border: 1px solid #02BD27 !important;
  }
  .main {
    /* background-image: url("../../assets/map-main-background.jpg"); */
    background-image: url("../../assets/map-main-background.jpg");
  
    background-color: black !important;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    background-attachment: fixed;
    
    height: auto; 
  }
  
  .mainafter {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mainafter::before {
    content: "";
    background-image: url("../../assets/map-main-background.jpg");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  }
  
  /* .mainafter {
    position: relative;
    height: 100vh;
    width: 100%;
    
   
  }
  
  .mainafter::before {
    content: "";
    
    background-image: url("../../assets/map-main-background.jpg");
    background-size: cover;
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  } */
  .footer::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
  .checkbox-round {
    width: 0.9em;
    height: 0.9em;
    background-color: black;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid gray;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  
  .ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
  }
  
  .ok-button:hover {
    background: linear-gradient(270deg,
        rgba(2, 189, 45, 0.24) 0%,
        rgba(2, 189, 45, 0) 50%,
        rgba(2, 189, 45, 0.24) 100%);
  }
  
  .checkbox-round:checked {
    background-color: #02bd2d;
  }
  
  .btnDefault{
    pointer-events: none;
  }
  .footerlink{
    color:#00c92e !important;
  
  }
  
  .footerlink:hover{
    color: #3ef705 !important;
    text-decoration: none !important;
  
  }
  </style>
  