
const AccountSettingsModule = {
    state: {
     Address:{
        line1:'',
        line2:'',
        city:'',
        country:'',
        state:'',
        postal_code:'',
     },
     newCard:[],
     cardStore:[],
     defaultCardId:null
    },
  
    mutations: { 
        ADDRESS_SET(state, payload){
            state.Address = payload
        },
        NEW_CARD_SET(state, payload){
            state.newCard = payload
        },
        CARD_STORE_SET(state, payload){
            console.log('hardik store payload:',payload);
            state.cardStore = payload
        },
        CHANGE_DEFAULT_CARD(state, payload){
            if (payload.isNewCard) {
                const updatedCards = state.newCard.map(card => {
                    if (card.cardId === payload.cardId) {
                      return {
                        ...card,
                        isDefault: true
                      };
                    }
                    return {
                      ...card,
                      isDefault: false
                    };
                  });
                  state.newCard = updatedCards
            }
            else{
                state.defaultCardId = payload.cardId
            }
        }
    },
  
    getters: {
      address_get: state => state.Address,
      newCard_get: state => state.newCard,
      cardStore_get: state => state.cardStore,
      defaultCard_get: state => state.defaultCardId,
    },
  };
  
  export default AccountSettingsModule;
  