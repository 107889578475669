<template>
  <div class="main min-h-screen">
    <div class="flex justify-center flex-col items-center w-full pt-20">
      <div
        role="status"
        class="flex justify-center w-full pt-12"
        v-if="mainLoader"
      >
        <div class="flex pt-28 justify-center w-full items-center text-center">
          <svg
            class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="text-white text-sm ml-2">please wait...</span>
        </div>
      </div>
      <div v-if="passwordChanged" class="relative flex justify-center">
        <div
          class="px-4 flex-col space-y-6 justify-center items-center sm:w-8/12 w-full relative"
        >
          <div class="grid justify-items-stretch">
            <img
              class="sm:w-9/12 w-full justify-self-center"
              :src="require('../../assets/mapWhite.svg')"
            />
          </div>
          <AlertLong
            alertType="Success"
            closeAlert="false"
            title="PASSWORD UPDATED"
          >
            <p class="text-white h-full">
              Congrats! Your password has been updated successfully.
              <router-link to="/">
                <span
                  :class="
                    'text-[' +
                    pageColors.MAP_GREEN +
                    '] cursor-pointer mx-1 font-bold'
                  "
                  >click here</span
                ></router-link
              >
              to sign in again!
            </p>
          </AlertLong>
        </div>
        <!--  -->
      </div>
      <!-- linkExpired -->
      <div v-if="linkExpired" class="flex justify-center">
        <div
          class="px-6 flex-col space-y-6 justify-center items-center sm:w-8/12 w-full relative sm:px-4"
        >
          <div class="grid justify-items-stretch">
            <img
              class="sm:w-7/12 w-full justify-self-center"
              :src="require('../../assets/mapWhite.svg')"
            />
          </div>

          <AlertLong alertType="Danger" closeAlert="false" title="Link Expired">
            <p class="text-white h-full sm:text-[16px] w-full text-[13px]">
              Oops! It seems that following link has expired, but dont worry you
              can get your updated link by sending us an email, please
              <router-link to="/recoverpassword">
                <span
                  :class="
                    'text-[' +
                    pageColors.MAP_GREEN +
                    '] cursor-pointer mx-1 font-bold'
                  "
                  >click here</span
                ></router-link
              >
            </p>
          </AlertLong>
        </div>
      </div>

      <!-- linkNotExpired -->
      <div
        class="relative -bg-green-300 sm:pt-0 pt-8 sm:w-fit w-full sm:px-0 px-6"
        :class="loaderState ? 'pointer-events-none' : null"
        v-if="linkNotExpired"
      >
        <div class="">
          <router-link to="/">
            <img class="w-full" :src="require('../../assets/mapWhite.svg')" />
          </router-link>
        </div>

        <div>
          <h6 class="text-[#e5e9ec] font-semibold">
            No worries, its easy to reset your password.
          </h6>
        </div>
        <div>
          <h4 class="text-[#02bd2d] mt-2 font-semibold text-lg">
            Enter your new password
          </h4>
        </div>

        <div class="text-gray-300">
          <AlertShort
            class="mt-2 w-[385px]"
            v-if="alertTrue"
            :alertType="alertType"
          >
            <p class="text-sm text-white">{{ alertMessage }}</p>
          </AlertShort>
        </div>
        <div>
          <div class="form-group flex flex-row mt-4">
            <!-- <div>
            <input
              :type="type"
              id="password"
              ref="password"
              v-model="password"
              class="p-4 bg-[#151515] block w-96 text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
              placeholder="Password"
            />
          </div>
          <div>
            <div class="">
              <font-awesome-icon
                @click="showPassword"
                class="text-white mt-5 -ml-8"
                style="color: whitesmoke; height: px; cursor: pointer"
                :icon="['fas', iconName]"
                :disabled="disabled"
              />
            </div>
          </div> -->

            <!--  -->
            <div
              style="border: 0.6px solid #7582787d"
              class="form-group rounded-md lg:w-full w-full -bg-red-300 justify-between flex mt-4"
              :class="showOutline ? 'focus' : ''"
              ref="password"
            >
              <div class="w-10/12">
                <input
                  @keyup.enter="Forgetdone"
                  @focus="handleOutline"
                  @blur="removeOutline"
                  :type="type"
                  id="password"
                  v-model="password"
                  placeholder="Choose a password*"
                  :disabled="disabled"
                  class="w-full bg-transparent -h-[5vh] p-4 box-border -text-[.8vw] text-[#ffffff] rounded-md focus:outline-none"
                />
              </div>
              <div class="flex items-center z-50 justify-center w-2/12">
                <font-awesome-icon
                  @click="showPassword('password')"
                  class="text-white -w-[1vw]"
                  style="color: whitesmoke; height: px; cursor: pointer"
                  :icon="['fas', iconName]"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
          <p
            v-if="this.validationObject.password.success === false"
            :class="'text-[' + pageColors.MAP_RED + ']'"
          >
            {{ validationObject.password.message }}
          </p>
          <div
            class="w-full -md:w-64 -w-48 h-[.1vh] bg-transparent flex justify-between mt-2"
          >
            <div class="w-[100%] h-[.1vh] bg-transparent flex justify-between">
              <div
                class="h-full w-[19%] rounded-xl"
                v-for="(n, i) in 5"
                :class="
                  passwordStrengthPoints > i
                    ? 'bg-[' + passwordMeterColors[i] + ']'
                    : 'bg-gray-400'
                "
                :key="i"
              ></div>
            </div>
          </div>
          <div
            class="w-[100%] mt-4"
            v-if="validationObject.password.passwordConditions"
          >
            <div
              class="border-l-4 p-4 w-[380px]"
              style="
                border-color: #00c92e;
                background-color: rgba(0, 201, 46, 0.1);
              "
            >
              <p class="text-white text-[0.8vw]">
                Password must have 6 character includes special character,
                uppercase and number
              </p>
              <!-- <li class="text-white" v-for="(p, i) in CorrectPasswordPoints" :key="i">
                              {{ p }}
                          </li> -->
            </div>
          </div>

          <!-- <div class="">
          <button
            class="flex items-center justify-center rounded bg-[#02BD2D] px-4 py-2 text-white w-full mt-5"
            type="submit"
            @click.prevent="Forgetdone"
          >
            <svg
              v-if="loaderState"
              class="mr-3 h-6 w-6 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span class="font-bold">
              {{ loaderState ? "RESETTING YOUR PASSWORD" : "RESET PASSWORD" }}
            </span>
          </button>
        </div> -->

          <div
            class="w-full flex items-center justify-center mt-5"
            :class="loaderState ? 'btnDefault' : ''"
          >
            <VueLoadingButton
              aria-label="Post message"
              :class="loaderState ? 'cursor-not-allowed' : ''"
              class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full"
              @click.native="Forgetdone"
              :loading="loaderState"
              :styled="isStyled"
              :type="submit"
              ><span class="text-[15px] font-bold">{{
                loaderState ? "PLEASE WAIT..." : "SAVE MY PASSWORD"
              }}</span></VueLoadingButton
            >
          </div>
        </div>

        <div
          class="w-[100%] h-[3.6vh] bg-transparent flex items-center justify-center mt-8"
          style="gap: 0.3vw"
        >
          <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
          <p class="text-[#E5E9EC] text-sm">or</p>
          <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
        </div>
        <div class="mt-5 flex justify-center">
          <p class="text-[#e5e9ec]">
            Go back to
            <router-link to="/"
              ><span class="font-semibold"
                ><a href=""> Login Area </a></span
              ></router-link
            >
          </p>
        </div>
        <div class="mt-8 flex justify-center">
          <p class="text-[#e5e9ec] TEXT-SM">
            Have an issue?
            <router-link to="/contactus">
              <span class="text-[#758278] text-sm font-semibold cursor-pointer"
                >Contact Support.</span
              >
            </router-link>
          </p>
        </div>
      </div>

      <div class="w-full relative sm:mt-20 mt-36 flex justify-center">
       <PageFooter />
      </div>
    </div>
  </div>
</template>

<style scoped>
.supermain {
  background-color: black;
}

a {
  color: #02bd2d !important;
}

.main {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-image: url("../../assets/map-main-background.jpg");
  height: auto;
} 

/* .main {
  position: relative;
  height: 130vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .main::before {
  content: "";
 
  background-image: url("../../assets/map-main-background.jpg");

  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
} */

.overlay__content {
  transform: translateX(600%);
  position: absolute;
}

.spinner {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}
</style>

<script>
import AlertShort from "../utilities/AlertShort.vue";
import { passwordStrength } from "check-password-strength";
import { Colors } from "../../helper/globalUtils";
import userApi from "../../api/userApi";
import AlertLong from "../utilities/AlertLong.vue";
import VueLoadingButton from "vue-loading-button";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"

// import router from '@/router';

export default {
  components: {
    FooterCompanyLinks,
    AlertShort,
    AlertLong,
    VueLoadingButton,
    PageFooter
  },
  data() {
    return {
      pageColors: Colors,
      checkPasswordMsg: "",
      validationObject: {
        password: {
          success: null,
          message: "kindly enter valid password",
          borderChange: false,
          passwordConditions: false,
        },
      },
      password: null,
      token: null,
      alertTrue: false,
      showOutline: false,
      alertType: "",
      alertMessage: "",
      loaderState: false,
      validatedPassword: false,
      iconName: "fa-eye",
      type: "password",
      passwordStrengthPoints: 0,
      disabled: false,
      passwordMeterColors: [
        "#FF0A00",
        "#FF0A00",
        "#02BD2D",
        "#02BD2D",
        "#02BD2D",
      ],
      CorrectPasswordPoints: ["Special character", "Uppercase", "Number"],
      passwordChanged: false,
      linkExpired: false,
      linkNotExpired: false,
      mainLoader: false,
    };
  },
  watch: {
    password(value) {
      this.password = value;
      this.alertType = false;
      if (value) {
        this.validationObject.password.success = true;
        this.validationObject.password.passwordConditions = false;
        this.FieldsBorderColor(["password"], "#7582787d");
        this.validatePassword(value);
      } else {
        this.passwordStrengthPoints = 0;
      }
    },
  },
  methods: {
    fullYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    handleOutline() {
      this.showOutline = true;
    },
    removeOutline() {
      this.showOutline = false;
    },
    FieldsBorderColor(refrence, color) {
      for (const refvalues of refrence) {
        this.$refs[refvalues].style.border = `0.6px solid ${color}`;
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.iconName = "fa-eye-slash";
      } else {
        this.type = "password";
        this.iconName = "fa-eye";
      }
    },
    validatePassword(value) {
      let _passwordStrength = passwordStrength(value);
      this.passwordStrengthPoints =
        _passwordStrength.contains.length +
        (_passwordStrength.length < 6 ? 0 : 1);
      console.log(_passwordStrength);

      if (_passwordStrength.length < 6)
        console.log("password at least 6 character");

      return this.passwordStrengthPoints >= 3;
    },
    showErrors(message, alertType, alertTrue) {
      this.alertTrue = alertTrue;
      this.alertType = alertType;
      this.alertMessage = message;
      // setTimeout(() => {
      //   this.alertType = false;
      // }, 3000);
    },

    async Forgetdone() {
      let isNotValid = false;
      // console.log("password dine");
      if (this.password === null) {
        isNotValid = true;
        this.validationObject.password.success = false;
        this.validationObject.password.borderChange = true;
      }
      if (this.passwordStrengthPoints < 3 && this.password != null) {
        isNotValid = true;
        this.validationObject.password.success = false;
        this.validationObject.password.borderChange = true;
        this.validationObject.password.passwordConditions = true;
        if (this.validationObject.password.borderChange) {
          this.FieldsBorderColor(["password"], this.pageColors.MAP_RED);
        }
      }
      if (isNotValid) {
        return;
      } else {
        this.loaderState = true;

        const payload = {
          token: this.token,
          password: this.password,
        };
        const res = await userApi.resetPassword(payload);
        console.log(res);
        if (res) {
          if (res.data.success) {
            this.loaderState = false;
            this.passwordChanged = true;
            this.linkNotExpired = false;
          } else {
            this.loaderState = false;
            this.showErrors(res.data.message, "Danger", true);
          }
        } else {
          this.showErrors(
            "An unknown error occured kindly try again later",
            "Danger",
            true
          );
        }
      }
    },
    //get user data for token
    async getUserData() {
      //loader on karna ha yaha.

      let payload = {
        token: this.token,
      };
      try {
        this.mainLoader = true;
        const response = await userApi.linkExpireChecker(payload);
        console.log(response);
        if (!response.data.success) {
          this.mainLoader = false;
          this.linkExpired = true;
          this.linkNotExpired = false;
        } else {
          this.linkExpired = false;
          this.mainLoader = false;
          this.linkNotExpired = true;
        }
      } catch (e) {
        this.showErrors("Server Error", "Danger", true);
      }
    },
  },
  mounted() {
    let route = this.$route.query;
    console.log(route);
    this.token = route.Token;
    this.getUserData();
  },
};
</script>
<style scoped>
a {
  color: white;
}
a:hover {
  text-decoration: underline !important;
  text-decoration-color: #02bd27 !important;
}
.focus {
  border: 1px solid #02bd27 !important;
}

.footerlink {
  color: #00c92e !important;
}

.footerlink:hover {
  color: #3ef705 !important;
  text-decoration: none !important;
}
</style>
