<template>
  <tailwind-modal
    :show-modal="addSymbolsState"
    v-on:change="toggleModal"
    :showHeader="false"
  >
    <div
      class="w-[48rem] px-10 py-4"
      style="
        border: 0.3px solid rgba(2, 189, 45, 0.3);
        box-sizing: border-box;
        scrollbar-color: red;
        border-radius: 3px;
        background-color: #151515;
      "
    >
      <div class="flex pb-4 space-x-4" >
        <span>Add Symbols</span>
        <span v-if="responseMessage" class="text-[16px] pt-0.8 text-[#FF0A00]">{{ responseMessage }}</span>
      </div>

      <div class="pb-10">
        <div
          class="pb-4"
          v-if="selectedSymbolList && selectedSymbolList.length > 0"
        >
          <span
            v-for="item in selectedSymbolList"
            :key="item.id"
            class="px-1 py-1 mr-1 rounded-md text-gray-500 bg-gray-200 font-semibold text-xs"
          >
            {{ item.name }}
          </span>
        </div>

        <div>
          <input
            type="text"
            class="p-4 flex bg-transparent block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
            placeholder="Search Symbol or Name"
            v-model="symbolName"
            @keydown.up="up"
            @keydown.down="down"
            @keydown.esc="closeList"
            v-on:keyup="debounce(searchStock($event.target.value), 400)"

          />
        </div>

        <div>
          <symbol-list
            :symbol-list="symbolList"
            v-on:symbolSelected="symbolSelected"
            :handleTop="scrollToItem" 
            ref="symbolListRef"
            :selectedIndex="selectedIndex"
            :symbolInputName="symbolName"
          />
        </div>
      </div>

      <div class="buttons flex space-x-4 justify-end">
        <button
          type="submit"
          @click="toggleModal"
          class="cancel-button px-8 mt-6 font-semibold py-2 text-sm"
        >
          Close
        </button>

        <button
          type="submit"
          @click="saveSearchedStock"
          class="ok-button px-8 mt-6 font-semibold py-2 text-sm"
        >
          Save
        </button>
      </div>
    </div>
  </tailwind-modal>
</template>
<script>
import TailwindModal from "@/components/popups/TailwindModal";
import SymbolList from "@/components/popups/SymbolList";
import chartApi from "@/api/charts";
import debounce from "@/helper/debounce";
import watchListApi from "../../../api/watchListApi";
import moment from "moment"

export default {
  name: "AddSymbolModal",
  components: { SymbolList, TailwindModal },

  props: {
    addSymbolsState: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
    watchId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      symbolList: [],
      selectedSymbolList: [],
      itemHeight:53,
      selectedIndex:0,
      symbolName:"",
      responseMessage:null,

    };
  },
  methods: {
    debounce,
    symbolSelected(value) {

      if (!this.selectedSymbolList.includes(value)) {
        this.selectedSymbolList.push(value);
      }
    },
    up(){
      if(this.selectedIndex == 0){
        return;
      }
      this.selectedIndex -= 1
      this.scrollToItem()
      console.log("up =>",this.selectedIndex)
    },

    down(){
      if(this.selectedIndex >= this.symbolList.length - 1){
        return;
      }
      this.selectedIndex += 1
      this.scrollToItem()
      console.log("down =>", this.selectedIndex)
    },

    scrollToItem(){
      console.log('this.$refs.symbolListRef',this.$refs.symbolListRef.$refs.optionsList)
      if(this.$refs.symbolListRef && this.$refs.symbolListRef.$refs.optionsList)
        this.$refs.symbolListRef.$refs.optionsList.scrollTop = this.selectedIndex * this.itemHeight
    },

    closeList(){
      this.symbolList = []
    },






    toggleModal() {
      this.selectedSymbolList = [];
      this.symbolList = []
      this.symbolName = ""
      this.$emit("close");
    },

    searchStock(value) {
      value = value.trim();
  
  // Remove multiple spaces between words and keep only the last space
  value = value.replace(/\s+/g, ' ').trim();

  if (value && value.length && this.symbolNameInput !== value) {
    chartApi.getListOfSymbolsStartWith(value).then((res) => {
      this.symbolNameInput = value;
      this.symbolList = res;
    });
  }
    },

    saveSearchedStock: async function () {
      if (this.selectedSymbolList && this.selectedSymbolList.length > 0) {
        // let date = moment().format('MMM, DD YYYY');
        let date = moment().format('MM/DD/YYYY');
        try {
          const payload = {
            watchId: this.watchId,
            symbolList: this.selectedSymbolList,
            updatedDate:date
          };
          const res = await watchListApi.updateWatchListRecord(payload);
          console.log("watclist res==>", res)
          if(res?.data?.success){
            this.selectedSymbolList = [];
            this.symbolList = []
            this.symbolName = ""

            this.$emit("fetchUpdatedSymbolList");
            this.$emit("close");
          }else{
            this.responseMessage = res?.data?.message
            setTimeout(()=>{this.responseMessage = null}, 3000)
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        console.log("select Symbol list");
      }
    },
  },
};
</script>


<style scoped>

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}
.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}
.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}
.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}



</style>