<template>
  <tailwind-modal :showModal="true" :showHeader="false">
    <div
      class="border-[0.3px] border-solid px-4 border-[#758278] w-[600px] h-[360px] bg-[#151515] justify-between flex flex-col rounded-md"
    >
      <div class="w-full flex items-center justify-between h-[80px]">
        <span class="text-[16px] text-[#FFFFFF] Inter-Bold"
          >Banner Setting</span
        >
        <svg
          @click="closeMessageModal"
          xmlns="http://www.w3.org/2000/svg"
          fill="red"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="gray"
          class="cursor-pointer w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div class="flex items-center justify-center">
        <svg
          v-if="loaderState"
          class="h-6 w-6 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>

      <div class="w-full h-[300px] overflow-y-auto" v-if="!loaderState">
        <table
          class="w-full text-[11px] -border-[0.1px] text-center -border-solid -border-gray-600"
        >
          <thead class="sticky top-0 -border-[0.1px] -border-gray-600 z-[105]">
            <tr class="bg-[#1E2224] -border-[0.1px] -border-gray-600">
              <th
                scope="col"
                class="p-2 text-gray-400 -border-[0.1px] text-left -border-gray-600"
              >
                Indices
              </th>
            </tr>
          </thead>

          <tbody
            v-for="(data, i) of indices"
            :key="i"
            class="text-left overflow-y-auto"
          >
            <tr
              class="text-left hover:bg-[#1E2224] bg-opacity-[0.45] cursor-pointer -border-[0.1px] border-[white] bg-[#151515]"
            >
              <td class="p-2 -border-[0.1px] border-gray-600 space-x-6">
                <div class="flex w-40 items-center text-left whitespace-nowrap">
                  <label class="container cursor-pointer">
                    <input
                      :id="data.desc"
                      type="checkbox"
                      class="checkbox-round"
                      @click="addIndices($event.target.checked, data)"
                      :checked="
                        getBannerValues.find(
                          (item) => item.symbol === data.symbol
                        )
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!-- <input type="checkbox" class="mr-1 cursor-pointer" :checked="bannerList.includes(data.assetID)" @click="addIndices($event.target.checked,data)"> -->

                  <label
                    :for="data.desc"
                    class="-ml-28 text-left w-full cursor-pointer"
                    >{{ data.desc }}
                  </label>
                </div>
              </td>
            </tr>
            <!-- :checked="savedList.includes(data.symbol)" -->
            <!-- savedList.includes(data.symbol) -->
          </tbody>
        </table>
      </div>

      <div class="w-full flex items-center space-x-4 justify-end h-[80px]">
        <button
          class="ok-button w-[120px] h-[30px] flex items-center justify-center py-4 px-6 border-[0.6px] rounded-md border-solid border-[#02BD2D]"
          :class="loaderState ? 'pointer-events-none' : ''"
          @click="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </tailwind-modal>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import TailwindModal from "../popups/TailwindModal.vue";
import FivesApi from "@/api/fivesApi";
export default {
  props: {
    indices: {
      type: [Array],
      // default: () => {
      //     return []
      // }
    },
  },
  computed: {
    ...mapGetters({
      UserDetail: "signedInUser",
      getBannerValues: "getBannerValues",
    }),
    bannerList() {
      let bannerSettings = this.UserDetail?.bannerSettings;
      let bannerSettingMap = bannerSettings
        ? bannerSettings.map((a) => a.assetID)
        : [];
      console.log("bannerSettingMap", this.indices, bannerSettingMap);
      console.log("bannerSettingMap", this.indices, bannerSettingMap.length);
      return bannerSettingMap;
    },
  },
  data() {
    return {
      savedList: [],
      loaderState: false,
      displayAlert: false,
      showMessage: false,
      message: "",
      invoicesData: [],
      addItem: [],
      removeItem: [],
      selectedBanner: [],
    };
  },
  components: {
    TailwindModal,
  },
  methods: {
    ...mapActions(["getUserData"]),
    closeMessageModal() {
      console.log("hello from receipt modal");
      this.$emit("closeMarqueeModal");
    },

    async handleSave() {
      this.loaderState = true;
      const res = await FivesApi.getMarqueeInfo(this.getBannerValues);
      if (res.data.success) {
        await this.getUserData();
      }
      this.loaderState = false;
      this.$emit("closeMarqueeModal");
    },

    addIndices(check, item) {
      if (check) {
        // Add
        this.$store.commit("ADD_BANNER_VALUE", item);
      } else {
        this.$store.commit("REMOVE_BANNER_VALUE", item);
      }
    },
  },
  mounted() {
    if (this.UserDetail.bannerSettings?.length > 0) {
      this.$store.commit("ADD_BANNER_VALUE", this.UserDetail?.bannerSettings);
    }
    console.log("indices", this.UserDetail.bannerSettings);
  },
};
</script>

<style scoped>
.btnBg:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.InputContainer .InputElement {
  position: absolute;
  top: 0;
  color: white !important;
}

div.stripeCard {
  color: #f3f3f3 !important;
}
/* .container {
  display: block;
  position: absolute;
  right: 2px;
  cursor: pointer;
  width: 0.7em;
  height: 0.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.container input {
  display: block;
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: block;
  position: relative;
  /* top: 0;
  left: 0; */

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #747474;
  border-radius: 0.1vw;
  background-color: transparent;
}

.container:hover input ~ .checkmark {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.container input:checked ~ .checkmark {
  /* background-color: rgba(0, 201, 46, 0.12); */
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
