<template>
   <div class="w-full flex items-center justify-center mt-2" :class="isLoading ? 'btnDefault':''">
                <VueLoadingButton
                  aria-label="Post message"
                  :class="isLoading ? 'cursor-not-allowed':''"
                  class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                  @click.native="handleClick"
                  :loading="isLoading"
                  :styled="isStyled"
                  :type="submit"
                  ><span class="text-[15px] font-bold">SIGN IN</span></VueLoadingButton>
              </div>
</template>

<script>



export default {
    name:"ButtonLoader",
    props:["isLoading","handleClick"],


    methods:{
        handleClick(){
            this.$emit('handleClick')
        }
    }
}
</script>

<style scoped>
.btnDisabled{
  pointer-events: none;
}
</style>