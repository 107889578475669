<template>
   <div class="bg-white">
    <select name="" id="" @change="SelectPackage($event)">
        <option value="annual">Annual</option>
        <option value="monthly">Monthly</option>
    </select>
    <select name="" id="" @change="SelectProduct($event)">
        <option value="starter">starter</option>
        <option value="plus">Plus</option>
        <option value="premium">Premium</option>
    </select>

    <a :href="`http://localhost:8080/signup?package=${package}&product=${product}`" target="blank">go</a>
   

    

   </div>
  </template>
  
  <style scoped>
  
  </style>
  
  <script>

  
  
  export default {
    components: {
     
    },
    data() {
      return {
       product:null,
       package:null
      
      };
    },
    methods:{
        SelectPackage(e){
            console.log(e.target.value);
           this.package=e.target.value
        },
        SelectProduct(e){
            console.log(e.target.value);
           this.product=e.target.value
        }
    }
   
    
  };
  </script>
  