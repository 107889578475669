<template>
  <!-- for toggle and riseupanddwon -->
  <div class="ml-2 h-fit" v-if="signedInUser.role == 'plus'">
    <div style="border: 0.3px solid rgba(233, 233, 233, 0.2);border-radius:6px;"
      class="py-[.1vh] px-3 pb-1 border-gray-800 w-full h-full">
      <div class="text-lg pb-[.3vh] font-inter font-semibold text-gray-100 flex justify-center">
        <span class="text-[.9vw]">
          Country
        </span>
      </div>

      <div class="w-full">
        <div style="" class="flex forselectcountry justify-between pl-[.4vw] w-full"
          :class="signedInUser.dataStreamCountry[0].country == 'us' ? '' : 'disabled'">

          <div class="flex items-center space-x-1">
            <img class="forflags" :src="require('../../assets/Us_flag.png')" alt="flag" />

            <div class="text-gray-100 font-normal text-xs leading-5" :class="disable('US')">
              <span class="text-[.7vw]"> United States </span>
            </div>
          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleA" :checked="usaValue"
              :disabled="usaValue" @change="toggleUSAValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleA">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="w-full mt-1">
        <div style="" class="flex forselectcountry justify-between w-full pl-[.4vw]"
          :class="signedInUser.dataStreamCountry[0].country == 'ca' ? '' : 'disabled'">
          <div class="flex items-center space-x-1">

            <img class="forflags" :src="require('../../assets/CanadaFlag.png')" alt="flag" />

            <div class="text-gray-100 text-gray-100 font-normal text-xs leading-5" :class="disable('CA')">
              <span class="text-[.7vw]">Canada</span>
            </div>

          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleB" :checked="canadaValue"
              :disabled="canadaValue" @change="toggleCANValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleB">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="w-full mt-1">
        <div style="" class="flex forselectcountry w-full justify-between pl-[.4vw]"
          :class="signedInUser.dataStreamCountry[0].country == 'oz' ? '' : 'disabled'">

          <div class="flex space-x-1 items-center">

            <img class="forflags" :src="require('../../assets/australiaicon.png')" alt="flag" />


            <div class="text-gray-100 font-normal text-xs leading-5" :class="disable('AU')">
              <span class="text-[.7vw]">Australia</span>
            </div>
          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleC" :checked="australiaValue"
              :disabled="australiaValue" @change="toggleAUSValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleC">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>

        </div>
      </div>

      <div class="text-lg directionPad font-inter font-semibold text-gray-100 flex justify-center">
        <span class="text-[.9vw]"> Direction Summary </span>
      </div>


      <div style="" class="flex w-full forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Rise/Fall</span>
        </div>
        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center" :class="marWatchSummaryColor('Rise/Fall', marketWatchSummary)">{{
            Object.keys(marketWatchSummary).length > 0
              ? marketWatchSummary.market_direction
              : "N/A"
          }}</span>
        </div>
      </div>

      <div class="flex w-full mt-1 forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Slow Trend</span>
        </div>

        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center" :class="marWatchSummaryColor('Slow Trend', marketWatchSummary)">{{
            Object.keys(marketWatchSummary).length > 0
              ? marketWatchSummary.slow_trend
              : "N/A"
          }}</span>
        </div>
      </div>
      <div style="" class="flex w-full mt-1 forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Fast Trend</span>
        </div>
        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center" :class="marWatchSummaryColor('Fast Trend', marketWatchSummary)">{{
            Object.keys(marketWatchSummary).length > 0
              ? marketWatchSummary.fast_trend
              : "N/A"
          }}</span>
        </div>
      </div>
    </div>
  </div>


  <div v-else class="ml-2 -h-fit">
    <div style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius:6px;" class="-py-[.1vh] countryStyle
       px-3  border-gray-800 w-full -h-full">
      <div class="text-lg pb-[.3vh] font-inter font-semibold text-gray-100 flex justify-center">
        <span class="text-[1vw]">
          {{ signedInUser.country.countryShow > 1 ? 'Select Country' : 'Country' }}
        </span>
      </div>

      <div class="w-full">
        <div style="" class="flex forselectcountry justify-between pl-[.4vw] w-full"
          :class="signedInUser.country.countryList.includes('us') ? '' : 'disabled'">

          <div class="flex items-center space-x-1">
            <img class="forflags " :src="require('../../assets/Us_flag.png')" alt="flag" />

            <div class="text-gray-100 font-normal text-xs leading-5" :class="disable('US')">
              <span class="text-[.7vw]"> United States </span>
            </div>
          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleA" :checked="usaValue"
              :disabled="usaValue" @change="toggleUSAValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleA">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="w-full mt-0.5">
        <div style="" class="flex forselectcountry justify-between w-full pl-[.4vw]"
          :class="signedInUser.country.countryList.includes('ca') ? '' : 'disabled'">
          <div class="flex items-center space-x-1">

            <img class="forflags" :src="require('../../assets/CanadaFlag.png')" alt="flag" />

            <div class="text-gray-100 text-gray-100 font-normal text-xs leading-5" :class="disable('CA')">
              <span class="text-[.7vw]">Canada</span>
            </div>

          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleB" :checked="canadaValue"
              :disabled="canadaValue" @change="toggleCANValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleB">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="w-full mt-0.5">
        <div style="" class="flex forselectcountry w-full justify-between pl-[.4vw]"
          :class="signedInUser.country.countryList.includes('oz') ? '' : 'disabled'">

          <div class="flex space-x-1 items-center">

            <img class="forflags" :src="require('../../assets/australiaicon.png')" alt="flag" />


            <div class="text-gray-100 font-normal text-xs leading-5" :class="disable('AU')">
              <span class="text-[.7vw]">Australia</span>
            </div>
          </div>

          <div class="switch flex items-center">
            <input type="checkbox" name="switch" class="sr-only switch-checkbox" id="toogleC" :checked="australiaValue"
              :disabled="australiaValue" @change="toggleAUSValue" />
            <label class="switch-labels w-[2.5vw] h-[2.1vh]" for="toogleC">
              <span class="switch-text"></span>
              <span class="switch-dot"></span>
            </label>
          </div>

        </div>
      </div>

      <div class="text-lg directionPad font-inter font-semibold text-gray-100 flex justify-center">
        <span class="text-[.9vw]"> Direction Summary </span>
      </div>


      <div style="" class="flex w-full forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Rise/Fall</span>
        </div>
        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center uppercase Inter-Medium"
            :class="marWatchSummaryColor('Rise/Fall', marketWatchSummary)">{{
              Object.keys(marketWatchSummary).length > 0
                ? marketWatchSummary.market_direction
                : "N/A"
            }}</span>
        </div>
      </div>

      <div class="flex w-full mt-1 forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Slow Trend</span>
        </div>

        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center uppercase Inter-Medium"
            :class="marWatchSummaryColor('Slow Trend', marketWatchSummary)">{{
              Object.keys(marketWatchSummary).length > 0
                ? marketWatchSummary.slow_trend
                : "N/A"
            }}</span>
        </div>
      </div>
      <div style="" class="flex w-full mt-1 forselectcountry text-gray-300 items-center justify-between pl-[.6vw]">
        <div class="flex items-center">
          <span class="text-[.8vw]">Fast Trend</span>
        </div>
        <div style="border-left: 1px solid #75827849" class=" h-full flex items-center">
          <span class="text-center uppercase Inter-Medium"
            :class="marWatchSummaryColor('Fast Trend', marketWatchSummary)">{{
              Object.keys(marketWatchSummary).length > 0
                ? marketWatchSummary.fast_trend
                : "N/A"
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Toggle from "@vueform/toggle";
export default {
  name: "SelectCountry",
  components: {
    //    Toggle,
  },
  data() {
    return {
      countryId: "",
      usaValue: false,
      canadaValue: false,
      australiaValue: false,
      marketWatchSummary: [],
    };
  },
  computed: {
    ...mapGetters(["clickedRowStore", "signedInUser", "getActiveCountry"]),
  },
  methods: {
    ...mapActions(["getUserData"]),
    toggleUSAValue() {
      this.usaValue = !this.usaValue;
      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "us");
    },
    toggleAUSValue() {
      this.australiaValue = !this.australiaValue;
      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "oz");
    },
    toggleCANValue() {
      this.canadaValue = !this.canadaValue;
      this.$store.commit("SET_ACTIVE_COUNTRY_FOR_MARKETWATCH_TABLE", "ca");
    },
    marWatchSummaryColor(colName, value) {
      switch (colName) {
        case "Slow Trend":
          if (value.slow_trend == "Up") return "slowTrendGreen";
          else {
            return "slowTrendRed";
          }
        case "Rise/Fall":
          if (value.market_direction == "Falling" && value.alerts == "Market Alert") return "slowTrendRed";
          else if (value.market_direction == "Falling") return "riseFallDarkGreen"
          else {
            return "riseFallGreen";
          }
        case "Fast Trend":
          if (value.fast_trend == "Up") return "fastTrendGreen";
          else {
            return "fastTrendRed";
          }
      }
    },
    disable(val) {
      switch (val) {
        case "US":
          if (this.usaValue === false) {
            return "disable";
          } else {
            return "";
          }
        case "CA":
          if (this.canadaValue === false) {
            return "disable";
          } else {
            return "";
          }
        case "AU":
          if (this.australiaValue === false) {
            return "disable";
          } else {
            return "";
          }
      }
    },
  },

  watch: {
    // getActiveCountry(n){
    //   console.log(n)

    // },
    // getActiveCountry:{
    //   handler(n,o){
    //     console.log("n,o",n,o)
    //     if(n=='us'){
    //       this.usaValue=true

    //     }

    //   },
    //   deep:true


    // },
    clickedRowStore() {
      console.log("abc");
      //  console.log(this.marketWatchSummary);
      this.marketWatchSummary = this.clickedRowStore;
      console.log("marketWatchSummary", this.marketWatchSummary);
      console.log(this.clickedRowStore.length);
    },
    usaValue() {
      if (this.usaValue) {
        this.canadaValue = false;
        this.australiaValue = false;
      }
    },
    canadaValue() {
      if (this.canadaValue) {
        this.usaValue = false;
        this.australiaValue = false;
      }
    },
    australiaValue() {
      if (this.australiaValue) {
        this.usaValue = false;
        this.canadaValue = false;
      }
    },
    getActiveCountry(n, o) {
      console.log("getActiveCountry", n, o)


    }
  },


  mounted() {
    console.log("this.getActiveCountry",)
    console.log("this.$store.state", this.$store.state.tableStore.activeCountry)

    if (this.getActiveCountry === 'us') {
      this.toggleUSAValue()
    }
    if (this.getActiveCountry === 'oz') {
      this.toggleAUSValue()
    }
    if (this.getActiveCountry === 'ca') {
      this.toggleCANValue()
    }
  },
  created() {
    console.log("this.$store.state", this.$store.state.tableStore.activeCountry)


  }
};
</script>

<style>
.slowTrendRed {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: #ff0a00;
  color: white !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  border-radius: 4px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slowTrendGreen {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: rgba(2, 189, 45, 1);

  color: white !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  font-weight: 800;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.riseFallGreen {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: rgba(2, 189, 45, 1);

  color: white !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  font-weight: 800;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.riseFallDarkGreen {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: #fed500;
  color: rgb(71, 71, 71) !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.fastTrendGreen {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: rgba(2, 189, 45, 1);
  color: white !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  font-weight: 800;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fastTrendRed {
  font-size: 0.6vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  background-color: #ff0a00;
  color: white !important;
  width: 3.85vw;
  height: 2.1vh;
  display: block;
  font-weight: 800;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable {
  color: gray;
}

.forselectcountry {
  width: 11vw;
  height: 3.65vh;
  background: #1e2224;
  border: 0.3px solid #75827849;
  box-sizing: border-box;
  border-radius: 3px;
  align-items: center;
  gap: 0.2vw;
}

.forflags {
  height: 1.5vh;
  width: 0.8vw;
}

input:checked~.dot {
  transform: translateX(110%);
  background-color: #48bb78;
}

.switch {
  position: relative;
  width: 2.9vw;
  height: 20px;
  /* margin-right:20px; */

  right: 0;
}

.switch-checkbox {
  display: none;
}

/*Style words and oval switch */

.switch-labels {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  border: 0.1px solid #00C026;
}

.switch-text {
  display: block;
  width: 5vw;
  margin-left: -2.3vw;
  transition: margin 0.3s ease-in 0s;
}

.switch-text:before,
.switch-text:after {
  float: left;
  width: 50%;
  line-height: 30px;
  color: white;
  box-sizing: border-box;
}

.switch-text:before {
  content: "ON";
  padding-left: 0.3vw;
  color: #48bb78;
  font-family: "Inter";
  font-style: normal;
  font-size: 0.42vw;
  line-height: 1.9vh;
  font-weight: bolder;
}

.switch-text:after {
  content: "OFF";
  padding-right: 0.6vw;
  color: #ffffff;
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-size: 0.42vw;
  line-height: 1.9vh;
  font-weight: bolder;
}

/*Style center dot*/

.switch-dot {
  width: 0.7vw;
  height: 1.4vh;
  background: #ffffff;
  position: absolute;
  right: 1.8vw;
  margin-right: 0.5vh;
  margin-top: 0.2vh;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.switch-dot:after {
  content: "";
  position: absolute;
  width: 20px;
  /* height: 90px; */
  background-size: cover;
  /* background-image: url("http://www.free-icons-download.net/images/multiply-icon-27981.png"); */
  margin: 1px 0 0 1px;
}

/*State changer*/

.switch-checkbox:checked+.switch-labels .switch-text {
  margin-left: 0;
}

.switch-checkbox:checked+.switch-labels .switch-dot {
  right: 0px;
  margin-right: 0.55vw;
  background: #00C026;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.countryStyle {
  padding-bottom: 12px
}

.directionPad {
  padding-top: 0.3vh;
  padding-bottom: 0.3vh;
}

@media only screen and (max-width: 1600px) {
  .countryStyle {
    padding-bottom: 5px
  }

  .directionPad {
    padding-top: 0.2vh;
    padding-bottom: 0.2vh;
  }
}
</style>