<template>
    <div class="">
      <!-- tokenExpired -->
    <div v-if="tokenExpired">
        <div class="mainafter h-[100vh]">
            <div class="px-4 flex justify-center items-center h-full w-full relative">
                <AlertLong alertType="Danger" closeAlert="false" title="Link Expired">
                    <p class="text-white flex items-center justify-center h-full mt-2">
                        Oops! it seems your link is not valid
                    </p>
                </AlertLong>
            </div>
        </div>
    </div>

    <div v-else> 
      <div class="mainafter h-[100vh]">
        <div class="px-4 flex justify-center items-center h-full w-full relative">
          <div>
            <div class="w-[100%]">
              <img src="../../assets/mapWhite.svg" class="w-full" alt="" />
            </div>
            <div class="w-[100%] mt-1" v-if="emailSentAgain">
              <div class="" data-temp-component="true" v-if="message">
                <AlertShort class="w-full" :alertType="alertType">
                  <p class="text-[1vw] text-gray-300">
                    {{ message }}
                  </p>
                </AlertShort>
              </div>
            </div>

            <div class="p-3 rounded h-[25vh] w-[50vh] mt-4" role="alert">
              <p class="font-bold text-lg" style="color: #00c92e">
                Your account is not active yet. Please verify your email.
              </p>
              <div class="flex justify-between items-center mt-5">
                <p class="text-sm h-full items-center" style="color: #fff">
                  If you don’t see your verification email, please check spam
                  and junk folders. If you don’t verify your email address
                  within 7 days, your account will be automatically deleted and
                  nobody wants that.
                </p>
              </div>

              <div class="pb-6">
                <slot />
              </div>
            </div>
            <div class="w-[100%] h-[4.5vh] bg-transparent flex items-center justify-center mt-5" style="gap: 0.3vw">
              <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
              <p class="text-[#E5E9EC] text-[.9vw]">or</p>
              <div class="bg-[#E5E9EC5d] w-[40%] h-[1px]"></div>
            </div>
            <div class="text-white flex justify-center mt-2">
              Didn’t get the email yet?
            </div>
            <div>
              <button @click="ResentVerificationEmail" class="ok-button px-8 mt-6 font-semibold py-2 text-sm w-full">
                RESEND ACTIVATION LINK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  
  </template>
  
  <script>
  import router from "@/router";
  import axios from "axios";
  import userApi from "../../api/userApi";
  import {verify_token,miss_email} from "@/api/apiRoutes"
import AlertShort from "../utilities/AlertShort.vue";

  import "../customcss/index.css"
  import AlertLong from "../utilities/AlertLong.vue";
  
  export default {
    data() {
      return {
        tokenExpired:false,
        message:"",
        alertType:"",
        emailSentAgain:false,
      };
    },
    computed: {
     
    },
    components: {
      AlertLong,
      AlertShort
    },
    methods:{
    async ResentVerificationEmail() {

      let result = await axios.post(miss_email, { email: this.userVerificationToken });
      if (result.data.success) {
        this.emailSentAgain = true;
        this.alertType = "Success";
        this.message = "Email sent successfully kindly check your mail";
      }
    },
    
    async verifyToken() {
            let result = await axios.post(verify_token, {
                token: this.userVerificationToken,
            });
            if (!result.data.success) {
              console.log("expired")
                this.tokenExpired = true;
            }
        },

  
  },
  
  async mounted() {
        let route = this.$route.query;
        console.log("route", route);
        let len = Object.keys(route).length;
        if (route?.Token) {
            this.userVerificationToken = route;
            await this.verifyToken();
        } else {
            this.tokenExpired = true;

        }
    },
    beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
  </script>
  
  <style scoped>
  
  
  a{
    color:#02BD2D;
  }
  a:hover{
    text-decoration: underline !important;
    text-decoration-color: #02BD27 !important;
  }
  input:focus{
  border: 1px solid #02BD27 !important;
  }
  .main {
    /* background-image: url("../../assets/map-main-background.jpg"); */
    background-image: url("../../assets/map-main-background.jpg");
  
    background-color: black !important;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    background-attachment: fixed;
    
    height: auto; 
  }
  
  .mainafter {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mainafter::before {
    content: "";
    background-image: url("../../assets/map-main-background.jpg");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  }
  
  /* .mainafter {
    position: relative;
    height: 100vh;
    width: 100%;
    
   
  }
  
  .mainafter::before {
    content: "";
    
    background-image: url("../../assets/map-main-background.jpg");
    background-size: cover;
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  } */
  .footer::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
  .checkbox-round {
    width: 0.9em;
    height: 0.9em;
    background-color: black;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid gray;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  
  .ok-button {
    border: 0.6px solid #02bd2d;
    border-radius: 3px;
    color: #02bd2d;
    text-transform: uppercase;
  }
  
  .ok-button:hover {
    background: linear-gradient(270deg,
        rgba(2, 189, 45, 0.24) 0%,
        rgba(2, 189, 45, 0) 50%,
        rgba(2, 189, 45, 0.24) 100%);
  }
  
  .checkbox-round:checked {
    background-color: #02bd2d;
  }
  
  .btnDefault{
    pointer-events: none;
  }
  .footerlink{
    color:#00c92e !important;
  
  }
  
  .footerlink:hover{
    color: #3ef705 !important;
    text-decoration: none !important;
  
  }
  </style>
  