<template>
  <div class="w-[100%] h-[40vh]">

    <!-- <---Commented by Adyaan---> 
      <!-- <ul
          ref="optionsList" 
          class="text-sm font-medium text-white bg-[#151515]  rounded-lg border border-[#7582785c]  z-[105] w-[100%] max-h-[40vh] overflow-y-scroll mt-2"
          :class="isAbsolute ? 'relative':''"
          v-if="symbolList.length">
         
         
        <li class="cursor-pointer hover:bg-[#2E2E2F]"  :class="[symbolListClasses(symbolList.length, i),i == selectedIndex ? 'bg-[#2E2E2F]': '','text-[.75vw]']" :key="i" v-for="(s, i) in symbolList" @click="symbolSelected(s,i)">
          <label class="cursor-pointer">{{ s.name }}</label> <label class="text-left cursor-pointer">{{ s.companyName }}</label>  <label class="cursor-pointer">{{ s.id }}</label> 
        </li>
      </ul> -->


<!-- <----Revamped by Adyaan (Start)----> 

      <ul
          ref="optionsList" 
          class="text-sm font-medium text-white bg-[#151515]  rounded-lg border border-[#7582785c]  z-[105] max-h-[40vh] overflow-y-auto mt-2"
          :class="[isAbsolute ? 'relative':'',isChart ? 'w-[35vw]' : 'w-[100%]']"
          v-if="symbolList.length && symbolInputName">
          <!-- @focusou -->
          <!-- v-on:keyup="handlekeyboard(s)" -->
         
        <li class="cursor-pointer hover:bg-[#2E2E2F]"  :class="[symbolListClasses(symbolList.length, i),i == selectedIndex ? 'bg-[#2E2E2F]': '','text-[.75vw]']" :key="i" v-for="(s, i) in symbolList" @click="symbolSelected(s,i)">
          <label class="cursor-pointer w-[20%]">{{ s.name }}</label> <label class=" cursor-pointer text-left w-[65%] ml-2">{{ s.companyName }}</label>  <label class="cursor-pointer w-[15%] text-right">{{ s.id }}</label> 
        </li>
      </ul>
      
      <!-- <ul
          ref="optionsList" 
          class="text-sm font-medium text-white bg-[#151515]  rounded-lg border border-[#7582785c]  z-[105] w-[100%] max-h-[40vh] mt-2"
          :class="isAbsolute ? 'relative':''"
          v-else>
         
        <li class="hover:bg-[#2E2E2F] bg-[#2E2E2F] text-[.75vw] w-full text-center">
          <label class="text-white">no results</label> 
        </li>
      </ul> -->
  </div>

  
</template>
<script>
export default {
  name:'SymbolList',
  props:{
    symbolList:{
      type:Array,
      default() {
        return []
      }
    },
    selectedIndex:{
      type:Number,
      default(){
        return 0
      }
    },
    isAbsolute:{
      type:Boolean,
      default:false
    },
    isChart:{
      type:Boolean,
      default:false
    },
    symbolInputName:{
      type:String,
      default:""
    }
  },

  data(){
    return {
    }
  },
  methods:{
    symbolSelected(value,index){
      this.$emit('symbolSelected',value,index)
    },
    // symbolListClasses(len, i) {
    //   return `py-4 px-4 w-full border-b-[0.3px] border-b-[#7582785c] flex justify-between ${i === 0 ? 'rounded-t-lg' : ''} ${len - 1 === i ? 'rounded-b-lg' : ''}`
    // },

    symbolListClasses(len, i) {
      return `py-4 px-1 w-full border-b-[0.3px] border-b-[#7582785c] flex justify-between ${i === 0 ? 'rounded-t-lg' : ''} ${len - 1 === i ? 'rounded-b-lg' : ''}`
    },
    handlekeyboard(s){
      console.log(s)
    },
  },

}
</script>