export default [
  {
    colName: "date",
    showInModal: false,
    rowspan: "2",
    dataClasses: "text-center  tableDateCoulmn text-xs",  
    headerClasses: "dateHeaders text-xs",
    label: "Date",
    isDate: true,
  },
  {
    colName: "all_advancing",
    showInModal: true,
    rowspan: "2",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn text-xs",
    headerClasses: "totalAboveHeaders",
    label: "Total Stocks Bullish",
  },
  {
    colName: "strong_breakout_up",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Strong Breakout Up",
  },
  {
    colName: "breakout_up",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Breakout Up",
  },
  {
    colName: "moderate_up",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Moderate Up",
  },
  {
    colName: "low_up",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Weak Up",
  },
  {
    colName: "border_line_up",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Very Weak Up",
  },
  {
    colName: "border_line_down",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Very Weak Down",
  },
  {
    colName: "low_down",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Weak Down",
  },
  {
    colName: "moderate_down",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Moderate Down",
  },
  {
    colName: "breakout_down",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Breakout Down	",
  },
  {
    colName: "strong_breakout_down",
    showInModal: true,
    rowspan: "",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "basicHeaders",
    label: "Strong Breakout Down",
  },
  {
    colName: "all_declining",
    showInModal: true,
    rowspan: "2",
    dataClasses: "text-center cursor-pointer tableWholeBodyColumn ",
    headerClasses: "totalAboveHeaders",
    label: "Total Stocks Bearish    ",
  },
  {
    colName: "date",
    showInModal: false,
    rowspan: "2",
    dataClasses: "text-center  tableDateCoulmn text-xs",
    headerClasses: "dateHeaders text-xs",
    label: "Date",
    isDate: true,
  },
];
export const ColorMap = {
 // green back
  "#FF0A00": "!text-[black] bg-[#FF0A00] Inter-Medium", // red back
  "#02BD2D": "!text-[black] !bg-[#02bD2d] Inter-Medium", // green back
  "#153D1E": "text-[#02BD2D] bg-[#153D1E] ", // green font
  "#550B08": "text-[#FF0A00] bg-[#550B08]", // red font
  // '#550B08':'redtrans',
};
export function getWatchClasses(dataClasses, colName, data) {
  let colorProp = "color_" + colName;
  let classes = dataClasses;

  if (data[colorProp] && this.colorMap[data[colorProp]]) {
    // console.log('data[colorProp]',data[colorProp],this.colorMap[data[colorProp]],colName)
    classes = this.colorMap[data[colorProp]] + " " + classes;
    console.log("classes==>",classes)
    if (colName != "all_advancing" && colName != "all_declining") {
        classes += " basicHeaders cursor-pointer";
      }
  

    // this.$store.commit("MARKET_HEAT_COLOR_CODE", data[colorProp])
  } else {
    classes = "text-gray-100 " + classes;

    if (colName == "all_advancing" || colName == "all_declining") {
      classes =
        "text-gray-100 text-center cursor-pointer tableWholeBodyColumn bg-[#1E2224]";
    }
    else if(colName==="date") {
      classes = "cursor-pointer-none basicHeaders"

    }
     else {
      classes = " basicHeaders cursor-pointer";
    }

  }
  return classes;
  // col.colName+' '+col.dataClasses+colorClass('12')
}
