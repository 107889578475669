<template>
  <tailwind-modal :showModal="true" :showHeader="false">
    <div
        class="border-[0.3px] border-solid px-8 border-[#758278] w-[600px] h-[360px] bg-[#151515] justify-between flex flex-col rounded-md">

      <div class="w-full flex items-center justify-start h-[80px]">
        <span class="text-[16px] text-[#FFFFFF] Inter-Bold">Indices</span>
      </div>

      <div class="flex items-center justify-center">
        <svg
            v-if="loaderState"
            class="h-6 w-6 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
          <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
          ></circle>
          <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>

      <div class="w-full h-[300px] overflow-y-auto" v-if="!loaderState">

        <table class="w-full text-[11px] -border-[0.1px] text-center -border-solid -border-gray-600">
          <thead class="sticky top-0 -border-[0.1px] -border-gray-600">
          <tr class="bg-[#1E2224] -border-[0.1px] -border-gray-600">
            <th scope="col" class="p-2 text-gray-400 -border-[0.1px] text-left -border-gray-600">
              Select an index
            </th>

          </tr>
          </thead>

          <tbody v-for="(data,i) of indice" :key="i" class="text-left">
          <tr class=" text-left  hover:bg-[#1E2224] bg-opacity-[0.45] -border-[0.1px] border-[white] cursor-pointer bg-[#151515]"
              @click="selectIndex(i,data)">
            <td class="p-2 -border-[0.1px]  border-gray-600">
              {{ data.desc }}
            </td>
          </tr>


          </tbody>
        </table>


      </div>


      <div class="w-full flex items-center space-x-4 justify-end h-[80px]">
        <button
            class="cancel-button w-[120px] flex items-center justify-center py-4 px-6 h-[30px] border-[0.6px] rounded-md border-solid border-[#02BD2D]"
            @click="closeMessageModal"
        >
          Close
        </button>


      </div>

    </div>
  </tailwind-modal>
</template>
<script>
import TailwindModal from "../popups/TailwindModal.vue";
import {
  STRIPE_INVOICES
} from "@/api/apiRoutes";
import axios from "axios";
import moment from 'moment'

export default {

  data() {
    return {
      shortName: "",
      index: "",
      loaderState: false,
      displayAlert: false,
      showMessage: false,
      message: "",
      
    };
  },
  props: {
    indice: {
      type: [Array, Object],
      default: () => {
        return []
      }
    }
  },
  components: {
    TailwindModal,
  },
  methods: {
    selectIndex(item, name) {
      console.log("hello from receipt modal")
      // this.shortName=name;
      // this.index=item;
      // console.log("index",this.index)
      // console.log("name", this.shortName)
      this.$emit("selectIndex", name);
      // this.$emit("Name",this.shortName)
    },
    closeMessageModal() {
      console.log("hello from receipt modal")
      // this.shortName=name;
      // this.index=item;
      // console.log("index",this.index)
      // console.log("name", this.shortName)
      this.$emit("closeModal");
      // this.$emit("Name",this.shortName)
    },
  },


}
</script>

<style scoped>

.btnBg:hover {
  background: linear-gradient(270deg, rgba(2, 189, 45, 0.24) 0%, rgba(2, 189, 45, 0) 50%, rgba(2, 189, 45, 0.24) 100%);
}

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(
      270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%
  );
}

.ok-button {
  border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}

.ok-button:hover {
  background: linear-gradient(
      270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%
  );
}

.InputContainer .InputElement {
  position: absolute;
  top: 0;
  color: white !important;
}

div.stripeCard {
  color: #f3f3f3 !important;
}
</style>
  