<template>
    <div class="footer " :class="totalPages == 2? '-mb-7':''">
        <div v-if="currentPage == totalPages" class="w-full border-[0.2px] border-[#3F3E3E] rounded-[6px] px-2 py-1">
            <div class="text-[14px] text-black font-semibold -mt-2">Important Notice:</div>
            <div class="w-full leading-[10px] p-2 text-justify">
                <span class="text-[7.5px] font-bold text-black">
                    Market Alert Pro is an Authorised Representative (No. 001297846) of Equity Analyst Pty Ltd – ACN
                    60643403285 and AFSL 534455. Market Alert Pro provides general advice only. We do not take into
                    account any individual’s financial objectives, goals, needs or situation or life situation. Before
                    acting on any financial information we provide, you should consider its appropriateness to you and
                    we recommend you seek independent professional financial advice and read/obtain a product disclosure
                    advice (PDA) for any specific financial product you consider. Our information does not form and
                    should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation
                    about your investment decisions. Market Alert Pro are not financial advisors and we do not hold
                    ourselves out to be financial advisors or planners. Our website, email and social media content is
                    not a substitute for professional financial advice. The information provided to you does not
                    consider your personal circumstances. The contents of our website change daily and the past
                    performance results on our website or email do not guarantee future results. The information on our
                    website or email is delivered to the general public and is not aimed at any individual. Nothing on
                    our website or email should be taken as a solicitation to buy or sell a financial product. Any
                    reliance you place on information on our website or email is strictly at your own risk. You alone
                    accept the responsibility for your investment decisions. Investing in stocks carries a risk of
                    financial loss when stock prices fall. Only trade with funds you can afford to lose.The data seen in
                    the Market Alert Pro Company Reports is usually updated annually. This is because companies are
                    typically required to report at least annual results. For the US and Canada data is usually
                    available by August and for the Australian market, the data is usually available by November. Please
                    note that Market Alert Pro has no control over when data becomes available or the accuracy or
                    completeness of the data. We do our best to keep up to date data, but cannot control the
                    availability of data. If you are uncertain about any of our data content, you should verify it for
                    yourself.
                </span>
            </div>
        </div>
        <div class="w-full pb-2 -mt-2 text-[12px] font-medium text-[#000000] flex justify-end">
            Page {{ currentPage }} of {{ totalPages }}
        </div>
    </div>
</template>

<script>
export default {
    name: "StockReportFooter",
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        },
    }
}
</script>

<style>
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    /* Add any additional styling for the footer */
}
</style>