<template>
  <div>
    <!-- tokenExpired -->
    <div v-if="tokenExpired">
      <div class="mainafter h-[100vh]">
        <div
          class="px-4 flex justify-center items-center h-full w-full relative"
        >
          <AlertLong alertType="Danger" closeAlert="false" title="Link Expired">
            <p class="text-white flex items-center justify-center h-full mt-2">
              Oops! it seems your link is not valid
            </p>
          </AlertLong>
        </div>
      </div>
    </div>

    <!-- userCreated -->

    <div v-else-if="userCreated" class="h-[100vh] bg-[#22272B]">
      <AccountCreated />
    </div>

    <!-- form  -->
    <div v-else class="main w-full min-h-screen">
      <div
        class="flex h-full sm:pt-24 pt-20 md:justify-start sm:justify-center justify-center"
      >
        <div
          class="w-full lg:w-[33%] md:w-[40%] sm:w-[50%] sm:justify-end justify-center flex items-center -xl:bg-red-200 -bg-purple-300 -lg:bg-blue-100 -md:bg-yellow-200 -sm:bg-red-600"
        >
          <form
            class="w-full lg:w-9/12 md:w-10/12 overflow-y-auto footer h-full md:px-0 px-6 sm:px-6 z-50 flex items-center"
            :class="loaderState ? 'pointer-events-none' : ''"
          >
            <div
              class="h-full w-[100%] z-50"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: end;
                gap: 2vh;
              "
            >
              <div
                class="w-full"
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 3vh;
                  align-items: center;
                "
              >
                <!-- logo -->
                <div class="w-[100%] -mt-4">
                  <router-link to="/">
                    <img src="../../assets/mapWhite.svg" class="w-full" alt="" />
                  </router-link>
                </div>
                <!-- Alert msg -->

                <!-- signup body -->
                <div
                 class="-mt-6"
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 1vh;
                    width: 100%;
                  "
                >
                  <div>
                    <p class="text-[#E5E9EC] p-0 m-0">You are almost done!</p>
                    <h1 class="text-[#02BD2D] font-bold p-0 m-0 Inter-Bold">
                      Account Information
                    </h1>
                  </div>
                  <div class="w-[100%]" v-if="alertMessage">
                    <div
                      class=""
                      data-temp-component="true"
                      v-if="messages.length > 0"
                    >
                      <AlertShort class="w-full" :alertType="alertType">
                        <p
                          class="text-[1vw] text-gray-300"
                          v-for="message in messages"
                          :key="message.id"
                        >
                          {{ message }}
                        </p>
                      </AlertShort>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <div class="w-full">
                      <input
                        type="text"
                        @focus="fNameBorder = true"
                        @blur="fNameBorder = false"
                        v-model="firstName"
                        id="fname"
                        ref="fname"
                        placeholder="First Name*"
                        :disabled="disabled"
                        class="w-[100%] -h-[5vh] bg-transparent sm:px-[1.5vw] px-[2.5vw] py-[1.5vh] box-border text-[#FFFFFF] rounded-md focus:outline-none"
                        :style="{
                          border: fNameBorder
                            ? '0.6px solid green'
                            : '0.6px solid #7582787d',
                        }"
                      />
                      <p
                        v-if="validationObject.isFirstNameNotValid"
                        :class="
                          'p-0 m-0 mt-0 text-[' +
                          pageColors.MAP_RED +
                          '] text-[1vw]'
                        "
                      >
                        {{ pleaseEnterMessage("first name") }}
                      </p>
                    </div>
                    <div class="">
                      <input
                        type="text"
                        @focus="lNameBorder = true"
                        @blur="lNameBorder = false"
                        v-model="lastName"
                        id="lname"
                        ref="lname"
                        placeholder="Last Name*"
                        :disabled="disabled"
                        class="w-[100%] -h-[5vh] bg-transparent sm:px-[1.5vw] px-[2.5vw] py-[1.5vh] box-border -text-[.8vw] text-[#FFFFFF] rounded-md focus:outline-none mt-3"
                        :style="{
                          border: lNameBorder
                            ? '0.6px solid green'
                            : '0.6px solid #7582787d',
                        }"
                      />
                      <p
                        v-if="validationObject.isLastNameNotValid"
                        :class="
                          'p-0 m-0 mt-0 text-[' +
                          pageColors.MAP_RED +
                          '] text-[1vw]'
                        "
                      >
                        {{ pleaseEnterMessage("last name") }}
                      </p>
                    </div>

                    <!-- <div>
                        <select  :disabled="disabled" @change="handleCountryChange" ref="country" class="w-[100%] h-[5vh] bg-transparent px-[.8vw] py-[1.5vh] box-border text-[.8vw] text-[#FFFFFF] rounded-md focus:outline-none mt-3" style="border: 0.6px solid #7582787d">
                        <option selected class="text-black">Choose a country</option>
                        <option value="au" class="text-black">Australia</option>
                        <option value="us" class="text-black">United States</option>
                        <option value="uk" class="text-black">United Kingdom</option>
                        <option value="ca" class="text-black">Canada</option>
                        </select>
                        <p v-if="validationObject.isCountryNotValid" :class="
                                'p-0 m-0 text-[8px] mt-1 text-[' +
                                pageColors.MAP_RED +
                                '] text-[.6vw]'
                                ">
                                {{ pleaseEnterMessage("country") }}
                            </p>
                        </div> -->

                    <!-- <div class="mt-2">
                            <VuePhoneNumberInput @update="(item)=>handleInput(item)" :only-countries="countries" :no-example="true" class="mb-[8px]" :default-country-code="countryCode" v-model="phone" :dark="true" />
                        </div> -->

                    <!-- <p v-if="validationObject.isPhoneNotValid" :class="
            'p-0 m-0 text-[8px] mt-0 text-[' +
            pageColors.MAP_RED +
            '] text-[.6vw]'
          ">
                            {{ pleaseEnterMessage("phone") }}
                        </p> -->
                  </div>

                  <!-- <div class="flex">
                    <div
                      class="heloworld bg-transparent block w-full p-4 border shadow-inner rounded-md"
                      style="border: 0.6px solid #7582787d"
                    >
                      <div class="text-gray-200" ref="cardContainer"></div>
                    </div>
                  </div>
                  <p
                    v-if="validationObject.isCardEntered"
                    :class="
                      'p-0 m-0 text-[8px] mt-0 text-[' +
                      pageColors.MAP_RED +
                      '] text-[.6vw]'
                    "
                  >
                    please enter a valid card
                  </p> -->

                  <div class="flex w-[100%] h-[3.6vh ] text-[.7vw] items-start">
                    <div class="h-full flex pt-1.5">
                      <input
                        type="checkbox"
                        id="Terms_Con_Checkbox"
                        @click="checked()"
                        :checked="termsAndCondiion"
                        :disabled="disabled"
                        class="checkbox-round bg-[white]"
                      />
                    </div>

                    <div class="w-full ml-2">
                      <div class="w-full">
                        <label
                          class="text-[#E5E9EC] text-[14px]"
                          for="Terms_Con_Checkbox"
                          >I have read and understand the "Important Notice" at
                          the bottom of this page and I agree to the
                          <a
                            href="https://docs.marketalertpro.com/legal/terms-and-conditions"
                            target="_blank"
                          >
                            <span
                              :disabled="disabled"
                              class="text-[#02BD2D] cursor-pointer font-bold hover:text-[#34c906]"
                              >Terms and Conditions</span
                            >
                          </a>

                          of using this website.
                        </label>
                    
                    <!-- <p v-if="validationObject.istermsAndCondtionsAccepted || validationObject.istermsAndCondtionsAcceptedSectorSuffer" :class="
          'p-0 m-0 text-[10px] mt-0 text-[' +
          pageColors.MAP_RED +
          '] text-[10px]'
        ">
                        Please read and accept terms and condtion
                    </p> -->
                      </div>
                      
                    </div>
                  </div>
                  <div class="flex w-[100%] -bg-red-400 h-[3.6vh ] text-[.7vw] items-start">
                        <div class="h-full flex pt-1.5">
                            <input type="checkbox" id="Terms_Con_Checkbox" @click="checkedtermsAndCondiionSectorSuffer()" :checked="termsAndCondiionSectorSuffer" :disabled="disabled" class="checkbox-round bg-[white]" />
                        </div>

                        <div class="w-full ml-2">
                            <div class="w-full">
                                <label class="text-[#E5E9EC] text-[14px]" for="Terms_Con_Checkbox">
                                  I understand Market Alert Pro is an automated analysis tool and Market Alert Pro provides no personalised professional advice.
                                </label>
                            </div>
                        </div>
                    </div>
                  <p
                  v-if="validationObject.istermsAndCondtionsAccepted || validationObject.istermsAndCondtionsAcceptedSectorSuffer"
                    :class="
                      'p-0 m-0 text-[12px] mt-0 text-[' +
                      pageColors.MAP_RED +
                      '] text-[10px]'
                    "
                  >
                  Please read and accept our conditions by checking the tick boxes above.
                  </p>

                  <div
                    class="w-full flex items-center justify-center mt-2"
                    :class="loaderState ? 'btnDefault' : ''"
                  >
                    <VueLoadingButton
                      aria-label="Post message"
                      :class="loaderState ? 'cursor-not-allowed' : ''"
                      class="button flex items-center justify-center rounded bg-[#02BD2D] px-4 py-3 font-bold text-white w-full mt-5"
                      @click.native="signUp()"
                      :loading="loaderState"
                      :styled="isStyled"
                      ><span class="text-[15px] font-bold whitespace-nowrap"
                        >SAVE AND CONTINUE</span
                      ></VueLoadingButton
                    >
                  </div>

                  <div class="w-full my-2 text-[12px] text-[white]">
                    <span>Please read our </span>

                    <a
                      href="https://docs.marketalertpro.com/legal/terms-and-conditions"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Terms and Conditions
                      </span> </a
                    >,

                    <a
                      href="https://docs.marketalertpro.com/legal/privacy-policy"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Privacy Policy
                      </span>
                    </a>

                    and

                    <a
                      href="https://docs.marketalertpro.com/legal/financial-services-guide-fsg"
                      target="_blank"
                    >
                      <span class="text-[#00C92E] hover:text-[#3ef705]">
                        Financial Services Guide (FSG)
                      </span>
                    </a>
                  </div>

                  <div
                    class="w-[100%] h-[4.5vh] bg-transparent flex items-center justify-center"
                    style="gap: 0.3vw"
                  >
                    <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                    <p class="text-[#E5E9EC] -text-[.9vw]">or</p>
                    <div class="bg-[#E5E9EC22] w-[40%] h-[1px]"></div>
                  </div>

                  <div class="h-[8.2vh] w-[100%] bg-transparent">
                    <div class="h-[33%] flex items-center justify-center">
                      <div class="flex items-center">
                        <p class="-text-[1vw] text-[#E5E9EC]">
                          Already have an account?
                        </p>
                      </div>
                      <div class="flex items-center">
                        <button
                          class="-text-[1vw] text-[#00C92E] hover:underline font-bold ml-2"
                        >
                          <router-link
                            to="/"
                            :class="[disabled ? pointer_events_none : '']"
                            ><span>Login</span></router-link
                          >
                        </button>
                      </div>
                    </div>
                    <div class="h-[66%] w-full flex items-end justify-center">
                      <p class="-text-[.8vw] text-[#E5E9EC]">
                        Have an issue?
                        <router-link to="/contactus">
                          <span
                            class="text-[#9E9898] text-sm cursor-pointer hover:underline hover:decoration-[green]"
                            >Contact Support</span
                          >
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="w-full pb-6 mt-20 flex justify-center">
      <PageFooter />
      </div>

      <template v-if="termsOfUse">
        <TermsOfUse
          v-on:closeTermsOfUseModal="toggleModalTermsOfuse"
          :privacyProp="privacyProp"
        />
      </template>
      <template>
        <ImportantNotice
          v-if="ImportantNoticeModal"
          v-on:closeImportantNoticeModal="toggleImportantNotice"
        />
      </template>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import userApi from "../../api/userApi";
// import { FormValidationException } from "../../helper/exceptions";
import {
  STRIPE_PRODUCT_LIST,
  verify_token,
  user_further_details,
} from "@/api/apiRoutes";
import AlertShort from "../utilities/AlertShort.vue";
import { STRIPE_ADD_CARD } from "@/api/apiRoutes";

import TermsOfUse from "../popups/TermsOfUse.vue";
import { passwordStrength } from "check-password-strength";
import { Colors } from "../../helper/globalUtils";
import { pleaseEnterMessage } from "../../helper/globalUtils";
import AlertLong from "../utilities/AlertLong.vue";
import { loadStripe } from "@stripe/stripe-js";
import ImportantNotice from "../popups/ImportantNotice.vue";
import { termsandcondition } from "../../utils/termsandcondition";
import VueLoadingButton from "vue-loading-button";
import "../customcss/index.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "./UserDetail/userDetail.css";
import AccountCreated from "./AccountCreated.vue";
import FooterCompanyLinks from "@/components/pages/FooterCompanyLinks.vue";
import PageFooter from "@/components/Footer/Footer.vue"
import config from "@/api/config";

// import jwt from 'jsonwebtoken'

export default {
    data() {
        return {
            countries:['US', 'CA', 'GB', 'AU'],
            regex:/^\d{8}$/,
            cardBorder:false,
            fNameBorder:false,
            lNameBorder:false,
            country:"",
            showToolTip:false,
            countryCode: 'AU',
            isStyled: false,
            ImportantNoticeModal: false,
            pageColors: Colors,
            privacyProp: null,
            alertMessage: false,
            missingProd: false,
            formValidation: {
                email: {
                    isValid: true,
                    messag: "",
                },
            },
            passwordMeterColors: [
                "#FF0A00",
                "#FF0A00",
                "#02BD2D",
                "#02BD2D",
                "#02BD2D",
            ],
            validationObject: {
                isPhoneNotValid: false,
                isFirstNameNotValid: false,
                isLastNameNotValid: false,
                isCountryNotValid:false,
                isPasswordNotMatch: false,
                isCardEntered: false,
                istermsAndCondtionsAccepted: false,
                istermsAndCondtionsAcceptedSectorSuffer: false,
            },
            CorrectPasswordPoints: ["Special character", "Uppercase", "Number"],
            passwordStrengthPoints: 0,
            passwordRequirements: ["It must be at least 6 characters long.", ""],
            checkPasswordMsg: "",
            results: " ",
            border: "20px solid black",
            firstName: "",
            lastName: "",
            email: "",
            validatedEmail: null,
            validatedLName: null,
            validatedFName: null,
            validatedPassword: null,
            validatedPhone: null,
            phone: "",
            password: "",
            repeatpassword: "",
            type: "password",
            typeRepeatPassword: "password",
            termsAndCondiion: false,
            termsAndCondiionSectorSuffer: false,
            selectedProduct: "",
            stripeProducts: [],
            messages: [],
            success: null,
            alertType: "Success",
            iconName: "fa-eye",
            cardShow: false,
            cardToken: "",
            cardElem: null,
            loaderState: false,
            disabled: false,
            termsOfUse: false,
            privacyandCookies: false,
            pointer_events_none: "pointer-events-none",
            borderColor: "red",
            booleanValue: false,
            // refArray: ["fname", "lname","country"],
            refArray: ["fname", "lname"],
            passwordCondition: false,
            vareinets: [],
            productId: null,
            productRetrived: false,
            missingVarient: false,
            userCreated: false,
            repeatPasswordIcon: "fa-eye",
            userVerificationToken: null,
            tokenExpired: false,
        };
    },
 
  components: {
    FooterCompanyLinks,
    AlertShort,
    TermsOfUse,
    AlertLong,
    AlertLong,
    AlertLong,
    ImportantNotice,
    VueLoadingButton,
    VuePhoneNumberInput,
    AccountCreated,
    PageFooter
  },
  watch: {
    countryCode(val) {
      if (val == "US") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "CA") {
        this.regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      } else if (val == "GB") {
        this.regex = /^\d{10}$/;
      } else if (val == "AU") {
        this.regex = /^\d{8}$/;
      }
    },

    phone(value) {
      console.log("phone", value);
      this.validatedPhone = true;
      this.validationObject.isPhoneNotValid = false;
      // this.$refs?.phone?.style.border = `0.6px solid #7582787d`;
      if (value) {
        console.log("phone if");
        this.validationObject.isPhoneNotValid = false;
        this.phone = value;
        this.validatePhone(value);
      }
    },

        phone(value) {
            console.log("phone",value)
            this.validatedPhone = true;
            this.validationObject.isPhoneNotValid = false;
            // this.$refs?.phone?.style.border = `0.6px solid #7582787d`;
            if (value) {
                console.log("phone if");
                this.validationObject.isPhoneNotValid = false;
                this.phone = value;
                this.validatePhone(value);
            }
        },

        firstName(value) {
            this.$refs.fname.style.border = `0.6px solid #7582787d`;
            this.validationObject.isFirstNameNotValid = false;
            this.alertType = " "
            if (value) {
                this.firstName = value;
                this.validateFName(value);
            }
        },
        country(value){
            this.$refs.country.style.border = `0.6px solid #7582787d`;
            this.validationObject.isCountryNotValid = false;
            if (value) {
                this.country = value;
            }
        },
        termsAndCondiion(value) {
            console.log(value);
            if (value == true) {
                this.validationObject.istermsAndCondtionsAccepted = false;
            }
        },
        termsAndCondiionSectorSuffer(value) {
            console.log(value);
            if (value == true) {
                this.validationObject.istermsAndCondtionsAcceptedSectorSuffer = false;
            }
        },
        lastName(value) {
            this.$refs.lname.style.border = `0.6px solid #7582787d`;
            this.validationObject.isLastNameNotValid = false;
            this.alertType = " "
            if (value) {
                this.lastName = value;
                this.validateLName(value);
            }
        },

        password(value) {
            this.$refs.password.style.border = `0.6px solid #7582787d`;
            this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
            this.validationObject.isPasswordNotMatch = false;
            this.validationObject.isPasswordNotValid = false;

            if (value) {
                this.password = value;
                this.passwordCondition = false;
                try {
                    this.validatePassword(value);
                } catch (err) {
                    console.log(err);
                }
            } else {
                this.passwordStrengthPoints = 0;
            }
        },
        repeatpassword() {
            this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
            this.validationObject.isPasswordNotMatch = false;
        },
    },
    methods: {
        pleaseEnterMessage,
        handleInput(value) {
            this.phone = value.formattedNumber
            this.countryCode = value.countryCode
            console.log("phone", this.phone)
            console.log("value", value)
        },
      
        handleCountryChange(e){
            let countrySelect = e.target.value
            if(countrySelect !== "Choose a country"){
                this.country = countrySelect
                console.log("country",this.country)
            }
        },
        handleToolTip(){
            this.showToolTip = true
        },

        selectProduct(value) {
            this.missingProd = false;
            this.selectedProduct = null;
            console.log(value);
            let localVarients = this.stripeProducts.find((o) => o.id === value);
            this.vareinets = localVarients.pricing;
            console.log(this.vareinets);
            this.productId = value;
        },
        selectVarient(value) {
            this.missingVarient = false;
            this.selectedProduct = value;
            console.log(this.selectedProduct);
        },
        passwordMatching() {
            if (this.password != this.repeatpassword) {
                console.log("password not mach");
                this.FieldsBorderColor(
                    ["password", "repeatpassword"],
                    this.pageColors.MAP_RED
                );
                this.validationObject.isPasswordNotMatch = true;
            } else {
                this.validationObject.isPasswordNotMatch = false;
                this.FieldsBorderColor(["password", "repeatpassword"], "#7582787d");
            }
        },
        redAll() {
            this.$refs.val.style.border = `0.1px solid 	rgba(255,0,0,0.5)`;
        },
        toggleModalTermsOfuse(value) {
            if (!this.disabled) {
                this.termsOfUse = !this.termsOfUse;
                this.privacyProp = value;
                console.log(this.privacyProp);
            }
        },
        toggleModalCookiesPolicy() {
            this.privacyandCookies = !this.privacyandCookies;
        },
        fullYear(){
              const currentYear = new Date().getFullYear();
              return currentYear
            },

        validatePhone(phone) {
            // var regex = /^\d{9}$/;
            if (this.regex.test(phone)) {
                this.validatedPhone = true;
                console.log("phone is true");
            } else {
                console.log("phone is false");
                this.validatedPhone = false;
            }
        },
        validateEmail(value) {
            if (
                /^[a-zA-Z0-9.'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
                    value
                )
            ) {
                this.validatedEmail = true;
            } else {
                // this.$refs.email.style.border = `0.1px solid 	rgba(255,0,0,0.5)`;

                // console.log("email not true");
                this.validatedEmail = false;
                // this.alertType = "Danger";
                // this.messages = ["Please enter a valid email addressss"];
                if (this.$refs.email.value == 0) {
                    this.$refs.email.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
                    this.validatedEmail = true;
                    // this.messages.length = 0;
                }
            }
        },
        validatePassword(value) {
            let _passwordStrength = passwordStrength(value);
            this.passwordStrengthPoints =
                _passwordStrength.contains.length +
                (_passwordStrength.length < 6 ? 0 : 1);
            console.log(_passwordStrength);

            if (_passwordStrength.length < 6)
                throw "Password must be at least 6 characters long.";

            return this.passwordStrengthPoints >= 3;
        },
        validateFName(value) {
            if (/^[A-Za-z]\w*$/.test(value)) {
                this.validatedFName = true;
            } else {
                console.log("name not true");
                this.validatedFName = false;
            }
        },
        validateLName(value) {
            if (/^[A-Za-z]\w*$/.test(value)) {
                this.validatedLName = true;
            } else {
                console.log("name not true");
                this.validatedLName = false;
            }
        },

        FieldsBorderColor(refrence, color) {
            for (const refvalues of refrence) {
                this.$refs[refvalues].style.border = `0.6px solid ${color}`;
            }
        },
        //~
        async signUp() {
            let validationErrors = [];
            let isEmptyForm = true;
            let isNotValid = false;
            for (const ref of this.refArray) {
                let fieldRef = this.$refs[ref];
                console.log(
                    "fieldRef.value==>",
                    ref,
                    fieldRef.value,
                    fieldRef.value === ""
                );
                if (fieldRef.value === "") {
                    fieldRef.style.border = `0.1px solid 	${this.pageColors.MAP_RED}`;
                    isEmptyForm = false;
                }

            }
            if (!isEmptyForm) {
                validationErrors.push("Fields with (*) are required");
                this.showErrors(validationErrors);
                return;
            }
            if (this.validatedFName == false) {
                this.validationObject.isFirstNameNotValid = true;
                this.FieldsBorderColor(["fname"], this.pageColors.MAP_RED);
                isNotValid = true;
            }
            if (this.validatedLName == false) {
                this.validationObject.isLastNameNotValid = true;
                this.FieldsBorderColor(["lname"], this.pageColors.MAP_RED);
                isNotValid = true;
            }
            // if (this.validatedLName == false) {
            //     this.validationObject.isLastNameNotValid = true;
            //     this.FieldsBorderColor(["lname"], this.pageColors.MAP_RED);
            //     isNotValid = true;
            // }
            // if (!this.country) {
            //     this.validationObject.isCountryNotValid = true;
            //     this.FieldsBorderColor(["country"], this.pageColors.MAP_RED);
            //     isNotValid = true;
            // }
            // if (this.validatedPhone == false) {
            //     this.validationObject.isPhoneNotValid = true;
            //     this.FieldsBorderColor(["phone"], this.pageColors.MAP_RED);

            //     isNotValid = true;
            // }

            if (!this.termsAndCondiion) {
                this.validationObject.istermsAndCondtionsAccepted = true;
                isNotValid = true;
            }
            if (!this.termsAndCondiionSectorSuffer) {
                this.validationObject.istermsAndCondtionsAcceptedSectorSuffer = true;
                isNotValid = true;
            }
            // if (!this.phone) {
            //     this.validationObject.isPhoneNotValid = true
            //     isNotValid = true
            // }
            // if (!this.cardToken) {
            //     this.validationObject.isCardEntered = true
            //     isNotValid = true
            // }
            if (isNotValid) {
                return;
            }
            if (validationErrors.length > 0) {
                this.showErrors(validationErrors);
                return;
            }

            

            try {
                console.log("in try")
                if (validationErrors.length > 0) throw Error("Failed to validate form");

                // country:this.country,
                const payload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    //cardToken: this.cardToken,
                    userToken: this.userVerificationToken,
                    termsAndCondition: this.termsAndCondiion,

                };

                console.log("payload=========>", payload);
                
                
                this.loaderState = true;
                this.disabled = true;
                const res = await axios.post(user_further_details, {
                    payload
                });

                console.log("res:", res);
                this.loaderState = false;
                this.disabled = false;
                if (res) {

                    this.success = res.data.success;
                    if (this.success) {
                        this.loaderState = false
                        this.alertMessage = true
                        this.alertType = "Success";
                        this.messages = [res.data.message];
                        this.userCreated = true;
                        this.validationErrors = [];
                        // setTimeout(()=>{
                        //   this.$router.push("/")
                        // },2000)
                    } else {
                        this.loaderState = false
                        this.alertMessage = true
                        this.alertType = "Danger";
                        this.messages = [res.data.message];
                        // setTimeout(() => {
                        //     this.alertType = " ";
                        // }, 2 * 2000);
                    }
                } else {
                    throw Error("Invalid Information");
                }
            } catch (err) {
                this.showErrors(validationErrors);
            }
            await this.sleep(4000);
            this.alertMessage = false;
        },
        sleep(ms) {
            this.alertMessage = true;
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        showErrors(validationErrors) {
            this.alertMessage = true;
            console.log("show errors", validationErrors);
            this.loaderState = false;
            this.disabled = false;
            this.success = false;
            this.alertType = "Danger";
            // setTimeout(() => {
            //     this.alertType = "";
            // }, 4 * 2000);
            this.messages = validationErrors;
            console.log(this.messages.length);
        },
        toggleImportantNotice(value) {

            this.ImportantNoticeModal = !this.ImportantNoticeModal;

        },

        checked() {
            this.termsAndCondiion = !this.termsAndCondiion;

            console.log(this.termsAndCondiion);
        },
        checkedtermsAndCondiionSectorSuffer() {
            this.termsAndCondiionSectorSuffer = !this.termsAndCondiionSectorSuffer;

            console.log(this.termsAndCondiionSectorSuffer);
        },
        async getProducts() {
            /**
             * @Todo RN: If we unable to retreive list of products, then sign up page should be down and display an error message "Registration form is currently unavailable."
             */
            let data = await axios.get(STRIPE_PRODUCT_LIST);
            console.log("stripe product data", data);
            if (data) {
                this.stripeProducts = data.data.payload;
                console.log(this.stripeProducts);
                this.productRetrived = true;
            }
        },
        // addCard function
        async addCard(token) {
            let response = await axios.post(STRIPE_ADD_CARD, {
                cardToken: token,
            });
            console.log(response);
            return response;
        },
        loadStripeElement() {
            loadStripe(
                config.stripe_id
            ).then((stripe) => {
                let stripeElements = stripe.elements();

                this.cardElem = stripeElements.create("card", {
                    hidePostalCode: true,
                    style: {
                        base: {
                            color: " #E0E0E0",
                            // fontWeight: 300,
                            fontFamily: "Inter, sans-serif",
                            fontSize: "12px",
                            // height: '60px',
                            // background: '#f1f1f1',
                            "::placeholder": {
                                color: ' #E0E0E0',
                                // fontSize: '19px',
                            },
                        },
                    },
                });
                console.log("this.cardElem===============>", this.cardElem);
                this.cardElem.mount(this.$refs.cardContainer);

                this.cardElem.on("ready", (event) => {
                    console.log("ready=>", event);
                    this.loaderState = false;
                });

                this.cardElem.on("change", async (event) => {
                    this.cardToken = null
                    this.validationObject.isCardEntered = false
                    if (event.complete) {
                        const res = await stripe.createToken(this.cardElem);
                        console.log(res);

                        if (res.token.id) {
                            this.cardToken = res.token.id;
                            console.log(this.cardToken);
                            this.$emit("cardToken", this.cardToken);
                            return;
                        }
                        if (res.error.type == "card_error") {
                            this.displayAlert = true;
                            this.alertType = "Danger";
                            this.alertMessage = res.error.message;
                            this.buttonDsiabled = true;

                            setTimeout(() => {
                                this.displayAlert = false;
                                this.cardElem.clear();
                                this.buttonDsiabled = false;
                            }, 5 * 1000);
                            return;
                        }
                    }
                });
            });
        },
        async getProducts() {
            this.mainLoaderstate = true;
            let data = await axios.get(STRIPE_PRODUCT_LIST);
            console.log("data from stripe products", data);
            if (data) {
                this.mainLoaderstate = false;
                this.stripeProducts = data.data.payload;
            }
        },
        async verifyToken() {
            let result = await axios.post(verify_token, {
                token: this.userVerificationToken,
            });
            console.log(result);
            if (!result.data.success) {
                this.tokenExpired = true;
            }
        },
    },
    termsAndCondiion(value) {
      console.log(value);
      if (value == true) {
        this.validationObject.istermsAndCondtionsAccepted = false;
      }
    },
    lastName(value) {
      this.$refs.lname.style.border = `0.6px solid #7582787d`;
      this.validationObject.isLastNameNotValid = false;

      if (value) {
        this.lastName = value;
        this.validateLName(value);
      }
    },

    password(value) {
      this.$refs.password.style.border = `0.6px solid #7582787d`;
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
      this.validationObject.isPasswordNotValid = false;

      if (value) {
        this.password = value;
        this.passwordCondition = false;
        try {
          this.validatePassword(value);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.passwordStrengthPoints = 0;
      }
    },
    repeatpassword() {
      this.$refs.repeatpassword.style.border = `0.6px solid #7582787d`;
      this.validationObject.isPasswordNotMatch = false;
    },

 
  async mounted() {
    let route = this.$route.query;
    console.log("route", route);

    let len = Object.keys(route).length;
    console.log(len);
    if (route?.Token) {
      this.userVerificationToken = route;
      await this.verifyToken();
      await this.loadStripeElement();
      console.log("route====>", route);
    } else {
      this.tokenExpired = true;
    }
  },
};
</script>

<style scoped>
.main {
  /* background-image: url("../../assets/map-main-background.jpg"); */
  background-color: black !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-image: url("../../assets/map-main-background.jpg");
  height: auto;
}

input[type="checkbox"] {
  height: 10px;
  width: 10px;
}

.mainafter {
  position: relative;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.mainafter::before {
  content: "";
  background-image: url("../../assets/map-main-background.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}

.donthaveacc p {
  color: #f8f9f9;
  font-weight: 100;
}

.donthaveacc span {
  color: #02bd2d;
  font-weight: 600;
}

.checkbox-round {
  width: 0.9em;
  height: 0.9em;
  /* background-color: black; */
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.footer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.checkbox-round:checked {
  background-color: #02bd2d;
}

.btnDefault {
  pointer-events: none;
}
</style>
