<template>
    <div class="flex w-full items-center justify-between">
        <img src="../../assets/mapBlack.svg" width="210" alt="" />
        <div class="flex space-x-2 pb-5 mr-2.5 items-center">
            <span class="text-sm text-black font-semibold">Company Report</span>
            <span class="text-[12px]">produced {{ getTodayDate() }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "StockReportHeader",
    methods:{
        getTodayDate() {
         return moment().format('DD/MM/YYYY')
      }
    }
}
</script>

<style>
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    /* Add any additional styling for the footer */
}
</style>