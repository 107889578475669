const bg_identifier = "_bg_color_"
const txt_identifier = "_text_color_"
export default [
  {
    applyColor: false,
    category: "",
    name: "date",
    desc: "",
    class: "inpppDate",
    style: "border-left: 0px; border-bottom: 0px;",
    isDate: true,
  },
  {
    applyColor: true,
    category: "sp_500",
    name: "sp_500",
    desc: "",
    class: "",
    style: " margin-left: 10px;",
  },
  {
    applyColor: true,
    category: "FT",
    name: "fast_trend",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px font-weight: bold;",
  },
//   {
//     applyColor: true,
//     category: "FT",
//     name: "vix",
//     desc: "",
//     class: "inpuDate Inter-Medium",
//     style: "border-bottom: 0px; font-weight: bold",
//   },
  {
    applyColor: true,
    category: "MD",
    name: "direction_depth",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold",
  },
  {
    applyColor: true,
    category: "MD",
    name: "market_direction",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold ",
  },
  {
    applyColor: true,
    category: "MD",
    name: "dircetion_total",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold",
  },
  {
    applyColor: true,
    category: "MBA",
    name: "momentum_m",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold",
  },
  {
    applyColor: true,
    category: "MBA",
    name: "breakout_m",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold",
  },
  {
    applyColor: true,
    category: "MBA",
    name: "advance_m",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold",
  },
  {
    applyColor: true,
    category: "MBA",
    name: "mba_avg",
    desc: "",
    class: "Inter-Medium",
    style: "",
  },
  {
    applyColor: true,
    category: "MBA",
    name: "alerts",
    desc: "",
    class: "Inter-Medium",
    style:
      "#02bd2d;border-left: 0.1px solid rgba(117, 130, 120, 0.3);border-right: 0.1px solid rgba(117, 130, 120, 0.3);border-bottom: 0px;",
  },
  {
    applyColor: true,
    category: "ST",
    name: "mba_percentage",
    desc: "",
    class: "Inter-Medium",
    style: "border-bottom: 0px; font-weight: bold ",
  },
  {
    applyColor: true,
    category: "ST",
    name: "slow_trend",
    desc: "",
    class: "Inter-Medium ",
    style:
      "border-bottom: 0px;border-right: 0.1px solid rgba(117, 130, 120, 0.3);font-weight: bold;",
  },
  {
    applyColor: false,
    category: "",
    name: "date2",
    desc: "",
    class: "inpppDate",
    style: "border-right: 0px; border-bottom: 0px",
    isDate: true,
  },
];

export const MarketAlertTableRowStructure = {
  "_id": {},
  "color_date": "",
  "font_color_date": "",
  "date": "",
  "color_sp_500": "",
  "font_color_sp_500": "",
  "sp_500": "",
  "color_fast_trend": "",
  "font_color_fast_trend": "",
  "fast_trend": "",
  "color_vix": "",
  "font_color_vix": "",
  "vix": 0.00,
  "color_direction_depth": "",
  "font_color_direction_depth": "",
  "direction_depth": 0.00,
  "color_market_direction": "",
  "font_color_market_direction": "",
  "market_direction": "",
  "color_dircetion_total": "",
  "font_color_dircetion_total": "",
  "dircetion_total": 0.00,
  "color_momentum_m": "",
  "font_color_momentum_m": "",
  "momentum_m": 0.00,
  "color_breakout_m": "",
  "font_color_breakout_m": "",
  "breakout_m": 0.00,
  "color_advance_m": "",
  "font_color_advance_m": "",
  "advance_m": 0.00,
  "color_mba_avg": "",
  "font_color_mba_avg": "",
  "mba_avg": 0.00,
  "color_alerts": "",
  "font_color_alerts": "",
  "alerts": "",
  "color_mba_percentage": "",
  "font_color_mba_percentage": "",
  "mba_percentage": 0,
  "color_slow_trend": "",
  "font_color_slow_trend": "",
  "slow_trend": "",
  "color_date2": "",
  "font_color_date2": "",
  "date2": "",
  "dateObject": {
    "$date": ""
  }
}

export const MarketAlertTableConfigurationStructure = {
  applyColor: true,
  category: "",
  name: "",
  desc: "",
  class: "",
  style: "",
  isDate: true,
};

export const colorMap = {
  "#009300": "green",
  // "#FF0000": "red",
  "#DC0000": "red",
  "#FFFF00": "yellow",
  "#4B0082": "red",
  "#00005E": "green",
  "#005500": "darkgreen",
  "#4B0082": "purple",
  // "#FFFFE0": "cream",
};
export const textColorMap = {
  "#4B0082": "text_purple",
  "#FFFFE0": "text_white",
  "#009300": "text_green",
  "#DC0000": "text_red"
};

// export const fontMap = {
//   "":"",
//   "":""

// }

export function marketwatchCellColor(
  classes,
  prop,
  font_color,
  cat,
  applyColor,
  alerts
) {
  let cellColor = "";

  if (applyColor) {
    if (alerts === "Market Alert") {
      cellColor = "redRow";
    } else {
      cellColor = this.colorMap[prop] ? this.colorMap[prop] : "";
      console.log("cellColor======>", cellColor);
    }
  }

  classes = `${classes} ${cellColor}`;
  classes = `${classes} ${this.hideColumnOption[cat] ? "brightness-50" : ""}`;
  console.log("classes", classes);
  return classes;
}
export function marketwatchCellColor3(
  classes,
  props,
  cat,
  applyColor,
  alerts
) {
  let cellColor = "", textColor = "";

  if (applyColor) {
    // if ((alerts + "").toUpperCase().replace(/\s/g, "_") === "MARKET_ALERT") {
    if (alerts === "Market Alert") {
      cellColor = "redRow";
    } else {
      cellColor = this.colorMap[props.bgColor]
        ? this.colorMap[props.bgColor]
        : "";
      console.log("cellColor======>", cellColor);

      textColor = this.colorMap[props.textColor]
        ? this.colorMap[props.textColor]
        : "";

    }
  }

  classes = `${classes} ${cellColor} ${textColor}`;
  classes = `${classes} ${
    this.hideColumnOption[cat] ? "brightness-50" : ""
  }`;
  console.log("defaultClasses", classes);
  return classes;
}
export function marketwatchCellColor2(p, x) {
 
  
  let cellColor = "", textColor = "";

  if (p.applyColor) {
    // if ((alerts + "").toUpperCase().replace(/\s/g, "_") === "MARKET_ALERT") {
    if (alerts === "Market Alert") {
      cellColor = "redRow";
    } else {
      cellColor = this.colorMap[props.bgColor]
        ? this.colorMap[props.bgColor]
        : "";
      console.log("cellColor======>", cellColor);

      textColor = this.colorMap[props.textColor]
        ? this.colorMap[props.textColor]
        : "";

    }
  }

  p.classes = `${classes} ${cellColor} ${textColor}`;
  p.classes = `${classes} ${
    this.hideColumnOption[cat] ? "brightness-50" : ""
  }`;
  console.log("defaultClasses", classes);
  return classes;
}