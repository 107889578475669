import { render, staticRenderFns } from "./TreeView.vue?vue&type=template&id=3b6c92a4&scoped=true"
import script from "./TreeView.vue?vue&type=script&lang=js"
export * from "./TreeView.vue?vue&type=script&lang=js"
import style0 from "./TreeView.vue?vue&type=style&index=0&id=3b6c92a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6c92a4",
  null
  
)

export default component.exports