<template>
  <div
    class="w-[615px] px-10 py-4"
    style="
      border: 0.3px solid rgba(2, 189, 45, 0.3);
      box-sizing: border-box;
      scrollbar-color: red;
      border-radius: 3px;
      background-color: #151515;
    "
  >
    <div class="flex pb-4">Saved Date(s)</div>

    <div
      role="status"
      v-if="loaderState"
      class="min-h-[18rem] flex justify-center"
    >
      <div class="self-center">
        <svg
          class="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="text-white text-sm">Loading...</span>
      </div>
    </div>

    <div
      v-if="!loaderState"
      class="min-h-[10rem] max-h-[18rem] overflow-y-auto"
    >
      <div class="mt-4" v-if="warningAlertState">
        <AlertShort alertType="Danger">
          <p class="text-sm">{{ emptyDateMessage }}</p>
        </AlertShort>
      </div>

      <div
        :key="x._id"
        v-for="x of dateArray"
        class="flex items-center justify-between"
        style="border-bottom: 0.2px solid #75827830"
        tabindex="0"
      >
        <div class="rows text-sm whitespace-nowrap">
          {{ x.DateCollectionName }} - ({{ x.DatesArray[0].split("-")[0] }})
        </div>

        <div class="flex">
          <div class="btn1" @click="handleSelectedDates(x)">
            <button class="rowsIcon1">SELECT</button>
          </div>

          <div class="btn2">
            <button
              class="rowsIcon"
              @click="handleDateDelete(x._id, x.DateCollectionName)"
            >
              REMOVE
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loaderState" class="flex justify-end pb-4">
      <!-- <button
        @click="handleCloseModal"
        class="bg-[#ff0a00] px-8 rounded mt-6 font-semibold text-white py-2 text-xs"
      >
        Cancel
      </button> -->

      <button
        type="submit"
        @click="handleCloseModal"
        class="cancel-button px-8 rounded mt-6 mr-4 font-semibold py-2 text-sm"
      >
        Close
      </button>

      <!-- 
        <button
          class="bg-[#ff0a00] px-8 rounded mt-6 mr-4 font-semibold text-white py-2 text-xs"
          @click="handleCloseModal"
        >
          Close
        </button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datesApi from "../../api/datesApi";
import AlertShort from "../utilities/AlertShort.vue";

export default {
  name: "LoadDates",
  props: {},
  data() {
    return {
      loaderState: true,
      warningAlertState: false,
      emptyDateMessage: "No Dates Found",

      email: "",
      dateArray: [],
      delt: false,
      idd: "",
      loading: false,
      rowCollection: "",
      emptyDates: false,
    };
  },
  components: {
    AlertShort,
  },
  methods: {
    async handleDateDelete(dates) {
      try {
        this.loading = true;
        const payload = {
          id: dates,
        };
        console.log("dates", payload);
        const res = await datesApi.deleteDates(payload);
        if (res.status == 200) {
          this.loading = false;
          this.$store.commit("ADD_DATE_INTO_ARRAY", []);
        }
      } catch (err) {
        alert(err);
      }
      this.fetchSavedDates();
    },

    async fetchSavedDates() {
      try {
        this.loaderState = true;
        const payload = {
          email: localStorage.getItem("email"),
          category:this.categoryforSaveDates
        }
        const res = await datesApi.loadDates(payload);
        if (res.data.data) {
          this.dateArray = res.data.data;
          console.log("date array", this.dateArray);
          this.$store.commit("SAVE_LOADED_DATES", res.data.data);
          this.loaderState = false;
        }
        if (this.dateArray.length === 0) {
          this.warningAlertState = true;
        }
      } catch (err) {
        alert(err);
      }
      this.loading = true;
    },

    handleSelectedDates(obj) {
      let year = obj.DatesArray[0].split("-")[0];
      let value = obj.DatesArray;
      if(this.$route.path == "/marketalert"){
        this.$store.commit("CHANGE_YEAR_MA", Number(year));
      }else{
        this.$store.commit("CHANGE_YEAR", Number(year));
      }
      setTimeout(()=>{
      if (this.getTableData.length) {
        let filterTabledates = [];
        let filterTabledatesIndex = [];
        for (let index in this.getTableData) {
          let item = this.getTableData[index];
          if (value.includes(item.date)) {
            filterTabledates.push(item.date);
            filterTabledatesIndex.push(Number(index));
          }
        }
        if (filterTabledates.length>0) {
            setTimeout(()=>{
          this.$store.commit("ADD_DATE_INTO_ARRAY", filterTabledates);
          this.$store.commit("CHECKED_DATES_MODAL", {
            index: filterTabledatesIndex,
            name: obj.DateCollectionName,
          });
             },)
        }
      }

      },3000)
      this.handleCloseModal(year);
    },
    handleCloseModal(year) {
      this.$emit("change", year);
    },
  },

  watch: {
    dateArray() {
      this.$store.commit("SAVE_LOADED_DATES", "dateArray");
    },
  },

  mounted() {
    this.fetchSavedDates();
  },
  computed: {
    ...mapGetters([
      "getTableData",
      "selectedDateStore",
      "selectedcheckedDatedModalStore",
      "selectedSavedDateStore",
      "categoryforSaveDates"

    ]),
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}

.overlay__content_badge {
  left: 28%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.whiteBtn {
  width: 80px;
  cursor: pointer;
  text-transform: uppercase;
  height: 26px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  border: 1px solid white;
  border-radius: 5px;
  padding-top: 3px;
  background-color: black;

  color: white;
}

.cnfrmDelt {
  width: 80px;
  cursor: pointer;
  text-transform: uppercase;
  height: 26px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  border: 0.3px solid white;
  border-radius: 5px;
  background-color: black;
  color: white;
}

.cnfrmDelt:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}

.cnfrmDeltt {
  width: 80px;
  cursor: pointer;
  text-transform: uppercase;
  height: 26px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  border-radius: 5px;
  background-color: black;
}

.cnfrmDeltt:hover {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.btn1 button {
  border-radius: 3px;
  height: 22px;
  width: 80px;
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(2, 189, 45, 0.8);
  color: #02bd2d;
  background: #121010;
  box-sizing: border-box;
  border-radius: 3px;
}

.btn2 button {
  border-radius: 3px;
  height: 22px;
  margin-left: 10px;
  width: 80px;
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-size: 9px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  padding: 5px;
  color: #ff0a00;
  border-radius: 3px;
  background: #121010;
  border: 1px solid #ff0a00;
  border-radius: 3px;
  box-sizing: border-box;
}

.btn1 button:hover,
.btn1 button:focus {
  background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}

.btn2 button:hover,
.btn2 button:focus {
  background: #550b08;
}

.innerDiv {
  display: flex;
  padding: 6px;
  cursor: pointer;
  margin-top: 20px !important;
}

.rows {
  padding-left: 0px;
  margin-left: 0px;
  color: #ffff;
  padding: 10px;
}

.textfields {
  border: 1px solid rgba(117, 130, 120, 0.3);
  border-radius: 3px;
  margin-top: 12px;
  margin-left: 9px;
  width: 350px;
  color: #758278;
  text-indent: 10px;
  height: 45px;
  display: flex;
  flex-direction: column;
}

.savebtn {
  background-color: #02bd2d;
  width: 100px;
  text-align: center;
  border-radius: 2px;
  height: 45px;
  color: #f8f9f9;
  margin-top: 17px;
  padding: 14px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 9px;
}

.closebtn {
  width: 80px;
  cursor: pointer;
  text-transform: uppercase;
  height: 26px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  border: 1px solid white;
  border-radius: 5px;
  padding-top: 3px;
  background-color: black;
  margin-top: 6px;
  color: white;
}

.closebtn:hover,
.closebtn:focus {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
  /* border: 0.6px solid rgba(248, 249, 249, 0.8);
border-radius: 3px; */
}

.textfields::placeholder {
  color: #758278;
  font-size: 12px;
  margin-left: 5rem;
  /* padding: 10px; */
}

.divbtns {
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f8f9f9;
}

.label-bottom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #f8f9f9;
  white-space: nowrap;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay__content {
  left: 50%;
  top: 200px;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 45px;
  height: 45px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

/* .innerDiv{cursor: pointer; transition: all .25s ease-in-out} */
/* div.innerDiv.selected{background-color: red; font-weight: bold; color: #fff;} */

.clicked {
  color: red;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.modal-overlay {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #4f5254;
  opacity: 0.6;
  cursor: pointer;
}

.fade-enter-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.emptyDates {
  margin: -30px auto;
  border: 0.1px solid rgba(117, 130, 120, 0.3);
  height: 300px;
  width: 490px;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(38, 34, 17, 0.866);
  border-radius: 3px;
}

.overlay__content_badge {
  left: 30%;
  top: 35%;
  width: 20%;
  /* height: 300px; */
  transform: translate(-66%, -50%);
}
</style>
