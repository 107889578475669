<template>
    <tailwind-modal
      :show-modal="openSaveModal"
      v-on:change="toggleModal"
      :showHeader="false"
    >
      <div
        class="min-w-[30rem] px-10 py-4"
        style="
          border: 0.3px solid rgba(2, 189, 45, 0.3);
          box-sizing: border-box;
          scrollbar-color: red;
          border-radius: 3px;
          background-color: #151515;
        "
      >
  
        <div class="h-[14vh] space-y-4">
            <div>Save Table Columns</div>
            <div class="space-y-2">
                <div>
                <input
                :class="isLoading?'pointer-events-none opacity-50':''"
                type="text"
                class="p-4 flex bg-transparent block w-full text-gray-200 border border-[#2d2d2d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
                placeholder="Name..."
                v-model="columnName"
                @keyup.enter="handleColumnName"
                />
            </div>

            <div>
              <span v-if="showError" class="text-[16px] mt-4 text-[#FF0A00] text-center">{{showError}}</span>
              <span v-if="showSuccess" class="text-[16px] mt-4 text-[#02bd2d] text-center">{{showSuccess}}</span>
          </div>
        </div>
          
        </div>
  
        <div class="buttons flex justify-end">
         
          <button
          :class="isLoading?'pointer-events-none opacity-50':''"
            @click="toggleModal"
            class="cancel-button px-4 rounded mt-6 mr-4 font-semibold py-1 text-sm"
            > 
        Close
      </button>
          <button   
          :class="isLoading?'pointer-events-none opacity-50':''"
          :disabled="isLoading"
          @click="handleColumnName"    
            class="ok-button px-4 mt-6 font-semibold py-1 text-sm"
      >
       {{isLoading?'Saving..':'Save'}}
      </button>
        </div>
      </div>
    </tailwind-modal>
  </template>
  <script>
  import TailwindModal from "@/components/popups/TailwindModal";
  import watchListApi from "../../../api/watchListApi";

  export default {
    name: "SaveTableColumn",
    components: { TailwindModal },
    data() {
      return {
        columnName:"",
        showError:"",
        showSuccess:"",
        isLoading:false
      };
    },
    props: {
        openSaveModal:{
            type:Boolean,
            default:false
        },
        tableColumns:{
            type:Array,
            default:[]
        }
    },
    methods: {
      toggleModal() {
        this.columnName=""
        this.$emit("close");
      },
      async handleColumnName() {
        const isEmpty = /^\s*$/.test(this.columnName);
        if(isEmpty){
            this.showError="Please enter valid name"
            setTimeout(() => {
                this.showError = false
            }, 3000);
            return
        }
        try{
        this.isLoading = true
        const payload={
            name:this.columnName,
            ColumnList:this.tableColumns
        }
        const res = await watchListApi.saveTableColumn(payload)
        if(res?.data?.success){
            this.showSuccess = res?.data?.message
            setTimeout(() => {
                this.showSuccess = false
                this.columnName = ""
                this.isLoading = false
                this.toggleModal()
            }, 2000);
        }else{
            this.showError = res?.data?.message
            setTimeout(() => {
                this.showError = false
              }, 2000);
              this.isLoading = false
        }
      }catch(err){
        this.showError = "Server error"
            setTimeout(() => {
                this.showError = false
                this.isLoading = false
            }, 2000)
      }

        },
      


      

    },
    mounted(){
        this.columnName = ""
    }
  };
  </script>
  <style scoped>
.ok-button {
    border: 0.6px solid #02bd2d;
  border-radius: 3px;
  color: #02bd2d;
  text-transform: uppercase;
}
.ok-button:hover {
    background: linear-gradient(
    270deg,
    rgba(2, 189, 45, 0.24) 0%,
    rgba(2, 189, 45, 0) 50%,
    rgba(2, 189, 45, 0.24) 100%
  );
}
.cancel-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}
.cancel-button:hover {
  background: linear-gradient(
    270deg,
    rgba(248, 249, 249, 0.21) 0%,
    rgba(248, 249, 249, 0) 52.08%,
    rgba(248, 249, 249, 0.21) 100%
  );
}


</style>
  